import { createSlice } from "@reduxjs/toolkit";
import { EnumInspectionAllocationShowYearCount } from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionSetDTO,
  SearchParameterDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";
import { InspectionAllocationPageInitialState } from "./InspectionAllocationPage.types";

// ==============================|| VIEWS - PAGES - INSPECTIONALLOCATION - REDUCER ||============================== //
const initialState = {
  InspectionSetList: [] as Array<InspectionSetDTO>,
  SiteInspectionAllocationList: [] as Array<SiteDTO>,
  InspectionSetSearchParameter: {
    InspectionSetSearchParams: {
      Name: "",
    } as InspectionSetDTO,
  } as SearchParameterDTO,
  SiteSearchParameter: {
    SiteSearchParams: {
      SiteName: "",
      AssignedFromYear:
        new Date().getFullYear() -
        (EnumInspectionAllocationShowYearCount.ShowYearCount - 1),
      AssignedToYear: new Date().getFullYear(),
    } as SiteDTO,
  } as SearchParameterDTO,
  AllocationYearList: [] as Array<number>,
  Inspection: {
    Id: 0,
    SiteId: 0,
    PlannedCompletionDate: "",
    AllocatedTime: "",
  } as InspectionDTO,
} as InspectionAllocationPageInitialState;

export const inspectionAllocationSlice = createSlice({
  name: "inspectionAllocation",
  initialState: initialState,
  reducers: {
    setInspectionAllocationInitState: (
      state: InspectionAllocationPageInitialState,
      action: { payload: InspectionAllocationPageInitialState; type: string }
    ) => {
      return {
        ...state,
        InspectionSetList: action.payload.InspectionSetList,
        SiteInspectionAllocationList:
          action.payload.SiteInspectionAllocationList,
        SiteSearchParameter: action.payload.SiteSearchParameter,
        //AllocationYearList: action.payload.AllocationYearList,
      };
    },
    setInspectionSetListForInspectionAllocation: (
      state: InspectionAllocationPageInitialState,
      action: { payload: Array<InspectionSetDTO>; type: string }
    ) => {
      return {
        ...state,
        InspectionSetList: action.payload,
      };
    },
    setSiteInspectionAllocationList: (
      state: InspectionAllocationPageInitialState,
      action: { payload: Array<SiteDTO>; type: string }
    ) => {
      return {
        ...state,
        SiteInspectionAllocationList: action.payload,
      };
    },
    setSiteInspection: (
      state: InspectionAllocationPageInitialState,
      action: { payload: InspectionDTO; type: string }
    ) => {
      state.SiteInspectionAllocationList =
        state.SiteInspectionAllocationList.map((item) => {
          if (item.Id.toString() === action.payload.SiteId.toString()) {
            item.Inspections.push(action.payload);
          }
          return item;
        });
    },
    setUpdatedSiteInspection: (
      state: InspectionAllocationPageInitialState,
      action: { payload: InspectionDTO; type: string }
    ) => {
      state.SiteInspectionAllocationList =
        state.SiteInspectionAllocationList.map((item) => {
          if (item.Id.toString() === action.payload.SiteId.toString()) {
            item.Inspections.forEach((inspection) => {
              if (inspection.Id.toString() === action.payload.Id.toString()) {
                inspection.AllocatedTime = action.payload.AllocatedTime;
                inspection.PlannedCompletionDate =
                  action.payload.PlannedCompletionDate;
                inspection.InspectionStatusId =
                  action.payload.InspectionStatusId;
                inspection.Name = action.payload.Name;
              }
              return inspection;
            });
          }
          return item;
        });
    },
    removeInspectionAllocation: (
      state: InspectionAllocationPageInitialState,
      action: { payload: InspectionDTO; type: string }
    ) => {
      state.SiteInspectionAllocationList =
        state.SiteInspectionAllocationList.map((item) => {
          if (item.Id.toString() === action.payload.SiteId.toString()) {
            item.Inspections = item.Inspections.filter(
              (filter) => filter.Id.toString() !== action.payload.Id.toString()
            );
          }
          return item;
        });
    },
    setInspectionSetSearchParameter: (
      state: InspectionAllocationPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        InspectionSetSearchParameter: {
          ...state.InspectionSetSearchParameter,
          InspectionSetSearchParams: action.payload.InspectionSetSearchParams,
        } as SearchParameterDTO,
      };
    },
    setAllocationYearList: (
      state: InspectionAllocationPageInitialState,
      action: { payload: Array<number>; type: string }
    ) => {
      return {
        ...state,
        AllocationYearList: action.payload,
      };
    },
    setSiteSearchParameter: (
      state: InspectionAllocationPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        SiteSearchParameter: {
          ...state.SiteSearchParameter,
          SiteSearchParams: action.payload.SiteSearchParams,
        } as SearchParameterDTO,
      };
    },
  },
});

export const {
  setInspectionAllocationInitState,
  setInspectionSetListForInspectionAllocation,
  setSiteInspectionAllocationList,
  setSiteInspection,
  setUpdatedSiteInspection,
  removeInspectionAllocation,
  setSiteSearchParameter,
} = inspectionAllocationSlice.actions;
export const InspectionAllocationReducer = inspectionAllocationSlice.reducer;
