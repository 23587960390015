import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
} from "@redux-saga/core/effects";
import {
  BULKAPPROVEREMEDIATIONTRACKERTASK,
  BULKREJECTREMEDIATIONTRACKERTASK,
  DELETEREMEDIATIONTRACKERTASK,
  GETREMEDIATIONTRACKERTASINITIALDATA,
  GETREMEDIATIONTRACKERTASKCOLUMNWISEFILTERLIST,
  GETREMEDIATIONTRACKERTASKSDETAILS,
  GETREMEDIATIONTRACKERTASKSLIST,
  bulkApproveRemediationTrackerTask,
  getRemediationTrackerTaskList,
} from "./RemediationTrackerTasksPage.action";
import {
  bulkApproveRemediationTrackerTaskApi,
  bulkRejectRemediationTrackerTaskApi,
  deleteRemediationTrackerTask,
  getRemediationTrackerTaskStatisticsApi,
  getRemediationTrackerTaskDetails,
  getRemediationTrackerTaskInitialData,
  getRemediationTrackerTaskListApi,
  getRemediationTrackerTasksColumnWiseFilter,
} from "./RemediationTrackerTasksPage.api";
import { RemediationTrackerTasksPageType } from "./RemediationTrackerTasksPage.types";
import { AnyAction } from "redux";
import { remediationTrackerTasksReducerActions } from "./RemediationTrackerTasksPage.reducer";

import {
  BulkApproveRemediationTrackerTaskDTO,
  BulkRejectRemediationTrackerTaskDTO,
  RemediationTrackerTaskDTO,
  RemediationTrackerTaskQuestionDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { ActionResponseType } from "../../../common/types/common.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { RootState } from "../../../core/store";
import { channel } from "redux-saga";
import { callAllApiRequest } from "../../../core/api/apiClient.service";

// ==============================|| VIEWS - PAGES - REMEDIATION TRACKER TASKS - SAGA ||============================== //

// Watcher for Bulk Approval channel
const bulkApprovalChannel = channel();
export function* bulkApprovalChannelChannelWatcher() {
  while (true) {
    const action = yield take(bulkApprovalChannel);
    yield put(action);
  }
}

const getRemediationTrackerTaskChannel = channel();
export function* getRemediationTrackerTaskChannelWatcher() {
  while (true) {
    const action = yield take(getRemediationTrackerTaskChannel);
    yield put(action);
  }
}

const getRemediationTrackerTaskInitialDataWatcher = function* (): any {
  yield takeEvery(
    GETREMEDIATIONTRACKERTASINITIALDATA,
    function* (action: AnyAction): any {
      const result: ActionResponseType = yield call(
        getRemediationTrackerTaskInitialData
      );

      if (result.IsSuccess) {
        yield put(
          remediationTrackerTasksReducerActions.setDropDownList(result.Data)
        );
        yield put(
          remediationTrackerTasksReducerActions.setRemediationTaskSystemConfiguration(
            result.Data
          )
        );

        const state: RemediationTrackerTasksPageType = yield select(
          (s: RootState) => s.remediationTrackerTasksReducer
        );
        yield put(
          remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
            state.RemediationTrackerTaskSearchParameter
          )
        );
      }
    }
  );
};

const getRemediationTrackerTaskListWatcher = function* (): any {
  yield takeEvery(
    GETREMEDIATIONTRACKERTASKSLIST,
    function* (action: AnyAction): any {
      const state: RemediationTrackerTasksPageType = yield select(
        (s: RootState) => s.remediationTrackerTasksReducer
      );

      // set page loading
      if (!state.DataLoading) {
        yield put(remediationTrackerTasksReducerActions.setDataLoading(false));
        yield put(setLoading(true));
      }

      const searchParameters: SearchParameterDTO = {
        ...action.payload?.searchParameter,
        //FilterColumnWise: false,
        RemediationTrackerTaskSearchParams: !action.payload?.searchParameter
          ?.FilterColumnWise
          ? {
              ...action.payload?.searchParameter
                ?.RemediationTrackerTaskSearchParams,
              Identifier: "",
              LocationName: "",
              InspectionName: "",
              InspectionStandardName: "",
              ColumnFilterQ_No: "",
              PiorityNavigation: {},
              DueDateString: "",
              StatusNavigation: {},
              IsCapexrequiredNavigation: {},
              ColumnFilterCapexamountRequired: "",
              AreaOwnerNavigation: {},
              NoOfRemediationTrackerTaskCount: -1,
              Approver: "",
            }
          : action.payload?.searchParameter?.RemediationTrackerTaskSearchParams,
      };

      const getTaskListApi: Promise<ActionResponseType> =
        getRemediationTrackerTaskListApi(searchParameters);
      const getTaskStatisticsApi: Promise<ActionResponseType> =
        getRemediationTrackerTaskStatisticsApi(searchParameters);

      const result = yield call(
        async () =>
          await callAllApiRequest([getTaskListApi, getTaskStatisticsApi])
      );

      if (result[0]?.Data?.PageData == null && !result[0]?.Data?.PageData) {
        // TODO document why this block is empty
      } else {
        yield put(
          remediationTrackerTasksReducerActions.setRemediationTrackerTaskList({
            Data: {
              ...result[0]?.Data?.PageData,
              TotalRecordsCount: result[1]?.Data?.TotalRecordsCount,
            },
            TaskStatusSummaryCount: result[1]?.Data?.TaskStatusSummaryCount,
            TaskAssessmentSummary: result[1]?.Data?.TaskAssessmentSummary,
          } as RemediationTrackerTasksPageType)
        );

        yield put(
          remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
            {
              ...searchParameters,
              RemediationTrackerTaskSearchParams: {
                ...searchParameters.RemediationTrackerTaskSearchParams,
                StatusSummaryFilter: result[0]?.Data?.SelectedStatusId,
                AssessmentTypeGroupId:
                  result[0]?.Data?.SelectedAssessmentTypeGroupId,
                SelectedPendingApproverIdList:
                  result[0]?.Data?.SelectedPendingApproverIdList,
              },
            } as SearchParameterDTO
          )
        );
      }

      //yield put(remediationTrackerTasksReducerActions.setDataLoading(false));
      yield put(setLoading(false));
    }
  );
};

const getRemediationTrackerTaskColumnWiseFilterWatcher = function* (): any {
  yield takeEvery(
    GETREMEDIATIONTRACKERTASKCOLUMNWISEFILTERLIST,
    function* (action: AnyAction): any {
      const state: RemediationTrackerTasksPageType = yield select(
        (s: RootState) => s.remediationTrackerTasksReducer
      );

      const searchPara: SearchParameterDTO = {
        ...action.payload?.searchParameter,
        FilterColumnWise: true,
      };

      const result = yield call(
        async () => await getRemediationTrackerTasksColumnWiseFilter(searchPara)
      );

      if (result?.Data == null && !result?.Data) {
        // TODO document why this block is empty
      } else {
        yield put(
          remediationTrackerTasksReducerActions.setRemediationTrackerTaskList({
            Data: result?.Data,
            TaskStatusSummaryCount: state.TaskStatusSummaryCount,
            TaskAssessmentSummary: state.TaskAssessmentSummary,
          } as RemediationTrackerTasksPageType)
        );

        yield put(
          remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
            searchPara
          )
        );
      }

      yield put(remediationTrackerTasksReducerActions.setDataLoading(false));
    }
  );
};

const getRemediationTrackerTaskDetailsWatcher = function* (): any {
  yield takeEvery(
    GETREMEDIATIONTRACKERTASKSDETAILS,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      const searchPara: SearchParameterDTO = action.payload;

      const result = yield call(() =>
        getRemediationTrackerTaskDetails(searchPara)
      );
      if (result?.Data == null && !result?.Data) {
        // TODO document why this block is empty
      } else {
        yield put(
          remediationTrackerTasksReducerActions.setRemediationTrackerTaskDetails(
            {
              ...result?.Data,
              Id: 0, //skip change task count
            } as RemediationTrackerTaskDTO
          )
        );
      }
      yield put(setLoading(false));
    }
  );
};

const deleteRemediationTrackerTaskWatcher = function* (): any {
  //
  yield takeEvery(
    DELETEREMEDIATIONTRACKERTASK,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      const remediationTrackerTaskQuestion: RemediationTrackerTaskQuestionDTO =
        action.payload;

      const result: ActionResponseType = yield call(() =>
        deleteRemediationTrackerTask(
          remediationTrackerTaskQuestion?.RemediationTrackerTask?.Id
        )
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when deleting task",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        //get Task List
        const state: RemediationTrackerTasksPageType = yield select(
          (s: RootState) => s.remediationTrackerTasksReducer
        );

        const result = yield call(() =>
          getRemediationTrackerTaskDetails({
            ...state?.RemediationTrackerTaskSearchParameter,
            RemediationTrackerTaskSearchParams: {
              ...state?.RemediationTrackerTaskSearchParameter
                ?.RemediationTrackerTaskSearchParams,
              Id: remediationTrackerTaskQuestion?.RemediationTrackerTask?.Id,
              InspectionQuestionId:
                remediationTrackerTaskQuestion?.InspectionQuestion?.Id,
              IsMergeTask:
                remediationTrackerTaskQuestion?.RemediationTrackerTask
                  ?.IsMergeTask,
            },
          })
        );

        if (result?.Data == null && !result?.Data) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when deleting task",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          yield put(
            remediationTrackerTasksReducerActions.setRemediationTrackerTaskDetails(
              {
                ...result?.Data,
                Id: remediationTrackerTaskQuestion?.RemediationTrackerTask?.Id,
              } as RemediationTrackerTaskDTO
            )
          );
        }
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const bulkApproveRemediationTrackerTaskWatcher = function* (): any {
  //
  yield takeEvery(
    BULKAPPROVEREMEDIATIONTRACKERTASK,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      // state
      const state: RemediationTrackerTasksPageType = yield select(
        (s: RootState) => s.remediationTrackerTasksReducer
      );

      // selected Remediation Tracker Task Id List
      const selectedRemediationTrackerTask: BulkApproveRemediationTrackerTaskDTO =
        action.payload;

      const result: ActionResponseType = yield call(() =>
        bulkApproveRemediationTrackerTaskApi(selectedRemediationTrackerTask)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when approve task(s)",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const isSameUserHasMultipleRole: boolean =
          result.Data?.IsSameUserHasMultipleRole;

        const isApproved: boolean = result.Data?.IsApproved;

        if (!isApproved) {
          yield put(
            openPopup({
              Open: true,
              BodyText: isSameUserHasMultipleRole
                ? "At least one of the selected task(s) has more than one position assigned to current user. Do you wish proceed and approve all at once?"
                : "Are you sure you want to approve the selected  task(s)?",
              HeaderText: "Confirmation",
              PopupType: EnumPopupType.YesNoConfirmation,
              YesBtnClick() {
                bulkApprovalChannel.put(
                  bulkApproveRemediationTrackerTask({
                    ...selectedRemediationTrackerTask,
                    CheckSameUserHasMultipleRole: false,
                  } as BulkApproveRemediationTrackerTaskDTO)
                );
              },
            })
          );
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Selected task(s) approved successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );

          // reset selected task
          yield put(
            remediationTrackerTasksReducerActions.setSelectedRemediationTrackerTask(
              {
                ...state,
                SelectedApproveRejectRemediationTrackerTaskIdList: [],
                SelectedRemediationTrackerTask: [],
                SelectedRemediationTrackerTaskIdList: [],
              }
            )
          );

          // set page loading
          yield put(remediationTrackerTasksReducerActions.setDataLoading(true));

          // get remediation tracker task lsit
          getRemediationTrackerTaskChannel.put(
            getRemediationTrackerTaskList({
              ...state.RemediationTrackerTaskSearchParameter,
              RemediationTrackerTaskSearchParams: {
                ...state.RemediationTrackerTaskSearchParameter
                  .RemediationTrackerTaskSearchParams,
              },
              SelectedPage: 1,
            })
          );
        }

        // set page loading
        yield put(setLoading(false));
      }
    }
  );
};

const bulkRejectRemediationTrackerTaskWatcher = function* (): any {
  //
  yield takeEvery(
    BULKREJECTREMEDIATIONTRACKERTASK,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      // state
      const state: RemediationTrackerTasksPageType = yield select(
        (s: RootState) => s.remediationTrackerTasksReducer
      );

      const selectedRemediationTrackerTask: BulkRejectRemediationTrackerTaskDTO =
        action.payload;

      const result: ActionResponseType = yield call(() =>
        bulkRejectRemediationTrackerTaskApi(selectedRemediationTrackerTask)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when reject task(s)",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText: "Selected task(s) rejected successfully",
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        // reset selected task
        yield put(
          remediationTrackerTasksReducerActions.setSelectedRemediationTrackerTask(
            {
              ...state,
              SelectedApproveRejectRemediationTrackerTaskIdList: [],
              SelectedRemediationTrackerTask: [],
              SelectedRemediationTrackerTaskIdList: [],
            }
          )
        );

        // set page loading
        yield put(remediationTrackerTasksReducerActions.setDataLoading(true));

        // get remediation tracker task lsit
        getRemediationTrackerTaskChannel.put(
          getRemediationTrackerTaskList({
            ...state.RemediationTrackerTaskSearchParameter,
            RemediationTrackerTaskSearchParams: {
              ...state.RemediationTrackerTaskSearchParameter
                .RemediationTrackerTaskSearchParams,
            },
            SelectedPage: 1,
          })
        );
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const remediationTrackerTaskListSaga = function* () {
  yield all([
    getRemediationTrackerTaskInitialDataWatcher(),
    getRemediationTrackerTaskListWatcher(),
    getRemediationTrackerTaskDetailsWatcher(),
    deleteRemediationTrackerTaskWatcher(),
    bulkApproveRemediationTrackerTaskWatcher(),
    bulkRejectRemediationTrackerTaskWatcher(),
    getRemediationTrackerTaskColumnWiseFilterWatcher(),
    bulkApprovalChannelChannelWatcher(),
    getRemediationTrackerTaskChannelWatcher(),
  ]);
};

export default remediationTrackerTaskListSaga;
