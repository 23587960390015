import { createSlice } from "@reduxjs/toolkit";
import { DownloadStatusType, StandardReportType } from "./StandardReport.types";

const initialState = {
  data: {} as StandardReportType,
  isReady: false,
  excelReportStatus: [],
};

const standardReportPageSlice = createSlice({
  name: "StandardReport",
  initialState: initialState,
  reducers: {
    setStandardReportData: (state, action: { payload: StandardReportType }) => {
      return { ...state, data: action.payload };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, isReady: action.payload };
    },
    // setExcelReportInProgress: (
    //   state,
    //   action: { payload: DownloadStatusType }
    // ) => {
    //   const tempList = [...state.excelReportStatus];
    //   const index = tempList.findIndex(
    //     (standard) => standard.StandardId === action.payload.StandardId
    //   );

    //   if (index !== -1) {
    //     tempList[index] = {
    //       ...tempList[index],
    //       ...({
    //         StandardId: tempList[index].StandardId,
    //         IsDownloading: true,
    //       } as DownloadStatusType),
    //     };
    //   } else {
    //     tempList.push({
    //       StandardId: action.payload.StandardId,
    //       IsDownloading: true,
    //     } as DownloadStatusType);
    //   }

    //   return { ...state, excelReportStatus: tempList };
    // },
    // setExcelReportReady: (state, action: { payload: DownloadStatusType }) => {
    //   const tempList = [...state.excelReportStatus];
    //   const index = tempList.findIndex(
    //     (standard) => standard.StandardId === action.payload.StandardId
    //   );

    //   if (index !== -1) {
    //     tempList[index] = {
    //       ...tempList[index],
    //       ...({
    //         StandardId: tempList[index].StandardId,
    //         IsDownloading: false,
    //       } as DownloadStatusType),
    //     };
    //   }
    //   return { ...state, excelReportStatus: tempList };
    // },

    setExcelReportStatus: (state, action: { payload: DownloadStatusType }) => {
      const tempList = [...state.excelReportStatus];
      const index = tempList.findIndex(
        (standard) =>
          standard.InspectionId === action.payload.InspectionId &&
          standard.StandardId === action.payload.StandardId &&
          standard.StandardName === action.payload.StandardName &&
          standard.InspectionStandardId === action.payload.InspectionStandardId
      );

      if (index !== -1) {
        tempList[index] = {
          ...tempList[index],
          ...({
            InspectionId: action.payload.InspectionId,
            StandardId: tempList[index].StandardId,
            StandardName: action.payload.StandardName,
            IsDownloading: action.payload.IsDownloading,
            InspectionStandardId: action.payload.InspectionStandardId,
          } as DownloadStatusType),
        };
      } else {
        tempList.push({
          InspectionId: action.payload.InspectionId,
          StandardId: action.payload.StandardId,
          StandardName: action.payload.StandardName,
          IsDownloading: action.payload.IsDownloading,
          InspectionStandardId: action.payload.InspectionStandardId,
        } as DownloadStatusType);
      }

      return { ...state, excelReportStatus: tempList };
    },
  },
});

export const standarReportReducerActions = standardReportPageSlice.actions;
export const standarReportReducer = standardReportPageSlice.reducer;
