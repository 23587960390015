import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

export const GET_ALL_INSPECTION_EXCEL_REPORT_DATA =
  "GET_ALL_INSPECTION_EXCEL_REPORT_DATA";

const allInspectionReportSagaActions = {
  getAllInspectionSummary: (parameters: ReportViewerType) => {
    return {
      type: GET_ALL_INSPECTION_EXCEL_REPORT_DATA,
      payload: parameters,
    };
  },
};

export default allInspectionReportSagaActions;
