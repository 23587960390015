import { createSlice } from "@reduxjs/toolkit";
import {
  EnumListShowRowCountList,
  EnumRemediationTrackerTaskColumnType,
  EnumRemediationTrackerTaskStatus,
  EnumSortTypes,
} from "../../../common/enums/common.enums";
import {
  AssessmentTypeDTO,
  DropDownDTO,
  PaginationDTO,
  RemediationTrackerTaskDTO,
  RemediationTrackerTaskStatusDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";

// project imports
import {
  RemediationTrackerTasksPageType,
  TaskFilteredSummaryType,
} from "./RemediationTrackerTasksPage.types";
import dayjs from "dayjs";
import { ListColumn } from "../../components/ViewRemediationTrackerTaskList/ViewRemediationTrackerTaskList.types";

// ==============================|| VIEWS - PAGES - REMEDIATION TRACKER TASKS - REDUCER ||============================== //

const initialState = {
  Data: {
    Data: [] as Array<RemediationTrackerTaskDTO>,
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  RemediationTrackerTaskSearchParameter: {
    RemediationTrackerTaskSearchParams: {
      TaskName: "",
      Status: EnumRemediationTrackerTaskStatus.New,
      SelectedActionOwnerIdList: [] as Array<string>,
      SelectedLocationIdList: [] as Array<number>,
      SelectedStandardIdList: [] as Array<number>,
      SelectedPriorityIdList: [] as Array<number>,
      SelectedStatusIdList: [] as Array<number>,
      SelectedInspectionIdList: [] as Array<number>,
      SelectedApproverIdList: [] as Array<number>,
      SelectedIsCapexRequiredIdList: [] as Array<number>,
      SelectedCapexStatusIdList: [] as Array<number>,
      SelectedAreaOwnerTypeIdList: [] as Array<number>,
      InspectionName: "",
      Q_No: "",
      ActionOwner: "",
      FromDueDate: null,
      ToDueDate: null,
      CapexamountRequired: null,
      CapexnumberAndName: "",
      RemediationTrackerTaskSortBy: {
        Label: "TaskId",
        Value: EnumRemediationTrackerTaskColumnType.TaskId.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
      ShowTasksAssignedToMe: false,
      ShowTasksPendingMyApproval: false,
      ShowCompletedTasks: false,
      Ernumber: "",
      AssessmentTypeGroupId: "0",
    } as RemediationTrackerTaskDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    BasicSearchValue: "",
  } as SearchParameterDTO,
  SelectedRemediationTrackerTask: [] as Array<RemediationTrackerTaskDTO>,
  DropDownListFilter: {
    LocationDropDownList: [] as Array<DropDownDTO>,
    InspectionDropDownList: [] as Array<DropDownDTO>,
    StandardDropDownList: [] as Array<DropDownDTO>,
    ActionOwnerDropDownList: [] as Array<DropDownDTO>,
    PendingApproverDropDownList: [] as Array<DropDownDTO>,
    PriorityDropDownList: [] as Array<DropDownDTO>,
    StatusDropDownList: [] as Array<DropDownDTO>,
    IsCapexRequiredDropDownList: [] as Array<DropDownDTO>,
    CapexStatusDropDownList: [] as Array<DropDownDTO>,
    AreaOwnerTypeDropDownList: [] as Array<DropDownDTO>,
  } as RemediationTrackerTaskDTO,
  ShowFilters: false,
  TaskStatusSummaryCount: [] as Array<RemediationTrackerTaskStatusDTO>,
  TaskAssessmentSummary: [] as Array<AssessmentTypeDTO>,
  TaskFilteredSummary: [] as Array<TaskFilteredSummaryType>,
  SelectedRemediationTrackerTaskIdList: [] as Array<number>,
  DataLoading: false,
  MergeTaskBetweenMultipleLocationsAndInspection: false,
  SelectedApproveRejectRemediationTrackerTaskIdList: [] as Array<number>,
  IsFullScreen: false,
  IsEnableCreateMergeTask: false,
  ClearExpandedRows: false,
  ColumnOptions: [] as Array<ListColumn>,
  InitialDataLoaded: false,
  StatusSummaryFilter: 0,
} as RemediationTrackerTasksPageType;

export const remediationTrackerTaskSlice = createSlice({
  name: "RemediationTrackerTaskList",
  initialState: initialState,
  reducers: {
    setRemediationTrackerTaskInitialState: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTasksPageType; type: string }
    ) => {
      return {
        ...state,
        Data: action.payload.Data,
        RemediationTrackerTaskSearchParameter:
          action.payload.RemediationTrackerTaskSearchParameter,
        SelectedRemediationTrackerTask:
          action.payload.SelectedRemediationTrackerTask,
        DropDownListFilter: action.payload.DropDownListFilter,
        TaskStatusSummaryCount: action.payload.TaskStatusSummaryCount,
      };
    },
    setRemediationTrackerTaskList: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTasksPageType; type: string }
    ) => {
      return {
        ...state,
        Data: {
          ...state.Data,
          Data: action.payload.Data?.Data?.map(
            (item: RemediationTrackerTaskDTO) => {
              return {
                ...item,
                TaskIsChecked: state.SelectedRemediationTrackerTask?.some(
                  (some: RemediationTrackerTaskDTO) =>
                    some.InspectionQuestionId === item.InspectionQuestionId
                ),
              };
            }
          ),
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
          PageSize: action.payload.Data?.PageSize,
        } as PaginationDTO,
        TaskStatusSummaryCount: action.payload.TaskStatusSummaryCount,
        TaskAssessmentSummary: action.payload.TaskAssessmentSummary,
        ClearExpandedRows: true,
        DataLoading: false,
      };
    },
    setRemediationTrackerTaskDetails: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTaskDTO; type: string }
    ) => {
      const taskObject = {
        Data: state?.Data?.Data?.map((task: RemediationTrackerTaskDTO) => {
          if (
            action.payload?.InspectionQuestionId ===
              task.InspectionQuestionId &&
            action.payload?.IsMergeTask === task.IsMergeTask
          ) {
            return {
              ...task,
              // RemediationTrackerTaskIdList:
              //   action.payload?.Id !== 0
              //     ? task?.RemediationTrackerTaskIdList?.filter(
              //         (filter) => filter !== action.payload?.Id
              //       )
              //     : task?.RemediationTrackerTaskIdList,
              // FilteredRemediationTrackerTaskIdCount:
              //   action.payload?.Id !== 0
              //     ? task?.FilteredRemediationTrackerTaskIdCount - 1
              //     : task?.FilteredRemediationTrackerTaskIdCount,
              RemediationTrackerTaskQuestions:
                action.payload?.RemediationTrackerTaskQuestions?.map(
                  (taskQuestion) => {
                    return {
                      ...taskQuestion,
                      RemediationTrackerTask: {
                        ...taskQuestion.RemediationTrackerTask,
                        TaskIsChecked:
                          state.SelectedRemediationTrackerTaskIdList?.some(
                            (selectedTaskId) =>
                              selectedTaskId ===
                              taskQuestion.RemediationTrackerTask.Id
                          ),
                      },
                    };
                  }
                ),
              // StatusNavigation:
              //   action.payload?.StatusNavigation ?? task.StatusNavigation,
              // Status: action.payload?.Status ?? task.Status,
            } as RemediationTrackerTaskDTO;
          } else {
            return {
              ...task,
              RemediationTrackerTaskIdList:
                action.payload?.Id !== 0
                  ? task?.RemediationTrackerTaskIdList?.filter(
                      (filter) => filter !== action.payload?.Id
                    )
                  : task?.RemediationTrackerTaskIdList,
            } as RemediationTrackerTaskDTO;
          }
        }),
      };
      return {
        ...state,
        Data: {
          ...state.Data,
          Data: taskObject?.Data,
        } as PaginationDTO,
        ClearExpandedRows: false,
      };
    },
    setDropDownList: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTaskDTO; type: string }
    ) => {
      return {
        ...state,
        DropDownListFilter: {
          ...state.DropDownListFilter,
          LocationDropDownList: action.payload?.LocationDropDownList,
          InspectionDropDownList: action.payload?.InspectionDropDownList,
          StandardDropDownList: action.payload?.StandardDropDownList,
          ActionOwnerDropDownList: action.payload?.ActionOwnerDropDownList,
          PriorityDropDownList: action.payload?.PriorityDropDownList,
          StatusDropDownList: action.payload?.StatusDropDownList,
          IsCapexRequiredDropDownList:
            action.payload?.IsCapexRequiredDropDownList,
          CapexStatusDropDownList: action.payload?.CapexStatusDropDownList,
          AreaOwnerTypeDropDownList: action.payload?.AreaOwnerTypeDropDownList,
          PendingApproverDropDownList:
            action.payload?.PendingApproverDropDownList,
        },
        InitialDataLoaded: true,
      };
    },
    setRemediationTaskSystemConfiguration: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTaskDTO; type: string }
    ) => {
      return {
        ...state,
        MergeTaskBetweenMultipleLocationsAndInspection:
          action.payload.MergeTaskBetweenMultipleLocationsAndInspection,
      };
    },
    checkedRemediationTrackerTask: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTaskDTO; type: string }
    ) => {
      // set updated task selected state
      const updatedTaskList = state.Data?.Data?.map(
        (item: RemediationTrackerTaskDTO) => {
          if (
            item.InspectionQuestionId === action.payload.InspectionQuestionId &&
            item.Id === action.payload.Id
          ) {
            return {
              ...item,
              TaskIsChecked: action.payload.TaskIsChecked,
              RemediationTrackerTaskQuestions:
                item.RemediationTrackerTaskQuestions?.map((taskQuestion) => {
                  return {
                    ...taskQuestion,
                    RemediationTrackerTask: {
                      ...taskQuestion.RemediationTrackerTask,
                      TaskIsChecked: action.payload.TaskIsChecked,
                    },
                  };
                }),
            };
          }

          return item;
        }
      );

      const selectedRemediationTrackerTask = action.payload.TaskIsChecked
        ? [
            ...state.SelectedRemediationTrackerTask,
            ...state.Data.Data.filter(
              (filter: RemediationTrackerTaskDTO) =>
                filter.Id === action.payload.Id &&
                filter.InspectionQuestionId ===
                  action.payload.InspectionQuestionId
            ).map((item: RemediationTrackerTaskDTO) => {
              return {
                ...item,
                TaskIsChecked: action.payload.TaskIsChecked,
              };
            }),
          ]
        : [
            ...state.SelectedRemediationTrackerTask.filter(
              (filter: RemediationTrackerTaskDTO) =>
                filter.InspectionQuestionId !==
                action.payload.InspectionQuestionId // && !filter.IsMergeTask
            ),
          ];

      return {
        ...state,
        Data: {
          ...state.Data,
          Data: updatedTaskList,
        } as PaginationDTO,
        SelectedRemediationTrackerTask: selectedRemediationTrackerTask,
        ClearExpandedRows: false,
      };
    },
    checkedChildRemediationTrackerTask: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTaskDTO; type: string }
    ) => {
      const newRemediationTrackerTaskState: RemediationTrackerTasksPageType = {
        ...state,
        Data: {
          ...state.Data,
          Data: state.Data?.Data?.map((item: RemediationTrackerTaskDTO) => {
            if (
              item.InspectionQuestionId === action.payload.InspectionQuestionId
            ) {
              const newTaskObj: RemediationTrackerTaskDTO = {
                ...item,
                RemediationTrackerTaskQuestions:
                  item.RemediationTrackerTaskQuestions?.map((taskQuestion) => {
                    return {
                      ...taskQuestion,
                      RemediationTrackerTask: {
                        ...taskQuestion.RemediationTrackerTask,
                        TaskIsChecked:
                          taskQuestion.RemediationTrackerTask.Id ===
                          action.payload.Id
                            ? action.payload.TaskIsChecked
                            : taskQuestion.RemediationTrackerTask.TaskIsChecked,
                      },
                    };
                  }),
              };

              const parentTaskIsChecked =
                newTaskObj.RemediationTrackerTaskQuestions?.filter(
                  (some) => !some.RemediationTrackerTask.TaskIsChecked
                )?.length === 0;

              return {
                ...newTaskObj,
                TaskIsChecked: parentTaskIsChecked,
              };
            }
            return item;
          }),
        } as PaginationDTO,
      };

      const selectedRemediationTrackerTask =
        newRemediationTrackerTaskState?.Data?.Data?.filter(
          (filter) => filter.TaskIsChecked
        );

      return {
        ...newRemediationTrackerTaskState,
        SelectedRemediationTrackerTask: selectedRemediationTrackerTask,
        ClearExpandedRows: false,
      };
    },
    setSelectedRemediationTrackerTaskIdList: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTaskDTO; type: string }
    ) => {
      let selectedRemediationTrackerTaskIdList =
        state.SelectedRemediationTrackerTaskIdList ?? ([] as Array<number>);

      let selectedApproveRejectRemediationTrackerTaskIdList =
        state.SelectedApproveRejectRemediationTrackerTaskIdList ??
        ([] as Array<number>);

      if (action.payload.TaskIsChecked) {
        selectedRemediationTrackerTaskIdList = [
          ...selectedRemediationTrackerTaskIdList,
          ...action.payload.FilteredRemediationTrackerTaskIdList.filter(
            (filter) =>
              selectedRemediationTrackerTaskIdList?.length === 0 ||
              selectedRemediationTrackerTaskIdList?.some(
                (some) => some !== filter
              )
          ),
        ];

        selectedApproveRejectRemediationTrackerTaskIdList = [
          ...selectedApproveRejectRemediationTrackerTaskIdList,
          ...action.payload.ApproveOrRejectRemediationTrackerTaskIdList.filter(
            (filter) =>
              selectedApproveRejectRemediationTrackerTaskIdList?.length === 0 ||
              selectedApproveRejectRemediationTrackerTaskIdList?.some(
                (some) => some !== filter
              )
          ),
        ];
      } else {
        selectedRemediationTrackerTaskIdList =
          selectedRemediationTrackerTaskIdList?.filter(
            (filter) =>
              !action.payload.FilteredRemediationTrackerTaskIdList?.some(
                (some) => some === filter
              )
          );

        selectedApproveRejectRemediationTrackerTaskIdList =
          selectedApproveRejectRemediationTrackerTaskIdList?.filter(
            (filter) =>
              !action.payload.FilteredRemediationTrackerTaskIdList?.some(
                (some) => some === filter
              )
          );
      }

      // remove duplicate task Id
      selectedRemediationTrackerTaskIdList = [
        ...selectedRemediationTrackerTaskIdList,
      ]
        ?.filter((value, index, array) => array.indexOf(value) === index)
        ?.map((item) => {
          return item;
        });

      // remove duplicate task Id
      selectedApproveRejectRemediationTrackerTaskIdList = [
        ...selectedApproveRejectRemediationTrackerTaskIdList,
      ]
        ?.filter((value, index, array) => array.indexOf(value) === index)
        ?.map((item) => {
          return item;
        });

      return {
        ...state,
        SelectedRemediationTrackerTaskIdList:
          selectedRemediationTrackerTaskIdList,
        SelectedApproveRejectRemediationTrackerTaskIdList:
          selectedApproveRejectRemediationTrackerTaskIdList,
      };
    },
    setRemediationTrackerTaskSearchParameter: (
      state: RemediationTrackerTasksPageType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      let taskFilteredValue = [] as Array<TaskFilteredSummaryType>;

      if (action.payload?.IsAdvanceSearch) {
        //set Location Filtered List

        const locationList =
          state.DropDownListFilter?.LocationDropDownList?.filter((filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedLocationIdList?.includes(
              parseInt(filter.Value)
            )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedLocationIdList",
            Label: "Location",
            Value: locationList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Inspection Filtered List
        const inspectionList =
          state.DropDownListFilter?.InspectionDropDownList?.filter((filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedInspectionIdList?.includes(
              parseInt(filter.Value)
            )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedInspectionIdList",
            Label: "Inspection",
            Value: inspectionList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Standard Filtered List
        const standardList =
          state.DropDownListFilter?.StandardDropDownList?.filter((filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedStandardIdList?.includes(
              parseInt(filter.Value)
            )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedStandardIdList",
            Label: "Standard",
            Value: standardList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Q_No Filtered
        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "Q_No",
            Label: "Q.No",
            Value: action.payload.RemediationTrackerTaskSearchParams?.Q_No,
            DefaultValue: "",
          } as TaskFilteredSummaryType,
        ];
        // }

        //set Task Name Filtered
        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "TaskName",
            Label: "Task Description",
            Value: action.payload.RemediationTrackerTaskSearchParams?.TaskName,
            DefaultValue: "",
          } as TaskFilteredSummaryType,
        ];

        //set Action Owner Filtered List
        const actionOwnerList =
          state.DropDownListFilter?.ActionOwnerDropDownList?.filter((filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedActionOwnerIdList?.map(
              (item) => {
                return item?.toString();
              }
            )?.includes(filter.Value?.toString())
          )
            ?.map((item) => item.Label)
            ?.join(" | ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedActionOwnerIdList",
            Label: "Action Owner",
            Value: actionOwnerList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Task Status Filtered List
        const statusList = state.DropDownListFilter?.StatusDropDownList?.filter(
          (filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedStatusIdList?.includes(
              parseInt(filter.Value)
            )
        )
          ?.map((item) => item.Label)
          ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedStatusIdList",
            Label: "Status",
            Value: statusList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Priority Filtered List
        const priorityList =
          state.DropDownListFilter?.PriorityDropDownList?.filter((filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedPriorityIdList?.includes(
              parseInt(filter.Value)
            )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedPriorityIdList",
            Label: "Priority",
            Value: priorityList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Is Capex Required Filtered List
        const isCAPEXRequiredList =
          state.DropDownListFilter?.IsCapexRequiredDropDownList?.filter(
            (filter) =>
              action.payload.RemediationTrackerTaskSearchParams?.SelectedIsCapexRequiredIdList?.includes(
                parseInt(filter.Value)
              )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedIsCapexRequiredIdList",
            Label: "Is CAPEX Required",
            Value: isCAPEXRequiredList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Capex number And Name Filtered
        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "CapexnumberAndName",
            Label: "CAPEX Number & Name",
            Value:
              action.payload.RemediationTrackerTaskSearchParams
                ?.CapexnumberAndName,
            DefaultValue: "",
          } as TaskFilteredSummaryType,
        ];

        //set Capex Status Filtered List
        const cAPEXStatusList =
          state.DropDownListFilter?.CapexStatusDropDownList?.filter((filter) =>
            action.payload.RemediationTrackerTaskSearchParams?.SelectedCapexStatusIdList?.includes(
              parseInt(filter.Value)
            )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedCapexStatusIdList",
            Label: "CAPEX Status",
            Value: cAPEXStatusList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Capex amount Required Filtered
        const currencyFormatter = new Intl.NumberFormat("en-US", {
          //style: "number",
          currency: "USD",
        });

        const capexamountRequired = currencyFormatter
          .format(
            action.payload.RemediationTrackerTaskSearchParams
              ?.CapexamountRequired
          )
          ?.toString();

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "CapexamountRequired",
            Label: "CAPEX Amount Required (M$)",
            Value: capexamountRequired === "0" ? "" : capexamountRequired,
            DefaultValue: null,
          } as TaskFilteredSummaryType,
        ];

        //set From Due Date Filtered
        // taskFilteredValue = [
        //   ...taskFilteredValue,
        //   {
        //     Label: "Task Due Date(From)",
        //     Value:
        //       action.payload.RemediationTrackerTaskSearchParams?.FromDueDate,
        //   } as TaskFilteredSummaryType,
        // ];

        //set To Due Date Filtered
        const _fromDueDate = dayjs(
          action.payload.RemediationTrackerTaskSearchParams?.FromDueDate
        ).format("DD-MMM-YYYY");
        const fromDueDate = _fromDueDate !== "Invalid Date" ? _fromDueDate : "";

        const _toDueDate = dayjs(
          action.payload.RemediationTrackerTaskSearchParams?.ToDueDate
        ).format("DD-MMM-YYYY");
        const toDueDate = _toDueDate !== "Invalid Date" ? _toDueDate : "";

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "DueDate",
            Label: "Due Date",
            Value: `${fromDueDate ? "From " + fromDueDate : ""}  ${
              fromDueDate && toDueDate ? " To " : ""
            }${toDueDate}`,
            DefaultValue: null,
          } as TaskFilteredSummaryType,
        ];

        //set Approver Filtered List
        // const approverlist =
        //   state.DropDownListFilter?.ActionOwnerDropDownList?.filter((filter) =>
        //     action.payload.RemediationTrackerTaskSearchParams?.SelectedApproverIdList?.includes(
        //       parseInt(filter.Value)
        //     )
        //   )
        //     ?.map((item) => item.Label)
        //     ?.join(", ");

        // taskFilteredValue = [
        //   ...taskFilteredValue,
        //   {
        //     Field: "SelectedApproverIdList",
        //     Label: "Closure Approver",
        //     Value: approverlist,
        //     DefaultValue: [],
        //   } as TaskFilteredSummaryType,
        // ];

        //set Category Filtered List
        const categoryList =
          state.DropDownListFilter?.AreaOwnerTypeDropDownList?.filter(
            (filter) =>
              action.payload.RemediationTrackerTaskSearchParams?.SelectedAreaOwnerTypeIdList?.includes(
                parseInt(filter.Value)
              )
          )
            ?.map((item) => item.Label)
            ?.join(", ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedAreaOwnerTypeIdList",
            Label: "Category",
            Value: categoryList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set Pending Approver Filtered List
        const pendingApproverList =
          state.DropDownListFilter?.PendingApproverDropDownList?.filter(
            (filter) =>
              action.payload.RemediationTrackerTaskSearchParams?.SelectedPendingApproverIdList?.map(
                (item) => {
                  return item?.toString();
                }
              )?.includes(filter.Value?.toString())
          )
            ?.map((item) => item.Label)
            ?.join(" | ");

        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "SelectedPendingApproverIdList",
            Label: "Pending Approver(s)",
            Value: pendingApproverList,
            DefaultValue: [],
          } as TaskFilteredSummaryType,
        ];

        //set ER number Filtered
        taskFilteredValue = [
          ...taskFilteredValue,
          {
            Field: "Ernumber",
            Label: "ER Number",
            Value: action.payload.RemediationTrackerTaskSearchParams?.Ernumber,
            DefaultValue: "",
          } as TaskFilteredSummaryType,
        ];
      }

      const isAdvanceSearch =
        taskFilteredValue &&
        taskFilteredValue?.filter(
          (filter) => filter.Value && filter.Value?.trim() !== ""
        )?.length > 0;

      return {
        ...state,
        RemediationTrackerTaskSearchParameter: {
          ...state.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams:
            action.payload.RemediationTrackerTaskSearchParams,
          IsAdvanceSearch: state.InitialDataLoaded
            ? isAdvanceSearch
            : action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          ListPageSize: action.payload.ListPageSize,
          BasicSearchValue: action.payload.BasicSearchValue,
          FilterColumnWise: action.payload.FilterColumnWise,
        } as SearchParameterDTO,
        TaskFilteredSummary: taskFilteredValue?.filter(
          (filter) => filter.Value && filter.Value?.trim() !== ""
        ),
        ClearExpandedRows: true,
      };
    },
    setDataLoading: (
      state: RemediationTrackerTasksPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        DataLoading: action.payload,
      };
    },
    setSelectedRemediationTrackerTask: (
      state: RemediationTrackerTasksPageType,
      action: { payload: RemediationTrackerTasksPageType; type: string }
    ) => {
      return {
        ...state,
        SelectedRemediationTrackerTask:
          action.payload.SelectedRemediationTrackerTask,
        SelectedApproveRejectRemediationTrackerTaskIdList:
          action.payload.SelectedApproveRejectRemediationTrackerTaskIdList,
        SelectedRemediationTrackerTaskIdList:
          action.payload.SelectedRemediationTrackerTaskIdList,
      };
    },
    setSelectAllRemediationTrackerTask: (
      state: RemediationTrackerTasksPageType,
      action: { payload: boolean; type: string }
    ) => {
      let selectedRemediationTrackerTask: Array<RemediationTrackerTaskDTO> = [];
      let selectedRemediationTrackerTaskIdList: Array<number> = [];
      let selectedApproveRejectRemediationTrackerTaskIdList: Array<number> = [];

      if (action.payload) {
        const remediationTrackerTasksList: Array<RemediationTrackerTaskDTO> =
          state.Data.Data?.filter(
            (filter) =>
              !filter?.IsAutoClosed ||
              filter?.StatusNavigation?.Id !==
                EnumRemediationTrackerTaskStatus.Closed
          );

        selectedRemediationTrackerTask = [
          ...state.SelectedRemediationTrackerTask,
          ...remediationTrackerTasksList
            .filter(
              (filter: RemediationTrackerTaskDTO) =>
                !state.SelectedRemediationTrackerTask?.some(
                  (some) =>
                    filter.Id === some.Id &&
                    filter.InspectionQuestionId === some.InspectionQuestionId
                )
            )
            .map((item: RemediationTrackerTaskDTO) => {
              return {
                ...item,
                TaskIsChecked: action.payload,
              };
            }),
        ];

        // remediationTrackerTasksList?.filter((filter) => !filter.IsMergeTask);

        selectedRemediationTrackerTask?.map((task) => {
          selectedRemediationTrackerTaskIdList.push(
            ...(task.FilteredRemediationTrackerTaskIdList ?? [])
          );

          selectedApproveRejectRemediationTrackerTaskIdList.push(
            ...(task.ApproveOrRejectRemediationTrackerTaskIdList ?? [])
          );
        });
      } else {
        selectedRemediationTrackerTask =
          state.SelectedRemediationTrackerTask?.filter(
            (filter) =>
              !state.Data?.Data?.some(
                (some) =>
                  filter.Id === some.Id &&
                  filter.InspectionQuestionId === some.InspectionQuestionId
              )
          );

        selectedRemediationTrackerTask?.map((task) => {
          selectedRemediationTrackerTaskIdList.push(
            ...(task.FilteredRemediationTrackerTaskIdList ?? [])
          );

          selectedApproveRejectRemediationTrackerTaskIdList.push(
            ...(task.ApproveOrRejectRemediationTrackerTaskIdList ?? [])
          );
        });
      }

      return {
        ...state,
        Data: {
          ...state.Data,
          Data: state.Data.Data?.map((task: RemediationTrackerTaskDTO) => {
            return {
              ...task,
              TaskIsChecked:
                (!task?.IsAutoClosed ||
                  task?.StatusNavigation?.Id !==
                    EnumRemediationTrackerTaskStatus.Closed) &&
                action.payload,
              RemediationTrackerTaskQuestions:
                task.RemediationTrackerTaskQuestions &&
                task.RemediationTrackerTaskQuestions?.length > 0
                  ? [
                      ...task.RemediationTrackerTaskQuestions.map(
                        (childTask) => {
                          return {
                            ...childTask,
                            RemediationTrackerTask: {
                              ...childTask.RemediationTrackerTask,
                              TaskIsChecked:
                                !childTask.RemediationTrackerTask
                                  .IsAutoClosed &&
                                childTask.RemediationTrackerTask
                                  .StatusNavigation.Id !==
                                  EnumRemediationTrackerTaskStatus.Closed &&
                                action.payload,
                            },
                          };
                        }
                      ),
                    ]
                  : [],
            };
          }),
        },
        SelectedRemediationTrackerTask: selectedRemediationTrackerTask,
        SelectedApproveRejectRemediationTrackerTaskIdList:
          selectedApproveRejectRemediationTrackerTaskIdList,
        SelectedRemediationTrackerTaskIdList:
          selectedRemediationTrackerTaskIdList,
        ClearExpandedRows: false,
      };
    },
    setEnabledCreateMergeTaskButtonState: (
      state: RemediationTrackerTasksPageType
      //action: { payload: boolean; type: string }
    ) => {
      // check is enable merge task between different Locations And Inspection
      let selectedTaskObjectIdList = state.SelectedRemediationTrackerTask.map(
        (task) => task.Id
      );
      let enableCreateMergeTask =
        state.SelectedRemediationTrackerTask?.length > 1 &&
        state.SelectedRemediationTrackerTask.every(
          (some) =>
            !some.IsMergeTask && some.RemediationTrackerTaskIdList?.length === 1
        ) &&
        state.SelectedRemediationTrackerTaskIdList.every((id) =>
          selectedTaskObjectIdList.includes(id)
        );

      if (
        !state.MergeTaskBetweenMultipleLocationsAndInspection &&
        enableCreateMergeTask
      ) {
        // check task is same location & inspection
        enableCreateMergeTask = state.SelectedRemediationTrackerTask?.every(
          (some, index, array) => {
            if (index === 0) {
              return true;
            } else {
              return (
                some.LocationId === array[index - 1].LocationId &&
                some.InspectionId === array[index - 1].InspectionId
              );
            }
          }
        );
      }

      return {
        ...state,
        IsEnableCreateMergeTask: enableCreateMergeTask,
      };
    },
    resetSelectedRemediationTrackerTask: (
      state: RemediationTrackerTasksPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        Data: {
          ...state.Data,
          Data: state.Data.Data?.map((task: RemediationTrackerTaskDTO) => {
            return {
              ...task,
              TaskIsChecked: false,
              RemediationTrackerTaskQuestions:
                task.RemediationTrackerTaskQuestions &&
                task.RemediationTrackerTaskQuestions?.length > 0
                  ? [
                      ...task.RemediationTrackerTaskQuestions.map(
                        (childTask) => {
                          return {
                            ...childTask,
                            RemediationTrackerTask: {
                              ...childTask.RemediationTrackerTask,
                              TaskIsChecked: false,
                            },
                          };
                        }
                      ),
                    ]
                  : [],
            };
          }),
        },
        SelectedRemediationTrackerTask: [],
        SelectedApproveRejectRemediationTrackerTaskIdList: [],
        SelectedRemediationTrackerTaskIdList: [],
        ClearExpandedRows: true,
      };
    },
    setRemediationTrackerTaskColumnOptions: (
      state: RemediationTrackerTasksPageType,
      action: { payload: Array<ListColumn>; type: string }
    ) => {
      return {
        ...state,
        ColumnOptions: action.payload,
      };
    },
    resetRemediationTrackerTaskList: () => {
      return initialState;
    },
  },
});

export const remediationTrackerTasksReducerActions =
  remediationTrackerTaskSlice.actions;
export const remediationTrackerTasksReducer =
  remediationTrackerTaskSlice.reducer;
