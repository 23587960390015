import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import dayjs from "dayjs";
import store from "../../../core/store/index";
import { GET_ALL_INSPECTION_EXCEL_REPORT_DATA } from "./AllInspectionSummaryReport.actions";
import getAllInspectionReport from "./AllInspectionSummaryReport.api";
import { allInspectionReportReducerActions } from "./AllInspectionSummaryReport.reducer";

const excelWorkerAllInspectionSummary: Worker = new Worker(
  new URL("./ExcelWorkerAllInspectionSummary.ts", import.meta.url)
);

excelWorkerAllInspectionSummary.onmessage = (event) => {
  const excelData = event.data.buffer;

  //Handle the Excel data here, for example, you can save it as a file
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  let downloadFileName = `All Inspections Summary Report - ${dayjs(new Date()).format(
    "DD-MMM-YYYY HH_mm"
  )}`;
  const extension = getFileExtensionFromMimeType(blob.type);
  if (extension !== null && extension !== "") {
    if (!downloadFileName.endsWith(extension)) {
      downloadFileName += extension;
    }
  }
  // Create a download link for the Excel file and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = downloadFileName;
  a.click();

  window.URL.revokeObjectURL(url);
  store.dispatch(
    allInspectionReportReducerActions.setDownloadStatus(false)
  );
  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "Success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );

  return () => {
    excelWorkerAllInspectionSummary.terminate();
  };
};

const allInspectionReportWatcher = function* () {
  yield takeEvery(
    GET_ALL_INSPECTION_EXCEL_REPORT_DATA,
    function* (action: AnyAction): any {
      const data = action.payload as ReportViewerType;

      try {
        const result: ActionResponseType = yield call(() =>
            getAllInspectionReport(data)
        );
        if (result.IsSuccess) {
          const workerObj = {
            data: result.Data,
          };
          excelWorkerAllInspectionSummary.postMessage(workerObj);
        } else {
          store.dispatch(
            allInspectionReportReducerActions.setDownloadStatus(false)
          );
          yield put(
            openPopup({
              Open: true,
              BodyText:
                "Sorry, An error occured while generating the excel report!",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick() {},
            } as PopupProps)
          );
        }
      } catch {
        store.dispatch(
          allInspectionReportReducerActions.setDownloadStatus(false)
        );
        yield put(
          openPopup({
            Open: true,
            BodyText:
              "Sorry, An error occured while generating the excel report!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
      }
    }
  );
};

const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};
const allInspectionReportSaga = function* () {
  yield all([allInspectionReportWatcher()]);
};

export default allInspectionReportSaga;
