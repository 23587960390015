import { Button, Grid, IconButton, InputBase, Paper, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import {
  ReferencePageParametersType,
  ReferencePageType,
} from "./ReferencePage.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  deleteStandardReference,
  getStandardReferenceList,
  saveStandardReference,
  updateStandardReference,
} from "./ReferencePage.action";
import HPRDataGrid from "../../../components/common/HPRDataGrid/HRPDataGrid";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StandardReferenceDTO,
} from "../../../common/types/common.dto.types";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  setPageMode,
  setReferenceModalOpen,
  setStandardReference,
  setStandardReferenceInitState,
  setStandardReferenceSearchParameter,
} from "./ReferencePage.reducer";
import {
  AddCircle,
  ArrowLeft2,
  ArrowRight2,
  CardSlash,
  CloseCircle,
  Edit,
  SearchNormal1,
} from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { StandardPageParametersType } from "../StandardPage/StandardPage.types";
import { useNavigate } from "react-router-dom";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import HPRSplitButton from "../../../components/common/HPRSplitButton/HPRSplitButton";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import SimpleReactValidator from "simple-react-validator";
import dayjs from "dayjs";
import {
  HPRDataGridToolbarProps,
  HPRGridColDef,
} from "../../../components/common/HPRDataGrid/HPRDataGrid.types";

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {} as PaginationDTO,
  StandaradReference: {
    Name: "",
    Url: "",
  } as StandardReferenceDTO,
  StandardReferenceSearchParameter: {
    StandardReferenceSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardReferenceDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  ReferenceModalOpen: false,
  Validator: new SimpleReactValidator({
    messages: {
      default: "This field is required",
    },
  }),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as ReferencePageType;

const ReferencePage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for StandardPageType with initialState values
  const [standardReferencePageState, setstandardReferencePageState] =
    useState(initialState);

  // pass parameters to page
  // use for page navigation
  const navigate = useNavigate();
  const location = useLocation();
  const parameter: ReferencePageParametersType = location.state;

  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);

  const dispatch = useAppDispatch();
  const standardReferenceState = useAppSelector(
    (state) => state.standardreferenceReducer
  );

  //open and close Modal
  const handleOpen = () => {
    dispatch(setReferenceModalOpen(true));
  };
  const handleClose = () => {
    dispatch(setReferenceModalOpen(false));
  };

  //=========================================================
  const columns: HPRGridColDef[] = [
    {
      field: "Id",
      headerName: "Id",
      hideColumn: true,
      hideable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: "Name-1",
      headerName: "Reference",
      flex: 8,
      valueGetter: (params) => params.row?.Name,
      value: "",
    },
    {
      field: "Url-2",
      headerName: "Link to support document",
      flex: 8,
      valueGetter: (params) => params.row?.Url,
      value: "",
    },
    {
      field: "ModifiedBy-3",
      headerName: "Last modified by",
      flex: 6,
      valueGetter: (params) => params.row?.ModifiedBy,
      value: "",
    },
    {
      field: "ModifiedDate-4",
      headerName: "Last modified date",
      flex: 6,
      valueGetter: (params) =>
        dayjs(params.row?.ModifiedDate).format("DD-MMM-YYYY HH:mm"),
      value: "",
    },
    {
      field: " ",
      flex: 1,
      sortable: false,
      filterable: false,
      hide: parameter?.StatusId === EnumStatus.Deactivated,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking

          dispatch(
            setStandardReference({
              Id: params.row?.Id,
              Name: params.row?.Name,
              Url: params.row?.Url,
              StandardId: parameter?.StandardId,
            } as StandardReferenceDTO)
          );
          setstandardReferencePageState((values) => {
            return {
              ...values,
              StandaradReference: {
                ...values.StandaradReference,
                Name: params.row?.Name,
                Url: params.row?.Url,
                Id: params.row?.Id,
              },
            };
          });
          onClickViewEditBtn();
        };

        return (
          <>
            <Button
              className="standards-icon-forward align-right flex-valign-middle cursor-pointer grid-icon-btn"
              onClick={onClick}
            >
              <ArrowRight2 size="32" variant="Outline" />
            </Button>
          </>
        );
      },
    },
  ];

  //Page onload
  useEffect(() => {
    if (parameter?.StandardId > 0) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      dispatch(
        setStandardReferenceInitState({
          ...standardReferenceState,
          StandaradReference: {
            ...standardReferenceState.StandaradReference,
            StandardId: parameter.StandardId,
          },
          StandardReferenceSearchParameter: {
            ...standardReferenceState.StandardReferenceSearchParameter,
            StandardReferenceSearchParams: {
              ...standardReferenceState.StandardReferenceSearchParameter
                .StandardReferenceSearchParams,
              StandardId: parameter.StandardId,
              Name: standardReferencePageState.StandardReferenceSearchParameter
                .StandardReferenceSearchParams.Name,
            },
          },
          Data: {} as PaginationDTO,
        })
      );

      dispatch(
        getStandardReferenceList({
          ...standardReferencePageState.StandardReferenceSearchParameter,
          StandardReferenceSearchParams: {
            ...standardReferencePageState.StandardReferenceSearchParameter
              .StandardReferenceSearchParams,
            StandardId: parameter.StandardId,
          },
        })
      );

      setstandardReferencePageState((values) => {
        return {
          ...values,
          StandaradReference: {
            ...values.StandaradReference,
            StandardId: parameter.StandardId,
          },
          StandardReferenceSearchParameter: {
            ...values.StandardReferenceSearchParameter,
            StandardReferenceSearchParams: {
              ...values.StandardReferenceSearchParameter
                .StandardReferenceSearchParams,
              StandardId: parameter.StandardId,
            },
          },
        };
      });
    }
  }, []);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;

    setstandardReferencePageState((values) => {
      return {
        ...values,
        Data: {
          ...values.Data,
          Data: standardReferenceState.Data.Data,
          TotalRecordsCount: standardReferenceState.Data.TotalRecordsCount,
          SelectedPage: standardReferenceState.Data.SelectedPage,
        },
        StandaradReference: {
          Id: standardReferenceState.StandaradReference.Id,
          Name: standardReferenceState.StandaradReference.Name,
          Url: standardReferenceState.StandaradReference.Url,
          StandardId: parameter.StandardId,
          Version: standardReferenceState.StandaradReference.Version,
          Status: standardReferenceState.StandaradReference.Status,
        } as StandardReferenceDTO,
        StandardReferenceSearchParameter: {
          ...values.StandardReferenceSearchParameter,
          SelectedPage: standardReferenceState.Data.SelectedPage,
          SortByNavigation:
            standardReferenceState.StandardReferenceSearchParameter
              .SortByNavigation,
          GridPageSize:
            standardReferenceState.StandardReferenceSearchParameter
              .GridPageSize,
        },
        ReferenceModalOpen: standardReferenceState.ReferenceModalOpen,
        PageMode: standardReferenceState.PageMode,
      };
    });
  }, [standardReferenceState]);

  //================================ Search ============================================================

  const onChangeSearchName = (event: ChangeEvent<HTMLInputElement>) => {
    setstandardReferencePageState((values) => {
      return {
        ...values,
        StandardReferenceSearchParameter: {
          ...values.StandardReferenceSearchParameter,
          StandardReferenceSearchParams: {
            ...values.StandardReferenceSearchParameter
              .StandardReferenceSearchParams,
            Name: event.target.value,
          },
        },
      };
    });

    if (!event.target.value) {
      dispatch(
        getStandardReferenceList({
          ...standardReferencePageState.StandardReferenceSearchParameter,
          StandardReferenceSearchParams: {
            ...standardReferencePageState.StandardReferenceSearchParameter
              .StandardReferenceSearchParams,
            Name: "",
          },
        })
      );
    }
  };

  const onChangeStandardReferenceSearchParameter = (
    isAdvanceSearch: boolean,
    selectedPage: number
  ) => {
    dispatch(
      setStandardReferenceSearchParameter({
        ...standardReferencePageState.StandardReferenceSearchParameter,
        IsAdvanceSearch: isAdvanceSearch,
        SelectedPage: selectedPage,
      })
    );

    dispatch(
      getStandardReferenceList({
        ...standardReferencePageState.StandardReferenceSearchParameter,
        IsAdvanceSearch: isAdvanceSearch,
        SelectedPage: selectedPage,
      })
    );
  };

  const onKeyPressSearchName = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (
        standardReferencePageState.StandardReferenceSearchParameter
          .StandardReferenceSearchParams.Name !==
        standardReferenceState.StandardReferenceSearchParameter
          .StandardReferenceSearchParams.Name
      ) {
        onChangeStandardReferenceSearchParameter(
          standardReferencePageState?.StandardReferenceSearchParameter
            ?.IsAdvanceSearch,
          1
        );
      }
    }
  };

  function serachBtnClick(IsAdvanceSearch: boolean) {
    onChangeStandardReferenceSearchParameter(IsAdvanceSearch, 1);
  }

  const onPaginationCallBack = (newPage: number) => {
    if (
      standardReferencePageState.StandardReferenceSearchParameter
        .SelectedPage !== newPage
    ) {
      onChangeStandardReferenceSearchParameter(
        standardReferencePageState?.StandardReferenceSearchParameter
          ?.IsAdvanceSearch,
        newPage
      );
    }
  };

  const onSortByNavigationCallBack = (
    selectedSortByNavigation: SortDropDownDTO
  ) => {
    dispatch(
      setStandardReferenceSearchParameter({
        ...standardReferencePageState.StandardReferenceSearchParameter,
        StandardReferenceSearchParams: {
          ...standardReferencePageState.StandardReferenceSearchParameter
            .StandardReferenceSearchParams,
        },
        SortByNavigation: selectedSortByNavigation,
        SelectedPage: 1,
      })
    );

    dispatch(
      getStandardReferenceList({
        ...standardReferencePageState.StandardReferenceSearchParameter,
        StandardReferenceSearchParams: {
          ...standardReferencePageState.StandardReferenceSearchParameter
            .StandardReferenceSearchParams,
        },
        SortByNavigation: selectedSortByNavigation,
        SelectedPage: 1,
      })
    );
  };

  //================================ End Search ============================================================

  const rowClick = (rowData: any) => {
    setstandardReferencePageState((values) => {
      return {
        ...values,
        StandaradReference: {
          ...values.StandaradReference,
          Name: rowData?.Name,
          Url: rowData?.Url,
          Id: rowData?.Id,
        },
        PageMode: {
          ...values.PageMode,
          IsShowAddBtn: true,
          IsShowEditBtn: false,
          IsShowDeleteBtn: false,
          IsShowCancelBtn: false,
          IsShowSaveBtn: false,
          IsShowUpdateBtn: false,
        },
      };
    });
  };

  //-----Button functions-----//

  const onClickCancelBtn = () => {
    //hide validation message
    standardReferencePageState.Validator.hideMessages();
    if (standardReferencePageState.PageMode.IsShowEditBtn) {
      dispatch(
        setStandardReference({
          Id: 0,
          Name: "",
          Url: "",
          StandardId: parameter?.StandardId,
          Version: 1,
          Status: EnumStatus.Published,
        } as StandardReferenceDTO)
      );

      dispatch(setPageMode(EnumPageMode.Default));
      handleClose();
    } else if (standardReferencePageState.PageMode.IsShowSaveBtn) {
      if (
        (standardReferencePageState.StandaradReference.Name === null ||
          standardReferencePageState.StandaradReference.Name?.match(/^ *$/) !==
            null) &&
        (standardReferencePageState.StandaradReference.Url === null ||
          standardReferencePageState.StandaradReference.Url?.match(/^ *$/) !==
            null)
      ) {
        dispatch(
          setStandardReference({
            Id: 0,
            Name: "",
            Url: "",
            StandardId: parameter?.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardReferenceDTO)
        );
        dispatch(setPageMode(EnumPageMode.Default));
        handleClose();
      } else {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                setStandardReference({
                  Id: 0,
                  Name: "",
                  StandardId: parameter?.StandardId,
                  Version: 1,
                  Status: EnumStatus.Published,
                } as StandardReferenceDTO)
              );
              dispatch(setPageMode(EnumPageMode.Default));
              handleClose();
            },
          } as PopupProps)
        );
      }
    } else {
      if (
        standardReferencePageState.StandaradReference.Name !==
          standardReferenceState.StandaradReference.Name ||
        standardReferencePageState.StandaradReference.Url !==
          standardReferenceState.StandaradReference.Url
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setstandardReferencePageState((values) => {
                return {
                  ...values,
                  StandaradReference: {
                    Id: standardReferenceState.StandaradReference.Id,
                    Name: standardReferenceState.StandaradReference.Name,
                    Url: standardReferenceState.StandaradReference.Url,
                    StandardId: parameter?.StandardId,
                    Version: 1,
                    Status: standardReferenceState.StandaradReference.Status,
                  } as StandardReferenceDTO,
                };
              });

              dispatch(setPageMode(EnumPageMode.ViewMode));
            },
          } as PopupProps)
        );
      } else {
        dispatch(setPageMode(EnumPageMode.ViewMode));
      }
    }
  };

  const CloseButtonClick = () => {
    dispatch(
      setStandardReference({
        Id: 0,
        Name: "",
        Url: "",
        StandardId: parameter?.StandardId,
        Version: 1,
        Status: EnumStatus.Published,
      } as StandardReferenceDTO)
    );
    dispatch(setPageMode(EnumPageMode.Default));
    handleClose();
  };

  const onClickViewEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.ViewMode));
    handleOpen();
  };

  const SaveButtonClick = () => {
    SaveReference(EnumButtonModeType.Save);
  };

  const onClickSaveAndCloseBtn = () => {
    SaveReference(EnumButtonModeType.SaveAndClose);
  };

  const onClickSaveAndContinueBtn = () => {
    SaveReference(EnumButtonModeType.SaveAndContinue);
  };

  //save Reference
  const SaveReference = (buttonModeType: EnumButtonModeType) => {
    //check form valid
    if (IsPageValid()) {
      dispatch(
        saveStandardReference({
          ...standardReferencePageState,
          ButtonModeType: buttonModeType,
        })
      );
    }
  };

  //Update Reference
  const UpdateReference = (buttonModeType: EnumButtonModeType) => {
    //check form valid
    if (IsPageValid()) {
      dispatch(
        updateStandardReference({
          ...standardReferencePageState,
          ButtonModeType: buttonModeType,
        })
      );
    }
  };

  const IsPageValid = (): boolean => {
    //check form valid
    let isValid = standardReferencePageState.Validator.allValid();
    if (!isValid) {
      setstandardReferencePageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !standardReferencePageState.ShowErrorMsg,
        };
      });

      //show validation message
      standardReferencePageState.Validator.showMessages();
    } else {
      //hide validation message
      standardReferencePageState.Validator.hideMessages();
    }

    return isValid;
  };

  //-----Button functions-----//
  const UpdateButtonClick = () => {
    UpdateReference(EnumButtonModeType.Update);
  };

  const onClickAddBtn = () => {
    dispatch(setPageMode(EnumPageMode.AddMode));
    handleOpen();
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
  };

  const onClickUpdateAndCloseBtn = () => {
    UpdateReference(EnumButtonModeType.UpdateAndClose);
  };

  const onClickDeleteBtn = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to deactivate?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          if (standardReferencePageState.StandaradReference?.Id > 0) {
            dispatch(deleteStandardReference(standardReferencePageState));
            //hide validation message
            standardReferencePageState.Validator.hideMessages();
          }
        },
      } as PopupProps)
    );
  };

  const OnReferenceTextChange = (name: string, value: any) => {
    setstandardReferencePageState((values) => {
      return {
        ...values,
        StandaradReference: {
          ...values.StandaradReference,
          Name: value,
        },
      };
    });
  };

  const OnReferenceUrlTextChange = (url: string, value: any) => {
    setstandardReferencePageState((values) => {
      return {
        ...values,
        StandaradReference: {
          ...values.StandaradReference,
          Url: value,
        },
      };
    });
  };

  // modal popup
  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  //------------------------------------

  return (
    <>
      {/* <Grid container columns={12} className="global-header-wrap"> */}
      {/* <Grid item lg={12} md={12} sm={12} xs={12} className="">
          <h1>References</h1>
        </Grid> */}
      {/* </Grid> */}
      <div className="content-section-card">
        <Grid container>
          {/* Button bar */}

          <div className="flex--container width100-p">
            <div className="flex__item--inherit pr-10">
              <h2>
                <Button
                  aria-label="back"
                  className="sub-head-back-btn back"
                  onClick={() => {
                    navigate("/standard", {
                      state: {
                        StandardId: parameter?.StandardId,
                      } as StandardPageParametersType,
                    });
                  }}
                  title="Back to home page"
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>{" "}
              </h2>
            </div>
            <div className="global-header-wrap">
              <h1 className="page_title">References</h1>
            </div>
            <div className="flex__item search-bar--small ml-10 mr-10">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  value={
                    standardReferencePageState.StandardReferenceSearchParameter
                      .StandardReferenceSearchParams?.Name
                  }
                  onChange={onChangeSearchName}
                  onKeyPress={onKeyPressSearchName}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by Reference"
                  inputProps={{ "aria-label": "Reference" }}
                  type="search"
                />
                <IconButton
                  onClick={() => {
                    serachBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                >
                  <SearchNormal1
                    variant="Outline"
                    className="ico-secondary"
                    size={18}
                  />
                </IconButton>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    serachBtnClick(true);
                  }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <Filter variant="Outline" className="ico-secondary" />
                </IconButton> */}
                {/* <IconButton
                  onClick={() => {
                    serachBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    serachBtnClick(true);
                  }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <FilterAltOutlinedIcon />
                </IconButton> */}
              </Paper>
            </div>
            <Button
              className="primary-btn-small"
              size="small"
              disableElevation
              variant="outlined"
              startIcon={<AddCircle variant="Outline" />}
              onClick={onClickAddBtn}
              style={{
                visibility:
                  standardReferencePageState?.PageMode?.IsShowAddBtn &&
                  parameter?.StatusId !== EnumStatus.Deactivated
                    ? "visible"
                    : "hidden",
              }}
            >
              Add
            </Button>
          </div>
          {/* <IconButton
                  aria-label="back"
                  className="sub-head-back-btn"
                  onClick={() => {
                    navigate("/standard", {
                      state: {
                        StandardId: parameter?.StandardId,
                      } as StandardPageParametersType,
                    });
                  }}
                  title="Back to Standard"
                >
                  <ArrowLeft2 size="24" variant="Outline" />
                </IconButton> */}

          {/* <h2>Standard: {parameter?.StandardName} </h2> */}

          <Grid container className="mb-15">
            {/* <Grid item xs={12} className="search-bar--small">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  value={
                    standardReferencePageState.StandardReferenceSearchParameter
                      .StandardReferenceSearchParams?.Name
                  }
                  onChange={onChangeSearchName}
                  onKeyPress={onKeyPressSearchName}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by Reference"
                  inputProps={{ "aria-label": "Reference" }}
                  type="search"
                />
                <IconButton
                  onClick={() => {
                    serachBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                >
                  <SearchNormal1 variant="Outline" className="ico-secondary" />
                </IconButton> */}
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    serachBtnClick(true);
                  }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <Filter variant="Outline" className="ico-secondary" />
                </IconButton> */}
            {/* <IconButton
                  onClick={() => {
                    serachBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    serachBtnClick(true);
                  }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <FilterAltOutlinedIcon />
                </IconButton> */}
            {/* </Paper>
            </Grid> */}
            {/* <Grid item xs={12} className="mb-15">
              <div className="records-found">
                {standardReferencePageState.Data.TotalRecordsCount ===
                  undefined ||
                standardReferencePageState.Data.TotalRecordsCount === 0
                  ? "No records found"
                  : standardReferencePageState.Data.TotalRecordsCount === 1
                  ? "1 record found"
                  : standardReferencePageState.Data.TotalRecordsCount +
                    " records found"}
              </div>
            </Grid> */}
          </Grid>

          {/* Items Found  End */}
          <Modal
            className="modal-outter sub-form-section"
            open={standardReferencePageState.ReferenceModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="modal-header">
                <h1>
                  <span>Reference</span>
                  <span>
                    {standardReferencePageState.PageMode.IsShowSaveBtn ? (
                      <>
                        {/* <Button
                        className="primary-btn"
                        variant="outlined"
                        startIcon={<DirectboxReceive variant="Outline" />}
                        disableElevation
                        onClick={SaveButtonClick}
                      >
                        Save
                      </Button> */}

                        <HPRSplitButton
                          Name="SystemSave"
                          ButtonOptions={[
                            {
                              ButtonId: "0",
                              ButtonName: "Save",
                              onButtonClickCallBack: () => {
                                SaveButtonClick();
                              },
                            },
                            {
                              ButtonId: "1",
                              ButtonName: "Save & Close",
                              onButtonClickCallBack: () => {
                                onClickSaveAndCloseBtn();
                              },
                            },
                            {
                              ButtonId: "2",
                              ButtonName: "Save & Continue",
                              onButtonClickCallBack: () => {
                                onClickSaveAndContinueBtn();
                              },
                            },
                          ]}
                        ></HPRSplitButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardReferencePageState.PageMode.IsShowUpdateBtn ? (
                      <>
                        {/* <Button
                        className="primary-btn"
                        variant="outlined"
                        disableElevation
                        onClick={EditButtonClick}
                      >
                        Update
                      </Button> */}
                        <HPRSplitButton
                          Name="SystemUpdateBtn"
                          ButtonOptions={[
                            {
                              ButtonId: "0",
                              ButtonName: "Update & Close",
                              onButtonClickCallBack: () => {
                                onClickUpdateAndCloseBtn();
                              },
                            },
                            {
                              ButtonId: "1",
                              ButtonName: "Update",
                              onButtonClickCallBack: () => {
                                UpdateButtonClick();
                              },
                            },
                          ]}
                        ></HPRSplitButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardReferencePageState.PageMode.IsShowEditBtn ? (
                      <>
                        <Button
                          className="primary-btn-small"
                          size="small"
                          variant="outlined"
                          disableElevation
                          onClick={onClickEditBtn}
                          startIcon={<Edit variant="Outline" />}
                        >
                          Edit
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardReferencePageState.PageMode.IsShowDeleteBtn ? (
                      <>
                        <Button
                          className="primary-btn-small"
                          size="small"
                          variant="outlined"
                          disableElevation
                          startIcon={<CardSlash variant="Outline" />}
                          onClick={onClickDeleteBtn}
                        >
                          Deactivate
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardReferencePageState.PageMode.IsShowCancelBtn ? (
                      <>
                        <Button
                          className="secondary-btn-small"
                          size="small"
                          variant="outlined"
                          disableElevation
                          startIcon={<CloseCircle variant="Outline" />}
                          onClick={onClickCancelBtn}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardReferencePageState.PageMode.IsShowCloseBtn && (
                      <Button
                        className="secondary-btn-small"
                        size="small"
                        variant="outlined"
                        disableElevation
                        onClick={CloseButtonClick}
                        startIcon={<CloseCircle variant="Outline" />}
                      >
                        Close
                      </Button>
                    )}
                  </span>
                </h1>
              </div>
              <div className="modal-content">
                <Grid container columns={12}>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={12}
                    className="m-auto"
                  >
                    <div className="form-group">
                      <HPRTextBox
                        Id=""
                        Label="Reference"
                        Name="reference"
                        Value={
                          standardReferencePageState?.StandaradReference.Name
                        }
                        Type={EnumTextBoxType.Text}
                        Disabled={standardReferencePageState.PageMode.IsDisable}
                        onTextBoxChange={OnReferenceTextChange}
                        Validator={standardReferencePageState.Validator}
                        Rules={"required"}
                        IsEnableValidator={true}
                        AutoFocus={
                          !standardReferencePageState.PageMode.IsDisable
                        }
                        Required={true}
                      ></HPRTextBox>
                    </div>
                    <div className="form-group">
                      <HPRTextBox
                        Id=""
                        Label="Link to support document"
                        Name="LinkToSupportDocument"
                        Value={
                          standardReferencePageState?.StandaradReference.Url
                        }
                        Type={EnumTextBoxType.Text}
                        Disabled={standardReferencePageState.PageMode.IsDisable}
                        onTextBoxChange={OnReferenceUrlTextChange}
                        // Validator={standardReferencePageState.Validator}
                        // Rules={"required"}
                        // IsEnableValidator={true}
                        // Required={true}
                      ></HPRTextBox>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Modal>

          <div className="HPRDataGrid-wrapper grid-border-b-0">
            <HPRDataGrid
              Columns={columns}
              Data={standardReferencePageState?.Data?.Data}
              TotalRecordsCount={
                standardReferencePageState?.Data?.TotalRecordsCount
              }
              AutoHeight={true}
              onPaginationChangeCallBack={onPaginationCallBack}
              SelectedPage={standardReferencePageState.Data?.SelectedPage}
              HPRDataGridToolbarProps={
                {
                  ShowTotalRecordsCount: true,
                } as HPRDataGridToolbarProps
              }
              PageSize={
                standardReferencePageState?.StandardReferenceSearchParameter
                  ?.GridPageSize ??
                EnumGridDataShowRowCount.GridDataShowRowCount
              }
              // MenuFunctions={{
              //   onChangeSortByNavigation: onSortByNavigationCallBack,
              // }}
              ShowCustomePagination
            ></HPRDataGrid>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ReferencePage;
