import { Button, Tooltip } from "@mui/material";
import {
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { ViewRemediationTrackerTaskListToolBarProps } from "./ViewRemediationTrackerTaskList.types";
import ViewRemediationTrackerTaskListColumnToolbar from "./ViewRemediationTrackerTaskListColumnToolbar";
import ViewRemediationTrackerTaskListReportToolbar from "./ViewRemediationTrackerTaskListReportToolbar";
import { Data, Slash, TickCircle } from "iconsax-react";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { useAppSelector } from "../../../core/hooks";
import { CheckFunctionRights } from "../../../common/functions/common.functions";

const ViewRemediationTrackerTaskTableToolBar = (
  props: ViewRemediationTrackerTaskListToolBarProps
) => {
  const authState = useAppSelector((state) => state.authReducer);
  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.RemediationTrackerTasks,
      functionId,
      functionTypeId
    );
  };

  return (
    <div
      className="flex--container action-btn-table"
      style={{ height: "32px" }}
      /*  ref={props.ToolBarRef} */
    >
      {!props?.ViewRemediationTrackerTaskTableToolBarButtonProps
        ?.HideCreateMergeTaskButton &&
        checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTasksPage_AddOrMergedTask,
          EnumSystemFunctionTypes.ButtonAccess
        ) &&
        !props.ViewRemediationTrackerTaskTableToolBarButtonProps
          ?.isFullScreen && (
          <Button
            className="primary-btn-small"
            disableElevation
            size="small"
            variant="outlined"
            startIcon={<Data variant="Outline" className="rotate" size={20} />}
            onClick={() => {
              props?.ViewRemediationTrackerTaskTableToolBarButtonProps?.onClickCreateMergeTaskButton();
            }}
            disabled={
              props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                ?.DisabledCreateMergeTaskButton
            }
          >
            Create Merge Task
          </Button>
        )}

      {!props?.ViewRemediationTrackerTaskTableToolBarButtonProps
        ?.HideRejectButton &&
        (checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTaskPage_Reject_For_SME_Review,
          EnumSystemFunctionTypes.ButtonAccess
        ) ||
          checkSystemFunctionRight(
            EnumSystemFunctions.RemTrackerTaskPage_Reject_For_Site_Review,
            EnumSystemFunctionTypes.ButtonAccess
          ) ||
          checkSystemFunctionRight(
            EnumSystemFunctions.RemTrackerTaskPage_Reject_For_Closure,
            EnumSystemFunctionTypes.ButtonAccess
          )) && (
          <Tooltip title="Reject selected task(s)">
            <Button
              className="primary-btn-small"
              disableElevation
              size="small"
              variant="outlined"
              startIcon={<Slash variant="Outline" size={20} />}
              onClick={
                props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                  ?.onClickRejectButton
              }
              disabled={
                props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                  ?.DisabledRejectButton
              }
            >
              Reject
            </Button>
          </Tooltip>
        )}

      {!props?.ViewRemediationTrackerTaskTableToolBarButtonProps
        ?.HideApproveButton &&
        (checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTaskPage_Approval_For_SME_Review,
          EnumSystemFunctionTypes.ButtonAccess
        ) ||
          checkSystemFunctionRight(
            EnumSystemFunctions.RemTrackerTaskPage_Approval_For_Site_Review,
            EnumSystemFunctionTypes.ButtonAccess
          ) ||
          checkSystemFunctionRight(
            EnumSystemFunctions.RemTrackerTaskPage_Approval_For_Closure,
            EnumSystemFunctionTypes.ButtonAccess
          )) && (
          <Tooltip title="Approve selected task(s)">
            <Button
              className="primary-btn-small"
              disableElevation
              size="small"
              variant="outlined"
              startIcon={<TickCircle variant="Outline" size={20} />}
              onClick={
                props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                  ?.onClickApproveButton
              }
              disabled={
                props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                  ?.DisabledApproveButton
              }
            >
              Approve
            </Button>
          </Tooltip>
        )}

      {props?.ViewRemediationTrackerTaskListToolbarReportProps?.ShowReport &&
        !props.ViewRemediationTrackerTaskTableToolBarButtonProps
          ?.isFullScreen && (
          <ViewRemediationTrackerTaskListReportToolbar
            ShowReport={
              props?.ViewRemediationTrackerTaskListToolbarReportProps
                ?.ShowReport
            }
            ReportViewer={
              {
                IsReportDataFound:
                  props.ViewRemediationTrackerTaskListToolbarReportProps
                    ?.ReportViewer?.IsReportDataFound,
                ReportType: EnumReportType.RemediationTrackerTasksReport,
                ReportSearchParameter:
                  props.ViewRemediationTrackerTaskListToolbarReportProps
                    ?.ReportViewer?.ReportSearchParameter,
              } as ReportViewerType
            }
          />
        )}

      {props?.ViewRemediationTrackerTaskListToolbarColumnProps
        ?.ShowColumnOption &&
        checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTasksPage_ColumnOptions,
          EnumSystemFunctionTypes.ButtonAccess
        ) &&
        !props.ViewRemediationTrackerTaskTableToolBarButtonProps
          ?.isFullScreen && (
          <ViewRemediationTrackerTaskListColumnToolbar
            ShowColumnOption={
              props.ViewRemediationTrackerTaskListToolbarColumnProps
                ?.ShowColumnOption
            }
            ListColumns={
              props.ViewRemediationTrackerTaskListToolbarColumnProps
                ?.ListColumns
            }
            OnChangeSwitch={
              props.ViewRemediationTrackerTaskListToolbarColumnProps
                ?.OnChangeSwitch
            }
            onChangeColumnDisplayOrder={
              props.ViewRemediationTrackerTaskListToolbarColumnProps
                ?.onChangeColumnDisplayOrder
            }
            onChangeShowAllColumn={
              props.ViewRemediationTrackerTaskListToolbarColumnProps
                ?.onChangeShowAllColumn
            }
          />
        )}
    </div>
  );
};
export default ViewRemediationTrackerTaskTableToolBar;
