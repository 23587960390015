import { InspectionSetReportDownloadStatusType } from "./InspectionSetReport.type";
import {
  InspectionSetDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { useEffect, useRef, useState } from "react";
import { InspectionSetReportReducerActions } from "./InspectionSetReport.reducer";
import inspectionSetReportSagaActions from "./InspectionSetReport.action";
import { EnumReportType } from "../../../common/enums/common.enums";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { Button, CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import { DirectInbox, Document } from "iconsax-react";

const InspectionSetReportExcelDownload = (props: {
  InspectionSetId: number;
  InspectionSetName?: string;
}) => {
  const initialState = [
    {
      InspectionSetId: 0,
      IsDownloading: false,
    },
  ] as Array<InspectionSetReportDownloadStatusType>;
  const state = useAppSelector((state) => state.inspectionSetReportReducer);

  const dispatch = useAppDispatch();
  const isInProgress = useRef(false);

  const [inspectionSetReportanchorEl, setInspectionSetReportanchorEl] =
    useState<null | HTMLElement>(null);
  const inspectionSetReportOpen = Boolean(inspectionSetReportanchorEl);

  const [isReportDownloading, setIsReportDownloading] = useState(initialState);

  useEffect(() => {
    setIsReportDownloading(state.InspectionSetExcelReportStatus);
  }, [state]);

  //open and close download popup Menu Item
  const onClickReportDownload = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setInspectionSetReportanchorEl(event.currentTarget);
  };

  const handleReportDownloadClose = () => {
    setInspectionSetReportanchorEl(null);
  };

  const downloadInspectionSets = () => {
    isInProgress.current = true;
    dispatch(
      InspectionSetReportReducerActions.setExcelReportStatus({
        InspectionSetId: props.InspectionSetId,
        IsDownloading: true,
      } as InspectionSetReportDownloadStatusType)
    );
    dispatch(
      inspectionSetReportSagaActions.getInspectionSetReport({
        ReportType: EnumReportType.InspectionSetReport,
        ReportSearchParameter: {
          InspectionSetSearchParams: {
            Id: props.InspectionSetId,
            Name: props.InspectionSetName,
          } as InspectionSetDTO,
        } as SearchParameterDTO,
      } as ReportViewerType)
    );
    handleReportDownloadClose();
  };

  return (
    <>
      {/* <div
        className={`colmn-opt excel`}
        onClick={(e) => {
          e.preventDefault();
          downloadInspectionSets();
        }}
      >
        <span className="">
          <Document
            className="m-r-10"
            color="#66bb6a"
            style={{ fontSize: 20 }}
          />
        </span>
        <span>Inspection Set Question(s) Summary</span>
      </div> */}
      {/* <div
        className="flex--container exceldownload"
        onClick={onClickReportDownload}
      >
        <a className="excel flex__item--vcenter">
          <Document className="ml-5 mr-5" style={{ fontSize: 20 }} />
        </a>
        <div> Export Report(s)</div>
      </div> */}
      <Button
        className="secondary-btn-small shadow-remove"
        size="small"
        variant="outlined"
        onClick={onClickReportDownload}
        startIcon={<DirectInbox variant="Outline" />}
      >
        {/* <Download className="m-r-10" style={{ fontSize: 20 }} /> */}
        Export
      </Button>{/* remove report(s) text */}
      <Menu
        className="sort-dialog-content"
        open={inspectionSetReportOpen}
        anchorEl={inspectionSetReportanchorEl}
        onClose={handleReportDownloadClose}
      >
        <Grid display={"flex"} alignItems={"center"}>
          {isReportDownloading.map(
            (item, index) =>
              item.InspectionSetId === props.InspectionSetId &&
              item.IsDownloading && <CircularProgress key={index} size={20} />
          )}
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              downloadInspectionSets();
            }}
            disabled={isReportDownloading.some(
              (item) =>
                item.InspectionSetId === props.InspectionSetId &&
                item.IsDownloading
            )}
          >
            <div className="colmn-opt excel">
              <span className="">
                <Document
                  className="m-r-10"
                  color="#66bb6a"
                  style={{ fontSize: 20 }}
                />
              </span>
              <span>Inspection Set Question(s)</span>
            </div>
          </MenuItem>
        </Grid>
      </Menu>
    </>
  );
};

export default InspectionSetReportExcelDownload;
