//-------common imports-----//

import {
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useEffect, useRef, useState } from "react";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  SearchNormal1,
  DirectInbox,
  ArrowCircleDown,
  InfoCircle,
} from "iconsax-react";
import CloseIcon from "@mui/icons-material/Close";
import "../../layouts/main-layout/audit-view-main.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  EnumInspectionStatus,
  EnumListShowRowCountList,
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import siteInspectionsSagaActions from "./SiteInspectionsPage.action";
import { siteInspectionsPageReducerActions } from "./SiteInspectionsPage.reducer";
import {
  AssessmentTypeDTO,
  PaginationDTO,
  InspectionDTO,
  StatusStatisticsDTO,
} from "../../../common/types/common.dto.types";

import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";
import { SiteInspectionStartPageParametersType } from "../SiteInspectionStartPage/SiteInspectionStartPage.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import {
  CheckFunctionRights,
  formatDateToString,
} from "../../../common/functions/common.functions";

import noData from "../../../images/no_data.svg";
import { allInspectionReportReducerActions } from "../../Reports/AllInspectionSummaryReport/AllInspectionSummaryReport.reducer";
import { AllInspectionDownloadStatusType } from "../../Reports/AllInspectionSummaryReport/AllInspectionSummaryReport.types";
import allInspectionReportSagaActions from "../../Reports/AllInspectionSummaryReport/AllInspectionSummaryReport.actions";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import {
  EnumSystemFunctions,
  EnumSystemFunctionTypes,
} from "../../../core/reducers/functionRights.types";

const SiteInspectionPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const navigate = useNavigate();
  const siteInspectionsState = useAppSelector(
    (state) => state.siteInspectionsPageReducer
  );
  const allInspectionReportState = useAppSelector(
    (state) => state.allInspectionReportReducer
  );

  const dispatch = useAppDispatch();
  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);
  const authState = useAppSelector((state) => state.authReducer);

  const initialState = {
    Data: {} as PaginationDTO,
    SiteInspectionSearchParameter: {
      Name: "",
      InspectionStatusId: 0,
      AssessmentType: { GroupId: "0" } as AssessmentTypeDTO,
      ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
      ShowInspectionStatistics: false,
      ShowCompletedInspection: false,
    } as InspectionDTO,
    AssessmentTypeList: [] as Array<AssessmentTypeDTO>,
    StatusStatistics: [] as Array<StatusStatisticsDTO>,
  } as SiteInspectionsPageInitialState;

  // Check Preparing Ref
  const setTimeOutRef = useRef(null);
  //const setTimeOutParaRef = useRef<InspectionDTO[] | null>(null);

  // Set Search Clear Icon
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  // useEffect(() => {
  //   setTimeOutParaRef.current =
  //     siteInspectionsState?.Data?.Data?.flat() as Array<InspectionDTO>;

  //   if (setTimeOutRef.current === null) {
  //     setTimeOutRef.current = setInterval(isInspectionPreparing, 15000);
  //   }
  // }, [siteInspectionsState.Data?.Data]);

  // end Check Preparing

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    dispatch(siteInspectionsPageReducerActions.resetSiteInspectionsList());

    // get searchparameter from session
    const siteInspectionSearchParameter: InspectionDTO = {
      ...(JSON.parse(localStorage.getItem("siteInspectionSearchParameter")) ??
        initialState.SiteInspectionSearchParameter),
      SelectedPage: 0,
    } as InspectionDTO;

    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
        siteInspectionSearchParameter
      )
    );
    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList(
        siteInspectionSearchParameter
      )
    );
  }, []);

  //On list item click
  const onClickInspection = (Id: number) => {
    //Clear Interval
    if (setTimeOutRef.current !== null) {
      clearInterval(setTimeOutRef.current);
      setTimeOutRef.current = null;
    }

    navigate("/siteInspectionStart", {
      state: {
        InspectionId: Id,
      } as SiteInspectionStartPageParametersType,
    });
  };

  //-----call after search paramter change----
  useEffect(() => {
    // add to local storage when serachparamter change
    localStorage.setItem(
      "siteInspectionSearchParameter",
      JSON.stringify(siteInspectionsState.SiteInspectionSearchParameter)
    );

    if (!PageChangeRef.current) return;
    PageChangeRef.current = false;
    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList(
        siteInspectionsState.SiteInspectionSearchParameter
      )
    );
  }, [siteInspectionsState.SiteInspectionSearchParameter]);

  const backOnClick = () => {
    //Clear Interval
    if (setTimeOutRef.current !== null) {
      clearInterval(setTimeOutRef.current);
      setTimeOutRef.current = null;
    }

    navigate("/");
  };

  //Check Inspection is still Preparing
  // const isInspectionPreparing = () => {
  //   if (
  //     setTimeOutParaRef.current !== null &&
  //     setTimeOutParaRef.current?.length > 0
  //   ) {
  //     const inspectionPreparingList = setTimeOutParaRef.current
  //       ?.filter((filter) => {
  //         return filter.Status === EnumStatus.Preparing;
  //       })
  //       .map((item) => item.Id);

  //     if (inspectionPreparingList.length > 0) {
  //       dispatch(
  //         siteInspectionsSagaActions.checkSiteInspectionsStatus(
  //           inspectionPreparingList
  //         )
  //       );
  //     } else {
  //       if (setTimeOutRef.current !== null) {
  //         clearInterval(setTimeOutRef.current);
  //         setTimeOutRef.current = null;
  //       }
  //     }
  //   }
  // };

  const getStatusStatisticClassName = (statusId: number): string => {
    let className = "";

    if (statusId === 0) {
      className = "common";
    } else if (statusId === EnumInspectionStatus.Scheduled) {
      className = "scheduled";
    } else if (statusId === EnumInspectionStatus.InProgress) {
      className = "inprogress";
    } else if (statusId === EnumInspectionStatus.Overdue) {
      className = "overdue";
    } else if (statusId === EnumInspectionStatus.Completed) {
      className = "completed";
    } else if (statusId === EnumInspectionStatus.Exception) {
      className = "exception";
    }

    //set active status
    if (
      siteInspectionsState?.SiteInspectionSearchParameter
        ?.InspectionStatusId === statusId
    ) {
      className = `${className} active`;
    }
    return className;
  };

  // ======================================== Search Inspection <Start> ===================================//

  const onsearchNameChange = (searchBy: string) => {
    if (!searchBy) {
      const searchparameter = {
        ...siteInspectionsState.SiteInspectionSearchParameter,
        Name: "",
        SelectedPage: 0,
        InspectionStatusId: 0,
        AssessmentType: {
          ...siteInspectionsState.SiteInspectionSearchParameter.AssessmentType,
          GroupId: "0",
        } as AssessmentTypeDTO,
      } as InspectionDTO;

      dispatch(
        siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
          searchparameter
        )
      );

      dispatch(
        siteInspectionsSagaActions.getSiteInspectionsList(searchparameter)
      );
    } else {
      dispatch(
        siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter({
          ...siteInspectionsState.SiteInspectionSearchParameter,
          Name: searchBy,
        } as InspectionDTO)
      );
    }
  };

  const onsearchNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      const searchparameter = {
        ...siteInspectionsState.SiteInspectionSearchParameter,
        SelectedPage: 0,
        InspectionStatusId: 0,
        AssessmentType: {
          ...siteInspectionsState.SiteInspectionSearchParameter.AssessmentType,
          GroupId: "0",
        } as AssessmentTypeDTO,
      } as InspectionDTO;

      dispatch(
        siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
          searchparameter
        )
      );

      dispatch(
        siteInspectionsSagaActions.getSiteInspectionsList(searchparameter)
      );
    }
  };

  const onClickSearchBtn = () => {
    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        SelectedPage: 0,
        InspectionStatusId: 0,
        AssessmentType: {
          ...siteInspectionsState.SiteInspectionSearchParameter.AssessmentType,
          GroupId: "0",
        } as AssessmentTypeDTO,
      })
    );
  };

  const onClickShowInspectionStatistics = (isChecked: boolean) => {
    dispatch(
      siteInspectionsPageReducerActions.setShowInspectionStatistics(isChecked)
    );
  };

  const onClickShowCompletedInspection = (isChecked: boolean) => {
    const inspectionSearchParamters = {
      ...siteInspectionsState.SiteInspectionSearchParameter,
      ShowCompletedInspection: isChecked,
      InspectionStatusId: 0,
      AssessmentType: {
        ...siteInspectionsState.SiteInspectionSearchParameter.AssessmentType,
        GroupId: "0",
      } as AssessmentTypeDTO,
      SelectedPage: 0,
    } as InspectionDTO;

    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
        inspectionSearchParamters
      )
    );

    PageChangeRef.current = true;
  };

  const onClickSeeMoreInspectionBtn = () => {
    const inspectionSearchParamters = {
      ...siteInspectionsState.SiteInspectionSearchParameter,
      SelectedPage: siteInspectionsState?.Data?.Data?.length * 2, // data list is a nested array [1 * 2] , multiply by 2
      ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    } as InspectionDTO;

    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
        inspectionSearchParamters
      )
    );

    PageChangeRef.current = true;
  };

  const onClickInspectionStatusStatistic = (statusId: number) => {
    const inspectionSearchParamters = {
      ...siteInspectionsState.SiteInspectionSearchParameter,
      InspectionStatusId: statusId,
      SelectedPage: 0,
    } as InspectionDTO;

    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
        inspectionSearchParamters
      )
    );

    PageChangeRef.current = true;
  };

  const onClickInspectionAssessmentType = (assessmentTypeGroupId: string) => {
    const inspectionSearchParamters = {
      ...siteInspectionsState.SiteInspectionSearchParameter,
      AssessmentType: {
        ...siteInspectionsState.SiteInspectionSearchParameter.AssessmentType,
        GroupId: assessmentTypeGroupId,
      } as AssessmentTypeDTO,
      InspectionStatusId: 0,
      SelectedPage: 0,
    } as InspectionDTO;

    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
        inspectionSearchParamters
      )
    );

    PageChangeRef.current = true;
  };
  // ======================================== Search Inspection <End> ===================================//

  //-----Page height List ------
  // const mainHeading = useRef(null);
  const wrapperCard = useRef(null);
  const searchRef = useRef(null);
  const statusFilter = useRef(null);
  const assessmentTypeRef = useRef(null);

  //Set Hight
  const [contentHeight, setContentHeight] = useState(0);
  const [assessmentTypeHeight, setassessmentTypeHeight] = useState<number>(0);

  useEffect(() => {
    let assessmentTypeHeight = assessmentTypeRef?.current
      ? assessmentTypeRef?.current?.offsetHeight + 15
      : 0;

    let contentHeight =
      wrapperCard?.current?.offsetHeight -
      searchRef?.current?.offsetHeight -
      // (assessmentTypeRef?.current?.offsetHeight ?? 130) -
      statusFilter?.current?.offsetHeight -
      assessmentTypeHeight -
      25;
    setContentHeight(contentHeight);
    setassessmentTypeHeight(assessmentTypeHeight);

    function handleResize() {
      let assessmentTypeHeight = assessmentTypeRef?.current
        ? assessmentTypeRef?.current?.offsetHeight + 15
        : 0;

      let contentHeight =
        wrapperCard?.current?.offsetHeight -
        searchRef?.current?.offsetHeight -
        // (assessmentTypeRef?.current?.offsetHeight ?? 130) -
        statusFilter?.current?.offsetHeight -
        assessmentTypeHeight -
        25;
      setContentHeight(contentHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [siteInspectionsState?.Data?.Data]);

  const downloadInspections = () => {
    dispatch(allInspectionReportReducerActions.setDownloadStatus(true));

    dispatch(
      allInspectionReportSagaActions.getAllInspectionSummary({
        ReportType: EnumReportType.AllInspectionSummaryReport,
        Inspection: siteInspectionsState.SiteInspectionSearchParameter,
      } as ReportViewerType)
    );
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.SiteInspectionStart,
      functionId,
      functionTypeId
    );
  };

  return (
    <div
      className="content-section-card rem-tracker top-main-padding site-inspection-start"
      ref={wrapperCard}
    >
      <Grid container>
        <div
          className="flex--container fx-wrap width100-p top-main-border mb-5 rem-btn-wrap"
          ref={searchRef}
        >
          <div className="flex__item--inherit pr-10 pl-10">
            <h2>
              <Button
                aria-label="back"
                className="sub-head-back-btn"
                onClick={backOnClick}
                title="Back to home page"
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </h2>
          </div>
          <div className="global-header-wrap rem-trak-head-res">
            <h1 className="page_title">Site Inspections</h1>
          </div>
          <div className="flex__item search-bar--xsmall mr-10 pr-20 fx-around">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InputBase
                value={siteInspectionsState.SiteInspectionSearchParameter?.Name}
                onChange={(event) => {
                  event.stopPropagation();
                  onsearchNameChange(event.target?.value);
                }}
                onKeyPress={onsearchNameKeyPress}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Site Inspections Name/Site"
                inputProps={{ "aria-label": "name" }}
              />

              {isMouseOver &&
                siteInspectionsState.SiteInspectionSearchParameter?.Name &&
                siteInspectionsState.SiteInspectionSearchParameter?.Name
                  ?.length > 0 && (
                  <Tooltip title="Clear" placement="top">
                    <IconButton
                      className="mr-0"
                      onClick={() => onsearchNameChange("")}
                    >
                      <CloseIcon
                        style={{ color: "#219ebc", fontSize: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

              <Tooltip title="Search" placement="top">
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                  onClick={onClickSearchBtn}
                >
                  <SearchNormal1
                    variant="Outline"
                    className="ico-secondary"
                    size={18}
                  />
                </IconButton>
              </Tooltip>
            </Paper>
          </div>
          <div className="flex__item--inherit text-right sub-header-btn-wrap pr-10 pl-10">
            {checkSystemFunctionRight(
              EnumSystemFunctions.SiteInspectionPage_All_Summary,
              EnumSystemFunctionTypes.ButtonAccess
            ) && (
              <Button
                className="rem-lined-btn"
                variant="outlined"
                startIcon={<DirectInbox variant="Outline" />}
                onClick={downloadInspections}
                disabled={allInspectionReportState?.IsDownloading}
                style={{ height: "fit-content" }}
              >
                {allInspectionReportState?.IsDownloading && (
                  <div className="btn-progress">
                    <CircularProgress size={20} />
                  </div>
                )}
                Export
              </Button>
            )}
          </div>
        </div>
        <Grid container>
          {siteInspectionsState?.AssessmentTypeList &&
            siteInspectionsState?.AssessmentTypeList?.length > 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  className="pl-10 pt-5 assessment-type-wrap"
                  ref={assessmentTypeRef}
                >
                  <div
                    className="standart-status-change mb-5 d-flex"
                    style={{
                      borderRight: "unset",
                      borderLeft: "unset",
                      zIndex: "10",
                    }}
                  >
                    <span className="mr-5 fx flex-right type-name">Type: </span>
                    <Grid container className="status-wrp">
                      {siteInspectionsState?.AssessmentTypeList?.map(
                        (assessmentType: AssessmentTypeDTO, index: number) => {
                          return (
                            <div
                              className="standards-status"
                              key={`assessmentType_${index}`}
                              onClick={() => {
                                onClickInspectionAssessmentType(
                                  assessmentType?.GroupId
                                );
                              }}
                            >
                              <span
                                className={`status-color all ${
                                  siteInspectionsState
                                    ?.SiteInspectionSearchParameter
                                    ?.AssessmentType?.GroupId ===
                                  assessmentType?.GroupId
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {`${assessmentType.Code}`}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
        </Grid>
        <Grid container ref={statusFilter}>
          {siteInspectionsState?.StatusStatistics &&
            siteInspectionsState?.StatusStatistics?.length > 0 && (
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <Grid container className="pl-10 inspection-stat-wrap">
                  <div
                    className="standart-status-change mb-5 d-flex"
                    style={{
                      borderRight: "unset",
                      borderLeft: "unset",
                      zIndex: "10",
                    }}
                  >
                    <span className="mr-5 type-name fx flex-right">
                      Status:{" "}
                    </span>
                    <Grid container className="status-wrp">
                      {siteInspectionsState?.StatusStatistics?.map(
                        (status: StatusStatisticsDTO, index: number) => {
                          return (
                            <div
                              className="standards-status"
                              key={`status_${index}`}
                              onClick={() => {
                                onClickInspectionStatusStatistic(status.Id);
                              }}
                            >
                              <span
                                className={`status-color ${getStatusStatisticClassName(
                                  status.Id
                                )}`}
                              >
                                {`${status.StatusName ?? ""} (${status.Count})`}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}

          {((siteInspectionsState?.AssessmentTypeList &&
            siteInspectionsState?.AssessmentTypeList?.length > 0) ||
            (siteInspectionsState?.StatusStatistics &&
              siteInspectionsState?.StatusStatistics?.length > 0) ||
            (siteInspectionsState?.Data?.Data &&
              siteInspectionsState?.Data?.Data?.length > 0)) && (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="checkbox-grid"
            >
              <Grid container className="pl-10 fx-right chkbox-wrap">
                <FormGroup className="chkbox-mt">
                  <HPRCheckBox
                    Id={""}
                    Name={"Include Completed Inspections"}
                    Label={"Include Completed Inspections"}
                    LabelClassName="no-wrap"
                    ClassName={""}
                    checked={
                      siteInspectionsState?.SiteInspectionSearchParameter
                        ?.ShowCompletedInspection ?? false
                    }
                    onCheckBoxChange={(name: string, IsChecked: boolean) => {
                      onClickShowCompletedInspection(IsChecked);
                    }}
                  />
                </FormGroup>
                <FormGroup className="chkbox-mt">
                  <HPRCheckBox
                    Id={""}
                    Name={"Include Inspection Statistics"}
                    Label={"Include Inspection Statistics"}
                    LabelClassName="no-wrap"
                    ClassName={""}
                    checked={
                      siteInspectionsState?.SiteInspectionSearchParameter
                        ?.ShowInspectionStatistics ?? false
                    }
                    onCheckBoxChange={(name: string, IsChecked: boolean) => {
                      onClickShowInspectionStatistics(IsChecked);
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* Start Content */}
        <Grid
          container
          className="fx-middle inspection-content overflow-auto"
          style={{ height: contentHeight }}
        >
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="mt-5 pr-10 pl-10"
          >
            {siteInspectionsState?.Data?.Data?.length > 0 ? (
              <>
                {siteInspectionsState?.Data?.Data?.map(
                  (inspectionArray: any, index: number) => {
                    return (
                      <Grid
                        container
                        spacing={1.2}
                        key={`inspectionArray_${index}`}
                        className={`${index > 0 ? "pt-10" : ""}`}
                      >
                        {inspectionArray?.map(
                          (
                            inspection: InspectionDTO,
                            inspectionIndex: number
                          ) => {
                            return (
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={12}
                                sm={12}
                                xs={12}
                                key={`inspection_${inspectionIndex}`}
                                className="inspection-two-col"
                              >
                                {/* add gap between 2 tiles 5px */}
                                <div
                                  className="item-wrapper cursor-pointer hover-effect mb-5"
                                  onClick={() =>
                                    onClickInspection(inspection.Id)
                                  }
                                >
                                  <div className="item-header">
                                    <h2 className="inner-header">
                                      <Tooltip
                                        title={inspection?.Name}
                                        placement="top-start"
                                      >
                                        <span className="inspect-name">
                                          {inspection?.Name}
                                        </span>
                                      </Tooltip>
                                      <a className="link-button-type1 align-right header-lbl center-lbl fx">
                                        Site:{" "}
                                        <Tooltip
                                          title={inspection?.Site?.SiteName}
                                          placement="top-start"
                                        >
                                          <span className="gray-text site-name ml-5">
                                            {inspection?.Site?.SiteName}
                                          </span>
                                        </Tooltip>
                                      </a>
                                      <a className="link-button-type1 align-right header-lbl icon-a">
                                        {inspection.InspectionStatusId ===
                                          EnumInspectionStatus.Exception && (
                                          <span className="icon-span">
                                            <Tooltip
                                              title={
                                                "At least one SME should be defined"
                                              }
                                            >
                                              <InfoCircle
                                                className="icon-btn mr-5"
                                                color={"#f44336"}
                                                variant="Bold"
                                                size={20}
                                              />
                                            </Tooltip>
                                          </span>
                                        )}
                                        Status:{" "}
                                        <span className="gray-text ml-5 status-length">
                                          {
                                            inspection.InspectionStatus
                                              ?.StatusName
                                          }
                                        </span>
                                      </a>
                                    </h2>
                                  </div>

                                  {siteInspectionsState
                                    ?.SiteInspectionSearchParameter
                                    ?.ShowInspectionStatistics && (
                                    <div className="item-content mt-15">
                                      {/* change gap 20px -> 15px (gaps between Inspection name underline and progress bar) */}
                                      <Grid container>
                                        <Grid item xs={12}></Grid>
                                      </Grid>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <div className="progress-bar-wrapper">
                                            <LinearProgress
                                              variant="determinate"
                                              value={
                                                inspection.ApplicableQuestionPrecentage ??
                                                0
                                              }
                                            />
                                            <label className="audit-progress-quantity">
                                              {`${
                                                inspection.ApplicableQuestionCount ??
                                                0
                                              }/${
                                                inspection.InspectionQuestionCount ??
                                                0
                                              }`}
                                            </label>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {/* add top & bottom gap 5px (gaps between Progress bar and statistic labels /  statistic labels and Planned completion Date/Started labels) */}
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="d-flex no-of-item mt-5 stat-label-gap"
                                      >
                                        <span className="audit-qty-lbl w-130 no-wrap">
                                          <span className="green-text-audit w-40 text-right">
                                            (
                                            {inspection.AnsweredWithoutGapCount ??
                                              0}
                                            )
                                          </span>
                                          <span>No Gaps</span>
                                        </span>
                                        <span className="audit-qty-lbl w-130 no-wrap">
                                          <span className="red-text w-40 text-right">
                                            (
                                            {inspection.AnsweredWithGapCount ??
                                              0}
                                            )
                                          </span>
                                          <span>Gaps</span>
                                        </span>
                                        <span className="audit-qty-lbl w-130 no-wrap">
                                          <span className="na-text w-40 text-right">
                                            ({inspection.AnsweredAsNACount ?? 0}
                                            )
                                          </span>
                                          <span>N/A</span>
                                        </span>

                                        <span className="audit-qty-lbl w-130 no-wrap">
                                          <span className="skipped-text w-40 text-right">
                                            (
                                            {inspection.AnsweredWithSkipCount ??
                                              0}
                                            )
                                          </span>
                                          <span>Skipped</span>
                                        </span>

                                        {inspection.InspectionStatusId ===
                                          EnumInspectionStatus.InProgress && (
                                          <span className="audit-qty-lbl w-130 no-wrap">
                                            <span className="pending-text w-40 text-right">
                                              (
                                              {inspection.PendingQuestionCount ??
                                                0}
                                              )
                                            </span>
                                            <span>Pending</span>
                                          </span>
                                        )}

                                        {inspection.IsAnswered && (
                                          <span className="audit-qty-lbl w-130 no-wrap">
                                            <span className="answerered-text w-40 text-right">
                                              (
                                              {inspection.AnsweredButNotApprovedCount ??
                                                0}
                                              )
                                            </span>
                                            <span>Answered</span>
                                          </span>
                                        )}
                                        {inspection.AnyInspectionStandardCompleted && (
                                          <span className="audit-qty-lbl w-130 no-wrap">
                                            <span className="red-text w-40 text-right">
                                              ({inspection.Gaps ?? 0})
                                            </span>
                                            <span className="red-text m-0">
                                              Total Gaps
                                            </span>
                                          </span>
                                        )}
                                      </Grid>
                                    </div>
                                  )}

                                  <div className="audit-item-footer">
                                    <Grid container>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="date-wrapper"
                                          >
                                            <span className="item-lbl-val time-stamp align-left column-calc">
                                              <span className="item-lbl-val align-left">
                                                <label className="audit-sub-lbl">
                                                  Planned Completion Date:{" "}
                                                </label>
                                                <label className="audit-sub-val gray-text">
                                                  {formatDateToString(
                                                    inspection.PlannedCompletionDate
                                                  )}
                                                </label>
                                              </span>
                                            </span>

                                            {inspection.ShowInspectionStartedBy && (
                                              <span className="item-lbl-val time-stamp align-left">
                                                <span className="item-lbl-val align-left fx started-lbl">
                                                  <label className="audit-sub-lbl mr-2">
                                                    Started:{" "}
                                                  </label>

                                                  <label className="audit-sub-val gray-text fx">
                                                    {inspection
                                                      .StartedByNavigation
                                                      ?.DisplayName ? (
                                                      <>
                                                        <Tooltip
                                                          title={`${
                                                            inspection
                                                              .StartedByNavigation
                                                              ?.DisplayName ??
                                                            ""
                                                          }`}
                                                          placement="top-start"
                                                        >
                                                          <span className="inspect-truncate b-right">
                                                            {`${
                                                              inspection
                                                                .StartedByNavigation
                                                                ?.DisplayName ??
                                                              "-"
                                                            }`}
                                                          </span>
                                                        </Tooltip>
                                                        {/* |{" "} */}
                                                        <span className="date">
                                                          {`${formatDateToString(
                                                            inspection.StartedDate
                                                          )}`}
                                                        </span>
                                                      </>
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </label>
                                                </span>
                                              </span>
                                            )}

                                            {inspection.InspectionStatusId !==
                                              EnumInspectionStatus.Scheduled &&
                                              !inspection.ShowFirstAndLastCompletedDate &&
                                              inspection.ReopenByDate && (
                                                <span className="item-lbl-val time-stamp align-left">
                                                  {/* remove top gap 5px (gaps between Planned completion Date and Initially date / Started and Latest completed) */}
                                                  <span className="item-lbl-val align-left">
                                                    <label className="audit-sub-lbl">
                                                      Reopened:{" "}
                                                    </label>
                                                    <label className="audit-sub-val gray-text fx-inline">
                                                      <>
                                                        <Tooltip
                                                          title={`${
                                                            inspection.ReopenBy
                                                              ?.DisplayName ??
                                                            ""
                                                          }`}
                                                          placement="top-start"
                                                        >
                                                          <span className="inspect-truncate b-right">
                                                            {`${
                                                              inspection
                                                                .ReopenBy
                                                                ?.DisplayName ??
                                                              "-"
                                                            }`}
                                                          </span>
                                                        </Tooltip>
                                                        {/* |{" "} */}
                                                        <span className="date">
                                                          {`${formatDateToString(
                                                            inspection.ReopenByDate
                                                          )}`}
                                                        </span>
                                                      </>
                                                    </label>
                                                  </span>
                                                </span>
                                              )}

                                            {inspection.ShowFirstAndLastCompletedDate ? (
                                              <>
                                                <span className="item-lbl-val time-stamp align-left">
                                                  {/* remove top gap 5px (gaps between Planned completion Date and Initially date / Started and Latest completed ) */}
                                                  <span className="item-lbl-val align-left fx">
                                                    <label className="audit-sub-lbl mr-2">
                                                      {`${
                                                        inspection.FirstCompletedDate ===
                                                        inspection.LastCompletedDate
                                                          ? "Completed: "
                                                          : "Initially Completed: "
                                                      }`}
                                                    </label>
                                                    <label className="audit-sub-val gray-text fx">
                                                      {inspection.FirstCompletedDate ? (
                                                        <>
                                                          <Tooltip
                                                            title={`${
                                                              inspection
                                                                .FirstCompletedBy
                                                                ?.DisplayName ??
                                                              ""
                                                            }`}
                                                            placement="top-start"
                                                          >
                                                            <span className="inspect-truncate b-right">
                                                              {`${
                                                                inspection
                                                                  .FirstCompletedBy
                                                                  ?.DisplayName ??
                                                                "-"
                                                              }`}
                                                            </span>
                                                          </Tooltip>
                                                          {/* |{" "} */}
                                                          <span className="date">
                                                            {`${formatDateToString(
                                                              inspection.FirstCompletedDate
                                                            )}`}
                                                          </span>
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )}
                                                    </label>
                                                  </span>
                                                </span>
                                                {inspection.LastCompletedDate &&
                                                  inspection.FirstCompletedDate !==
                                                    inspection.LastCompletedDate && (
                                                    <span className="item-lbl-val time-stamp align-left">
                                                      {/* remove top gap 5px (gaps between Planned completion Date and Initially date / Started and Latest completed ) */}
                                                      <span className="item-lbl-val align-left">
                                                        <label className="audit-sub-lbl">
                                                          Latest Completed:{" "}
                                                        </label>

                                                        <label className="audit-sub-val gray-text fx-inline">
                                                          {inspection.LastCompletedDate ? (
                                                            <>
                                                              <Tooltip
                                                                title={`${
                                                                  inspection
                                                                    .LastCompletedByNavigation
                                                                    ?.DisplayName ??
                                                                  ""
                                                                }`}
                                                                placement="top-start"
                                                              >
                                                                <span className="inspect-truncate b-right">
                                                                  {`${
                                                                    inspection
                                                                      .LastCompletedByNavigation
                                                                      ?.DisplayName ??
                                                                    "-"
                                                                  }`}
                                                                </span>
                                                              </Tooltip>
                                                              {/* |{" "} */}
                                                              <span className="date">
                                                                {`${formatDateToString(
                                                                  inspection.LastCompletedDate
                                                                )}`}
                                                              </span>
                                                            </>
                                                          ) : (
                                                            "-"
                                                          )}
                                                        </label>
                                                      </span>
                                                    </span>
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    );
                  }
                )}

                {siteInspectionsState?.Data?.Data?.length * 2 <
                  siteInspectionsState?.Data?.TotalRecordsCount && (
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="seperator-line"
                  >
                    <Button
                      className="secondary-btn-small"
                      size="small"
                      variant="outlined"
                      endIcon={
                        <ArrowCircleDown
                          variant="Outline"
                          className="see-more-arrow"
                        />
                      }
                      onClick={onClickSeeMoreInspectionBtn}
                    >
                      See More
                    </Button>
                  </Grid>
                )}
              </>
            ) : (
              <>
                <div className="no-data width100-p flex--container">
                  <img className="" src={noData} style={{}}></img>
                  <div className="gray-text body2 flex__item--vcenter nodata-text">
                    No records found
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SiteInspectionPage;
