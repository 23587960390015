import React, { useEffect, useRef, useState } from "react";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getLocationsList } from "./LocationsPage.action";
import { setLocationsSearchParameter } from "./LocationsPage.reducer";
import {
  EnumListShowRowCountList,
  EnumLocationSortType,
  EnumPageType,
  EnumSortTypes,
  EnumStandardQuestionSortType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { LocationPageParametersType } from "../LocationPage/LocationPage.types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { AddCircle, ArrowLeft2, SearchNormal1 } from "iconsax-react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import HPRStatus from "../../../components/common/HPRStatus/HPRStatus";
import HPRListPagination from "../../../components/common/HPRListPagination/HPRListPagination";
import {
  DropDownDTO,
  PaginationDTO,
  SearchParameterDTO,
  SiteDTO,
  SortDropDownDTO,
  StatusSummaryDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import {
  LocationsPageInitialState,
  LocationsPageParametersType,
} from "./LocationsPage.types";
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const initialState = {
  Data: {} as PaginationDTO,
  Loading: false,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: 2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  LocationsSearchParameter: {
    LocationsSearchParams: {
      SiteName: "",
      Status: EnumStatus.Published,
      SortBy: {
        Label: "Name",
        Value: EnumLocationSortType.Name.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
    } as SiteDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
  } as SearchParameterDTO,
} as LocationsPageInitialState;

const LocationsPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);

  // navigate
  const navigate = useNavigate();
  const locationsState = useAppSelector((state) => state.locationsReducer);
  const location = useLocation();
  const parameter: LocationsPageParametersType = location.state;
  const dispatch = useAppDispatch();

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (parameter != null) {
      dispatch(
        setLocationsSearchParameter({
          ...initialState.LocationsSearchParameter,
          LocationsSearchParams: {
            ...initialState.LocationsSearchParameter.LocationsSearchParams,
            Status: parameter.StatusId
              ? parameter.StatusId
              : EnumStatus.Published,
          },
        })
      );
      dispatch(
        getLocationsList({
          ...initialState.LocationsSearchParameter,
          LocationsSearchParams: {
            ...initialState.LocationsSearchParameter.LocationsSearchParams,
            Status: parameter.StatusId,
          },
        })
      );
    } else {
      dispatch(
        setLocationsSearchParameter(initialState.LocationsSearchParameter)
      );
      dispatch(getLocationsList(initialState.LocationsSearchParameter));
    }
  }, []);

  const onsearchNameChange = (searchValue: string) => {
    dispatch(
      setLocationsSearchParameter({
        ...locationsState.LocationsSearchParameter,
        LocationsSearchParams: {
          ...locationsState.LocationsSearchParameter.LocationsSearchParams,
          SiteName: searchValue,
        },
      })
    );

    if (!searchValue) {
      dispatch(
        getLocationsList({
          ...locationsState.LocationsSearchParameter,
          LocationsSearchParams: {
            ...locationsState.LocationsSearchParameter.LocationsSearchParams,
            SiteName: searchValue,
          },
        })
      );
    }
  };

  //-----enetr Key Press ===> serach TextBox-----
  const onsearchNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      dispatch(
        getLocationsList({
          ...locationsState.LocationsSearchParameter,
          SelectedPage: 1,
        })
      );
    }
  };

  //-----Serach Buttion Click-----
  const searchBtnClick = (IsAdvanceSearch: boolean) => {
    if (
      locationsState.LocationsSearchParameter.IsAdvanceSearch ===
      IsAdvanceSearch
    ) {
      dispatch(
        getLocationsList({
          ...locationsState.LocationsSearchParameter,
          SelectedPage: 1,
        })
      );
    } else {
      PageChangeRef.current = true;
      dispatch(
        setLocationsSearchParameter({
          SelectedPage: 1,
          ...locationsState.LocationsSearchParameter,
          IsAdvanceSearch: IsAdvanceSearch,
        })
      );
    }
  };

  //-----selected Page Change (callback function)-----
  const onPageChange = (page: number) => {
    PageChangeRef.current = true;
    dispatch(
      setLocationsSearchParameter({
        ...locationsState.LocationsSearchParameter,
        SelectedPage: page,
      })
    );
  };

  //-----call after selected page change(pagination) or is advanceSearch-----
  useEffect(() => {
    if (!PageChangeRef.current) return;
    PageChangeRef.current = false;
    dispatch(getLocationsList(locationsState.LocationsSearchParameter));
  }, [
    locationsState.LocationsSearchParameter.SelectedPage,
    locationsState.LocationsSearchParameter.IsAdvanceSearch,
    locationsState.LocationsSearchParameter.LocationsSearchParams.Status,
    locationsState.LocationsSearchParameter.LocationsSearchParams.SortBy,
    locationsState.LocationsSearchParameter.ListPageSize,
  ]);

  const searchByStatusOnClick = (status: EnumStatus) => {
    if (
      status !=
      locationsState.LocationsSearchParameter.LocationsSearchParams?.Status
    ) {
      PageChangeRef.current = true;
      dispatch(
        setLocationsSearchParameter({
          ...locationsState.LocationsSearchParameter,
          SelectedPage: 1,
          LocationsSearchParams: {
            ...locationsState.LocationsSearchParameter.LocationsSearchParams,
            Status: status,
          },
        })
      );
    } else {
      dispatch(getLocationsList(locationsState.LocationsSearchParameter));
    }
  };

  //-----List Item onclick callback function-----
  const ItemOnClickFunction = (Id: number) => {
    navigate("/location", {
      state: {
        LocationId: Id,
        StatusId:
          locationsState.LocationsSearchParameter.LocationsSearchParams.Status,
      } as LocationPageParametersType,
    });
  };

  const AddBtnOnclick = () => {
    navigate("/location", {
      state: {
        StatusId:
          locationsState.LocationsSearchParameter.LocationsSearchParams.Status,
      } as LocationPageParametersType,
    });
  };

  const BacktnOnclick = () => {
    navigate("/");
  };

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const subHeading = useRef(null);
  const searchBar = useRef(null);
  const recordCount = useRef(null);

  //Set Hight
  const [listHeight, setListHeight] = useState(0);
  // const [calendarHeight, setCalendarHeight] = useState(0);

  //-----Page load-----
  useEffect(() => {
    let listheight =
      window.innerHeight -
      // mainHeading.current.offsetHeight -
      subHeading.current.offsetHeight -
      // searchBar.current.offsetHeight -
      recordCount.current.offsetHeight -
      241;
    setListHeight(listheight);

    function handleResize() {
      let listheight =
        window.innerHeight -
        // mainHeading.current.offsetHeight -
        subHeading.current.offsetHeight -
        // searchBar.current.offsetHeight -
        recordCount.current.offsetHeight -
        241;
      setListHeight(listheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onChangeSortBy(SelectedSortState: SortDropDownDTO) {
    PageChangeRef.current = true;
    dispatch(
      setLocationsSearchParameter({
        ...locationsState.LocationsSearchParameter,
        SelectedPage: 1,
        LocationsSearchParams: {
          ...locationsState.LocationsSearchParameter.LocationsSearchParams,
          SortBy: SelectedSortState,
        },
      })
    );
  }

  function onChangePagination(Name: string, Id: string) {
    PageChangeRef.current = true;
    dispatch(
      setLocationsSearchParameter({
        ...locationsState.LocationsSearchParameter,
        SelectedPage: 1,
        LocationsSearchParams: {
          ...locationsState.LocationsSearchParameter.LocationsSearchParams,
        },
        ListPageSize: parseInt(Id),
      })
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={locationsState !== undefined ? locationsState?.Loading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid
        container
        columns={12}
        className="global-header-wrap"
        ref={mainHeading}
      > */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>{props.PageTitle}</h1>
        </Grid> */}
      {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        ></Grid>
      </Grid> */}

      {/* Main Backgroud */}

      <div className="content-section-card top-main-padding">
        {/* Serarch */}

        <Grid container className="" ref={subHeading}>
          {/* Global Sub Header with  Buttons*/}

          <div className="flex--container width100-p top-main-border mb-10 rem-btn-wrap pl-10 pr-10">
            {/* <IconButton
                    aria-label="back"
                    className="sub-head-back-btn"
                    onClick={BacktnOnclick}
                    title="Back to home page"
                  >
                    <ArrowLeft2 size="24" variant="Outline" />
                  </IconButton> */}
            <div className="flex__item--inherit pr-10">
              <h2>
                <Button
                  aria-label="back"
                  className="sub-head-back-btn"
                  onClick={BacktnOnclick}
                  title="Back to home page"
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>
              </h2>
            </div>
            <div className="global-header-wrap">
              <h1 className="page_title">{props.PageTitle}</h1>
            </div>
            <div
              className="flex__item search-bar--small ml-10 mr-10"
              ref={searchBar}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100% ",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <InputBase
                  value={
                    locationsState.LocationsSearchParameter
                      .LocationsSearchParams.SiteName
                  }
                  onChange={(event) => {
                    event.stopPropagation();
                    onsearchNameChange(event.target?.value);
                  }}
                  onKeyPress={onsearchNameKeyPress}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Locations"
                  inputProps={{ "aria-label": "Locations" }}
                  //type="search"
                />
                {isMouseOver &&
                  locationsState.LocationsSearchParameter?.LocationsSearchParams
                    ?.SiteName &&
                  locationsState.LocationsSearchParameter?.LocationsSearchParams
                    ?.SiteName?.length > 0 && (
                    <Tooltip title="Clear" placement="left-end">
                      <IconButton
                        className="mr-0"
                        onClick={() => onsearchNameChange("")}
                      >
                        <CloseIcon
                          style={{ color: "#219ebc", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                <Tooltip title="Search" placement={"left-end"}>
                  <IconButton
                    onClick={() => {
                      searchBtnClick(false);
                    }}
                    type="button"
                    className="m-0"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    {/* <SearchIcon /> */}

                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                </Tooltip>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      searchBtnClick(true);
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                  

                    <Filter variant="Outline" className="ico-secondary" />
                  </IconButton> */}
              </Paper>
            </div>
            <Button
              className="primary-btn-small"
              variant="outlined"
              size="small"
              startIcon={<AddCircle variant="Outline" />}
              onClick={AddBtnOnclick}
              title="Add"
            >
              Add
            </Button>
          </div>
        </Grid>
        {/* 
              <Grid item xs={12} className="search-bar--small" ref={searchBar}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    value={
                      locationsState.LocationsSearchParameter
                        .LocationsSearchParams.SiteName
                    }
                    onChange={onsearchNameChange}
                    onKeyPress={onsearchNameKeyPress}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Locations"
                    inputProps={{ "aria-label": "Locations" }}
                    type="search"
                  />
                  <IconButton
                    onClick={() => {
                      searchBtnClick(false);
                    }}
                    type="button"
                    className="m-0"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  > */}
        {/* <SearchIcon /> */}
        {/* <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                    />
                  </IconButton> */}
        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      searchBtnClick(true);
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                  

                    <Filter variant="Outline" className="ico-secondary" />
                  </IconButton> */}
        {/* </Paper>
              </Grid> */}

        {/* Serarch End*/}
        <div className="content-inner-wrap pl-10 pr-10">
          <HPRStatus
            onClickStatus={searchByStatusOnClick}
            SelectedStatus={
              locationsState.LocationsSearchParameter.LocationsSearchParams
                .Status as EnumStatus
            }
            StatusSummaryItemList={
              [
                {
                  Value: EnumStatus.Published,
                  Label: "Published",
                  StatusSummaryCount:
                    locationsState.Data?.StatusSummaryCount?.Published,
                },
                // {
                //   Value: EnumStatus.Revision,
                //   Label: "Revision",
                //   StatusSummaryCount:
                //     locationsState.Data?.StatusSummaryCount?.Revision,
                // },
                {
                  Value: EnumStatus.Draft,
                  Label: "Draft",
                  StatusSummaryCount:
                    locationsState.Data?.StatusSummaryCount?.Draft,
                },
                {
                  Value: EnumStatus.Deactivated,
                  Label: "Deactivated",
                  StatusSummaryCount:
                    locationsState.Data?.StatusSummaryCount?.Deactivated,
                },
                // {
                //   Value: EnumStatus.Archive,
                //   Label: "Archive",
                //   StatusSummaryCount:
                //     locationsState.Data?.StatusSummaryCount?.Archive,
                // },
                {
                  Value: EnumStatus.All,
                  Label: "All",
                  StatusSummaryCount:
                    locationsState.Data?.StatusSummaryCount?.All,
                },
              ] as Array<StatusSummaryDTO>
            }
            Name={"LocationsStatus"}
            SortByItemList={
              [
                {
                  Label: "Name",
                  Value: EnumLocationSortType.Name.toString(),
                  SortType: EnumSortTypes.Ascending,
                },
                {
                  Label: "Country",
                  Value: EnumLocationSortType.CountryName.toString(),
                  SortType: EnumSortTypes.Ascending,
                },
                {
                  Label: "Region",
                  Value: EnumLocationSortType.RegionName.toString(),
                  SortType: EnumSortTypes.Ascending,
                },
                // {
                //   Label: "Status",
                //   Value: EnumLocationSortType.Status.toString(),
                //   SortType: EnumSortTypes.Ascending,
                // },
                {
                  Label: "Last modified user",
                  Value: EnumLocationSortType.LastModifiedUser.toString(),
                  SortType: EnumSortTypes.Ascending,
                },
                {
                  Label: "Last modified date & time",
                  Value: EnumLocationSortType.LastModifiedDateTime.toString(),
                  SortType: EnumSortTypes.Ascending,
                },
              ] as SortDropDownDTO[]
            }
            onChangeSortBy={onChangeSortBy}
            PerPageItemList={
              [
                {
                  Label: "5",
                  Value:
                    EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
                },
                {
                  Label: "10",
                  Value:
                    EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
                },
                {
                  Label: "15",
                  Value:
                    EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
                },
                {
                  Label: "20",
                  Value:
                    EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
                },
                {
                  Label: "25",
                  Value:
                    EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
                },
              ] as DropDownDTO[]
            }
            onChangePerPage={onChangePagination}
            InitialSortByState={
              {
                Label: "Name",
                Value: EnumLocationSortType.Name.toString(),
                SortType: EnumSortTypes.Ascending,
              } as SortDropDownDTO
            }
          />

          {/* Items Found  */}
          {!locationsState.Loading && (
            <Grid item xs={12} className="mb-15" ref={recordCount}>
              <div className="records-found">
                {locationsState.ListPagination.TotalRecordsCount ===
                  undefined ||
                locationsState.ListPagination.TotalRecordsCount === 0
                  ? "No records found"
                  : locationsState.ListPagination.TotalRecordsCount === 1
                  ? "1 record found"
                  : locationsState.ListPagination.TotalRecordsCount +
                    " records found"}
              </div>
            </Grid>
          )}
          <div
            className="location-page-items-wrap"
            style={{ height: listHeight }}
          >
            <HPRListPagination
              ItemList={locationsState.ListPagination.ItemList}
              ItemShowCount={locationsState.ListPagination.ItemShowCount}
              TotalRecordsCount={
                locationsState.ListPagination.TotalRecordsCount
              }
              PaginationOnClickFunction={onPageChange}
              ItemOnClickFunction={ItemOnClickFunction}
              PageType={EnumPageType.Locations}
              SelectedPage={locationsState.ListPagination.SelectedPage}
              IsLoading={locationsState.Loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationsPage;
