import { MRT_ColumnDef } from "material-react-table";
import HPRMaterialReactTable from "../../../components/common/HPRMaterialReactTable/HPRMaterialReactTable";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { SearchNormal1, AddCircle } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  AssessmentTypeDTO,
  ColumnFiltertypeDTO,
  EmployeeDTO,
  PaginationDTO,
  SortDropDownDTO,
  StatusDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { AssessmentTypeWizardsPageType } from "./AssessmentTypeWizardsPage.types";
import {
  EnumListShowRowCountList,
  EnumPageType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { assessmentTypeWizardsReducerActions } from "./AssessmentTypeWizardsPage.reducer";
import { getAssessmentTypeWizardList } from "./AssessmentTypeWizardsPage.action";
import {
  HPRMaterialReactTableColumnFilteredSummaryType,
  HPRMaterialReactTableStatusSummaryType,
} from "../../../components/common/HPRMaterialReactTable/HPRMaterialReactTable.type";
import {
  CheckFunctionRights,
  IsDisabledFromDate,
  IsDisabledToDate,
  formatDateTimeToString,
  getFormatedDateForBackend,
} from "../../../common/functions/common.functions";
import { EnumDateType } from "../../../components/common/HPRDatePicker/HPRDatePicker.enum";
import HPRDatePicker from "../../../components/common/HPRDatePicker/HPRDatePicker";
import dayjs, { Dayjs } from "dayjs";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";

// ==============================|| VIEWS - PAGES - ASSESSMENT TYPE WIZARDS ||============================== //

const initialState = {
  Data: {
    Data: [] as Array<AssessmentTypeDTO>,
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  AssessmentTypeWizardSearchParameter: {
    AssessmentTypeName: "",
    Description: "",
    Code: "",
    StatusNavigation: {
      Status1: "",
    } as StatusDTO,
    CreatedBy: { DisplayName: "" } as EmployeeDTO,
    CreatedDateFrom: null,
    CreatedDateTo: null,
    ModifiedByNavigation: { DisplayName: "" } as EmployeeDTO,
    ModifiedDateFrom: null,
    ModifiedDateTo: null,
    Status: EnumStatus.Published,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    BasicSearchValue: "",
  } as AssessmentTypeDTO,
  DataLoading: false,
} as AssessmentTypeWizardsPageType;

const AssessmentTypeWizardsPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  const dataFetchedRef = useRef(false);
  const dispatch = useAppDispatch();

  // use for page navigation
  const navigate = useNavigate();

  // create state for AssessmentTypeWizardsPage with initialState values
  const [assessmentTypeWizardsPageState, setAssessmentTypeWizardsPageState] =
    useState<AssessmentTypeWizardsPageType>(initialState);

  const assessmentTypeWizardListState = useAppSelector(
    (state) => state.assessmentTypeWizardListReducer
  );

  const authState = useAppSelector((state) => state.authReducer);

  // pass parameters from outside to page
  const location = useLocation();

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const wrapperCard = useRef(null);

  //Set Hight
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    let tableHeight =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      45;
    setTableHeight(tableHeight);

    function handleResize() {
      let tableHeight =
        wrapperCard?.current?.offsetHeight -
        mainHeading?.current?.offsetHeight -
        45;
      setTableHeight(tableHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [wrapperCard?.current?.offsetHeight, mainHeading?.current?.offsetHeight]);

  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  // Set Search Clear Icon
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.RemediationTrackerTasks,
      functionId,
      functionTypeId
    );
  };

  const TableCellComponent = (props: {
    value: any;
    showToolTip?: boolean;
    className?: string;
  }) => {
    return props.showToolTip ? (
      <Tooltip title={!props.value || props.value === "-" ? "" : props.value}>
        <span className={`${props.className}`}>{props.value}</span>
      </Tooltip>
    ) : (
      <span className={props.className}>{props.value}</span>
    );
  };

  const columns: Array<MRT_ColumnDef<any>> = [
    {
      id: "AssessmentType-1",
      accessorKey: "AssessmentTypeName",
      header: "Assessment Type",
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.AssessmentTypeName || "-",
          showToolTip: true,
        }),
    },
    {
      id: "Description-2",
      accessorKey: "Description",
      header: "Description",
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.Description || "-",
          showToolTip: true,
          className: "limit-3 truncate",
        }),
    },
    {
      id: "Code-3",
      accessorKey: "Code",
      header: "Code",
      Cell: ({ cell }) =>
        TableCellComponent({ value: cell.row.original?.Code || "-" }),
    },
    {
      id: "Status-4",
      accessorKey: "Status",
      header: "Status",
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.StatusNavigation?.Status1 || "-",
          className: `status-color ${getAssessmentTypeStatusColor(
            cell.row.original?.StatusNavigation?.Id
          )}`,
        }),
    },
    {
      id: "CreatedBy-5",
      accessorKey: "CreatedBy",
      header: "Created By",
      size: 270,
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.CreatedBy?.DisplayName || "-",
        }),
    },
    {
      id: "CreatedDateAndTime-6",
      accessorKey: "CreatedDateAndTime",
      header: "Created Date And Time",
      size: 200,
      Cell: ({ cell }) =>
        TableCellComponent({
          value: formatDateTimeToString(cell.row.original?.CreatedDate) || "-",
        }),
      Filter: () => {
        return (
          <div className="filter-date-wrapper">
            <HPRDatePicker
              Value={
                assessmentTypeWizardsPageState
                  .AssessmentTypeWizardSearchParameter.CreatedDateFrom ?? null
              }
              Placeholder={"Select from date"}
              Name={"CreatedDateFrom"}
              Type={EnumDateType.DateOnly}
              onDateChange={onColumnWiseFilterDateChange}
              IsDisabledDate={(date) =>
                IsDisabledFromDate(
                  date,
                  assessmentTypeWizardsPageState
                    .AssessmentTypeWizardSearchParameter.CreatedDateTo ?? null
                )
              }
            />
            <HPRDatePicker
              Value={
                assessmentTypeWizardsPageState
                  .AssessmentTypeWizardSearchParameter.CreatedDateTo ?? null
              }
              Placeholder={"Select to date"}
              Name={"CreatedDateTo"}
              Type={EnumDateType.DateOnly}
              onDateChange={onColumnWiseFilterDateChange}
              IsDisabledDate={(date) =>
                IsDisabledToDate(
                  date,
                  assessmentTypeWizardsPageState
                    .AssessmentTypeWizardSearchParameter.CreatedDateFrom ?? null
                )
              }
            />
          </div>
        );
      },
    },
    {
      id: "ModifiedBy-7",
      accessorKey: "ModifiedBy",
      header: "Modified By ",
      size: 270,
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.ModifiedByNavigation?.DisplayName || "-",
        }),
    },
    {
      id: "ModifiedDateAndTime-8",
      accessorKey: "ModifiedDateAndTime",
      header: "Modified Date And Time",
      size: 200,
      Cell: ({ cell }) =>
        TableCellComponent({
          value: formatDateTimeToString(cell.row.original?.ModifiedDate) || "-",
        }),
      Filter: () => {
        return (
          <div className="filter-date-wrapper">
            <HPRDatePicker
              Value={
                assessmentTypeWizardsPageState
                  .AssessmentTypeWizardSearchParameter.ModifiedDateFrom ?? null
              }
              Placeholder={"Select from date"}
              Name={"ModifiedDateFrom"}
              Type={EnumDateType.DateOnly}
              onDateChange={onColumnWiseFilterDateChange}
              IsDisabledDate={(date) =>
                IsDisabledFromDate(
                  date,
                  assessmentTypeWizardsPageState
                    .AssessmentTypeWizardSearchParameter.ModifiedDateTo ?? null
                )
              }
            />
            <HPRDatePicker
              Value={
                assessmentTypeWizardsPageState
                  .AssessmentTypeWizardSearchParameter.ModifiedDateTo ?? null
              }
              Placeholder={"Select to date"}
              Name={"ModifiedDateTo"}
              Type={EnumDateType.DateOnly}
              onDateChange={onColumnWiseFilterDateChange}
              IsDisabledDate={(date) =>
                IsDisabledToDate(
                  date,
                  assessmentTypeWizardsPageState
                    .AssessmentTypeWizardSearchParameter.ModifiedDateFrom ??
                    null
                )
              }
            />
          </div>
        );
      },
    },
  ];

  const getAssessmentTypeStatusColor = (status: EnumStatus) => {
    if (status === EnumStatus.Published) {
      return "published";
    } else if (status === EnumStatus.Draft) {
      return "draft";
    } else if (status === EnumStatus.Deactivated) {
      return "deactivated";
    } else if (status === EnumStatus.All) {
      return "all";
    } else {
      return "";
    }
  };

  const assessmentTypeStatusSummary =
    assessmentTypeWizardsPageState?.AssessmentTypeStatusSummary?.map(
      (status: StatusDTO) => {
        return {
          Value: status.Id,
          Label: status.Status1,
          StatusSummaryCount: status.NoOfStatusCount,
          ClassName: getAssessmentTypeStatusColor(status.Id),
        } as HPRMaterialReactTableStatusSummaryType;
      }
    );

  // Page onload
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    setAssessmentTypeWizardsPageState(initialState);
    dispatch(
      assessmentTypeWizardsReducerActions.resetAssessmentTypeWizardList()
    );

    dispatch(
      getAssessmentTypeWizardList({
        ...initialState?.AssessmentTypeWizardSearchParameter,
      })
    );

    //clear parameter
    navigate(location.pathname, { replace: true });
  }, []);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setAssessmentTypeWizardsPageState((values) => {
      return {
        ...values,
        Data: {
          ...values.Data,
          Data: assessmentTypeWizardListState?.Data?.Data,
          TotalRecordsCount:
            assessmentTypeWizardListState?.Data?.TotalRecordsCount,
          SelectedPage: assessmentTypeWizardListState?.Data?.SelectedPage,
          PageSize: assessmentTypeWizardListState?.Data?.PageSize,
        } as PaginationDTO,
        AssessmentTypeWizardSearchParameter: {
          ...values?.AssessmentTypeWizardSearchParameter,
          SelectedPage: assessmentTypeWizardListState?.Data?.SelectedPage,
          ListPageSize: assessmentTypeWizardListState?.Data?.PageSize,
        } as AssessmentTypeDTO,
        AssessmentTypeStatusSummary:
          assessmentTypeWizardListState?.AssessmentTypeStatusSummary,
      } as AssessmentTypeWizardsPageType;
    });
  }, [assessmentTypeWizardListState]);

  //--------------------- Page Navigate <Start> ------------------------------//
  const onClickBackButton = () => {
    navigate("/");
  };

  const onClickAddBtn = () => {
    navigate("/AssessmentTypeWizard", {
      state: { AssessmentTypeId: 0 },
    });
  };

  const onRowClickedCallBack = (assessmentType: AssessmentTypeDTO) => {
    navigate("/AssessmentTypeWizard", {
      state: { AssessmentTypeId: assessmentType.Id },
    });
  };
  //--------------------- Page Navigate <End> ------------------------------//

  //--------------------- Page Function Search <Start> ------------------------------//

  const getFilterDateText = (fromDate: string, toDate: string): string => {
    let filterDateText = "";

    if (fromDate && toDate) {
      filterDateText = `From ${fromDate} to ${toDate}`;
    } else if (fromDate) {
      filterDateText = `From ${fromDate} up to now`;
    } else if (toDate) {
      filterDateText = `Until ${toDate}`;
    }

    return filterDateText;
  };

  const searchAssessmentTypeWizard = (
    assessmentTypeSearchParamter: AssessmentTypeDTO
  ) => {
    const columnFilteredSummary = assessmentTypeSearchParamter.FilterColumnWise
      ? ([
          {
            Field: "AssessmentType-1",
            Value: assessmentTypeSearchParamter?.AssessmentTypeName,
          },
          {
            Field: "Description-2",
            Value: assessmentTypeSearchParamter?.Description,
          },
          {
            Field: "Code-3",
            Value: assessmentTypeSearchParamter?.Code,
          },
          {
            Field: "Status-4",
            Value: assessmentTypeSearchParamter?.StatusNavigation?.Status1,
          },
          {
            Field: "CreatedBy-5",
            Value: assessmentTypeSearchParamter?.CreatedBy?.DisplayName,
          },
          {
            Field: "CreatedDateAndTime-6",
            Value: getFilterDateText(
              assessmentTypeSearchParamter?.CreatedDateFrom,
              assessmentTypeSearchParamter?.CreatedDateTo
            ),
          },
          {
            Field: "ModifiedBy-7",
            Value:
              assessmentTypeSearchParamter?.ModifiedByNavigation?.DisplayName,
          },
          {
            Field: "ModifiedDateAndTime-8",
            Value: getFilterDateText(
              assessmentTypeSearchParamter?.ModifiedDateFrom,
              assessmentTypeSearchParamter?.ModifiedDateTo
            ),
          },
        ] as Array<HPRMaterialReactTableColumnFilteredSummaryType>)
      : ([] as Array<HPRMaterialReactTableColumnFilteredSummaryType>);

    setAssessmentTypeWizardsPageState((values) => {
      return {
        ...values,
        AssessmentTypeWizardSearchParameter: assessmentTypeSearchParamter,
        ColumnFilteredSummary: columnFilteredSummary,
      };
    });

    dispatch(getAssessmentTypeWizardList(assessmentTypeSearchParamter));
  };

  const onClickBasicSerachBtn = () => {
    if (
      assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        .BasicSearchValue !==
      assessmentTypeWizardListState.AssessmentTypeWizardSearchParameter
        .BasicSearchValue
    ) {
      const assessmentTypeSearchParamter = {
        ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
        BasicSearchValue:
          assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
            .BasicSearchValue,
        SelectedPage: 1,
      } as AssessmentTypeDTO;

      searchAssessmentTypeWizard(assessmentTypeSearchParamter);
    }
  };

  const onChangeSearchName = (searchValue: string) => {
    setAssessmentTypeWizardsPageState((values) => {
      return {
        ...values,
        AssessmentTypeWizardSearchParameter: {
          ...values.AssessmentTypeWizardSearchParameter,
          BasicSearchValue: searchValue,
        },
      };
    });

    if (!searchValue || searchValue === "") {
      const assessmentTypeSearchParamter = {
        ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
        BasicSearchValue: "",
        SelectedPage: 1,
      } as AssessmentTypeDTO;

      searchAssessmentTypeWizard(assessmentTypeSearchParamter);
    }
  };

  const onKeyPressSearchName = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      onClickBasicSerachBtn();
    }
  };

  const onClickStatusSummaryCallBack = (status: number) => {
    const assessmentTypeSearchParamter = {
      ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
      Status: status,
      SelectedPage: 1,
    } as AssessmentTypeDTO;

    searchAssessmentTypeWizard(assessmentTypeSearchParamter);
  };

  const onPaginationChangeCallBack = (newPage: number, pageSize: number) => {
    let assessmentTypeSearchParamter = {
      ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
    } as AssessmentTypeDTO;

    if (
      assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        ?.FilterColumnWise &&
      (assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        .SelectedPage !== newPage ||
        assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
          .ListPageSize !== pageSize)
    ) {
      dispatch(
        assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(true)
      );
      assessmentTypeSearchParamter = {
        ...assessmentTypeSearchParamter,
        SelectedPage: newPage,
        ListPageSize: pageSize,
        FilterColumnWise: true,
      };
    } else if (
      assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        .ListPageSize !== pageSize
    ) {
      assessmentTypeSearchParamter = {
        ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
        SelectedPage: 1,
        ListPageSize: pageSize,
        FilterColumnWise: false,
      };
    } else if (
      assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        .SelectedPage !== newPage
    ) {
      assessmentTypeSearchParamter = {
        ...assessmentTypeSearchParamter,
        SelectedPage: newPage,
        ListPageSize: pageSize,
        FilterColumnWise: false,
      };
    }

    searchAssessmentTypeWizard(assessmentTypeSearchParamter);
  };

  const onChangeAssessmentTypeWizardSortByCallBack = (
    selectedSortState: SortDropDownDTO
  ) => {
    if (
      Object.keys(selectedSortState)?.length === 0 ||
      JSON.stringify(
        assessmentTypeWizardsPageState?.AssessmentTypeWizardSearchParameter
          ?.SortByNavigation
      ) === JSON.stringify(selectedSortState)
    )
      return;

    let assessmentTypeSearchParamter = {
      ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
    } as AssessmentTypeDTO;

    if (
      assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        ?.FilterColumnWise
    ) {
      dispatch(
        assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(true)
      );

      assessmentTypeSearchParamter = {
        ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
        SortByNavigation: selectedSortState,
        SelectedPage: 1,
        FilterColumnWise: true,
      } as AssessmentTypeDTO;
    } else {
      assessmentTypeSearchParamter = {
        ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
        SortByNavigation: selectedSortState,
        SelectedPage: 1,
        FilterColumnWise: false,
      } as AssessmentTypeDTO;
    }

    searchAssessmentTypeWizard(assessmentTypeSearchParamter);
  };

  const columnWiseFilterApply = (
    isColumnWiseFilter: boolean,
    columnSearchParameter: AssessmentTypeDTO
  ) => {
    if (isColumnWiseFilter) {
      dispatch(
        assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(true)
      );

      searchTimeout.current = setTimeout(async () => {
        searchAssessmentTypeWizard({
          ...columnSearchParameter,
          SelectedPage: 1,
          FilterColumnWise: true,
        });

        searchTimeout.current = null;
      }, 1000);
    } else if (
      assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
        .FilterColumnWise
    ) {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      searchTimeout.current = null;
      dispatch(
        assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(true)
      );
      searchTimeout.current = null;

      searchAssessmentTypeWizard({
        ...columnSearchParameter,
        SelectedPage: 1,
        FilterColumnWise: false,
      });
    }
  };

  const onColumnWiseFilterDateChange = (name: string, date: string) => {
    let columnSearchParameter = {
      ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
      [name]: getFormatedDateForBackend(date),
    } as AssessmentTypeDTO;

    const isColumnWiseFilter = !(
      !columnSearchParameter?.AssessmentTypeName &&
      !columnSearchParameter?.Description &&
      !columnSearchParameter?.Code &&
      !columnSearchParameter?.StatusNavigation?.Status1 &&
      !columnSearchParameter?.CreatedBy?.DisplayName &&
      !columnSearchParameter?.CreatedDateFrom &&
      !columnSearchParameter?.CreatedDateTo &&
      !columnSearchParameter?.ModifiedByNavigation?.DisplayName &&
      !columnSearchParameter?.ModifiedDateFrom &&
      !columnSearchParameter?.ModifiedDateTo
    );

    dispatch(
      assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(true)
    );

    searchAssessmentTypeWizard({
      ...columnSearchParameter,
      SelectedPage: 1,
      FilterColumnWise: isColumnWiseFilter,
    });
  };

  const onColumnWiseFiltersCallBack = (columns: Array<ColumnFiltertypeDTO>) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    let assessmentType = "";
    let description = "";
    let code = "";
    let status = "";
    let createdBy = "";
    // let createdDateFrom = null;
    // let createdDateTo = null;
    let modifiedBy = "";
    // let modifiedDateFrom = null;
    // let modifiedDateTo = null;

    columns?.map((column) => {
      if (column.Column === "AssessmentType-1") {
        assessmentType = column.Value;
      } else if (column.Column === "Description-2") {
        description = column.Value;
      } else if (column.Column === "Code-3") {
        code = column.Value;
      } else if (column.Column === "Status-4") {
        status = column.Value;
      } else if (column.Column === "CreatedBy-5") {
        createdBy = column.Value;
      }
      // else if (column.Column === "CreatedDateAndTime-6") {
      //   createdDateFrom = getFormatedDateForBackend(column.Value[0]);
      //   createdDateTo = getFormatedDateForBackend(column.Value[1]);
      // }
      else if (column.Column === "ModifiedBy-7") {
        modifiedBy = column.Value;
      }
      // else if (column.Column === "ModifiedDateAndTime-8") {
      //   modifiedDateFrom = getFormatedDateForBackend(column.Value[0]);
      //   modifiedDateTo = getFormatedDateForBackend(column.Value[1]);
      // }
    });

    const columnSearchParameter = {
      ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter,
      AssessmentTypeName: assessmentType,
      Description: description,
      Code: code,
      StatusNavigation: {
        ...assessmentTypeWizardsPageState.AssessmentTypeWizardSearchParameter
          .StatusNavigation,
        Status1: status,
      } as StatusDTO,
      CreatedBy: { DisplayName: createdBy } as EmployeeDTO,
      ModifiedByNavigation: { DisplayName: modifiedBy } as EmployeeDTO,
    } as AssessmentTypeDTO;

    const isColumnWiseFilter = columns?.some(
      (column) =>
        column.Value !== undefined &&
        column.Value !== null &&
        column.Value !== ""
    );

    columnWiseFilterApply(isColumnWiseFilter, columnSearchParameter);
  };

  //--------------------- Page Function Search <End> ------------------------------//

  return (
    <div
      className="content-section-card rem-tracker top-main-padding"
      ref={wrapperCard}
    >
      <Grid container>
        <div
          className="flex--container fx-wrap width100-p top-main-border mb-10 rem-btn-wrap"
          ref={mainHeading}
        >
          <div className="flex__item--inherit pr-10 pl-10">
            <h2>
              <Button
                aria-label="back"
                className="sub-head-back-btn"
                onClick={onClickBackButton}
                title="Back to home page"
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </h2>
          </div>
          <div className="global-header-wrap rem-trak-head-res">
            <h1>Assessment Type</h1>
          </div>
          <div className="flex__item search-bar--xsmall mr-10 pr-20 fx-around">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InputBase
                value={
                  assessmentTypeWizardsPageState
                    ?.AssessmentTypeWizardSearchParameter?.BasicSearchValue
                }
                onChange={(event) => {
                  onChangeSearchName(event.target?.value);
                }}
                onKeyPress={onKeyPressSearchName}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Type/Description/Code"
                inputProps={{ "aria-label": "name" }}
                // type="search"
              />

              {isMouseOver && (
                <Tooltip title="Clear" placement="top">
                  <IconButton
                    className="mr-0"
                    onClick={() => onChangeSearchName("")}
                  >
                    <CloseIcon style={{ color: "#219ebc", fontSize: "20px" }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Search" placement="top">
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                  onClick={onClickBasicSerachBtn}
                >
                  <SearchNormal1
                    variant="Outline"
                    className="ico-secondary"
                    size={18}
                  />
                </IconButton>
              </Tooltip>
            </Paper>
          </div>
          <div className="flex__item--inherit text-right sub-header-btn-wrap pr-10 pl-10">
            {checkSystemFunctionRight(
              EnumSystemFunctions.AssessmentTypeWizardPage_Add_Assessment_Type,
              EnumSystemFunctionTypes.ButtonAccess
            ) && (
              <Button
                className="primary-btn-small"
                variant="outlined"
                startIcon={<AddCircle variant="Outline" />}
                onClick={onClickAddBtn}
                title="Add"
              >
                Add
              </Button>
            )}
          </div>
        </div>

        <div style={{ maxHeight: tableHeight, width: "100%"}}>
          <Grid container>
            <Grid
              item
              xs={12}
              // style={{ height: 500 }}
              className="grid-height pl-10 pr-10 hpr-material-table width100-p"
            >
              <HPRMaterialReactTable
                Columns={columns}
                Data={assessmentTypeWizardsPageState.Data?.Data}
                PaginationProps={{
                  TotalRecordsCount:
                    assessmentTypeWizardsPageState.Data?.TotalRecordsCount,
                  PageSize: assessmentTypeWizardsPageState.Data?.PageSize,
                  SelectedPage:
                    assessmentTypeWizardsPageState.Data?.SelectedPage,
                  onPaginationChangeCallBack: onPaginationChangeCallBack,
                }}
                //EnableColumnFilters
                EnableSorting
                EnableRowClick={checkSystemFunctionRight(
                  EnumSystemFunctions.AssessmentTypeWizardPage_View_Assessment_Type,
                  EnumSystemFunctionTypes.ViewPage
                )}
                CallbackFunctionProps={{
                  onRowClickedCallBack: onRowClickedCallBack,
                  onChangeSortByCallBack:
                    onChangeAssessmentTypeWizardSortByCallBack,
                  onColumnFiltersCallBack: onColumnWiseFiltersCallBack,
                }}
                ToolBarProps={{
                  StatusSummaryProps: {
                    SelectedStatus:
                      assessmentTypeWizardsPageState
                        .AssessmentTypeWizardSearchParameter?.Status,
                    StatusSummary: assessmentTypeStatusSummary,
                    onClickStatusSummaryCallBack: onClickStatusSummaryCallBack,
                  },
                }}
                FilterColumnWise={
                  assessmentTypeWizardsPageState
                    .AssessmentTypeWizardSearchParameter?.FilterColumnWise ??
                  false
                }
                ColumnFilteredSummary={
                  assessmentTypeWizardsPageState.ColumnFilteredSummary ?? []
                }
                ShowDataLoading={assessmentTypeWizardListState.DataLoading}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
export default AssessmentTypeWizardsPage;
