/** @format */

//-------common imports-----//

import { Box, Button, Grid, Modal, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  CardTick,
  CloseCircle,
  CloseSquare,
  Edit2,
  InfoCircle,
  Document,
} from "iconsax-react";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import {
  DropDownDTO,
  InspectionDTO,
  InspectionMemberDTO,
  InspectionQuestionDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
  StandardDTO,
  StandardSmeDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import {
  CopyInspectionStandardDTO,
  QuestionMainDataType,
  ReopenInspectionStandardData,
  SiteInspectionStartDataType,
  SiteInspectionStartPageParametersType,
} from "./SiteInspectionStartPage.types";
import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  completeInspection,
  completeInspectionStandard,
  copyInspectionStandard,
  getGlobalSMEAssignmentPopupData,
  getSiteInspectionStartData,
  reOpenInspectionStandard,
  reopenCompleteInspection,
  resumeInspectionStandard,
  saveAssignedGlobalSMEs,
  saveInspectionSiteTeamMember,
} from "./SiteInspectionStartPage.action";
import {
  clearPageModeRedirectMode,
  setPageMode,
} from "./SiteInspectionStartPage.reducer";
import {
  EnumInspectionQuestionStatus,
  EnumInspectionStandardStatus,
  EnumInspectionStatus,
  EnumPageMode,
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import {
  openPopup,
  updatePopupProps,
} from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRLinedTextArea from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea";
import { QuestionnaireStartPageParametersTypes } from "../QuestionnaireStartPage/QuestionnaireStartPage.types";
import { EnumLinedTextAreaType } from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea.enum";
import { QuestionnaireSummaryParameterDTO } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import dayjs from "dayjs";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import StandardExcelReportDownload from "../../Reports/StandardReport/StandardReport.Excel";
import InspectionExcelReportDownload from "../../Reports/StandardsSummaryReport/StandardsSummaryReport.Excel";
import ReportViewerNew from "../../../components/ReportViewer/ReportViewerNew";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { QuestionnaireParametersType } from "../QuestionnairePage/QuestionnairePage.types";
import { viewInspectionSummarySliceReducerActions } from "../../components/ViewInspectionSummary/ViewInspectionSummary.reducer";
import {
  EnumInspectionSummaryType,
  ViewInspectionSummaryProps,
} from "../../components/ViewInspectionSummary/ViewInspectionSummary.types";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import HPRReportMenu from "../../../components/common/HPRReportMenu/HPRReportMenu";
import {
  EnumReportFormatType,
  HPRReportMenuType,
} from "../../../components/common/HPRReportMenu/HPRReportMenu.types";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: true,
    IsShowAddBtn: false,
    IsShowEditBtn: true,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Inspection: {} as InspectionDTO,
  SiteTeamMemberName: "",
  InspectionName: "",
  InspectonDescription: "",
  InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
  QuestionMainData: [] as Array<QuestionMainDataType>,
  InspectionMembers: [] as Array<InspectionMemberDTO>,
  AllowResumption: true,
  InspectionStandardIdToResume: [],
  ReopenUpdateData: [] as Array<ReopenInspectionStandardData>,
  UpdateOnlySMEData: false,
  ShowGlobalSMEAssignmentPopup: false,
  TempGlobalSMEAssignmentObject: {},
} as SiteInspectionStartDataType;

const SiteInspectionStartPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for StandardPageType with initialState values
  const [siteInspectionStartPageState, setSiteInspectionStartPageState] =
    useState(initialState);

  // pass parameters to page
  // use for page navigation
  const navigate = useNavigate();
  const authState = useAppSelector((state) => state.authReducer);
  const reportState = useAppSelector((state) => state.reportReducer);
  const location = useLocation();
  const parameter: SiteInspectionStartPageParametersType = location.state;

  const FetchedRef = useRef(false);

  const [isDirty, setIsDirty] = useState(false);
  //const [discardOpen, setDiscardOpen] = useState(false);
  const prompt = {
    Message: "Do you want to discard site team member changes?",
    When: isDirty,
  } as UsePromptTypes;
  usePrompt(prompt);

  const initCopyModalState: CopyInspectionStandardDTO = {
    Id: 0,
    SelectedBuildingId: 0,
    Suffix: "",
    Location: "",
    RelatedEntity: "",
    Title: "",
  };
  const [copyModalState, setCopyModalState] =
    useState<CopyInspectionStandardDTO>(initCopyModalState);

  const dispatch = useAppDispatch();
  const siteInspectionStartState = useAppSelector(
    (state) => state.siteInspectionStartReducer
  );

  // const [buildingList, setBuildingList] = useState<Array<DropDownDTO>>();
  const containerHeight = useRef(null);
  const siteInspectionHeader = useRef(null);
  //Set Height
  const [siteInspectionHeight, setSiteInspectionHeight] = useState(0);
  //Page onload
  useEffect(() => {
    if (FetchedRef.current) return;
    FetchedRef.current = true;
    if (parameter.InspectionId > 0) {
      dispatch(getSiteInspectionStartData(parameter.InspectionId));
    } else {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          Inspection: initialState.Inspection,
        };
      });
    }

    let siteInspectionHeight =
      containerHeight?.current?.offsetHeight -
      siteInspectionHeader?.current?.offsetHeight -
      52;

    setSiteInspectionHeight(siteInspectionHeight);

    function handleResize() {
      let siteInspectionHeight =
        containerHeight?.current?.offsetHeight -
        siteInspectionHeader?.current?.offsetHeight -
        52;

      setSiteInspectionHeight(siteInspectionHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // every time when reducer state change set values to page state
  useEffect(() => {
    if (!FetchedRef.current) return;

    let questionMainData = siteInspectionStartState.QuestionMainData;
    let inspectionQuestionList =
      siteInspectionStartState.Inspection.InspectionQuestions;
    let questionMainDataTemp = [];
    let tempQuestionList = [];
    if (
      siteInspectionStartState.AllowResumption &&
      siteInspectionStartState.InspectionStandardIdToResume.length > 0
    ) {
      questionMainDataTemp = questionMainData.map((item) => {
        var temp = Object.assign({}, item);
        if (
          siteInspectionStartState.InspectionStandardIdToResume.includes(
            temp.InspectionStandardId
          )
        ) {
          let reOpenedObject = siteInspectionStartState.ReopenUpdateData.find(
            (x) => x.InspectionStandardId === temp.InspectionStandardId
          );

          return {
            ...item,
            InspectionStandardStatus: EnumInspectionStandardStatus.Inprogress,
            InspectionStandardReOpenedBy: reOpenedObject?.ReopenedBy,
            InspectionStandardReOpenedDate: reOpenedObject?.ReopenedDate,
            InspectionStandardStatusName: reOpenedObject?.StatusName,
            PendingCount: temp.SkippedCount,
            SkippedCount: 0,
          };
        }
        return item;
      });

      questionMainData = questionMainDataTemp;
      tempQuestionList =
        siteInspectionStartState.Inspection.InspectionQuestions.map((x) => {
          if (
            siteInspectionStartState.InspectionStandardIdToResume.includes(
              x.InspectionStandardId
            ) &&
            x.InspectionQuestionStatusId ===
              EnumInspectionQuestionStatus.Skipped
          ) {
            return {
              ...x,
              InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
            };
          }
          return x;
        });
      inspectionQuestionList = tempQuestionList;
    }
    if (siteInspectionStartState.UpdateOnlySMEData) {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          Inspection: siteInspectionStartState.Inspection,
          QuestionMainData: questionMainData,
          ShowGlobalSMEAssignmentPopup:
            siteInspectionStartState.ShowGlobalSMEAssignmentPopup,
          TempGlobalSMEAssignmentObject:
            siteInspectionStartState.TempGlobalSMEAssignmentObject,
        };
      });
    } else {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          Inspection: siteInspectionStartState.Inspection,
          InspectionName: siteInspectionStartState.Inspection.Name,
          InspectonDescription: siteInspectionStartState.Inspection.Description,
          QuestionMainData: questionMainData,
          InspectionMembers: siteInspectionStartState.InspectionMembers,
          SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
          PageMode: siteInspectionStartState.PageMode,
          InspectionQuestionList: inspectionQuestionList,

          AllowResumption: siteInspectionStartState.AllowResumption,
          InspectionStandardIdToResume:
            siteInspectionStartState.InspectionStandardIdToResume,
          ShowGlobalSMEAssignmentPopup:
            siteInspectionStartState.ShowGlobalSMEAssignmentPopup,
          TempGlobalSMEAssignmentObject:
            siteInspectionStartState.TempGlobalSMEAssignmentObject,
        };
      });
    }

    //NAVIGATION
    if (siteInspectionStartState?.PageMode?.RedirctOption?.NavigateURl !== "") {
      if (
        siteInspectionStartState?.PageMode?.RedirctOption?.NavigateURl ===
        "/remediationTrackerTasks"
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: `Inspection "${siteInspectionStartState.Inspection.Name}" closed and tasks were created in Remediation Tracker`,
            HeaderText: `Closing inspection - ${siteInspectionStartState.Inspection.Name}`,
            PopupType: EnumPopupType.SuccessPopup,
            OkButtonText: "Close",
            NoButtonText: "Go to Remediation Tracker",
            NoBtnClick() {
              navigate("/remediationTrackerTasks");
            },
          } as PopupProps)
        );
      } else {
        navigate(
          siteInspectionStartState.PageMode.RedirctOption.NavigateURl,
          siteInspectionStartState.PageMode.RedirctOption.NavigateObject
        );
      }
      dispatch(clearPageModeRedirectMode());
    }

    // if (siteInspectionStartState.PageMode.PageMode !== EnumPageMode.Default) {
    //   if (siteInspectionStartState.Inspection.Site.Buildings) {
    //     const dropDownDTOList: DropDownDTO[] =
    //       siteInspectionStartState.Inspection.Site.Buildings.map(
    //         (building) =>
    //           ({
    //             Value: building.Id.toString(),
    //             Label: building.BuildingName,
    //           } as DropDownDTO)
    //       );
    //     setBuildingList(dropDownDTOList);
    //   }
    // }
  }, [siteInspectionStartState]);

  useEffect(() => {
    if (siteInspectionStartPageState.ShowGlobalSMEAssignmentPopup) {
      dispatch(
        openPopup({
          Open: true,
          HeaderText: "Global SME Assignment",
          LableText: "Global SME Assignment",
          Required: true,
          CancelButtonText: "Cancel",
          YesButtonText: "Save",
          PopupType: EnumPopupType.EmployeeDropdownPopup,
          EmployeeDropdownList:
            siteInspectionStartPageState.TempGlobalSMEAssignmentObject
              .StandardSMEDropdownList,
          SelectedEmployeeList:
            siteInspectionStartPageState.TempGlobalSMEAssignmentObject
              .SelectedSMEList,
          IsSearchable: false,

          EmployeeDropdownActionBtnClick: (value, closePopup) => {
            let inspectionStandard = {
              Id: siteInspectionStartPageState.TempGlobalSMEAssignmentObject.Id,
              InspectionId:
                siteInspectionStartPageState.TempGlobalSMEAssignmentObject
                  .InspectionId,
              SelectedSMEList: value,
            } as InspectionStandardDTO;
            handleSaveGlobalAssignmentSME(
              inspectionStandard,
              siteInspectionStartPageState.TempGlobalSMEAssignmentObject
                .SelectedSMEList,
              siteInspectionStartPageState.TempGlobalSMEAssignmentObject
                .InspectionStandardStatusId,
              closePopup
            );
          },
        } as PopupProps)
      );

      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          ShowGlobalSMEAssignmentPopup: false,
        };
      });
    }
  }, [siteInspectionStartPageState.ShowGlobalSMEAssignmentPopup]);

  const OnTextAreaChange = (name: string, value: any) => {
    if (siteInspectionStartPageState?.InspectionMembers.length > 0) {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          SiteTeamMemberName: value,
          InspectionMembers:
            siteInspectionStartPageState?.InspectionMembers.map(
              (member: InspectionMemberDTO, index) => {
                return {
                  ...member,
                  MemberName: value,
                } as InspectionMemberDTO;
              }
            ),
        };
      });
    } else {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          SiteTeamMemberName: value,
          InspectionMembers: [
            ...values.InspectionMembers,
            {
              MemberName: value,
              SiteInspectionId: siteInspectionStartPageState.Inspection.Id,
            } as InspectionMemberDTO,
          ],
        };
      });
    }

    setIsDirty(true);
  };

  const onClickSaveBtn = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to save?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            saveInspectionSiteTeamMember(
              siteInspectionStartPageState?.InspectionMembers
            )
          );
          setIsDirty(false);
        },
      } as PopupProps)
    );
  };

  const checkSiteMemberAdd = () => {
    let prviousSiteMemberCount = 0;
    let newSiteMemberCount = 0;
    let isValidate = false;

    if (siteInspectionStartState.InspectionMembers.length > 0) {
      prviousSiteMemberCount =
        siteInspectionStartState.InspectionMembers[0].MemberName.split(
          "@@"
        ).length;
    }
    if (siteInspectionStartPageState?.InspectionMembers.length > 0) {
      newSiteMemberCount =
        siteInspectionStartPageState.InspectionMembers[0].MemberName.split(
          "@@"
        ).length;
    }

    if (prviousSiteMemberCount !== newSiteMemberCount) {
      isValidate = true;
    }

    return isValidate;
  };

  const onClickCompleteBtn = (
    saveStatus?: EnumInspectionStandardStatus,
    standardId?: number,
    inspectionId?: number,
    inspectionStandardId?: number
  ): void => {
    CompleteInspection(
      saveStatus,
      standardId,
      inspectionId,
      inspectionStandardId
    );
  };

  function CompleteInspection(
    saveStatus?: EnumInspectionStandardStatus,
    standardId?: number,
    inspectionId?: number,
    inspectionStandardId?: number
  ): void {
    let questionPendingList =
      siteInspectionStartPageState.InspectionQuestionList.filter(
        (obj: InspectionQuestionDTO) => {
          if (
            obj.StandardId === standardId &&
            obj.SiteInspectionId === inspectionId &&
            obj.InspectionStandardId === inspectionStandardId &&
            obj.InspectionQuestionStatusId ===
              EnumInspectionQuestionStatus.Pending
          ) {
            return obj;
          }
        }
      );

    dispatch(
      completeInspectionStandard({
        InspectionQuestionList: questionPendingList,
        SiteInspectionID: inspectionId,
        StandardID: standardId,
        InspectionStandardStatus: saveStatus,
        InspectionStandardId: inspectionStandardId,
        UploadedFileList: [] as Array<UploadFileDTO>,
      } as QuestionnaireDTO)
    );
  }

  function backOnClick(): void {
    //setDiscardOpen(true); //used to counter discard popup
    // if (checkSiteMemberAdd()) {
    //   dispatch(
    //     openPopup({
    //       Open: true,
    //       BodyText: "Do you want to discard site team member changes?",
    //       HeaderText: "Confirmation",
    //       PopupType: EnumPopupType.YesNoConfirmation,
    //       YesBtnClick() {
    //         // setSiteInspectionStartPageState((values) => {
    //         //   return {
    //         //     ...values,
    //         //     InspectionMembers: [],
    //         //     SiteTeamMemberName: "",
    //         //   };
    //         // });
    //         // setIsDirty(false);
    //         navigate("/siteInspections", {});
    //       },
    //       // NoBtnClick() {
    //       //   setDiscardOpen(false);
    //       // },
    //     } as PopupProps)
    //   );
    // } else {
    navigate("/siteInspections", {});
    //}
  }

  const onClickCloseInspection = (): void => {
    if (checkSiteMemberAdd()) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                InspectionMembers: [],
                SiteTeamMemberName: "",
              };
            });
            setIsDirty(false);
            getInspectionSummary();
          },
        } as PopupProps)
      );
    } else if (
      siteInspectionStartPageState.SiteTeamMemberName !==
      siteInspectionStartState.SiteTeamMemberName
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
              };
            });
            setIsDirty(false);
            getInspectionSummary();
          },
        } as PopupProps)
      );
    } else {
      setIsDirty(false);
      getInspectionSummary();
    }
  };

  const onClickInspectionStatus = (): void => {
    getInspectionStatus();
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
    //setIsDirty(true);
  };

  const onClickCloseBtn = () => {
    if (checkSiteMemberAdd()) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                InspectionMembers: [],
                SiteTeamMemberName: "",
              };
            });
            setIsDirty(false);
            dispatch(setPageMode(EnumPageMode.ViewMode));
          },
        } as PopupProps)
      );
    } else if (
      siteInspectionStartPageState.SiteTeamMemberName !==
      siteInspectionStartState.SiteTeamMemberName
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
              };
            });
            setIsDirty(false);
            dispatch(setPageMode(EnumPageMode.ViewMode));
          },
        } as PopupProps)
      );
    } else {
      setIsDirty(false);
      dispatch(setPageMode(EnumPageMode.ViewMode));
    }
  };

  const onClickReOpen = (mainData: QuestionMainDataType) => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: `Are you sure you want to re-open "${
          mainData.InspectionStandardName
        }${mainData.Suffix ? " - " + mainData.Suffix : ""}" standard`,
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            reOpenInspectionStandard({
              SiteInspectionId: mainData.InspectionId,
              StandardId: mainData.StandardId,
              InspectionStandardId: mainData.InspectionStandardId,
              InspectionStandardName: mainData.Suffix
                ? mainData.InspectionStandardName + " - " + mainData.Suffix
                : mainData.InspectionStandardName,
            } as QuestionnaireSummaryParameterDTO)
          );
        },
      } as PopupProps)
    );
  };
  // modal popup
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  //------------------------------------

  useEffect(() => {
    if (siteInspectionStartState.CloseCopyPopup) {
      if (open) {
        setOpen(false);
      }
    }
  }, [siteInspectionStartState.CloseCopyPopup]);

  function handleOpenInNewTab() {
    const searchParams = new URLSearchParams({
      ReportType: EnumReportType.SummeryReport.toString(),
      InspectionID: siteInspectionStartPageState.Inspection.Id.toString(),
    });

    window.open(`/report?${searchParams}`, "_blank");
  }

  function handleOpenCopyModal(inspectionStandard: QuestionMainDataType) {
    if (isDirty) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setIsDirty(false);
            // console.log(inspectionStandard);
            setCopyModalState((prevState) => ({
              ...prevState,
              Id: inspectionStandard.InspectionStandardId,
              InspectionId: parameter.InspectionId,

              Suffix: "copy",
              Title: inspectionStandard.InspectionStandardName,
            }));
            setOpen(true);
          },
        } as PopupProps)
      );
    } else {
      // console.log(inspectionStandard);
      setCopyModalState((prevState) => ({
        ...prevState,
        Id: inspectionStandard.InspectionStandardId,
        InspectionId: parameter.InspectionId,

        Suffix: "copy",
        Title: inspectionStandard.InspectionStandardName,
      }));
      setOpen(true);
    }
  }

  function onClickCreateCopy() {
    dispatch(copyInspectionStandard(copyModalState));
    //setOpen(false);
  }

  function onChangeBuilding(
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string
  ) {
    // console.log(selectOption);
    setCopyModalState((prevState) => ({
      ...prevState,
      SelectedBuildingId: parseInt(selectedValue),
    }));
  }

  const OnGenaratingReport = (blob: any): void => {
    if (blob._INTERNAL__LAYOUT__DATA_.children.length > 2) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }
  };

  function onClickResume(mainData: QuestionMainDataType) {
    dispatch(resumeInspectionStandard(mainData.InspectionStandardId));
    //navigate("/questionnaire", {
    //    state: {
    //        SiteInspectionID: mainData.InspectionId,
    //        StandardID: mainData.StandardId,
    //        InspectionStandardId: mainData.InspectionStandardId,
    //    } as QuestionnaireParametersType,
    //});

    navigate("/questionnaireStart", {
      state: {
        InspectionStandardId: mainData.InspectionStandardId,
        StandardId: mainData.StandardId,
        InspectionId: mainData.InspectionId,
        Title: mainData.Suffix
          ? mainData.InspectionStandardName + " - " + mainData.Suffix
          : mainData.InspectionStandardName,
        IsContinueButtonVisible: true,
      } as QuestionnaireStartPageParametersTypes,
    });
  }

  const getInspectionSummary = () => {
    // dispatch(
    //   getSummaryOfInspectionBeforeClose({
    //     InspectionId: siteInspectionStartPageState.Inspection?.Id,
    //   } as InspectionStandardDTO)
    // );
    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.Inspection,
        SerachInspectionStandard: {
          InspectionId: siteInspectionStartPageState.Inspection?.Id,
        } as InspectionStandardDTO,
        onCompleteBtnClick(inspectionSummary: InspectionDTO) {
          onClickCompleteInspectionBtn(inspectionSummary);
        },
      } as ViewInspectionSummaryProps)
    );
  };

  const getInspectionStatus = () => {
    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.InspectionStatus,
        SerachInspectionStandard: {
          InspectionId: siteInspectionStartPageState.Inspection?.Id,
        } as InspectionStandardDTO,
      } as ViewInspectionSummaryProps)
    );
  };

  const onClickCompleteInspectionBtn = (
    inspectionSummary: InspectionDTO
  ): void => {
    dispatch(completeInspection(inspectionSummary));
  };

  //standard wise close
  const getInspectionStandardSummary = (inspectionStandardId: number) => {
    if (isDirty) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setIsDirty(false);
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
              };
            });
            dispatch(
              viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails(
                {
                  InspectionSummaryType:
                    EnumInspectionSummaryType.InspectionStandard,
                  SerachInspectionStandard: {
                    Id: inspectionStandardId,
                    InspectionId: siteInspectionStartPageState.Inspection?.Id,
                  } as InspectionStandardDTO,
                  onCompleteBtnClick(inspectionSummary: InspectionDTO) {
                    if (inspectionSummary?.InspectionStandards?.length > 0) {
                      const standardId =
                        inspectionSummary?.InspectionStandards[0].Standard?.Id;
                      const inspectionId =
                        inspectionSummary?.InspectionStandards[0].InspectionId;
                      const inspectionStandardId =
                        inspectionSummary?.InspectionStandards[0].Id;

                      onClickCompleteBtn(
                        EnumInspectionStandardStatus.Completed,
                        standardId,
                        inspectionId,
                        inspectionStandardId
                      );
                    }
                  },
                } as ViewInspectionSummaryProps
              )
            );
            dispatch(setPageMode(EnumPageMode.ViewMode));
          },
        } as PopupProps)
      );
    } else {
      dispatch(
        viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
          InspectionSummaryType: EnumInspectionSummaryType.InspectionStandard,
          SerachInspectionStandard: {
            Id: inspectionStandardId,
            InspectionId: siteInspectionStartPageState.Inspection?.Id,
          } as InspectionStandardDTO,
          onCompleteBtnClick(inspectionSummary: InspectionDTO) {
            if (inspectionSummary?.InspectionStandards?.length > 0) {
              const standardId =
                inspectionSummary?.InspectionStandards[0].Standard?.Id;
              const inspectionId =
                inspectionSummary?.InspectionStandards[0].InspectionId;
              const inspectionStandardId =
                inspectionSummary?.InspectionStandards[0].Id;

              onClickCompleteBtn(
                EnumInspectionStandardStatus.Completed,
                standardId,
                inspectionId,
                inspectionStandardId
              );
            }
          },
        } as ViewInspectionSummaryProps)
      );
      dispatch(setPageMode(EnumPageMode.ViewMode));
    }
  };

  const onClickReopenInspectionBtn = (): void => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to re-open inspection?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            reopenCompleteInspection({
              SiteInspectionID: siteInspectionStartPageState.Inspection.Id,
            } as QuestionnaireDTO)
          );
        },
      } as PopupProps)
    );
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.SiteInspectionStart,
      functionId,
      functionTypeId
    );
  };

  const handleSaveGlobalAssignmentSME = async (
    inspectionStandard: InspectionStandardDTO,
    previousSMEList: Array<StandardSmeDTO>,
    inspectionStandardStatus: EnumInspectionStandardStatus,
    closePopup: () => void
  ) => {
    if (inspectionStandard?.SelectedSMEList?.length > 0) {
      const isSameUser = inspectionStandard.SelectedSMEList?.filter((obj) => {
        return !previousSMEList?.some((obj1) => {
          return obj1.Sme.Uuid === obj.Sme.Uuid;
        });
      });

      if (
        isSameUser.length > 0 ||
        previousSMEList?.length > inspectionStandard?.SelectedSMEList?.length ||
        inspectionStandardStatus === EnumInspectionStandardStatus.Intial
      ) {
        dispatch(saveAssignedGlobalSMEs(inspectionStandard));
        closePopup();
      } else {
        closePopup();
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Global SME Assignment saved successfully",
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );
      }
    } else {
      dispatch(
        updatePopupProps({
          ErrorMessage: "Select at least one SME",
        })
      );
    }
  };

  return (
    <div
      className="content-section-card rem-tracker top-main-padding site-inspection-start inner-start"
      ref={containerHeight}
    >
      <Grid
        container
        // columnSpacing={2}
        // sx={{ display: "flex", flexWrap: "nowrap" }}
      >
        <div
          className="flex--container fx-wrap width100-p top-main-border mb-5 rem-btn-wrap"
          ref={siteInspectionHeader}
        >
          <div className="flex__item--inherit pr-10 pl-10">
            <h2>
              <Button
                aria-label="back"
                className="sub-head-back-btn"
                onClick={backOnClick}
                title="Back to home page"
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </h2>
          </div>
          <div className="global-header-wrap rem-trak-head-res limit-1">
            <Tooltip
              title={
                siteInspectionStartPageState.InspectionName
                  ? siteInspectionStartPageState.InspectionName
                  : ""
              }
            >
              <h1 className="page_title">
                {siteInspectionStartPageState.InspectionName
                  ? siteInspectionStartPageState.InspectionName
                  : ""}
              </h1>
            </Tooltip>
          </div>
          <div className="flex__item search-bar--xsmall mr-10 pr-20 fx-around"></div>
          <div className="flex__item--inherit text-right sub-header-btn-wrap pr-10 pl-10 fx">
            {siteInspectionStartPageState?.QuestionMainData?.length > 0 ? (
              <Grid item className="pr-5">
                {/* <InspectionExcelReportDownload
                        InspectionId={
                          siteInspectionStartPageState.Inspection?.Id
                        }
                      /> */}
                <HPRReportMenu
                  ReportList={
                    [
                      {
                        ReportName: "UpToDate Inspection Summary",
                        ReportFormatType: EnumReportFormatType.EXCEL,
                        ReportComponent: (
                          <InspectionExcelReportDownload
                            InspectionId={
                              siteInspectionStartPageState.Inspection?.Id
                            }
                          />
                        ),
                        HideReport: !checkSystemFunctionRight(
                          EnumSystemFunctions.SiteInspectionPage_New_Summary,
                          EnumSystemFunctionTypes.ButtonAccess
                        ),
                      },
                      {
                        ReportName: "Inspection Summary Report",
                        ReportFormatType: EnumReportFormatType.PDF,
                        ReportComponent: (
                          <ReportViewerNew
                            ReportType={EnumReportType.SummeryReport}
                            Inspection={
                              {
                                Id: siteInspectionStartPageState.Inspection.Id,
                              } as InspectionDTO
                            }
                            ReportLinkName="Inspection Summary Report"
                            ReportDownloadName="SummaryReport"
                            OnRendering={OnGenaratingReport}
                          >
                            <div className={`colmn-opt pdf`}>
                              <span className="">
                                <Document
                                  className="m-r-10"
                                  style={{ fontSize: 20 }}
                                  color="#f44336"
                                />
                              </span>
                              <span>Inspection Summary Report</span>
                            </div>
                          </ReportViewerNew>
                        ),
                        HideReport: !(
                          siteInspectionStartPageState.Inspection &&
                          siteInspectionStartPageState.Inspection
                            ?.InspectionStatusId ===
                            EnumInspectionStatus.Completed &&
                          checkSystemFunctionRight(
                            EnumSystemFunctions.SiteInspectionPage_Download_InspectionSummary,
                            EnumSystemFunctionTypes.ButtonAccess
                          )
                        ),
                      },
                    ] as Array<HPRReportMenuType>
                  }
                ></HPRReportMenu>
              </Grid>
            ) : (
              <></>
            )}
            {checkSystemFunctionRight(
              EnumSystemFunctions.SiteInspectionPage_ViewStatistics,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              siteInspectionStartPageState?.QuestionMainData?.length > 0 && (
                <Grid item className="pr-5">
                  <Button
                    className="primary-btn-small"
                    variant="contained"
                    disableElevation
                    onClick={onClickInspectionStatus}
                  >
                    Inspection Statistics
                  </Button>
                </Grid>
              )}

            {checkSystemFunctionRight(
              EnumSystemFunctions.SiteInspectionPage_CloseInspection,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              siteInspectionStartPageState?.QuestionMainData?.length > 0 &&
              siteInspectionStartPageState?.Inspection?.InspectionStatusId !==
                EnumInspectionStatus.Completed && (
                <Grid item className="pr-5">
                  <Button
                    className={
                      siteInspectionStartPageState?.Inspection?.IsException ||
                      siteInspectionStartPageState?.Inspection
                        ?.AnyInspectionStandardAnswersNotApproved
                        ? "primary-btn-small disable-btn"
                        : "primary-btn-small"
                    }
                    variant="contained"
                    disableElevation
                    onClick={
                      onClickCloseInspection // onClickCompleteInspectionBtn()
                    }
                    disabled={
                      siteInspectionStartPageState?.Inspection?.IsException ||
                      siteInspectionStartPageState?.Inspection
                        ?.AnyInspectionStandardAnswersNotApproved
                    }
                  >
                    Close Inspection
                  </Button>
                </Grid>
              )}
            {checkSystemFunctionRight(
              EnumSystemFunctions.SiteInspectionPage_ReopenInspection,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              siteInspectionStartPageState?.QuestionMainData?.length > 0 &&
              siteInspectionStartPageState?.Inspection?.InspectionStatusId ===
                EnumInspectionStatus.Completed && (
                <Grid item>
                  <Button
                    className="primary-btn-small"
                    variant="contained"
                    disableElevation
                    onClick={() => onClickReopenInspectionBtn()}
                  >
                    Re-open inspection
                  </Button>
                </Grid>
              )}
          </div>
        </div>
      </Grid>
      <div style={{ height: siteInspectionHeight }} className="overflow-auto">
        <Grid container>
          {siteInspectionStartPageState?.QuestionMainData?.length > 0 ? (
            <>
              {/* <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid> */}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  spacing={2}
                  wrap={"wrap-reverse"}
                  className="pl-10 pr-10 main-content"
                >
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    {siteInspectionStartPageState.QuestionMainData.map(
                      (mainData, index) => {
                        return (
                          <div key={index} className="item-wrapper mb-10">
                            <div className="item-header mt-5 limit-1 before-style">
                              {" "}
                              {/* change margin-bottom 20px -> 10px */}
                              <h2 className="d-flex justify-between">
                                <Tooltip
                                  title={`${mainData.InspectionStandardName} ${
                                    mainData.Suffix
                                      ? "- " + mainData.Suffix
                                      : ""
                                  }`}
                                >
                                  <span className="title limit-1 mr-10">
                                    {mainData.InspectionStandardName}{" "}
                                    {mainData.Suffix
                                      ? "- " + mainData.Suffix
                                      : ""}
                                  </span>
                                </Tooltip>
                                <span className="d-flex no-wrap">
                                  <span
                                    className="link-button-type1 download-doc link-text align-right fx no-wrap"
                                    style={{
                                      visibility: checkSystemFunctionRight(
                                        EnumSystemFunctions.SiteInspectionPage_Download_Standard_Summary,
                                        EnumSystemFunctionTypes.ButtonAccess
                                      )
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    {/* Download summary{" "}
                                        <a
                                          href=""
                                          className="disabled"
                                          title={"PDF"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <PictureAsPdfOutlinedIcon className="download-ico" />
                                        </a>{" "} */}
                                    <span className="valign-middle">
                                      Download summary{" "}
                                    </span>
                                    <ReportViewerNew
                                      ReportType={EnumReportType.StandardReport}
                                      Inspection={
                                        {
                                          Id: mainData.InspectionId,
                                        } as InspectionDTO
                                      }
                                      Standard={
                                        {
                                          Id: mainData.StandardId,
                                          Name: mainData.StandardName,
                                        } as StandardDTO
                                      }
                                      InspectionStandardId={
                                        mainData.InspectionStandardId
                                      }
                                      ReportLinkName="Download Summary"
                                      ReportDownloadName="SummaryReport"
                                      OnRendering={OnGenaratingReport}
                                    >
                                      <a
                                        href=""
                                        title={"PDF"}
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                        className={`${
                                          reportState.ReportDownloadStatus.some(
                                            (item) => {
                                              return (
                                                item.ReportType ===
                                                  EnumReportType.StandardReport &&
                                                item.InspectionId ===
                                                  mainData.InspectionId &&
                                                item.StandardName ===
                                                  mainData.StandardName &&
                                                item.StandardId ===
                                                  mainData.StandardId &&
                                                item.IsDownloading &&
                                                item.InspectionStandardId ===
                                                  mainData.InspectionStandardId
                                              );
                                            }
                                          )
                                            ? " disabled"
                                            : ""
                                        } colmn-opt pdf m-l-10 mt-2`}
                                      >
                                        {/* <PictureAsPdfOutlinedIcon className="download-ico" /> */}
                                        <Document
                                          className="m-r-10"
                                          color="#f44336"
                                          style={{ fontSize: 20 }}
                                        />
                                      </a>
                                    </ReportViewerNew>
                                    <StandardExcelReportDownload
                                      Title="Excel"
                                      StandardId={mainData.StandardId}
                                      StandardName={mainData.StandardName}
                                      InspectionId={mainData.InspectionId}
                                      InspectionStandardId={
                                        mainData.InspectionStandardId
                                      }
                                    />
                                  </span>
                                  {checkSystemFunctionRight(
                                    EnumSystemFunctions.SiteInspectionPage_CreateCopy,
                                    EnumSystemFunctionTypes.ButtonAccess
                                  ) &&
                                  siteInspectionStartPageState.Inspection
                                    .InspectionStatusId !==
                                    EnumInspectionStatus.Completed ? (
                                    <a
                                      className="link-button-type1 align-right fx valign-middle"
                                      style={{ lineHeight: "30px" }}
                                      // onClick={() =>
                                      //   handleOpenCopyModal(mainData)
                                      // }
                                    >
                                      <span className="sep ml-10 gray-text">
                                        {/* | */}
                                      </span>
                                      <span className="link-text p-l-10">
                                        Create a copy
                                      </span>
                                      <ContentCopyIcon
                                        className="copy-ico m-l-10"
                                        onClick={() =>
                                          handleOpenCopyModal(mainData)
                                        }
                                        // style={{ marginRight: 10 }}
                                      />
                                    </a>
                                  ) : null}
                                </span>
                              </h2>
                            </div>
                            <div className="item-content">
                              <Grid container className="">
                                <div className="site-ins-fist mb-3">
                                  {/* add margin-bottom 5px */}
                                  <span className="item-lbl-val sub-info-stat flex--container">
                                    <label className="audit-sub-lbl flex__item--inherit">
                                      Status:{" "}
                                    </label>

                                    {mainData.IsException ? (
                                      <Tooltip title={"Exception"}>
                                        <label className="audit-sub-val gray-text">
                                          {"Exception"}
                                        </label>
                                      </Tooltip>
                                    ) : mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Intial ? (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val gray-text flex__item">
                                          {
                                            mainData.InspectionStandardStatusName /* Not started */
                                          }
                                        </label>
                                      </Tooltip>
                                    ) : mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Inprogress ? (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val flex__item inprogress-text inprogress">
                                          {
                                            mainData.InspectionStandardStatusName /* Started */
                                          }
                                        </label>
                                      </Tooltip>
                                    ) : mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Completed ? (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val green-text flex__item">
                                          {/* change class 'green-text-audit' -> 'green-text' */}
                                          {
                                            mainData.InspectionStandardStatusName /* Completed */
                                          }
                                        </label>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val orange-text-audit">
                                          {
                                            mainData.InspectionStandardStatusName /* Paused */
                                          }
                                        </label>
                                      </Tooltip>
                                    )}
                                    {mainData.IsException && (
                                      <span className="icon-span">
                                        <Tooltip
                                          title={
                                            "At least one SME should be defined"
                                          }
                                        >
                                          <InfoCircle
                                            className="icon-btn mr-5"
                                            color={"#f44336"}
                                            variant="Bold"
                                            size={20}
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </span>
                                </div>
                                <div className="site-ins-second second mb-3">
                                  <span className="item-lbl-val sm-left sub-info flex--container">
                                    <label className="audit-sub-lbl flex__item--inherit no-wrap">
                                      Global primary SME:{"  "}
                                    </label>
                                    <Tooltip
                                      title={
                                        mainData.GlobalPrimarySME != null
                                          ? mainData.GlobalPrimarySME
                                          : ""
                                      }
                                    >
                                      <label className="audit-sub-val short gray-text flex__item">
                                        {mainData.GlobalPrimarySME != null
                                          ? mainData.GlobalPrimarySME
                                          : "N/A"}
                                      </label>
                                    </Tooltip>
                                  </span>
                                </div>
                                <div className="site-ins-second mb-3">
                                  <span className="item-lbl-val sm-left sub-info flex--container no-wrap">
                                    <label className="audit-sub-lbl flex__item--inherit">
                                      Site primary SME(s):{" "}
                                    </label>
                                    <Tooltip
                                      title={
                                        mainData?.SitePrimarySME
                                          ? mainData?.SitePrimarySME
                                          : ""
                                      }
                                    >
                                      <label className="audit-sub-val gray-text flex__item truncate">
                                        {mainData?.SitePrimarySME
                                          ? mainData?.SitePrimarySME
                                          : "N/A"}
                                      </label>
                                    </Tooltip>
                                  </span>
                                </div>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <span className="audit-qty-lbl audit-progress-item-qty">
                                    <span className="questions-blue-text">
                                      {/* change text color (add new class in _elements.scss) */}
                                      ({mainData.AllQuestionCount}){" "}
                                    </span>
                                    Questions
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div className="progress-bar-wrapper mt-5 mb-5">
                                    {/* add margin-top & margin-bottom 5px */}
                                    {mainData.InspectionStandardStatus ===
                                    EnumInspectionStandardStatus.Completed ? (
                                      <LinearProgress
                                        variant="determinate"
                                        value={
                                          mainData.ProgressBarValue
                                            ? Math.round(
                                                ((mainData.AnsweredCount +
                                                  mainData.AnsweredAsNA +
                                                  mainData.GapCount +
                                                  mainData.SkippedCount) /
                                                  mainData.AllQuestionCount) *
                                                  100
                                              )
                                            : 0
                                        }
                                      />
                                    ) : (
                                      <LinearProgress
                                        variant="determinate"
                                        value={
                                          mainData.ProgressBarValue
                                            ? Math.round(
                                                ((mainData.AnsweredCount +
                                                  mainData.AnsweredAsNA +
                                                  mainData.GapCount) /
                                                  mainData.AllQuestionCount) *
                                                  100
                                              )
                                            : 0
                                        }
                                      />
                                    )}
                                    {mainData.InspectionStandardStatus ===
                                    EnumInspectionStandardStatus.Completed ? (
                                      <label className="audit-progress-quantity">
                                        {mainData.AnsweredCount +
                                          mainData.AnsweredAsNA +
                                          mainData.GapCount +
                                          mainData.SkippedCount}
                                        /{mainData.AllQuestionCount}{" "}
                                      </label>
                                    ) : (
                                      <label className="audit-progress-quantity">
                                        {mainData.AnsweredCount +
                                          mainData.AnsweredAsNA +
                                          mainData.GapCount}
                                        /{mainData.AllQuestionCount}{" "}
                                      </label>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className="audit-item-footer">
                              <Grid container>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="d-flex no-of-item stat-label-gap mb-0"
                                >
                                  <span className="audit-qty-lbl w-130">
                                    <span className="green-text-audit w-40 text-right">
                                      ({mainData.AnsweredCount}){" "}
                                    </span>
                                    <span className="text-left no-wrap">
                                      No Gaps
                                    </span>
                                  </span>
                                  <span className="audit-qty-lbl w-130">
                                    <span className="red-text w-40 text-right">
                                      ({mainData.GapCount}){" "}
                                    </span>
                                    <span className="text-left">Gaps</span>
                                  </span>
                                  <span className="audit-qty-lbl w-130">
                                    <span className="na-text w-40 text-right">
                                      ({mainData.AnsweredAsNA}){" "}
                                    </span>
                                    <span className="text-left">N/A</span>
                                  </span>
                                  <span className="audit-qty-lbl w-130">
                                    <span className="skipped-text w-40 text-right">
                                      ({mainData.SkippedCount}){" "}
                                    </span>
                                    <span className="text-left">Skipped</span>
                                  </span>

                                  {mainData.InspectionStandardStatus !==
                                  EnumInspectionStandardStatus.Completed ? (
                                    <span className="audit-qty-lbl w-130">
                                      <span className="pending-text w-40 text-right">
                                        ({mainData.PendingCount}){" "}
                                      </span>
                                      <span className="text-left">Pending</span>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {mainData.IsApprovalRequired ? (
                                    <span className="audit-qty-lbl w-130">
                                      <span className="answerered-text w-40 text-right">
                                        ({mainData.AnsweredWithoutApprovalCount}
                                        ){" "}
                                      </span>
                                      <span className="text-left">
                                        Answered
                                      </span>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {mainData.InspectionStandardStatus ===
                                  EnumInspectionStandardStatus.Completed ? (
                                    <span className="audit-qty-lbl w-140">
                                      <span className="red-text w-40 text-right">
                                        (
                                        {mainData.SkippedCount +
                                          mainData.GapCount}
                                        ){" "}
                                      </span>
                                      <span className="red-text text-left">
                                        {"Total Gaps"}
                                      </span>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {/* {/* <a href="" className="link-button-type1">
                                    <PictureAsPdfOutlinedIcon className="download-ico" />
                                    Download preface
                                  </a> */}
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="pb-5"
                                    >
                                      {mainData.InspectionStandardStatus !==
                                        EnumInspectionStandardStatus.Completed &&
                                      mainData.InspectionStandardReOpenedBy ==
                                        null ? (
                                        <span className="no-wrap item-lbl-val time-stamp align-left">
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-lbl"
                                              style={{ fontSize: 14 }}
                                            >
                                              Started by:{" "}
                                            </label>
                                            <label
                                              className="audit-sub-val gray-text mr-5"
                                              style={{ fontSize: 14 }}
                                            >
                                              {mainData.InspectionStandardStartedBy
                                                ? mainData.InspectionStandardStartedBy
                                                : "N/A"}
                                            </label>
                                          </span>
                                          <span className="item-lbl-val">
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {""}

                                              {mainData.InspectionStandardStartedDate !=
                                              null ? (
                                                <>
                                                  <span className="pipe-mark-style">
                                                    |
                                                  </span>
                                                  {/* change '|' size */}
                                                  <span>
                                                    {dayjs(
                                                      new Date(
                                                        mainData.InspectionStandardStartedDate
                                                      )
                                                    ).format(
                                                      "DD-MMM-YYYY HH:mm"
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* ? "| " +
                                                  dayjs(
                                                    new Date(
                                                      mainData.InspectionStandardStartedDate
                                                    )
                                                  ).format("DD-MMM-YYYY HH:mm")
                                                : ""} */}
                                            </label>
                                          </span>
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {mainData.InspectionStandardStatus !==
                                        EnumInspectionStandardStatus.Completed &&
                                      mainData.InspectionStandardReOpenedBy !=
                                        null ? (
                                        <span className="no-wrap item-lbl-val time-stamp align-left">
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-lbl"
                                              style={{ fontSize: 14 }}
                                            >
                                              Reopened by:{" "}
                                            </label>
                                            <label
                                              className="audit-sub-val gray-text mr-5"
                                              style={{ fontSize: 14 }}
                                            >
                                              {mainData.InspectionStandardReOpenedBy
                                                ? mainData.InspectionStandardReOpenedBy
                                                : "N/A"}
                                            </label>
                                          </span>
                                          <span className="item-lbl-val">
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {""}

                                              {mainData.InspectionStandardReOpenedDate !=
                                              null ? (
                                                <>
                                                  <span className="pipe-mark-style">
                                                    |
                                                  </span>
                                                  {/* change '|' size */}
                                                  <span>
                                                    {dayjs(
                                                      new Date(
                                                        mainData.InspectionStandardReOpenedDate
                                                      )
                                                    ).format(
                                                      "DD-MMM-YYYY HH:mm"
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* ? "| " +
                                                  dayjs(
                                                    new Date(
                                                      mainData.InspectionStandardReOpenedDate
                                                    )
                                                  ).format("DD-MMM-YYYY HH:mm")
                                                : ""} */}
                                            </label>
                                          </span>
                                        </span>
                                      ) : (
                                        <></>
                                      )}

                                      {mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Completed ? (
                                        <span className="no-wrap item-lbl-val time-stamp align-left">
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-lbl"
                                              style={{ fontSize: 14 }}
                                            >
                                              Completed by:{" "}
                                            </label>
                                            <label
                                              className="audit-sub-val gray-text mr-5"
                                              style={{ fontSize: 14 }}
                                            >
                                              {mainData.InspectionStandardCompletedBy
                                                ? mainData.InspectionStandardCompletedBy
                                                : "N/A"}
                                            </label>
                                          </span>
                                          <span className="item-lbl-val">
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {""}

                                              {mainData.InspectionStandardCompletedDate !=
                                              null ? (
                                                <>
                                                  <span className="pipe-mark-style">
                                                    |
                                                  </span>
                                                  {/* change '|' size */}
                                                  <span>
                                                    {dayjs(
                                                      new Date(
                                                        mainData.InspectionStandardCompletedDate
                                                      )
                                                    ).format(
                                                      "DD-MMM-YYYY HH:mm"
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* ? "| " +
                                                  dayjs(
                                                    new Date(
                                                      mainData.InspectionStandardCompletedDate
                                                    )
                                                  ).format("DD-MMM-YYYY HH:mm")
                                                : ""} */}
                                            </label>
                                          </span>
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                    {/* <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={3}
                                    >
                                      <a
                                        className="link-button-type1 align-left"
                                        onClick={() =>
                                          handleOpenCopyModal(mainData)
                                        }
                                      >
                                        <FileDownloadOutlinedIcon className="download-ico" />
                                        PDF
                                      </a>
                                      <a
                                        className="link-button-type1 align-left"
                                        onClick={() =>
                                          handleOpenCopyModal(mainData)
                                        }
                                      >
                                        <FileDownloadOutlinedIcon className="download-ico" />
                                        Excel
                                      </a>
                                    </Grid> */}

                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="text-right"
                                    >
                                      {checkSystemFunctionRight(
                                        EnumSystemFunctions.SiteInspectionPage_GlobalSMEAssignment,
                                        EnumSystemFunctionTypes.ButtonAccess
                                      ) &&
                                        siteInspectionStartPageState.Inspection
                                          .SMEsDefinedInSiteInspectionRequired &&
                                        mainData.InspectionStandardStatus >
                                          EnumInspectionStandardStatus.Intial && (
                                          <Button
                                            className="primary-btn-small"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                              let inspectionStandard = {
                                                Id: mainData.InspectionStandardId,
                                                InspectionId:
                                                  mainData.InspectionId,
                                                InspectionStandardStatusId:
                                                  mainData.InspectionStandardStatus,
                                                IsException:
                                                  mainData.IsException,
                                              } as InspectionStandardDTO;
                                              dispatch(
                                                getGlobalSMEAssignmentPopupData(
                                                  inspectionStandard
                                                )
                                              );
                                            }}
                                          >
                                            Global SME Assignment
                                          </Button>
                                        )}

                                      {mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Paused ||
                                      mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Inprogress ? (
                                        <>
                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_ResumeStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary-btn-small w-110"
                                              variant="contained"
                                              disableElevation
                                              onClick={() =>
                                                onClickResume(mainData)
                                              }
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_ResumeStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Resume
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Intial ? (
                                        <>
                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_StartStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary-btn-small w-110"
                                              variant="contained"
                                              disableElevation
                                              onClick={() => {
                                                navigate(
                                                  "/questionnaireStart",
                                                  {
                                                    state: {
                                                      InspectionStandardId:
                                                        mainData.InspectionStandardId,
                                                      StandardId:
                                                        mainData.StandardId,
                                                      InspectionId:
                                                        mainData.InspectionId,
                                                      Title: mainData.Suffix
                                                        ? mainData.InspectionStandardName +
                                                          " - " +
                                                          mainData.Suffix
                                                        : mainData.InspectionStandardName,
                                                    } as QuestionnaireStartPageParametersTypes,
                                                  }
                                                );
                                              }}
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_StartStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Start
                                            </Button>
                                          )}

                                          <Button
                                            className="primary-btn-small w-110"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                              navigate("/questionnaire", {
                                                state: {
                                                  SiteInspectionID:
                                                    mainData.InspectionId,
                                                  StandardID:
                                                    mainData.StandardId,
                                                  IsSiteInspectionViewButtonClick:
                                                    true,
                                                  InspectionStandardId:
                                                    mainData.InspectionStandardId,
                                                } as QuestionnaireParametersType,
                                              });
                                            }}
                                            // style={{
                                            //   visibility:
                                            //     checkSystemFunctionRight(
                                            //       EnumSystemFunctions.SiteInspectionPage_View,
                                            //       EnumSystemFunctionTypes.ButtonAccess
                                            //     )
                                            //       ? "visible"
                                            //       : "hidden",
                                            // }}
                                          >
                                            View
                                          </Button>

                                          {/* <ButtonGroup
                                            variant="contained"
                                            aria-label="split button"
                                            className="primary small-btn align-right"
                                          >
                                            <Button className="split-val">
                                              Download
                                            </Button>
                                            <Button
                                              size="small"
                                              className="split-caret"
                                            >
                                              <KeyboardArrowDownOutlinedIcon />
                                            </Button>
                                          </ButtonGroup> */}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Paused ||
                                      mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Inprogress ? (
                                        <>
                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_CompleteStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className={
                                                mainData.IsException ||
                                                (mainData.IsApprovalRequired &&
                                                  mainData.AnsweredWithoutApprovalCount >
                                                    0)
                                                  ? "primary-btn-small disable-btn w-110"
                                                  : "primary-btn-small w-110"
                                              }
                                              variant="contained"
                                              disableElevation
                                              onClick={() =>
                                                // onClickCompleteBtn(
                                                //   EnumInspectionStandardStatus.Completed,
                                                //   mainData.StandardId,
                                                //   mainData.InspectionId,
                                                //   mainData.InspectionStandardId
                                                // )
                                                getInspectionStandardSummary(
                                                  mainData.InspectionStandardId
                                                )
                                              }
                                              disabled={
                                                mainData.IsException ||
                                                (mainData.IsApprovalRequired &&
                                                  mainData.AnsweredWithoutApprovalCount >
                                                    0)
                                              }
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_CompleteStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Complete
                                            </Button>
                                          )}
                                          <Button
                                            className="primary-btn-small w-110"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                              navigate("/questionnaire", {
                                                state: {
                                                  SiteInspectionID:
                                                    mainData.InspectionId,
                                                  StandardID:
                                                    mainData.StandardId,
                                                  IsSiteInspectionViewButtonClick:
                                                    true,
                                                  InspectionStandardId:
                                                    mainData.InspectionStandardId,
                                                } as QuestionnaireParametersType,
                                              });
                                            }}
                                            // style={{
                                            //   visibility:
                                            //     checkSystemFunctionRight(
                                            //       EnumSystemFunctions.SiteInspectionPage_View,
                                            //       EnumSystemFunctionTypes.ButtonAccess
                                            //     )
                                            //       ? "visible"
                                            //       : "hidden",
                                            // }}
                                          >
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Completed ? (
                                        <Button
                                          className="primary-btn-small w-110"
                                          variant="contained"
                                          disableElevation
                                          onClick={() => {
                                            navigate("/questionnaire", {
                                              state: {
                                                SiteInspectionID:
                                                  mainData.InspectionId,
                                                StandardID: mainData.StandardId,
                                                IsSiteInspectionViewButtonClick:
                                                  true,
                                                InspectionStandardId:
                                                  mainData.InspectionStandardId,
                                              } as QuestionnaireParametersType,
                                            });
                                          }}
                                          // style={{
                                          //   visibility:
                                          //     checkSystemFunctionRight(
                                          //       EnumSystemFunctions.SiteInspectionPage_View,
                                          //       EnumSystemFunctionTypes.ButtonAccess
                                          //     )
                                          //       ? "visible"
                                          //       : "hidden",
                                          // }}
                                        >
                                          View
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                      {siteInspectionStartPageState?.Inspection
                                        ?.InspectionStatusId !==
                                        EnumInspectionStatus.Completed &&
                                      mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Completed ? (
                                        <>
                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_ReopenStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary-btn-small w-110"
                                              variant="contained"
                                              disableElevation
                                              onClick={() =>
                                                onClickReOpen(mainData)
                                              }
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_ReopenStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Re-open
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Grid>
                  {/* <Grid item xl={1} lg={1} md={12} sm={12} xs={12}></Grid> */}
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    {siteInspectionStartPageState.Inspection
                      .InspectionStatusId !== EnumInspectionStatus.Completed ? (
                      <div className="item-wrapper site-members">
                        <div className="item-header">
                          <h2 className="fx fx-between p-t-5 right-head">
                            Site Team Members
                            <span className="right">
                              {siteInspectionStartPageState.PageMode
                                .IsShowSaveBtn ? (
                                <a
                                  title="Save"
                                  className="link-button-type1 icon-link-btn cursor-pointer"
                                >
                                  <CardTick
                                    variant="Outline"
                                    className="green-text-audit mr-10"
                                    onClick={onClickSaveBtn}
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                              {siteInspectionStartPageState.PageMode
                                .IsShowCancelBtn ? (
                                <a
                                  title="Close"
                                  className="link-button-type1 icon-link-btn cursor-pointer"
                                >
                                  <CloseCircle
                                    variant="Outline"
                                    className="primary-blue-text"
                                    onClick={onClickCloseBtn}
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                              {siteInspectionStartPageState.PageMode
                                .IsShowEditBtn ? (
                                <a
                                  title="Edit"
                                  className="link-button-type1 icon-link-btn cursor-pointer"
                                >
                                  <Edit2
                                    variant="Outline"
                                    className="link-blue-text "
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onClickEditBtn();
                                    }}
                                    style={{
                                      visibility: checkSystemFunctionRight(
                                        EnumSystemFunctions.SiteInspectionPage_Edit_TeamMembers,
                                        EnumSystemFunctionTypes.ButtonAccess
                                      )
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                            </span>
                          </h2>
                        </div>
                        <div className="item-content">
                          <div className="FormGroup sme-set">
                            <HPRLinedTextArea
                              Id=""
                              Name="SiteTeamMember"
                              Label={""}
                              Value={
                                siteInspectionStartPageState?.SiteTeamMemberName
                              }
                              Disabled={
                                siteInspectionStartPageState.PageMode.IsDisable
                              }
                              onTextAreaChange={OnTextAreaChange}
                              Type={EnumLinedTextAreaType.Text}
                              ClassName={"hpr-textarea"}
                              InputProps={{ maxLength: 250 }}
                              TextLength={250}
                              ShowTextCounter
                            ></HPRLinedTextArea>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="item-wrapper complete-set">
                        <div className="item-content mt-10">
                          {/* <a
                              
                              className="link-button-type1 align-right mb-15 cursor-pointer"
                              // onClick={handleOpenInNewTab}
                            >
                              {siteInspectionStartPageState.Inspection &&
                              checkSystemFunctionRight(
                                EnumSystemFunctions.SiteInspectionPage_Download_InspectionSummary,
                                EnumSystemFunctionTypes.ButtonAccess
                              ) ? (
                                // <ReportViewer
                                //   ReportType={EnumReportType.SummeryReport}
                                //   Inspection={
                                //     {
                                //       Id: siteInspectionStartPageState
                                //         .Inspection.Id,
                                //     } as InspectionDTO
                                //   }
                                //   ReportLinkName="Download Summary"
                                //   ReportDownloadName="InspectionSummaryReport"
                                //   OnRendering={OnGenaratingReport}
                                // ></ReportViewer>

                                <ReportViewerNew
                                  ReportType={EnumReportType.SummeryReport}
                                  Inspection={
                                    {
                                      Id: siteInspectionStartPageState
                                        .Inspection.Id,
                                    } as InspectionDTO
                                  }
                                  ReportLinkName="Download Summary"
                                  ReportDownloadName="SummaryReport"
                                  OnRendering={OnGenaratingReport}
                                >
                                  <a
                                    className="align-center-all download-text"
                                    href=""
                                    title={"PDF"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <FileDownloadOutlinedIcon
                                      className="copy-ico"
                                      style={{ color: "#561ff8" }}
                                    />
                                    {"Download Summary"}
                                  </a>
                                </ReportViewerNew>
                              ) : (
                                // <HPRReportMenu
                                //   ReportList={
                                //     [
                                //       {
                                //         ReportName:
                                //           "UpToDate Inspection Summary (in Excel)",
                                //         OnClickReport() {},
                                //       },
                                //       {
                                //         ReportName: "Download Summary (in PDF)",
                                //         OnClickReport() {},
                                //       },
                                //     ] as Array<HPRReportMenuType>
                                //   }
                                // ></HPRReportMenu>
                                <></>
                              )}
                            </a> */}
                          <span className="complete-label">
                            Inspection completed
                          </span>
                          {/* <span className="completed-date body3">
                              <span className="black-text">
                                Planned on:&nbsp;
                              </span>
                              {siteInspectionStartPageState.Inspection
                                .PlannedDate != null
                                ? dayjs(
                                    new Date(
                                      siteInspectionStartPageState.Inspection.PlannedDate
                                    )
                                  ).format("DD-MMM-YYYY")
                                : ""}
                            </span> */}
                          <span className="completed-date body3">
                            <span className="black-text">
                              Started By and Date:&nbsp;
                            </span>
                            {/* {`${
                              siteInspectionStartPageState.Inspection
                                .StartedDate
                                ? `${
                                    siteInspectionStartPageState.Inspection
                                      .StartedBy
                                  } | ${
                                    siteInspectionStartPageState.Inspection
                                      .StartedDate
                                      ? dayjs(
                                          new Date(
                                            siteInspectionStartPageState.Inspection.StartedDate
                                          )
                                        ).format("DD-MMM-YYYY HH:mm")
                                      : ""
                                  }`
                                : "-"
                            }`} */}
                            {siteInspectionStartPageState.Inspection
                              .StartedDate ? (
                              <>
                                {
                                  siteInspectionStartPageState.Inspection
                                    .StartedBy
                                }
                                <span className="pipe-mark-style ml-5">|</span>
                                {/* change '|' size */}
                                {siteInspectionStartPageState.Inspection
                                  .StartedDate
                                  ? dayjs(
                                      new Date(
                                        siteInspectionStartPageState.Inspection.StartedDate
                                      )
                                    ).format("DD-MMM-YYYY HH:mm")
                                  : ""}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                          <span className="completed-date body3">
                            <span className="black-text">
                              Completed By and Date:&nbsp;
                            </span>
                            {/* {`${
                              siteInspectionStartPageState.Inspection
                                .LastCompletedDate
                                ? `${
                                    siteInspectionStartPageState.Inspection
                                      .LastCompletedBy
                                  } | ${
                                    siteInspectionStartPageState.Inspection
                                      .LastCompletedDate
                                      ? dayjs(
                                          new Date(
                                            siteInspectionStartPageState.Inspection.LastCompletedDate
                                          )
                                        ).format("DD-MMM-YYYY HH:mm")
                                      : ""
                                  }`
                                : "-"
                            }`} */}

                            {siteInspectionStartPageState.Inspection
                              .LastCompletedDate ? (
                              <>
                                {
                                  siteInspectionStartPageState.Inspection
                                    .LastCompletedBy
                                }
                                <span className="pipe-mark-style ml-5">|</span>
                                {/* change '|' size */}
                                {siteInspectionStartPageState.Inspection
                                  .LastCompletedBy
                                  ? dayjs(
                                      new Date(
                                        siteInspectionStartPageState.Inspection.LastCompletedDate
                                      )
                                    ).format("DD-MMM-YYYY HH:mm")
                                  : ""}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Modal
                  className="modal-outter modal-md"
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-header">
                      <h1>
                        Create copy
                        <a className="cursor-pointer">
                          <CloseSquare
                            size="25"
                            color="#697689"
                            onClick={() => setOpen(false)}
                            style={{
                              visibility: checkSystemFunctionRight(
                                EnumSystemFunctions.SiteInspectionPage_CreateCopy,
                                EnumSystemFunctionTypes.ButtonAccess
                              )
                                ? "visible"
                                : "hidden",
                            }}
                          />
                        </a>
                      </h1>
                      <div></div>
                    </div>
                    <div className="modal-content">
                      {/* <Grid container>
                        <Grid item xs={12}>
                          <h2>
                            {copyModalState.Title}
                          </h2>
                          <br></br>
                        </Grid>
                      </Grid> */}
                      <Grid container spacing={2} className="pt-10">
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                          <div className="form-group mb-0">
                            <HPRTextBox
                              Id=""
                              Label="Standard"
                              Name="Standard"
                              Value={copyModalState.Title}
                              Type={EnumTextBoxType.Text}
                              Disabled={true}
                            ></HPRTextBox>
                          </div>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <div className="form-group mb-0">
                            <HPRTextBox
                              Id=""
                              Label="Suffix"
                              Name="suffix"
                              Value={copyModalState.Suffix}
                              onTextBoxChange={(name, text) =>
                                setCopyModalState((prevState) => ({
                                  ...prevState,
                                  Suffix: text,
                                }))
                              }
                              Type={EnumTextBoxType.Text}
                              InputProps={{ maxLength: 250 }}
                              TextLength={250}
                              ShowTextCounter
                            ></HPRTextBox>
                          </div>
                          {/* <div className="form-group">
                            <HPRDropDownSingleSelect
                              Id=""
                              Label="Building"
                              Name="Building"
                              Select={
                                copyModalState.SelectedBuildingId
                                  ? copyModalState.SelectedBuildingId
                                  : 0
                              }
                              Items={buildingList}
                              onDropDownChange={onChangeBuilding}
                            ></HPRDropDownSingleSelect>
                          </div>
                          <div className="form-group">
                            <HPRTextBox
                              Id=""
                              Label="Location(Floor, column, room, space ID, other)"
                              Name="location"
                              Value={copyModalState.Location}
                              Type={EnumTextBoxType.Text}
                              onTextBoxChange={(name, text) =>
                                setCopyModalState((prevState) => ({
                                  ...prevState,
                                  Location: text,
                                }))
                              }
                            ></HPRTextBox>
                          </div>
                          <div className="form-group">
                            <HPRTextBox
                              Id=""
                              Label="Equipment, Application, System, other"
                              Name="RelatedEntity"
                              Value={copyModalState.RelatedEntity}
                              onTextBoxChange={(name, text) =>
                                setCopyModalState((prevState) => ({
                                  ...prevState,
                                  RelatedEntity: text,
                                }))
                              }
                              Type={EnumTextBoxType.Text}
                            ></HPRTextBox>
                          </div> */}
                        </Grid>
                      </Grid>
                    </div>
                    <div className="modal-button-bar">
                      <Button
                        // className="primary small-btn"
                        className="primary-btn-small"
                        variant="contained"
                        disableElevation
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="primary-btn-small"
                        variant="contained"
                        disableElevation
                        onClick={onClickCreateCopy}
                      >
                        Create a copy
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </div>
  );
};
export default SiteInspectionStartPage;
