//-------common imports-----//
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//Search with filte textbox

import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { Button, Paper } from "@mui/material";
//-------------------------------------------------------------------------//
//Radio group
//------------------------------------------------------------------------//
//------------------------------------------------------------------------//
//------------------------------------------------------------------------//
import { DirectInbox, SearchNormal1 } from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
//Menu Import

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EnumListShowRowCountList,
  EnumPageType,
  EnumReportType,
  EnumSearchBy,
  EnumSortTypes,
  EnumStandardQuestionSortType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import HPRListPagination from "../../../components/common/HPRListPagination/HPRListPagination";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getSearchQuestion,
  getStandardDetailsWithQuestionList,
} from "./StandardsQuestionsPage.action";
import {
  StandardQuestionsPageParametersType,
  StandardQuestionsPageType,
  StandardQuestionsType,
} from "./StandardsQuestionsPage.types";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import HPRStatus from "../../../components/common/HPRStatus/HPRStatus";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { ArrowLeft2 } from "iconsax-react";
import {
  DropDownDTO,
  QuestionDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StatusSummaryDTO,
} from "../../../common/types/common.dto.types";
import { StandardPageParametersType } from "../StandardPage/StandardPage.types";
import {
  setFilteredStatus,
  setStandardDetails,
} from "./StandardsQuestionsPage.reducer";
import { QuestionPageParametersType } from "../QuestionsPage/QuestionsPage.types";
import StandardQuestionsExcelDownload from "../../Reports/StandardQuestionsReport/StandardQuestionsReport.Excel";

//menu Import

const initialState = {
  StandardQuestions: {} as StandardQuestionsType,
  ReferenceId: 0,
  SystemId: 0,
  SubSystemId: 0,
  SubjectId: 0,
  SectionId: 0,
  SearchById: 1,
  StandardQuestionSearchParameter: {
    StandardQuestionSearchParams: {
      StandardId: 0,
      SearchBy: EnumSearchBy.Question,
      SearchByText: "",
      SelectedReferanceId: 0,
      SelectedSystemId: 0,
      SelectedSubSystemId: 0,
      SelectedSubjectId: 0,
      SelectedSectionId: 0,
      Status: EnumStatus.Published,
      SortBy: {
        Label: "Question number",
        Value: EnumStandardQuestionSortType.QuestionNumber.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
    } as QuestionDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
  } as SearchParameterDTO,
} as StandardQuestionsPageType;

const StandardsQuestionsPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const [standardQuestionsPageState, setstandardQuestionsPageState] =
    useState(initialState);
  const navigate = useNavigate();

  const location = useLocation();

  const dataFetchedRef = useRef(false);
  const standardQuestionState = useAppSelector(
    (state) => state.standardQuestionReducer
  );

  const standardState = useAppSelector(
    (state) => state.standardQuestionReducer.StandardQuestions.StandardData
  );

  const mainLayoutState = useAppSelector((state) => state.mainLayoutReducer);

  const dispatch = useAppDispatch();

  const parameter: StandardQuestionsPageParametersType = location.state;

  //get Standard Details With Question List
  useEffect(() => {
    dispatch(setFilteredStatus(parameter.EnumStatus));
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    const pageno: number = 1;
    if (
      parameter.StandardId !== 0 &&
      parameter.StandardId != null &&
      parameter.StandardId !== undefined
    ) {
      dispatch(setStandardDetails(parameter.StandardId));
      dispatch(
        getStandardDetailsWithQuestionList(
          parameter.StandardId,
          pageno,
          parameter.EnumStatus != null
            ? parameter.EnumStatus
            : EnumStatus.Published,
          parseInt(
            standardQuestionsPageState.StandardQuestionSearchParameter
              .StandardQuestionSearchParams.SortBy.Value
          ),
          standardQuestionsPageState.StandardQuestionSearchParameter
            .StandardQuestionSearchParams.SortBy.SortType
        )
      );
      setstandardQuestionsPageState((values) => {
        return {
          ...values,
          StandardQuestionSearchParameter: {
            ...values.StandardQuestionSearchParameter,
            StandardQuestionSearchParams: {
              ...values.StandardQuestionSearchParameter
                .StandardQuestionSearchParams,
              StandardId: parameter.StandardId,
              Status:
                parameter.EnumStatus != null
                  ? parameter.EnumStatus
                  : EnumStatus.Published,
            },
          },
        };
      });
    } else {
      dispatch(
        getStandardDetailsWithQuestionList(
          standardState.Id,
          pageno,
          EnumStatus.Published,
          EnumStandardQuestionSortType.QuestionNumber,
          EnumSortTypes.Ascending
        )
      );
      setstandardQuestionsPageState((values) => {
        return {
          ...values,
          StandardQuestionSearchParameter: {
            ...values.StandardQuestionSearchParameter,
            StandardQuestionSearchParams: {
              ...values.StandardQuestionSearchParameter
                .StandardQuestionSearchParams,
              StandardId: standardState.Id,
            },
          },
        };
      });
    }
  }, []);

  //on serach TextBox  change
  const onsearchNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    let SelectedPage =
      standardQuestionsPageState.StandardQuestionSearchParameter.SelectedPage;

    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          StandardQuestionSearchParams: {
            ...values.StandardQuestionSearchParameter
              .StandardQuestionSearchParams,
            SearchByText: event.target.value,
          },
          SelectedPage: !event.target.value ? 1 : SelectedPage,
        },
      };
    });

    if (!event.target.value) {
      dispatch(
        getSearchQuestion({
          ...standardQuestionsPageState.StandardQuestionSearchParameter,
          StandardQuestionSearchParams: {
            ...standardQuestionsPageState.StandardQuestionSearchParameter
              .StandardQuestionSearchParams,
            SearchByText: "",
          },
          SelectedPage: 1,
        })
      );
    }
  };

  //Search questions by status
  const searchByStatusOnClick = (status: EnumStatus) => {
    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          StandardQuestionSearchParams: {
            ...values.StandardQuestionSearchParameter
              .StandardQuestionSearchParams,
            Status: status,
          },
          SelectedPage: 1,
        },
      };
    });

    dispatch(
      getSearchQuestion({
        ...standardQuestionsPageState.StandardQuestionSearchParameter,
        StandardQuestionSearchParams: {
          ...standardQuestionsPageState.StandardQuestionSearchParameter
            .StandardQuestionSearchParams,
          Status: status,
        },
        SelectedPage: 1,
      })
    );
  };

  const onsearchQuestionKeyPress = (event: any) => {
    //enetr Key Press
    if (event.charCode === 13) {
      event.preventDefault();

      setstandardQuestionsPageState((values) => {
        return {
          ...values,
          StandardQuestionSearchParameter: {
            ...values.StandardQuestionSearchParameter,
            SelectedPage: 1,
          },
        };
      });

      dispatch(
        getSearchQuestion({
          ...standardQuestionsPageState.StandardQuestionSearchParameter,
          SelectedPage: 1,
        })
      );
    }
  };

  //Search Buttion Click
  const searchBtnClick = () => {
    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          SelectedPage: 1,
        },
      };
    });

    dispatch(
      getSearchQuestion({
        ...standardQuestionsPageState.StandardQuestionSearchParameter,
        SelectedPage: 1,
      })
    );
  };

  //Pagination button click
  function PaginationOnClickFunction(newPage: number): void {
    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          SelectedPage: newPage,
        },
      };
    });

    dispatch(
      getSearchQuestion({
        ...standardQuestionsPageState.StandardQuestionSearchParameter,
        SelectedPage: newPage,
      })
    );
  }

  const onDropDownChange = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string,
    name: string
  ) => {
    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          StandardQuestionSearchParams: {
            ...values.StandardQuestionSearchParameter
              .StandardQuestionSearchParams,
            [name]: selectedValue && selectedValue !== "" ? selectedValue : 0,
          },
          SelectedPage: 1,
        },
      };
    });

    dispatch(
      getSearchQuestion({
        ...standardQuestionsPageState.StandardQuestionSearchParameter,
        StandardQuestionSearchParams: {
          ...standardQuestionsPageState.StandardQuestionSearchParameter
            .StandardQuestionSearchParams,
          [name]: selectedValue && selectedValue !== "" ? selectedValue : 0,
        },
        SelectedPage: 1,
      })
    );
  };

  //On list item click
  function ItemOnClickFunction(Id: number): void {
    navigate("/question", {
      state: {
        QuestionId: Id,
        StandardId: parameter.StandardId,
        StandardName:
          standardQuestionState.StandardQuestions.StandardData?.Name,
        SelectedStatus:
          standardQuestionsPageState.StandardQuestionSearchParameter
            .StandardQuestionSearchParams.Status,
        CurrentQuestionCount:
          standardQuestionState.StandardQuestions.AllQuestionListCount,
      } as QuestionPageParametersType,
    });
  }

  //On back button click
  function BackOnClickFunction() {
    navigate("/standard", {
      state: {
        StandardId: parameter.StandardId,
      } as StandardPageParametersType,
    });
  }

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const subHeading = useRef(null);
  const searchBar = useRef(null);
  const recordCount = useRef(null);

  //Set Hight
  const [listHeight, setListHeight] = useState(0);
  // const [calendarHeight, setCalendarHeight] = useState(0);

  //-----Page load-----
  useEffect(() => {
    let listheight =
      window.innerHeight -
      // mainHeading.current.offsetHeight -
      subHeading.current.offsetHeight -
      // searchBar.current.offsetHeight * 2 -
      recordCount.current.offsetHeight -
      330;
    setListHeight(listheight);

    function handleResize() {
      let listheight =
        window.innerHeight -
        // mainHeading.current.offsetHeight -
        subHeading.current.offsetHeight -
        // searchBar.current.offsetHeight * 2 -
        recordCount.current.offsetHeight -
        330;
      setListHeight(listheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onChangeSortBy(SelectedSortState: SortDropDownDTO) {
    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          StandardQuestionSearchParams: {
            ...values.StandardQuestionSearchParameter
              .StandardQuestionSearchParams,
            SortBy: SelectedSortState,
          },
          SelectedPage: 1,
        },
      };
    });

    dispatch(
      getSearchQuestion({
        ...standardQuestionsPageState.StandardQuestionSearchParameter,
        StandardQuestionSearchParams: {
          ...standardQuestionsPageState.StandardQuestionSearchParameter
            .StandardQuestionSearchParams,
          SortBy: SelectedSortState,
        },
        SelectedPage: 1,
      })
    );
  }

  function onChangePagination(Name: string, Id: string) {
    setstandardQuestionsPageState((values) => {
      return {
        ...values,
        StandardQuestionSearchParameter: {
          ...values.StandardQuestionSearchParameter,
          StandardQuestionSearchParams: {
            ...values.StandardQuestionSearchParameter
              .StandardQuestionSearchParams,
          },
          SelectedPage: 1,
          ListPageSize: parseInt(Id),
        },
      };
    });

    dispatch(
      getSearchQuestion({
        ...standardQuestionsPageState.StandardQuestionSearchParameter,
        StandardQuestionSearchParams: {
          ...standardQuestionsPageState.StandardQuestionSearchParameter
            .StandardQuestionSearchParams,
        },
        SelectedPage: 1,
        ListPageSize: parseInt(Id),
      })
    );
  }

  return (
    <>
      <Box>
        {/* Main Backgroud */}

        <Grid container spacing={2}>
          {/* Left Panel Start */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* Page loading backdrop */}

            {/* Global Header with Status & Version */}
            {/* <Grid
              container
              columns={12}
              className="global-header-wrap"
              ref={mainHeading}
            >
              <Grid item lg={8} md={5} sm={12} xs={12} className=""> */}
            {/* <h1> */}
            {/* {" "}
                  Standard:{" "}
                  {standardQuestionState.StandardQuestions.StandardData?.Name} */}
            {/* Questions */}
            {/* </h1> */}
            {/* </Grid> */}

            {/* <Grid
                item
                lg={4}
                md={7}
                sm={12}
                xs={12}
                className="vlign-middle status-version-wrap"
              >
                <div className="global-version-top mb-5">
                  <span>Version:</span>
                  {standardQuestionState.StandardQuestions.StandardData?.Version.toFixed(
                    1
                  )}
                </div>
                <div className="standart-status-change mb-5 d-flex">
                  <div className="standards-status">
                    <span>Status:</span>
                    <span
                      className={
                        standardQuestionState.StandardQuestions.StandardData
                          ?.StatusId === EnumStatus.Published
                          ? "status-color green"
                          : standardQuestionState.StandardQuestions.StandardData
                              ?.StatusId === EnumStatus.Draft
                          ? "status-color yellow"
                          : standardQuestionState.StandardQuestions.StandardData
                              ?.StatusId === EnumStatus.Deactivated
                          ? "status-color gray"
                          : "status-color"
                      }
                    >
                      {" "}
                      {standardQuestionState.StandardQuestions.StandardData &&
                        standardQuestionState.StandardQuestions.StandardData
                          .Status}
                    </span>
                  </div>
                </div>
              </Grid> */}
            {/* </Grid> */}

            <div className="content-section-card top-main-padding">
              <Grid
                container
                columns={12}
                className="global-subhead-btn-wrap mt-5 top-main-border-inner"
                ref={subHeading}
              >
                <div className="flex--container width100-p standared-que-header-res">
                  {/* <IconButton
                    onClick={BackOnClickFunction}
                    aria-label="back"
                    className="sub-head-back-btn"
                    title={"Back to Standard"}
                  >
                    <ArrowLeft2 size="24" variant="Outline" />
                  </IconButton> */}
                  <div className="flex__item--inherit">
                    <Button
                      aria-label="back"
                      className="sub-head-back-btn back"
                      onClick={BackOnClickFunction}
                      title="Back to home page"
                    >
                      {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                      <ArrowBackIosNewOutlinedIcon className="" />
                      <span className="body-bold secondary-color">Back</span>
                    </Button>
                  </div>
                  {/* <h2>
                    {" "}
                    Standard:{" "}
                    {
                      standardQuestionState.StandardQuestions.StandardData?.Name
                    }{" "}
                  </h2> */}

                  {/* <Grid item lg={8} md={5} sm={12} xs={12} className=""> */}
                  <div className="">
                    <h1 className="ml-10">
                      {" "}
                      Standard:{" "}
                      {
                        standardQuestionState.StandardQuestions.StandardData
                          ?.Name
                      }{" "}
                      Questions
                    </h1>
                  </div>

                  <div className="flex___item vlign-middle status-version-wrap standadard-que-status-align">
                    <div className="standart-status-change mb-5 d-flex">
                      <div className="standards-status">
                        <span>Status:</span>
                        <span
                          className={
                            standardQuestionState.StandardQuestions.StandardData
                              ?.StatusId === EnumStatus.Published
                              ? "status-color green"
                              : standardQuestionState.StandardQuestions
                                  .StandardData?.StatusId === EnumStatus.Draft
                              ? "status-color yellow"
                              : standardQuestionState.StandardQuestions
                                  .StandardData?.StatusId ===
                                EnumStatus.Deactivated
                              ? "status-color gray"
                              : "status-color"
                          }
                        >
                          {" "}
                          {standardQuestionState.StandardQuestions
                            .StandardData &&
                            standardQuestionState.StandardQuestions.StandardData
                              .Status}
                        </span>
                      </div>
                    </div>
                    <div className="global-version-top mb-5">
                      <span>Version:</span>
                      {standardQuestionState.StandardQuestions.StandardData?.Version.toFixed(
                        1
                      )}
                    </div>
                  </div>

                  {/* </Grid> */}

                  <div className="flex__item search-bar--small ml-10 mr-10 unset-width">
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <InputBase
                        value={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SearchByText
                        }
                        onChange={onsearchNameChange}
                        onKeyPress={onsearchQuestionKeyPress}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Questions"
                        inputProps={{ "aria-label": "search google maps" }}
                        type="search"
                      />

                      <IconButton
                        onClick={searchBtnClick}
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        className="m-0"
                      >
                        {/* <SearchIcon /> */}
                        <SearchNormal1
                          variant="Outline"
                          className="ico-secondary"
                          size={18}
                        />
                        {/* <SearchNormal1 size="24" color="#FF8A65" /> */}
                      </IconButton>
                      {/* <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                        <IconButton
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                        
                          <Filter variant="Outline" className="ico-secondary" />
                         
                        </IconButton> */}
                    </Paper>
                  </div>
                  <div className="btn-header-responsive">
                    <StandardQuestionsExcelDownload
                      ReportViewer={{
                        ReportSearchParameter:
                          standardQuestionsPageState?.StandardQuestionSearchParameter,
                        IsAllDataAvailable:
                          standardQuestionState.StandardQuestions
                            .AllQuestionListCount > 0,
                        IsReportDataFound:
                          standardQuestionState.StandardQuestions
                            .QuestionListCount > 0,
                        ReportType: EnumReportType.StandardQuestionsReport,
                      }}
                      StandardQuestionsExcelReportFilter={
                        standardQuestionsPageState?.StandardQuestionSearchParameter
                      }
                    />
                  </div>
                </div>

                {/* <Button
                    className="secondary-btn"
                    variant="outlined"
                    startIcon={<CloseCircle variant="Outline" />}
                  >
                    Cancel
                  </Button> */}
                {/* <Button
                    variant="contained"
                    startIcon={<Export variant="Outline" />}
                    className="primary-btn"
                  >
                    Export
                  </Button> */}
              </Grid>
              {/* Global Sub Header with  Buttons*/}
              {/* <Grid container columns={12} className="global-subhead-btn-wrap">
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="global-subhead-wrap"
                >
                  <IconButton
                    onClick={() => BackOnClickFunction}
                    aria-label="back"
                    className="sub-head-back-btn"
                  >
                    <ArrowLeft2 size="24" variant="Outline" />
                  </IconButton>
                </Grid>
              </Grid> */}
              {/* Global Sub Header with  Buttons */}

              <div className="main-elements-background content-inner-wrap pl-10 pr-10">
                {/* Main Button */}

                {/* Serarch */}

                <Grid
                  container
                  spacing={2}
                  className="mb-15"
                  ref={searchBar}
                  style={{ display: "none" }}
                >
                  <Grid item lg={2} md={2} sm={4} xs={4} className="">
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Id=""
                        Name="SearchBy"
                        Label=""
                        Select={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SearchBy
                        }
                        onDropDownChange={onDropDownChange}
                        Items={standardQuestionState.SearchMenu}
                        IsSearchable={true}
                        DisableClearable={true}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={4} className=""></Grid> */}
                  {/* <Grid
                    item
                    lg={10}
                    md={10}
                    sm={8}
                    xs={8}
                    style={{ height: "fit-content", marginTop: "2px" }}
                  >
                    <div className="search-bar--small">
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <InputBase
                          value={
                            standardQuestionsPageState
                              .StandardQuestionSearchParameter
                              .StandardQuestionSearchParams.SearchByText
                          }
                          onChange={onsearchNameChange}
                          onKeyPress={onsearchQuestionKeyPress}
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search Questions"
                          inputProps={{ "aria-label": "search google maps" }}
                          type="search"
                        /> */}
                  {/* 
                        <IconButton
                          onClick={searchBtnClick}
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          className="m-0"
                        > */}
                  {/* <SearchIcon /> */}
                  {/* <SearchNormal1
                            variant="Outline"
                            className="ico-secondary"
                          /> */}
                  {/* <SearchNormal1 size="24" color="#FF8A65" /> */}
                  {/* </IconButton> */}
                  {/* <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                        <IconButton
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                        
                          <Filter variant="Outline" className="ico-secondary" />
                         
                        </IconButton> */}
                  {/* </Paper>
                    </div>
                  </Grid> */}
                </Grid>

                {/* Serarch End*/}

                <HPRStatus
                  onClickStatus={searchByStatusOnClick}
                  Name={"StandardQuestionStatus"}
                  SelectedStatus={
                    standardQuestionsPageState.StandardQuestionSearchParameter
                      .StandardQuestionSearchParams.Status
                  }
                  StatusSummaryItemList={
                    [
                      {
                        Value: EnumStatus.Published,
                        Label: "Published",
                        StatusSummaryCount:
                          standardQuestionState.ListPagination
                            ?.StatusSummaryCount?.Published,
                      },
                      // {
                      //   Value: EnumStatus.Revision,
                      //   Label: "Revision",
                      //   StatusSummaryCount:
                      //     standardQuestionState.ListPagination?.StatusSummaryCount?.Revision,
                      // },
                      {
                        Value: EnumStatus.Draft,
                        Label: "Draft",
                        StatusSummaryCount:
                          standardQuestionState.ListPagination
                            ?.StatusSummaryCount?.Draft,
                      },
                      {
                        Value: EnumStatus.Deactivated,
                        Label: "Deactivated",
                        StatusSummaryCount:
                          standardQuestionState.ListPagination
                            ?.StatusSummaryCount?.Deactivated,
                      },
                      // {
                      //   Value: EnumStatus.Archive,
                      //   Label: "Archive",
                      //   StatusSummaryCount:
                      //     standardQuestionState.ListPagination?.StatusSummaryCount?.Archive,
                      // },
                      {
                        Value: EnumStatus.All,
                        Label: "All",
                        StatusSummaryCount:
                          standardQuestionState.ListPagination
                            ?.StatusSummaryCount?.All,
                      },
                    ] as Array<StatusSummaryDTO>
                  }
                  SortByItemList={
                    [
                      {
                        Label: "Question number",
                        Value:
                          EnumStandardQuestionSortType.QuestionNumber.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      {
                        Label: "Question",
                        Value: EnumStandardQuestionSortType.Name.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      // {
                      //   Label: "Status",
                      //   Value: EnumStandardQuestionSortType.Status.toString(),
                      //   SortType: EnumSortTypes.Ascending,
                      // },
                      {
                        Label: "Last modified user",
                        Value:
                          EnumStandardQuestionSortType.LastModifiedUser.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      {
                        Label: "Last modified date & time",
                        Value:
                          EnumStandardQuestionSortType.LastModifiedDateTime.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                    ] as SortDropDownDTO[]
                  }
                  onChangeSortBy={onChangeSortBy}
                  PerPageItemList={
                    [
                      {
                        Label: "5",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
                      },
                      {
                        Label: "10",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
                      },
                      {
                        Label: "15",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
                      },
                      {
                        Label: "20",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
                      },
                      {
                        Label: "25",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
                      },
                    ] as DropDownDTO[]
                  }
                  onChangePerPage={onChangePagination}
                  InitialSortByState={
                    {
                      Label: "Question number",
                      Value:
                        EnumStandardQuestionSortType.QuestionNumber.toString(),
                      SortType: EnumSortTypes.Ascending,
                    } as SortDropDownDTO
                  }
                  ShowReportDownload
                  StandardQuestionReportViewer={{
                    ReportSearchParameter:
                      standardQuestionsPageState?.StandardQuestionSearchParameter,
                    IsReportDataFound:
                      standardQuestionState.StandardQuestions
                        .QuestionListCount > 0,
                    ReportType: EnumReportType.StandardQuestionsReport,
                    IsAllDataAvailable:
                      standardQuestionState.StandardQuestions
                        .AllQuestionListCount > 0,
                  }}
                />
                <br />

                {/* Items Found  */}

                <Grid
                  container
                  spacing={2}
                  className="flex--container"
                  ref={searchBar}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    className="hpr-form-control"
                  >
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Id=""
                        Name="SelectedReferanceId"
                        Label="Reference"
                        Select={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SelectedReferanceId
                        }
                        onDropDownChange={onDropDownChange}
                        Items={
                          standardQuestionState.StandardQuestions?.MenuList
                            ?.standardReferenceMenu
                        }
                        IsSearchable={true}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    className="hpr-form-control"
                  >
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Id=""
                        Name="SelectedSystemId"
                        Label="System"
                        Select={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SelectedSystemId
                        }
                        onDropDownChange={onDropDownChange}
                        Items={
                          standardQuestionState.StandardQuestions?.MenuList
                            ?.standardSystemMenu
                        }
                        IsSearchable={true}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="hpr-form-control"
                  >
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Id=""
                        Name="SelectedSubSystemId"
                        Label="Sub-system"
                        Select={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SelectedSubSystemId
                        }
                        onDropDownChange={onDropDownChange}
                        Items={
                          standardQuestionState.StandardQuestions?.MenuList
                            ?.standardSubSystemMenu
                        }
                        IsSearchable={true}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    className="hpr-form-control"
                  >
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Id=""
                        Name="SelectedSubjectId"
                        Label="Subject"
                        Select={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SelectedSubjectId
                        }
                        onDropDownChange={onDropDownChange}
                        Items={
                          standardQuestionState.StandardQuestions?.MenuList
                            ?.standardSubjectMenu
                        }
                        IsSearchable={true}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={1}
                    className="hpr-form-control"
                  >
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Id=""
                        Name="SelectedSectionId"
                        Label="Section"
                        Select={
                          standardQuestionsPageState
                            .StandardQuestionSearchParameter
                            .StandardQuestionSearchParams.SelectedSectionId
                        }
                        onDropDownChange={onDropDownChange}
                        Items={
                          standardQuestionState.StandardQuestions?.MenuList
                            ?.standardSectionMenu
                        }
                        IsSearchable={true}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} ref={recordCount}>
                  <div className="records-found">
                    {standardQuestionState.StandardQuestions?.QuestionListCount}{" "}
                    {standardQuestionState.StandardQuestions
                      ?.QuestionListCount > 1
                      ? "Records found"
                      : "Record found"}
                  </div>
                </Grid>

                <div
                  className="standard-q-page-items-wrap"
                  style={{ height: listHeight }}
                >
                  <HPRListPagination
                    ItemList={standardQuestionState.ListPagination.ItemList}
                    ItemShowCount={
                      standardQuestionState.ListPagination.ItemShowCount
                    }
                    TotalRecordsCount={
                      standardQuestionState.ListPagination.TotalRecordsCount
                    }
                    PaginationOnClickFunction={PaginationOnClickFunction}
                    ItemOnClickFunction={ItemOnClickFunction}
                    PageType={EnumPageType.StandardQuestions}
                    SelectedPage={
                      standardQuestionsPageState.StandardQuestionSearchParameter
                        .SelectedPage
                    }
                    IsLoading={mainLayoutState.IsLoading}
                  />
                </div>
              </div>
            </div>
          </Grid>

          {/* Left Panel End */}
        </Grid>

        {/* Main Backgroud End */}
      </Box>
    </>
  );
};

export default StandardsQuestionsPage;
