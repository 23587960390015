import * as React from "react";
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
  type MRT_ColumnDef,
  MRT_SortingState,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_Row,
} from "material-react-table";

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableHead,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  ArrowLeft2,
  Bag,
  CardAdd,
  Clock,
  Data,
  Eye,
  Maximize2,
  Refresh,
  SearchNormal1,
  Settings,
  ElementPlus,
} from "iconsax-react";
import {
  ColumnFiltertypeDTO,
  RemediationTrackerTaskDTO,
  RemediationTrackerTaskQuestionDTO,
  SortDropDownDTO,
} from "../../../common/types/common.dto.types";
import {
  ListColumn,
  ViewRemediationTrackerTaskListProps,
  ViewRemediationTrackerTaskListToolBarProps,
} from "./ViewRemediationTrackerTaskList.types";
import dayjs from "dayjs";
import {
  EnumPageType,
  EnumRemediationTrackerTaskColumnType,
  EnumRemediationTrackerTaskStatus,
  EnumSortTypes,
} from "../../../common/enums/common.enums";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import ViewRemediationTrackerTaskTableToolBar from "./ViewRemediationTrackerTaskTableToolBar";
import { viewInspectionQuestionSliceReducerActions } from "../ViewInspectionQuestion/ViewInspectionQuestion.reducer";
import { viewRemediationTrackerTaskHistorySliceReducerActions } from "../ViewRemediationTrackerTaskHistory/ViewRemediationTrackerTaskHistory.reducer";
import { ViewRemediationTrackerTaskHistoryProps } from "../ViewRemediationTrackerTaskHistory/ViewRemediationTrackerTaskHistory.types";
import { useNavigate } from "react-router-dom";

// Table Custom Component <Start>
const setDateToString = (_date: any): string => {
  const date = dayjs(_date).format("DD-MMM-YYYY");
  return date !== "Invalid Date" ? date : "-";
};

const getStatusColorClass = (
  status: EnumRemediationTrackerTaskStatus
): string => {
  let colorClass = "";

  if (status === EnumRemediationTrackerTaskStatus.New) {
    colorClass = "blue";
  } else if (status === EnumRemediationTrackerTaskStatus.PendingReviewSME) {
    colorClass = "purple";
  } else if (status === EnumRemediationTrackerTaskStatus.PendingReviewSite) {
    colorClass = "site";
  }
  // else if (status === EnumRemediationTrackerTaskStatus.ReviewCompleted) {
  //   colorClass = "not-started";
  // }
  else if (status === EnumRemediationTrackerTaskStatus.ActionInProgress) {
    colorClass = "yellow";
  } else if (
    status === EnumRemediationTrackerTaskStatus.PendingClosureApproval
  ) {
    colorClass = "pendding-closure";
  } else if (status === EnumRemediationTrackerTaskStatus.Closed) {
    colorClass = "green";
  } else if (status === EnumRemediationTrackerTaskStatus.Overdue) {
    colorClass = "red";
  } else if (status === EnumRemediationTrackerTaskStatus.AutoClosed) {
    colorClass = "auto-closed";
  } else if (status === EnumRemediationTrackerTaskStatus.ClosedAsOverdue) {
    colorClass = "overdue-closed";
  } else if (status === EnumRemediationTrackerTaskStatus.Cancelled) {
    colorClass = "remediation-cancelled";
  }

  return colorClass;
};

//get CAPEX amound with thousand seperator
const currencyFormatter = new Intl.NumberFormat("en-US", {
  //style: "number",
  currency: "USD",
});

const getCAPEXAmount = (capexAmount: number): string => {
  const amount = currencyFormatter.format(capexAmount);

  if (amount && amount !== "0") {
    return amount;
  } else {
    return "0";
  }
};

//get no of task
const getNoOfTaskCountToString = (task: RemediationTrackerTaskDTO): string => {
  let taskCount = "";
  if (
    task?.RemediationTrackerTaskIdList?.length ===
      task?.FilteredRemediationTrackerTaskIdCount ||
    task?.RemediationTrackerTaskIdList?.length <=
      task?.FilteredRemediationTrackerTaskIdCount ||
    task?.FilteredRemediationTrackerTaskIdCount === 0
  ) {
    taskCount = task?.RemediationTrackerTaskIdList?.length?.toString();
  } else {
    taskCount = `${task?.FilteredRemediationTrackerTaskIdCount}/${task?.RemediationTrackerTaskIdList?.length} task(s) filtered`;
  }

  return taskCount;
};

const handleCheckBoxClick = (event: any) => {
  event.stopPropagation();
};

const TableCheckAllCheckBoxComponent = (props: {
  remediationTrackerTaskList?: Array<RemediationTrackerTaskDTO>;
  className?: string;
  onCheckBoxChange: (isChecked: boolean) => void;
  onClickRefreshBtn: () => void;
}) => {
  // set check box disabled state
  const isDisabled = props.remediationTrackerTaskList?.every(
    (task) =>
      task?.IsAutoClosed ||
      task?.StatusNavigation?.Id === EnumRemediationTrackerTaskStatus.Closed ||
      task?.StatusNavigation?.Id ===
        EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
      task?.StatusNavigation?.Id === EnumRemediationTrackerTaskStatus.Cancelled
  );

  // set checked or indeterminate
  const isChecked =
    props.remediationTrackerTaskList?.length > 0 &&
    props.remediationTrackerTaskList?.every((task) => task?.TaskIsChecked);

  const isIndeterminate =
    props.remediationTrackerTaskList?.some((task) => task?.TaskIsChecked) &&
    !isChecked;

  return (
    <>
      {/* <Settings
        size="20"
        color="#FF8A65"
        style={{ marginRight: "10px", visibility: "hidden" }}
      /> */}
      <div className="refresh-wrapper">
        <HPRCheckBox
          Id={`RT_CheckAll`}
          Name={`RT_CheckAll`}
          ClassName={props.className}
          // ClassName={`${props.className} intermidiate`}
          onCheckBoxChange={(name: string, IsChecked: boolean) => {
            props.onCheckBoxChange(IsChecked);
          }}
          checked={isChecked ?? false}
          indeterminateChecked={isIndeterminate ?? false}
          Disabled={isDisabled ?? false}
          TooltipsTitle={isChecked ? "Deselect All" : "Select All"}
        />
        <Tooltip title="Refresh Grid">
          <div className="refresh-btn-grid position-abs">
            <Button
              onClick={() => {
                props.onClickRefreshBtn();
              }}
            >
              <Refresh size="22" color="#FF8A65" />
              {/* <span>Refresh</span> */}
            </Button>
          </div>
        </Tooltip>
      </div>
      {/* <span>{isChecked ? "Deselect All" : "Select All"}</span> */}
    </>
  );
};

const TableCellCheckBoxWithIconComponent = (props: {
  row: any;
  functionRight: { AddBtn: boolean };
  className?: string;
  onCheckBoxChange: (
    remediationTrackerTask: RemediationTrackerTaskDTO,
    isChecked: boolean
  ) => void;
  onclickAddNewTaskCallBack: (task: RemediationTrackerTaskDTO) => void;
}) => {
  const remediationTrackerTaskQuestionList: Array<RemediationTrackerTaskQuestionDTO> =
    props?.row?.original?.RemediationTrackerTaskQuestions?.filter(
      (filter) =>
        !filter?.RemediationTrackerTask?.IsAutoClosed &&
        filter?.RemediationTrackerTask?.StatusNavigation?.Id !==
          EnumRemediationTrackerTaskStatus.Closed &&
        filter?.RemediationTrackerTask?.StatusNavigation?.Id !==
          EnumRemediationTrackerTaskStatus.ClosedAsOverdue &&
        filter?.RemediationTrackerTask?.StatusNavigation?.Id !==
          EnumRemediationTrackerTaskStatus.Cancelled
    );

  // set checked or indeterminate
  let isChecked = props.row?.original?.TaskIsChecked;
  let isIndeterminate = false;

  if (
    remediationTrackerTaskQuestionList?.length > 0 &&
    !props.row?.original?.IsMergeTask
  ) {
    isChecked =
      props.row?.original?.TaskIsChecked ||
      remediationTrackerTaskQuestionList.every(
        (task) => task?.RemediationTrackerTask?.TaskIsChecked
      );

    isIndeterminate =
      remediationTrackerTaskQuestionList?.some(
        (task) => task?.RemediationTrackerTask?.TaskIsChecked
      ) && !isChecked;
  }

  return (
    <div className="fx fx-align-center">
      <Tooltip title="Add" placement="bottom">
        <div
          className="mr-10 pt-5 text-center"
          style={{
            width: "25px",
            visibility: `${
              !props?.row?.original?.IsMergeTask &&
              props.functionRight.AddBtn &&
              props.row.original?.StatusNavigation?.Id ===
                EnumRemediationTrackerTaskStatus.New
                ? "visible"
                : "hidden"
            }`,
          }}
          onClick={() => {
            props.onclickAddNewTaskCallBack(props.row?.original);
          }}
        >
          <span className="add-icon add-new-task">
            {/* <PlaylistAddIcon style={{ fill: "#6a6a6a" }} /> */}
            <CardAdd size="24" color="#6a6a6a" />
          </span>
        </div>
      </Tooltip>

      <div className="mr-10 pt-5 text-center" style={{ width: "40px" }}>
        {TableCellIcon({ row: props?.row })}
      </div>
      <div style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
        <HPRCheckBox
          Id={`Chk_${props?.row?.original?.Id}`}
          Name={`Chk_${props?.row?.original?.Id}`}
          ClassName={props.className}
          // ClassName={`${props.className} intermidiate`}
          onCheckBoxChange={(name: string, IsChecked: boolean) => {
            props.onCheckBoxChange(props?.row?.original, IsChecked);
          }}
          checked={isChecked}
          indeterminateChecked={isIndeterminate}
          Disabled={
            props.row?.original?.IsAutoClosed ||
            props.row?.original?.StatusNavigation?.Id ===
              EnumRemediationTrackerTaskStatus.Closed ||
            props.row?.original?.StatusNavigation?.Id ===
              EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
            props.row?.original?.StatusNavigation?.Id ===
              EnumRemediationTrackerTaskStatus.Cancelled
          }
          onCheckBoxClick={handleCheckBoxClick}
        />
      </div>
    </div>
  );
};

const TableCellComponent = (props: {
  value: any;
  showToolTip?: boolean;
  className?: string;
}) => {
  return props.showToolTip ? (
    <Tooltip title={!props.value || props.value === "-" ? "" : props.value}>
      <span className={`${props.className}`}>{props.value}</span>
    </Tooltip>
  ) : (
    <span className={props.className}>{props.value}</span>
  );
};

// const TableExpandAllButtonComponent = (props: {
//   onClickRefreshBtn: () => void;
// }) => {
//   return (
//     <Tooltip title="Refresh Grid">
//       <div className="refresh-btn-grid">
//         <Button
//           onClick={() => {
//             props.onClickRefreshBtn();
//           }}
//         >
//           <Refresh size="22" color="#FF8A65" />
//           {/* <span>Refresh</span> */}
//         </Button>
//       </div>
//     </Tooltip>
//   );
// };

const TableCellComponentForInspectionQuestion = (props: {
  cell: any;
  onClickViewInspectionQuestion: (inspectionQuestionId: number) => void;
}) => {
  return (
    <div className="flex--container fx-align-center">
      <div
        className="flex--item--vcenter"
        style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}
      >
        {TableCellComponent({ value: props.cell.row.original?.Q_No || "-" })}{" "}
      </div>
      {!props.cell.row.original?.IsMergeTask &&
        props.cell.row.original?.Q_No && (
          <div className="flex--item--inherit">
            <Tooltip title="View Question">
              <IconButton
                id={`view-btn_${props.cell.row.original?.Id}`}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onClickViewInspectionQuestion(
                    props.cell.row.original?.InspectionQuestionId
                  );
                }}
              >
                <Eye size="18" color="#FF8A65" />
              </IconButton>
            </Tooltip>
          </div>
        )}
    </div>
  );
};

const TableToolbarComponent = (props: {
  table: any;
  toolBarProps: ViewRemediationTrackerTaskListToolBarProps;
  listColumnsState: Array<ListColumn>;
  ToolBarRef?: any;
  isFullScreen?: boolean;
  SearchValue?: string;
  onChangeSwitch: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onChangeColumnDisplayOrder: (startIndex: number, endIndex: number) => void;
  onChangeShowAllColumn: (showAllColumn: boolean) => void;
  onChangeGlobalFilter?: (searchValue: string) => void;
  onKeyPressGlobalFilter?: (event: any) => void;
  onGlobalFilterBtnClick?: () => void;
}) => {
  // use for page navigation
  const navigate = useNavigate();

  const onclickBackButton = () => {
    navigate("/");
  };

  // Set Search Clear Icon
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  return (
    <div className="fx width100-p rem-tr-res v-align" ref={props.ToolBarRef}>
      {/* show when not fullscreen */}
      {!props.isFullScreen && (
        <Grid container className="checkbox-filter-wrap">
          <div className="checkbox-filter">
            <HPRCheckBox
              Id={"ShowTasksAssignedToMe"}
              Name={"ShowTasksAssignedToMe"}
              Label={"Task(s) assigned to me"}
              checked={
                props.toolBarProps?.FilterCheckboxsProps
                  ?.CheckedTasksAssignedToMe
              }
              onCheckBoxChange={
                props.toolBarProps?.FilterCheckboxsProps?.onFilterCheckboxChange
              }
              ClassName={"inline-single-element"}
            ></HPRCheckBox>
            <HPRCheckBox
              Id={"ShowTasksPendingMyApproval"}
              Name={"ShowTasksPendingMyApproval"}
              Label={"Task(s) pending my approval"}
              checked={
                props.toolBarProps?.FilterCheckboxsProps
                  ?.CheckedTasksPendingMyApproval
              }
              onCheckBoxChange={
                props.toolBarProps?.FilterCheckboxsProps?.onFilterCheckboxChange
              }
              ClassName={"inline-single-element"}
            ></HPRCheckBox>
            <HPRCheckBox
              Id={"ShowCompletedTasks"}
              Name={"ShowCompletedTasks"}
              Label={"Include Closed"}
              checked={
                props.toolBarProps?.FilterCheckboxsProps?.CheckedCompletedTasks
              }
              onCheckBoxChange={
                props.toolBarProps?.FilterCheckboxsProps?.onFilterCheckboxChange
              }
              ClassName={"inline-single-element"}
            ></HPRCheckBox>

            <HPRCheckBox
              Id={"ShowCancelledTasks"}
              Name={"ShowCancelledTasks"}
              Label={"Include Cancelled"}
              checked={
                props.toolBarProps?.FilterCheckboxsProps?.CheckedCancelledTasks
              }
              onCheckBoxChange={
                props.toolBarProps?.FilterCheckboxsProps?.onFilterCheckboxChange
              }
              ClassName={"inline-single-element"}
            ></HPRCheckBox>
          </div>
        </Grid>
      )}

      {/* show when is fullscreen */}
      {props.isFullScreen && (
        <div className="full-view-header">
          <div className="flex--container fx-wrap width100-p top-main-border mb-10 rem-btn-wrap">
            <div className="flex__item--inherit pr-10">
              <h2>
                <Button
                  aria-label="back"
                  className="sub-head-back-btn"
                  onClick={onclickBackButton}
                  title="Back to home page"
                >
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>
              </h2>
            </div>
            <div className="global-header-wrap rem-trak-head-res ml-20">
              <h1>Remediation Tracker</h1>
            </div>
            <div className="flex__item search-bar--small mr-10 pr-20 fx-around">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <InputBase
                  value={props?.SearchValue ?? ""}
                  onChange={(event) =>
                    props.onChangeGlobalFilter(event.target?.value)
                  }
                  onKeyPress={props.onKeyPressGlobalFilter}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Remediation Tasks"
                  inputProps={{ "aria-label": "TaskName" }}
                  // type="search"
                />
                {isMouseOver &&
                  props?.SearchValue &&
                  props?.SearchValue?.length > 0 && (
                    <Tooltip title="Clear">
                      <IconButton
                        className="mr-0"
                        onClick={() => props.onChangeGlobalFilter("")}
                      >
                        <CloseIcon
                          style={{ color: "#219ebc", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                <Tooltip title="Search">
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    className="m-0"
                    onClick={props.onGlobalFilterBtnClick}
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                </Tooltip>
              </Paper>
            </div>
          </div>
          {/* <div className="flex__item--inherit pr-10">
            <h2>
              <Button
                aria-label="back"
                className="sub-head-back-btn"
                onClick={onclickBackButton}
                title="Back to home page"
              >
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </h2>
            <div className="global-header-wrap">
              <h1>Remediation Tracker</h1>
            </div>
          </div>

          <div className="flex__item search-bar--small ml-10 mr-10">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                value={props?.SearchValue ?? ""}
                onChange={props.onChangeGlobalFilter}
                onKeyPress={props.onKeyPressGlobalFilter}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Remediation Tasks"
                inputProps={{ "aria-label": "TaskName" }}
                type="search"
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                className="m-0"
                onClick={props.onGlobalFilterBtnClick}
              >
                <SearchNormal1
                  variant="Outline"
                  className="ico-secondary"
                  size={18}
                />
              </IconButton>
            </Paper>
          </div> */}
        </div>
      )}
      <div className="d-flex rem-trc-expo-btn-wrap">
        <div className="">
          <MRT_ToggleFullScreenButton
            table={props.table}
            className="rem-toggle-btn"
          />
        </div>
        <div className="btn-sec">
          <ViewRemediationTrackerTaskTableToolBar
            /*  ToolBarRef={props.ToolBarRef} */
            ViewRemediationTrackerTaskTableToolBarButtonProps={{
              ...props.toolBarProps
                .ViewRemediationTrackerTaskTableToolBarButtonProps,
              isFullScreen: props.isFullScreen,
            }}
            ViewRemediationTrackerTaskListToolbarReportProps={
              props.toolBarProps
                ?.ViewRemediationTrackerTaskListToolbarReportProps
            }
            ViewRemediationTrackerTaskListToolbarColumnProps={{
              ...props.toolBarProps
                .ViewRemediationTrackerTaskListToolbarColumnProps,
              ListColumns: props.listColumnsState,
              OnChangeSwitch: props.onChangeSwitch,
              onChangeColumnDisplayOrder: props.onChangeColumnDisplayOrder,
              onChangeShowAllColumn: props.onChangeShowAllColumn,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const TableBottomToolbarComponent = (props: { legendHeight }) => {
  return (
    <div
      className="flex--container legend-table"
      /* ref={props.legendHeight} */
      style={{ flexWrap: "wrap" }}
    >
      {/* legend */}
      <div className="flex--container pr-20">
        <div className="flex--item--vcenter mr-5">
          <Clock size="20" color="#FF8A65" />
        </div>
        <div className="flex--item--vcenter">
          <span>Approval History</span>
        </div>
      </div>
      <div className="flex--container pr-20">
        <div className="flex--item--vcenter mr-5">
          <Settings size="20" color="#FF8A65" />
        </div>
        <div className="flex--item--vcenter">
          <span>One to One</span>
        </div>
      </div>
      <div className="flex--container pr-20">
        <div className="flex--item--vcenter mr-5">
          <Data size="20" color="#FF8A65" />
        </div>
        <div className="flex--item--vcenter">
          <span>One to Many</span>
        </div>
      </div>
      <div className="flex--container pr-20">
        <div className="flex--item--vcenter mr-5">
          <Data size="20" color="#FF8A65" className="rotate" />
        </div>
        <div className="flex--item--vcenter">
          <span>Many to One</span>
        </div>
      </div>
      <div className="flex--container pr-20">
        <div className="flex--item--vcenter mr-5">
          <Maximize2 size="20" color="#FF8A65" />
        </div>
        <div className="flex--item--vcenter">
          <span>Toggle full screen</span>
        </div>
      </div>
      <div className="flex--container pr-20">
        <div className="flex--item--vcenter mr-5">
          <Refresh size="20" color="#FF8A65" />
        </div>
        <div className="flex--item--vcenter">
          <span>Refresh Grid</span>
        </div>
      </div>
    </div>
  );
};

const TableDetailPanelComponent = (props: {
  row: any;
  functionRight: { deleteBtn: boolean; AddBtn: boolean };
  onDeleteRemediationTrackerTask: (
    remediationTrackerTaskQuestion: RemediationTrackerTaskQuestionDTO
  ) => void;
  onChildTaskCheckBoxClicked: (
    parentTask: RemediationTrackerTaskDTO,
    childTask: RemediationTrackerTaskQuestionDTO,
    isChecked: boolean
  ) => void;
  onClickViewOrEditTask: (task: RemediationTrackerTaskDTO) => void;
  //onclickAddNewTaskCallBack: (task: RemediationTrackerTaskDTO) => void;
  onViewRemediationTrackerTaskHistory: (
    event: React.MouseEvent<HTMLButtonElement>,
    remediationTrackerTaskId: number,
    identifier: string
  ) => void;
}) => {
  const onClickViewOrEditTask = (
    event: any,
    task: RemediationTrackerTaskDTO
  ) => {
    //skip redirect to remediation tracker task page when view Remediation Tracker Task History details
    if (
      event?.target?.parentElement?.className?.includes(
        "viewRemediationTrackerTaskHistory"
      )
    )
      return;

    if (props.onClickViewOrEditTask) {
      props.onClickViewOrEditTask(task);
    }
  };

  return (
    props.row.original.RemediationTrackerTaskQuestions &&
    props.row.original.RemediationTrackerTaskQuestions?.length > 0 && (
      <Box
        sx={{
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "1fr",
          width: "100%",
        }}
      >
        <Table style={{ minWidth: "88vw" }}>
          <TableHead>
            <tr>
              <th
                style={{ width: "30px", maxWidth: "30px", minWidth: "30px" }}
                className="bold"
              >
                <Button
                  style={{
                    margin: 5,
                    // visibility: `${
                    //   props.functionRight.AddBtn &&
                    //   props.row.original?.StatusNavigation?.Id ===
                    //     EnumRemediationTrackerTaskStatus.New
                    //     ? "visible"
                    //     : "hidden"
                    // }`,
                    visibility: "hidden",
                  }}
                  className="primary-btn-small"
                  disableElevation
                  size="small"
                  variant="outlined"
                  startIcon={<CardAdd variant="Outline" />}
                  onClick={() => {
                    //props.onclickAddNewTaskCallBack(props.row.original);
                  }}
                >
                  Add
                </Button>
              </th>

              <th
                style={{ width: "10px", minWidth: "10px", maxWidth: "10px" }}
              ></th>

              <th
                style={{ width: "10px", minWidth: "10px", maxWidth: "10px" }}
              ></th>

              <th style={{ width: "100%" }}>
                <tbody className="fx">
                  <th style={{ flex: 1.5 }} className="bold">
                    Identifier
                  </th>
                  <th style={{ flex: 4.2 }} className="bold">
                    Task Description
                  </th>
                  <th style={{ flex: 1.6 }} className="bold">
                    Category
                  </th>
                  <th style={{ flex: 0.9 }} className="bold">
                    Due Date
                  </th>
                  <th style={{ flex: 2 }} className="bold">
                    Status
                  </th>
                  <th style={{ flex: 2 }} className="bold">
                    Approver
                  </th>
                  <th style={{ flex: 2 }} className="bold">
                    Action Owner
                  </th>
                  {/* <th style={{ flex: 2 }} className="bold">
                    Pending Approvers
                  </th> */}
                </tbody>
              </th>
            </tr>
          </TableHead>
          <TableBody>
            {props.row.original.RemediationTrackerTaskQuestions?.map(
              (
                taskQuestion: RemediationTrackerTaskQuestionDTO,
                taskQuestionIndex
              ) => {
                return (
                  <tr key={taskQuestionIndex}>
                    <td
                      style={{
                        width: "10px",
                        minWidth: "10px",
                        maxWidth: "10px",
                      }}
                    ></td>
                    <td
                      style={{
                        width: "40px",
                        minWidth: "40px",
                        maxWidth: "40px",
                      }}
                    >
                      <div className="mr-10">
                        <IconButton
                          style={{
                            visibility: `${
                              !(
                                taskQuestion?.RemediationTrackerTask
                                  ?.IsAutoCreate ?? false
                              ) &&
                              taskQuestion?.RemediationTrackerTask?.Status ===
                                EnumRemediationTrackerTaskStatus.New &&
                              props.functionRight.deleteBtn
                                ? "visible"
                                : "hidden"
                            }`,
                          }}
                          onClick={() => {
                            props.onDeleteRemediationTrackerTask(taskQuestion);
                          }}
                        >
                          <Bag
                            variant="Outline"
                            className="Icon-button-delete"
                            size={20}
                          />
                        </IconButton>
                      </div>
                    </td>
                    <td
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                      }}
                    >
                      <HPRCheckBox
                        Id={`ChildChk_${taskQuestion.RemediationTrackerTask?.Id}`}
                        Name={`ChildChk_${taskQuestion.RemediationTrackerTask?.Id}`}
                        ClassName={`inline-single-elemen`}
                        onCheckBoxChange={(
                          name: string,
                          IsChecked: boolean
                        ) => {
                          props.onChildTaskCheckBoxClicked(
                            props.row.original,
                            taskQuestion,
                            IsChecked
                          );
                        }}
                        checked={
                          taskQuestion.RemediationTrackerTask?.TaskIsChecked
                        }
                        Disabled={
                          taskQuestion.RemediationTrackerTask?.IsAutoClosed ||
                          taskQuestion.RemediationTrackerTask?.StatusNavigation
                            ?.Id === EnumRemediationTrackerTaskStatus.Closed ||
                          taskQuestion.RemediationTrackerTask?.StatusNavigation
                            ?.Id ===
                            EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
                          taskQuestion.RemediationTrackerTask?.StatusNavigation
                            ?.Id === EnumRemediationTrackerTaskStatus.Cancelled
                        }
                        onCheckBoxClick={handleCheckBoxClick}
                      />
                    </td>

                    <td width={"100%"}>
                      <tbody
                        className="cursor-pointer fx fx-middle"
                        onClick={(event) => {
                          onClickViewOrEditTask(
                            event,
                            taskQuestion.RemediationTrackerTask
                          );
                        }}
                        onKeyUp={() => {}}
                      >
                        <td style={{ flex: 1.5 }}>
                          {taskQuestion.RemediationTrackerTask?.Identifier}
                        </td>
                        <td style={{ flex: 4.2 }}>
                          <Tooltip
                            title={
                              taskQuestion.RemediationTrackerTask?.TaskName
                            }
                          >
                            <div className="limit-2">
                              {taskQuestion.RemediationTrackerTask?.TaskName ||
                                "-"}
                            </div>
                          </Tooltip>
                        </td>
                        <td style={{ flex: 1.6 }}>
                          {taskQuestion.RemediationTrackerTask
                            ?.AreaOwnerNavigation?.Description || "-"}
                        </td>
                        <td style={{ flex: 0.9 }}>
                          {setDateToString(
                            taskQuestion.RemediationTrackerTask?.DueDate
                          ) || "-"}
                        </td>
                        <td style={{ flex: 2 }}>
                          <span
                            className={`status-color ${getStatusColorClass(
                              taskQuestion.RemediationTrackerTask
                                ?.StatusNavigation?.Id
                            )}`}
                          >
                            {taskQuestion.RemediationTrackerTask
                              ?.StatusNavigation?.Description || "-"}
                          </span>
                        </td>
                        <td
                          style={{ flex: 2 }}
                          className="viewRemediationTrackerTaskHistory"
                        >
                          {taskQuestion.RemediationTrackerTask?.StatusNavigation
                            ?.Id !== EnumRemediationTrackerTaskStatus.New ? (
                            <Tooltip title="View Approver History">
                              <IconButton
                                id="history-btn"
                                className="viewRemediationTrackerTaskHistory"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  props.onViewRemediationTrackerTaskHistory(
                                    event,
                                    taskQuestion.RemediationTrackerTask?.Id,
                                    taskQuestion.RemediationTrackerTask
                                      ?.Identifier
                                  );
                                }}
                              >
                                <Clock size="18" color="#FF8A65" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td style={{ flex: 2 }}>
                          {taskQuestion.RemediationTrackerTask?.ActionOwner ||
                            "-"}
                        </td>
                        {/* <td style={{ flex: 2 }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                taskQuestion.RemediationTrackerTask
                                  ?.ApproversWithRole || "-",
                            }}
                          />
                        </td> */}
                      </tbody>
                    </td>
                  </tr>
                );
              }
            )}
          </TableBody>
        </Table>
      </Box>
    )
  );
};

const TableDetailPanelForMergeTaskComponent = (props: {
  row: any;
  onClickViewOrEditTask: (task: RemediationTrackerTaskDTO) => void;
  onViewRemediationTrackerTaskHistory: (
    event: React.MouseEvent<HTMLButtonElement>,
    remediationTrackerTaskId: number,
    identifier: string
  ) => void;
}) => {
  const onClickViewOrEditTask = (
    event: any,
    task: RemediationTrackerTaskDTO
  ) => {
    //skip redirect to remediation tracker task page when view Remediation Tracker Task History details
    if (
      event?.target?.parentElement?.className?.includes(
        "viewRemediationTrackerTaskHistory"
      )
    )
      return;

    if (props.onClickViewOrEditTask) {
      props.onClickViewOrEditTask(task);
    }
  };

  return (
    props.row.original.RemediationTrackerTaskQuestions &&
    props.row.original.RemediationTrackerTaskQuestions?.length > 0 && (
      <Box
        sx={{
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "1fr",
          width: "100%",
        }}
      >
        <Table style={{ minWidth: "88vw" }}>
          <TableHead>
            <tr style={{ height: "42px" }}>
              <th
                style={{ width: "100px", maxWidth: "100px", minWidth: "100px" }}
                className="bold"
              >
                <Button
                  style={{ margin: 5, visibility: "hidden" }}
                  className="primary-btn-small"
                  disableElevation
                  size="small"
                  variant="outlined"
                  startIcon={<CardAdd variant="Outline" />}
                  onClick={() => {}}
                >
                  Add
                </Button>
              </th>
              <th style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
                <div style={{ visibility: "hidden" }}>
                  <HPRCheckBox
                    ClassName={`inline-single-elemen`}
                    Name={""}
                    checked={false}
                  />
                </div>
              </th>
              <th style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
                <div style={{ visibility: "hidden" }}>
                  <HPRCheckBox
                    ClassName={`inline-single-elemen`}
                    Name={""}
                    checked={false}
                  />
                </div>
              </th>
              <th style={{ width: "100%" }}>
                <tbody className="fx">
                  <th style={{ flex: 3.5 }} className="bold">
                    <div className="limit-2">Merge Task Description</div>
                  </th>
                  <th style={{ flex: 2 }} className="bold">
                    Location
                  </th>
                  <th style={{ flex: 2.5 }} className="bold">
                    <div className="limit-2">Inspection</div>
                  </th>
                  <th style={{ flex: 3.5 }} className="bold">
                    Standard
                  </th>
                  <th style={{ flex: 2 }} className="bold">
                    Q.No
                  </th>
                  <th style={{ flex: 2 }} className="bold">
                    Approver
                  </th>
                  {/* <th style={{ flex: 2 }} className="bold">
                    Pending Approvers
                  </th> */}
                </tbody>
              </th>
            </tr>
          </TableHead>
          <TableBody>
            {props.row.original.RemediationTrackerTaskQuestions?.map(
              (
                taskQuestion: RemediationTrackerTaskQuestionDTO,
                taskQuestionIndex
              ) => {
                return (
                  <tr key={taskQuestionIndex} style={{ height: "42px" }}>
                    <td
                      style={{
                        width: "100px",
                        maxWidth: "100px",
                        minWidth: "100px",
                      }}
                      className="bold"
                    >
                      <Button
                        style={{ margin: 5, visibility: "hidden" }}
                        className="primary-btn-small"
                        disableElevation
                        size="small"
                        variant="outlined"
                        startIcon={<CardAdd variant="Outline" />}
                        onClick={() => {}}
                      >
                        Add
                      </Button>
                    </td>
                    <td
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                      }}
                    ></td>
                    <td
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                      }}
                    ></td>
                    <td>
                      <tbody
                        style={{}}
                        className="cursor-pointer fx fx-middle"
                        onClick={(event) => {
                          onClickViewOrEditTask(
                            event,
                            taskQuestion.RemediationTrackerTask
                          );
                        }}
                        onKeyUp={() => {}}
                      >
                        <td style={{ flex: 3.5 }}>
                          <Tooltip
                            title={
                              taskQuestion.RemediationTrackerTask?.TaskName
                            }
                          >
                            <div className="limit-2">
                              {taskQuestion.RemediationTrackerTask?.TaskName ||
                                "-"}
                            </div>
                          </Tooltip>
                        </td>
                        <td style={{ flex: 2 }}>
                          {taskQuestion.RemediationTrackerTask?.LocationName ||
                            "-"}
                        </td>
                        <td style={{ flex: 2.5 }}>
                          <Tooltip
                            title={
                              taskQuestion.RemediationTrackerTask
                                ?.InspectionName
                            }
                          >
                            <div className="limit-2">
                              {taskQuestion.RemediationTrackerTask
                                ?.InspectionName || "-"}
                            </div>
                          </Tooltip>
                        </td>
                        <td style={{ flex: 3.5 }}>
                          <Tooltip
                            title={
                              taskQuestion.RemediationTrackerTask
                                ?.InspectionStandardName
                            }
                          >
                            <div className="limit-2">
                              {taskQuestion.RemediationTrackerTask
                                ?.InspectionStandardName || "-"}
                            </div>
                          </Tooltip>
                        </td>
                        <td style={{ flex: 2 }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                taskQuestion.RemediationTrackerTask?.Q_No ||
                                "-",
                            }}
                          />
                        </td>
                        <td
                          style={{ flex: 2 }}
                          className="viewRemediationTrackerTaskHistory"
                        >
                          {taskQuestion.RemediationTrackerTask?.StatusNavigation
                            ?.Id !== EnumRemediationTrackerTaskStatus.New ? (
                            <Tooltip title="View Approver History">
                              <IconButton
                                id="history-btn"
                                className="viewRemediationTrackerTaskHistory"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  props.onViewRemediationTrackerTaskHistory(
                                    event,
                                    taskQuestion.RemediationTrackerTask?.Id,
                                    taskQuestion.RemediationTrackerTask
                                      ?.Identifier
                                  );
                                }}
                              >
                                <Clock size="18" color="#FF8A65" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </td>
                        {/* <td style={{ flex: 2 }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                taskQuestion.RemediationTrackerTask
                                  ?.ApproversWithRole || "-",
                            }}
                          />
                        </td> */}
                      </tbody>
                    </td>
                  </tr>
                );
              }
            )}
          </TableBody>
        </Table>
      </Box>
    )
  );
};

const TableCellIcon = (props: { row: any }) => {
  if (props.row.original?.IsMergeTask) {
    return <Data size="20" color="#FF8A65" className="rotate" />;
  } else if (props.row.original?.RemediationTrackerTaskIdList?.length === 1) {
    return <Settings size="20" color="#FF8A65" />;
  } else if (props.row.original?.RemediationTrackerTaskIdList?.length > 1) {
    return <Data size="20" color="#FF8A65" />;
  }
};

const TableCellButtonIcon = (props: {
  cell: any;
  onViewRemediationTrackerTaskHistory: (
    event: any,
    remediationTrackerTaskId,
    identifier
  ) => void;
}) => {
  return (
    <IconButton
      id="history-btn"
      onClick={(event) => {
        props.onViewRemediationTrackerTaskHistory(
          event,
          props.cell.row.original?.Id,
          props.cell.row.original?.Identifier
        );
      }}
    >
      <Clock size="18" color="#FF8A65" />
    </IconButton>
  );
};

// Table Custom Component <End>

const ViewRemediationTrackerTaskTable: FC<
  ViewRemediationTrackerTaskListProps
> = (props) => {
  const dispatch = useAppDispatch();

  // reduce state
  const authState = useAppSelector((state) => state.authReducer);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(props.IsFullScreen);
  // set height
  const legendHeight = useRef(null);
  const toolBarRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => {
    // set page height
    let tableHeight =
      props.GridHeight -
      (toolBarRef?.current?.offsetHeight -
        19 +
        legendHeight?.current?.offsetHeight) -
      // props.AdvanceFilterHeight -
      36;

    setTableHeight(tableHeight);
    function handleResize() {
      let tableHeight =
        props.GridHeight -
        (toolBarRef?.current?.offsetHeight -
          19 +
          legendHeight?.current?.offsetHeight) -
        // props.AdvanceFilterHeight -
        36;
      setTableHeight(tableHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    props.AdvanceFilterHeight,
    toolBarRef?.current?.offsetHeight,
    legendHeight?.current?.offsetHeight,
    props.GridHeight,
    props.IsFullScreen,
    isFullScreen,
  ]);

  // check System Function access/right
  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.RemediationTrackerTasks,
      functionId,
      functionTypeId
    );
  };

  const initialListColumns: Array<ListColumn> =
    props.ViewRemediationTrackerTaskListToolBar
      .ViewRemediationTrackerTaskListToolbarColumnProps.ListColumns;

  const dataFetchedRef = useRef(false);
  const columnFilterDataFetchedRef = useRef(false);

  const [listColumnsState, setListColumnsState] =
    useState<Array<ListColumn>>(initialListColumns);

  const [pagination, setPagination] = useState({
    pageIndex: props.PaginationProps?.SelectedPage
      ? props.PaginationProps?.SelectedPage - 1
      : 0,
    pageSize: props.PaginationProps?.PageSize,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: `${props.ViewRemediationTrackerTaskListToolBar?.ViewRemediationTrackerTaskListSortByOptions?.InitialSortByState?.Label}-${props.ViewRemediationTrackerTaskListToolBar?.ViewRemediationTrackerTaskListSortByOptions?.InitialSortByState?.Value}`,
      desc:
        props.ViewRemediationTrackerTaskListToolBar
          ?.ViewRemediationTrackerTaskListSortByOptions?.InitialSortByState
          ?.SortType === EnumSortTypes.Descending,
    },
  ]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [expanded, setExpanded] = useState<MRT_ExpandedState>({});

  // reset expand
  useEffect(() => {
    if (!props.ClearExpandedRows) return;
    setExpanded({});
  }, [props.ClearExpandedRows]);

  // set pagination
  useEffect(() => {
    setPagination({
      pageIndex: props.PaginationProps?.SelectedPage - 1,
      pageSize: props.PaginationProps?.PageSize,
    });
  }, [props.PaginationProps?.PageSize, props.PaginationProps?.SelectedPage]);

  // full screen
  useEffect(() => {
    if (props.CallbackFunction.onIsFullScreenChangeCallBack) {
      props.CallbackFunction.onIsFullScreenChangeCallBack(isFullScreen);
    }
  }, [isFullScreen]);

  // pagination
  useEffect(() => {
    // skip on load page
    dataFetchedRef.current = true;
    if (pagination.pageSize === props.PaginationProps?.PageSize) {
      if (pagination.pageIndex + 1 !== props.PaginationProps?.SelectedPage) {
        dataFetchedRef.current = false;
      }
    } else {
      dataFetchedRef.current = false;
    }

    if (dataFetchedRef.current) return;

    if (props?.PaginationProps?.onPaginationChangeCallBack) {
      props?.PaginationProps.onPaginationChangeCallBack(
        pagination.pageIndex + 1,
        pagination.pageSize
      );
    }
  }, [pagination]);

  // sorting
  useEffect(() => {
    // if (sorting?.length === 0) return;

    if (props.CallbackFunction?.onChangeSortByCallBack) {
      if (sorting?.length === 0) {
        props.CallbackFunction?.onChangeSortByCallBack({
          SortType: EnumSortTypes.Ascending,
          Value: EnumRemediationTrackerTaskColumnType.TaskId.toString(),
          Label: "TaskId",
        } as SortDropDownDTO);
      } else {
        const sort = sorting[0];

        props.CallbackFunction?.onChangeSortByCallBack({
          SortType: sort.desc
            ? EnumSortTypes.Descending
            : EnumSortTypes.Ascending,
          Value: sort?.id?.split("-")[1],
          Label: sort.id?.split("-")[0],
        } as SortDropDownDTO);
      }
    }
  }, [sorting]);

  // column Filters
  useEffect(() => {
    // skip page load
    if (!columnFilterDataFetchedRef.current && columnFilters?.length > 0) {
      columnFilterDataFetchedRef.current = true;
    }

    if (!columnFilterDataFetchedRef.current) return;

    if (props.CallbackFunction?.onColumnFiltersCallBack) {
      const columnsFilterList = columnFilters?.map((column) => {
        return {
          Column: column.id,
          Value: column.value,
        } as ColumnFiltertypeDTO;
      });

      props.CallbackFunction?.onColumnFiltersCallBack(columnsFilterList);
    }
  }, [columnFilters]);

  // when change filter column wise
  useEffect(() => {
    if (!props.FilterColumnWise) {
      setColumnFilters([]);
    }
  }, [props.FilterColumnWise]);

  // When create column & apply sorting then  Id sholud be equal to accessorKey-EnumRemediationTrackerTaskSortType Ex:[columnName-1]
  const columns = useMemo<MRT_ColumnDef<RemediationTrackerTaskDTO>[]>(
    () => [
      // {
      //   id: "Icon",
      //   accessorKey: "Icon",
      //   header: "",
      //   enableColumnActions: false,
      //   enableColumnOrdering: false,
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   enableResizing: false,
      //   size: 50,
      //   Cell: (cell) => TableCellIcon({ cell: cell }),
      // },
      // {
      //   id: "Id",
      //   accessorKey: "Id",
      //   header: "",
      //   enableColumnActions: false,
      //   enableColumnOrdering: false,
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   enableResizing: false,
      //   size: 50,
      //   Cell: ({ cell }) =>
      //     TableCellCheckBoxComponent({
      //       cell: cell,
      //       className: ``,
      //       onCheckBoxChange: onCheckBoxClicked,
      //     }),
      // },
      {
        id: "Identifier-16",
        accessorKey: "Identifier",
        header: "Identifier",
        Size: 100,
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({ value: cell.row.original?.Identifier || "-" }),
      },
      {
        id: "LocationName-1",
        accessorKey: "LocationName",
        header: "Location",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({ value: cell.row.original?.LocationName || "-" }),
      },
      {
        id: "AssessmentType-20",
        accessorKey: "AssessmentType",
        header: "Assessment Type",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original?.AssessmentTypeName || "-",
          }),
      },
      {
        id: "InspectionName-2",
        accessorKey: "InspectionName",
        header: "Inspection",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original?.InspectionName || "-",
            showToolTip: true,
            className: "truncate",
          }),
      },
      {
        id: "InspectionStandardName-3",
        accessorKey: "InspectionStandardName",
        header: "Standard",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original?.InspectionStandardName || "-",
            showToolTip: true,
            className: "truncate",
          }),
      },
      {
        id: "Q_No-4",
        accessorKey: "Q_No",
        header: "Q.No",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponentForInspectionQuestion({
            cell: cell,
            onClickViewInspectionQuestion: onClickViewInspectionQuestion,
          }),
      },
      {
        id: "Piority-8",
        accessorKey: "Piority",
        header: "Priority",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original?.PiorityNavigation?.Description || "-",
          }),
      },
      {
        id: "DueDate-9",
        accessorKey: "DueDate",
        header: "Due Date",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: setDateToString(cell.getValue()) || "-",
          }),
      },
      {
        id: "Status-6",
        accessorKey: "Status",
        header: "Status",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original.StatusNavigation?.Description || "-",
            className: `status-color ${getStatusColorClass(
              cell.row.original.StatusNavigation.Id
            )}`,
          }),
        size: 200,
      },
      {
        id: "IsCapexRequired-11",
        accessorKey: "IsCapexRequired",
        header: "Is CAPEX Required",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value:
              cell.row.original.IsCapexrequiredNavigation?.TypeDescription ||
              "-",
          }),
      },
      {
        id: "CapexAmountRequired-12",
        accessorKey: "CapexAmountRequired",
        header: "CAPEX Amount Required (M$)",
        enableColumnActions: false,
        size: 200,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: getCAPEXAmount(cell.row.original?.CapexamountRequired),
          }),
      },
      {
        id: "Category-15",
        accessorKey: "Category",
        header: "Category",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original?.AreaOwnerNavigation?.Description || "-",
          }),
      },
      {
        id: "noOfTask-17",
        accessorKey: "noOfTask",
        header: "No.Of Task",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: getNoOfTaskCountToString(cell.row.original),
          }),
      },
      {
        id: "pendingApprovers-18",
        accessorKey: "pendingApprovers",
        header: "Pending Approver(s)",
        enableColumnActions: false,
        Cell: ({ cell }) =>
          TableCellComponent({
            value: cell.row.original?.PendingApprovers || "-",
            className: "truncate-multiline",
            showToolTip: true,
          }),
      },
      // {
      //   id: "approver-10",
      //   accessorKey: "approver",
      //   header: "Approver",
      //   enableColumnActions: false,
      //   Cell: ({ cell }) =>
      //     TableCellButtonIcon({
      //       cell: cell,
      //       onViewRemediationTrackerTaskHistory:
      //         onViewRemediationTrackerTaskHistory,
      //     }),
      // },
    ],
    []
  );

  // =================== Call Back Function <Start> ===========================================

  const onClickRefreshBtn = () => {
    if (props.CallbackFunction.onClickRefreshBtnCallBack) {
      props.CallbackFunction.onClickRefreshBtnCallBack();
    }
  };

  const onCheckAllCheckBoxClicked = (isChecked: boolean) => {
    if (props.CallbackFunction?.onCheckAllCheckBoxClickedCallBack) {
      props.CallbackFunction?.onCheckAllCheckBoxClickedCallBack(isChecked);
    }
  };

  const onCheckBoxClicked = (
    remediationTrackerTask: RemediationTrackerTaskDTO,
    IsChecked: boolean
  ) => {
    if (props.CallbackFunction?.onCheckBoxClicked) {
      props.CallbackFunction?.onCheckBoxClicked(
        remediationTrackerTask,
        IsChecked
      );
    }
  };

  const onChildTaskCheckBoxClicked = (
    parentTask: RemediationTrackerTaskDTO,
    childTask: RemediationTrackerTaskQuestionDTO,
    isChecked: boolean
  ) => {
    if (props.CallbackFunction?.onChildTaskCheckBoxClicked) {
      props.CallbackFunction?.onChildTaskCheckBoxClicked(
        parentTask,
        childTask,
        isChecked
      );
    }
  };

  const onClickExpandedRow = (task: RemediationTrackerTaskDTO) => {
    if (
      (!task.RemediationTrackerTaskQuestions ||
        task.RemediationTrackerTaskQuestions?.length === 0) &&
      // !task.IsMergeTask &&
      props?.CallbackFunction?.onclickRemediationTrackerTaskDetails
    ) {
      props?.CallbackFunction?.onclickRemediationTrackerTaskDetails(task);
    }
  };

  const onDeleteRemediationTrackerTask = (
    remediationTrackerTaskQuestion: RemediationTrackerTaskQuestionDTO
  ) => {
    if (props.CallbackFunction?.onDeleteRemediationTrackerTask) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to delete the task?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            props.CallbackFunction?.onDeleteRemediationTrackerTask(
              remediationTrackerTaskQuestion
            );
          },
        })
      );
    }
  };

  const onClickViewOrEditTask = (task: RemediationTrackerTaskDTO) => {
    if (props.CallbackFunction?.onClickViewOrEditTask) {
      props.CallbackFunction?.onClickViewOrEditTask(
        task.Id,
        task.StatusNavigation?.Id
      );
    }
  };

  const onChangeColumnOptionShowHideSwitch = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let filteredColumns: Array<ListColumn> = [] as Array<ListColumn>;
    listColumnsState.forEach((column) => {
      if (column.ColumnName == event.target.name) {
        filteredColumns.push({ ...column, ColumnHide: !checked });
      } else {
        filteredColumns.push(column);
      }
    });

    if (props.CallbackFunction?.onChangeColumnOptions) {
      props.CallbackFunction?.onChangeColumnOptions(filteredColumns);
    }

    setListColumnsState(filteredColumns);
  };

  const getColumnHideValue = (name: string) => {
    return !listColumnsState.find((column) => column.ColumnName == name)
      ?.ColumnHide;
  };

  const onclickAddNewTaskCallBack = (task: RemediationTrackerTaskDTO) => {
    if (props.CallbackFunction?.onclickAddNewTaskCallBack) {
      props.CallbackFunction?.onclickAddNewTaskCallBack(task);
    }
  };

  const onClickViewInspectionQuestion = (inspectionQuestionId: number) => {
    dispatch(
      viewInspectionQuestionSliceReducerActions.viewInspectionQuestionDetails({
        InspectionQuestionId: inspectionQuestionId,
      })
    );
  };

  const onChangeColumnDisplayOrder = (startIndex: number, endIndex: number) => {
    const columnList = [...listColumnsState];

    const [removed] = columnList.splice(startIndex, 1);
    columnList.splice(endIndex, 0, removed);

    if (props.CallbackFunction?.onChangeColumnOptions) {
      props.CallbackFunction?.onChangeColumnOptions(columnList);
    }

    setListColumnsState(columnList);
  };

  const onChangeShowAllColumn = (showAllColumn: boolean) => {
    const columnList = listColumnsState?.map((column) => {
      return {
        ...column,
        ColumnHide: !column.DisabledColumnHide
          ? !showAllColumn
          : column.ColumnHide,
      };
    });

    if (props.CallbackFunction?.onChangeColumnOptions) {
      props.CallbackFunction?.onChangeColumnOptions(columnList);
    }

    setListColumnsState(columnList);
  };

  const onChangeFullScreenGlobalFilter = (searchValue) => {
    if (props.CallbackFunction.onChangeFullScreenGlobalFilterCallBack) {
      props.CallbackFunction.onChangeFullScreenGlobalFilterCallBack(
        searchValue
      );
    }
  };

  const onKeyPressFullScreenGlobalFilter = (event: any) => {
    if (props.CallbackFunction.onKeyPressFullScreenGlobalFilterCallBack) {
      props.CallbackFunction.onKeyPressFullScreenGlobalFilterCallBack(event);
    }
  };

  const onFullScreenGlobalFilterBtnClick = () => {
    if (props.CallbackFunction.onFullScreenGlobalFilterBtnClickCallBack) {
      props.CallbackFunction.onFullScreenGlobalFilterBtnClickCallBack();
    }
  };

  const handleExpandedChange = (expandedState: MRT_ExpandedState) => {
    setExpanded(expandedState);
  };

  // =================== Call Back Function <End> ===========================================

  const onViewRemediationTrackerTaskHistory = (
    event: React.MouseEvent<HTMLButtonElement>,
    remediationTrackerTaskId: number,
    identifier: string
  ) => {
    dispatch(
      viewRemediationTrackerTaskHistorySliceReducerActions.viewRemediationTrackerTaskHistoryDetails(
        {
          Ref: event.currentTarget,
          RemediationTrackerTaskId: remediationTrackerTaskId,
          Identifier: identifier,
        } as ViewRemediationTrackerTaskHistoryProps
      )
    );
  };

  const onClickTableBodyRow = (
    row: MRT_Row<RemediationTrackerTaskDTO>,
    event?: any
  ) => {
    // handle add button click
    if (event?.target?.parentElement?.className?.includes("add-new-task"))
      return;

    row.original?.IsMergeTask ||
    row.original?.RemediationTrackerTaskIdList?.length === 1
      ? onClickViewOrEditTask(row.original)
      : onClickExpandedRow(row.original);
    row.toggleExpanded(!row.getIsExpanded());
  };

  return (
    <div className={isFullScreen ? "enlarge-height-unset" : ""}>
      <div className="test">
        <MaterialReactTable
          //  style= {{height: innerTable}}

          enableStickyHeader
          enableStickyFooter
          columns={columns}
          data={props.RemediationTrackerTaskList}
          defaultColumn={{ maxSize: 900, minSize: 50, size: 150 }}
          renderBottomToolbarCustomActions={({ table }) =>
            TableBottomToolbarComponent({ legendHeight })
          }
          // muiExpandAllButtonProps={{
          //   title: "",
          //   className: "refresh-grid",
          //   component: () =>
          //     TableExpandAllButtonComponent({
          //       onClickRefreshBtn: onClickRefreshBtn,
          //     }),
          // }}
          renderToolbarInternalActions={({ table }) =>
            TableToolbarComponent({
              table: table,
              toolBarProps: props.ViewRemediationTrackerTaskListToolBar,
              listColumnsState: listColumnsState,
              isFullScreen: isFullScreen,
              SearchValue: props.SearchValue,
              onChangeSwitch: onChangeColumnOptionShowHideSwitch,
              onChangeColumnDisplayOrder: onChangeColumnDisplayOrder,
              onChangeShowAllColumn: onChangeShowAllColumn,
              onChangeGlobalFilter: onChangeFullScreenGlobalFilter,
              onKeyPressGlobalFilter: onKeyPressFullScreenGlobalFilter,
              onGlobalFilterBtnClick: onFullScreenGlobalFilterBtnClick,
              ToolBarRef: toolBarRef,
            })
          }
          // muiExpandButtonProps={({ row }) => ({
          //   title: "",
          //   onClick: (event) => {
          //     onClickExpandedRow(row.original);
          //   },
          // })}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              onClickTableBodyRow(row, event);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          renderDetailPanel={({ row }) =>
            row.original.IsMergeTask
              ? TableDetailPanelForMergeTaskComponent({
                  row: row,
                  onClickViewOrEditTask: onClickViewOrEditTask,
                  onViewRemediationTrackerTaskHistory:
                    onViewRemediationTrackerTaskHistory,
                })
              : TableDetailPanelComponent({
                  row: row,
                  functionRight: {
                    deleteBtn: checkSystemFunctionRight(
                      EnumSystemFunctions.RemTrackerTasksPage_Delete_childTask,
                      EnumSystemFunctionTypes.ButtonAccess
                    ),
                    AddBtn: checkSystemFunctionRight(
                      EnumSystemFunctions.RemTrackerTasksPage_AddOrMergedTask,
                      EnumSystemFunctionTypes.ButtonAccess
                    ),
                  },
                  onChildTaskCheckBoxClicked: onChildTaskCheckBoxClicked,
                  onDeleteRemediationTrackerTask:
                    onDeleteRemediationTrackerTask,
                  onClickViewOrEditTask: onClickViewOrEditTask,
                  //onclickAddNewTaskCallBack: onclickAddNewTaskCallBack,
                  onViewRemediationTrackerTaskHistory:
                    onViewRemediationTrackerTaskHistory,
                })
          }
          rowCount={props.PaginationProps.TotalRecordsCount}
          paginationDisplayMode="pages"
          muiPaginationProps={{
            rowsPerPageOptions: [10, 15, 25, 50, 100],
            // page: props.PaginationProps.SelectedPage,
          }}
          onPaginationChange={setPagination}
          state={{
            pagination: pagination,
            columnVisibility: {
              // give column  ID
              "Identifier-16": getColumnHideValue("Identifier"),
              "LocationName-1": getColumnHideValue("LocationName"),
              "AssessmentType-20": getColumnHideValue("AssessmentType"),
              "InspectionName-2": getColumnHideValue("InspectionName"),
              "InspectionStandardName-3": getColumnHideValue(
                "InspectionStandardName"
              ),
              "Q_No-4": getColumnHideValue("Q_No"),
              "Piority-8": getColumnHideValue("Piority"),
              "DueDate-9": getColumnHideValue("DueDate"),
              "Status-6": getColumnHideValue("Status"),
              "IsCapexRequired-11": getColumnHideValue("IsCapexRequired"),
              "CapexAmountRequired-12": getColumnHideValue(
                "CapexAmountRequired"
              ),
              "Category-15": getColumnHideValue("Category"),
              "noOfTask-17": getColumnHideValue("noOfTask"),
              // "approver-10": getColumnHideValue("approver"),
              "pendingApprovers-18": getColumnHideValue("pendingApprovers"),
            },
            sorting: sorting,
            columnFilters: columnFilters,
            columnOrder: [
              ...["mrt-row-expand", "mrt-row-select"],
              ...listColumnsState.map((item) => item.ColumnId),
            ],
            isFullScreen: isFullScreen,
            showLoadingOverlay: props.ShowDataLoading,
            isLoading: props.ShowDataLoading,
            expanded: expanded,
          }}
          manualPagination={true}
          enableSorting={true}
          manualSorting={true}
          muiTableContainerProps={{ style: { height: tableHeight } }}
          /* muiTableContainerProps={{ className: "remediation-tracker-tbl-body" }} */
          muiBottomToolbarProps={{
            className: "rem-leg-pag-wrapper",
            ref: legendHeight,
          }}
          onSortingChange={setSorting}
          onColumnFiltersChange={setColumnFilters}
          onIsFullScreenChange={setIsFullScreen}
          positionExpandColumn="first"
          enableColumnFilters={false}
          manualFiltering={true}
          columnFilterDisplayMode="popover"
          autoResetExpanded={true}
          onExpandedChange={handleExpandedChange}
          muiSelectAllCheckboxProps={{
            component: () =>
              TableCheckAllCheckBoxComponent({
                remediationTrackerTaskList: props.RemediationTrackerTaskList,
                onCheckBoxChange: onCheckAllCheckBoxClicked,
                onClickRefreshBtn: onClickRefreshBtn,
              }),
          }}
          muiSelectCheckboxProps={(props) => ({
            component: () =>
              TableCellCheckBoxWithIconComponent({
                row: props.row,
                functionRight: {
                  AddBtn: checkSystemFunctionRight(
                    EnumSystemFunctions.RemTrackerTasksPage_AddOrMergedTask,
                    EnumSystemFunctionTypes.ButtonAccess
                  ),
                },
                onCheckBoxChange: onCheckBoxClicked,
                onclickAddNewTaskCallBack: onclickAddNewTaskCallBack,
              }),
          })}
          displayColumnDefOptions={{
            "mrt-row-select": {
              // maxSize: 10,
              // size: 10,
              // minSize: 10,
              muiTableHeadCellProps: {
                sx: {
                  paddingTop: "4px",
                  // width: "55px !important",
                  // maxWidth: "75px !important",
                  // minWidth: "75px !important",
                },
                className: "select-all-column-head",
              },
              muiTableBodyCellProps: {
                className: "select-all-column-body",
                sx: {
                  // width: "55px !important",
                  // maxWidth: "55px !important",
                  // minWidth: "55px !important",
                },
              },
            },
            "mrt-row-expand": {
              // maxSize: 10,
              // size: 10,
              // minSize: 10,
              muiTableHeadCellProps: {
                sx: {
                  paddingTop: "4px",
                  display: "none",
                  // width: "55px !important",
                  // maxWidth: "55px !important",
                  // minWidth: "55px !important",
                },
                className: "expand-sec",
              },
              muiTableBodyCellProps: {
                className: "header-refresh",
                sx: {
                  display: "none",
                  // width: "55px !important",
                  // maxWidth: "55px !important",
                  // minWidth: "55px !important",
                },
              },
            },
          }}
          muiTopToolbarProps={{
            className: "btn-bar-command",
          }}
          localization={{ filterFuzzy: "" }}
          sortDescFirst={false}
        />
      </div>
    </div>
  );
};

export default ViewRemediationTrackerTaskTable;
