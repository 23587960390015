import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
} from "@mui/material";
import { HPRLinedTextAreaProps } from "./HPRLinedTextArea.types";

const HPRLinedTextArea = (props: HPRLinedTextAreaProps) => {
  /*  const [nameList, setNameList] = useState(props.Value.replace(/,/g,'\n'));*/
  const [nameList, setNameList] = useState(
    typeof props.Value === "string"
      ? props.Value.replace(/@@/g, "\n")
      : props.Value
  );
  useEffect(() => {
    setNameList(
      typeof props.Value === "string"
        ? props.Value.replace(/@@/g, "\n")
        : props.Value
    );
  }, [props.Value]);
  function arrayToString(arr: string[]): string {
    return arr.join("@@");
  }

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setNameList(event.target.value);
    const newNames = event.target.value.split("\n");
    props.onTextAreaChange(event.target.name, arrayToString(newNames));
  }

  return (
    <>
      {/* <TextField
        fullWidth
        id={props.Id}
        name={props.Name}
        multiline
        value={nameList}
        label={props.Label}
        type={props.Type === EnumLinedTextAreaType.Text ? "text" : ""}
        required={props.Required}
        disabled={props.Disabled ? props.Disabled : false}
        onChange={handleChange}
        error={props.Error}
        // helperText={props.HelperText}
        inputProps={props.InputProps}
        autoComplete="off"
        className="hpr-textarea" //check prop disable
        InputLabelProps={{ shrink: true }}
        // variant="outlined"
      /> */}
      <FormControl
        // className="width100-p autotextarea"
        className={
          props.Disabled
            ? "width100-p autotextarea-dis"
            : "width100-p autotextarea"
        }
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
        >
          {props.Label}
          {props.Required ? <span className="asterisk-red">*</span> : null}
        </InputLabel>
        <TextareaAutosize
          className={`hpr-textarea
            ${props.Disabled ? "hpr-autotextarea-dis" : "hpr-autotextarea"}`}
          id={props.Id}
          name={props.Name}
          value={nameList}
          required={props.Required}
          disabled={props.Disabled ? props.Disabled : false}
          onChange={handleChange}
          autoComplete="off"
          style={{ resize: "none" }}
          maxLength={props.TextLength}
        />
      </FormControl>
      {props.HelperText && <FormHelperText>{props.HelperText}</FormHelperText>}
      {props.IsEnableValidator ? (
        props.Validator.message(
          props.Name,
          nameList,
          props.Rules ? props.Rules : ""
        )
      ) : (
        <></>
      )}
      <div className="flex--container">
        <div className="validate-msg flex__item" style={{}}>
          {props.IsEnableValidator ? (
            props.Validator.message(
              props.Name,
              props.Value ? props.Value : "",
              props.Rules ? props.Rules : ""
            )
          ) : (
            <></>
          )}
        </div>
        <div
          className={`${
            props.Disabled
              ? props.ShowTextCounter
                ? "validate-hint-dis"
                : "validate-hint-dis no-hint"
              : props.ShowTextCounter
              ? "validate-hint flex__item--inherit"
              : "validate-hint flex__item--inherit no-hint"
          } ${
            props.ShowTextCounter
              ? "validate-hint flex__item--inherit"
              : "validate-hint-dis no-hint"
          }
        `}
        >
          {props.ShowTextCounter
            ? `${props.Value?.replaceAll(/@@/g, " ")?.length}/${
                props.TextLength
              }`
            : ""}
        </div>
      </div>
    </>
  );
};

export default HPRLinedTextArea;
