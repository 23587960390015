import { usePDF, pdf } from "@react-pdf/renderer";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { EnumReportType } from "../../common/enums/common.enums";
import {
  InspectionDTO,
  RemediationTrackerTaskDTO,
  StandardDTO,
} from "../../common/types/common.dto.types";
import StandardReport from "../../views/Reports/StandardReport/StandardReport";
import { SummaryReport } from "../../views/Reports/SummaryReport/SummaryReport";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { getReport } from "./ReportViewer.action";
import * as FileSaver from "file-saver";
import {
  ReportViewerDownloadStatusType,
  ReportViewerPageParametersType,
  ReportViewerType,
} from "./ReportViewer.types";
import {
  reportReducer,
  setIsReportReady,
  setReportStatus,
} from "./ReportViewer.reducer";
import { setLoading } from "../../views/layouts/main-layout/MainLayout.reducer";
import dayjs from "dayjs";
import RemediationTrackerTasksReport from "../../views/Reports/RemediationTrackerTasksReport/RemediationTrackerTasksReport";
import { CircularProgress } from "@mui/material";

const ReportViewerNew = (props: ReportViewerType) => {
  const initialState = {
    isLoading: true,
    ReportType: EnumReportType.SummeryReport,
    Inspection: {
      Id: 0,
      Name: "",
    } as InspectionDTO,
    Standard: {
      Id: 0,
      Name: "",
    },
    InspectionStandardId: 0,
    OnRendering: props.OnRendering,
  } as ReportViewerType;

  const isInProgress = useRef(false);

  // check when one time call to get question data from backend

  const [reportPageState, setReportPageState] = useState(initialState);

  const [reportName, setReportName] = useState("");

  //   const reportName = useRef("");

  //   if (props.ReportType == EnumReportType.SummeryReport) {
  //     reportName.current =  "InspectionSummaryReport - " + reportState.Inspection?.Name;
  //   } else if (parameter.ReportType == EnumReportType.StandardReport) {
  //     setReportName(
  //       "StandardSummaryReport - " +
  //         reportState.Inspection?.Name +
  //         (reportState.Inspection.InspectionStandards &&
  //         reportState.Inspection.InspectionStandards[0].Standard != undefined
  //           ? " - " +
  //             reportState.Inspection.InspectionStandards[0].Standard.Name
  //           : "")
  //     );
  //   }

  //PDF screen width,height
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [reportDownloading, setReportDownloading] = useState(false);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  // const [searchParams] = useSearchParams();
  // pass parameters from outside to page
  const location = useLocation();

  // reducer manage fucntions
  const reportState = useAppSelector((state) => state.reportReducer);
  const dispatch = useAppDispatch();

  // const ReportType = parsed.ReportType[0] as unknown as EnumReportType;
  let InspectionID: number = 0;
  let StandardID: number = 0;
  let InspectionStandardId: number = 0;

  if (props.ReportType == EnumReportType.SummeryReport) {
    InspectionID = props.Inspection ? props.Inspection.Id : 0;
  } else if (props.ReportType == EnumReportType.StandardReport) {
    InspectionID = props.Inspection ? props.Inspection.Id : 0;
    StandardID = props.Standard ? props.Standard.Id : 0;
    InspectionStandardId = props.InspectionStandardId
      ? props.InspectionStandardId
      : 0;
  }

  const parameter: ReportViewerPageParametersType = {
    ReportType: props.ReportType,
    InspectionID: InspectionID,
    StandardID: StandardID,
    StandardName: props?.Standard?.Name,
    InspectionStandardId: InspectionStandardId,
  };

  //Everytime state change
  useEffect(() => {
    setReportPageState((values) => {
      return {
        ...values,
        Inspection: reportState.Inspection,
      };
    });

    // if (parameter.ReportType == EnumReportType.SummeryReport) {
    //   setReportName(
    //     "InspectionSummaryReport - " + reportState.Inspection?.Name
    //   );
    // } else if (parameter.ReportType == EnumReportType.StandardReport) {
    //   setReportName(
    //     "StandardSummaryReport - " +
    //       reportState.Inspection?.Name +
    //       (reportState.Inspection.InspectionStandards &&
    //       reportState.Inspection.InspectionStandards[0].Standard != undefined
    //         ? " - " +
    //           reportState.Inspection.InspectionStandards[0].Standard.Name
    //         : "")
    //   );
    // }
  }, [reportState.Inspection]);

  //Resize pdf with window size change
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // const pdfViewerObject = (data) => {
  //   // let mobile: boolean = isMobile;
  //   // let browser: boolean = isBrowser;

  //   let reportData = <></>;

  //   if (parameter.ReportType == EnumReportType.SummeryReport) {
  //     reportData = <SummaryReport SummaryReport={data} />;
  //   } else if (parameter.ReportType == EnumReportType.StandardReport) {
  //     reportData = <StandardReport StandardReport={data} />;
  //   } else if (
  //     parameter.ReportType == EnumReportType.RemediationTrackerTasksReport
  //   ) {
  //     reportData = (
  //       <RemediationTrackerTasksReport
  //         RemediationTrackerTasksReport={data}
  //       ></RemediationTrackerTasksReport>
  //     );
  //   }

  //   return reportData;
  //   // isBrowser ? (
  //   //   <BrowserView>{reportData}</BrowserView>
  //   // ) : (
  //   //   <MobileView>{reportData}</MobileView>
  //   // );
  // };

  // useEffect(() => {
  //   if (reportState.IsReady && reportState.Inspection && isInProgress.current) {
  //     let reportFileName: string = "";
  //     if (parameter.ReportType == EnumReportType.SummeryReport) {
  //       reportFileName =
  //         "InspectionSummaryReport - " + reportState.Inspection?.Name;
  //       // setReportName(reportFileName);
  //     } else if (parameter.ReportType == EnumReportType.StandardReport) {
  //       reportFileName =
  //         "StandardSummaryReport - " +
  //         reportState.Inspection?.Name +
  //         (reportState.Inspection?.InspectionStandards &&
  //         reportState.Inspection?.InspectionStandards[0].Standard != undefined
  //           ? " - " +
  //             reportState.Inspection.InspectionStandards[0].Standard?.Name
  //           : "") +
  //         (" - " + dayjs(new Date()).format("DD-MMM-YYYY HH_mm"));
  //       // setReportName(reportFileName);
  //     } else if (
  //       parameter.ReportType == EnumReportType.RemediationTrackerTasksReport
  //     ) {
  //       reportFileName =
  //         "RemediationTrackerTasksReport - " +
  //         dayjs(new Date()).format("DD-MMM-YYYY HH_mm");
  //     }

  //     pdf(pdfViewerObject(reportState))
  //       .toBlob()
  //       .then((blob) => {
  //         FileSaver.saveAs(blob, `${reportFileName}.pdf`);
  //       })
  //       .finally(() => {
  //         isInProgress.current = false;
  //         dispatch(setIsReportReady(false));
  //         dispatch(setLoading(false));
  //       });
  //   }
  // }, [reportState.IsReady, reportState.Inspection]);

  useEffect(() => {
    const isDownloading = reportState.ReportDownloadStatus.some((item) => {
      return (
        (item.ReportType === EnumReportType.SummeryReport &&
          item.InspectionId === parameter.InspectionID &&
          !parameter.StandardID &&
          item.IsDownloading) ||
        (item.ReportType === EnumReportType.StandardReport &&
          item.InspectionId === parameter.InspectionID &&
          item.StandardName === parameter.StandardName &&
          item.StandardId === parameter.StandardID &&
          item.InspectionStandardId === parameter.InspectionStandardId &&
          item.IsDownloading) ||
        (item.ReportType === EnumReportType.RemediationTrackerTasksReport &&
          item.IsDownloading)
      );
    });

    setReportDownloading(isDownloading);
  }, [reportState.ReportDownloadStatus]);

  const handleClick = () => {
    isInProgress.current = true;
    //dispatch(setLoading(true));

    if (parameter != null) {
      setReportPageState((values) => {
        return {
          ...values,
          Inspection: reportState.Inspection,
        };
      });

      if (parameter.ReportType == EnumReportType.SummeryReport) {
        const initialReportState = {
          ReportType: EnumReportType.SummeryReport,
          Inspection: {
            Id: parameter.InspectionID,
            Name: "",
          } as InspectionDTO,
        } as ReportViewerType;
        dispatch(
          setReportStatus({
            ReportType: EnumReportType.SummeryReport,
            InspectionId: parameter.InspectionID,
            IsDownloading: true,
          } as ReportViewerDownloadStatusType)
        );
        dispatch(getReport(initialReportState));
      } else if (parameter.ReportType == EnumReportType.StandardReport) {
        const initialReportState = {
          ReportType: EnumReportType.StandardReport,
          Inspection: {
            Id: parameter.InspectionID,
            Name: "",
          } as InspectionDTO,
          Standard: {
            Id: parameter.StandardID,
            Name: parameter.StandardName,
          } as StandardDTO,
          InspectionStandardId: parameter.InspectionStandardId,
        } as ReportViewerType;

        dispatch(
          setReportStatus({
            ReportType: EnumReportType.StandardReport,
            InspectionId: parameter.InspectionID,
            StandardId: parameter.StandardID,
            StandardName: parameter.StandardName,
            IsDownloading: true,
            InspectionStandardId: parameter.InspectionStandardId,
          } as ReportViewerDownloadStatusType)
        );
        dispatch(getReport(initialReportState));
      } else if (
        parameter.ReportType == EnumReportType.RemediationTrackerTasksReport
      ) {
        const initialReportState = {
          ReportType: EnumReportType.RemediationTrackerTasksReport,
          RemediationTrackerTask: { TaskName: "" } as RemediationTrackerTaskDTO,
        } as ReportViewerType;
        dispatch(
          setReportStatus({
            ReportType: EnumReportType.RemediationTrackerTasksReport,
            IsDownloading: true,
          } as ReportViewerDownloadStatusType)
        );
        dispatch(getReport(initialReportState));
      }
    }
  };

  return (
    <>
      {reportDownloading && (
        <div>
          <CircularProgress size={25} />
        </div>
      )}
      <div
        onClick={(e) => {
          if (!reportDownloading) {
            e.preventDefault();
            handleClick();
          }
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default ReportViewerNew;
