import { createSlice } from "@reduxjs/toolkit";
import SimpleReactValidator from "simple-react-validator";
import {
  EnumAttributeType,
  EnumPageMode,
  EnumQuestionTypesType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  DefaultTextDTO,
  DropDownDTO,
  LanguageDTO,
  QuestionChoiceDTO,
  QuestionDTO,
  QuestionParameterDTO,
  QuestionTypeDTO,
  SourcesDTO,
  StandardDTO,
  StatusDTO,
  TranslatedTextDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { QuestionPageType } from "./QuestionsPage.types";

// ==============================|| VIEWS - PAGES - QUESTION - REDUCER ||============================== //

// initial state
const initialState = {
  IsInitialDataLoaded: false,
  EffectivePeriodFrom: false,
  EffectivePeriodTo: false,
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsShowSaveBtn: false,
    IsDisable: true,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
  } as PageModeType,

  LanguageId: 1,
  TranslatedText: "",
  RequirementText: "",
  Question: {
    Id: 0,
    AdditionalComments: true,
    NotApplicable: true,
    ProductImpact: false,
    QuestionTypeId: EnumQuestionTypesType.YesNo,
    ValidFrom: "",
    ValidTo: "",
    QuestionChoices: [] as Array<QuestionChoiceDTO>,
    Version: 0.1,
    Status: EnumStatus.Draft,
    StatusNavigation: { Status1: "Draft" } as StatusDTO,
    ImageUpload: EnumAttributeType.Allow,
    DocumentUpload: EnumAttributeType.Allow,
    RequirementText: {
      DefaultText1: "",
      TranslatedTexts: [] as Array<TranslatedTextDTO>,
    } as DefaultTextDTO,
    QuestionType: {
      Code: "",
      Id: 0,
      ModifiedBy: "",
      Name: "",
      Status: EnumStatus.Published,
      Version: 0,
    } as QuestionTypeDTO,
    StandardId: 0,
    Standard: { Name: "", Id: 0 } as StandardDTO,
    QuestionParameters: [] as Array<QuestionParameterDTO>,
    ReferanceList: [] as Array<DropDownDTO>,
    SystemList: [] as Array<DropDownDTO>,
    SubSystemList: [] as Array<DropDownDTO>,
    SubjectList: [] as Array<DropDownDTO>,
    SectionList: [] as Array<DropDownDTO>,
    QuestionTypeList: [] as Array<DropDownDTO>,
    LanguageList: [] as Array<LanguageDTO>,
    SequenceNumber: 0,
  } as QuestionDTO,
  HeaderText: "",
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  ReferenceId: 0,
  SystemId: 0,
  SubSystemId: 0,
  SubjectId: 0,
  SectionId: 0,
  ReferanceList: [] as Array<DropDownDTO>,
  SystemList: [] as Array<DropDownDTO>,
  SubSystemList: [] as Array<DropDownDTO>,
  SubjectList: [] as Array<DropDownDTO>,
  SectionList: [] as Array<DropDownDTO>,
  ViewNavigationArrows: false,
  AllQuestionCount: 0,
} as QuestionPageType;

export const questionSlice = createSlice({
  name: "question",
  initialState: initialState,
  reducers: {
    resetQuestion: (state: QuestionPageType) => {
      return {
        ...state,
        Question: initialState.Question,
      };
    },
    setStandardId: (
      state: QuestionPageType,
      action: { payload: number; type: string }
    ) => {
      return {
        ...state,
        Question: { ...state.Question, StandardId: action.payload },
      };
    },
    setStandard: (
      state: QuestionPageType,
      action: { payload: StandardDTO; type: string }
    ) => {
      return {
        ...state,
        StandardName: action.payload.Name,
      };
    },
    setLanguageId: (
      state: QuestionPageType,
      action: { payload: number; type: string }
    ) => {
      return { ...state, LanguageId: action.payload };
    },
    setLanguageList: (
      state: QuestionPageType,
      action: { payload: Array<LanguageDTO>; type: string }
    ) => {
      return { ...state, LanguageList: action.payload };
    },
    setQuestion: (
      state: QuestionPageType,
      action: { payload: QuestionDTO; type: string }
    ) => {
      return { ...state, Question: action.payload };
    },
    changePageMode: (
      state: QuestionPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowCloseBtn: true,
            IsShowEditBtn: true,
            IsShowDeactivateBtn: true,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowCancelBtn: true,
            IsShowDeactivateBtn: true,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDeactivateBtn: false,
          },
          // Standard: initialState.Standard,
        };
      }
    },
    setSources: (
      state: QuestionPageType,
      action: { payload: SourcesDTO; type: string }
    ) => {
      return {
        ...state,
        Question: {
          ...state.Question,
        },
        ReferenceId: 0,
        SystemId: 0,
        SubSystemId: 0,
        SubjectId: 0,
        SectionId: 0,
      };
    },
    setQuestionPageInitialData: (
      state: QuestionPageType,
      action: { payload: QuestionPageType; type: string }
    ) => {
      return {
        ...state,
        IsInitialDataLoaded: action.payload.IsInitialDataLoaded,
        ReferanceList: action.payload.ReferanceList,
        SystemList: action.payload.SystemList,
        SubSystemList: action.payload.SubSystemList,
        SubjectList: action.payload.SubjectList,
        SectionList: action.payload.SectionList,
        QuestionTypeList: action.payload.QuestionTypeList,
        LanguageList: action.payload.LanguageList,
        Question: {
          ...state.Question,
          Id: action.payload.Question.Id,
          StandardId: action.payload.Question.StandardId,
          Standard: action.payload.Question.Standard,
        },
        Standard: action.payload.Question.Standard,
      };
    },
    setQuestionStatus: (
      state: QuestionPageType,
      action: { payload: QuestionDTO; type: string }
    ) => {
      return {
        ...state,
        Question: {
          ...state.Question,
          Status: action.payload.Status,
          StatusNavigation: action.payload.StatusNavigation,
        },
      };
    },
    setInitialDataLoad: (
      state: QuestionPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        IsInitialDataLoaded: action.payload,
      };
    },
    setViewNavigationArrows: (
      state: QuestionPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        ViewNavigationArrows: action.payload,
      };
    },
    setAllQuestionCount: (
      state: QuestionPageType,
      action: { payload: number; type: string }
    ) => {
      return {
        ...state,
        AllQuestionCount: action.payload,
      };
    },
  },
});

export const {
  setLanguageId,
  setLanguageList,
  setQuestion,
  changePageMode,
  resetQuestion,
  setStandardId,
  setSources,
  setQuestionPageInitialData,
  setQuestionStatus,
  setInitialDataLoad,
  setViewNavigationArrows,
  setAllQuestionCount,
} = questionSlice.actions;
export const questionReducer = questionSlice.reducer;
export default false;
