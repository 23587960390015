import {
  RemediationTrackerTaskDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

const client = new ApiClient();

const renameFile = (originalFile, newName): File => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

const UploadFileListBeforeUpload = (
  UploadedFileList: Array<UploadFileDTO>
): Array<UploadFileDTO> => {
  if (UploadedFileList && UploadedFileList.length > 0) {
    UploadedFileList = [...UploadedFileList].map(
      (uploadFile, index: number) => ({
        ...uploadFile,
        FilePreview: "",
        File:
          uploadFile.File != null
            ? renameFile(
                uploadFile.File,
                (index < 10 ? `00${index}` : index < 100 ? `0${index}` : "") +
                  uploadFile.FileName
              )
            : null,
        FileData: null,
        // FileBase64: convertFileToBase64(uploadFile.File),
      })
    );
  }

  return UploadedFileList;
};

const toBase64 = async (file) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

export const getRemediationTrackerTaskById = async (
  remediationTrackerTaskId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `RemediationTrackerTask/GetRemediationTrackerTaskById/${remediationTrackerTaskId}`;
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const getQuestionsForRemediationTrackerTasks = async (
  inspectionQuestionIdList: Array<number>
): Promise<ActionResponseType> => {
  try {
    const URL: string =
      "RemediationTrackerTask/GetQuestionsForRemediationTrackerTasks";
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, inspectionQuestionIdList)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveRemediationTrackerTaskApi = async (
  remediationTrackerTask: RemediationTrackerTaskDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "";
    if (remediationTrackerTask.Id > 0) {
      URL = "RemediationTrackerTask/UpdateRemediationTrackerTask";
    } else {
      URL = "RemediationTrackerTask/AddRemediationTrackerTask";
    }
    let result: ActionResponseType = {} as ActionResponseType;

    if (
      remediationTrackerTask.UploadFiles &&
      remediationTrackerTask.UploadFiles.length > 0
    ) {
      remediationTrackerTask.UploadFiles = [
        ...remediationTrackerTask.UploadFiles,
      ].map((uploadFile, index: number) => ({
        ...uploadFile,
        FilePreview: "",
        File: null,
        FileData: null,
      }));
    }

    await client
      .post<ActionResponseType>(URL, remediationTrackerTask)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const deleteFilesOnCancelClick = async (
  remediationTrackerTask: RemediationTrackerTaskDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "RemediationTrackerTask/DeleteFilesOnCancel";
    let result: ActionResponseType = {} as ActionResponseType;

    if (
      remediationTrackerTask.UploadFiles &&
      remediationTrackerTask.UploadFiles.length > 0
    ) {
      remediationTrackerTask.UploadFiles = [
        ...remediationTrackerTask.UploadFiles,
      ].map((uploadFile, index: number) => ({
        ...uploadFile,
        FilePreview: "",
        File: null,
        FileData: null,
      }));
    }
    await client
      .post<ActionResponseType>(URL, remediationTrackerTask)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// export const removeRemediationTrackerTask = async (
//   remediationTrackerTaskId: number
// ): Promise<ActionResponseType> => {
//   try {
//     let URL: string = "RemediationTrackerTask/RemoveRemediationTrackerTask";
//     let result: ActionResponseType = {} as ActionResponseType;
//     await client
//       .post<ActionResponseType>(URL, remediationTrackerTaskId)
//       .then((response) => {
//         result = response;
//       })
//       .catch((error) => {
//         return error;
//       });
//     return result;
//   } catch (error) {
//     throw error;
//   }
// };

export const remediationTrackerTaskFileUpload = async (
  uploadFile: UploadFileDTO
): Promise<ActionResponseType> => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const URL: string = "File/UploadFile";
    let status: ActionResponseType;

    uploadFile = UploadFileListBeforeUpload([
      uploadFile,
    ] as Array<UploadFileDTO>)[0];

    status = await toBase64(uploadFile.File).then((result) => {
      const base64String: string = result?.toString().split(",")[1];

      // shit data by 3
      const obfuscateBase64ShiftEncodingString =
        obfuscateBase64ShiftEncoding(base64String);
      // encrypt data by urlencoded data
      const urlEncodedString = encodeURIComponent(
        obfuscateBase64ShiftEncodingString
      );
      uploadFile.FileBase64 = urlEncodedString;
      uploadFile.File = null;
      return client
        .post<ActionResponseType>(URL, uploadFile, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          status = {} as ActionResponseType;
          return error;
        });
    });
    // status = await response;
    if (status) return status;
  } catch (error) {
    throw error;
  }
};

const obfuscateBase64ShiftEncoding = (base64String) => {
  const shift = 3; // Shift each character by 3 positions
  let obfuscatedString = "";

  for (let char of base64String) {
    obfuscatedString += String.fromCharCode(char.charCodeAt(0) + shift);
  }

  return obfuscatedString;
};
