// react
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";

import dayjs from "dayjs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

/* Icons */
import {
  ArrowDown,
  ArrowLeft2,
  ArrowRight2,
  ArrowUp,
  Bag,
  Calendar,
  More,
  Repeat,
  Sort,
} from "iconsax-react";
import noData from "../../../images/no_data.svg";
// material UI
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// ant
import { DatePicker } from "antd";
// redux
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";

// project imports
import {
  InspectionDTO,
  InspectionSetDTO,
  SearchParameterDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";
import {
  InspectionAllocationPageInitialState,
  InspectionAssignmentPageSortType,
} from "./InspectionAllocationPage.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import {
  EnumInspectionAllocationShowYearCount,
  EnumInspectionStatus,
  EnumPageMode,
} from "../../../common/enums/common.enums";
import { setInspectionAllocationInitState } from "./InspectionAllocationPage.reducer";
import {
  deleteInspectionAllocation,
  getInspectionSetListForInspectionAllocation,
  getSiteInspectionAllocationList,
  saveInspectionAllocation,
  updateInspectionAllocation,
} from "./InspectionAllocationPage.action";

import { AssignInspectionPageParametersType } from "../AssignInspectionPage/AssignInspectionPage.types";
/* Components */
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import CloseIcon from "@mui/icons-material/Close";
/* Components End */

// ==============================|| VIEWS - PAGES - INSPECTIONALLOCATION ||============================== //

const InspectionAllocationPage = (props: PageRegistrationProperties) => {
  const getYearList = (isPreviousYearList: boolean, Year: number) => {
    let yearList = [];

    if (isPreviousYearList) {
      for (
        let i = EnumInspectionAllocationShowYearCount.ShowYearCount - 1;
        i >= 0;
        i--
      ) {
        yearList.push(Year - i);
      }
    } else {
      for (
        let i = 0;
        i < EnumInspectionAllocationShowYearCount.ShowYearCount;
        i++
      ) {
        yearList.push(Year + i);
      }
    }

    return yearList;
  };

  const initialState = {
    InspectionSetList: [] as Array<InspectionSetDTO>,
    SiteInspectionAllocationList: [] as Array<SiteDTO>,
    InspectionSetSearchParameter: {
      InspectionSetSearchParams: {
        Name: "",
      } as InspectionSetDTO,
    } as SearchParameterDTO,
    SiteSearchParameter: {
      SiteSearchParams: {
        SiteName: "",
        AssignedFromYear: new Date().getFullYear() - 2,
        AssignedToYear: new Date().getFullYear() + 2,
      } as SiteDTO,
    } as SearchParameterDTO,
    AllocationYearList: getYearList(false, new Date().getFullYear() - 2),
    Inspection: {
      Id: 0,
      SiteId: 0,
      PlannedCompletionDate: "",
      AllocatedTime: "",
    } as InspectionDTO,
  } as InspectionAllocationPageInitialState;

  // registering page properties
  usePageRegistration(props);

  const dataFetchedRef = useRef(false);

  // navigate
  const navigate = useNavigate();

  // reducer
  const inspectionAllocationState = useAppSelector(
    (state) => state.InspectionAllocationReducer
  );
  const dispatch = useAppDispatch();

  const [isMouseOverInspectionSet, setIsMouseOverInspectionSet] =
    useState(false);
  const [isMouseOverSite, setIsMouseOverSite] = useState(false);

  const handleMouseEnterInspectionSetSearch = () => {
    setIsMouseOverInspectionSet(true);
  };

  const handleMouseLeaveInspectionSetSearch = () => {
    setIsMouseOverInspectionSet(false);
  };

  const handleMouseEnterSiteSearch = () => {
    setIsMouseOverSite(true);
  };

  const handleMouseLeaveSiteSearch = () => {
    setIsMouseOverSite(false);
  };

  //page state
  const [inspectionAllocationPageState, setInspectionAllocationPageState] =
    useState(initialState);

  //-----Page load-----
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    dispatch(setInspectionAllocationInitState(inspectionAllocationPageState));

    //get Inspection Set List for Inspection Allocation
    dispatch(
      getInspectionSetListForInspectionAllocation(
        inspectionAllocationPageState.InspectionSetSearchParameter
      )
    );

    //get site and  Inspection Allocation
    dispatch(
      getSiteInspectionAllocationList(
        inspectionAllocationPageState.SiteSearchParameter
      )
    );
  }, []);

  //set Page State when reducer change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        InspectionSetList: inspectionAllocationState.InspectionSetList,
        SiteInspectionAllocationList:
          inspectionAllocationState.SiteInspectionAllocationList,
        Inspection: inspectionAllocationState.Inspection,
      };
    });
  }, [
    inspectionAllocationState.InspectionSetList,
    inspectionAllocationState.SiteInspectionAllocationList,
  ]);

  const getListOfSiteInspectionAllocation = (
    AssignedFromYear: number,
    AssignedToYear: number
  ) => {
    //get site and  Inspection Allocation
    dispatch(
      getSiteInspectionAllocationList({
        ...inspectionAllocationPageState.SiteSearchParameter,
        SiteSearchParams: {
          ...inspectionAllocationPageState.SiteSearchParameter.SiteSearchParams,
          AssignedFromYear: AssignedFromYear,
          AssignedToYear: AssignedToYear,
        },
      })
    );
  };

  // NextYearBtnClick - get next year data
  // getYearList - get next year by passing parameters
  // isPreviousYearList: false , Year: inspectionAllocationPageState.AllocationYearList[3]
  const NextYearBtnClick = () => {
    let YearList = getYearList(
      false,
      inspectionAllocationPageState.AllocationYearList[1]
    );
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        AllocationYearList: YearList,
        SiteSearchParameter: {
          ...values.SiteSearchParameter,
          SiteSearchParams: {
            ...values.SiteSearchParameter.SiteSearchParams,
            AssignedFromYear: YearList[0],
            AssignedToYear:
              YearList[EnumInspectionAllocationShowYearCount.ShowYearCount - 1],
          },
        },
      };
    });

    getListOfSiteInspectionAllocation(
      YearList[0],
      YearList[EnumInspectionAllocationShowYearCount.ShowYearCount - 1]
    );
  };

  // PreviousYearBtnClick - get Previous year data
  // getYearList - get Previous year by passing parameters
  // isPreviousYearList: true , Year: inspectionAllocationPageState.AllocationYearList[1]
  const PreviousYearBtnClick = () => {
    let YearList = getYearList(
      true,
      inspectionAllocationPageState.AllocationYearList[3]
    );

    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        AllocationYearList: YearList,
        SiteSearchParameter: {
          ...values.SiteSearchParameter,
          SiteSearchParams: {
            ...values.SiteSearchParameter.SiteSearchParams,
            AssignedFromYear: YearList[0],
            AssignedToYear:
              YearList[EnumInspectionAllocationShowYearCount.ShowYearCount - 1],
          },
        },
      };
    });

    getListOfSiteInspectionAllocation(
      YearList[0],
      YearList[EnumInspectionAllocationShowYearCount.ShowYearCount - 1]
    );
  };

  //-----on change site serach TextBox-----
  const onSearchBySiteChange = (searchValue: string) => {
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        SiteSearchParameter: {
          ...values.SiteSearchParameter,
          SiteSearchParams: {
            ...values.SiteSearchParameter.SiteSearchParams,
            SiteName: searchValue,
          },
        },
      };
    });

    if (searchValue?.length > 3 || searchValue === "") {
      dispatch(
        getSiteInspectionAllocationList({
          ...inspectionAllocationPageState.SiteSearchParameter,
          SiteSearchParams: {
            ...inspectionAllocationPageState.SiteSearchParameter
              .SiteSearchParams,
            SiteName: searchValue,
          },
        })
      );
    }
  };

  //-----enetr Key Press ===> site serach TextBox-----
  const onSearchBySiteKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      dispatch(
        getSiteInspectionAllocationList(
          inspectionAllocationPageState.SiteSearchParameter
        )
      );
    }
  };

  //-----on change Inspection Set serach TextBox-----
  const onSearchByInspectionSetChange = (searchValue: string) => {
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        InspectionSetSearchParameter: {
          ...values.InspectionSetSearchParameter,
          InspectionSetSearchParams: {
            ...values.InspectionSetSearchParameter.InspectionSetSearchParams,
            Name: searchValue,
          },
        },
      };
    });

    if (searchValue.length > 3 || searchValue === "") {
      dispatch(
        getInspectionSetListForInspectionAllocation({
          ...inspectionAllocationPageState.InspectionSetSearchParameter,
          InspectionSetSearchParams: {
            ...inspectionAllocationPageState.InspectionSetSearchParameter
              .InspectionSetSearchParams,
            Name: searchValue,
          },
        })
      );
    }
  };

  //-----enetr Key Press ===> Inspection Set serach TextBox-----
  const onSearchByInspectionSetKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      dispatch(
        getInspectionSetListForInspectionAllocation(
          inspectionAllocationPageState.InspectionSetSearchParameter
        )
      );
    }
  };

  // ----------------------Menu -------------------------------------------------
  // ----------------------inspection Set menu -----------------------------------
  const [inspectionSetanchorEl, setInspectionSetanchorEl] =
    useState<null | HTMLElement>(null);
  const [locationSortAnchorEl, setLocationSortAnchorEl] =
    useState<null | HTMLElement>(null);
  const LocationSortOpen = Boolean(locationSortAnchorEl);
  const inspectionSetOpen = Boolean(inspectionSetanchorEl);
  const handleInspectionSetClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setInspectionSetanchorEl(event.currentTarget);
  };
  const handleInspectionSetClose = () => {
    setInspectionSetanchorEl(null);
  };

  const handleLocationSortClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setLocationSortAnchorEl(event.currentTarget);
  };
  const handleLocationSortClose = () => {
    setLocationSortAnchorEl(null);
  };
  //------------------------Inspection Year menu----------------------------------
  const [inspectionYearanchorEl, setInspectionYearanchorEl] =
    useState<null | HTMLElement>(null);
  const inspectionYearOpen = Boolean(inspectionYearanchorEl);

  const handleInspectionYearClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    year: string
  ) => {
    setInspectionYearanchorEl(event.currentTarget);
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          AllocatedTime: dayjs(new Date(year)).toString(),
        },
      };
    });
  };
  const handleInspectionYearClose = () => {
    clearSelectedInspection(); // clear Inspection Object
    setInspectionYearanchorEl(null);
  };

  //-------------------------Inspection Detail Menu---------------------------------

  const [inspectionDetailsanchorEl, setInspectionDetailsanchorEl] =
    useState<null | HTMLElement>(null);
  const inspectionDetailOpen = Boolean(inspectionDetailsanchorEl);
  const handleInspectionDetailClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    inspection: InspectionDTO
  ) => {
    setInspectionDetailsanchorEl(event.currentTarget);
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          Id: inspection.Id,
          SiteId: inspection.SiteId,
          InspectionStatusId: inspection.InspectionStatusId,
          ShowInspectionStartedBy: inspection.ShowInspectionStartedBy,
        },
      };
    });
  };
  const handleInspectionDetailClose = () => {
    setInspectionDetailsanchorEl(null);
    clearSelectedInspection();
  };

  // ---------------------------------menu end--------------------------------------

  // clear Inspection Object
  const clearSelectedInspection = () => {
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        Inspection: inspectionAllocationState.Inspection,
      };
    });
  };

  const handleOpenDatePicker = (id: number, plannedCompletionDate: string) => {
    // Inspection id is same then close date picker & set Inspection object to default
    if (inspectionAllocationPageState.Inspection.Id === id) {
      clearSelectedInspection(); // clear Inspection Object
    } else {
      setInspectionAllocationPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            Id: id,
            PlannedCompletionDate: plannedCompletionDate,
          },
        };
      });
    }
  };

  const onPlannedCompletionDateChange = (
    inspectionId: number,
    date: dayjs.Dayjs,
    inspectionName: string
  ) => {
    dispatch(
      updateInspectionAllocation({
        Id: inspectionId,
        Name: inspectionName,
        PlannedCompletionDate: date.format("YYYY-MM-DD"),
        AllocatedTime: dayjs(new Date(date.year().toString())).format(
          "YYYY-MM-DD"
        ),
      } as InspectionDTO)
    );
    clearSelectedInspection();
  };

  const BackbtnOnclick = () => {
    navigate("/");
  };

  // Drag And Drop
  const onDragEnd = (result) => {
    const { destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInspectionId = result.draggableId;
    const dResult: string = destination.droppableId;

    // Split Droppable ID _
    // [0] = Site Id
    // [1] = Year
    const splitDResult = dResult.split("_");
    const siteId = parseInt(splitDResult[0]);
    const year = dayjs(new Date(splitDResult[1])).format("YYYY-MM-DD");

    let dropInspection = inspectionAllocationPageState.InspectionSetList?.find(
      (item) => item.Id.toString() === sInspectionId.toString()
    );

    dispatch(
      saveInspectionAllocation({
        InspectionSetId: sInspectionId,
        Name: dropInspection.Name,
        SiteId: siteId,
        AllocatedTime: year,
      } as InspectionDTO)
    );
  };

  const onRemoveInspection = () => {
    handleInspectionDetailClose();
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to remove?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            deleteInspectionAllocation(inspectionAllocationPageState.Inspection)
          );
        },
      } as PopupProps)
    );
  };

  // Sort By
  // Inspection set sort by date ASC & DESC
  const onInspectionSetSortBy = (isDesc?: boolean) => {
    let SortedInspectionSet = [
      ...inspectionAllocationPageState.InspectionSetList,
    ].sort(
      (a, b) =>
        new Date(a["ModifiedDate"]).valueOf() -
        new Date(b["ModifiedDate"]).valueOf()
    );

    if (isDesc) {
      SortedInspectionSet = SortedInspectionSet.reverse();
    }

    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        InspectionSetList: SortedInspectionSet,
      };
    });

    handleInspectionSetClose();
  };

  // Sort By Year Column
  const onInspectionYearSortByAsc = (
    sortType: InspectionAssignmentPageSortType
  ) => {
    // Sort Year
    let SelectedYear = new Date(
      inspectionAllocationPageState.Inspection.AllocatedTime
    ).getFullYear();

    // Sort By scheduling & By gaps
    let SortedInspection = [
      ...inspectionAllocationPageState.SiteInspectionAllocationList,
    ].map((item) => {
      const notSortsiteInspections = [
        ...item.Inspections.filter(
          (filter) =>
            new Date(filter.AllocatedTime).getFullYear() !== SelectedYear
        ),
      ];

      // return 0 use if both are equal(skip sort)
      const siteInspections = [
        ...item.Inspections?.filter(
          (filter) =>
            new Date(filter.AllocatedTime).getFullYear() === SelectedYear
        ),
      ]?.sort((a, b) => {
        switch (sortType) {
          case InspectionAssignmentPageSortType.ByScheduling:
            return (
              new Date(a.PlannedCompletionDate).valueOf() -
              new Date(b.PlannedCompletionDate).valueOf()
            ); // asc
          case InspectionAssignmentPageSortType.ByGaps:
            return a.Gaps - b.Gaps; // asc
          case InspectionAssignmentPageSortType.ByInspectionSetName:
            return a.Name.localeCompare(b.Name); // asc
          case InspectionAssignmentPageSortType.ByStatus:
            return a.InspectionStatusId - b.InspectionStatusId; // asc
          default:
            return 0;
        }
      });

      return {
        ...item,
        Inspections: [...notSortsiteInspections, ...siteInspections],
      };
    });

    // Assign sorted array to page state
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        SiteInspectionAllocationList: SortedInspection,
      };
    });
    handleInspectionYearClose();
  };

  const onInspectionYearSortByDesc = (
    sortType: InspectionAssignmentPageSortType
  ) => {
    // Sort Year
    let SelectedYear = new Date(
      inspectionAllocationPageState.Inspection.AllocatedTime
    ).getFullYear();

    // Sort By scheduling & By gaps
    let SortedInspection = [
      ...inspectionAllocationPageState.SiteInspectionAllocationList,
    ].map((item) => {
      const notSortsiteInspections = [
        ...item.Inspections.filter(
          (filter) =>
            new Date(filter.AllocatedTime).getFullYear() !== SelectedYear
        ),
      ];

      // return 0 use if both are equal(skip sort)
      const siteInspections = [
        ...item.Inspections?.filter(
          (filter) =>
            new Date(filter.AllocatedTime).getFullYear() === SelectedYear
        ),
      ]?.sort((a, b) => {
        switch (sortType) {
          case InspectionAssignmentPageSortType.ByScheduling:
            return (
              new Date(b.PlannedCompletionDate).valueOf() -
              new Date(a.PlannedCompletionDate).valueOf()
            ); //  desc
          case InspectionAssignmentPageSortType.ByGaps:
            return b.Gaps - a.Gaps; //  desc
          case InspectionAssignmentPageSortType.ByInspectionSetName:
            return b.Name.localeCompare(a.Name); // desc
          case InspectionAssignmentPageSortType.ByStatus:
            return b.InspectionStatusId - a.InspectionStatusId; // desc
          default:
            return 0;
        }
      });

      return {
        ...item,
        Inspections: [...notSortsiteInspections, ...siteInspections],
      };
    });

    // Assign sorted array to page state
    setInspectionAllocationPageState((values) => {
      return {
        ...values,
        SiteInspectionAllocationList: SortedInspection,
      };
    });

    handleInspectionYearClose();
  };

  const onMoreDetailsClick = () => {
    navigate("/assignInspection", {
      state: {
        InspectionId: inspectionAllocationPageState.Inspection.Id,
        PageMode: EnumPageMode.ViewMode,
      } as AssignInspectionPageParametersType,
    });
  };

  //-----standard list heigh ------
  const mainHeading = useRef(null);

  const calendarHeading = useRef(null);
  const legend = useRef(null);
  const subHeading = useRef(null);

  //Set Hight
  const [listHeight, setListHeight] = useState(0);
  const [calendarHeight, setCalendarHeight] = useState(0);

  //-----Page load-----
  useEffect(() => {
    let listheight =
      window.innerHeight -
      // - mainHeading.current.offsetHeight
      87;
    setListHeight(listheight);

    let calendarHeight =
      listheight -
      subHeading.current.offsetHeight -
      calendarHeading.current.offsetHeight -
      63;
    setCalendarHeight(calendarHeight);

    function handleResize() {
      let listheight =
        window.innerHeight - mainHeading.current.offsetHeight - 119;
      setListHeight(listheight);

      let calendarHeight =
        listheight -
        subHeading.current.offsetHeight -
        calendarHeading.current.offsetHeight -
        61;
      setCalendarHeight(calendarHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //set style
  const setCalendaCellYearClass = (year: number) => {
    //set calendar Cell color
    let styleClass = "";
    if (year < new Date().getFullYear()) {
      styleClass = "past-years";
    } else if (year === new Date().getFullYear()) {
      styleClass = "curent-year";
    }

    return styleClass;
  };

  const setInspectionStatusClass = (status: EnumInspectionStatus) => {
    let styleClass = "";

    if (status === EnumInspectionStatus.NotPlanned) {
      styleClass = "not-planned";
    } else if (status === EnumInspectionStatus.Scheduled) {
      styleClass = "scheduled";
    } else if (
      status === EnumInspectionStatus.InProgress ||
      status === EnumInspectionStatus.Paused
    ) {
      styleClass = "inprogress";
    } else if (status === EnumInspectionStatus.Completed) {
      styleClass = "completed";
    } else if (status === EnumInspectionStatus.Overdue) {
      styleClass = "overdue";
    }

    return styleClass;
  };

  // set Calender Default Date
  const setCalenderDefaultDate = (year: number, date: string) => {
    let plannedCompletionDate: dayjs.Dayjs = null;
    if (date) {
      plannedCompletionDate = dayjs(date);
    }
    // else if (new Date().getFullYear() !== year) {
    //   //plannedCompletionDate = dayjs(new Date(year.toString()));
    // }

    return plannedCompletionDate;
  };

  // render UI start

  const renderInspectionAllocationMainBody = (site: SiteDTO) => {
    return (
      <>
        {inspectionAllocationPageState.AllocationYearList.map(
          (year: number, yearIndex: number) => {
            return (
              <Droppable
                key={`${site.Id}_${year}`}
                droppableId={`${site.Id}_${year}`}
                isDropDisabled={year < new Date().getFullYear()}
              >
                {(provided, snapshot) => (
                  <div
                    className={`calendar-cell ${setCalendaCellYearClass(
                      year
                    )} ${snapshot.isDraggingOver ? "active-cell" : ""}`}
                    key={`inspectionYear_${site.Id}_${year}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {renderInspectionCell(year, site)}
                  </div>
                )}
              </Droppable>
            );
          }
        )}
      </>
    );
  };

  const renderInspectionCell = (year: number, site: SiteDTO) => {
    return (
      <>
        {site.Inspections?.map((inspection: InspectionDTO, index) => {
          return new Date(inspection.AllocatedTime).getFullYear() === year ? (
            <div
              className={`inspection-allocation-tile ${setInspectionStatusClass(
                inspection.InspectionStatusId
              )} `}
              key={`inspection_${inspection.Id}`}
            >
              <div className="top-sec flex--container">
                <Tooltip
                  title={inspection.Name}
                  PopperProps={{
                    container: document.querySelector(
                      ".inspection-allocation-tile"
                    ),
                    disablePortal: true, // Prevents the tooltip from being rendered outside the parent hierarchy
                  }}
                >
                  <div className="header flex__item--vcenter limit-1">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/assignInspection", {
                          state: {
                            InspectionId: inspection.Id,
                            PageMode: EnumPageMode.ViewMode,
                          } as AssignInspectionPageParametersType,
                        });
                      }}
                    >
                      {inspection.Name}
                    </span>
                  </div>
                </Tooltip>
                <div className="ico-grp flex__item--vcenter">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={setCalenderDefaultDate(
                        year,
                        inspection.PlannedCompletionDate
                      )}
                      defaultPickerValue={
                        year.toString() === new Date().getFullYear().toString()
                          ? dayjs(new Date())
                          : dayjs(new Date(year.toString()))
                      }
                      open={
                        inspectionAllocationPageState.Inspection.Id ===
                          inspection.Id && inspectionDetailOpen === false
                      }
                      style={{
                        width: 0,
                        visibility: "hidden",
                      }}
                      onChange={(newDate) => {
                        onPlannedCompletionDateChange(
                          inspection.Id,
                          newDate,
                          inspection.Name
                        );
                      }}
                      disabledDate={(currentDate) => {
                        return currentDate.isBefore(dayjs().subtract(1, "day"));
                      }}
                    />
                  </LocalizationProvider>

                  {/* Hide Date Picker for past year */}
                  {inspection.AllocatedTime &&
                    new Date(inspection.AllocatedTime).getFullYear() >=
                      new Date().getFullYear() &&
                    (inspection.InspectionStatusId ===
                      EnumInspectionStatus.NotPlanned ||
                      inspection.InspectionStatusId ===
                        EnumInspectionStatus.Scheduled ||
                      inspection.InspectionStatusId ===
                        EnumInspectionStatus.Overdue) &&
                    !inspection.ShowInspectionStartedBy && (
                      <IconButton
                        onClick={() => {
                          handleOpenDatePicker(
                            inspection.Id,
                            inspection.PlannedCompletionDate
                              ? inspection.PlannedCompletionDate
                              : ""
                          );
                        }}
                        onBlur={() => {
                          clearSelectedInspection();
                        }}
                      >
                        <Calendar size="16" variant="Outline" />
                      </IconButton>
                    )}

                  <IconButton
                    id="more-btn"
                    onClick={(event) => {
                      handleInspectionDetailClick(event, inspection);
                    }}
                  >
                    <More className="more" size="16" variant="Outline" />
                  </IconButton>
                </div>
              </div>
              {renderInspectionBody(inspection)}
            </div>
          ) : (
            ""
          );
        })}
      </>
    );
  };

  const renderInspectionBody = (inspection: InspectionDTO) => {
    return (
      <>
        {inspection.PlannedCompletionDate ? (
          <div className="bottom-sec flex--container">
            <div className="date flex__item--vcenter body4">
              {inspection.PlannedCompletionDate
                ? dayjs(inspection.PlannedCompletionDate).format("DD-MMM-YYYY")
                : ""}
            </div>
            <div className="icon-grp flex__item--vcenter">
              {inspection.InspectionStatusId ===
                EnumInspectionStatus.Completed ||
              inspection.InspectionStatusId ===
                EnumInspectionStatus.InProgress ||
              inspection.ShowInspectionStartedBy ? (
                <div
                  className={`${
                    inspection.Gaps && inspection.Gaps > 0 ? "gaps" : "no-gaps"
                  }`}
                >
                  <span>{inspection.Gaps ? inspection.Gaps : "0"}</span>
                  &nbsp;Gaps
                </div>
              ) : (
                ""
              )}

              {inspection.Recurring ? (
                <Repeat className="reccursion" size="16" variant="Outline" />
              ) : (
                <>&nbsp;</>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  // render UI end

  function onLocationSortByAsc() {
    const sortedSiteInspectionAllocationList = [
      ...inspectionAllocationPageState.SiteInspectionAllocationList,
    ].sort((a, b) => {
      return a.SiteName.localeCompare(b.SiteName);
    });

    setInspectionAllocationPageState({
      ...inspectionAllocationPageState,
      SiteInspectionAllocationList: sortedSiteInspectionAllocationList,
    });

    handleLocationSortClose();
  }
  function onLocationSortByDesc() {
    const sortedSiteInspectionAllocationList = [
      ...inspectionAllocationPageState.SiteInspectionAllocationList,
    ].sort((a, b) => {
      return b.SiteName.localeCompare(a.SiteName);
    });

    setInspectionAllocationPageState({
      ...inspectionAllocationPageState,
      SiteInspectionAllocationList: sortedSiteInspectionAllocationList,
    });

    handleLocationSortClose();
  }

  return (
    <>
      {/* Global Header with Status & Version */}
      {/* <Grid
        container
        columns={12}
        className=""
        ref={mainHeading}
      > */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>{props.PageTitle}</h1>
        </Grid> */}

      {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        ></Grid>
      </Grid> */}
      {/* Global Header with Status & Version  End*/}
      <div
        className="content-section-card allocation-specific top-main-padding"
        style={{ height: listHeight }}
      >
        {/* Global Sub Header with  Buttons*/}
        <Grid
          container
          columns={12}
          className="global-subhead-btn-wrap"
          ref={subHeading}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="global-subhead-wrap top-main-border-inner"
          >
            {/* <IconButton
              aria-label="back"
              className="sub-head-back-btn"
              onClick={BackbtnOnclick}
              title={"Back to home page"}
            >
              <ArrowLeft2 size="24" variant="Outline" />
            </IconButton> */}
            <Button
              aria-label="back"
              className="sub-head-back-btn back"
              onClick={BackbtnOnclick}
              title="Back to home page"
            >
              {/* <ArrowLeft2 size="24" variant="Outline" /> */}
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color">Back</span>
            </Button>
            {/* <h2>Inspection Allocation</h2> */}

            <Grid item lg={6} md={6} sm={12} xs={12} className="">
              <h1>{props.PageTitle}</h1>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} className="mt-10 pr-10">
            <div className="legend" ref={legend}>
              <div className="item1 flex--container">
                <div className="flex__item--inherit symbol completed"></div>
                <div className="flex__item--vcenter label">Completed</div>
              </div>
              <div className="item1 flex--container">
                <div className="flex__item--inherit symbol inprogress"></div>
                <div className="flex__item--vcenter label">In progress</div>
              </div>
              <div className="item1 flex--container">
                <div className="flex__item--inherit symbol scheduled"></div>
                <div className="flex__item--vcenter label">Scheduled</div>
              </div>
              <div className="item1 flex--container">
                <div className="flex__item--inherit symbol notplanned"></div>
                <div className="flex__item--vcenter label">Not planned</div>
              </div>
              <div className="item1 flex--container">
                <div className="flex__item--inherit symbol overdue"></div>
                <div className="flex__item--vcenter label">Overdue</div>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* Global Sub Header with  Buttons*/}

        {/* calendar start */}
        <div className="calendar pl-10 pr-10">
          <Grid container spacing={2}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid item xs={10}>
                {/* Grid Body */}
                <div
                  className="calendar-grid-header flex--container"
                  ref={calendarHeading}
                >
                  <div
                    className="sites-search flex__item--vcenter"
                    style={{ position: "relative", left: "" }}
                  >
                    {/* Serarch Start */}

                    <div className="flex--container">
                      <div className="flex__item search-bar--small unset-width">
                        <Paper
                          component="form"
                          sx={{
                            p: "1px 2px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onMouseEnter={handleMouseEnterSiteSearch}
                          onMouseLeave={handleMouseLeaveSiteSearch}
                        >
                          <Tooltip title="Search by Sites">
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              placeholder="Search by Sites"
                              inputProps={{ "aria-label": "Standard" }}
                              value={
                                inspectionAllocationPageState
                                  .SiteSearchParameter.SiteSearchParams.SiteName
                              }
                              onChange={(event) => {
                                event.stopPropagation();
                                onSearchBySiteChange(event.target?.value);
                              }}
                              onKeyPress={onSearchBySiteKeyPress}
                              //type="search"
                            />
                          </Tooltip>
                          {isMouseOverSite &&
                            inspectionAllocationPageState.SiteSearchParameter
                              ?.SiteSearchParams?.SiteName &&
                            inspectionAllocationPageState.SiteSearchParameter
                              ?.SiteSearchParams?.SiteName?.length > 0 && (
                              <Tooltip title="Clear" placement="left-end">
                                <IconButton
                                  className="mr-0"
                                  onClick={() => onSearchBySiteChange("")}
                                >
                                  <CloseIcon
                                    style={{
                                      color: "#219ebc",
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Paper>
                      </div>
                      <div className="flex__item--inherit">
                        <IconButton onClick={handleLocationSortClick}>
                          <Sort color="#FF8A65" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div className="year-previous">
                    <IconButton onClick={PreviousYearBtnClick}>
                      <ArrowLeft2 variant="Outline" />
                    </IconButton>
                  </div>

                  {inspectionAllocationPageState.AllocationYearList?.map(
                    (year: number) => {
                      return (
                        <div
                          className="year flex--container"
                          key={`year_${year}`}
                        >
                          <div className="flex__item text-center">{year}</div>
                          <div className="flex__item--inherit sort">
                            <IconButton
                              id="filter-btn"
                              onClick={(event) => {
                                handleInspectionYearClick(
                                  event,
                                  year.toString()
                                );
                              }}
                            >
                              <Sort color="#FF8A65" />
                            </IconButton>
                          </div>
                        </div>
                      );
                    }
                  )}

                  <div className="year-next">
                    <IconButton onClick={NextYearBtnClick}>
                      <ArrowRight2 variant="Outline" />
                    </IconButton>
                  </div>
                </div>

                {/* site Inspection Allocation */}
                <div
                  className="calendar-grid-body-wrap loading-delay"
                  style={{ maxHeight: calendarHeight }}
                >
                  {inspectionAllocationPageState.SiteInspectionAllocationList?.map(
                    (site: SiteDTO, index) => {
                      return (
                        <div
                          className="calendar-grid-body"
                          key={`site_${site.Id}`}
                        >
                          {/* Inspection Allocation */}
                          <div className="calendar-cell site-cell flex--container">
                            <div className="flex__item--vcenter word-break">
                              {site.SiteName}
                            </div>
                          </div>
                          {renderInspectionAllocationMainBody(site)}

                          {/* </div> */}
                        </div>
                      );
                    }
                  )}
                </div>

                {/* End site Inspection allocation */}
              </Grid>
              <Grid item xs={2}>
                {/* Serarch Start */}
                <div className="flex--container">
                  <div className="flex__item search-bar--small unset-width">
                    <Paper
                      component="form"
                      sx={{
                        p: "1px 2px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                      onMouseEnter={handleMouseEnterInspectionSetSearch}
                      onMouseLeave={handleMouseLeaveInspectionSetSearch}
                    >
                      <Tooltip title="Search by Inspection Set">
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search by Inspection Set"
                          inputProps={{ "aria-label": "Standard" }}
                          value={
                            inspectionAllocationPageState
                              .InspectionSetSearchParameter
                              .InspectionSetSearchParams.Name
                          }
                          onChange={(event) => {
                            event.stopPropagation();
                            onSearchByInspectionSetChange(event.target?.value);
                          }}
                          onKeyPress={onSearchByInspectionSetKeyPress}
                          //type="search"
                        />
                      </Tooltip>
                      {isMouseOverInspectionSet &&
                        inspectionAllocationPageState
                          .InspectionSetSearchParameter
                          ?.InspectionSetSearchParams?.Name &&
                        inspectionAllocationPageState
                          .InspectionSetSearchParameter
                          ?.InspectionSetSearchParams?.Name?.length > 0 && (
                          <Tooltip title="Clear" placement="left-end">
                            <IconButton
                              className="mr-0"
                              onClick={() => onSearchByInspectionSetChange("")}
                            >
                              <CloseIcon
                                style={{ color: "#219ebc", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Paper>
                  </div>
                  <div className="flex__item--inherit">
                    <IconButton onClick={handleInspectionSetClick}>
                      <Sort color="#FF8A65" />
                    </IconButton>
                  </div>
                </div>

                {/* inspection set start */}
                <Droppable
                  droppableId="InspectionSetList"
                  isDropDisabled={true}
                >
                  {(provided) => (
                    <div
                      className="inspection-tile-wrapper pr-10"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ maxHeight: calendarHeight }}
                    >
                      {inspectionAllocationPageState.InspectionSetList?.map(
                        (inspectionSet: InspectionSetDTO, index) => {
                          return (
                            <Draggable
                              key={inspectionSet.Id}
                              draggableId={`${inspectionSet.Id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="inspection-tile"
                                  key={inspectionSet.Id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="inspection-name">
                                    <span>{inspectionSet.Name}</span>
                                  </div>
                                  <div className="last-modify body3">
                                    Last modified: &nbsp;
                                    <span>
                                      {dayjs(inspectionSet.ModifiedDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    </div>
                  )}
                </Droppable>

                {/* no Inspection msg start */}
                {inspectionAllocationPageState.InspectionSetList?.length ===
                0 ? (
                  <div className="no-data width100-p flex--container">
                    <img className="" src={noData} style={{}}></img>
                    <div className="gray-text body2 flex__item--vcenter nodata-text">
                      No records found
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* no Inspection msg end */}

                {/* Inspection set End*/}
              </Grid>
            </DragDropContext>
          </Grid>
        </div>
      </div>

      {/* sort year start */}
      <div className="sort-dialog dialog-year">
        <Menu
          className="sort-dialog-content"
          open={inspectionYearOpen}
          anchorEl={inspectionYearanchorEl}
          onClose={handleInspectionYearClose}
        >
          <MenuItem
            onClick={() => {
              onInspectionYearSortByAsc(
                InspectionAssignmentPageSortType.ByGaps
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By gaps</div>
              <div className="flex__item fx-align-right fx-right">
                <ArrowDown
                  variant="Outline"
                  className="ico-primary"
                  size={18}
                />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionYearSortByDesc(
                InspectionAssignmentPageSortType.ByGaps
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By gaps</div>
              <div className="flex__item--inherit fx-align-right fx-right">
                <ArrowUp variant="Outline" className="ico-primary" size={18} />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionYearSortByAsc(
                InspectionAssignmentPageSortType.ByInspectionSetName
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By inspection set name</div>
              <div className="flex__item fx-align-right fx-right">
                <ArrowDown
                  variant="Outline"
                  className="ico-primary"
                  size={18}
                />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionYearSortByDesc(
                InspectionAssignmentPageSortType.ByInspectionSetName
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By inspection set name</div>
              <div className="flex__item--inherit fx-align-right fx-right">
                <ArrowUp variant="Outline" className="ico-primary" size={18} />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionYearSortByAsc(
                InspectionAssignmentPageSortType.ByScheduling
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By scheduling</div>
              <div className="flex__item--inherit fx-align-right fx-right">
                <ArrowDown
                  variant="Outline"
                  className="ico-primary"
                  size={18}
                />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionYearSortByDesc(
                InspectionAssignmentPageSortType.ByScheduling
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By scheduling</div>
              <div className="flex__item--inherit fx-align-right fx-right">
                <ArrowUp variant="Outline" className="ico-primary" size={18} />
              </div>
            </div>
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              onInspectionYearSortByAsc(
                InspectionAssignmentPageSortType.ByStatus
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By status</div>
              <div className="flex__item text-right">
                <ArrowDown variant="Outline" className="ico-primary" />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionYearSortByDesc(
                InspectionAssignmentPageSortType.ByStatus
              );
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By status</div>
              <div className="flex__item--inherit text-right">
                <ArrowUp variant="Outline" className="ico-primary" />
              </div>
            </div>
          </MenuItem> */}
        </Menu>
      </div>

      {/* sort site start */}
      <div className="sort-dialog dialog-site">
        <Menu
          className="sort-dialog-content"
          anchorEl={inspectionSetanchorEl}
          open={inspectionSetOpen}
          onClose={handleInspectionSetClose}
        >
          <MenuItem
            onClick={() => {
              onInspectionSetSortBy(false);
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By date</div>
              <div className="flex__item--inherit text-right fx-align-center">
                <ArrowDown
                  variant="Outline"
                  className="ico-primary"
                  size={18}
                />
              </div>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onInspectionSetSortBy(true);
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">By date</div>
              <div className="flex__item--inherit text-right fx-align-center">
                <ArrowUp variant="Outline" className="ico-primary" size={18} />
              </div>
            </div>
          </MenuItem>
        </Menu>
      </div>
      <div className="sort-dialog dialog-site">
        <Menu
          className="sort-dialog-content"
          anchorEl={locationSortAnchorEl}
          open={LocationSortOpen}
          onClose={handleLocationSortClose}
        >
          <MenuItem onClick={onLocationSortByAsc}>
            <div className="flex--container width100-p">
              <div className="flex__item">By location name</div>
              <div className="flex__item--inherit text-right fx-align-center">
                <ArrowDown
                  variant="Outline"
                  className="ico-primary"
                  size={18}
                />
              </div>
            </div>
          </MenuItem>
          <MenuItem onClick={onLocationSortByDesc}>
            <div className="flex--container width100-p">
              <div className="flex__item">By location name</div>
              <div className=" flex__item--inherit text-right fx-align-center">
                <ArrowUp variant="Outline" className="ico-primary" size={18} />
              </div>
            </div>
          </MenuItem>
        </Menu>
      </div>
      {/* more detailed dialog start*/}

      <div className="more-dialog">
        <Menu
          className="sort-dialog-content"
          id="basic-menu"
          anchorEl={inspectionDetailsanchorEl}
          open={inspectionDetailOpen}
          onClose={handleInspectionDetailClose}
        >
          <MenuItem
            onClick={() => {
              onMoreDetailsClick();
            }}
          >
            <div className="flex--container width100-p">
              <div className="flex__item">Detailed view</div>
              <div className="flex__item--inherit text-right">
                <ArrowRight2 variant="Outline" className="ico-primary" />
              </div>
            </div>
          </MenuItem>

          {(inspectionAllocationPageState.Inspection.InspectionStatusId ===
            EnumInspectionStatus.Scheduled ||
            inspectionAllocationPageState.Inspection.InspectionStatusId ===
              EnumInspectionStatus.NotPlanned ||
            inspectionAllocationPageState.Inspection.InspectionStatusId ===
              EnumInspectionStatus.Overdue) &&
            !inspectionAllocationPageState.Inspection
              ?.ShowInspectionStartedBy && (
              <MenuItem
                onClick={() => {
                  onRemoveInspection();
                }}
              >
                <div className="flex--container width100-p">
                  <div className="flex__item">Remove</div>
                  <div className="flex__item--inherit text-right">
                    <Bag
                      variant="Outline"
                      className="ico-primary"
                      size="20"
                      color="#ef5350"
                    />
                  </div>
                </div>
              </MenuItem>
            )}
        </Menu>

        {/* {inspectionAllocationPageState.Inspection.InspectionStatusId ===
          EnumInspectionStatus.Scheduled ||
        inspectionAllocationPageState.Inspection.InspectionStatusId ===
          EnumInspectionStatus.NotPlanned ||
        inspectionAllocationPageState.Inspection.InspectionStatusId ===
          EnumInspectionStatus.Overdue ? (
          <Menu
            className="sort-dialog-content"
            id="basic-menu"
            anchorEl={inspectionDetailsanchorEl}
            open={inspectionDetailOpen}
            onClose={handleInspectionDetailClose}
          >
            <MenuItem
              onClick={() => {
                onMoreDetailsClick();
              }}
            >
              <div className="flex--container width100-p">
                <div className="flex__item">Detailed view</div>
                <div className="flex__item--inherit text-right">
                  <ArrowRight2 variant="Outline" className="ico-primary" />
                </div>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                onRemoveInspection();
              }}
            >
              <div className="flex--container width100-p">
                <div className="flex__item">Remove</div>
                <div className="flex__item--inherit text-right">
                  <Bag
                    variant="Outline"
                    className="ico-primary"
                    size="20"
                    color="#ef5350"
                  />
                </div>
              </div>
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            className="sort-dialog-content"
            id="basic-menu"
            anchorEl={inspectionDetailsanchorEl}
            open={inspectionDetailOpen}
            onClose={handleInspectionDetailClose}
          >
            <MenuItem
              onClick={() => {
                onMoreDetailsClick();
              }}
            >
              <div className="flex--container width100-p">
                <div className="flex__item">Detailed view</div>
                <div className="flex__item--inherit text-right">
                  <ArrowRight2 variant="Outline" className="ico-primary" />
                </div>
              </div>
            </MenuItem>
          </Menu>
        )} */}
      </div>
    </>
  );
};

export default InspectionAllocationPage;
