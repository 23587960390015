import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TablePaginationProps,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import MuiPagination from "@mui/material/Pagination";
import {
  ArrowLeft2,
  CloseCircle,
  DirectboxReceive,
  Edit,
  SearchNormal1,
} from "iconsax-react";

import dayjs from "dayjs";
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridPaginationModel,
  GridSortModel,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { EnumTextBoxType } from "../../../../components/common/HPRTextBox/HPRTextBox.enum";
import { PageRegistrationProperties } from "../../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../../core/hooks/usePageRegistration";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserRoleManagementType } from "../UserRoleManagement.types";
import {
  EnumButtonModeType,
  EnumPageMode,
  EnumPageType,
} from "../../../../common/enums/common.enums";
import { PageModeType } from "../../../../common/types/common.page.type";
import {
  SearchParameterDTO,
  SystemGroupAzureGroupSiteDTO,
  SystemGroupDTO,
} from "../../../../common/types/common.dto.types";
import SimpleReactValidator from "simple-react-validator";
import noData from "../../../../images/no_data.svg";
import {
  EnumScopeUserRole,
  SaveAzureGroupSiteListDTO,
  UserRoleManagementLocationDataRowType,
  UserRoleManagementLocationsPaginationDTO,
  UserRoleManagementLocationsType,
  UserRoleManagementScopeDataRowType,
} from "./UserRoleManagementLocationstypes";
import { useDispatch } from "react-redux";
import { setPageMode } from "./UserRoleManagementLocations.reducer";
import {
  fetchSystemGroupById,
  getAzureGroupSiteList,
  saveAzureGroupSiteList,
  saveUserRoleAdgroup,
} from "./UserManagementLocations.actions";
import { useAppSelector } from "../../../../core/hooks";
import HPRTextBoxUserRole from "../../../../components/common/HPRTextBox/HPRTextBoxUserRole";
import { openPopup } from "../../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../../components/Popups/PopupProps.types";
import { UsePromptTypes } from "../../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../../core/hooks/use-prompt/use-prompt";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../../common/functions/common.functions";

const UserRoleManagementLocations = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const [queryOptions, setQueryOptions] = useState({
    field: "",
    sort: "",
  });

  const systemGroupLocation = useLocation();

  const initialState = {
    IsDisable: false,
    IsLoading: false,
    GroupName: "",
    Id: 0,
    PageMode: {
      PageMode: EnumPageMode.ViewMode,
      IsDisable: true,
      IsShowAddBtn: true,
      IsShowEditBtn: false,
      IsShowDeleteBtn: false,
      IsShowCancelBtn: false,
      IsShowSaveBtn: false,
      IsShowUpdateBtn: false,
      IsShowCloseBtn: false,
    } as PageModeType,
    PagedData: {
      PagedData: [] as any[],
      PageSize: 10,
      SelectedPage: 1,
      TotalRecordsCount: 0,
    } as any,
    UserRoleManagementSearchParameter: {
      CheckCompleted: false,
      IsAdvanceSearch: false,
      SelectedPage: 1,
      ListPageSize: 10,
      ShowDeactivated: false,
      Field: "GroupName",
      Sort: "",
      SearchText: "",
      SystemGroupSearchParams: systemGroupLocation.state,
    } as SearchParameterDTO,
    SystemGroup: systemGroupLocation.state,
    SystemGroupModalOpen: false,
    Validator: new SimpleReactValidator(),
    ButtonModeType: EnumButtonModeType.Save,
    ShowErrorMessages: false,
    ExportPressed: false,
  } as UserRoleManagementType;

  const initialStateLocationPage: UserRoleManagementLocationsType = {
    IsDisable: false,
    IsLoading: false,
    GroupName: "",
    Id: 0,
    PageMode: {
      PageMode: EnumPageMode.ViewMode,
      IsDisable: true,
      IsShowAddBtn: true,
      IsShowEditBtn: false,
      IsShowDeleteBtn: false,
      IsShowCancelBtn: false,
      IsShowSaveBtn: false,
      IsShowUpdateBtn: false,
      IsShowCloseBtn: false,
    } as PageModeType,
    PagedData: {
      Data: [] as SystemGroupAzureGroupSiteDTO[],
      PageSize: 10,
      SelectedPage: 1,
      TotalRecordsCount: 0,
    } as UserRoleManagementLocationsPaginationDTO,
    UserRoleManagementSearchParameter: {
      CheckCompleted: false,
      IsAdvanceSearch: false,
      SelectedPage: 1,
      ListPageSize: 10,
      ShowDeactivated: false,
      Field: "GroupName",
      Sort: "",
      SystemGroupSearchParams: systemGroupLocation.state,
    } as SearchParameterDTO,
    SystemGroup: {
      Id: 0,
      GroupName: "",
      Status: 0,
      GroupFunctions: null,
      GroupScreens: null,
      AzureGroupName: "",
    } as SystemGroupDTO,
    SystemGroupModalOpen: false,
    Validator: new SimpleReactValidator(),
    ButtonModeType: EnumButtonModeType.Save,
    ShowErrorMessages: false,
    ExportPressed: false,
  };
  //-----Height Calc-----
  const userCard = useRef(null);
  const userMngHeader = useRef(null);

  //Set Hight
  const [userGridHeight, setUserGridHeight] = useState(0);

  const heightUser = () => {
    let userGridHeight =
    userCard?.current?.offsetHeight -
    userMngHeader?.current?.offsetHeight -
      43;
    setUserGridHeight(userGridHeight);

    function handleResize() {
      let userGridHeight =
    userCard?.current?.offsetHeight -
    userMngHeader?.current?.offsetHeight -
      43;
    setUserGridHeight(userGridHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  };
  //------------------------------------

  // use for page navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userRoleManagementPageState, setUserRoleManagementPageState] =
    useState<UserRoleManagementType>(initialState);

  const [azureGroupSiteList, setAzureGroupSiteList] = useState<any[]>([]);
  const [scopeAdGroupName, setScopeAdGroupName] = useState("");
  const [changeAzureGroupSiteList, setChangeAzureGroupSiteList] = useState<
    any[]
  >([]);

  const [
    userRoleManagementLocationsPageState,
    setUserRoleManagementLocationsPageState,
  ] = useState<UserRoleManagementLocationsType>(initialStateLocationPage);

  const [iconClick, setIconClick] = useState("");
  const [adGroupName, setAdGroupName] = useState("");
  const [clickCancelClose, setClickCancelClose] = useState(false);

  useEffect(() => {
    heightUser();
    dispatch(setPageMode(EnumPageMode.ViewMode));
  }, [userMngHeader?.current?.offsetHeight, userCard?.current?.offsetHeight]);

  useEffect(() => {
    if (systemGroupLocation.state?.ScopeId != EnumScopeUserRole.Globle) {
      dispatch(
        getAzureGroupSiteList(
          userRoleManagementPageState.UserRoleManagementSearchParameter
        )
      );
    }
  }, [
    userRoleManagementPageState.UserRoleManagementSearchParameter,
    dispatch,
    iconClick,
  ]);

  useEffect(() => {
    if (systemGroupLocation.state?.ScopeId == EnumScopeUserRole.Globle) {
      dispatch(fetchSystemGroupById(systemGroupLocation.state?.Id));
    }
  }, [dispatch]);

  const authState = useAppSelector((state) => state.authReducer);
  const userRoleManagementLocationsState: UserRoleManagementLocationsType =
    useAppSelector((state) => state.userRoleManagementLocationsReducer);

  const onAzureGroupNameChange = (
    name: string,
    value: any,
    siteId: number,
    adGroupId: number
  ) => {
    if (systemGroupLocation.state?.ScopeId !== EnumScopeUserRole.Globle) {
      setAdGroupName(value);

      setAzureGroupSiteList((prevList) => {
        const existingIndex = prevList.findIndex(
          (item) =>
            item.SiteId === siteId &&
            item.SystemGroupId === systemGroupLocation.state?.Id
        );
        setChangeAzureGroupSiteList((oldArray) => [...oldArray, siteId]);

        if (existingIndex !== -1) {
          // If it exists, update the azure name
          return prevList.map((item, index) =>
            index === existingIndex ? { ...item, AzureGroupName: value } : item
          );
        } else {
          // If it doesn't exist, add a new object to the array

          return [
            ...prevList,
            {
              SiteId: siteId,
              SystemGroupId: systemGroupLocation.state?.Id,
              AzureGroupName: value,
              Id: adGroupId,
            },
          ];
        }
      });
    } else {
      setScopeAdGroupName(value);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "SiteName",
      headerName: "Location",
      flex: 2,
      hideable: false,
      cellClassName: "table-cell status",
      disableColumnMenu: true,
    },
    {
      field: "AzureGroupName",
      headerName: "AD Group Name",
      headerClassName: "app--header",
      flex: 3,
      // minWidth: 420,
      sortable: true,
      renderCell: (params) => {
        const data = azureGroupSiteList?.find(
          (x) => x.SiteId === params.row.SiteId
        );
        return (
          <div className="table-txtbox" style={{ width: "400px" }}>
            <HPRTextBoxUserRole
              Id={"Group Name"}
              Label={""}
              // Value={params.row.azureGroupName}
              PlaceHolder={""}
              Name={"Group Name"}
              ClassName={""}
              TextLength={250}
              Value={data?.AzureGroupName}
              InputProps={{ maxLength: 250 }}
              Rules={"required"}
              Required={true}
              onTextBoxChange={onAzureGroupNameChange}
              IsEnableValidator={true}
              Type={EnumTextBoxType.Text}
              SiteId={params.row.SiteId}
              AzureGroupSiteId={params.row.AzureGroupSiteId}
              Disabled={
                userRoleManagementLocationsState.PageMode.PageMode ==
                EnumPageMode.ViewMode
                  ? true
                  : false
              }
            />
          </div>
        );
      },
      disableColumnMenu: true,
      //maxWidth: 200
    },
    {
      field: "ModifiedBy",
      headerName: "Modified By",
      headerClassName: "app--header",
      flex: 2,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      // cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "ModifiedDate",
      headerName: "Modified Date",
      headerClassName: "app--header",
      flex: 2,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      //cellClassName: "table-cell",
      //maxWidth: 200
    },
  ];

  const scopeDataGridColumns: GridColDef[] = [
    {
      field: "RoleName",
      headerName: "Role Name",
      flex: 2,
      hideable: false,
      cellClassName: "table-cell status",
      disableColumnMenu: true,
    },
    {
      field: "AzureGroupName",
      headerName: "AD Group Name",
      headerClassName: "app--header",
      // flex: 3,
      minWidth: 420,
      sortable: true,
      renderCell: (params) => {
        const data = scopeAdGroupName;
        return (
          <div className="table-txtbox" style={{ width: "400px" }}>
            <HPRTextBoxUserRole
              Id={"Group Name"}
              Label={""}
              // Value={params.row.azureGroupName}
              PlaceHolder={""}
              Name={"Group Name"}
              ClassName={""}
              TextLength={250}
              Value={data}
              InputProps={{ maxLength: 250 }}
              Rules={"required"}
              Required={true}
              onTextBoxChange={onAzureGroupNameChange}
              IsEnableValidator={true}
              Type={EnumTextBoxType.Text}
              SiteId={params.row.SiteId}
              AzureGroupSiteId={params.row.AzureGroupSiteId}
              Disabled={
                userRoleManagementLocationsState.PageMode.PageMode ==
                EnumPageMode.ViewMode
                  ? true
                  : false
              }
            />
          </div>
        );
      },
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "ModifiedBy",
      headerName: "Modified By",
      headerClassName: "app--header",
      flex: 2,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "ModifiedDate",
      headerName: "Modified Date",
      headerClassName: "app--header",
      flex: 2,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
  ];

  useEffect(() => {
    if (systemGroupLocation.state?.ScopeId !== EnumScopeUserRole.Globle) {
      setUserRoleManagementLocationsPageState((prevState) => {
        return {
          ...prevState,
          PagedData: userRoleManagementLocationsState.PagedData,
          ButtonModeType: userRoleManagementLocationsState.ButtonModeType,
          SystemGroup: userRoleManagementLocationsState.SystemGroup,
        };
      });
    } else {
      setUserRoleManagementLocationsPageState((prevState) => {
        return {
          ...prevState,
          SystemGroup: userRoleManagementLocationsState.SystemGroup,
        };
      });
    }
  }, [
    userRoleManagementLocationsState.PagedData?.Data,
    userRoleManagementLocationsState.ButtonModeType,
    userRoleManagementLocationsState.SystemGroup,
  ]);

  const [locationTableDataRows, setLocationTableDataRows] = useState<
    UserRoleManagementLocationDataRowType[]
  >([]);

  const [scopeTableDataRows, setScopeTableDataRows] = useState<
    UserRoleManagementScopeDataRowType[]
  >([]);

  //setting data rows
  useEffect(() => {
    if (systemGroupLocation.state?.ScopeId !== EnumScopeUserRole.Globle) {
      const dataRows: UserRoleManagementLocationDataRowType[] = [];

      const resultArray: any = [];

      userRoleManagementLocationsPageState?.PagedData?.Data?.forEach(
        (element) => {
          const data = {
            SiteId: element.SiteId,
            SystemGroupId: systemGroupLocation.state?.Id,
            AzureGroupName: element?.AzureGroupName ?? "",
            Id: element.Id,
          };

          dataRows.push({
            Id: element.SiteId ?? 0,
            AzureGroupSiteId: element.Id,
            SiteName: element.SiteName ?? "",
            SiteId: element.SiteId ?? 0,
            SystemGroupId: 0,
            ModifiedBy: element.ModifiedBy || "",
            ModifiedDate: element.ModifiedDate
              ? dayjs(element.ModifiedDate).format("DD-MMM-YYYY  HH:mm")
              : "",
            AzureGroupName: element?.AzureGroupName ?? "",
          });
          resultArray.push(data);
        }
      );

      setAzureGroupSiteList(resultArray);
      setLocationTableDataRows(dataRows);
    } else {
      const dataRows: UserRoleManagementScopeDataRowType[] = [];

      dataRows.push({
        Id: systemGroupLocation.state?.Id,
        AzureGroupSiteId: 0,
        RoleName:
          userRoleManagementLocationsPageState.SystemGroup.GroupName ?? "",
        SystemGroupId: 0,
        ModifiedBy: systemGroupLocation.state?.ModifiedBy ?? "",
        ModifiedDate: userRoleManagementLocationsPageState.SystemGroup
          .ModifiedDate
          ? dayjs(
              userRoleManagementLocationsPageState.SystemGroup.ModifiedDate
            ).format("DD-MMM-YYYY  HH:mm")
          : "",
        AzureGroupName:
          userRoleManagementLocationsPageState.SystemGroup.AzureGroupName ?? "",
      });

      setScopeAdGroupName(
        userRoleManagementLocationsPageState.SystemGroup.AzureGroupName ?? ""
      );

      setScopeTableDataRows(dataRows);
    }
  }, [
    userRoleManagementLocationsPageState.PagedData?.Data,
    userRoleManagementLocationsPageState.SystemGroup,
  ]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={Math.ceil(
          userRoleManagementLocationsPageState?.PagedData?.TotalRecordsCount /
            userRoleManagementLocationsPageState?.PagedData?.PageSize
        )}
        page={page + 1}
        onChange={(event, newPage) => {
          if (
            userRoleManagementLocationsState.PageMode.PageMode ===
              EnumPageMode.ViewMode ||
            changeAzureGroupSiteList.length <= 0
          ) {
            setAzureGroupSiteList([]);
            setChangeAzureGroupSiteList([]);
            dispatch(setPageMode(EnumPageMode.ViewMode));
            onPageChange(event as any, newPage - 1);
          } else {
            dispatch(
              openPopup({
                Open: true,
                BodyText: "Are you sure you want to discard the changes?",
                HeaderText: "Confirmation",
                PopupType: EnumPopupType.YesNoConfirmation,
                YesBtnClick() {
                  setAzureGroupSiteList([]);
                  setChangeAzureGroupSiteList([]);
                  dispatch(setPageMode(EnumPageMode.ViewMode));
                  onPageChange(event as any, newPage - 1);
                },
              } as PopupProps)
            );
          }
        }}
      />
    );
  }

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      setQueryOptions({
        field: sortModel[0]?.field,
        sort: sortModel[0]?.sort ?? "",
      });
    } else {
      setQueryOptions({
        field: sortModel[0]?.field,
        sort: "",
      });
    }
  }, []);

  //sorting effects
  useEffect(() => {
    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        UserRoleManagementSearchParameter: {
          ...prevState.UserRoleManagementSearchParameter,
          Field: queryOptions.field,
          Sort: queryOptions.sort,
        },
      };
    });
  }, [queryOptions]);

  function CustomPagination(props: any) {
    return (
      <GridPagination
        labelRowsPerPage={"Per page"} //"Per page"
        ActionsComponent={Pagination}
        {...props}
      />
    );
  }

  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [filter, setfilter] = useState(0);

  //pagination effects
  useEffect(() => {
    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        UserRoleManagementSearchParameter: {
          ...prevState.UserRoleManagementSearchParameter,
          SelectedPage: paginationModel.page + 1,
          ListPageSize: paginationModel.pageSize,
        },
      };
    });
  }, [iconClick, paginationModel, filter, queryOptions, advanceSearch]);

  const [searchTerm, setSearchTerm] = useState("");

  //setting search term to the state
  useEffect(() => {
    if (searchTerm === "") {
      setIconClick(searchTerm);
      setUserRoleManagementPageState((prevState) => {
        return {
          ...prevState,
          UserRoleManagementSearchParameter: {
            ...prevState.UserRoleManagementSearchParameter,
            SearchText: searchTerm,
          },
        };
      });
      setUserRoleManagementLocationsPageState((prevState) => {
        return {
          ...prevState,
          UserRoleManagementSearchParameter: {
            ...prevState.UserRoleManagementSearchParameter,
            SearchText: searchTerm,
          },
        };
      });
    }
  }, [searchTerm]);

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.UserRoleManagementLocations,
      functionId,
      functionTypeId
    );
  };

  const saveAzureGroupLocationList = () => {
    if (systemGroupLocation.state?.ScopeId !== EnumScopeUserRole.Globle) {
      // Create an object to store siteIds for each azureGroupName
      const siteIdsByAzureGroupName: { [key: string]: number[] } = {};

      // Iterate through the array
      azureGroupSiteList.forEach((item) => {
        const { AzureGroupName, SiteId } = item;

        // Check if azureGroupName is not null or empty
        if (AzureGroupName != null && AzureGroupName !== "") {
          // Check if azureGroupName is already in the object
          if (siteIdsByAzureGroupName.hasOwnProperty(AzureGroupName)) {
            // If yes, add the siteId to the array
            siteIdsByAzureGroupName[AzureGroupName].push(SiteId);
          } else {
            // If not, create a new array with the current siteId
            siteIdsByAzureGroupName[AzureGroupName] = [SiteId];
          }
        }
      });

      // Find azureGroupNames with more than one siteId
      const azureGroupsWithMultipleSiteIds = Object.keys(
        siteIdsByAzureGroupName
      ).filter(
        (azureGroupName) => siteIdsByAzureGroupName[azureGroupName].length > 1
      );

      // if (azureGroupsWithMultipleSiteIds.length > 0) {
      //   let errorMsg = "";
      //   azureGroupsWithMultipleSiteIds.forEach((azureGroupName) => {
      //     const siteIds = siteIdsByAzureGroupName[azureGroupName];

      //     errorMsg = `Azure Group Name: ${azureGroupName} has duplicated`;
      //   });

      //   dispatch(
      //     openPopup({
      //       Open: true,
      //       BodyText: errorMsg, //"Are you sure you want to discard the changes?"
      //       HeaderText: "Confirmation",
      //       PopupType: EnumPopupType.WarningPopup,
      //     } as PopupProps)
      //   );
      // } else {
      const filteredAzureGroupSiteList = azureGroupSiteList.filter((item) =>
        changeAzureGroupSiteList.includes(item.SiteId)
      );

      const data = {
        AzureGroupSiteList: filteredAzureGroupSiteList,
        GroupId: systemGroupLocation.state.Id,
        SearchParams:
          userRoleManagementPageState.UserRoleManagementSearchParameter,
      } as SaveAzureGroupSiteListDTO;

      dispatch(setPageMode(EnumPageMode.ViewMode));
      setAzureGroupSiteList([]);
      setChangeAzureGroupSiteList([]);
      dispatch(saveAzureGroupSiteList(data));
      //}
    } else {
      const group: SystemGroupDTO = {
        Id: systemGroupLocation.state?.Id,
        ModifiedById: 0,
        ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        GroupName: systemGroupLocation.state?.GroupName,
        StatusId: 0,
        AzureGroupName: scopeAdGroupName,
        IsActive: false,
        ScopeId: systemGroupLocation.state?.ScopeId,
        Scope: null,
        ModifiedByNavigation: null,
        Status: 0,
        CreatedByNavigation: null,
        CreatedById: 0,
        CreatedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        GroupFunctions: null,
        GroupScreens: null,
      } as SystemGroupDTO;

      dispatch(setPageMode(EnumPageMode.ViewMode));
      dispatch(saveUserRoleAdgroup(group));
    }
  };

  const displayMapDiscardConfirmation = (): boolean => {
    if (
      systemGroupLocation.state?.ScopeId != EnumScopeUserRole.Globle &&
      userRoleManagementLocationsState.PageMode.PageMode !=
        EnumPageMode.ViewMode &&
      changeAzureGroupSiteList.length > 0
    ) {
      return true;
    } else if (
      systemGroupLocation.state?.ScopeId == EnumScopeUserRole.Globle &&
      userRoleManagementLocationsState.PageMode.PageMode !=
        EnumPageMode.ViewMode &&
      userRoleManagementLocationsState.SystemGroup.AzureGroupName !=
        scopeAdGroupName
    ) {
      return true;
    } else {
      return false;
    }
  };

  const prompt = {
    Message: "Are you sure you want to discard the changes?",
    When: displayMapDiscardConfirmation(),
    YesBtnClick() {
      setAzureGroupSiteList([]);
      setChangeAzureGroupSiteList([]);
      dispatch(setPageMode(EnumPageMode.ViewMode));
    },
  } as UsePromptTypes;
  usePrompt(prompt);

  return (
    <div className="content-section-card top-main-padding" ref={userCard}>
      <Grid container>
        {/* Button bar */}
        <Grid container>
          {/* <Grid item xl={10} lg={9} md={8} sm={12} xs={12}> */}
          <div className="flex--container width100-p fx-wrap top-main-border pl-10 pr-10" ref= {userMngHeader}>
            <div className="flex__item--inherit pr-10">
              <Button
                aria-label="back"
                className="sub-head-back-btn back"
                title="Back to home page"
                onClick={() => {
                  navigate("/userRoleManagement");
                }}
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </div>
            <div className="global-header-wrap">
              <h1>
                User Role Management Locations
                {systemGroupLocation.state.GroupName
                  ? ": " + systemGroupLocation.state.GroupName
                  : ""}
              </h1>
            </div>
            <div className="flex__item search-bar--small ml-10 mr-10">
              {systemGroupLocation.state?.ScopeId !==
                EnumScopeUserRole.Globle && (
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={"Search by AD Group Name or Location"}
                    inputProps={{ "aria-label": "Locations" }}
                    type="search"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setIconClick(searchTerm);
                        setUserRoleManagementPageState((prevState) => {
                          return {
                            ...prevState,
                            UserRoleManagementSearchParameter: {
                              ...prevState.UserRoleManagementSearchParameter,
                              SearchText: searchTerm,
                            },
                          };
                        });
                        setUserRoleManagementLocationsPageState((prevState) => {
                          return {
                            ...prevState,
                            UserRoleManagementSearchParameter: {
                              ...prevState.UserRoleManagementSearchParameter,
                              SearchText: searchTerm,
                            },
                          };
                        });
                      }
                    }}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <IconButton
                    type="button"
                    className="m-0"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      setIconClick(searchTerm);
                      setUserRoleManagementPageState((prevState) => {
                        return {
                          ...prevState,
                          UserRoleManagementSearchParameter: {
                            ...prevState.UserRoleManagementSearchParameter,
                            SearchText: searchTerm,
                          },
                        };
                      });
                      setUserRoleManagementLocationsPageState((prevState) => {
                        return {
                          ...prevState,
                          UserRoleManagementSearchParameter: {
                            ...prevState.UserRoleManagementSearchParameter,
                            SearchText: searchTerm,
                          },
                        };
                      });
                      //setSearchTerm("");
                    }}
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                </Paper>
              )}
            </div>
            <Button
              className="secondary-btn-small"
              size="small"
              variant="outlined"
              onClick={() => {
                navigate("/userRoleManagement");
                // setClickCancelClose(true);
                // if (
                //   userRoleManagementLocationsState.pageMode.pageMode !=
                //     EnumPageMode.ViewMode &&
                //   changeAzureGroupSiteList.length > 0
                // ) {
                //   dispatch(
                //     openPopup({
                //       Open: true,
                //       BodyText: t("discardConfirmation"), //"Are you sure you want to discard the changes?"
                //       HeaderText: t("confirmation"), //"Confirmation"
                //       PopupType: EnumPopupType.YesNoConfirmation,
                //       YesBtnClick() {
                //         setAzureGroupSiteList([]);
                //         setChangeAzureGroupSiteList([]);
                //         dispatch(setPageMode(EnumPageMode.ViewMode));
                //         navigate("/userRoleManagement");
                //       },
                //       NoBtnClick() {
                //         setClickCancelClose(false);
                //       },
                //     } as PopupProps)
                //   );
                // } else {
                //   setAzureGroupSiteList([]);
                //   setChangeAzureGroupSiteList([]);
                //   dispatch(setPageMode(EnumPageMode.ViewMode));
                //   navigate("/userRoleManagement");
                // }
              }}
              startIcon={<CloseCircle variant="Outline" />}
            >
              Close
            </Button>

            {checkSystemFunctionRight(
              EnumSystemFunctions.UserRoleManagementPage_Edit_User_Group,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
            userRoleManagementLocationsState.PageMode?.PageMode ==
              EnumPageMode.ViewMode ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Edit variant="Outline" />}
                onClick={() => dispatch(setPageMode(EnumPageMode.EditMode))}
              >
                Edit
              </Button>
            ) : userRoleManagementLocationsState.PageMode?.PageMode !==
              EnumPageMode.ViewMode ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<DirectboxReceive variant="Outline" />}
                onClick={() => saveAzureGroupLocationList()}
              >
                Save
              </Button>
            ) : null}
          </div>
        </Grid>
        <Grid container>
          <Grid container className="">
            <Grid item xs={12} className="fx">
              {/* Serarch End*/}
              {/* <Grid item xs={5} lg={3} md={3} xl={3} className="">
                <h1 style={{ display: "inline-block", width: "100%" }}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="fx-right mb-10"
                  >
                    <FormGroup className="">
                      <HPRCheckBox
                        Id={""}
                        Name={"Show Deactivated"}
                        Label={"Show Deactivated"}
                        ClassName={""}
                        checked={false}
                      />
                    </FormGroup>
                  </Grid>
                </h1>
              </Grid> */}
            </Grid>

            <div className="HPRDataGrid-wrapper mt-10 pl-10 pr-10" style={{height: userGridHeight}}>
              {systemGroupLocation.state?.ScopeId ===
              EnumScopeUserRole.Globle ? (
                <DataGrid
                  className="datagrid-f"
                  style={{
                    // height: "auto",
                    width: "99.6%",
                    overflow: "hidden",
                    // maxHeight: "571px",
                    maxHeight: "758px",
                    borderWidth: "0px !important",
                    minHeight: "100%",
                  }}
                  getRowId={(row: any) => row.Id}
                  slots={{
                    noRowsOverlay: () => (
                      <div className="no-data width100-p flex--container">
                        <img className="" src={noData} style={{}}></img>
                        <div className="gray-text body2 flex__item--vcenter nodata-text">
                          No record(s) found
                        </div>
                      </div>
                    ),
                    noResultsOverlay: () => (
                      <div className="no-data width100-p flex--container">
                        <img className="" src={noData} style={{}}></img>
                        <div className="gray-text body2 flex__item--vcenter nodata-text">
                          No record(s) found
                        </div>
                      </div>
                    ),
                  }}
                  hideFooterPagination
                  hideFooter
                  autoHeight={false}
                  getRowHeight={() => "auto"}
                  disableColumnFilter
                  sx={{
                    "& .app--header": {
                      color: "#000000",
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#c6e4ee !important;",
                    },
                    "& .MuiDataGrid-cell:hover ": {
                      backgroundColor: "transparent",
                    },
                  }}
                  // paginationMode="server"
                  disableColumnSelector={true}
                  sortingOrder={["asc", "desc"]}
                  sortingMode="server"
                  rows={scopeTableDataRows}
                  columns={scopeDataGridColumns}
                ></DataGrid>
              ) : (
                <DataGrid
                  className="datagrid-f"
                  style={{
                    // height: "auto",
                    width: "99.6%",
                    overflow: "hidden",
                    // maxHeight: "571px",
                    maxHeight: "758px",
                    borderWidth: "0px !important",
                    minHeight: "100%",
                  }}
                  pagination
                  getRowId={(row: any) => row.Id}
                  slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: () => (
                      <div className="no-data width100-p flex--container">
                        <img className="" src={noData} style={{}}></img>
                        <div className="gray-text body2 flex__item--vcenter nodata-text">
                          No record(s) found
                        </div>
                      </div>
                    ),
                    noResultsOverlay: () => (
                      <div className="no-data width100-p flex--container">
                        <img className="" src={noData} style={{}}></img>
                        <div className="gray-text body2 flex__item--vcenter nodata-text">
                          No record(s) found
                        </div>
                      </div>
                    ),
                  }}
                  autoHeight={false}
                  getRowHeight={() => "auto"}
                  disableColumnFilter
                  sx={{
                    "& .app--header": {
                      color: "#000000",
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#c6e4ee !important;",
                    },
                    "& .MuiDataGrid-cell:hover ": {
                      backgroundColor: "transparent",
                    },
                  }}
                  paginationMode="server"
                  disableColumnSelector={true}
                  sortingOrder={["asc", "desc"]}
                  sortingMode="server"
                  rows={locationTableDataRows}
                  columns={columns}
                  rowCount={
                    userRoleManagementLocationsState?.PagedData
                      ?.TotalRecordsCount
                  }
                  paginationModel={paginationModel}
                  pageSizeOptions={[10, 15, 25, 50, 100]}
                  onPaginationModelChange={setPaginationModel}
                  hideFooterSelectedRowCount={true}
                  onSortModelChange={handleSortModelChange}
                  // initialState={{
                  //   sorting: {
                  //     sortModel: [{ field: "SiteName", sort: "asc" }],
                  //   },
                  // }}
                  // onRowClick={(params, event) => {
                  //   handleConfigPopupOpen();
                  // }}
                ></DataGrid>
              )}

              {/* Data Row End */}

              {/* <HPRDataGrid
                Data={rows}
                Columns={columns}
                TotalRecordsCount={10}
                //onRowClickedCallBack={onSubjectGridRowClickedCallBack}
                AutoHeight={true}
                HPRDataGridToolbarProps={
                  {
                    ShowTotalRecordsCount: true,
                  } as HPRDataGridToolbarProps
                }
                PageSize={10}
                ShowCustomePagination
              ></HPRDataGrid> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default UserRoleManagementLocations;
