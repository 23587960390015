import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { InspectionDTO } from "../../../common/types/common.dto.types";
import {
  GETSITEINSPECTIONSLIST,
  GETSITEINSPECTIONSTATAUS,
} from "./SiteInspectionsPage.action";
import {
  getSiteInspectionsCurrentStatus,
  getSiteInspectionsList,
} from "./SiteInspectionsPage.api";
import { siteInspectionsPageReducerActions } from "./SiteInspectionsPage.reducer";
import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import { select } from "redux-saga/effects";

const getSiteInspectionsListWatcher = function* (): any {
  yield takeEvery(GETSITEINSPECTIONSLIST, function* (action: AnyAction): any {
    yield put(setLoading(true));

    // state
    const state: SiteInspectionsPageInitialState = yield select(
      (s: RootState) => s.siteInspectionsPageReducer
    );

    const searchPara: InspectionDTO = action.payload;
    const result: SiteInspectionsPageInitialState = yield call(() =>
      getSiteInspectionsList(searchPara)
    );

    if (result != null) {
      // set search parameter
      yield put(
        siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter({
          ...state.SiteInspectionSearchParameter,
          InspectionStatusId:
            result?.Data?.Data?.SelectedInspectionStatusId ??
            state.SiteInspectionSearchParameter?.InspectionStatusId,
          AssessmentType: {
            ...state.SiteInspectionSearchParameter.AssessmentType,
            GroupId:
              result?.Data?.Data?.SelectedAssessmentTypeGroupId ??
              state.SiteInspectionSearchParameter?.AssessmentType?.GroupId,
          },
        })
      );

      yield put(
        siteInspectionsPageReducerActions.setSiteInspectionsList(result)
      );
    }

    yield put(setLoading(false));
  });
};

const checkSiteInspectionsStatusWatcher = function* (): any {
  yield takeEvery(GETSITEINSPECTIONSTATAUS, function* (action: AnyAction): any {
    const searchPara: Array<number> = action.payload;

    const result: SiteInspectionsPageInitialState = yield call(() =>
      getSiteInspectionsCurrentStatus(searchPara)
    );

    if (result?.Data?.Data !== null) {
      yield put(
        siteInspectionsPageReducerActions.setStatusUpdatedInspectionList(
          result.Data
        )
      );
    }
  });
};

const siteInspectionsListSaga = function* () {
  yield all([
    getSiteInspectionsListWatcher(),
    checkSiteInspectionsStatusWatcher(),
  ]);
};

export default siteInspectionsListSaga;
