// redux
import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, compose } from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer } from "redux-persist";
import {
  createStateSyncMiddleware,
  initMessageListener,
  initStateWithPrevTab,
} from "redux-state-sync";
import logger from "redux-logger";

// redux saga
import createSagaMiddleware from "redux-saga";

// project imports
import rootReducer from "./root.reducer";
import rootSaga from "./root.saga";
import { authReducerActions } from "../reducers/authReducer";
import { protectedLayoutReducerActions } from "../../views/layouts/protected-layout/ProtectedLayout.reducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

const sagaMiddleware = createSagaMiddleware();

// sync will be only persisted in multiple tabs for the actions specified
const config = {
  whitelist: [
    authReducerActions.setAuth.type,
    authReducerActions.removeAuth.type,
    protectedLayoutReducerActions.setShouldPrompt.type,
  ], // these are actions, not reducers
};

const persistConfig = {
  key: "root",
  whitelist: ["authReducer", "protectedLayoutReducer"], // only navigation will be persisted for this reducer
  storage: storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (
    getDefaultMiddleware: (arg0: { serializableCheck: boolean }) => any[]
  ) =>
    getDefaultMiddleware({ serializableCheck: false })
      // .concat(logger)
      .concat(createStateSyncMiddleware(config))
      .concat(sagaMiddleware),
  // devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
// initStateWithPrevTab(store);
initMessageListener(store);
export default store;
