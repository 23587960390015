// saga $ redux
import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";

// project imports
import {
  AssignInspectionDTO,
  InspectionDTO,
  InspectionStandardDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";

import {
  getInspectionData,
  getInspectionStandardList,
  saveAssignInspection,
} from "./AssignInspectionPage.api";
import {
  setEventSuccess,
  setInitPageData,
  setInspectionStandard,
  setSaveAssignInspection,
} from "./AssignInspectionPage.reducer";
import {
  GETASSIGNINSPECTIONDETAILS,
  GETINSPECTIONSTANDARDLIST,
  SAVEASSIGNINSPECTIONDETAILS,
} from "./AssignInspectionPage.action";

// ==============================|| VIEWS - ASSIGN INSPECTION - SAGA ||============================== //

// watcher for SAVES SITE
const saveAssignInspectionWatcher = function* (): any {
  yield takeEvery(
    SAVEASSIGNINSPECTIONDETAILS,
    function* (action: AnyAction): any {
      yield put(setLoading(true));

      let inspection: AssignInspectionDTO = action.payload;
      // get data from backend
      try {
        let result: ActionResponseType = yield call(() =>
          saveAssignInspection(inspection)
        );
        if (result.IsSuccess) {
          inspection = result.Data;

          yield put(setSaveAssignInspection(inspection));
          yield put(setEventSuccess(false));
          // change page mode (change disable and button show hide)
          //yield put(changePageMode(EnumPageMode.ViewMode));

          // yield put(setReadOnlyMode(true));

          yield put(
            openPopup({
              Open: true,
              BodyText: "Inspection updated successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
          yield put(setEventSuccess(true));
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "Inspection not updated successfully",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred saving site",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading off
      //const InspectionId: number = inspection.Id;

      // const result: AssignInspectionDTO = yield call(() =>
      //   getInspectionData(inspection)
      // );

      // if (result == null && !result) {
      // } else {
      //   // yield put(setSaveAssignInspection(result));
      //   yield put(setInitPageData(result));
      // }
      yield put(setLoading(false));
    }
  );
};

const getInspectionDetailsWatcher = function* (): any {
  yield takeEvery(
    GETASSIGNINSPECTIONDETAILS,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      const inspection: InspectionDTO = action.payload;

      const result: AssignInspectionDTO = yield call(() =>
        getInspectionData(inspection)
      );

      if (result == null && !result) {
      } else {
        // yield put(setSaveAssignInspection(result));
        yield put(setInitPageData(result));
      }
      yield put(setLoading(false));
    }
  );
};

const getInspectionStandardListWatcher = function* (): any {
  yield takeEvery(
    GETINSPECTIONSTANDARDLIST,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      const inspection: InspectionDTO = action.payload;

      const result: Array<InspectionStandardDTO> = yield call(() =>
        getInspectionStandardList(inspection)
      );

      if (result == null && !result) {
      } else {
        // yield put(setSaveAssignInspection(result));
        yield put(setInspectionStandard(result));
      }
      yield put(setLoading(false));
    }
  );
};

const assignInspectionSaga = function* () {
  yield all([
    saveAssignInspectionWatcher(),
    getInspectionDetailsWatcher(),
    getInspectionStandardListWatcher(),
  ]);
};

export default assignInspectionSaga;
