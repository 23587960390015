// react
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";

// material UI
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/Box";
import {
  Grid,
  Button,
  IconButton,
  DialogContent,
  Dialog,
  DialogTitle,
  Divider,
  Paper,
  InputBase,
  DialogActions,
  styled,
  Collapse,
  IconButtonProps,
  Modal,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { GridColDef } from "@mui/x-data-grid";

// icons
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  AddSquare,
  Bag,
  ArrowLeft2,
  ArrowRight2,
  CardSend,
  CloseCircle,
  CardSlash,
  Edit,
  ClipboardText,
  GlobalEdit,
  DirectboxReceive,
  SearchNormal1,
  Filter,
  ArrowUp2,
  More,
  ArrowDown2,
  Add,
} from "iconsax-react";

// redux
import { useAppDispatch, useAppSelector } from "../../../core/hooks";

// project imports
import {
  EnumAttributeType,
  EnumPageMode,
  EnumQuestionTypesType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  DefaultTextDTO,
  DropDownDTO,
  LanguageDTO,
  QuestionChoiceDTO,
  QuestionDTO,
  QuestionParameterDTO,
  QuestionTypeDTO,
  StandardDTO,
  StatusDTO,
  TranslatedTextDTO,
} from "../../../common/types/common.dto.types";
import {
  QuestionPageParametersType,
  QuestionPageType,
} from "./QuestionsPage.types";
import "./QuestionPage.css";
import {
  checkAvailableInspectionSetsBeforeUpdateQuestion,
  checkQuestionBeforeDeactivate,
  getNavigateQuestion,
  getQuestionById,
  getQuestionPageInitialData,
  saveQuestion,
} from "./QuestionPage.actions";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";

// Components
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import HPRRadioButton from "../../../components/common/HPRRadioButton/HPRRadioButton";
import { PageModeType } from "../../../common/types/common.page.type";
import { useLocation, useNavigate } from "react-router-dom";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import {
  changePageMode,
  resetQuestion,
  setAllQuestionCount,
  setViewNavigationArrows,
} from "./QuestionPage.reducer";
import { RadioGroup } from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import { useValidator } from "../../../common/functions/common.functions";
import { StandardQuestionsPageParametersType } from "../StandardQuestionsPage/StandardsQuestionsPage.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import React from "react";
import HPRFilterNode from "../../../components/common/HPRFilterNode/HPRFilterNode";
import noData from "../../../images/no_data.svg";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import HPRTextBoxAutoResize from "../../../components/common/HPRTextBox/HPRTextBoxAutoResize";
// ==============================|| VIEWS - PAGES - QUESTIONS     ||============================== //
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // marginLeft: "auto",
  // transition: theme.transitions.create("transform", {
  //   duration: theme.transitions.duration.shortest,
  // }),
}));
export const RecipeReviewCard = () => {
  const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
};
const QuestionPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  // initial state
  const initialState = {
    EffectivePeriodFrom: false,
    EffectivePeriodTo: false,
    PageMode: {
      PageMode: EnumPageMode.Default,
      IsShowSaveBtn: false,
      IsDisable: true,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
    } as PageModeType,

    LanguageId: 1,
    TranslatedText: "",
    RequirementText: "",
    Question: {
      Id: 0,
      AdditionalComments: true,
      NotApplicable: true,
      ProductImpact: false,
      QuestionTypeId: EnumQuestionTypesType.YesNo,
      ValidFrom: "",
      ValidTo: "",
      QuestionChoices: [] as Array<QuestionChoiceDTO>,
      Version: 0.1,
      Status: EnumStatus.Draft,
      StatusNavigation: { Status1: "Draft" } as StatusDTO,
      ImageUpload: EnumAttributeType.Allow,
      DocumentUpload: EnumAttributeType.Allow,
      RequirementText: {
        DefaultText1: "",
        TranslatedTexts: [] as Array<TranslatedTextDTO>,
      } as DefaultTextDTO,
      QuestionType: {
        Code: "",
        Id: 0,
        ModifiedBy: "",
        Name: "",
        Status: EnumStatus.Published,
        Version: 0,
      } as QuestionTypeDTO,
      StandardId: 0,
      Standard: { Name: "", Id: 0 } as StandardDTO,
      QuestionParameters: [] as Array<QuestionParameterDTO>,
      ReferanceList: [] as Array<DropDownDTO>,
      SystemList: [] as Array<DropDownDTO>,
      SubSystemList: [] as Array<DropDownDTO>,
      SubjectList: [] as Array<DropDownDTO>,
      SectionList: [] as Array<DropDownDTO>,
      QuestionTypeList: [] as Array<DropDownDTO>,
      LanguageList: [] as Array<LanguageDTO>,
    } as QuestionDTO,
    HeaderText: "",
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    ShowErrorMsg: false,
    ReferenceId: 0,
    SystemId: 0,
    SubSystemId: 0,
    SubjectId: 0,
    SectionId: 0,
    ReferanceList: [] as Array<DropDownDTO>,
    SystemList: [] as Array<DropDownDTO>,
    SubSystemList: [] as Array<DropDownDTO>,
    SubjectList: [] as Array<DropDownDTO>,
    SectionList: [] as Array<DropDownDTO>,
    ViewNavigationArrows: false,
    AllQuestionCount: 0,
  } as QuestionPageType;

  // grid data columns settings
  const columns: GridColDef[] = [
    { field: "Id", headerName: "Id", hideable: false, flex: 0.5 },
    { field: "Reference", headerName: "Reference", type: "string", width: 150 },
    { field: "System", headerName: "System", type: "string", width: 150 },
    {
      field: "SubSystem",
      headerName: "Sub System",
      type: "string",
      width: 150,
    },
    { field: "Subject", headerName: "Subject", type: "string", width: 110 },
    { field: "Section", headerName: "Section", type: "string", width: 110 },
  ];

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // page validaotr
  const [validator, showValidationMessage] = useValidator();
  // create state for QuestionPageType with initialState values
  const [questionPageState, setQuestionPageState] = useState(initialState);

  // use for page navigation
  const navigate = useNavigate();

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: QuestionPageParametersType = location.state;

  // reducer manage fucntions
  const questionState = useAppSelector((state) => state.questionReducer);
  const dispatch = useAppDispatch();

  // clear state on back button click
  window.addEventListener("popstate", (ev) => {
    dispatch(resetQuestion());
  });

  // check when one time call to get question data from backend
  const dataFetchedRef = useRef(false);

  // check when one time call to get language data from backend
  const languageDataFetchedRef = useRef(false);

  // load page with parameter and with out
  useEffect(() => {
    // dispatch(setStandardId(parameter.StandardId));
    dispatch(setAllQuestionCount(parameter.CurrentQuestionCount));

    if (parameter.CurrentQuestionCount > 1) {
      dispatch(setViewNavigationArrows(true));
    } else {
      dispatch(setViewNavigationArrows(false));
    }
    if (languageDataFetchedRef.current) return;
    languageDataFetchedRef.current = true;
    dispatch(
      getQuestionPageInitialData({
        StandardId: parameter.StandardId,
        Id: parameter.QuestionId ? parameter.QuestionId : 0,
      } as QuestionDTO)
    );
  }, []);

  useEffect(() => {
    if (questionPageState?.QuestionTypeList?.length > 0) {
      if (questionState?.Question?.Id) {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        dispatch(changePageMode(EnumPageMode.ViewMode));
        dispatch(
          getQuestionById(
            parameter.QuestionId
              ? parameter.QuestionId
              : questionState?.Question?.Id
          )
        );
      } else {
        if (
          questionState?.IsInitialDataLoaded &&
          questionState?.Question?.Id === 0
        ) {
          setQuestionPageState((values) => {
            return {
              ...values,
              Question: {
                ...initialState.Question,
                Standard: questionPageState.Question.Standard,
              },
            };
          });
        }
      }
      if (questionPageState.PageMode.PageMode === EnumPageMode.AddMode) {
        questionTypeDropDownChange(
          questionPageState.QuestionTypeList,
          EnumQuestionTypesType.YesNo.toString(),
          "QuestionTypeId"
        );
        questionTypeSelectionChange();
      }
    }
  }, [questionPageState.QuestionTypeList]);

  // every time when reducer state change set values to page state
  useEffect(() => {
    setQuestionPageState((values) => {
      return {
        ...values,
        LanguageId: questionState.LanguageId,
        Question: questionState.Question,
        PageMode: questionState.PageMode,
        LanguageList: questionState.LanguageList,
        IsLoading: questionState.IsLoading,
        QuestionTypeList: questionState.QuestionTypeList,
        ReferenceId: questionState.ReferenceId,
        SystemId: questionState.SystemId,
        SubSystemId: questionState.SubSystemId,
        SubjectId: questionState.SubjectId,
        SectionId: questionState.SectionId,
        SystemList: questionState.SystemList,
        SectionList: questionState.SectionList,
        SubjectList: questionState.SubjectList,
        ReferanceList: questionState.ReferanceList,
        SubSystemList: questionState.SubSystemList,
        IsInitialDataLoaded: questionState.IsInitialDataLoaded,
      };
    });
    /*changeQuestionPageStateQuestion("TranslatedText",questionState.Question.RequirementText.DefaultText1)*/
    // if (questionState.LanguageId > 0) {
    //   onChangeLanguageSwitch(1);
    // }
    // if (questionPageState.PageMode.PageMode == EnumPageMode.AddMode) {
    //   questionTypeDropDownChange(
    //     questionPageState.QuestionTypeList,
    //     EnumQuestionTypesType.YesNo.toString(),
    //     "QuestionTypeId"
    //   );
    //   questionTypeSelectionChange();
    // }

    showSources();
  }, [questionState]);
  useEffect(() => {
    onChangeLanguageSwitch(1);
    questionTypeSelectionChange();
  }, [
    questionState.LanguageId > 0,
    questionPageState.Question.RequirementText.TranslatedTexts.length > 0,
    questionPageState.Question.QuestionTypeId > 0,
  ]);

  useEffect(() => {
    StandardQuestionPageValidation();
  }, [questionPageState]);

  // every time when reducer state change set values to page state
  /*
  useEffect(() => {
    // console.log(questionPageState.Question.QuestionParameters);
  }, [questionState.Question.QuestionParameters.length]);
*/
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    YesBtnClick() {
      dispatch(resetQuestion());
    },
  } as UsePromptTypes;

  usePrompt(prompt);

  const IsPageValid = (): boolean => {
    let isValid = questionPageState.Validator.allValid();
    if (!isValid) {
      setQuestionPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !questionPageState.ShowErrorMsg,
        };
      });

      //show validation message
      questionPageState.Validator.showMessages();

      return isValid;
    } else {
      //hide validation message
      questionPageState.Validator.hideMessages();
    }

    return isValid;
  };

  const changeQuestionPageStateQuestion = (name: string, value: any) => {
    if (name === "LanguageId") {
      setQuestionPageState((values) => {
        return {
          ...values,
          LanguageId: value,
        };
      });
    } else if (name === "TranslatedText") {
      setQuestionPageState((values) => {
        return {
          ...values,
          TranslatedText: value,
        };
      });
    } else if (name === "EffectivePeriodFrom" || name === "EffectivePeriodTo") {
      setQuestionPageState((values) => {
        return {
          ...values,
          EffectivePeriodFrom:
            name === "EffectivePeriodFrom"
              ? value
              : questionPageState.EffectivePeriodFrom,
          EffectivePeriodTo:
            name === "EffectivePeriodTo"
              ? value
              : questionPageState.EffectivePeriodTo,
          Question: {
            ...values.Question,
            ValidFrom:
              name === "EffectivePeriodFrom" &&
              questionPageState.EffectivePeriodFrom
                ? ""
                : questionPageState.Question.ValidFrom,
            ValidTo:
              name === "EffectivePeriodTo" &&
              questionPageState.EffectivePeriodTo
                ? ""
                : questionPageState.Question.ValidTo,
          },
        };
      });
    } else {
      setQuestionPageState((values) => {
        return {
          ...values,
          Question: { ...values.Question, [name]: value },
        };
      });
    }
  };

  const requirementTranslationManage = (name: string, value: string) => {
    const languageId = questionPageState.LanguageId;
    // Check if the selected language has already been translated into the array.
    const translation: TranslatedTextDTO =
      questionPageState.Question.RequirementText.TranslatedTexts.find(
        (transtaion) => {
          return transtaion.LanguageId === languageId;
        }
      );

    // If the translation text field is blank.
    if (
      !questionPageState.TranslatedText &&
      questionPageState.TranslatedText === ""
    ) {
      // Nothing to do here for now.....

      if (questionPageState.LanguageId > 0) {
        setQuestionPageState((values) => {
          return {
            ...values,
            LanguageId: languageId,
            Question: {
              ...values.Question,
              RequirementText: {
                ...values.Question.RequirementText,
                TranslatedTexts:
                  questionPageState.Question.RequirementText.TranslatedTexts.filter(
                    (translation) =>
                      translation.LanguageId !== questionPageState.LanguageId
                  ),
              },
            },
          };
        });
      }
    } else {
      // Get the index of the existing translation array.
      const translationIndex: number =
        questionPageState.Question.RequirementText.TranslatedTexts.findIndex(
          (transtaion) => {
            return transtaion.LanguageId === questionPageState.LanguageId;
          }
        );

      // If index found in the array.
      if (translationIndex > -1) {
        let updatedTranslations: Array<TranslatedTextDTO> =
          questionPageState.Question.RequirementText.TranslatedTexts.map(
            (translation, index) => {
              // Check if the exists index match to array index
              // Replace with new values
              if (index === translationIndex) {
                return {
                  Id: translation.Id,
                  TranslatedText1: questionPageState.TranslatedText,
                  LanguageId: questionPageState.LanguageId,
                } as TranslatedTextDTO;
              }
              // Check if the exists index not match to array index
              // return old values
              else {
                return translation;
              }
            }
          );
        setQuestionPageState((values) => {
          return {
            ...values,
            LanguageId: languageId,
            Question: {
              ...values.Question,
              RequirementText: {
                ...values.Question.RequirementText,
                TranslatedTexts: updatedTranslations,
              },
            },
          };
        });
      } else {
        setQuestionPageState((values) => {
          return {
            ...values,
            LanguageId: languageId,
            TranslatedText: "",
            Question: {
              ...values.Question,
              RequirementText: {
                ...values.Question.RequirementText,
                TranslatedTexts: [
                  ...values.Question.RequirementText.TranslatedTexts,
                  {
                    TranslatedText1: questionPageState.TranslatedText,
                    LanguageId: questionPageState.LanguageId,
                  } as TranslatedTextDTO,
                ],
              },
            },
          };
        });
      }
    }
  };

  const questionTypeDropDownChange = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string,
    name?: string
  ) => {
    if (name === "QuestionTypeId") {
      switch (parseInt(selectedValue)) {
        case EnumQuestionTypesType.FreeText: {
          setQuestionPageState((values) => {
            return {
              ...values,
              Question: {
                ...values.Question,
                QuestionTypeId: EnumQuestionTypesType.FreeText,
                QuestionChoices: [] as Array<QuestionChoiceDTO>,
              },
            };
          });
          break;
        }
        case EnumQuestionTypesType.YesNo: {
          setQuestionPageState((values) => {
            return {
              ...values,
              Question: {
                ...values.Question,
                QuestionTypeId: EnumQuestionTypesType.YesNo,
                QuestionChoices: [
                  {
                    ChoiceText: { DefaultText1: "Yes" } as DefaultTextDTO,
                    IsGap: false,
                  } as QuestionChoiceDTO,
                  {
                    ChoiceText: { DefaultText1: "No" } as DefaultTextDTO,
                    IsGap: true,
                  } as QuestionChoiceDTO,
                ],
              },
            };
          });
          break;
        }
        case EnumQuestionTypesType.MultipleAnswersSingleChoice: {
          setQuestionPageState((values) => {
            return {
              ...values,
              Question: {
                ...values.Question,
                QuestionTypeId:
                  EnumQuestionTypesType.MultipleAnswersSingleChoice,
                QuestionChoices: [
                  {
                    ChoiceText: { DefaultText1: "" } as DefaultTextDTO,
                    IsGap: false,
                  } as QuestionChoiceDTO,
                ],
              },
            };
          });
          break;
        }
        case EnumQuestionTypesType.MultiplAnswersMultipleChoice: {
          setQuestionPageState((values) => {
            return {
              ...values,
              Question: {
                ...values.Question,
                QuestionTypeId:
                  EnumQuestionTypesType.MultiplAnswersMultipleChoice,
                QuestionChoices: [
                  {
                    ChoiceText: { DefaultText1: "" } as DefaultTextDTO,
                    IsGap: false,
                  } as QuestionChoiceDTO,
                ],
              },
            };
          });
          break;
        }
        default:
          {
            setQuestionPageState((values) => {
              return {
                ...values,
                Question: {
                  ...values.Question,
                  QuestionTypeId: EnumQuestionTypesType.FreeText,
                  QuestionChoices: [] as Array<QuestionChoiceDTO>,
                },
              };
            });
          }
          break;
      }

      // question types save to state
      if (selectOption.length > 0) {
        setQuestionPageState((values) => {
          return {
            ...values,
            QuestionTypeList: selectOption,
          };
        });
      }
      // changeQuestionPageStateQuestion(name, selectedValue);
    }
  };

  const addMultipleAnswers = () => {
    setQuestionPageState((values) => {
      return {
        ...values,
        Question: {
          ...values.Question,
          QuestionChoices: [
            ...values.Question.QuestionChoices,
            {
              ChoiceText: { DefaultText1: "" } as DefaultTextDTO,
              IsGap: false,
            } as QuestionChoiceDTO,
          ],
        },
      };
    });
  };

  const removeMultipleAnswers = (index: number) => {
    const filteredQuestionChoices: Array<QuestionChoiceDTO> =
      questionPageState.Question.QuestionChoices.filter(
        (questionChoices: QuestionChoiceDTO, qIndex: number) => {
          return qIndex !== index;
        }
      );

    if (filteredQuestionChoices.length > 0) {
      setQuestionPageState((values) => {
        return {
          ...values,
          Question: {
            ...values.Question,
            QuestionChoices: filteredQuestionChoices,
          },
        };
      });
    }
  };

  const questionTypeSelectionChange = () => {
    // modal popup
    //const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleOpenError = () => setOpen(true);
    const handleOpenInfo = () => setOpen(true);
    const handleOpenSuccess = () => setOpen(true);
    const handleClose = () => setOpen(false);
    try {
      return questionPageState.Question.QuestionChoices.map(
        (questionChoice: QuestionChoiceDTO, index: number) => {
          return (
            <div className="form-group" key={index}>
              <Grid container columns={12} spacing={2}>
                <Grid item lg={7} className="inline-2-elements">
                  <div className="">
                    {questionPageState.Question.QuestionTypeId ===
                    EnumQuestionTypesType.YesNo ? (
                      <HPRRadioButton
                        Id={""}
                        Name={""}
                        ClassName={""}
                        Value={""}
                        Disabled
                      ></HPRRadioButton>
                    ) : (
                      <HPRCheckBox
                        Id={""}
                        Name={""}
                        ClassName={"inline-single-element"}
                        checked={true}
                        Disabled
                      ></HPRCheckBox>
                    )}
                  </div>

                  <div className="width100-p">
                    <HPRTextBox
                      Id=""
                      Label=""
                      Name={`choiceText-${index}`}
                      Value={questionChoice.ChoiceText.DefaultText1}
                      Type={EnumTextBoxType.Text}
                      onTextBoxChange={ChangeMultipleChoiceText}
                      Disabled={
                        questionPageState.Question.QuestionTypeId ===
                          EnumQuestionTypesType.YesNo ||
                        questionPageState.PageMode.IsDisable
                      }
                    ></HPRTextBox>
                  </div>
                </Grid>
                <Grid item lg={5} className="inline-2-elements">
                  {questionPageState.Question.QuestionTypeId !==
                    EnumQuestionTypesType.YesNo && (
                    <div className="inline-element">
                      <IconButton
                        className="Icon-button-special"
                        aria-label="Follow-up Question"
                        onClick={addMultipleAnswers}
                      >
                        <AddSquare variant="Outline" />
                      </IconButton>
                    </div>
                  )}
                  {questionPageState.Question.QuestionTypeId !==
                    EnumQuestionTypesType.YesNo && (
                    <div className="inline-element">
                      <IconButton
                        className="Icon-button-special"
                        aria-label="Follow-up Question"
                        onClick={() => removeMultipleAnswers(index)}
                      >
                        <Bag className="Icon-button-delete" variant="Outline" />
                      </IconButton>
                    </div>
                  )}

                  <div className="inline-element">
                    <FormControlLabel className="default-label">
                      <HPRCheckBox
                        Id={""}
                        Name={`choiceGap-${index}`}
                        checked={questionChoice.IsGap}
                        ClassName={""}
                        onCheckBoxChange={ChangeMultipleChoiceText}
                        Disabled={questionPageState.PageMode.IsDisable}
                      ></HPRCheckBox>
                      Gap
                    </FormControlLabel>
                  </div>
                  {/* Next version */}
                  {/* <div className="inline-element">
                    <IconButton
                      className="Icon-button-special"
                      aria-label="Follow-up Question"
                      onClick={handleClickOpen}
                      disabled={questionPageState.PageMode.IsDisable}
                    >
                      <CardEdit variant="Outline" />
                      <span>Follow-up Question</span>
                    </IconButton>
                  </div> */}
                </Grid>
              </Grid>
            </div>
          );
        }
      );
    } catch (error) {}
  };

  const ChangeMultipleChoiceText = (name: string, value: any) => {
    let choiceType: string = name.split("-")[0];
    let qIndex: number = parseInt(name.split("-")[1]);

    // If selected type choiceGap
    // if (
    //   questionPageState.Question.QuestionTypeId ==
    //     EnumQuestionTypesType.YesNo &&
    //   choiceType == "choiceGap"
    // ) {
    //   let obj = { ...questionPageState.Question };

    //   // obj.QuestionChoices.map((x: QuestionChoiceDTO) => {
    //   //   return { ...x, IsGap: false };
    //   // });
    //   // setQuestionPageState((values) => {
    //   //   return {
    //   //     ...values,
    //   //     Question: obj,
    //   //   };
    //   // });
    // }

    let editedList: Array<QuestionChoiceDTO> =
      questionPageState.Question.QuestionChoices.map(
        (questionChoice: QuestionChoiceDTO, index: number) => {
          let updateQuestionChoice: QuestionChoiceDTO = Object.assign(
            {} as QuestionChoiceDTO,
            questionChoice
          );

          Object.freeze(updateQuestionChoice.ChoiceText);

          if (index === qIndex) {
            if (choiceType === "choiceGap") {
              updateQuestionChoice.IsGap = value;
            } else if (choiceType === "choiceText") {
              let choiceText: DefaultTextDTO = Object.assign(
                {} as DefaultTextDTO,
                updateQuestionChoice.ChoiceText
              );
              choiceText.DefaultText1 = value;
              updateQuestionChoice.ChoiceText = choiceText;
            }
            return updateQuestionChoice;
          } else {
            if (
              questionPageState.Question.QuestionTypeId ===
                EnumQuestionTypesType.YesNo &&
              choiceType === "choiceGap"
            ) {
              updateQuestionChoice.IsGap = false;
            }
            return updateQuestionChoice;
          }
        }
      );

    // editedList = [
    //   ...editedList.map((x: QuestionChoiceDTO) => {
    //     return { ...x, IsGap: false };
    //   }),
    // ];

    setQuestionPageState((values) => {
      return {
        ...values,
        Question: {
          ...values.Question,
          QuestionChoices: editedList,
        },
      };
    });
  };

  const onChangeLanguageSwitch = (languageId: number) => {
    setQuestionPageState((values) => {
      return {
        ...values,
        LanguageId: languageId,
      };
    });
    // Check if the selected language has already been translated into the array.
    const translation: TranslatedTextDTO =
      questionPageState.Question.RequirementText.TranslatedTexts.find(
        (transtaion) => {
          return transtaion.LanguageId === languageId;
        }
      );

    // If the translation text field is blank.
    if (
      !questionPageState.TranslatedText &&
      questionPageState.TranslatedText === ""
    ) {
      setQuestionPageState((values) => {
        return {
          ...values,
          LanguageId: languageId,
        };
      });
    } else {
      // Get the index of the existing translation array.
      const translationIndex: number =
        questionPageState.Question.RequirementText.TranslatedTexts.findIndex(
          (transtaion) => {
            return transtaion.LanguageId === questionPageState.LanguageId;
          }
        );

      // If index found in the array.
      if (translationIndex > -1) {
        // let updatedTranslations: Array<TranslatedTextDTO> =
        //   questionPageState.Question.RequirementText.TranslatedTexts.map(
        //     (translation, index) => {
        //       // Check if the exists index match to array index
        //       // Replace with new values
        //       if (index == translationIndex) {
        //         return {
        //           TranslatedText1: questionPageState.TranslatedText,
        //           LanguageId: questionPageState.LanguageId,
        //         } as TranslatedTextDTO;
        //       }
        //       // Check if the exists index not match to array index
        //       // return old values
        //       else {
        //         return translation;
        //       }
        //     }
        //   );
        // setQuestionPageState((values) => {
        //   return {
        //     ...values,
        //     LanguageId: languageId,
        //     TranslatedText: "",
        //     Question: {
        //       ...values.Question,
        //       RequirementText: {
        //         ...values.Question.RequirementText,
        //         TranslatedTexts: updatedTranslations,
        //       },
        //     },
        //   };
        // });
      } else {
        setQuestionPageState((values) => {
          return {
            ...values,
            LanguageId: languageId,
            TranslatedText: "",
          };
        });
      }
    }

    // If a translation is available.
    if (translation) {
      // Set the translation text on the text field.
      setQuestionPageState((values) => {
        return {
          ...values,
          LanguageId: languageId,
          TranslatedText: translation.TranslatedText1,
        };
      });
    }
  };

  const onDateChange = (date: string, name: string) => {
    if (name === "ValidFrom") {
      setQuestionPageState((values) => {
        return {
          ...values,
          Question: {
            ...values.Question,
            ValidFrom: date,
          },
        };
      });
    } else {
      setQuestionPageState((values) => {
        return {
          ...values,
          Question: {
            ...values.Question,
            ValidTo: date,
          },
        };
      });
    }
  };
  //-----Button functions-----//

  function areTranslatedTextsSimilar(
    question1: QuestionDTO,
    question2: QuestionDTO
  ): boolean {
    const translatedTexts1 = question1.RequirementText.TranslatedTexts;
    const translatedTexts2 = question2.RequirementText.TranslatedTexts;

    for (let i = 0; i < translatedTexts1.length; i++) {
      const translatedText1 = translatedTexts1[i];
      const translatedText2 = translatedTexts2[i];

      if (translatedText1.TranslatedText1 !== translatedText2.TranslatedText1) {
        return false;
      }
    }

    return true;
  }
  const CancelButtonClick = (): void => {
    const isDirtyTemp = isDirty;
    if (questionPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        questionPageState.TranslatedText === "" &&
        questionPageState.Question.RequirementText.TranslatedTexts.length ===
          0 &&
        questionPageState.Question.NotApplicable &&
        !questionPageState.Question.ProductImpact &&
        questionPageState.Question.AdditionalComments &&
        questionPageState.Question.ImageUpload === EnumAttributeType.Allow &&
        questionPageState.Question.DocumentUpload === EnumAttributeType.Allow &&
        !questionPageState.EffectivePeriodFrom &&
        !questionPageState.EffectivePeriodTo &&
        questionPageState.Question.QuestionParameters.length === 0
      ) {
        dispatch(resetQuestion());
        navigate("/standardsQuestions", {
          state: {
            StandardId: parameter?.StandardId,
            EnumStatus: parameter?.SelectedStatus,
          } as StandardQuestionsPageParametersType,
        });
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              // setQuestionPageState((values) => {
              //   return {
              //     ...values,
              //     Question: questionPageState.Question,
              //   };
              // });
              dispatch(resetQuestion());
              navigate("/standardsQuestions", {
                state: {
                  StandardId: parameter?.StandardId,
                  EnumStatus: parameter?.SelectedStatus,
                } as StandardQuestionsPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      }
    } else {
      if (
        /*JSON.stringify(
          questionPageState.Question.RequirementText.TranslatedTexts
        ) !==
          JSON.stringify(
            questionState.Question.RequirementText.TranslatedTexts
          ) ||*/
        !areTranslatedTextsSimilar(
          questionPageState.Question,
          questionState.Question
        ) ||
        questionPageState.TranslatedText !==
          questionState.Question?.RequirementText?.DefaultText1 ||
        questionPageState.Question.RequirementText.TranslatedTexts.length !==
          questionState.Question.RequirementText.TranslatedTexts.length ||
        questionPageState.Question.QuestionTypeId !==
          questionState.Question.QuestionTypeId ||
        questionPageState.Question.NotApplicable !==
          questionState.Question.NotApplicable ||
        questionPageState.Question.ProductImpact !==
          questionState.Question.ProductImpact ||
        questionPageState.Question.AdditionalComments !==
          questionState.Question.AdditionalComments ||
        questionPageState.Question.ImageUpload !==
          questionState.Question.ImageUpload ||
        questionPageState.Question.DocumentUpload !==
          questionState.Question.DocumentUpload ||
        questionPageState.Question.ValidFrom !==
          questionState.Question.ValidFrom ||
        questionPageState.Question.ValidTo !== questionState.Question.ValidTo ||
        questionPageState.Question.QuestionParameters !==
          questionState.Question.QuestionParameters
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setQuestionPageState((values) => {
                return {
                  ...values,
                  Question: questionState.Question,
                  EffectivePeriodFrom: false,
                  EffectivePeriodTo: false,
                  TranslatedText:
                    questionState.Question.RequirementText.TranslatedTexts.find(
                      (x) => x.LanguageId === questionPageState.LanguageId
                    ).TranslatedText1,
                };
              });
              dispatch(changePageMode(EnumPageMode.ViewMode));
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      } else {
        dispatch(changePageMode(EnumPageMode.ViewMode));
      }
    }
  };

  const CloseButtonClick = (): void => {
    dispatch(resetQuestion());
    navigate("/standardsQuestions", {
      state: {
        StandardId: questionPageState.Question.StandardId,
        EnumStatus: parameter?.SelectedStatus,
      } as StandardQuestionsPageParametersType,
    });
  };

  const DeactivateButtonClick = (): void => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Do you want to deactivate the question?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick: () => {
          let question = Object.assign(
            {} as QuestionDTO,
            questionPageState.Question
          );
          question.Status = EnumStatus.Deactivated;
          //dispatch(deactivateQuestion(question));
          dispatch(checkQuestionBeforeDeactivate(question));
        },
      } as PopupProps)
    );
  };

  const EditButtonClick = (): void => {
    dispatch(changePageMode(EnumPageMode.EditMode));
  };

  const DraftButtonClick = (): void => {
    SaveButtonClick(EnumStatus.Draft);
  };

  const PublishButtonClick = (): void => {
    SaveButtonClick(EnumStatus.Published);
  };

  const SaveButtonClick = (saveStatus?: EnumStatus): void => {
    if (IsPageValid()) {
    }

    if (
      questionPageState.Question.RequirementText.TranslatedTexts.length > 0 &&
      questionPageState.Question.RequirementText.TranslatedTexts.find(
        (item) => {
          return item.LanguageId === 1;
        }
      )
    ) {
      if (
        questionPageState.Question.QuestionTypeId !==
          EnumQuestionTypesType.FreeText &&
        questionPageState.Question.QuestionChoices.filter(
          (choice) => choice.IsGap === true
        ).length === 0
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText:
              "Question Type has muti choice, please select which choice should be marked as Gap.",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        // let question: QuestionDTO = Object.assign(
        //   {},
        //   questionPageState.Question
        // );

        let question: QuestionDTO = {
          ...questionPageState.Question,
          RequirementText: {
            ...questionPageState.Question.RequirementText,
            TranslatedTexts: [
              ...questionPageState.Question.RequirementText.TranslatedTexts,
            ],
          },
        };
        if (saveStatus === EnumStatus.Draft) {
          question.Status = EnumStatus.Draft;
        } else if (saveStatus === EnumStatus.Published) {
          question.Status = EnumStatus.Published;
        }

        if (question.ValidFrom === "") {
          question.ValidFrom = "0001-01-01";
        }
        if (question.ValidTo === "") {
          question.ValidTo = "0001-01-01";
        }
        //question.ModifiedBy = "c.enriquez";

        let englishText = question.RequirementText.TranslatedTexts.find(
          (translation) => {
            return translation.LanguageId === 1;
          }
        );
        question.RequirementText.DefaultText1 = englishText.TranslatedText1;
        question.StandardId = parameter.StandardId;

        if (saveStatus !== EnumStatus.Published && question.Id > 0) {
          dispatch(checkAvailableInspectionSetsBeforeUpdateQuestion(question));
        } else {
          dispatch(saveQuestion(question));
        }
      }
    } else {
      // dispatch(
      //   openPopup({
      //     Open: true,
      //     BodyText: "Please add requirement texts",
      //     HeaderText: "Warning",
      //     PopupType: EnumPopupType.WarningPopup,
      //   } as PopupProps)
      // );
    }
  };

  const BackBtnClick = () => {
    let standardId: number = parameter.StandardId
      ? parameter.StandardId
      : questionPageState?.Question?.Standard?.Id;
    // dispatch(resetQuestion());
    // navigate("/standardsQuestions", {
    //   state: {
    //     StandardId: standardId,
    //     EnumStatus: parameter?.SelectedStatus,
    //   } as StandardQuestionsPageParametersType,
    // });
    const isDirtyTemp = isDirty;

    if (questionPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        questionPageState.TranslatedText === "" &&
        questionPageState.Question.RequirementText.TranslatedTexts.length ===
          0 &&
        questionPageState.Question.NotApplicable &&
        !questionPageState.Question.ProductImpact &&
        questionPageState.Question.AdditionalComments &&
        questionPageState.Question.ImageUpload === EnumAttributeType.Allow &&
        questionPageState.Question.DocumentUpload === EnumAttributeType.Allow &&
        !questionPageState.EffectivePeriodFrom &&
        !questionPageState.EffectivePeriodTo &&
        questionPageState.Question.QuestionParameters.length === 0
      ) {
        dispatch(resetQuestion());
        navigate("/standardsQuestions", {
          state: {
            StandardId: standardId,
            EnumStatus: parameter?.SelectedStatus,
          } as StandardQuestionsPageParametersType,
        });
      } else {
        setIsDirty(false);

        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setQuestionPageState((values) => {
                return {
                  ...values,
                  Question: questionPageState.Question,
                };
              });
              //dispatch(resetQuestion());
              navigate("/standardsQuestions", {
                state: {
                  StandardId: standardId,
                  EnumStatus: parameter?.SelectedStatus,
                } as StandardQuestionsPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      }
    } else {
      if (
        /*JSON.stringify(
          questionPageState.Question.RequirementText.TranslatedTexts
        ) !==
          JSON.stringify(
            questionState.Question.RequirementText.TranslatedTexts
          ) ||*/
        !areTranslatedTextsSimilar(
          questionPageState.Question,
          questionState.Question
        ) ||
        questionPageState.TranslatedText !==
          questionState.Question?.RequirementText?.DefaultText1 ||
        questionPageState.Question.RequirementText.TranslatedTexts.length !==
          questionState.Question.RequirementText.TranslatedTexts.length ||
        questionPageState.Question.QuestionTypeId !==
          questionState.Question.QuestionTypeId ||
        questionPageState.Question.NotApplicable !==
          questionState.Question.NotApplicable ||
        questionPageState.Question.ProductImpact !==
          questionState.Question.ProductImpact ||
        questionPageState.Question.AdditionalComments !==
          questionState.Question.AdditionalComments ||
        questionPageState.Question.ImageUpload !==
          questionState.Question.ImageUpload ||
        questionPageState.Question.DocumentUpload !==
          questionState.Question.DocumentUpload ||
        questionPageState.Question.ValidFrom !==
          questionState.Question.ValidFrom ||
        questionPageState.Question.ValidTo !== questionState.Question.ValidTo ||
        questionPageState.Question.QuestionParameters !==
          questionState.Question.QuestionParameters
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setQuestionPageState((values) => {
                return {
                  ...values,
                  Question: questionState.Question,
                  EffectivePeriodFrom: false,
                  EffectivePeriodTo: false,
                  TranslatedText:
                    questionState.Question.RequirementText.TranslatedTexts.find(
                      (x) => x.LanguageId === questionPageState.LanguageId
                    ).TranslatedText1,
                };
              });
              dispatch(changePageMode(EnumPageMode.ViewMode));
              //dispatch(resetQuestion());
              navigate("/standardsQuestions", {
                state: {
                  StandardId: standardId,
                  EnumStatus: parameter?.SelectedStatus,
                } as StandardQuestionsPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      } else {
        dispatch(resetQuestion());
        navigate("/standardsQuestions", {
          state: {
            StandardId: standardId,
            EnumStatus: parameter?.SelectedStatus,
          } as StandardQuestionsPageParametersType,
        });
      }
    }
  };
  //-----End Button functions-----//
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSourceDropDownChange = (
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string
  ): void => {
    let choiceType: string = name.split("-")[0];
    let qIndex: number = 0;
    if (name.split("-")[1] !== undefined) {
      qIndex = parseInt(name.split("-")[1]);

      let editedList: Array<QuestionParameterDTO> =
        questionPageState.Question.QuestionParameters.map(
          (source: QuestionParameterDTO, index: number) => {
            let editedSource = Object.assign(
              {} as QuestionParameterDTO,
              source
            );
            Object.freeze(source);
            if (index === qIndex) {
              if (choiceType === "ReferenceId") {
                editedSource.StandardReferenceId = parseInt(selectedValue);
              } else if (choiceType === "SystemId") {
                editedSource.StandardSystemId = parseInt(selectedValue);
              } else if (choiceType === "SubSystemId") {
                editedSource.StandardSubSystemId = parseInt(selectedValue);
              } else if (choiceType === "SubjectId") {
                editedSource.StandardSubectId = parseInt(selectedValue);
              } else if (choiceType === "SectionId") {
                editedSource.StandardSectionId = parseInt(selectedValue);
              }
              return editedSource;
            } else {
              return editedSource;
            }
          }
        );

      setQuestionPageState((values) => {
        return {
          ...values,
          Question: {
            ...values.Question,
            QuestionParameters: editedList,
          },
          ReferanceList:
            name === "ReferenceId"
              ? selectOption
              : questionPageState.Question.ReferanceList,
          SystemList:
            name === "SystemId"
              ? selectOption
              : questionPageState.Question.SystemList,

          SubSystemList:
            name === "SubSystemId"
              ? selectOption
              : questionPageState.Question.SubSystemList,
          SubjectList:
            name === "SubjectId"
              ? selectOption
              : questionPageState.Question.SubjectList,
          SectionList:
            name === "SectionId"
              ? selectOption
              : questionPageState.Question.SectionList,
        };
      });
    } else {
      setQuestionPageState((values) => {
        return {
          ...values,
          Question: {
            ...values.Question,
          },
          [choiceType]: parseInt(selectedValue),
          ReferanceList:
            name === "ReferenceId"
              ? selectOption
              : questionPageState.ReferanceList,
          SystemList:
            name === "SystemId" ? selectOption : questionPageState.SystemList,

          SubSystemList:
            name === "SubSystemId"
              ? selectOption
              : questionPageState.SubSystemList,
          SubjectList:
            name === "SubjectId" ? selectOption : questionPageState.SubjectList,
          SectionList:
            name === "SectionId" ? selectOption : questionPageState.SectionList,
        };
      });
    }
  };

  const addSources = (): void => {
    if (
      questionPageState.ReferenceId > 0 ||
      questionPageState.SystemId > 0 ||
      questionPageState.SubSystemId > 0 ||
      questionPageState.SubjectId > 0 ||
      questionPageState.SectionId > 0
    ) {
      let existsSource = questionPageState.Question.QuestionParameters.find(
        (source: QuestionParameterDTO) => {
          return (
            source.StandardReferenceId === questionPageState.ReferenceId &&
            source.StandardSystemId === questionPageState.SystemId &&
            source.StandardSubSystemId === questionPageState.SubSystemId &&
            source.StandardSubectId === questionPageState.SubjectId &&
            source.StandardSectionId === questionPageState.SectionId
          );
        }
      );
      if (existsSource !== undefined && existsSource !== null) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Selected source already added",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        // let referanceName: string = questionPageState.ReferanceList.find(
        //   (x) => x.Value == questionPageState.ReferenceId.toString()
        // ).Label;
        // let systemName: string = questionPageState.SystemList.find(
        //   (x) => x.Value == questionPageState.SystemId.toString()
        // ).Label;
        // let subSystemName: string = questionPageState.SubSystemList.find(
        //   (x) => x.Value == questionPageState.SubSystemId.toString()
        // ).Label;
        // let subjectName: string = questionPageState.SubjectList.find(
        //   (x) => x.Value == questionPageState.SubjectId.toString()
        // ).Label;
        // let sectionName: string = questionPageState.SectionList.find(
        //   (x) => x.Value == questionPageState.SectionId.toString()
        // ).Label;

        let questionParameter: QuestionParameterDTO = {
          Id: questionPageState.Question.QuestionParameters.length + 1,
          // Reference: referanceName,
          StandardReferenceId: questionPageState.ReferenceId,
          // System: systemName,
          StandardSystemId: questionPageState.SystemId,
          // SubSystem: subSystemName,
          StandardSubSystemId: questionPageState.SubSystemId,
          // Subject: subjectName,
          StandardSubectId: questionPageState.SubjectId,
          // Section: sectionName,
          StandardSectionId: questionPageState.SectionId,
        } as QuestionParameterDTO;

        setQuestionPageState((values) => {
          return {
            ...values,
            ReferenceId: 0,
            SystemId: 0,
            SubSystemId: 0,
            SubjectId: 0,
            SectionId: 0,
            Question: {
              ...values.Question,
              QuestionParameters: [
                ...values.Question.QuestionParameters,
                questionParameter,
              ],
            },
          };
        });
      }
    } else {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Please select one of the source to add",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }
  };

  const deleteSources = (index: number) => {
    const filteredQuestionSources: Array<QuestionParameterDTO> =
      questionPageState.Question.QuestionParameters.filter(
        (sources: QuestionParameterDTO, qIndex: number) => {
          return qIndex !== index;
        }
      );

    setQuestionPageState((values) => {
      return {
        ...values,
        Question: {
          ...values.Question,
          QuestionParameters:
            filteredQuestionSources.length !== 0
              ? filteredQuestionSources
              : ([] as Array<QuestionParameterDTO>),
        },
      };
    });
  };

  const StandardQuestionPageValidation = () => {
    if (questionPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        questionPageState.TranslatedText === "" &&
        questionPageState.Question.RequirementText.TranslatedTexts.length ===
          0 &&
        questionPageState.Question.NotApplicable &&
        !questionPageState.Question.ProductImpact &&
        questionPageState.Question.AdditionalComments &&
        questionPageState.Question.ImageUpload === EnumAttributeType.Allow &&
        questionPageState.Question.DocumentUpload === EnumAttributeType.Allow &&
        !questionPageState.EffectivePeriodFrom &&
        !questionPageState.EffectivePeriodTo &&
        questionPageState.Question.QuestionParameters.length === 0
      ) {
        setIsDirty(false);
      } else {
        setIsDirty(true);
      }
    } else {
      if (
        /*JSON.stringify(
          questionPageState.Question.RequirementText.TranslatedTexts
        ) !==
          JSON.stringify(
            questionState.Question.RequirementText.TranslatedTexts
          ) ||*/
        !areTranslatedTextsSimilar(
          questionPageState.Question,
          questionState.Question
        ) ||
        questionPageState.TranslatedText !==
          questionState.Question?.RequirementText?.DefaultText1 ||
        questionPageState.Question.RequirementText.TranslatedTexts.length !==
          questionState.Question.RequirementText.TranslatedTexts.length ||
        questionPageState.Question.QuestionTypeId !==
          questionState.Question.QuestionTypeId ||
        questionPageState.Question.NotApplicable !==
          questionState.Question.NotApplicable ||
        questionPageState.Question.ProductImpact !==
          questionState.Question.ProductImpact ||
        questionPageState.Question.AdditionalComments !==
          questionState.Question.AdditionalComments ||
        questionPageState.Question.ImageUpload !==
          questionState.Question.ImageUpload ||
        questionPageState.Question.DocumentUpload !==
          questionState.Question.DocumentUpload ||
        questionPageState.Question.ValidFrom !==
          questionState.Question.ValidFrom ||
        questionPageState.Question.ValidTo !== questionState.Question.ValidTo ||
        questionPageState.Question.QuestionParameters !==
          questionState.Question.QuestionParameters
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  };

  const showSources = (): ReactNode => {
    if (questionPageState.Question.QuestionParameters?.length > 0) {
      return questionPageState.Question.QuestionParameters.map(
        (source: QuestionParameterDTO, index: number) => {
          return (
            <Grid
              container
              columns={16}
              spacing={2}
              className="form-group"
              key={index}
            >
              <Grid item lg={3} md={3} sm={8} xs={8}>
                <HPRDropDownSingleSelect
                  Id="Reference"
                  Label="Reference"
                  Name={`ReferenceId-${index}`}
                  Select={source.StandardReferenceId}
                  Items={questionPageState.ReferanceList}
                  // Url={encodeURI(
                  //   `StandardReference/GetStandardReferenceListForDropDown/${
                  //     parameter.StandardId > 0
                  //       ? parameter.StandardId
                  //       : questionPageState.Question.StandardId
                  //   }`
                  // )}
                  onDropDownChange={onSourceDropDownChange}
                  Disabled={questionPageState.PageMode.IsDisable}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={8} xs={8}>
                <HPRDropDownSingleSelect
                  Id="System"
                  Label="System"
                  Name={`SystemId-${index}`}
                  Select={source.StandardSystemId}
                  Items={questionPageState.SystemList}
                  // Url={encodeURI(
                  //   `StandardSystem/GetStandardSystemListForDropDown/${
                  //     parameter.StandardId > 0
                  //       ? parameter.StandardId
                  //       : questionPageState.Question.StandardId
                  //   }`
                  // )}
                  onDropDownChange={onSourceDropDownChange}
                  Disabled={questionPageState.PageMode.IsDisable}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={8} xs={8}>
                <HPRDropDownSingleSelect
                  Id="SubSystem"
                  Label="Sub-system"
                  Name={`SubSystemId-${index}`}
                  Select={source.StandardSubSystemId}
                  Items={questionPageState.SubSystemList}
                  // Url={encodeURI(
                  //   `StandardSubSystem/GetStandardSubSystemListForDropDown/${
                  //     parameter.StandardId > 0
                  //       ? parameter.StandardId
                  //       : questionPageState.Question.StandardId
                  //   }`
                  // )}
                  onDropDownChange={onSourceDropDownChange}
                  Disabled={questionPageState.PageMode.IsDisable}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={8} xs={8}>
                <HPRDropDownSingleSelect
                  Id="Subject"
                  Label="Subject"
                  Name={`SubjectId-${index}`}
                  Select={source.StandardSubectId}
                  Items={questionPageState.SubjectList}
                  // Url={encodeURI(
                  //   `StandardSubject/GetStandardSubjectListForDropDown/${
                  //     parameter.StandardId > 0
                  //       ? parameter.StandardId
                  //       : questionPageState.Question.StandardId
                  //   }`
                  // )}
                  onDropDownChange={onSourceDropDownChange}
                  Disabled={questionPageState.PageMode.IsDisable}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={8} xs={8}>
                <HPRDropDownSingleSelect
                  Id="Section"
                  Label="Section"
                  Name={`SectionId-${index}`}
                  Select={source.StandardSectionId}
                  Items={questionPageState.SectionList}
                  // Url={encodeURI(
                  //   `StandardSection/GetStandardSectionListForDropDown/${
                  //     parameter.StandardId > 0
                  //       ? parameter.StandardId
                  //       : questionPageState.Question.StandardId
                  //   }`
                  // )}
                  onDropDownChange={onSourceDropDownChange}
                  Disabled={questionPageState.PageMode.IsDisable}
                />
              </Grid>
              {questionPageState.PageMode.PageMode !==
                EnumPageMode.ViewMode && (
                <Grid item lg={2} md={2} className="valign-middle">
                  <div className="inline-element">
                    <IconButton
                      className="Icon-button-special"
                      aria-label="Follow-up Question"
                      onClick={() => deleteSources(index)}
                    >
                      <Bag className="Icon-button-delete" variant="Outline" />
                    </IconButton>
                  </div>
                </Grid>
              )}
            </Grid>
          );
        }
      );
    } else {
      return (
        <>
          {" "}
          {/* no data start */}
          <div className="no-data width100-p flex--container">
            <img className="" src={noData} style={{}}></img>
            <div className="gray-text body2 flex__item--vcenter nodata-text">
              No sources found
            </div>
          </div>
          {/* no data end */}
        </>
      );
    }
  };

  function onClickNext() {
    dispatch(setLoading(true));
    setQuestionPageState((prevState) => ({
      ...prevState,
      LanguageId: 1,
      TranslatedText: "",
      RequirementText: "",
      Question: {
        ...prevState.Question,
        RequirementText: {
          DefaultText1: "",
          TranslatedTexts: [] as Array<TranslatedTextDTO>,
        } as DefaultTextDTO,
      } as QuestionDTO,
    }));
    dispatch(
      getNavigateQuestion(
        questionState.Question.Id,
        parameter.StandardId,
        false
      )
    );
  }

  function onClickPrev() {
    dispatch(setLoading(true));
    setQuestionPageState((prevState) => ({
      ...prevState,
      LanguageId: 1,
      TranslatedText: "",
      RequirementText: "",
      Question: {
        ...prevState.Question,
        RequirementText: {
          DefaultText1: "",
          TranslatedTexts: [] as Array<TranslatedTextDTO>,
        } as DefaultTextDTO,
      } as QuestionDTO,
    }));
    dispatch(
      getNavigateQuestion(questionState.Question.Id, parameter.StandardId, true)
    );
  }

  return (
    <>
      {/* Global Header with Status & Version */}
      {/* <Grid container columns={12} className="global-header-wrap display-none"> */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12}>
          <h1>Question</h1>
        </Grid> */}

      {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        >
          <div className="global-version-top mb-5">
            <span>Version:</span>
            {questionPageState.Question != null
              ? questionPageState.Question.Version?.toFixed(1)
              : "0.1"}
          </div>
          <div className="standart-status-change mb-5 d-flex">
            <div className="standards-status">
              <span>Status:</span>
              <span
                className={
                  questionPageState.Question.Status === EnumStatus.Published
                    ? "status-color green"
                    : questionPageState.Question.Status === EnumStatus.Draft &&
                      questionPageState.PageMode.PageMode !==
                        EnumPageMode.AddMode
                    ? "status-color yellow"
                    : questionPageState.Question.Status ===
                      EnumStatus.Deactivated
                    ? "status-color gray"
                    : "status-color yellow"
                }
              >
                {questionPageState.Question.StatusNavigation?.Id ===
                EnumStatus.Deactivated
                  ? "Deactivated"
                  : questionPageState.Question.StatusNavigation?.Status1}
              </span>
            </div>
          </div>
        </Grid> */}
      {/* </Grid> */}
      {/* Global Header with Status & Version  End*/}
      <Grid className="text-center" container columns={12}>
        <Grid item lg={12}>
          {/* <img src={templateImage} /> */}
        </Grid>
      </Grid>
      <Box>
        <div className="content-section-card top-main-padding">
          {/* Global Sub Header with  Buttons*/}
          <Grid
            container
            columns={12}
            className="global-subhead-btn-wrap top-main-border-inner"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="global-subhead-wrap"
            >
              {/* <IconButton
                aria-label="back"
                className="sub-head-back-btn"
                onClick={() => {
                  BackBtnClick();
                }}
                title={"Back to questions"}
              >
                <ArrowLeft2 size="24" variant="Outline" />
              </IconButton> */}
              <Button
                aria-label="back"
                className="sub-head-back-btn back"
                onClick={() => {
                  BackBtnClick();
                }}
                title="Back to home page"
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
              {/* <h2>Sub Header</h2> */}
              {/* <h2>
                {" "}
                Standard:{" "}
                {parameter?.StandardName
                  ? parameter.StandardName
                  : questionPageState?.Question?.Standard?.Name}{" "}
              </h2> */}

              <h1>Question</h1>

              <div className="vl"></div>
              <Grid
                item
                // lg={6}
                // md={6}
                // sm={12}
                // xs={12}
                className="vlign-middle status-version-wrap"
              >
                <div className="standart-status-change d-flex">
                  <div className="standards-status">
                    <span>Status:</span>
                    <span
                      className={
                        questionPageState.Question.Status ===
                        EnumStatus.Published
                          ? "status-color green"
                          : questionPageState.Question.Status ===
                              EnumStatus.Draft &&
                            questionPageState.PageMode.PageMode !==
                              EnumPageMode.AddMode
                          ? "status-color yellow"
                          : questionPageState.Question.Status ===
                            EnumStatus.Deactivated
                          ? "status-color gray"
                          : "status-color yellow"
                      }
                    >
                      {questionPageState.Question.StatusNavigation?.Id ===
                      EnumStatus.Deactivated
                        ? "Deactivated"
                        : questionPageState.Question.StatusNavigation?.Status1}
                    </span>
                  </div>
                </div>
                <div className="global-version-top">
                  <span>Version:</span>
                  {questionPageState.Question != null
                    ? questionPageState.Question.Version?.toFixed(1)
                    : "0.1"}
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="sub-header-btn-wrap"
            >
              {questionPageState.PageMode.IsShowCancelBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={CancelButtonClick}
                  className="secondary-btn-small"
                  size="small"
                  startIcon={<CloseCircle variant="Outline" />}
                >
                  Cancel
                </Button>
              )}
              {questionPageState.PageMode.IsShowCloseBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={CloseButtonClick}
                  startIcon={<CloseCircle variant="Outline" />}
                  className="secondary-btn-small"
                  size="small"
                >
                  Close
                </Button>
              )}
              {questionPageState.PageMode.IsShowDeactivateBtn &&
                questionPageState.Question.Status !== EnumStatus.Deactivated &&
                questionPageState.PageMode.PageMode ===
                  EnumPageMode.EditMode && (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={DeactivateButtonClick}
                    startIcon={<CardSlash variant="Outline" />}
                    className="secondary-btn-small"
                    size="small"
                  >
                    Deactivate
                  </Button>
                )}
              {questionPageState.PageMode.IsShowEditBtn &&
                questionPageState.Question.Status !==
                  EnumStatus.Deactivated && (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={EditButtonClick}
                    startIcon={<Edit variant="Outline" />}
                    className="primary-btn-small"
                    size="small"
                  >
                    Edit
                  </Button>
                )}

              {questionPageState.PageMode.IsShowDraftBtn &&
                questionPageState.Question.Status !== EnumStatus.Published && (
                  <Button
                    variant="contained"
                    disableElevation
                    className="primary-btn-small"
                    size="small"
                    startIcon={<ClipboardText variant="Outline" />}
                    onClick={DraftButtonClick}
                  >
                    Draft
                  </Button>
                )}
              {questionPageState.PageMode.IsShowPublishedBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<CardSend variant="Outline" />}
                  onClick={() => PublishButtonClick()}
                >
                  Publish
                </Button>
              )}
              {questionPageState.PageMode.IsShowUpdateBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<GlobalEdit variant="Outline" />}
                  onClick={() => SaveButtonClick()}
                >
                  Update
                </Button>
              )}
              {questionPageState.PageMode.IsShowSaveBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<DirectboxReceive variant="Outline" />}
                  onClick={() => SaveButtonClick(EnumStatus.Published)}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
          {/* Global Sub Header with  Buttons End*/}

          {/* Form Start */}
          {/* Question Number */}
          {questionPageState.PageMode.PageMode !== EnumPageMode.AddMode && (
            <Grid container columns={12}>
              <Grid item lg={1} md={12}></Grid>
              <Grid item lg={10} md={12}>
                <div className="question-number-wrap">
                  {questionState.ViewNavigationArrows ? (
                    <div className="icon">
                      <IconButton
                        disabled={
                          questionState.PageMode.PageMode ===
                          EnumPageMode.EditMode
                        }
                        onClick={onClickPrev}
                        className="icon-btn"
                        aria-label=""
                      >
                        <ArrowLeft2 variant="Outline" />
                      </IconButton>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="question-number">
                    {`Q: ${
                      questionPageState.Question?.SequenceNumber
                        ? questionPageState.Question?.SequenceNumber
                        : ""
                    }`}
                  </div>
                  {questionState.ViewNavigationArrows ? (
                    <div className="icon">
                      <IconButton
                        disabled={
                          questionState.PageMode.PageMode ===
                          EnumPageMode.EditMode
                        }
                        onClick={onClickNext}
                        className="icon-btn"
                        aria-label=""
                      >
                        <ArrowRight2 variant="Outline" />
                      </IconButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>
          )}

          {/* Question Number End */}

          <Grid container columns={12}>
            <Grid item lg={10} md={12} sm={12} xs={12} className="m-auto">
              <Grid item lg={12} md={12}>
                <fieldset>
                  {/* Re enable in phase 2 :D */}
                  {/* <div className="lang-bar-wrapper">
                    {questionPageState.Question.LanguageList ? (
                      questionPageState.Question.LanguageList.map(
                        (language, index) => {
                          return (
                            <div key={index} className="lang-switch-wrap">
                              <div className="lang">{language.Code}</div>
                              <HPRSwitch
                                Name=""
                                ClassName="lang-switch"
                                OnChange={() =>
                                  onChangeLanguageSwitch(language.Id)
                                }
                                Checked={
                                  language.Id == questionPageState.LanguageId
                                }
                              />
                            </div>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </div> */}
                  <legend>
                    Requirement <span className="red-text">*</span>
                  </legend>

                  <div className="form-group">
                    {/* <HPRTextBox
                      Id={"TranslatedText"}
                      Name={"TranslatedText"}
                      Value={questionPageState.TranslatedText}
                      Type={EnumTextBoxType.Text}
                      ClassName={"form-label"}
                      onTextBoxChange={changeQuestionPageStateQuestion}
                      onBlurChange={requirementTranslationManage}
                      Disabled={questionPageState.PageMode.IsDisable}
                      MultiLine
                      Validator={questionPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      AutoFocus={!questionPageState.PageMode.IsDisable}
                      InputProps={{ maxLength: 2000 }}
                      ShowTextCounter
                      TextLength={2000}
                    /> */}
                    <HPRTextBoxAutoResize
                      Id={"TranslatedText"}
                      Name={"TranslatedText"}
                      Value={questionPageState.TranslatedText}
                      Type={EnumTextBoxType.Text}
                      ClassName={"form-label"}
                      onTextBoxChange={changeQuestionPageStateQuestion}
                      onBlurChange={requirementTranslationManage}
                      Disabled={questionPageState.PageMode.IsDisable}
                      MultiLine
                      Validator={questionPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      AutoFocus={!questionPageState.PageMode.IsDisable}
                      InputProps={{ maxLength: 2000 }}
                      ShowTextCounter
                      TextLength={2000}
                    />
                  </div>
                </fieldset>
                {/* Quetion Type */}
                <fieldset>
                  <legend>
                    Question Type <span className="red-text">*</span>
                  </legend>
                  <div className="form-group">
                    <HPRDropDownSingleSelect
                      Select={questionPageState.Question.QuestionTypeId}
                      Label={""}
                      Name={"QuestionTypeId"}
                      // Url={"Question/GetQuestionTypesForDropDown"}
                      onDropDownChange={questionTypeDropDownChange}
                      Items={questionPageState.QuestionTypeList}
                      Validator={questionPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      Disabled={true}
                    />
                  </div>
                  {questionTypeSelectionChange()}
                </fieldset>
                {/* Quetion Type End */}
                {/* Other Options */}
                <fieldset>
                  <legend>Other Options</legend>
                  <div className="form-group-sub">
                    <FormControlLabel className="default-label">
                      <HPRCheckBox
                        Id={""}
                        Name={"NotApplicable"}
                        checked={questionPageState.Question.NotApplicable}
                        ClassName={""}
                        onCheckBoxChange={changeQuestionPageStateQuestion}
                        Disabled={questionPageState.PageMode.IsDisable}
                        Label={"Include Not Applicable"}
                      ></HPRCheckBox>
                    </FormControlLabel>
                  </div>
                  {/* <div className="form-group-sub">
                    <FormControlLabel className="default-label">
                      <HPRCheckBox
                        Id={""}
                        Name={"ProductImpact"}
                        checked={questionPageState.Question.ProductImpact}
                        onCheckBoxChange={changeQuestionPageStateQuestion}
                        ClassName={""}
                        Label={"Include Product Impact"}
                        Disabled={questionPageState.PageMode.IsDisable}
                      ></HPRCheckBox>
                    </FormControlLabel>
                  </div> */}
                  <div className="form-group-sub">
                    <FormControlLabel className="default-label">
                      <HPRCheckBox
                        Id={""}
                        Name={"AdditionalComments"}
                        checked={questionPageState.Question.AdditionalComments}
                        onCheckBoxChange={changeQuestionPageStateQuestion}
                        ClassName={""}
                        Label={"Allow Comments"}
                        Disabled={questionPageState.PageMode.IsDisable}
                      ></HPRCheckBox>
                    </FormControlLabel>
                  </div>
                </fieldset>
                {/* Other Options End */}
                {/* Attributes */}
                <fieldset>
                  <legend>Attributes</legend>
                  <div className="form-group-sub">
                    <Grid container columns={12}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="inline-2-elements"
                      >
                        <FormControlLabel className="default-label">
                          Picture
                        </FormControlLabel>
                      </Grid>

                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <RadioGroup
                          row
                          name="ImageUpload"
                          value={questionPageState.Question.ImageUpload}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            changeQuestionPageStateQuestion(
                              event.target.name,
                              parseInt(event.target.value)
                            )
                          }
                        >
                          <Grid container columns={12} spacing="1">
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className="inline-2-elements"
                            >
                              <HPRRadioButton
                                Name={"ImageUpload"}
                                Value={EnumAttributeType.Allow.toString()}
                                ClassName={"default-label"}
                                Disabled={questionPageState.PageMode.IsDisable}
                                Label={"Allow"}
                              ></HPRRadioButton>
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              className="inline-2-elements"
                            >
                              <HPRRadioButton
                                Name={"ImageUpload"}
                                Value={EnumAttributeType.Mandotory.toString()}
                                ClassName={"default-label"}
                                Disabled={questionPageState.PageMode.IsDisable}
                                Label={"Mandatory"}
                              ></HPRRadioButton>
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              className="inline-2-elements"
                            >
                              <HPRRadioButton
                                Name={"ImageUpload"}
                                Value={EnumAttributeType.NotAllowed.toString()}
                                ClassName={"default-label"}
                                Disabled={questionPageState.PageMode.IsDisable}
                                Label={"Not Allowed"}
                              ></HPRRadioButton>
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group-sub">
                    <Grid container columns={12}>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="inline-2-elements"
                      >
                        <FormControlLabel className="default-label">
                          Document (PDF/Word)
                        </FormControlLabel>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <RadioGroup
                          row
                          name="DocumentUpload"
                          value={questionPageState.Question.DocumentUpload}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            changeQuestionPageStateQuestion(
                              event.target.name,
                              parseInt(event.target.value)
                            )
                          }
                        >
                          <Grid container columns={12} spacing="1">
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className="inline-2-elements"
                            >
                              <HPRRadioButton
                                Name={"DocumentUpload"}
                                Value={EnumAttributeType.Allow.toString()}
                                ClassName={"default-label"}
                                Label={" Allow"}
                                Disabled={questionPageState.PageMode.IsDisable}
                              ></HPRRadioButton>
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              className="inline-2-elements"
                            >
                              <HPRRadioButton
                                Name={"DocumentUpload"}
                                Value={EnumAttributeType.Mandotory.toString()}
                                ClassName={"default-label"}
                                Disabled={questionPageState.PageMode.IsDisable}
                                Label={"Mandatory"}
                              ></HPRRadioButton>
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              className="inline-2-elements"
                            >
                              <HPRRadioButton
                                Name={"DocumentUpload"}
                                Value={EnumAttributeType.NotAllowed.toString()}
                                ClassName={"default-label"}
                                Disabled={questionPageState.PageMode.IsDisable}
                                Label={"Not Allowed"}
                              ></HPRRadioButton>
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </div>
                </fieldset>
                {/* Attributes End */}
                {/* Effective Period */}
                {/* <fieldset>
                  <legend>Effective Period</legend>
                  <div className="form-group">
                    <Grid container columns={12} spacing={2}>
                      <Grid item lg={6} className="inline-2-elements">
                        <div className="inline-2-elements">
                          <div className="inline-element">
                            <HPRCheckBox
                              Name={"EffectivePeriodFrom"}
                              checked={questionPageState.EffectivePeriodFrom}
                              onCheckBoxChange={changeQuestionPageStateQuestion}
                              Disabled={questionPageState.PageMode.IsDisable}
                            ></HPRCheckBox>
                          </div>
                          <div className="inline-element">
                            <HPRDatePicker
                              Label={"From"}
                              Value={questionPageState.Question.ValidFrom}
                              Name={"ValidFrom"}
                              Disabled={!questionPageState.EffectivePeriodFrom}
                              onDateChange={changeQuestionPageStateQuestion}
                              Type={EnumDateType.DateOnly}
                            ></HPRDatePicker>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={6} className="inline-2-elements">
                        <div className="inline-2-elements">
                          <div className="inline-element">
                            <HPRCheckBox
                              Name={"EffectivePeriodTo"}
                              checked={questionPageState.EffectivePeriodTo}
                              onCheckBoxChange={changeQuestionPageStateQuestion}
                              Disabled={questionPageState.PageMode.IsDisable}
                            ></HPRCheckBox>
                          </div>
                          <div className="inline-element">
                            <HPRDatePicker
                              Label={"To"}
                              Value={questionPageState.Question.ValidTo}
                              Name={"ValidTo"}
                              Disabled={!questionPageState.EffectivePeriodTo}
                              onDateChange={changeQuestionPageStateQuestion}
                              Type={EnumDateType.DateOnly}
                            ></HPRDatePicker>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </fieldset> */}
                {/* Effective Period End */}
                {/* Sources */}
                <fieldset>
                  <legend>Sources</legend>
                  {questionPageState.PageMode.PageMode !==
                    EnumPageMode.ViewMode && (
                    <Grid
                      container
                      columns={16}
                      spacing={2}
                      className="form-group"
                    >
                      <Grid item lg={3} md={3} sm={10} xs={8}>
                        <HPRDropDownSingleSelect
                          Id="Reference"
                          Label="Reference"
                          Name={`ReferenceId`}
                          DefaultValue=""
                          Select={questionPageState.ReferenceId}
                          Items={questionPageState.ReferanceList}
                          // Url={encodeURI(
                          //   `StandardReference/GetStandardReferenceListForDropDown/${
                          //     parameter.StandardId > 0
                          //       ? parameter.StandardId
                          //       : questionPageState.Question.StandardId
                          //   }`
                          // )}
                          onDropDownChange={onSourceDropDownChange}
                          Disabled={questionPageState.PageMode.IsDisable}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={8} xs={8}>
                        <HPRDropDownSingleSelect
                          Id="System"
                          Label="System"
                          Name={`SystemId`}
                          DefaultValue=""
                          Select={questionPageState.SystemId}
                          Items={questionPageState.SystemList}
                          // Url={encodeURI(
                          //   `StandardSystem/GetStandardSystemListForDropDown/${
                          //     parameter.StandardId > 0
                          //       ? parameter.StandardId
                          //       : questionPageState.Question.StandardId
                          //   }`
                          // )}
                          onDropDownChange={onSourceDropDownChange}
                          Disabled={questionPageState.PageMode.IsDisable}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={8} xs={8}>
                        <HPRDropDownSingleSelect
                          Id="SubSystem"
                          Label="Sub-system"
                          Name={`SubSystemId`}
                          DefaultValue=""
                          Select={questionPageState.SubSystemId}
                          Items={questionPageState.SubSystemList}
                          // Url={encodeURI(
                          //   `StandardSubSystem/GetStandardSubSystemListForDropDown/${
                          //     parameter.StandardId > 0
                          //       ? parameter.StandardId
                          //       : questionPageState.Question.StandardId
                          //   }`
                          // )}
                          onDropDownChange={onSourceDropDownChange}
                          Disabled={questionPageState.PageMode.IsDisable}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={8} xs={8}>
                        <HPRDropDownSingleSelect
                          Id="Subject"
                          Label="Subject"
                          Name={`SubjectId`}
                          DefaultValue=""
                          Select={questionPageState.SubjectId}
                          Items={questionPageState.SubjectList}
                          // Url={encodeURI(
                          //   `StandardSubject/GetStandardSubjectListForDropDown/${
                          //     parameter.StandardId > 0
                          //       ? parameter.StandardId
                          //       : questionPageState.Question.StandardId
                          //   }`
                          // )}
                          onDropDownChange={onSourceDropDownChange}
                          Disabled={questionPageState.PageMode.IsDisable}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} sm={8} xs={8}>
                        <HPRDropDownSingleSelect
                          Id="Section"
                          Label="Section"
                          Name={`SectionId`}
                          DefaultValue=""
                          Select={questionPageState.SectionId}
                          Items={questionPageState.SectionList}
                          // Url={encodeURI(
                          //   `StandardSection/GetStandardSectionListForDropDown/${
                          //     parameter.StandardId > 0
                          //       ? parameter.StandardId
                          //       : questionPageState.Question.StandardId
                          //   }`
                          // )}
                          onDropDownChange={onSourceDropDownChange}
                          Disabled={questionPageState.PageMode.IsDisable}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={8}
                        xs={8}
                        className="valign-middle"
                      >
                        <div className="inline-element">
                          {/* <IconButton onClick={addSources}>
                            <Bag
                              variant="Outline"
                              className="Icon-button-delete"
                            />
                          </IconButton> */}
                          <Button
                            variant="contained"
                            disableElevation
                            onClick={addSources}
                            startIcon={<Add variant="Outline" />}
                            className="primary-btn-small"
                          >
                            Add
                          </Button>
                        </div>
                      </Grid>
                      <Grid item lg={16} md={16} className="form-group">
                        <Divider />
                      </Grid>
                    </Grid>
                  )}

                  {showSources()}
                  <Grid container columns={12}>
                    <Grid item lg={12}>
                      {/* <Box sx={{ height: 400, width: "100%" }}> */}
                      {/* <DataGrid
                          className="quetion-grid-header"
                          rows={rows}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          checkboxSelection
                          disableSelectionOnClick
                          experimentalFeatures={{ newEditingApi: true }}
                        /> */}
                      {/* <div className="HPRDataGrid-wrapper">
                        <HPRDataGrid
                          Columns={columns}
                          Data={questionPageState.Question.Sources}
                          TotalRecordsCount={1}
                          AutoHeight={true}
                          // onPaginationChangeCallBack={onPaginationCallBack}
                          SelectedPage={1}
                        ></HPRDataGrid>
                      </div> */}
                      {/* </Box> */}

                      {/* <div className="Grid">
                        <div className="quetion-grid-head">
                          <div>Reference </div>
                          <div>System</div>
                          <div>Sub-System</div>
                          <div>Subject</div>
                          <div>Section</div>
                          <div>
                            {" "}
                            <IconButton
                              className="Icon-button-special"
                              aria-label=""
                            >
                              <AddSquare variant="Outline" />
                            </IconButton>
                          </div>
                        </div>

                        <div className="quetion-grid-body">
                          <div>17.202STD</div>
                          <div>Purified Water Systems</div>
                          <div>System Design</div>
                          <div>System Integrity and Backflow Protection</div>
                          <div>3.1</div>
                          <div className="vlign-middle">
                            <IconButton
                              className="Icon-button-special"
                              aria-label=""
                            >
                              <CardEdit variant="Outline" />
                            </IconButton>
                            <IconButton aria-label="">
                              <Bag
                                variant="Outline"
                                className="Icon-button-delete"
                              />
                            </IconButton>
                          </div>
                        </div>

                        <div className="quetion-grid-body">
                          <div>17.202STD</div>
                          <div>Purified Water Systems</div>
                          <div>System Design</div>
                          <div>System Integrity and Backflow Protection</div>
                          <div>3.1</div>
                          <div className="vlign-middle">
                            <IconButton
                              className="Icon-button-special"
                              aria-label=""
                            >
                              <CardEdit variant="Outline" />
                            </IconButton>
                            <IconButton aria-label="">
                              <Bag
                                variant="Outline"
                                className="Icon-button-delete"
                              />
                            </IconButton>
                          </div>
                        </div>

                        <div className="quetion-grid-body">
                          <div>17.202STD</div>
                          <div>Purified Water Systems</div>
                          <div>System Design</div>
                          <div>System Integrity and Backflow Protection</div>
                          <div>3.1</div>
                          <div className="vlign-middle">
                            <IconButton
                              className="Icon-button-special"
                              aria-label=""
                            >
                              <CardEdit variant="Outline" />
                            </IconButton>
                            <IconButton aria-label="">
                              <Bag
                                variant="Outline"
                                className="Icon-button-delete"
                              />
                            </IconButton>
                          </div>
                        </div>
                      </div> */}
                    </Grid>
                  </Grid>
                </fieldset>
                {/* Sources End */}
              </Grid>

              {/* <HPRTextBox Name="" Type={EnumTextBoxType.Text} Value="" />
              <HPRCheckBox Id={""} Name={""} checked={false} ClassName={""} />
              <HPRDatePicker label={""} value={""} name={""} />
              <HPRRadioGroup
                Id={""}
                Name={""}
                Value={""}
                ClassName={""}
                RadioButtonList={[]}
              />
              <HPRTextArea
                Id={""}
                Label={""}
                Name={""}
                Value={undefined}
                Type={EnumTextAreaType.Text}
                ClassName={""}
              /> */}
            </Grid>
          </Grid>

          {/* Form End */}
        </div>
      </Box>
      {/* Followup quesions set dialog start */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="more-dialog"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {"Link follow-up questions"}
        </DialogTitle>
        <DialogContent className="sort-dialog-content">
          {/* Serarch */}

          <Grid container spacing={2} className="mb-15">
            <Grid item xs={2} className="">
              <div className="form-group">
                <HPRDropDownSingleSelect
                  Id=""
                  Label=""
                  DefaultValue="Site 01"
                  Name="site"
                  Select={0}
                  Items={[]}
                />
              </div>
            </Grid>
            {/* <Grid item xs={4} className=""></Grid> */}
            <Grid
              item
              xs={10}
              style={{ height: "fit-content", marginTop: "2px" }}
            >
              <div className="search-bar--small">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search standard"
                    inputProps={{ "aria-label": "Standard" }}
                  />

                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    className="m-0"
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <Filter variant="Outline" className="ico-secondary" />
                  </IconButton>
                </Paper>
              </div>
            </Grid>
          </Grid>

          {/* Serarch End*/}
          {/* chip and filter start */}

          {/* <HPRStatus/> */}
          <br />

          {/* Items Found  */}

          <Grid container spacing={2} className="mb-15 flex--container">
            <Grid item xs={2} className="hpr-form-control">
              <div className="form-group">
                <HPRDropDownSingleSelect
                  Label="Reference"
                  DefaultValue="Site 01"
                  Name="site"
                  Select={questionPageState.ReferenceId}
                  Disabled={questionPageState.PageMode.IsDisable}
                ></HPRDropDownSingleSelect>
              </div>
            </Grid>

            <Grid item xs={4} className="hpr-form-control">
              <div className="form-group">
                <HPRDropDownSingleSelect
                  Label="System"
                  DefaultValue="Site 01"
                  Name="site"
                  Select={questionPageState.SystemId}
                  Disabled={questionPageState.PageMode.IsDisable}
                ></HPRDropDownSingleSelect>
              </div>
            </Grid>

            <Grid item xs={3} className="hpr-form-control">
              <div className="form-group">
                <HPRDropDownSingleSelect
                  Label="Sub-system"
                  DefaultValue="Site 01"
                  Name="site"
                  Select={questionPageState.SubSystemId}
                  Disabled={questionPageState.PageMode.IsDisable}
                ></HPRDropDownSingleSelect>
              </div>
            </Grid>

            <Grid item xs={2} className="hpr-form-control">
              <div className="form-group">
                <HPRDropDownSingleSelect
                  Label="Subject"
                  DefaultValue="Site 01"
                  Name="site"
                  Select={questionPageState.SubjectId}
                  Disabled={questionPageState.PageMode.IsDisable}
                ></HPRDropDownSingleSelect>
              </div>
            </Grid>

            <Grid item xs={1} className="hpr-form-control">
              <div className="form-group">
                <HPRDropDownSingleSelect
                  Label="Section"
                  DefaultValue="Site 01"
                  Name="site"
                  Select={questionPageState.SectionId}
                  Disabled={questionPageState.PageMode.IsDisable}
                ></HPRDropDownSingleSelect>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} className="mb-15">
            <div className="records-found">1 Record found</div>
          </Grid>
          {/* Chip and filter end */}

          <div className="form-group">
            <Grid container columns={12} spacing={2}>
              <Grid item lg={8} className="">
                <div className="inline-element">
                  <HPRCheckBox
                    Id={""}
                    Name={""}
                    checked={false}
                    ClassName={"inline-single-element"}
                    Label="Expand all"
                  ></HPRCheckBox>
                </div>
                {/*      <div className="width100-p">
                    
                  </div> */}
              </Grid>
            </Grid>
          </div>
          {/* inspection set start */}
          <div className="ins-standard-item-wrap">
            <div className="ins-standard-mark question"></div>

            <div className="ins-standard-chkbx">
              <HPRCheckBox Name={""} checked={false} />
            </div>

            <div className="ins-standard-text">
              <span>Q 0001</span>
              Standard
            </div>
            <div className="ins-standard-controls">
              <div className="d-flex valign-middle">
                {/* <div className="view-more-text">More</div> */}

                {/* <div className="d-flex">
                  <span className="ins-standard-q-count">6 </span>
                  <span>Question(s)</span>
                </div> */}

                {/* <span className="ins-standard-q-count">6 </span>
    <span>Quetion(s)</span> */}
              </div>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <IconButton className="more-button">
                  <span>More</span>
                  <ArrowUp2 variant="Outline" className="more-icon" />

                  <ArrowDown2 variant="Outline" className="more-icon" />
                </IconButton>
              </ExpandMore>
              <IconButton className="drag-button">
                <More
                  variant="Outline"
                  className="ins-standard-drag drag-icon drag-icon-2"
                />
                <More
                  variant="Outline"
                  className="ins-standard-drag drag-icon"
                />
              </IconButton>
              {/* <IconButton>
                <CloseCircle
                  variant="Outline"
                  className="ins-standard-drag close"
                />
              </IconButton> */}
            </div>
          </div>
          {/* inspection set tile end */}
          {/* <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ArrowRight2 />
          </ExpandMore> */}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {/* Data Row Start */}
            <div className="width-p-100 d-flex mb-15 main-card">
              <Grid container className="standrads-cards-main-wrap">
                <div className="standrads-cards-contain-wrap d-flex">
                  <div className="standrads-icon d-flex">
                    <div className="icon-question flex--container">
                      <div className="question secondary-color flex__item--vcenter">
                        Q
                      </div>
                      <h5 className="primary-color flex__item--vcenter question-no">
                        90{" "}
                      </h5>
                    </div>
                  </div>

                  <div className="standrads-icon-details-wrap">
                    <Grid container>
                      {/*  Standard question */}
                      {/* <Grid item lg={10}> */}
                      {/* <div className="standrads-que-header"></div>

                  <div className="filter-tag-wrapper">
                   
                  </div> */}
                      <Grid item lg={10}>
                        <div className="standrads-que-header">test</div>

                        <div className="filter-tag-wrapper">
                          <div className="filter-tag-inner-row">
                            <Grid container>
                              <Grid item lg={2} md={2} sm={2} xs={12}>
                                <label className="hpr-filternote-lbl body3">
                                  Reference
                                </label>
                              </Grid>
                              <Grid item lg={4} md={4} sm={2} xs={12}>
                                <label className="hpr-filternote-lbl body3">
                                  System
                                </label>
                              </Grid>
                              <Grid item lg={3} md={3} sm={2} xs={12}>
                                <label className="hpr-filternote-lbl body3">
                                  Sub-system
                                </label>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={12}>
                                <label className="hpr-filternote-lbl body3">
                                  Subject
                                </label>
                              </Grid>
                              <Grid item lg={1} md={1} sm={2} xs={12}>
                                <label className="hpr-filternote-lbl body3">
                                  Section
                                </label>
                              </Grid>
                            </Grid>
                          </div>
                        </div>

                        <div className="filter-tag-wrapper">
                          <div className="filter-tag-inner-row">
                            <Grid container>
                              <Grid item lg={2} md={2} sm={2} xs={12}>
                                <HPRFilterNode
                                  ClassName="hpr-filternote ref"
                                  Label="Reference"
                                  Name="Reference"
                                  Value="22"
                                />
                              </Grid>
                              <Grid item lg={4} md={4} sm={4} xs={12}>
                                <HPRFilterNode
                                  ClassName="hpr-filternote sys"
                                  Label="System"
                                  Name="System"
                                  Value="22"
                                />
                              </Grid>
                              <Grid item lg={3} md={3} sm={3} xs={12}>
                                <HPRFilterNode
                                  ClassName="hpr-filternote sub-sys"
                                  Label="Sub-system"
                                  Name="Sub-system"
                                  Value="22"
                                />
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={12}>
                                <HPRFilterNode
                                  ClassName="hpr-filternote subject"
                                  Label="Subject"
                                  Name="Subject"
                                  Value="22"
                                />
                              </Grid>
                              <Grid item lg={1} md={1} sm={1} xs={12}>
                                <HPRFilterNode
                                  ClassName="hpr-filternote section"
                                  Label="Section"
                                  Name="Section"
                                  Value="22"
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={1}></Grid>
                    </Grid>
                    <div className="modified-by flex--container">
                      <div className="body3 title pr-10">
                        Last modified by:{" "}
                      </div>
                      <div className="name body3 default-text-color">
                        Sasanika Wijerathna
                      </div>
                      <div className="date body3 gray-text">20-Dec-2022</div>
                    </div>
                    {/* <div style={{ width: "40%" }}>
                item <h4 className="standrads-icon-details-header">heder </h4>
              </div> */}
                  </div>
                  <div
                    className="flex--container col"
                    style={{ width: "15%", paddingTop: "10px" }}
                  >
                    <Grid item lg={3}>
                      <div className="standards-total-status-wrap flex--container">
                        {/* <div className="standards-total-ques flex--container">
                    <span className="version">Version: 5</span>
                    <span className="d-flex flex-valign-middle">54</span>
                  </div> */}

                        <div className="standards-status flex--container">
                          <span>Status:</span>
                          <span className="status-color green">Publish </span>
                        </div>
                      </div>
                      <div className="standarded-que-version flex--container line-h">
                        <span>Version:</span>
                        <span className="d-flex flex-valign-middle">4.0</span>
                      </div>
                    </Grid>
                  </div>
                </div>
                <div className="standards-icon-forward d-flex flex-valign-middle cursor-pointer">
                  <KeyboardArrowRightIcon />
                </div>
                <div className="standards-card-end"></div>
              </Grid>
            </div>

            {/* Data Row End */}
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="sub-header-btn-wrap"
          >
            <Button
              className="secondary-btn-small"
              size="small"
              variant="outlined"
              onClick={handleClose}
              // startIcon={<CloseCircle variant="Outline" />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // startIcon={<DirectboxReceive variant="Outline" />}
              className="primary-btn-small"
              size="small"
            >
              Save
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Modal
        className="modal-outter modal-md"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <h1>
              Due to the change(s) in the current question, following assigned
              inspection sets will be effected. Do you want to continue applying
              the changes?
            </h1>
          </div>
          <div className="modal-content">
            <h2>Inspection sets</h2>
            <ul>
              <li>Inspection set 01</li>
              <li>Inspection set 02</li>
              <li>Inspection set 03</li>
            </ul>
          </div>
          <div className="modal-button-bar">
            <Button
              className="primary align-left"
              variant="contained"
              disableElevation
            >
              Yes
            </Button>
            <Button className="primary" variant="contained" disableElevation>
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        className="modal-outter modal-md"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <h1>
              Due to the change(s) in the current question, active/assigned site
              inspection(s) will be affected. Do you want to apply the changes
              to all or one/more site inspection from the followning list? the
              changes?
            </h1>
          </div>
          <div className="modal-content">
            <h2>Inspection sets</h2>
            <ul>
              <li>
                <HPRCheckBox
                  Id={""}
                  Name={""}
                  checked={false}
                  ClassName={"inline-single-element"}
                  Label="Site inspection 01"
                ></HPRCheckBox>
              </li>
              <li>
                <HPRCheckBox
                  Id={""}
                  Name={""}
                  checked={false}
                  ClassName={"inline-single-element"}
                  Label="Site inspection 02"
                ></HPRCheckBox>
              </li>
              <li>
                <HPRCheckBox
                  Id={""}
                  Name={""}
                  checked={false}
                  ClassName={"inline-single-element"}
                  Label="Site inspection 03"
                ></HPRCheckBox>
              </li>
            </ul>
          </div>
          <div className="modal-button-bar">
            <Button
              className="primary align-left"
              variant="contained"
              disableElevation
            >
              Apply
            </Button>
            <Button className="primary" variant="contained" disableElevation>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default QuestionPage;
