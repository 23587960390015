/** @format */
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  RadioGroup,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  ArrowLeft2,
  ArrowRight2,
  Slash,
  TickCircle,
  ShieldTick,
  CloseCircle,
  Note,
  Edit,
} from "iconsax-react";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import "./QuestionnairePage.scss";
import HPRFileUploader from "../../../components/common/HPRFileUploader/HPRFileUploader";
import { FileUploaderErrorType } from "../../../components/common/HPRFileUploader/HPRFileUploader.types";
import {
  EnumApprovalStatus,
  EnumDataTransferStatus,
  EnumFileType,
  EnumInspectionQuestionStatus,
  EnumInspectionStandardStatus,
  EnumPageMode,
  EnumPageType,
  EnumQuestionTypesType,
} from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionQuestionChoiceDTO,
  InspectionQuestionDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import {
  QuestionnaireDataType,
  QuestionnaireParametersType,
} from "./QuestionnairePage.types";
import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getSiteInspectionQuestiontData,
  inspectionFileUpload,
  saveInspectionQuestionAnswer,
  saveInspectionStandardStatus,
} from "./QuestionnairePage.action";
import HPRRadioButton from "../../../components/common/HPRRadioButton/HPRRadioButton";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { motion } from "framer-motion";
import {
  setSiteInspectionStandardStatus,
  changeUploadFileListData,
  clearPageModeRedirectMode,
  clearPageState,
  setPageMode,
  setPageReadOnlyMode,
} from "./QuestionnairePage.reducer";
import { QuestionnaireSummaryPageParametersType } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { viewInspectionSummarySliceReducerActions } from "../../components/ViewInspectionSummary/ViewInspectionSummary.reducer";
import {
  EnumInspectionSummaryType,
  ViewInspectionSummaryProps,
} from "../../components/ViewInspectionSummary/ViewInspectionSummary.types";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import {
  CheckFunctionRights,
  formatDateTimeToString,
} from "../../../common/functions/common.functions";
import attachIco3 from "../../../icons/attachment.svg";
import ViewInspectionQuestionHistory from "../../components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory";
import HPRTextBoxAutoResize from "../../../components/common/HPRTextBox/HPRTextBoxAutoResize";
import { updateQuestionAnswerApprovalStatus } from "./QuestionnairePage.action";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: false,
    IsShowAddBtn: false,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: true,
    IsShowUpdateBtn: false,
    IsPauseBtn: false,
    IsCompleteBtn: false,
    IsShowProgress: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Questionnaire: {
    InspectionQuestion: {
      Comment: "",
    },
    StandardID: 0,
    SiteInspectionID: 0,
    CurrentQuestionNumber: 0,
    AllQuestionCount: 0,
    AnsweredCount: 0,
    PendingCount: 0,
    GapCount: 0,
    SkippedCount: 0,
    InspectionStandardName: "",
    QuestionProgressBarValue: 0,
    QuestionText: "",
    InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
    InspectionStandardStatus: 0,
    InspectionStandardStatusId: EnumInspectionStandardStatus.Completed,
    ReadOnlyMode: false,
    FinalQuestionAnswered: false,
    SaveAnswerBeforeClickPrevious: false,
    UploadedFileList: [] as Array<UploadFileDTO>,
    IsFromQuestionnairePge: false,
    ImageFile: {} as any,
    DocumentFile: [] as any,
    RemovedFileIdList: [] as Array<number>,
    IsSiteInspectionViewButtonClick: false,
    ReferenceID: 0,
    IsEnableReviewByGlobalSME: false,
    RejectedNow: false,
  } as QuestionnaireDTO,
  YesNoAnswer: "",
  IsHideSkipButton: false,
  QuestionnaireSaveActionName: "",
  IsQuestionnaireSaveRunning: false,
  QuestionnaireSaveCopy: {} as QuestionnaireDTO,
} as QuestionnaireDataType;

const QuestionnairePage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for QuestionnairePage with initialState values
  const [questionnairePagePageState, setQuestionnairePageState] =
    React.useState(initialState);
  const [numberList, setNumberList] = useState<InspectionQuestionDTO[] | null>(
    null
  );
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [isScrollableStartPrev, setIsScrollableStartPrev] =
    useState<boolean>(true);
  const [isScrollableEndPrev, setIsScrollableEndPrev] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAppSelector((state) => state.authReducer);
  //const [isDirty, setIsDirty] = useState(false);
  // const prompt = {
  //   Message: "Do you want to discard the changes?",
  //   When: isDirty,
  // } as UsePromptTypes;
  // usePrompt(prompt);
  const parameter: QuestionnaireParametersType = location.state;

  const FetchedRef = useRef(false);

  // Horizontal ScrollBar Navigation
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(true);
  const questionContainerRef = useRef<HTMLDivElement>(null);
  const container = questionContainerRef.current
    ? questionContainerRef.current
    : null;
  const ScrollWidth = container ? container.scrollWidth : 0;
  const ClientWidth = container ? container.clientWidth : 0;
  const ScrollLeft = container ? container.scrollLeft : 0;

  const handleScroll = (direction: number) => {
    checkScrollState(direction);
    if (direction === 1) {
      questionContainerRef.current.scrollBy({
        top: 0,
        left: 325,
        behavior: "smooth",
      });
      setIsStart(false);
    } else {
      questionContainerRef.current.scrollBy({
        top: 0,
        left: -325,
        behavior: "smooth",
      });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //Reject modal set
  const [openRejectPopup, setRejectPopupOpen] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      messages: {
        default: "Please enter a reason for rejection",
      },
    })
  );

  const handleRejectPopupOpen = () => {
    setRejectReason("");
    setRejectPopupOpen(true);
  };
  const [rejectReason, setRejectReason] = useState("");

  const handleRejectPopupClose = () => {
    setRejectPopupOpen(false);
    setRejectReason("");
    validator.hideMessages();
  };

  const checkScrollState = (direction?: number) => {
    try {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const isAtStart = scrollLeft - 325 <= 0;
      const isAtEnd = Math.ceil(scrollLeft + clientWidth + 325) >= scrollWidth;
      setIsStart(isAtStart);
      setIsEnd(isAtEnd);
      isAtStart
        ? setIsScrollableStartPrev(isAtStart)
        : setIsScrollableStartPrev(null);
      isAtEnd ? setIsScrollableEndPrev(isAtEnd) : setIsScrollableEndPrev(null);

      if (isAtStart === true && isScrollableStartPrev === true) {
        if (direction === 1) {
          setIsStart(false);
        } else {
          setIsStart(true);
        }
      }
      if (isAtEnd === true && isScrollableEndPrev === true) {
        if (direction === 0) {
          setIsEnd(false);
        } else {
          setIsEnd(true);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (ScrollWidth > ClientWidth) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
    if (ScrollLeft > 65) {
      setIsStart(false);
      setIsScrollableStartPrev(false);
    } else if (ScrollLeft <= 65) {
      setIsStart(true);
      setIsScrollableStartPrev(true);
    }
    if (ScrollLeft + ClientWidth + 65 <= ScrollWidth) {
      setIsEnd(false);
      setIsScrollableStartPrev(false);
    } else if (ScrollLeft + ClientWidth + 65 > ScrollWidth) {
      setIsEnd(true);
      setIsScrollableStartPrev(true);
    }
  }, [ScrollLeft, ClientWidth, ScrollWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollLeft } = container;

      if (!container) return;

      if (scrollLeft >= 300) {
        setIsStart(false);
        setIsScrollableStartPrev(false);
      } else if (scrollLeft > 0 && scrollLeft <= 150) {
        return;
      } else if (scrollLeft <= 0) {
        setIsStart(true);
        setIsScrollableStartPrev(true);
      }
    };
    setTimeout(() => {
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }, 100);

    // Clear the timeout
    //clearTimeout(timeoutId);
  }, [handleScroll]);

  const dispatch = useAppDispatch();
  const questionnaireState = useAppSelector(
    (state) => state.siteInspectionQuestionnaireReducer
  );

  window.addEventListener("popstate", (ev) => {
    navigate("/questionnaire", {
      state: {
        SiteInspectionID: parameter?.SiteInspectionID,
        StandardID: parameter?.StandardID,
        InspectionStandardId: parameter?.InspectionStandardId,
        ReferenceID: parameter?.ReferenceID,
        InspectionStandardName: parameter?.InspectionStandardName,
      } as QuestionnaireParametersType,
    });
  });

  const backwardTransition = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: "0%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: "100%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  const forwardTransition = {
    initial: {
      opacity: 0,
      x: "100%", // swap with '100%'
    },
    animate: {
      opacity: 1,
      x: "0%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: "-100%", // swap with '-100%'
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  type Transition = typeof backwardTransition | typeof forwardTransition;
  const [questionTransition, setQuestionTransition] =
    useState<Transition>(null);

  const initCountList = {
    All: 0,
    AnsweredWithoutGaps: 0,
    AnsweredWithGaps: 0,
    Skipped: 0,
    Pending: 0,
    AnsweredAsNA: 0,
    Answered: 0,
    InspectionStandardQuestionList: [] as Array<InspectionQuestionDTO>,
  };
  type CountListType = typeof initCountList;
  const [countList, setCountList] = useState<CountListType>(initCountList);

  //-----standard list heigh ------
  const slider = useRef(null);
  const legend = useRef(null);
  const header = useRef(null);
  const footer = useRef(null);

  //Set Hight
  const [qsectionheight, setQsectioHeight] = useState(0);

  //-----Page load-----

  const heightCal = () => {
    let qsectionheight =
      window.innerHeight -
      slider?.current?.offsetHeight -
      legend?.current?.offsetHeight -
      header?.current?.offsetHeight -
      footer?.current?.offsetHeight -
      145;
    setQsectioHeight(qsectionheight);

    function handleResize() {
      let qsectionheight =
        window.innerHeight -
        slider?.current?.offsetHeight -
        legend?.current?.offsetHeight -
        header?.current?.offsetHeight -
        footer?.current?.offsetHeight -
        145;
      setQsectioHeight(qsectionheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  };
  //------------------------------------

  const handleQuestionRadioChange = (name: string, value: any) => {
    if (parseInt(value) !== -1) {
      let qIndex: number = parseInt(name.split("_")[1]);

      let editedList: Array<InspectionQuestionChoiceDTO> =
        questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
          (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
            let updateQuestionChoice: InspectionQuestionChoiceDTO =
              Object.assign({} as InspectionQuestionChoiceDTO, questionChoice);
            if (index === qIndex) {
              if (parseInt(value) === 1 || parseInt(value) === 0) {
                updateQuestionChoice.IsCorrect = true;
              } else {
                updateQuestionChoice.IsCorrect = null;
              }

              return updateQuestionChoice;
            } else {
              updateQuestionChoice.IsCorrect = null;
              return updateQuestionChoice;
            }
          }
        );

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              InspectionQuestionChoices: editedList,
              NotApplicableAnswer: null,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          YesNoAnswer: value,
        };
      });

      CheckQuestionAnswered(
        questionnairePagePageState.Questionnaire,
        editedList,
        false
      );
    } else {
      let editedList: Array<InspectionQuestionChoiceDTO> =
        questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
          (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
            let updateQuestionChoice: InspectionQuestionChoiceDTO =
              Object.assign({} as InspectionQuestionChoiceDTO, questionChoice);
            updateQuestionChoice.IsCorrect = null;
            return updateQuestionChoice;
          }
        );

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              InspectionQuestionChoices: editedList,
              NotApplicableAnswer: true,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          YesNoAnswer: value,
        };
      });
      CheckQuestionAnswered(
        questionnairePagePageState.Questionnaire,
        editedList,
        true
      );
    }
    //setIsDirty(true);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.Questionnaire,
      functionId,
      functionTypeId
    );
  };

  const CheckQuestionAnswered = (
    questionnaireDTO: QuestionnaireDTO,
    editedList: any,
    NotApplicableAnswer: boolean
  ) => {
    let yesnoAnswer = editedList?.filter((obj: InspectionQuestionChoiceDTO) => {
      if (obj.IsCorrect && obj.IsCorrect != null) {
        return obj;
      }
    });

    if (
      questionnaireDTO.CurrentQuestionNumber ===
      questionnaireDTO.InspectionQuestionList?.length
    ) {
      if (yesnoAnswer?.length > 0 || NotApplicableAnswer !== false) {
        setQuestionnairePageState((values) => {
          return {
            ...values,
            IsHideSkipButton: true,
          };
        });
      }
    }
  };

  const OnTextBoxChange = (name: string, value: any) => {
    if (value != null) {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              Comment: value,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          IsHideSkipButton:
            questionnairePagePageState.Questionnaire?.CurrentQuestionNumber ===
              questionnairePagePageState.Questionnaire?.InspectionQuestionList
                ?.length &&
            value != null &&
            value !== ""
              ? true
              : false,
        };
      });
      // setIsDirty(true);
    }
  };

  //Page onload
  useEffect(() => {
    // heightCal();
    if (FetchedRef.current) return;
    FetchedRef.current = true;
    //dispatch(clearPageState());
    if (parameter?.SiteInspectionID > 0 && parameter?.StandardID > 0) {
      dispatch(
        getSiteInspectionQuestiontData({
          StandardID: parameter?.StandardID,
          SiteInspectionID: parameter?.SiteInspectionID,
          IsSiteInspectionViewButtonClick:
            parameter?.IsSiteInspectionViewButtonClick,
          InspectionStandardId: parameter?.InspectionStandardId,
          QuestionnaireStarted: parameter?.QuestionnaireStarted,
          ReferenceID: parameter?.ReferenceID,
          InspectionStandardName: parameter?.InspectionStandardName,
        } as QuestionnaireDTO)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: initialState.Questionnaire,
          PageMode: initialState.PageMode,
        };
      });
    }
  }, []);

  useEffect(() => {
    setNumberList(questionnaireState?.Questionnaire?.InspectionQuestionList);
  }, [questionnaireState.Questionnaire?.InspectionQuestionList]);

  useEffect(() => {
    if (numberList && numberList.length > 0) {
      heightCal();
    }
  }, [
    numberList,
    questionnairePagePageState.Questionnaire?.CurrentQuestionNumber,
    questionnairePagePageState.Questionnaire?.InspectionQuestion?.IsApproved,
    questionnairePagePageState.Questionnaire?.InspectionQuestion
      ?.RejectedReason,
    slider?.current?.offsetHeight,
    legend?.current?.offsetHeight,
    header?.current?.offsetHeight,
    footer?.current?.offsetHeight,
  ]);

  // every time when reducer state change set values to page state
  useEffect(() => {
    if (!FetchedRef.current) return;
    if (
      questionnairePagePageState?.Questionnaire?.InspectionQuestion?.Id !==
      questionnaireState?.Questionnaire?.InspectionQuestion?.Id
    ) {
      // setIsDirty(false);
      setTimeout(function () {
        let scrollto = questionnaireState.Questionnaire?.CurrentQuestionNumber;
        let diff = fieldRef.current?.length - scrollto;
        if (
          questionnairePagePageState?.Questionnaire?.CurrentQuestionNumber >
          questionnaireState.Questionnaire?.CurrentQuestionNumber
        ) {
          window.scrollTo(0, 0);
          if (questionnaireState.Questionnaire?.CurrentQuestionNumber <= 5) {
            fieldRef.current[
              questionnaireState.Questionnaire?.CurrentQuestionNumber -
                questionnaireState.Questionnaire?.CurrentQuestionNumber
            ]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else {
            fieldRef.current[scrollto - 6]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        } else {
          window.scrollTo(0, 0);
          if (diff >= 5) {
            fieldRef.current[scrollto + 4]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else if (diff > 2 && diff <= 5) {
            fieldRef.current[scrollto + 1]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else if (diff > 0 && diff <= 2) {
            fieldRef.current[scrollto]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else {
            fieldRef.current[scrollto - 1]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }
      }, 100);
    }

    let InspectionQuestionChoiceDTO: Array<InspectionQuestionChoiceDTO> =
      questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id !==
      questionnaireState.Questionnaire?.InspectionQuestion?.Id
        ? questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionChoices ?? []
        : questionnairePagePageState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionChoices ?? [];

    let notApplicableAnswer: boolean = questionnairePagePageState
      ? questionnairePagePageState.Questionnaire
        ? questionnairePagePageState.Questionnaire?.InspectionQuestion
          ? questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id !==
            questionnaireState.Questionnaire?.InspectionQuestion?.Id
            ? questionnaireState.Questionnaire?.InspectionQuestion
                ?.NotApplicableAnswer
            : questionnairePagePageState.Questionnaire?.InspectionQuestion
                ?.NotApplicableAnswer
          : false
        : false
      : false;

    let yesnoAnswer = InspectionQuestionChoiceDTO?.filter(
      (obj: InspectionQuestionChoiceDTO) => {
        if (obj.IsCorrect && obj.IsCorrect != null) {
          return obj;
        }
      }
    );

    let answerVal = "";
    let isSkipBtnHide = false;
    if (
      yesnoAnswer !== undefined &&
      yesnoAnswer != null &&
      yesnoAnswer?.length > 0
    ) {
      if (
        yesnoAnswer[0].ChoiceText != null &&
        yesnoAnswer[0].ChoiceText.DefaultText === "Yes"
      ) {
        answerVal = "1";
      } else {
        answerVal = "0";
      }
    } else if (notApplicableAnswer !== null && notApplicableAnswer) {
      answerVal = "-1";
    }

    if (
      questionnaireState.Questionnaire?.CurrentQuestionNumber ===
        questionnaireState.Questionnaire?.InspectionQuestionList?.length &&
      (answerVal !== "" ||
        questionnaireState.Questionnaire?.InspectionQuestion?.Comment != null ||
        questionnaireState.Questionnaire?.InspectionQuestion
          ?.ProductImpactAnswer != null ||
        questionnaireState.Questionnaire?.InspectionQuestion?.UploadFiles
          ?.length > 0)
    ) {
      isSkipBtnHide = true;
    } else {
      isSkipBtnHide = false;
    }

    let comment: string = questionnairePagePageState
      ? questionnairePagePageState.Questionnaire
        ? questionnairePagePageState.Questionnaire?.InspectionQuestion
          ? questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id !==
            questionnaireState.Questionnaire?.InspectionQuestion?.Id
            ? questionnaireState.Questionnaire?.InspectionQuestion?.Comment
            : questionnairePagePageState.Questionnaire?.InspectionQuestion
                ?.Comment
          : ""
        : ""
      : "";

    let ProductImpactAnswer: boolean = questionnairePagePageState
      ? questionnairePagePageState.Questionnaire
        ? questionnairePagePageState.Questionnaire?.InspectionQuestion
          ? questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id !==
            questionnaireState.Questionnaire?.InspectionQuestion?.Id
            ? questionnaireState.Questionnaire?.InspectionQuestion
                ?.ProductImpactAnswer
            : questionnairePagePageState.Questionnaire?.InspectionQuestion
                ?.ProductImpactAnswer
          : false
        : false
      : false;

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...questionnaireState.Questionnaire,
          InspectionQuestion: {
            ...questionnaireState.Questionnaire?.InspectionQuestion,
            Comment: comment,
            InspectionQuestionChoices: InspectionQuestionChoiceDTO,
            ProductImpactAnswer: ProductImpactAnswer,
            NotApplicableAnswer: notApplicableAnswer,
          },
          SaveAnswerBeforeClickPrevious:
            questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id !==
            questionnaireState.Questionnaire?.InspectionQuestion?.Id
              ? false
              : questionnaireState.Questionnaire?.SaveAnswerBeforeClickPrevious,
        },
        YesNoAnswer: answerVal,
        PageMode: questionnaireState.PageMode,
        IsHideSkipButton: isSkipBtnHide,
      };
    });

    if (questionnaireState?.PageMode?.RedirctOption?.NavigateURl !== "") {
      navigate(
        questionnaireState.PageMode.RedirctOption.NavigateURl,
        questionnaireState.PageMode.RedirctOption.NavigateObject
      );
      dispatch(clearPageModeRedirectMode());
      FetchedRef.current = false;
      dispatch(clearPageState());
    }
    setCountList((prevState) => ({
      ...prevState,
      All: questionnaireState.Questionnaire?.AllQuestionCount,
      AnsweredWithGaps: questionnaireState.Questionnaire?.GapCount,
      AnsweredWithoutGaps: questionnaireState.Questionnaire?.AnsweredCount,
      Skipped: questionnaireState.Questionnaire?.SkippedCount,
      Pending: questionnaireState.Questionnaire?.PendingCount,
      AnsweredAsNA: questionnaireState.Questionnaire?.AnsweredAsNA,
      Answered:
        questionnaireState.Questionnaire?.PendingApproveAnsweredCount ?? 0,
    }));
  }, [questionnaireState]);

  useEffect(() => {}, [
    questionnairePagePageState.Questionnaire?.InspectionQuestion
      ?.InspectionQuestionChoices,
  ]);

  const getSelectedImageFiles = (fileList: Array<UploadFileDTO>) => {
    // select files to be removed
    let removefileIdList: Array<number> =
      questionnairePagePageState.Questionnaire?.RemovedFileIdList != null
        ? [...questionnairePagePageState.Questionnaire?.RemovedFileIdList]
        : [];

    questionnairePagePageState.Questionnaire?.UploadedFileList.forEach(
      (item, index: number) => {
        let found = fileList.filter((obj) => {
          return obj.Id === item.Id;
        });

        if (found?.length === 0) {
          removefileIdList.push(item.Id);
        } else {
          // dispatch(updateUploadFilesList(fileList));
        }
      }
    );

    //
    let toBeAddList: Array<UploadFileDTO> = fileList.filter(
      (file) => file.UploadStatus === EnumDataTransferStatus.Default
    );

    fileList = fileList.map((file) => {
      return {
        ...file,
        InspectionQuestionId:
          questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id,
      };
    });

    dispatch(
      changeUploadFileListData({
        UploadedFileList: fileList,
        RemovedFileIdList: removefileIdList,
        SaveAnswerBeforeClickPrevious: true,
      } as QuestionnaireDTO)
    );

    //setIsDirty(true);
  };

  const uploadFilesToBackend = () => {
    let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
    let inpogressUploaded: UploadFileDTO = {} as UploadFileDTO;

    inpogressUploaded =
      questionnairePagePageState.Questionnaire?.UploadedFileList?.find(
        (file) => file.UploadStatus === EnumDataTransferStatus.InProgress
      );

    if (!inpogressUploaded) {
      toBeUploaded =
        questionnairePagePageState.Questionnaire?.UploadedFileList?.filter(
          (file) => file.UploadStatus === EnumDataTransferStatus.NotStarted
        );
      if (toBeUploaded?.length > 0) {
        dispatch(inspectionFileUpload());
      }
    }
  };

  useEffect(() => {
    uploadFilesToBackend();
  }, [questionnairePagePageState.Questionnaire?.UploadedFileList]);

  const onClickSaveBtn = (saveStatus?: EnumInspectionQuestionStatus): void => {
    //setIsDirty(false);
    if (
      !(
        questionnairePagePageState?.Questionnaire
          .IsSiteInspectionViewButtonClick ?? false
      ) &&
      (questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.QuestionTypeId === EnumQuestionTypesType.YesNo ||
        questionnairePagePageState?.Questionnaire?.InspectionQuestion
          ?.NotApplicable)
    ) {
      if (
        questionnairePagePageState.YesNoAnswer === "" &&
        !questionnairePagePageState.Questionnaire?.ReadOnlyMode &&
        !questionnairePagePageState.Questionnaire?.RejectedNow
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                saveInspectionQuestionAnswer({
                  ...questionnairePagePageState?.Questionnaire,
                  InspectionQuestion: {
                    ...questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                  },
                })
              );
            },
          } as PopupProps)
        );
      } else if (saveStatus === EnumInspectionQuestionStatus.Skipped) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                saveInspectionQuestionAnswer({
                  ...questionnairePagePageState?.Questionnaire,
                  InspectionQuestion: {
                    ...questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                    NotApplicableAnswer: null,
                    InspectionQuestionChoices: [
                      ...questionnairePagePageState?.Questionnaire
                        ?.InspectionQuestion?.InspectionQuestionChoices,
                    ].map((item) => {
                      return {
                        ...item,
                        IsCorrect: null,
                      };
                    }),
                  },
                })
              );
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          saveInspectionQuestionAnswer(
            questionnairePagePageState?.Questionnaire
          )
        );
      }
    } else {
      dispatch(
        saveInspectionQuestionAnswer(questionnairePagePageState?.Questionnaire)
      );
    }
  };

  const onClickPausedBtn = (
    saveStatus?: EnumInspectionStandardStatus,
    isFromPauseBtn?: Boolean
  ): void => {
    //setIsDirty(false);
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire?.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();

    setAnswerCountList();

    if (
      isFromPauseBtn &&
      !questionnairePagePageState.Questionnaire
        .IsSiteInspectionViewButtonClick &&
      questionnairePagePageState.Questionnaire?.InspectionStandardStatusId !==
        EnumInspectionStandardStatus.Completed
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Are you sure you want to pause?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setQuestionnairePageState((values) => {
              return {
                ...values,
                Questionnaire: {
                  ...values.Questionnaire,
                  InspectionStandardStatus: saveStatus,
                  FinalQuestionAnswered: SaveClickPrevious,
                  IsReferenceButtonClick: false,
                },
              };
            });
            dispatch(
              saveInspectionStandardStatus({
                ...questionnairePagePageState.Questionnaire,
                InspectionStandardStatus: saveStatus,
                FinalQuestionAnswered: SaveClickPrevious,
                IsReferenceButtonClick: false,
              })
            );
          },
          NoBtnClick() {
            reSetAnswerCountListToOriginal();
          },
        } as PopupProps)
      );
    } else if (
      questionnairePagePageState.Questionnaire?.IsSiteInspectionViewButtonClick
    ) {
      navigate("/siteInspectionStart", {
        state: {
          InspectionId:
            questionnairePagePageState.Questionnaire.SiteInspectionID,
        },
      });
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionStandardStatus: saveStatus,
            FinalQuestionAnswered: SaveClickPrevious,
            IsReferenceButtonClick: true,
          },
        };
      });
      dispatch(
        saveInspectionStandardStatus({
          ...questionnairePagePageState.Questionnaire,
          InspectionStandardStatus: saveStatus,
          FinalQuestionAnswered: SaveClickPrevious,
          IsReferenceButtonClick: true,
        })
      );
    }
  };

  const onClickCompleteBtn = (
    saveStatus?: EnumInspectionStandardStatus
  ): void => {
    //setIsDirty(false);
    if (
      questionnairePagePageState.Questionnaire?.InspectionQuestionList
        ?.length ===
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.SequenceNumber
    ) {
      validateAndComplete(saveStatus, true);
    } else {
      let pendingCount = questionnairePagePageState.Questionnaire?.PendingCount;
      let questionSaveStatus = true;

      let yesnoAnswerPrevious =
        questionnaireState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
          (obj: InspectionQuestionChoiceDTO) => {
            if (obj.IsCorrect && obj.IsCorrect != null) {
              return obj;
            }
          }
        );

      let yesnoAnswer =
        questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
          (obj: InspectionQuestionChoiceDTO) => {
            if (obj.IsCorrect && obj.IsCorrect != null) {
              return obj;
            }
          }
        );
      if (
        (pendingCount > 0 &&
          yesnoAnswer?.length > 0 &&
          yesnoAnswerPrevious?.length === 0) ||
        (questionnairePagePageState.Questionnaire?.InspectionQuestion
          .NotApplicableAnswer != null &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer == null) ||
        (yesnoAnswer?.length > 0 &&
          yesnoAnswerPrevious?.length === 0 &&
          questionnairePagePageState.Questionnaire?.UploadedFileList?.length >
            0)
      ) {
        pendingCount = pendingCount - 1;
        questionSaveStatus = true;
      } else if (
        questionnairePagePageState.Questionnaire?.UploadedFileList?.length > 0
      ) {
        questionSaveStatus = true;
      } else if (
        questionnairePagePageState.Questionnaire?.InspectionQuestion?.Comment
      ) {
        if (
          questionnairePagePageState.Questionnaire?.InspectionQuestion?.Comment
            ?.length > 0
        ) {
          questionSaveStatus = true;
        }
      }

      // validateAndComplete(pendingCount, saveStatus, questionSaveStatus);
      validateAndComplete(saveStatus, questionSaveStatus);
    }
  };

  const setAnswerCountList = (): CountListType => {
    // current answer count
    let answeredWithoutGaps =
      questionnairePagePageState.Questionnaire?.AnsweredCount ?? 0;
    let answeredWithGaps =
      questionnairePagePageState.Questionnaire?.GapCount ?? 0;
    let skipped = questionnairePagePageState.Questionnaire?.SkippedCount ?? 0;
    let answeredAsNA =
      questionnairePagePageState.Questionnaire?.AnsweredAsNA ?? 0;
    let PendingCount =
      questionnairePagePageState.Questionnaire?.PendingCount ?? 0;
    let pendingApproveAnsweredCount =
      questionnairePagePageState.Questionnaire?.PendingApproveAnsweredCount ??
      0;

    // current answer
    let inspectionQuestionStatusId =
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.InspectionQuestionStatusId;
    let notApplicableAnswer = false;

    let isAnswered =
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.IsAnswered ?? false;
    let isApproved =
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.IsApproved ?? false;

    // check answer status
    questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
      (item) => {
        if (item.IsGap && item.IsCorrect != null && item.IsCorrect) {
          inspectionQuestionStatusId = EnumInspectionQuestionStatus.Gap;
        } else if (!item.IsGap && item.IsCorrect != null && item.IsCorrect) {
          inspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
        }
      }
    );

    // check answer is notApplicableAnswer
    if (
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.NotApplicableAnswer ??
      false
    ) {
      inspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
      notApplicableAnswer = true;
    }

    // get previous answer
    const previousAnswer =
      questionnaireState.Questionnaire?.InspectionQuestion
        ?.InspectionQuestionStatusId;
    const isPreviousAnswerNotApplicable =
      questionnaireState.Questionnaire?.InspectionQuestion?.NotApplicableAnswer;

    // check is enable Review By Global SME
    const isEnableReviewByGlobalSME =
      questionnairePagePageState.Questionnaire?.IsEnableReviewByGlobalSME ??
      false;

    // set Answered without Gaps Count
    answeredWithoutGaps =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
      !notApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Answered &&
        !isPreviousAnswerNotApplicable
      ) &&
      !isEnableReviewByGlobalSME
        ? answeredWithoutGaps + 1
        : answeredWithoutGaps;

    if (
      !(
        inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
        !notApplicableAnswer
      ) &&
      previousAnswer === EnumInspectionQuestionStatus.Answered &&
      !isPreviousAnswerNotApplicable &&
      answeredWithoutGaps > 0
    ) {
      answeredWithoutGaps = answeredWithoutGaps - 1;
      isAnswered = false;
    }

    // set Answered with Gaps Count
    answeredWithGaps =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap &&
      !notApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Gap &&
        !isPreviousAnswerNotApplicable
      ) &&
      !isEnableReviewByGlobalSME
        ? answeredWithGaps + 1
        : answeredWithGaps;

    if (
      inspectionQuestionStatusId !== EnumInspectionQuestionStatus.Gap &&
      previousAnswer === EnumInspectionQuestionStatus.Gap &&
      answeredWithGaps > 0
    ) {
      answeredWithGaps = answeredWithGaps - 1;
      isAnswered = false;
    }

    // set Answered as N/A Count
    answeredAsNA =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
      notApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Answered &&
        isPreviousAnswerNotApplicable
      ) &&
      !isEnableReviewByGlobalSME
        ? answeredAsNA + 1
        : answeredAsNA;

    if (
      !(
        inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
        notApplicableAnswer
      ) &&
      previousAnswer === EnumInspectionQuestionStatus.Answered &&
      isPreviousAnswerNotApplicable &&
      answeredAsNA > 0
    ) {
      answeredAsNA = answeredAsNA - 1;
      isAnswered = false;
    }

    // set Skipped Count
    skipped =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Skipped &&
      !notApplicableAnswer
        ? skipped + 1
        : skipped;

    if (
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.InspectionQuestionStatusId === EnumInspectionQuestionStatus.Skipped &&
      skipped > 0 &&
      previousAnswer === EnumInspectionQuestionStatus.Skipped
    ) {
      skipped = skipped - 1;
      isAnswered = false;
    }

    // set Pending Count
    if (
      previousAnswer === EnumInspectionQuestionStatus.Pending &&
      inspectionQuestionStatusId !== EnumInspectionQuestionStatus.Pending &&
      PendingCount > 0
    ) {
      PendingCount = PendingCount - 1;
      isAnswered = false;
    }

    // set Pending Approve Answered Count
    if (
      isEnableReviewByGlobalSME &&
      (inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered ||
        inspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap) &&
      !isAnswered
    ) {
      pendingApproveAnsweredCount = pendingApproveAnsweredCount + 1;
      isAnswered = true;
      isApproved = false;
    }

    // set number list
    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId: inspectionQuestionStatusId,
          NotApplicableAnswer: notApplicableAnswer,
          IsAnswered: isAnswered,
          IsApproved: isApproved,
        };
      }
      return item;
    });
    setNumberList(numberlist);

    // set count list
    setCountList((prevState) => ({
      ...prevState,
      AnsweredWithoutGaps: answeredWithoutGaps,
      AnsweredWithGaps: answeredWithGaps,
      Skipped: skipped,
      Pending: PendingCount,
      AnsweredAsNA: answeredAsNA,
      Answered: pendingApproveAnsweredCount,
    }));

    return {
      AnsweredWithoutGaps: answeredWithoutGaps,
      AnsweredWithGaps: answeredWithGaps,
      Skipped: skipped,
      Pending: PendingCount,
      AnsweredAsNA: answeredAsNA,
      InspectionStandardQuestionList: numberlist,
    } as CountListType;
  };

  const reSetAnswerCountListToOriginal = () => {
    setCountList((prevState) => ({
      ...prevState,
      Pending: questionnaireState.Questionnaire?.PendingCount,
      AnsweredWithoutGaps: questionnaireState.Questionnaire?.AnsweredCount,
      AnsweredWithGaps: questionnaireState.Questionnaire?.GapCount,
      Skipped: questionnaireState.Questionnaire?.SkippedCount,
      AnsweredAsNA: questionnaireState.Questionnaire?.AnsweredAsNA,
      Answered:
        questionnaireState.Questionnaire?.PendingApproveAnsweredCount ?? 0,
    }));

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId:
            questionnaireState?.Questionnaire?.InspectionQuestion
              ?.InspectionQuestionStatusId,
          NotApplicableAnswer:
            questionnaireState?.Questionnaire?.InspectionQuestion
              ?.NotApplicableAnswer,
        };
      }
      return item;
    });
    setNumberList(numberlist);
  };

  const validateAndComplete = (
    saveStatus?: EnumInspectionStandardStatus,
    FinalQuestionAnswered?: boolean
  ) => {
    const answerCount = setAnswerCountList();

    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.InspectionStandard,
        SerachInspectionStandard: {
          Id: questionnairePagePageState.Questionnaire?.InspectionQuestion
            ?.InspectionStandardId,
          InspectionId:
            questionnairePagePageState.Questionnaire?.InspectionQuestion
              ?.SiteInspectionId,
          Inspection: {
            InspectionQuestions: answerCount.InspectionStandardQuestionList,
            AnsweredWithoutGapCount: answerCount?.AnsweredWithoutGaps,
            AnsweredWithGapCount: answerCount?.AnsweredWithGaps,
            AnsweredWithSkipCount: answerCount?.Skipped,
            AnsweredAsNACount: answerCount?.AnsweredAsNA,
            PendingQuestionCount: answerCount?.Pending,
          } as InspectionDTO,
        } as InspectionStandardDTO,
        onCompleteBtnClick(inspectionSummary: InspectionDTO) {
          setQuestionnairePageState((values) => {
            return {
              ...values,
              Questionnaire: {
                ...values.Questionnaire,
                InspectionQuestion: {
                  ...values.Questionnaire?.InspectionQuestion,
                  InspectionQuestionStatusId:
                    values.Questionnaire?.InspectionQuestion
                      .InspectionQuestionStatusId ===
                    EnumInspectionQuestionStatus.Pending
                      ? EnumInspectionQuestionStatus.Skipped
                      : values.Questionnaire?.InspectionQuestion
                          .InspectionQuestionStatusId,
                },
                InspectionStandardStatus: saveStatus,
                FinalQuestionAnswered: FinalQuestionAnswered,
              },
            };
          });
          dispatch(
            saveInspectionStandardStatus({
              ...questionnairePagePageState.Questionnaire,
              InspectionQuestion: {
                ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
                InspectionQuestionStatusId:
                  questionnairePagePageState.Questionnaire?.InspectionQuestion
                    .InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Pending
                    ? EnumInspectionQuestionStatus.Skipped
                    : questionnairePagePageState.Questionnaire
                        .InspectionQuestion?.InspectionQuestionStatusId,
              },
              InspectionStandardStatus: saveStatus,
              FinalQuestionAnswered: FinalQuestionAnswered,
            })
          );
        },
        onCloseBtnClick() {
          reSetAnswerCountListToOriginal();
        },
      } as ViewInspectionSummaryProps)
    );
  };

  const navigationQuestion = (sequence?: number): void => {
    //ignore navigate qustion when click current selection
    if (
      questionnairePagePageState.Questionnaire?.CurrentQuestionNumber ===
      sequence
    ) {
      return;
    }

    // setIsDirty(false);
    if (
      questionnairePagePageState.Questionnaire?.CurrentQuestionNumber > sequence
    ) {
      setQuestionTransition(backwardTransition);
    } else {
      setQuestionTransition(forwardTransition);
    }
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire?.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();

    if (
      !(
        questionnairePagePageState?.Questionnaire
          .IsSiteInspectionViewButtonClick ?? false
      ) &&
      questionnairePagePageState.YesNoAnswer === "" &&
      !questionnairePagePageState.Questionnaire?.ReadOnlyMode &&
      !questionnairePagePageState.Questionnaire?.RejectedNow
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Are you sure you want to skip?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setQuestionnairePageState((values) => {
              return {
                ...values,
                Questionnaire: {
                  ...values.Questionnaire,
                  PendingCount: 0,
                  AnsweredCount: 0,
                  AllQuestionCount: 0,
                  GapCount: 0,
                  SkippedCount: 0,
                  AnsweredAsNA: 0,
                  InspectionQuestion: {
                    ...values.Questionnaire?.InspectionQuestion,
                    //SequenceNumber: sequence,
                    Comment:
                      questionnairePagePageState.Questionnaire
                        .InspectionQuestion?.Comment,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                    //NotApplicableAnswer: null,
                  },
                  InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
                  SaveAnswerBeforeClickPrevious: true,
                  IsFromQuestionnairePge: false,
                },
              };
            });
            dispatch(
              getSiteInspectionQuestiontData({
                ...questionnairePagePageState.Questionnaire,

                PendingCount: 0,
                AllQuestionCount: 0,
                AnsweredCount: 0,
                GapCount: 0,
                SkippedCount: 0,
                AnsweredAsNA: 0,
                InspectionQuestion: {
                  ...questionnairePagePageState.Questionnaire
                    .InspectionQuestion,
                  SequenceNumber: sequence,
                  Comment:
                    questionnairePagePageState.Questionnaire?.InspectionQuestion
                      .Comment,
                  InspectionQuestionStatusId:
                    EnumInspectionQuestionStatus.Skipped,
                  //NotApplicableAnswer: null,
                },
                InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
                SaveAnswerBeforeClickPrevious: true,
                IsFromQuestionnairePge: false,
              })
            );
          },
        } as PopupProps)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            PendingCount: 0,
            AnsweredCount: 0,
            AllQuestionCount: 0,
            GapCount: 0,
            SkippedCount: 0,
            AnsweredAsNA: 0,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,

              Comment:
                questionnairePagePageState.Questionnaire?.InspectionQuestion
                  .Comment,
            },
            InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
            SaveAnswerBeforeClickPrevious: SaveClickPrevious,
            IsFromQuestionnairePge: false,
          },
        };
      });

      dispatch(
        getSiteInspectionQuestiontData({
          ...questionnairePagePageState.Questionnaire,

          PendingCount: 0,
          AllQuestionCount: 0,
          AnsweredCount: 0,
          GapCount: 0,
          SkippedCount: 0,
          AnsweredAsNA: 0,
          InspectionQuestion: {
            ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
            SequenceNumber: sequence,
            Comment:
              questionnairePagePageState.Questionnaire?.InspectionQuestion
                .Comment,
          },
          InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
          SaveAnswerBeforeClickPrevious: SaveClickPrevious,
          IsFromQuestionnairePge: false,
        })
      );
    }
  };

  const getPreviousInspectionQuestion = (
    Currentsequence?: number,
    SaveQuestion?: boolean,
    questionStatus?: EnumInspectionQuestionStatus
  ) => {
    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          PendingCount: 0,
          AnsweredCount: 0,
          AllQuestionCount: 0,
          GapCount: 0,
          SkippedCount: 0,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            //SequenceNumber: Currentsequence - 1,
            Comment:
              questionnairePagePageState.Questionnaire?.InspectionQuestion
                .Comment,
            InspectionQuestionStatusId: questionStatus,
          },
          InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
          SaveAnswerBeforeClickPrevious: SaveQuestion,
          IsFromQuestionnairePge: false,
        },
      };
    });
    dispatch(
      getSiteInspectionQuestiontData({
        ...questionnairePagePageState.Questionnaire,
        PendingCount: 0,
        AllQuestionCount: 0,
        AnsweredCount: 0,
        GapCount: 0,
        SkippedCount: 0,
        InspectionQuestion: {
          ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
          SequenceNumber: Currentsequence - 1,
          Comment:
            questionnairePagePageState.Questionnaire?.InspectionQuestion
              ?.Comment,
          InspectionQuestionStatusId: questionStatus,
        },
        InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
        SaveAnswerBeforeClickPrevious: SaveQuestion,
        IsFromQuestionnairePge: false,
      })
    );
  };

  const onClickBackBtn = (
    Currentsequence?: number,
    saveStatus?: EnumInspectionQuestionStatus
  ): void => {
    setQuestionTransition(backwardTransition);
    // setIsDirty(false);
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire?.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();
    if (
      !(
        questionnairePagePageState?.Questionnaire
          .IsSiteInspectionViewButtonClick ?? false
      ) &&
      (questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.QuestionTypeId === EnumQuestionTypesType.YesNo ||
        questionnairePagePageState?.Questionnaire?.InspectionQuestion
          ?.NotApplicable)
    ) {
      if (
        questionnairePagePageState.YesNoAnswer === "" &&
        !questionnairePagePageState.Questionnaire?.ReadOnlyMode &&
        !questionnairePagePageState.Questionnaire?.RejectedNow
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              getPreviousInspectionQuestion(
                Currentsequence,
                true,
                EnumInspectionQuestionStatus.Skipped
              );
            },
          } as PopupProps)
        );
      } else {
        getPreviousInspectionQuestion(
          Currentsequence,
          SaveClickPrevious,
          questionnairePagePageState.Questionnaire?.InspectionQuestion
            .InspectionQuestionStatusId
        );
      }
    } else {
      getPreviousInspectionQuestion(
        Currentsequence,
        false,
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          .InspectionQuestionStatusId
      );
    }
  };

  const getError = (error: FileUploaderErrorType) => {
    /* TODO document why this arrow function is empty */
  };

  const fieldRef = useRef([]);

  // fieldRef.current = [];
  const addToRefs: (el) => void = (el) => {
    if (el && !fieldRef.current.includes(el)) {
      fieldRef.current.push(el);
    }
  };

  const getIndicator = (
    inspectionQuestion: InspectionQuestionDTO,
    questionBoxStyle: string
  ) => {
    if (
      questionnairePagePageState.Questionnaire?.IsEnableReviewByGlobalSME &&
      ((inspectionQuestion.InspectionQuestionStatusId ===
        EnumInspectionQuestionStatus.Answered ||
        inspectionQuestion.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Gap ||
        inspectionQuestion.NotApplicableAnswer) ??
        false) &&
      (inspectionQuestion.IsAnswered ?? false) &&
      !(inspectionQuestion.IsApproved ?? false)
    ) {
      return "dark-blue-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
        EnumInspectionQuestionStatus.Answered &&
      !(inspectionQuestion.NotApplicableAnswer ?? false)
    ) {
      return "green-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
        EnumInspectionQuestionStatus.Answered &&
      (inspectionQuestion.NotApplicableAnswer ?? false)
    ) {
      return "na-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
      EnumInspectionQuestionStatus.Gap
    ) {
      return "red-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
      EnumInspectionQuestionStatus.Skipped
    ) {
      return "orange-indicator" + questionBoxStyle;
    } else {
      return "light-blue-indicator" + questionBoxStyle;
    }
  };

  const onClickClearAnswerBtn = (
    saveStatus?: EnumInspectionQuestionStatus
  ): void => {
    let editedList: Array<InspectionQuestionChoiceDTO> =
      questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
        (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
          let updateQuestionChoice: InspectionQuestionChoiceDTO = Object.assign(
            {} as InspectionQuestionChoiceDTO,
            questionChoice
          );
          updateQuestionChoice.IsCorrect = null;
          return updateQuestionChoice;
        }
      );

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            InspectionQuestionChoices: editedList,
            NotApplicableAnswer: null,
            InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
          },
          SaveAnswerBeforeClickPrevious: true,
          PendingCount: values.Questionnaire?.PendingCount + 1,
        },
        YesNoAnswer: "",
      };
    });

    dispatch(
      setSiteInspectionStandardStatus({
        ...questionnaireState.Questionnaire,
        InspectionQuestion: {
          ...questionnaireState.Questionnaire?.InspectionQuestion,
          InspectionQuestionChoices: editedList,
          NotApplicableAnswer: null,
          InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
        },
        SaveAnswerBeforeClickPrevious: true,
        PendingCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId !==
          EnumInspectionQuestionStatus.Pending
            ? questionnaireState.Questionnaire?.PendingCount + 1
            : questionnaireState.Questionnaire?.PendingCount,
        SkippedCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Skipped
            ? questionnaireState.Questionnaire?.SkippedCount - 1
            : questionnaireState.Questionnaire?.SkippedCount,
        AnsweredCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
            EnumInspectionQuestionStatus.Answered &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer === null
            ? questionnaireState.Questionnaire?.AnsweredCount - 1
            : questionnaireState.Questionnaire?.AnsweredCount,
        GapCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap
            ? questionnaireState.Questionnaire?.GapCount - 1
            : questionnaireState.Questionnaire?.GapCount,
        AnsweredAsNA:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
            EnumInspectionQuestionStatus.Answered &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer
            ? questionnaireState.Questionnaire?.AnsweredAsNA - 1
            : questionnaireState.Questionnaire?.AnsweredAsNA,
      })
    );

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId: saveStatus,
          NotApplicableAnswer: null,
        };
      }
      return item;
    });
    setNumberList(numberlist);
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
    dispatch(setPageReadOnlyMode(false));
  };

  const onClickQuestionAnswerApproveBtn = () => {
    dispatch(
      updateQuestionAnswerApprovalStatus({
        Id: questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id,
        AnswerApprovalStatus: EnumApprovalStatus.Approved,
      } as InspectionQuestionDTO)
    );
  };
  // Use a reducer to force re-render
  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];

  const onClickQuestionAnswerRejectBtn = () => {
    let isValid = validator.allValid();
    if (!isValid) {
      validator.showMessages();
      forceUpdate();
    } else {
      validator.hideMessages();

      setQuestionnairePageState((values) => {
        return {
          ...values,
          YesNoAnswer: "",
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              NotApplicableAnswer: null,
              InspectionQuestionChoices: [
                ...values.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
                  (item) => {
                    return { ...item, IsCorrect: null };
                  }
                ),
              ],
            },
          },
        };
      });

      dispatch(
        updateQuestionAnswerApprovalStatus({
          Id: questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id,
          AnswerApprovalStatus: EnumApprovalStatus.Reject,
          RejectedReason: rejectReason,
        } as InspectionQuestionDTO)
      );

      handleRejectPopupClose();
    }
  };

  const onChangeRejectReason = (name: string, value: string) => {
    setRejectReason(value ?? "");
  };

  const onClickGoToFirstBtn = () => {
    const SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire?.SaveAnswerBeforeClickPrevious;

    let sequenceNumber =
      questionnairePagePageState?.Questionnaire?.InspectionQuestionList?.find(
        (find) =>
          find.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Pending
      )?.SequenceNumber ?? numberList[0].SequenceNumber;

    dispatch(
      getSiteInspectionQuestiontData({
        ...questionnairePagePageState.Questionnaire,

        PendingCount: 0,
        AllQuestionCount: 0,
        AnsweredCount: 0,
        GapCount: 0,
        SkippedCount: 0,
        AnsweredAsNA: 0,
        InspectionQuestion: {
          ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
          SequenceNumber: sequenceNumber,
          Comment:
            questionnairePagePageState.Questionnaire?.InspectionQuestion
              .Comment,
        },
        InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
        SaveAnswerBeforeClickPrevious: SaveClickPrevious,
        IsFromQuestionnairePge: false,
      })
    );
  };

  const IsDisableCompleteBtn = (): boolean => {
    let isDisable = false;

    if (
      questionnairePagePageState?.Questionnaire?.IsInspectionStandardException
    ) {
      isDisable = true;
    } else if (
      questionnairePagePageState.Questionnaire?.IsEnableReviewByGlobalSME
    ) {
      const previousQuestionAnswerId =
        questionnaireState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.find(
          (find) => find.IsCorrect ?? false
        )?.Id ?? 0;

      const newQuestionAnswerId =
        questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.find(
          (find) => find.IsCorrect ?? false
        )?.Id ?? 0;

      const previousIsNotApplicableAnswer =
        questionnaireState.Questionnaire?.InspectionQuestion
          ?.NotApplicableAnswer ?? false;

      const newIsNotApplicableAnswer =
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.NotApplicableAnswer ?? false;

      if (
        questionnairePagePageState.Questionnaire
          ?.PendingApproveAnsweredCount !== 0 ||
        previousQuestionAnswerId !== newQuestionAnswerId ||
        previousIsNotApplicableAnswer !== newIsNotApplicableAnswer
      ) {
        isDisable = true;
      }
    }

    return isDisable;
  };

  return (
    <>
      {questionnairePagePageState?.Questionnaire?.InspectionQuestion
        .QuestionId ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="custom-question-nav-wrap" ref={slider}>
                  <div className="">
                    {isScrollable && !isStart && (
                      <div
                        className="q-nav button"
                        onClick={() => {
                          handleScroll(0);
                        }}
                        style={{ marginLeft: "-14px" }}
                      >
                        <ArrowLeft2 className="prev" />
                        <span className="mr-10 prev">
                          Previous <br />
                          QN(s)
                        </span>
                      </div>
                    )}
                  </div>

                  <div
                    className={`question-container ${
                      isScrollable ? "question-container" : ""
                    }`}
                    ref={questionContainerRef}
                  >
                    {numberList &&
                      numberList.map((list, index) => {
                        let questionBoxStyle: string =
                          list.SequenceNumber ===
                          questionnairePagePageState.Questionnaire
                            .CurrentQuestionNumber
                            ? " current-item-blue-border"
                            : "";

                        return (
                          <div
                            key={index}
                            className={"question-item-wrapper"}
                            onClick={() =>
                              navigationQuestion(list.SequenceNumber)
                            }
                            ref={addToRefs}
                          >
                            <span
                              className={getIndicator(list, questionBoxStyle)}
                            >
                              {list.SequenceNumber}
                              <span
                                className="attachment-indicator"
                                style={{
                                  display: list.IsAttachmentAvailable
                                    ? "flex"
                                    : "none",
                                }}
                              >
                                {/* <AttachFileIcon></AttachFileIcon> */}
                                <img src={attachIco3} height={20} />
                              </span>
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <div className="m-l-3">
                    {isScrollable && !isEnd && (
                      // <Tooltip title="Next Question">
                      <div
                        className="q-nav"
                        onClick={() => handleScroll(1)}
                        style={{ marginRight: "-14px" }}
                      >
                        <span className="ml-10">
                          Next <br />
                          QN(s)
                        </span>
                        <div className="next">
                          <ArrowRight2 />
                        </div>
                      </div>
                      // </Tooltip>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            {/*Counts bar start*/}

            <Grid container>
              <div className="legend-wrapper" ref={legend}>
                <ul>
                  <li>
                    <a>
                      <span className="color-code-legend green-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredWithoutGaps})
                        </span>
                        No Gaps
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend red-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredWithGaps})
                        </span>
                        Gaps
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend na-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredAsNA})
                        </span>
                        N/A
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend orange-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.Skipped})
                        </span>
                        Skipped
                      </label>
                    </a>
                  </li>
                  <li>
                    {questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion?.SiteInspection
                      ?.InspectionStandards[0]?.InspectionStandardStatusId !==
                    EnumInspectionStandardStatus.Completed ? (
                      <a>
                        <span className="color-code-legend blue-indicator"></span>
                        <label className="legend-description">Current</label>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                  <li>
                    {questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion?.SiteInspection
                      ?.InspectionStandards[0]?.InspectionStandardStatusId !==
                    EnumInspectionStandardStatus.Completed ? (
                      <a>
                        <span className="color-code-legend light-blue-indicator"></span>
                        <label className="legend-description">
                          <span className="q-quantity">
                            ({countList.Pending})
                          </span>
                          Pending
                        </label>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                  {questionnairePagePageState.Questionnaire
                    ?.IsEnableReviewByGlobalSME && (
                    <li>
                      <a>
                        <span className="color-code-legend dark-blue-indicator"></span>
                        <label className="legend-description">
                          <span className="q-quantity">
                            ({countList.Answered})
                          </span>
                          Answered
                        </label>
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              <div className="flex-right valign-middle site-ins-progress-test">
                {/* {questionnairePagePageState?.PageMode.IsShowProgress ? ( */}
                {questionnaireState?.Questionnaire
                  ?.InspectionStandardStatusId !==
                  EnumInspectionStandardStatus.Completed &&
                questionnairePagePageState?.Questionnaire?.ReferenceID === 0 ? (
                  <span className="question-num-wrapper align-right margin-right-15">
                    Progress:{" "}
                    <span className="cur-question-numv">
                      {countList.AnsweredWithoutGaps +
                        countList.AnsweredWithGaps +
                        countList.AnsweredAsNA}
                    </span>
                    <span className="total-question-num">/{countList.All}</span>
                  </span>
                ) : (
                  <></>
                )}

                {questionnairePagePageState?.Questionnaire?.InspectionQuestion
                  ?.SiteInspection?.InspectionStandards[0]
                  ?.InspectionStandardStatusId ===
                EnumInspectionStandardStatus.Completed ? (
                  <label className="audit-qty-lbl red-text align-right">
                    <span></span>
                    {questionnairePagePageState.Questionnaire?.SkippedCount +
                      questionnairePagePageState.Questionnaire?.GapCount +
                      " Total Gaps"}
                  </label>
                ) : (
                  <></>
                )}
                {questionnaireState?.Questionnaire
                  ?.InspectionStandardStatusId !==
                  EnumInspectionStandardStatus.Completed &&
                questionnairePagePageState?.Questionnaire?.ReferenceID > 0 ? (
                  <span className="question-num-wrapper align-right margin-right-15">
                    Selection Progress:{" "}
                    <span className="cur-question-numv">
                      {countList.AnsweredWithoutGaps +
                        countList.AnsweredWithGaps +
                        countList.AnsweredAsNA}
                    </span>
                    <span className="total-question-num">/{countList.All}</span>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </Grid>

            {/*Counts bar end*/}

            <Grid container>
              <Grid item xs={12}>
                <div className="item-wrapper questionaire-wrap mb-0">
                  <div className="item-header" ref={header}>
                    <Grid container>
                      <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
                        <h1 className="fx v-align">
                          <a
                            className="cursor-pointer audit-back v-align mr-10"
                            style={{ display: "inline-flex" }}
                            onClick={() =>
                              onClickPausedBtn(
                                EnumInspectionStandardStatus.Paused,
                                true
                              )
                            }
                            title={"Back To Site Inspection"}
                          >
                            <ArrowBackIosNewOutlinedIcon className="" />
                            <span className="body-bold secondary-color flex__item--vcenter">
                              Back
                            </span>
                          </a>
                          <Tooltip
                            title={`${questionnairePagePageState.Questionnaire?.InspectionName} : ${questionnairePagePageState.Questionnaire.InspectionStandardName}`}
                          >
                            <span className="limit-1">
                              {`${questionnairePagePageState.Questionnaire?.InspectionName} : ${questionnairePagePageState.Questionnaire.InspectionStandardName}`}
                            </span>
                          </Tooltip>
                        </h1>
                      </Grid>
                      <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                        {/* Reference selection button */}
                        {questionnairePagePageState?.Questionnaire
                          ?.ReferenceID > 0 && (
                          <Button
                            className="primary-btn-small align-right" // change 'primary' -> 'primary-btn-small'
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              onClickPausedBtn(
                                EnumInspectionStandardStatus.Paused,
                                false
                              )
                            }
                          >
                            Reference selection
                          </Button>
                        )}

                        {/* Complete button */}
                        {((questionnairePagePageState.Questionnaire
                          ?.IsEnableReviewByGlobalSME &&
                          questionnairePagePageState.Questionnaire
                            ?.InspectionStandardStatusId !==
                            EnumInspectionStandardStatus.Completed) ||
                          (questionnairePagePageState?.PageMode.IsPauseBtn &&
                            questionnairePagePageState?.PageMode
                              .IsCompleteBtn)) &&
                          !questionnairePagePageState?.Questionnaire
                            ?.IsSiteInspectionViewButtonClick &&
                          questionnairePagePageState?.Questionnaire
                            ?.ReferenceID === 0 &&
                          checkSystemFunctionRight(
                            EnumSystemFunctions.QuestionnairePage_CompleteStandard,
                            EnumSystemFunctionTypes.ButtonAccess
                          ) && (
                            <Button
                              startIcon={<ShieldTick variant="Outline" />} // add icon
                              className={
                                IsDisableCompleteBtn()
                                  ? "primary-btn-small  align-right disable-btn" // change 'primary' -> 'primary-btn-small'
                                  : "primary-btn-small  align-right" // change 'primary' -> 'primary-btn-small'
                              }
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                onClickCompleteBtn(
                                  EnumInspectionStandardStatus.Completed
                                )
                              }
                              disabled={IsDisableCompleteBtn()}
                            >
                              Complete
                            </Button>
                          )}

                        {/* Approve & Reject button */}
                        {!questionnairePagePageState?.Questionnaire
                          ?.IsSiteInspectionViewButtonClick &&
                          questionnairePagePageState?.Questionnaire
                            ?.ReferenceID === 0 &&
                          questionnairePagePageState?.Questionnaire
                            ?.IsEnableReviewByGlobalSME &&
                          questionnairePagePageState.Questionnaire
                            ?.IsInspectionQuestionApprover &&
                          (questionnairePagePageState.Questionnaire
                            ?.InspectionQuestion?.IsAnswered ??
                            false) &&
                          !(
                            questionnairePagePageState.Questionnaire
                              ?.InspectionQuestion?.IsApproved ?? false
                          ) && (
                            <>
                              {checkSystemFunctionRight(
                                EnumSystemFunctions.QuestionnairePage_GlobalSMEReject,
                                EnumSystemFunctionTypes.ButtonAccess
                              ) && (
                                <Button
                                  className="primary-btn-small align-right"
                                  disableElevation
                                  size="small"
                                  variant="outlined"
                                  startIcon={
                                    <Slash variant="Outline" size={20} />
                                  }
                                  onClick={handleRejectPopupOpen}
                                >
                                  Reject
                                </Button>
                              )}

                              {checkSystemFunctionRight(
                                EnumSystemFunctions.QuestionnairePage_GlobalSMEApprove,
                                EnumSystemFunctionTypes.ButtonAccess
                              ) && (
                                <Button
                                  className="primary-btn-small align-right"
                                  disableElevation
                                  size="small"
                                  variant="outlined"
                                  startIcon={
                                    <TickCircle variant="Outline" size={20} />
                                  }
                                  onClick={onClickQuestionAnswerApproveBtn}
                                >
                                  Approve
                                </Button>
                              )}
                            </>
                          )}

                        {/* Edit button */}
                        {questionnairePagePageState?.Questionnaire
                          ?.IsEnableReviewByGlobalSME &&
                          checkSystemFunctionRight(
                            EnumSystemFunctions.QuestionnairePage_OverwriteApprovedAnswers,
                            EnumSystemFunctionTypes.ButtonAccess
                          ) &&
                          (questionnairePagePageState.Questionnaire
                            ?.InspectionQuestion?.IsAnswered ??
                            false) &&
                          (questionnairePagePageState.Questionnaire
                            ?.InspectionQuestion?.IsApproved ??
                            false) &&
                          questionnairePagePageState.PageMode.PageMode ===
                            EnumPageMode.ViewMode &&
                          !questionnairePagePageState?.Questionnaire
                            .IsSiteInspectionViewButtonClick && (
                            <Button
                              className="primary-btn-small align-right"
                              variant="outlined"
                              disableElevation
                              onClick={onClickEditBtn}
                              startIcon={<Edit variant="Outline" />}
                              size="small"
                            >
                              Edit
                            </Button>
                          )}

                        {/* Summary button */}
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.SiteInspection
                          ?.InspectionStandards[0]
                          ?.InspectionStandardStatusId ===
                          EnumInspectionStandardStatus.Completed &&
                          questionnairePagePageState?.Questionnaire
                            ?.ReferenceID === 0 && (
                            <Button
                              startIcon={<Note variant="Outline" />} // add icon
                              className="primary-btn-small  align-right" // change 'primary' -> 'primary-btn-small'
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                navigate("/questionnaireSummary", {
                                  state: {
                                    StandardId:
                                      questionnairePagePageState.Questionnaire
                                        .StandardID,
                                    SiteInspectionId:
                                      questionnairePagePageState.Questionnaire
                                        .SiteInspectionID,
                                    InspectionStandardId:
                                      questionnairePagePageState.Questionnaire
                                        .InspectionStandardId,
                                    ReAssess: false,
                                  } as QuestionnaireSummaryPageParametersType,
                                })
                              }
                            >
                              Summary
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                  {/*Start of question*/}
                  <>
                    <motion.div
                      key={
                        questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.QuestionId
                      }
                      className="item-content question-conent"
                      variants={questionTransition}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      style={{ height: qsectionheight, overflowY: "auto" }}
                    >
                      {/*Start of question*/}
                      <div className="questionair-outer-wrapper">
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <label className="question-label">
                              <span className="question-num">
                                {questionnairePagePageState
                                  ? questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.SequenceNumber
                                  : ""}
                                {questionnairePagePageState &&
                                questionnairePagePageState.Questionnaire
                                  .InspectionQuestion?.SequenceNumber
                                  ? "."
                                  : ""}
                              </span>
                              <span className="question-text mb-0">
                                {
                                  questionnairePagePageState?.Questionnaire
                                    ?.QuestionText
                                }
                              </span>
                            </label>
                          </Grid>
                        </Grid>

                        <Grid container className="mb-5 mt-5">
                          <Grid item xl={2} lg={3} md={6} sm={6} xs={12}>
                            <div className="audit-sub-section width-auto">
                              {questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion?.QuestionTypeId ===
                              EnumQuestionTypesType.YesNo ? (
                                questionnairePagePageState?.Questionnaire
                                  ?.InspectionQuestion
                                  ?.InspectionQuestionChoices?.length > 0 ? (
                                  <RadioGroup
                                    name="QuestionAnswer"
                                    className="max-content"
                                    value={
                                      questionnairePagePageState.YesNoAnswer
                                    }
                                    onChange={(event) =>
                                      handleQuestionRadioChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                  >
                                    {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
                                      (choiceData, index) => {
                                        return (
                                          <HPRRadioButton
                                            key={index}
                                            Name={"QuestionAnswer_" + index}
                                            Disabled={
                                              questionnairePagePageState
                                                ?.PageMode?.IsDisable
                                            }
                                            Value={
                                              choiceData.ChoiceText
                                                .DefaultText === "Yes"
                                                ? "1"
                                                : "0"
                                            }
                                            Label={
                                              choiceData.ChoiceText.DefaultText
                                            }
                                            ClassName={"default-label"}
                                          ></HPRRadioButton>
                                        );
                                      }
                                    )}
                                    {questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.NotApplicable ? (
                                      <HPRRadioButton
                                        Name={"QuestionAnswer"}
                                        Value={"-1"}
                                        Label={"Not Applicable"}
                                        ClassName={"default-label applicable"}
                                        Disabled={
                                          questionnairePagePageState?.PageMode
                                            ?.IsDisable
                                        }
                                      ></HPRRadioButton>
                                    ) : (
                                      <></>
                                    )}
                                  </RadioGroup>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="audit-sub-section-right"
                          >
                            {questionnairePagePageState.Questionnaire
                              ?.IsEnableReviewByGlobalSME &&
                              questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion?.PreviousAnswer && (
                                <div className="audit-sub-section">
                                  <div className="form-group site-inspec-textarea mb-0 pre-answer-textarea">
                                    {" "}
                                    {/* add width to "Previous Answer" textarea same as in Figma design (new class in _elements.scss) */}
                                    <HPRTextBoxAutoResize
                                      Id={"previousAnswer"}
                                      Label={"Previous Answer"}
                                      Name={"previousAnswer"}
                                      Value={
                                        questionnairePagePageState
                                          ?.Questionnaire?.InspectionQuestion
                                          ?.PreviousAnswer || ""
                                      }
                                      Type={EnumTextBoxType.Text}
                                      ClassName={"site-inspec-textarea"}
                                      InputProps={{ maxLength: 1000 }}
                                      TextLength={1000}
                                      MultiLine
                                      onTextBoxChange={OnTextBoxChange}
                                      Disabled={true}
                                    />
                                  </div>
                                </div>
                              )}

                            {!questionnairePagePageState?.PageMode.IsDisable &&
                            (questionnairePagePageState.YesNoAnswer !== "" ||
                              questionnairePagePageState.Questionnaire
                                .InspectionQuestion?.NotApplicableAnswer) ? (
                              <>
                                <Button
                                  className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    display: "flex",
                                    alignSelf: "end",
                                    visibility: checkSystemFunctionRight(
                                      EnumSystemFunctions.QuestionnairePage_ClearAnswer,
                                      EnumSystemFunctionTypes.ButtonAccess
                                    )
                                      ? "visible"
                                      : "hidden",
                                  }}
                                  onClick={() => {
                                    onClickClearAnswerBtn(
                                      EnumInspectionQuestionStatus.Pending
                                    );
                                  }}
                                >
                                  Clear answer
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.AdditionalComments ? (
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="audit-sub-section mt-10">
                                <div className="form-group site-inspec-textarea mb-0">
                                  <HPRTextBoxAutoResize
                                    Id={""}
                                    Label={"Comment"}
                                    Name={"QuestionComment"}
                                    Value={
                                      questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion?.Comment
                                        ? questionnairePagePageState
                                            ?.Questionnaire?.InspectionQuestion
                                            ?.Comment
                                        : ""
                                    }
                                    Type={EnumTextBoxType.Text}
                                    ClassName={"site-inspec-textarea"}
                                    InputProps={{ maxLength: 1000 }}
                                    ShowTextCounter
                                    TextLength={1000}
                                    MultiLine
                                    onTextBoxChange={OnTextBoxChange}
                                    Disabled={
                                      questionnairePagePageState?.PageMode
                                        ?.IsDisable
                                    }
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : null}
                        <Grid container spacing={2} className="mb-5">
                          {questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion?.ImageUpload !== 3 ? (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div className="item-desc-wrap">
                                    <div className={"selected-item-wrapper"}>
                                      <HPRFileUploader
                                        Label={"Upload image"}
                                        FileType={[EnumFileType.Image]}
                                        Name={"Image"}
                                        GetselectedFiles={getSelectedImageFiles}
                                        GetError={getError}
                                        PreviewImage={true}
                                        UploadedFileList={
                                          questionnairePagePageState
                                            .Questionnaire?.UploadedFileList
                                        }
                                        Multiple
                                        Disabled={
                                          questionnairePagePageState?.PageMode
                                            ?.IsDisable
                                        }
                                        ShowComment
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                          {questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion?.ImageUpload === 3 &&
                          questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion?.DocumentUpload !== 3 ? (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} />
                          ) : null}
                          {questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion?.DocumentUpload !== 3 ? (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <div
                                className={
                                  "selected-item-wrapper align-right upload-button"
                                }
                              >
                                <HPRFileUploader
                                  Label={"Upload documents"}
                                  FileType={[
                                    EnumFileType.Doc,
                                    EnumFileType.Email,
                                  ]}
                                  Name={"doc"}
                                  GetselectedFiles={getSelectedImageFiles}
                                  GetError={getError}
                                  UploadedFileList={
                                    questionnairePagePageState.Questionnaire
                                      .UploadedFileList
                                  }
                                  Multiple
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                  ShowComment
                                />
                              </div>
                            </Grid>
                          ) : null}
                        </Grid>

                        <Grid container>
                          <div className="audit-sub-section">
                            {questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestion?.InspectionQuestionParameters
                              .length > 0 ? (
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={2}>
                                    <div className="grid-item-header">
                                      Reference
                                    </div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div className="grid-item-header">
                                      System
                                    </div>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div className="grid-item-header">
                                      Sub-system
                                    </div>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <div className="grid-item-header">
                                      Subject
                                    </div>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <div className="grid-item-header">
                                      Section
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionParameters?.map(
                              (questionparams, index: number) => {
                                return (
                                  <Grid item xs={12} key={index}>
                                    <Grid container spacing={1} sx={{ mb: 1 }}>
                                      <Grid item xs={2}>
                                        <div className="grid-item-wrapper q-page-ref">
                                          <a
                                            href={
                                              questionparams.StandardReference
                                                .Url
                                            }
                                            target="_blank"
                                          >
                                            {questionparams.StandardReference
                                              .Name
                                              ? questionparams.StandardReference
                                                  .Name
                                              : "N/A"}
                                          </a>
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSystem.Name
                                            ? questionparams.StandardSystem.Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSubSystem.Name
                                            ? questionparams.StandardSubSystem
                                                .Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSubject.Name
                                            ? questionparams.StandardSubject
                                                .Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSection.Name
                                            ? questionparams.StandardSection
                                                .Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                          </div>
                        </Grid>
                      </div>
                    </motion.div>
                  </>
                  {/* End of question */}
                  <div className="audit-item-footer" ref={footer}>
                    <Grid
                      container
                      className={`${
                        questionnairePagePageState.Questionnaire
                          ?.IsEnableReviewByGlobalSME
                          ? "fx-middle"
                          : "fx-between"
                      }`}
                    >
                      <Grid item xl={4} lg={4} md={4} sm={3} xs={3}>
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestionList?.length > 1 &&
                        questionnairePagePageState.Questionnaire
                          .CurrentQuestionNumber > 1 ? (
                          <Button
                            className="primary small-btn align-left"
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              onClickBackBtn(
                                questionnairePagePageState.Questionnaire
                                  .CurrentQuestionNumber,
                                EnumInspectionQuestionStatus.Pending
                              )
                            }
                          >
                            Previous
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {questionnairePagePageState.Questionnaire
                        ?.IsEnableReviewByGlobalSME && (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <div
                            className="mt-10 pt-5"
                            // style={{ visibility: "hidden" }}
                          >
                            <div className="form-group site-inspec-textarea mb-0">
                              {questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion?.RejectedReason && (
                                <HPRTextBoxAutoResize
                                  Id={"reasonForRejection"}
                                  Label={"Reason for Rejection"}
                                  Name={"ReasonforRejection"}
                                  Value={`${
                                    questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.RejectedByNavigation
                                      ?.DisplayName
                                  } | ${formatDateTimeToString(
                                    questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.RejectedDate,
                                    "DD-MMM-YYYY HH:mm"
                                  )} - ${
                                    questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.RejectedReason
                                  }`}
                                  Type={EnumTextBoxType.Text}
                                  ClassName={"site-inspec-textarea"}
                                  InputProps={{ maxLength: 1000 }}
                                  // ShowTextCounter
                                  TextLength={1000}
                                  MultiLine
                                  onTextBoxChange={OnTextBoxChange}
                                  Disabled={true}
                                />
                              )}

                              {questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion?.IsApproved && (
                                <HPRTextBoxAutoResize
                                  Id={"approvedByAndDate"}
                                  Label={"Approved By and Date"}
                                  Name={"approvedByAndDate"}
                                  Value={`${
                                    questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.ApprovedByNavigation
                                      ?.DisplayName
                                  } | ${formatDateTimeToString(
                                    questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.ApprovedDate,
                                    "DD-MMM-YYYY HH:mm"
                                  )}`}
                                  Type={EnumTextBoxType.Text}
                                  ClassName={"site-inspec-textarea"}
                                  InputProps={{ maxLength: 1000 }}
                                  // ShowTextCounter
                                  TextLength={1000}
                                  MultiLine
                                  onTextBoxChange={OnTextBoxChange}
                                  Disabled={true}
                                />
                              )}
                            </div>
                          </div>
                        </Grid>
                      )}

                      <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestionList?.length > 1 &&
                          questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestionList[
                            questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestionList?.length - 1
                          ]?.SequenceNumber !==
                            questionnairePagePageState.Questionnaire
                              .CurrentQuestionNumber && (
                            <Button
                              className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                setQuestionTransition(forwardTransition);
                                onClickSaveBtn(
                                  EnumInspectionQuestionStatus.Pending
                                );
                              }}
                            >
                              Next
                            </Button>
                          )}

                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestionList?.length > 1 &&
                          questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestionList[
                            questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestionList?.length - 1
                          ]?.SequenceNumber ===
                            questionnairePagePageState.Questionnaire
                              .CurrentQuestionNumber && (
                            <Button
                              className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                              variant="contained"
                              disableElevation
                              onClick={onClickGoToFirstBtn}
                            >
                              {`${
                                questionnairePagePageState?.Questionnaire?.InspectionQuestionList?.some(
                                  (some) =>
                                    some.InspectionQuestionStatusId ===
                                    EnumInspectionQuestionStatus.Pending
                                )
                                  ? "Go To First Pending"
                                  : "Go To First"
                              }`}
                            </Button>
                          )}

                        {!questionnairePagePageState?.PageMode.IsDisable &&
                        !questionnairePagePageState?.IsHideSkipButton &&
                        questionnairePagePageState?.Questionnaire
                          ?.ReferenceID === 0 ? (
                          <Button
                            className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setQuestionTransition(forwardTransition);
                              onClickSaveBtn(
                                EnumInspectionQuestionStatus.Skipped
                              );
                            }}
                          >
                            Skip
                          </Button>
                        ) : (
                          <>
                            {!questionnairePagePageState?.PageMode.IsDisable &&
                            questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestionList?.length > 1 &&
                            questionnairePagePageState?.Questionnaire
                              ?.ReferenceID > 0 ? (
                              <Button
                                className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                  setQuestionTransition(forwardTransition);
                                  onClickSaveBtn(
                                    EnumInspectionQuestionStatus.Skipped
                                  );
                                }}
                              >
                                Skip
                              </Button>
                            ) : (
                              <>
                                {!questionnairePagePageState?.PageMode
                                  .IsDisable &&
                                  questionnairePagePageState.YesNoAnswer ===
                                    "" &&
                                  questionnairePagePageState.Questionnaire
                                    .InspectionQuestion?.NotApplicableAnswer ==
                                    null && (
                                    <Button
                                      className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                                      variant="contained"
                                      disableElevation
                                      onClick={() => {
                                        setQuestionTransition(
                                          forwardTransition
                                        );
                                        onClickSaveBtn(
                                          EnumInspectionQuestionStatus.Skipped
                                        );
                                      }}
                                    >
                                      Skip
                                    </Button>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <ViewInspectionQuestionHistory />
      <Grid item xs={12} lg={12} md={12} xl={12}>
        <Modal
          className="modal-outter modal-md"
          open={openRejectPopup}
          onClose={handleRejectPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modal-header">
              <h1>Reason for Rejection</h1>
            </div>
            <div className="modal-content p-b-5">
              <Grid container className="">
                <Grid item xs={12} className="">
                  <div className="form-group w-100 mb-0 textinput-popup mt-10">
                    <HPRTextBox
                      Id="reasonForRejection"
                      Name="reasonForRejection"
                      Label={"Reason for Rejection"}
                      Required={true}
                      TextLength={100}
                      InputProps={{ maxLength: 100 }}
                      ComponentProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => {
                                onChangeRejectReason("reasonForRejection", "");
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      ShowTextCounter={true}
                      Value={rejectReason ?? ""}
                      Type={EnumTextBoxType.Text}
                      Disabled={false}
                      onTextBoxChange={onChangeRejectReason}
                      Validator={validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      ShowValidMsg={true}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="modal-button-bar">
              <Button
                className="secondary-btn-small"
                variant="outlined"
                size="small"
                startIcon={<CloseCircle variant="Outline" />}
                disableElevation
                onClick={handleRejectPopupClose}
              >
                Discard
              </Button>
              <Button
                className="primary-btn-small"
                variant="contained"
                size="small"
                startIcon={<ShieldTick variant="Outline" />}
                disableElevation
                onClick={onClickQuestionAnswerRejectBtn}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default QuestionnairePage;
