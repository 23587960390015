import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import FormControlLabel from "@mui/material/Box";

import { useEffect, useRef, useState } from "react";
import "../../layouts/main-layout/audit-view-main.scss";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import { CloseCircle, Edit, GlobalEdit, ArrowCircleDown } from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  EnumInspectionStatus,
  EnumListShowRowCountList,
  EnumPageMode,
  EnumRecurrencepattern,
} from "../../../common/enums/common.enums";
import HPRDatePicker from "../../../components/common/HPRDatePicker/HPRDatePicker";
import { EnumDateType } from "../../../components/common/HPRDatePicker/HPRDatePicker.enum";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import HPRRadioButton from "../../../components/common/HPRRadioButton/HPRRadioButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AssignInspectionPageParametersType,
  AssignInspectionPageType,
} from "./AssignInspectionPage.types";
import {
  AssignInspectionDTO,
  DropDownDTO,
  InspectionAssignmentSmeDTO,
  InspectionDTO,
  InspectionMemberDTO,
  InspectionQuestionDTO,
  InspectionSetDTO,
  InspectionStandardDTO,
  InspectionStatusDTO,
  SiteDTO,
  StandardSmeDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";
import {
  getInspectionDetails,
  getInspectionStandardList,
  saveInspectionDetails,
} from "./AssignInspectionPage.action";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import HPRLinedTextArea from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea";
import { EnumLinedTextAreaType } from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea.enum";
import dayjs, { Dayjs } from "dayjs";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import HPRDropDownMsGraphMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMsGraphMultipleSelect";
import {
  resetInspectionStandard,
  setInitPageData,
} from "./AssignInspectionPage.reducer";

interface OccurrenceData {
  recurringDates: string[];
  endDate: string;
}

const AssignInspectionPage = (props: PageRegistrationProperties) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  // use for page navigation
  const navigate = useNavigate();

  // set initial state values for Assign inspection
  const initialState = {
    Inspection: {
      Id: 0,
      ModifiedBy: "",
      ModifiedDate: "",
      Version: 0,
      SiteId: 0,
      InspectionSetId: 0,
      Name: "",
      Description: "",
      Status: 0,
      InspectionStatusId: 0,
      CreatedDate: "",
      CreatedBy: "",
      StartedDate: "",
      StartedBy: "",
      LastCompletedDate: "",
      LastCompletedBy: "",
      PlannedCompletionDate: "",
      AllocatedTime: "",
      InspectionSet: {} as InspectionSetDTO,
      InspectionStatus: {} as InspectionStatusDTO,
      Site: {} as SiteDTO,
      InspectionMembers: [] as Array<InspectionMemberDTO>,
      InspectionQuestions: [] as Array<InspectionQuestionDTO>,
      InspectionStandards: [] as Array<InspectionStandardDTO>,
      SaveAction: 0,
      SiteList: [] as Array<DropDownDTO>,
      InspectionList: [] as Array<DropDownDTO>,
      Recurring: false,
      RecurringPattenId: null,
      OccurrenceCount: 0,
      EndBy: "",
      InspectionSetList: [] as Array<DropDownDTO>,
      EventSuccess: false,
      RecurringDates: [] as Array<string>,
      SelectedPage: 0,
      // InspectionStandardsList: [] as Array<InspectionStandardEmployeeDTO>,
      ParentId: null,
    } as AssignInspectionDTO,
    IsLoading: false,
    PageMode: {
      IsDisable: false,
      IsShowSaveBtn: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      PageMode: EnumPageMode.Default,
    } as PageModeType,

    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
      validators: {
        plannedCompletionDate: {
          message: "This field is required",
          rule: (val, params) => {
            return true;
          },
        },
      },
    }),
    ShowErrorMsg: false,
  } as AssignInspectionPageType;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const parameter: AssignInspectionPageParametersType = location.state;
  const assignInspectionState = useAppSelector(
    (state) => state.assignInspectionReducer
  );
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (!dataFetchedRef.current) {
      dataFetchedRef.current = true;

      dispatch(setInitPageData(initialState));
      setAssignInspectionPageState(initialState);
    }

    if (parameter.InspectionId > 0) {
      dispatch(
        getInspectionDetails({
          Id: parameter.InspectionId,
          SelectedPage: 0,
          ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        } as InspectionDTO)
      );
    } else {
      dispatch(
        getInspectionDetails({
          Id: 1,
          SelectedPage: 0,
          ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        } as InspectionDTO)
      );
    }
  }, []);

  const [editDisabled, setEditDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      assignInspectionState.Inspection.InspectionStatusId ==
      EnumInspectionStatus.Completed
    ) {
      setEditDisabled(true);
    } else {
      setEditDisabled(false);
    }
  }, [assignInspectionState.Inspection.InspectionStatusId]);

  //On back button click
  function BackOnClickFunction() {
    //hide validation message
    assignInspectionPageState.Validator.hideMessages();

    navigate("/inspectionAssignment");
  }

  const [updateClicked, setUpdateClicked] = useState(false);
  const [assignInspectionPageState, setAssignInspectionPageState] =
    useState(initialState);

  // page unload validations
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    // YesBtnClick() {
    // },
  } as UsePromptTypes;
  usePrompt(prompt);
  //-----Button functions-----//

  //check validation
  const assignInspectionPagevalidation = (): boolean => {
    let changesExsit = true;

    if (
      assignInspectionPageState.Inspection.Name !==
        assignInspectionState.Inspection.Name ||
      assignInspectionPageState.Inspection.SiteId !==
        assignInspectionState.Inspection.SiteId ||
      assignInspectionPageState.Inspection.InspectionSetId !==
        assignInspectionState.Inspection.InspectionSetId ||
      assignInspectionPageState.Inspection.PlannedCompletionDate !==
        assignInspectionState.Inspection.PlannedCompletionDate ||
      assignInspectionPageState.Inspection.InspectionStandardsList !==
        assignInspectionState.Inspection.InspectionStandardsList ||
      JSON.stringify(
        assignInspectionPageState?.Inspection?.InspectionStandards
      ) !==
        JSON.stringify(assignInspectionState?.Inspection?.InspectionStandards)
    ) {
      changesExsit = false;
    }
    setIsDirty(!changesExsit);
    return changesExsit;
  };

  //check page validation
  useEffect(() => {
    assignInspectionPagevalidation();
  }, [assignInspectionPageState]);

  const IsPageValid = (): boolean => {
    let isValid = assignInspectionPageState.Validator.allValid();
    if (!isValid) {
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !assignInspectionPageState.ShowErrorMsg,
        };
      });

      //show validation message
      assignInspectionPageState.Validator.showMessages();

      return isValid;
    } else {
      //hide validation message
      assignInspectionPageState.Validator.hideMessages();
    }

    return isValid;
  };

  useEffect(() => {
    const updatedInspectionStandardList =
      assignInspectionState.Inspection?.InspectionStandards?.map(
        (inspectionStandard: InspectionStandardDTO) => {
          const prveinspectionStandard =
            assignInspectionPageState.Inspection?.InspectionStandards?.find(
              (find) => find.Id === inspectionStandard.Id
            );

          let inspectionAssignmentSmeList: Array<InspectionAssignmentSmeDTO> =
            prveinspectionStandard?.InspectionAssignmentSmes ?? [];
          //if not change dropdown
          if (
            !inspectionAssignmentSmeList ||
            inspectionAssignmentSmeList?.length === 0
          ) {
            inspectionAssignmentSmeList =
              inspectionStandard.InspectionAssignmentSmes;
          }

          return {
            ...inspectionStandard,
            InspectionAssignmentSmes: inspectionAssignmentSmeList,
          } as InspectionStandardDTO;
        }
      );

    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...assignInspectionState.Inspection,
          InspectionStandards: updatedInspectionStandardList,
        },
      };
    });
  }, [assignInspectionState]);

  const onNameChange = (name: string, value: any) => {
    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          Name: value,
        },
      };
    });
  };

  const onSiteDropDownChange = (
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string
  ) => {
    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          SiteId: parseInt(selectedValue),
          SiteList: selectOption,
        },
      };
    });
  };

  const onInspectionSetDropDownChange = (
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string
  ) => {
    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          InspectionSetId: parseInt(selectedValue),
          InspectionList: selectOption,
        },
      };
    });
  };

  const [recurrenceDates, setRecurrenceDates] = useState<string[]>(
    [] as string[]
  );

  const onPlannedCompletionDateChange = (name: string, value: any) => {
    const newPlannedCompletionDate: Dayjs | null = dayjs(value);
    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          PlannedCompletionDate: convertDateFormatToString(value),
          AllocatedTime: newPlannedCompletionDate.isValid()
            ? dayjs(new Date(value)).format("YYYY-MM-DD")
            : values.Inspection?.AllocatedTime,
        },
      };
    });
  };
  const [recurringEnabled, setRecurringEnabled] = useState<boolean>(false);
  const [endAfterDisabled, setEndAfterDisabled] = useState<boolean>(false);
  const onRecurringChange = (name: string, value: any) => {
    setRecurringEnabled(value);
    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          Recurring: value,
        },
      };
    });
  };

  const onRecurrencePatternChange = (Name: string, Checked: boolean) => {
    if (Name === "Anually") {
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            RecurringPattenId: EnumRecurrencepattern.Annually,
          },
        };
      });
    } else if (Name === "Quaterly") {
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            RecurringPattenId: EnumRecurrencepattern.Quaterly,
          },
        };
      });
    } else {
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            RecurringPattenId: EnumRecurrencepattern.Annually,
          },
        };
      });
    }
  };

  const onOccurenceChange = (name: string, value: number) => {
    let endDate: Date;
    let ocData: OccurrenceData;
    if (
      assignInspectionPageState.Inspection.RecurringPattenId ==
      EnumRecurrencepattern.Annually
    ) {
      ocData = getAnnualOccurrenceDatesWithEndDateFromCount(
        new Date(assignInspectionPageState.Inspection.PlannedCompletionDate),
        value
      );
      setRecurrenceDates(ocData.recurringDates);
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            RecurringDates: recurrenceDates,
            EndBy: ocData.endDate.trimEnd(),
            Recurring: true,
          },
        };
      });
    } else if (
      assignInspectionPageState.Inspection.RecurringPattenId ==
      EnumRecurrencepattern.Quaterly
    ) {
      ocData = getQuarterlyOccurrenceDatesWithEndDateFromCount(
        new Date(assignInspectionPageState.Inspection.PlannedCompletionDate),
        value
      );
      setRecurrenceDates(ocData.recurringDates);
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            RecurringDates: recurrenceDates,
            EndBy: ocData.endDate.trimEnd(),
            Recurring: true,
          },
        };
      });
    } else {
      return;
    }

    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          OccurrenceCount: value,
        },
      };
    });
  };

  const onEndByChange = (name: string, value: any) => {
    const isoDate = new Date(value).toISOString();
    const fixedDate = convertToLocalDateTime(isoDate);
    if (
      assignInspectionPageState.Inspection.RecurringPattenId ==
      EnumRecurrencepattern.Annually
    ) {
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            EndBy: fixedDate,
            OccurrenceCount: calculateAnnualOccurrences(
              new Date(
                assignInspectionPageState.Inspection.PlannedCompletionDate
              ),
              new Date(value)
            ),
          },
        };
      });
    } else if (
      assignInspectionPageState.Inspection.RecurringPattenId ==
      EnumRecurrencepattern.Quaterly
    ) {
      setAssignInspectionPageState((values) => {
        return {
          ...values,
          Inspection: {
            ...values.Inspection,
            EndBy: fixedDate,
            OccurrenceCount: calculateQuarterlyOccurrences(
              new Date(
                assignInspectionPageState.Inspection.PlannedCompletionDate
              ),
              new Date(value)
            ),
          },
        };
      });
    }

    /* onOccurenceChange("Occurrence",calculateAnnualOccurrences(
         new Date(assignInspectionPageState.Inspection.PlannedCompletionDate)    ,
         new Date(value)
         ));*/
  };

  function convertDateFormatToString(dateStr: string): string {
    if (dateStr === null || dateStr === "") {
      return null;
    }
    const [day, month, year] = dateStr.split("-");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = months.indexOf(month) + 1;
    return `${year}-${monthIndex.toString().padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
  }

  const handleDropDownListChange = (event: string, index: number) => {
    let newInspectionStandardArr = [
      ...assignInspectionPageState.Inspection.InspectionStandardsList,
    ];

    const updatedInspectionStandardObject = {
      ...newInspectionStandardArr[index],
      SelectedEmployeeId: parseInt(event),
    };

    newInspectionStandardArr[index] = updatedInspectionStandardObject;
    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          InspectionStandardsList: newInspectionStandardArr,
        },
      };
    });
  };

  const onClickUpdate = () => {
    if (IsPageValid()) {
      dispatch(
        saveInspectionDetails({
          ...assignInspectionPageState.Inspection,
          SelectedPage: 0,
          ListPageSize:
            assignInspectionPageState.Inspection?.InspectionStandards?.length,
          EndBy: null,
        })
      );
      setReadOnly(true);
      setUpdateClicked(true);
      setIsDirty(false);
    }
  };

  useEffect(() => {
    if ((assignInspectionState.Inspection.EventSuccess, updateClicked)) {
      setReadOnly(true);
    }
  }, [assignInspectionState.Inspection.EventSuccess]);

  const IsDisabledDate = (date: dayjs.Dayjs) => {
    return date && date.isBefore(dayjs(), "day");
  };

  const onClickCancelButton = () => {
    //hide validation message
    assignInspectionPageState.Validator.hideMessages();

    if (
      assignInspectionPageState.Inspection.Name !==
        assignInspectionState.Inspection.Name ||
      assignInspectionPageState.Inspection.SiteId !==
        assignInspectionState.Inspection.SiteId ||
      assignInspectionPageState.Inspection.InspectionSetId !==
        assignInspectionState.Inspection.InspectionSetId ||
      assignInspectionPageState.Inspection.PlannedCompletionDate !==
        assignInspectionState.Inspection.PlannedCompletionDate ||
      assignInspectionPageState.Inspection.InspectionStandardsList !==
        assignInspectionState.Inspection.InspectionStandardsList ||
      JSON.stringify(
        assignInspectionPageState?.Inspection?.InspectionStandards
      ) !==
        JSON.stringify(assignInspectionState?.Inspection?.InspectionStandards)
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard the changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            if (parameter.InspectionId > 0) {
              dispatch(resetInspectionStandard());

              dispatch(
                getInspectionDetails({
                  Id: parameter.InspectionId,
                  SelectedPage: 0,
                  ListPageSize:
                    EnumListShowRowCountList.ListShowRowCountPerPage_2,
                } as InspectionDTO)
              );
            }
            setReadOnly(true);
            setIsDirty(false);
          },
        } as PopupProps)
      );
    } else {
      if (parameter.InspectionId > 0) {
        dispatch(resetInspectionStandard());
        dispatch(
          getInspectionDetails({
            Id: parameter.InspectionId,
            SelectedPage: 0,
            ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
          } as InspectionDTO)
        );
      }
      setReadOnly(true);
      setIsDirty(false);
    }
  };

  function getAnnualOccurrenceDatesWithEndDateFromCount(
    startDate: Date,
    occurrenceCount: number
  ): OccurrenceData {
    const result: string[] = [];
    for (let i = 0; i < occurrenceCount; i++) {
      result.push(
        new Date(
          startDate.getFullYear() + i + 1,
          startDate.getMonth(),
          startDate.getDate() + 1
        )
          .toISOString()
          .split("T", 1)[0]
      );
    }
    return {
      recurringDates: result,
      endDate: new Date(
        startDate.getFullYear() + occurrenceCount + 1,
        startDate.getMonth(),
        startDate.getDate() + 1
      )
        .toISOString()
        .split("T", 1)[0],
    };
  }

  function getQuarterlyOccurrenceDatesWithEndDateFromCount(
    startDate: Date,
    occurrenceCount: number
  ): OccurrenceData {
    const result: string[] = [];
    for (let i = 0; i < occurrenceCount; i++) {
      result.push(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 3 * i + 3,
          startDate.getDate() + 1
        )
          .toISOString()
          .split("T", 1)[0]
      );
    }
    return {
      recurringDates: result,
      endDate: new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 3 * occurrenceCount + 3,
        startDate.getDate() + 1
      )
        .toISOString()
        .split("T", 1)[0],
    };
  }

  function convertToLocalDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return date
      .toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .replace(/\s/g, "-");
  }

  function calculateAnnualOccurrences(
    plannedCompletionDate: Date,
    endDate: Date
  ): number {
    let years = Math.abs(
      endDate.getFullYear() - plannedCompletionDate.getFullYear()
    );

    if (plannedCompletionDate > endDate) {
      if (plannedCompletionDate.getMonth() > endDate.getMonth()) {
        years--;
      } else if (plannedCompletionDate.getMonth() === endDate.getMonth()) {
        if (plannedCompletionDate.getDate() >= endDate.getDate()) {
          years--;
        }
      }
    } else {
      if (endDate.getMonth() > plannedCompletionDate.getMonth()) {
        years--;
      } else if (endDate.getMonth() === plannedCompletionDate.getMonth()) {
        if (endDate.getDate() >= plannedCompletionDate.getDate()) {
          years--;
        }
      }
    }
    // console.log({ date1: plannedCompletionDate, date2: endDate, years });
    if (years > 0) {
      return years;
    } else {
      return 0;
    }
  }

  function calculateQuarterlyOccurrences(
    plannedCompletionDate: Date,
    endDate: Date
  ): number {
    let quarters = Math.abs(
      (endDate.getFullYear() - plannedCompletionDate.getFullYear()) * 4 +
        (endDate.getMonth() - plannedCompletionDate.getMonth()) / 3
    );

    if (plannedCompletionDate > endDate) {
      if (plannedCompletionDate.getMonth() > endDate.getMonth()) {
        quarters--;
      } else if (plannedCompletionDate.getMonth() === endDate.getMonth()) {
        if (plannedCompletionDate.getDate() >= endDate.getDate()) {
          quarters--;
        }
      }
    } else {
      if (endDate.getMonth() > plannedCompletionDate.getMonth()) {
        quarters--;
      } else if (endDate.getMonth() === plannedCompletionDate.getMonth()) {
        if (endDate.getDate() >= plannedCompletionDate.getDate()) {
          quarters--;
        }
      }
    }
    const returnValue = Math.floor(quarters);
    if (returnValue > 0) {
      return returnValue;
    } else {
      return 0;
    }
  }

  const onClickSeeMoreInspectionStandardBtn = () => {
    dispatch(
      getInspectionStandardList({
        Id: parameter?.InspectionId ?? 0,
        SelectedPage:
          assignInspectionPageState?.Inspection?.InspectionStandards?.length,
        ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
      } as InspectionDTO)
    );
  };

  const onChangeInspectionStandardDropDown = (
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>,
    selectedInspectionStandard: InspectionStandardDTO
  ) => {
    const UpdatedInspectionStandard: Array<InspectionStandardDTO> =
      assignInspectionPageState.Inspection.InspectionStandards?.map(
        (inspectionStandard: InspectionStandardDTO) => {
          if (inspectionStandard.Id === selectedInspectionStandard.Id) {
            return {
              ...inspectionStandard,
              InspectionAssignmentSmes: selectedValues.map((sme) => {
                return {
                  InspectionStandardId: inspectionStandard.Id,
                  Smeid: parseInt(sme),
                } as InspectionAssignmentSmeDTO;
              }),
            };
          }

          return inspectionStandard;
        }
      );

    setAssignInspectionPageState((values) => {
      return {
        ...values,
        Inspection: {
          ...values.Inspection,
          InspectionStandards: UpdatedInspectionStandard,
        },
      };
    });
  };

  const onClickEditBtn = () => {
    setUpdateClicked(false);
    setReadOnly(false);
  };

  return (
    <>
      {/* Global Header with Status & Version */}
      <Grid container columns={12} className="">
        {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>Inspection Assignment: Detailed View</h1>
        </Grid> */}

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        ></Grid>
      </Grid>
      {/* Global Header with Status & Version  End*/}
      <div className="content-section-card top-main-padding">
        {/* Global Sub Header with  Buttons*/}
        <Grid
          container
          columns={12}
          className="global-subhead-btn-wrap top-main-border-inner"
        >
          <Grid item className="global-subhead-wrap ai-heading-grid">
            {/* <IconButton
              onClick={BackOnClickFunction}
              aria-label="back"
              className="sub-head-back-btn"
            >
              <ArrowLeft2 size="24" variant="Outline" />
            </IconButton> */}
            {/* <div className="" > */}
            <Button
              aria-label="back"
              className="assignInspection-back-btn sub-head-back-btn back"
              onClick={BackOnClickFunction}
              title="Back to home page"
            >
              {/* <ArrowLeft2 size="24" variant="Outline" /> */}
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color">Back</span>
            </Button>
            <h2></h2>
            <Grid className="truncate-ai-heading">
              <h1 className="truncate-ai-heading">
                <>Inspection Assignment:</>
                <Tooltip
                  title={assignInspectionState.Inspection?.Name ?? ""}
                  placement="bottom-start"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, 18],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <span>
                    {" "}
                    {` ${assignInspectionState.Inspection?.Name ?? ""}`}
                  </span>
                </Tooltip>
              </h1>
              {/* <h1 className="truncate-ai-heading">{`Inspection Assignment: ${
                  assignInspectionState.Inspection?.Name ?? ""
                }`}</h1> */}
            </Grid>
            {/* </div> */}
          </Grid>

          <Grid item className="sub-header-btn-wrap">
            {readOnly ? (
              <>
                <Button
                  className="secondary-btn-small"
                  size="small"
                  variant="outlined"
                  startIcon={<CloseCircle variant="Outline" />}
                  onClick={BackOnClickFunction}
                >
                  Close
                </Button>
                {assignInspectionPageState.Inspection.InspectionStatusId ==
                EnumInspectionStatus.Completed ? (
                  <></>
                ) : (
                  <Button
                    className="primary-btn-small"
                    size="small"
                    variant="outlined"
                    onClick={onClickEditBtn}
                    startIcon={<Edit variant="Outline" />}
                    disabled={editDisabled}
                  >
                    Edit
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  className="secondary-btn-small"
                  size="small"
                  variant="outlined"
                  startIcon={<CloseCircle variant="Outline" />}
                  onClick={onClickCancelButton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  startIcon={<GlobalEdit variant="Outline" />}
                  className="primary-btn-small"
                  size="small"
                  onClick={onClickUpdate}
                >
                  Update
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} lg={2}></Grid>

          <Grid item xs={8} lg={8} md={8}>
            <FormGroup>
              <FormControl>
                <div className="form-group mb-0">
                  <HPRTextBox
                    Name="inspection-name"
                    Value={assignInspectionPageState.Inspection.Name}
                    onTextBoxChange={onNameChange}
                    Label="Inspection Name"
                    Type={EnumTextBoxType.Text}
                    TextLength={250}
                    ShowTextCounter={true}
                    InputProps={{ maxLength: 250 }}
                    Disabled={readOnly}
                    Required={true}
                    Rules={"required"}
                    Validator={assignInspectionPageState.Validator}
                    IsEnableValidator={true}
                  ></HPRTextBox>
                </div>
              </FormControl>
              <FormControl>
                <div className="form-group">
                  <HPRTextBox
                    Label="Site"
                    Name={"site"}
                    Value={
                      assignInspectionPageState?.Inspection?.Site?.SiteName
                    }
                    Disabled
                    Type={EnumTextBoxType.Text}
                    Required
                  ></HPRTextBox>
                  {/* <HPRDropDownSingleSelect
                    Label="Site"
                    DefaultValue="Site 01"
                    Name="site"
                    onDropDownChange={onSiteDropDownChange}
                    Select={assignInspectionPageState.Inspection.SiteId}
                    Items={assignInspectionPageState.Inspection.SiteList}
                    IsSearchable={true}
                    Disabled={
                      // readOnly ||
                      // assignInspectionState.Inspection.InspectionStatusId >
                      //   EnumInspectionStatus.Scheduled
                      true
                    }
                    Required={true}
                    Rules={"required"}
                    IsEnableValidator={true}
                    Validator={assignInspectionPageState.Validator}
                  ></HPRDropDownSingleSelect> */}
                </div>
              </FormControl>
              <FormControl>
                <div className="form-group">
                  <HPRTextBox
                    Label="Inspection Set"
                    Name={"inspectionSet"}
                    Value={
                      assignInspectionPageState?.Inspection?.InspectionSet?.Name
                    }
                    Disabled
                    Type={EnumTextBoxType.Text}
                    Required
                  ></HPRTextBox>
                  {/* <HPRDropDownSingleSelect
                    Label="Inspection Set"
                    DefaultValue="Site 01"
                    Name="site1"
                    onDropDownChange={onInspectionSetDropDownChange}
                    Select={
                      assignInspectionPageState.Inspection.InspectionSetId
                    }
                    Items={
                      assignInspectionPageState.Inspection.InspectionSetList
                    }
                    IsSearchable={true}
                    Disabled={
                      // readOnly ||
                      // assignInspectionState.Inspection.InspectionStatusId >
                      //   EnumInspectionStatus.Scheduled
                      true
                    }
                    Required={true}
                    Rules={"required"}
                    IsEnableValidator={true}
                    Validator={assignInspectionPageState.Validator}
                  ></HPRDropDownSingleSelect> */}
                </div>
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="form-group planned-date mb-0">
                    <HPRDatePicker
                      Label={"Planned Completion Date"}
                      Value={
                        assignInspectionPageState.Inspection
                          .PlannedCompletionDate
                      }
                      DefaultValue={
                        dayjs(
                          assignInspectionPageState.Inspection.AllocatedTime
                        ).isValid() &&
                        dayjs(
                          assignInspectionPageState.Inspection.AllocatedTime
                        )?.year() !== dayjs().year()
                          ? dayjs(
                              assignInspectionPageState.Inspection.AllocatedTime
                            ).toString()
                          : dayjs().toString()
                      }
                      Name={"plannedCompletionDate"}
                      onDateChange={onPlannedCompletionDateChange}
                      IsDisabledDate={IsDisabledDate}
                      Disabled={
                        readOnly ||
                        // (assignInspectionState.Inspection.InspectionStatusId !==
                        //   EnumInspectionStatus.NotPlanned &&
                        //   assignInspectionState.Inspection
                        //     .InspectionStatusId !==
                        //     EnumInspectionStatus.Scheduled &&
                        //   assignInspectionState.Inspection
                        //     .InspectionStatusId !==
                        //     EnumInspectionStatus.Overdue)
                        // assignInspectionState.Inspection.InspectionStatusId >
                        //   EnumInspectionStatus.Scheduled
                        (assignInspectionPageState.Inspection?.StartedDate &&
                          assignInspectionPageState.Inspection?.StartedDate
                            ?.length > 0)
                      }
                      Required={true}
                      Type={EnumDateType.DateOnly}
                    ></HPRDatePicker>
                    <span className="date-validation">
                      {assignInspectionPageState.Validator.message(
                        "This field is required",
                        assignInspectionPageState.Inspection
                          .PlannedCompletionDate,
                        "required"
                      )}
                    </span>
                  </div>
                </Grid>
              </Grid>

              {/* {assignInspectionPageState?.Inspection?.InspectionStandards
                ?.length > 0 && (
                <fieldset className="field-sme-assignment">
                  <legend>Global Standard SME Assignment</legend>
                  <div className="form-group sme-assignment mb-0">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        xl={12}
                        className="pl-0"
                      >
                        <div className="Grid ml-5">
                          { Grid Header }
                          <div className="quetion-grid-head-assigninspection mb-20 ml-10 tab-view-grid-head-assigninspection">
                            <Grid container>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <div>Standard</div>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div>Global Standard SME(s)</div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>

                        <div className="form-group mt-20 pt-10 pl-15 mb-10">
                          {assignInspectionPageState?.Inspection?.InspectionStandards?.map(
                            (
                              inspectionStandard: InspectionStandardDTO,
                              index: number
                            ) => {
                              return (
                                <Grid
                                  container
                                  key={`inspectionStandard_${index}`}
                                >
                                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <div className="inline-element pl-10 pt-10">
                                      <span>
                                        {inspectionStandard?.Standard?.Name}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <div className={"form-group"}>
                                      <div className="inline-element multi-scroll">
                                        <HPRDropDownMsGraphMultipleSelect
                                          Label="Select Global SME(s) of the standard"
                                          //DefaultValue="Select Global SME(s) of the standard"
                                          Name={`inspectionStandard_${index}`}
                                          Select={inspectionStandard?.InspectionAssignmentSmes?.map(
                                            (
                                              inspectionAssignmentSme: InspectionAssignmentSmeDTO
                                            ) => {
                                              return inspectionAssignmentSme?.Smeid?.toString();
                                            }
                                          )}
                                          Items={
                                            inspectionStandard?.Standard?.StandardSmes?.map(
                                              (standardSme: StandardSmeDTO) => {
                                                return {
                                                  Value:
                                                    standardSme?.Sme?.Id?.toString(),
                                                  Label:
                                                    standardSme?.Sme
                                                      ?.DisplayName,
                                                } as DropDownDTO;
                                              }
                                            ) ?? []
                                          }
                                          IsSearchable={true}
                                          Disabled={
                                            readOnly ||
                                            (assignInspectionState.Inspection
                                              .InspectionStatusId !==
                                              EnumInspectionStatus.NotPlanned &&
                                              assignInspectionState.Inspection
                                                .InspectionStatusId !==
                                                EnumInspectionStatus.Scheduled &&
                                              assignInspectionState.Inspection
                                                .InspectionStatusId !==
                                                EnumInspectionStatus.Overdue)
                                          }
                                          Rules={"required"}
                                          IsEnableValidator={true}
                                          Validator={
                                            assignInspectionPageState.Validator
                                          }
                                          onMultipleSelectDropDownChange={(
                                            selectOptions: Array<DropDownDTO>,
                                            selectedValues: Array<string>
                                          ) =>
                                            onChangeInspectionStandardDropDown(
                                              selectOptions,
                                              selectedValues,
                                              inspectionStandard
                                            )
                                          }
                                        ></HPRDropDownMsGraphMultipleSelect>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {assignInspectionPageState?.Inspection?.InspectionStandards
                    ?.length <
                    assignInspectionPageState?.Inspection
                      .TotalNoOfInspectionStandardCount && (
                    <div className="btm-legend">
                      <Button
                        className="secondary-btn-small"
                        size="small"
                        variant="outlined"
                        endIcon={<ArrowCircleDown variant="Outline" />}
                        onClick={onClickSeeMoreInspectionStandardBtn}
                      >
                        See More
                      </Button>
                    </div>
                  )}
                </fieldset>
              )} */}

              <div className="form-group" style={{ display: "none" }}>
                <Grid container columns={12} spacing={2}>
                  <Grid item lg={8} className="">
                    <div className="inline-element">
                      <HPRCheckBox
                        Id={""}
                        Name={"Recurring"}
                        checked={
                          assignInspectionPageState.Inspection?.Recurring
                            ? assignInspectionPageState.Inspection?.Recurring
                            : false
                        }
                        onCheckBoxChange={onRecurringChange}
                        ClassName={"inline-single-element"}
                        Label="Make Recurring"
                        Disabled={
                          readOnly ||
                          assignInspectionPageState.Inspection?.ParentId !=
                            null ||
                          true
                        }
                      ></HPRCheckBox>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <fieldset style={{ display: "none" }}>
                <legend></legend>
                <fieldset>
                  <legend>Recurrence Pattern</legend>
                  <div className="form-group">
                    <Grid container columns={12}>
                      <Grid item xs={5}>
                        <RadioGroup aria-disabled={recurringEnabled}>
                          <Grid item lg={3} className="inline-2-elements">
                            <FormControlLabel className="default-label">
                              <HPRRadioButton
                                Id={"81"}
                                Name={"Anually"}
                                Value={"Anually"}
                                onRadioButtonChange={onRecurrencePatternChange}
                                ClassName={""}
                                Label="Anually"
                                Disabled={!recurringEnabled || readOnly}
                              ></HPRRadioButton>
                            </FormControlLabel>
                          </Grid>
                          <Grid item lg={3} className="inline-2-elements">
                            <FormControlLabel className="default-label">
                              <HPRRadioButton
                                Id={"82"}
                                Name={"Quaterly"}
                                onRadioButtonChange={onRecurrencePatternChange}
                                Value={"Quaterly"}
                                ClassName={""}
                                Label="Quaterly"
                                Disabled={!recurringEnabled || readOnly}
                              ></HPRRadioButton>
                            </FormControlLabel>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={7}>
                        <HPRLinedTextArea
                          Id={"Recurrence-dates"}
                          Label={"Recurrence Dates"}
                          Name={"Recurrence-dates"}
                          Value={recurrenceDates.join("@@")}
                          ClassName={""}
                          Disabled={true}
                          Type={EnumLinedTextAreaType.Text}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </fieldset>
                <fieldset className="">
                  <legend>Range of Recurrence</legend>
                  <RadioGroup aria-disabled={!recurringEnabled}>
                    <Grid container columns={12}>
                      <Grid item lg={3} className="form-group-sub">
                        <HPRRadioButton
                          Id={"85"}
                          Name={"End-after"}
                          Value={"End-after"}
                          ClassName={""}
                          Label={"End after"}
                          Disabled={!recurringEnabled || readOnly}
                          onRadioButtonChange={(name, checked) => {
                            setEndAfterDisabled(false);
                          }}
                        ></HPRRadioButton>
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        className="inline-2-elements form-group-sub"
                      >
                        <div className="inline-element">
                          <HPRTextBox
                            Name="occurence"
                            Value={
                              assignInspectionPageState.Inspection
                                .OccurrenceCount
                                ? assignInspectionPageState.Inspection.OccurrenceCount.toString()
                                : "0"
                            }
                            onTextBoxChange={(a, b) =>
                              onOccurenceChange(a, parseInt(b))
                            }
                            Label=""
                            Type={EnumTextBoxType.Number}
                            Disabled={
                              !recurringEnabled || endAfterDisabled || readOnly
                            }
                          ></HPRTextBox>
                        </div>
                        <div className="inline-element">
                          <span>Occurrence(s)</span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container columns={12}>
                      <Grid item lg={3}>
                        <HPRRadioButton
                          Id={"84"}
                          Name={"End-By"}
                          Value={"End-By"}
                          ClassName={""}
                          Label={"End By"}
                          Disabled={!recurringEnabled || readOnly}
                          onRadioButtonChange={(name, checked) => {
                            setEndAfterDisabled(true);
                          }}
                        ></HPRRadioButton>
                      </Grid>
                      <Grid item lg={6}>
                        <HPRDatePicker
                          Label={""}
                          Value={assignInspectionPageState.Inspection.EndBy}
                          Name={"Endby"}
                          onDateChange={onEndByChange}
                          Type={EnumDateType.DateOnly}
                          Disabled={
                            !recurringEnabled || !endAfterDisabled || readOnly
                          }
                        ></HPRDatePicker>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </fieldset>
              </fieldset>
              {/* <fieldset>
                <legend>Site local SMEs</legend>
                <div className="Grid">
                 

                  <div className="quetion-grid-head-assigninspection mb-20">
                    <Grid container>
                      <Grid item xs={6}>
                        <div>Standard</div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>Site local SME</div>
                      </Grid>
                    </Grid>
                  </div>
                </div> */}

              {/* {assignInspectionPageState.Inspection
                  .InspectionStandardsList ? (
                  <div className="form-group mt-20">
                    {assignInspectionPageState.Inspection.InspectionStandardsList.map(
                      (item, index) => (
                        <Grid key={index} container>
                          <Grid item xs={6}>
                            <div className="inline-element pl-10">
                              <span>{item.Standard.Name}</span>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={"form-group"}>
                              <div className="inline-element">
                                <HPRDropDownSingleSelect
                                  Label="Select a SME"
                                  DefaultValue=""
                                  Name="Pest-control"
                                  IsSearchable={true}
                                  onDropDownChange={(
                                    selectOption,
                                    selectedValue,
                                    name
                                  ) =>
                                    handleDropDownListChange(
                                      selectedValue,
                                      index
                                    )
                                  }
                                  Select={
                                    item.SelectedEmployeeId
                                      ? item.SelectedEmployeeId
                                      : 0
                                  }
                                  Items={item.EmployeeList}
                                  Disabled={readOnly}
                                ></HPRDropDownSingleSelect>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </div>
                ) : (
                  <></>
                )} */}
              {/* </fieldset> */}
            </FormGroup>
          </Grid>
          <Grid item xs={2} lg={2}></Grid>
        </Grid>
      </div>
    </>
  );
};

export default AssignInspectionPage;
