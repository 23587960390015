import { useEffect, useRef, useState, forwardRef } from "react";
import Grid from "@mui/material/Grid";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import noUploadImage from "../../../images/no_upload_image.svg";
import { Avatar, Button, Tooltip } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import {
  ArrowLeft2,
  Bag,
  CloseCircle,
  Edit,
  Eye,
  More,
  InfoCircle,
  Refresh2,
  DirectboxReceive,
  Timer,
  FolderCross,
  TickCircle,
  Slash,
} from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import dayjs from "dayjs";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import HPRDatePicker from "../../../components/common/HPRDatePicker/HPRDatePicker";
import { EnumDateType } from "../../../components/common/HPRDatePicker/HPRDatePicker.enum";
import {
  CapexRequiredOptionDTO,
  CapexStatusDTO,
  DropDownDTO,
  RemediationTrackerTaskCommentDTO,
  RemediationTrackerTaskDTO,
  RemediationTrackerTaskHistoryDTO,
  RemediationTrackerTaskPriorityDTO,
  RemediationTrackerTaskQuestionDTO,
  RemediationTrackerTaskStatusDTO,
  RemediationTrackerTaskWorkFlowReasonDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import jwt_decode from "jwt-decode";

import HPRFileUploaderDND from "../../../components/common/HPRFileUploaderDND/HPRFileUploaderDND";
import { FileUploaderErrorType } from "../../../components/common/HPRFileUploaderDND/HPRFileUploaderDND.types";
import {
  EnumDataTransferStatus,
  EnumFileType,
  EnumIsCapexRequired,
  EnumPageMode,
  EnumPageType,
  EnumRemediationTrackerTaskPriority,
  EnumRemediationTrackerTaskStatus,
  SystemGroupScope,
} from "../../../common/enums/common.enums";
import {
  RemediationTrackerTaskPageParametersType,
  RemediationTrackerTaskPageType,
} from "./RemediationTrackerTaskPage.types";
import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteFilesOnCancel,
  getQuestionsForRemediationTrackerTasks,
  getRemediationTrackerTaskById,
  remediationTrackerTaskFileUpload,
  saveRemediationTrackerTask,
} from "./RemediationTrackerTaskPage.action";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { remediationTrackerTaskReducerActions } from "./RemediationTrackerTaskPage.reducer";
import {
  openPopup,
  updatePopupProps,
} from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";

import { viewInspectionQuestionSliceReducerActions } from "../../components/ViewInspectionQuestion/ViewInspectionQuestion.reducer";
import { RemediationTrackerTasksPageParametersType } from "../RemediationTrackerTasksPage/RemediationTrackerTasksPage.types";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import HPRDropDownMsGraphSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownMsGraphSingleSelecty";
import { viewRemediationTrackerTaskHistorySliceReducerActions } from "../../components/ViewRemediationTrackerTaskHistory/ViewRemediationTrackerTaskHistory.reducer";
import { ViewRemediationTrackerTaskHistoryProps } from "../../components/ViewRemediationTrackerTaskHistory/ViewRemediationTrackerTaskHistory.types";
import { AuthType } from "../../../core/reducers/auth.types";
import { AuthProperties } from "../../../core/hooks/useAuth";
import HPRTextBoxAutoResize from "../../../components/common/HPRTextBox/HPRTextBoxAutoResize";
import HPRDropDownMsGraphMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMsGraphMultipleSelect";
// import { FileUploaderErrorType } from "../../../components/common/HPRFileUploader/HPRFileUploader.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowLeadingZeros
        allowedDecimalSeparators={["%"]}
        thousandSeparator=","
        decimalScale={2}
      />
    );
  }
);
const RemediationTrackerTaskPage = (props: PageRegistrationProperties) => {
  //Register page properties
  usePageRegistration(props);

  //Initial page state
  const initialState = {
    PageMode: {
      IsDisable: true,
      IsShowSaveBtn: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      IsShowDeactivateBtn: false,
      PageMode: EnumPageMode.AddMode,
    } as PageModeType,

    RemediationTrackerTask: {
      TaskName: "",
      ActionOwner: "",
      Status: EnumRemediationTrackerTaskStatus.New,
      Piority: null,
      DueDate: "",
      RemediationTrackerTaskComments:
        [] as Array<RemediationTrackerTaskCommentDTO>,
      RemediationTrackerTaskRejectReason: null,
      RemediationTrackerTaskQuestions:
        [] as Array<RemediationTrackerTaskQuestionDTO>,
      UploadFiles: [] as Array<UploadFileDTO>,
      StatusNavigation: {
        StatusName: "New",
        Description: "New",
      } as RemediationTrackerTaskStatusDTO,
      PiorityNavigation: {
        PriorityName: "",
      } as RemediationTrackerTaskPriorityDTO,
      SelectedActionOwnerId: "0",
      SelectedPriorityId: 0,
      ApproverId: 0,
      ActionOwnerDropDownList: [] as Array<DropDownDTO>,
      PriorityDropDownList: [] as Array<DropDownDTO>,
      CapexStatusDropDownList: [] as Array<DropDownDTO>,
      IsCapexRequiredDropDownList: [] as Array<DropDownDTO>,
      AreaOwnerTypeDropDownList: [] as Array<DropDownDTO>,
      Version: 0.01,
      AreaOwner: null,
      Approver: "",
      IsCapexrequired: null,
      CapexamountRequired: 0.0,
      CapexnumberAndName: "",
      Capexstatus: null,
      CompletedDate: "",
      CompletedBy: "",
      CapexstatusNavigation: {
        StatusName: "",
      } as CapexStatusDTO,
      IsCapexrequiredNavigation: {
        TypeName: "",
      } as CapexRequiredOptionDTO,
      IsMergeTask: false,
      IsInApprovalProcess: false,
      CreatedBy: "",
      CreatedDate: "",
      CompletedById: 0,
      ShowApprovalButton: false,
      ShowRejectButton: false,
      IsSMEApproved: false,
      IsSiteExecutiveApproved: false,
      IsAdmin: false,
      ShowEditButton: false,
      RemediationTrackerTaskHistoryList:
        [] as Array<RemediationTrackerTaskHistoryDTO>,
      CapexplannedYear: null,
      CapexPlannedYearDropDownList: [] as Array<DropDownDTO>,
      PendingApproverDropDownList: [] as Array<DropDownDTO>,
      Ernumber: "",
      ClosureReason: "",
    } as RemediationTrackerTaskDTO,
    TaskComment: "",
    RejectReason: "",
    IsDisable: false,
    ConfirmationPopupOpen: false,
    ErrorPopupOpen: false,
    showDeleteBtn: false,
    HeaderText: "",
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
      validators: {
        dueDate: {
          message: "This field is required",
          rule: (val, params) => {
            return true;
          },
        },
      },
    }),
    DueDateErrorMsg: "This field is required",
    ShowErrorMsg: false,
    ShowDueDateErrorMsg: false,
    ShowMoreComments: false,
    CommentsToShow: [],
    IsOverdue: false,
    SaveByFileUpload: false,
    FilesToBeUploaded: [],
    FilesToBeRemoved: [],
    SaveTaskRunning: false,
    RunSaveTask: false,
    TempTaskToSave: {},
    UpdateThroughFileUpload: false,
    UpdateThroughCommentToggle: false,
    TempFileIdList: [],
    ShowCapexFields: false,
    EditInAddMode: false,
    IsAdmin: false,
    IsUser: false,
    ValidateCapexAmount: false,
    ShowMoreHistory: false,
    HistoryToShow: [],
    UpdateThroughHistoryToggle: false,
    ShowCapexPlannedYear: false,
    TaskDeleted: false,
  } as RemediationTrackerTaskPageType;

  const tokenStore = JSON.parse(
    localStorage.getItem("hpr_token_store") || "null"
  ) as AuthType | null;

  const decoded = jwt_decode(tokenStore?.GeneralToken) as AuthProperties;
  const userScope = parseInt(decoded.UserScope);

  const [remediationTrackerTaskPageState, setRemediationTrackerTaskPageState] =
    useState(initialState);

  const [isDirty, setIsDirty] = useState(false);
  const authState = useAppSelector((state) => state.authReducer);

  const location = useLocation();
  const parameter: RemediationTrackerTaskPageParametersType = location.state;
  const remediationTrackerTaskState = useAppSelector(
    (state) => state.remediationTrackerTaskReducer
  );

  const mainLayoutState = useAppSelector((state) => state.mainLayoutReducer);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (parameter?.PageMode == EnumPageMode.AddMode) {
      dispatch(
        getQuestionsForRemediationTrackerTasks(
          parameter.InspectionQuestionIdList
        )
      );
    } else {
      dispatch(
        getRemediationTrackerTaskById(parameter.RemediationTrackerTaskId)
      );
    }
  }, []);

  useEffect(() => {
    let commentArray = [];
    let showMoreComments = false;
    let isOverdue = false;
    let showCapexFields = true;
    let showCapexPlannedYear = true;
    let historyArray = [];
    let showMoreHistory = false;
    if (
      remediationTrackerTaskState.RemediationTrackerTask.Status != null &&
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.ActionInProgress
    ) {
      if (
        remediationTrackerTaskState.RemediationTrackerTask?.DueDate != null &&
        remediationTrackerTaskState.RemediationTrackerTask?.DueDate !== ""
      ) {
        let isDateOverdue = checkOverdueStatus(
          dayjs(
            remediationTrackerTaskState.RemediationTrackerTask.DueDate
          ).format("YYYY-MM-DD")
        );
        if (isDateOverdue) {
          isOverdue = true;
        } else {
          isOverdue = false;
        }
      }
    }

    // Show/hide CAPEX fields based on isRequired selection

    if (
      remediationTrackerTaskState.RemediationTrackerTask.IsCapexrequired ==
        EnumIsCapexRequired.No ||
      remediationTrackerTaskState.RemediationTrackerTask.IsCapexrequired == null
    ) {
      showCapexFields = false;
      showCapexPlannedYear = false;
    }

    // Show/hide CAPEX planned year based on isRequired selection

    // if (
    //   remediationTrackerTaskState.RemediationTrackerTask.IsCapexrequired ==
    //   EnumIsCapexRequired.YesToBeRequested
    // ) {
    //   showCapexPlannedYear = false;
    // }

    // Display the show more/less comment toggle and the latest comment by default if there is more than one comment
    if (
      !remediationTrackerTaskPageState.ShowMoreComments &&
      remediationTrackerTaskState.RemediationTrackerTask
        .RemediationTrackerTaskComments?.length > 1
    ) {
      showMoreComments = false;
      commentArray = [
        remediationTrackerTaskState.RemediationTrackerTask
          .RemediationTrackerTaskComments[0],
      ];
    } else {
      showMoreComments = true;
    }

    // Display the show more/less history toggle and the latest history by default if there is more than one history
    if (
      !remediationTrackerTaskPageState.ShowMoreHistory &&
      remediationTrackerTaskState.RemediationTrackerTask
        .RemediationTrackerTaskHistoryList?.length > 1
    ) {
      showMoreHistory = false;
      historyArray = [
        remediationTrackerTaskState.RemediationTrackerTask
          .RemediationTrackerTaskHistoryList[0],
      ];
    } else {
      showMoreHistory = true;
    }

    if (
      !remediationTrackerTaskState.UpdateThroughFileUpload &&
      !remediationTrackerTaskState.UpdateThroughCommentToggle &&
      !remediationTrackerTaskState.UpdateThroughHistoryToggle
    ) {
      setRemediationTrackerTaskPageState({
        ...remediationTrackerTaskPageState,

        RemediationTrackerTask: {
          ...remediationTrackerTaskState.RemediationTrackerTask,
          ActionOwnerDropDownList:
            remediationTrackerTaskPageState.RemediationTrackerTask
              ?.ActionOwnerDropDownList?.length === 0
              ? remediationTrackerTaskState.RemediationTrackerTask
                  ?.ActionOwnerDropDownList
              : remediationTrackerTaskPageState.RemediationTrackerTask
                  ?.ActionOwnerDropDownList,
        },
        PageMode: remediationTrackerTaskState.PageMode,

        showDeleteBtn:
          remediationTrackerTaskState.RemediationTrackerTask
            ?.RemediationTrackerTaskQuestions?.length > 1
            ? true
            : false,
        CommentsToShow: showMoreComments
          ? remediationTrackerTaskState.RemediationTrackerTask
              .RemediationTrackerTaskComments
          : commentArray,
        IsOverdue: isOverdue,
        SaveTaskRunning: remediationTrackerTaskState.SaveTaskRunning,
        RunSaveTask: remediationTrackerTaskState.RunSaveTask,
        TempTaskToSave: remediationTrackerTaskState.TempTaskToSave,
        TaskComment: "",
        TempFileIdList: remediationTrackerTaskState.TempFileIdList,
        ShowCapexFields: showCapexFields,
        EditInAddMode: remediationTrackerTaskState.EditInAddMode,
        IsAdmin: remediationTrackerTaskState.IsAdmin,
        IsUser: remediationTrackerTaskState.IsUser,
        HistoryToShow: showMoreHistory
          ? remediationTrackerTaskState.RemediationTrackerTask
              .RemediationTrackerTaskHistoryList
          : historyArray,
        ShowCapexPlannedYear: showCapexPlannedYear,
      });
    } else if (
      !remediationTrackerTaskState.UpdateThroughFileUpload &&
      remediationTrackerTaskState.UpdateThroughCommentToggle
    ) {
      setRemediationTrackerTaskPageState((values) => {
        return {
          ...values,
          PageMode: remediationTrackerTaskState.PageMode,
          CommentsToShow: showMoreComments
            ? remediationTrackerTaskState.RemediationTrackerTask
                .RemediationTrackerTaskComments
            : commentArray,

          HistoryToShow: showMoreHistory
            ? remediationTrackerTaskState.RemediationTrackerTask
                .RemediationTrackerTaskHistoryList
            : historyArray,

          TempFileIdList: remediationTrackerTaskState.TempFileIdList,
        };
      });
    } else if (
      !remediationTrackerTaskState.UpdateThroughFileUpload &&
      remediationTrackerTaskState.UpdateThroughHistoryToggle
    ) {
      setRemediationTrackerTaskPageState((values) => {
        return {
          ...values,
          PageMode: remediationTrackerTaskState.PageMode,
          CommentsToShow: showMoreComments
            ? remediationTrackerTaskState.RemediationTrackerTask
                .RemediationTrackerTaskComments
            : commentArray,

          HistoryToShow: showMoreHistory
            ? remediationTrackerTaskState.RemediationTrackerTask
                .RemediationTrackerTaskHistoryList
            : historyArray,

          TempFileIdList: remediationTrackerTaskState.TempFileIdList,
        };
      });
    } else {
      setRemediationTrackerTaskPageState((values) => {
        return {
          ...values,
          PageMode: remediationTrackerTaskState.PageMode,
          RemediationTrackerTask: {
            ...values.RemediationTrackerTask,
            UploadFiles:
              remediationTrackerTaskState.RemediationTrackerTask.UploadFiles,
            RemovedFileIdList:
              remediationTrackerTaskState.RemediationTrackerTask
                .RemovedFileIdList,
            ActionOwnerDropDownList:
              remediationTrackerTaskPageState.RemediationTrackerTask
                ?.ActionOwnerDropDownList?.length === 0
                ? remediationTrackerTaskState.RemediationTrackerTask
                    ?.ActionOwnerDropDownList
                : remediationTrackerTaskPageState.RemediationTrackerTask
                    ?.ActionOwnerDropDownList,
          },
          CommentsToShow: showMoreComments
            ? remediationTrackerTaskState.RemediationTrackerTask
                .RemediationTrackerTaskComments
            : commentArray,

          HistoryToShow: showMoreHistory
            ? remediationTrackerTaskState.RemediationTrackerTask
                .RemediationTrackerTaskHistoryList
            : historyArray,
          SaveTaskRunning: remediationTrackerTaskState.SaveTaskRunning,
          RunSaveTask: remediationTrackerTaskState.RunSaveTask,
          TempTaskToSave: remediationTrackerTaskState.TempTaskToSave,
          TempFileIdList: remediationTrackerTaskState.TempFileIdList,
        };
      });
    }
  }, [remediationTrackerTaskState]);

  useEffect(() => {
    RemediationTrackerTaskPageValidation();
  }, [remediationTrackerTaskPageState]);

  useEffect(() => {
    if (remediationTrackerTaskPageState.RunSaveTask) {
      let taskToSave = remediationTrackerTaskPageState.TempTaskToSave;

      let filesToBeRemoved: number[] = [];
      if (taskToSave.Status === EnumRemediationTrackerTaskStatus.Cancelled) {
        filesToBeRemoved = getFilesToBeRemoved();
      } else {
        filesToBeRemoved = taskToSave.RemovedFileIdList;
      }

      dispatch(
        saveRemediationTrackerTask(
          {
            ...taskToSave,
            UploadFiles:
              remediationTrackerTaskPageState.RemediationTrackerTask
                .UploadFiles,
            RemovedFileIdList: filesToBeRemoved,
          },
          remediationTrackerTaskState.RemediationTrackerTask.Status !==
            taskToSave.Status
            ? true
            : false
        )
      );
    }
  }, [remediationTrackerTaskPageState.RunSaveTask]);

  // useEffect(() => {
  //   if (
  //     remediationTrackerTaskPageState.RemediationTrackerTask.IsCapexrequired ===
  //       EnumIsCapexRequired.No ||
  //     remediationTrackerTaskPageState.RemediationTrackerTask.IsCapexrequired ==
  //       null
  //   ) {
  //     remediationTrackerTaskPageState.Validator.purgeFields();
  //   }
  // }, [remediationTrackerTaskPageState.RemediationTrackerTask.IsCapexrequired]);

  const IsPageValid = (
    pageMode: EnumPageMode,
    saveStatus: EnumRemediationTrackerTaskStatus,
    prevStatus: EnumRemediationTrackerTaskStatus
  ): boolean => {
    if (pageMode === EnumPageMode.ViewMode) {
      return true;
    } else if (
      saveStatus === EnumRemediationTrackerTaskStatus.New &&
      prevStatus === EnumRemediationTrackerTaskStatus.New
    ) {
      //hide validation message
      remediationTrackerTaskPageState.Validator.hideMessages();
      return true;
    } else if (saveStatus === EnumRemediationTrackerTaskStatus.Cancelled) {
      //hide validation message
      remediationTrackerTaskPageState.Validator.hideMessages();
      return true;
    }

    let isValid = remediationTrackerTaskPageState.Validator.allValid();

    if (!isValid) {
      setRemediationTrackerTaskPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !remediationTrackerTaskPageState.ShowErrorMsg,
        };
      });

      //show validation message
      remediationTrackerTaskPageState.Validator.showMessages();

      return isValid;
    } else {
      //hide validation message
      remediationTrackerTaskPageState.Validator.hideMessages();
    }

    return isValid;
  };

  const checkOverdueStatus = (dateString: string): boolean => {
    let today = dayjs(new Date()).format("YYYY-MM-DD");

    if (dayjs(dateString).isSame(today)) {
      return false;
    } else if (
      dayjs(dateString).isBefore(today)
      // &&
      // remediationTrackerTaskState.RemediationTrackerTask.Status ===
      //   EnumRemediationTrackerTaskStatus.ReviewCompleted
    ) {
      return true;
    }

    return false;
  };

  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    YesBtnClick(event) {
      dispatch(
        remediationTrackerTaskReducerActions.resetRemediationTrackerTask()
      );
    },
  } as UsePromptTypes;

  usePrompt(prompt);

  const handleRejectPopupYesClick = async (
    value: string,
    remediationTrackerTask: RemediationTrackerTaskDTO,
    prevStatus: EnumRemediationTrackerTaskStatus,
    closePopup: () => void
  ) => {
    if (value.trim().length > 0) {
      const remediationTrackerTaskWithRejectReason = {
        ...remediationTrackerTask,
        RemediationTrackerTaskRejectReason: {
          RemediationTrackerTaskId: remediationTrackerTask.Id,
          Comment: value,
          Id: 0,
        } as RemediationTrackerTaskCommentDTO,
      } as RemediationTrackerTaskDTO;

      setIsDirty(false);
      dispatch(
        saveRemediationTrackerTask(remediationTrackerTaskWithRejectReason, true)
      );
      closePopup();
    } else {
      dispatch(
        updatePopupProps({
          ErrorMessage: "Please enter a reason for rejection",
        })
      );
    }
  };

  const handleCancelPopupYesClick = async (
    value: string,
    remediationTrackerTask: RemediationTrackerTaskDTO,
    closePopup: () => void
  ) => {
    if (value != null && value.trim().length > 0) {
      let reasonToBeSaved: RemediationTrackerTaskWorkFlowReasonDTO = {
        RemediationTrackerTaskId: remediationTrackerTask.Id,
        Reason: value.trim(),
        Status: remediationTrackerTask.Status,
      } as RemediationTrackerTaskWorkFlowReasonDTO;

      const remediationTrackerTaskWithCancellationReason = {
        ...remediationTrackerTask,
        RemediationTrackerTaskWorkFlowReasons: [reasonToBeSaved],
      } as RemediationTrackerTaskDTO;

      dispatch(
        saveRemediationTrackerTask(
          remediationTrackerTaskWithCancellationReason,
          true
        )
      );
      closePopup();
    } else {
      dispatch(
        updatePopupProps({
          ErrorMessage: "Please enter a reason for cancellation",
        })
      );
    }
  };

  const handleCloseWithoutApprovalYesClick = async (
    value: string,
    remediationTrackerTask: RemediationTrackerTaskDTO,
    closePopup: () => void
  ) => {
    if (value.trim().length > 0) {
      const remediationTrackerTaskWithClosureReason = {
        ...remediationTrackerTask,
        ClosureReason: value,
      } as RemediationTrackerTaskDTO;

      setIsDirty(false);
      dispatch(
        saveRemediationTrackerTask(
          remediationTrackerTaskWithClosureReason,
          true
        )
      );
      closePopup();
    } else {
      dispatch(
        updatePopupProps({
          ErrorMessage: "Please enter a reason for closure",
        })
      );
    }
  };

  const saveBtnClick = (
    saveStatus: EnumRemediationTrackerTaskStatus,
    isSaveClick: boolean,
    isRejectClick: boolean,
    confirmationMessage: string,
    isApproval: boolean,
    closeWithoutApproval: boolean,
    closeIfERNumberAvailable: boolean,
    cancelTask: boolean
  ) => {
    if (
      IsPageValid(
        remediationTrackerTaskPageState.PageMode.PageMode,
        saveStatus,
        remediationTrackerTaskPageState.RemediationTrackerTask.Status
      )
    ) {
      // if (
      //   !checkOverdueStatus(
      //     remediationTrackerTaskPageState.RemediationTrackerTask.DueDate
      //   )
      // ) {
      let remediationTrackerTask: RemediationTrackerTaskDTO = Object.assign(
        {},
        remediationTrackerTaskPageState.RemediationTrackerTask
      );
      remediationTrackerTask.CloseWithoutApproval = false;
      // Set priority to none if no value is selected
      if (
        remediationTrackerTask.Piority == null ||
        remediationTrackerTask.Piority == 0
      ) {
        remediationTrackerTask.Piority =
          EnumRemediationTrackerTaskPriority.None;
      }

      if (remediationTrackerTask.CapexamountRequired?.toString() == "") {
        remediationTrackerTask.CapexamountRequired = null;
      }

      // Get selected Action Owner
      let selectedSmeId = remediationTrackerTask.SelectedActionOwnerId;
      if (selectedSmeId != null && selectedSmeId !== "0") {
        let selectedSme =
          remediationTrackerTaskPageState.RemediationTrackerTask?.ActionOwnerDropDownList?.find(
            (item) => item.Value?.toString() == selectedSmeId?.toString()
          );

        remediationTrackerTask.ActionOwner = selectedSme?.Label;
      }

      // Get selected Approver
      let selectedApproverId = remediationTrackerTask.ApproverId;
      if (selectedApproverId != null && selectedApproverId > 0) {
        let selectedApprover =
          remediationTrackerTask.ActionOwnerDropDownList.find(
            (item) => item.Value?.toString() == selectedApproverId?.toString()
          );

        remediationTrackerTask.Approver = selectedApprover?.Label;
      }

      //Get current status
      let prevStatus = remediationTrackerTask.Status;

      //Set approval status
      if (
        (prevStatus === EnumRemediationTrackerTaskStatus.ActionInProgress &&
          saveStatus ===
            EnumRemediationTrackerTaskStatus.PendingClosureApproval) ||
        (prevStatus ===
          EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
          isSaveClick)
      ) {
        remediationTrackerTask.IsInApprovalProcess = true;
      } else {
        remediationTrackerTask.IsInApprovalProcess = false;
      }

      //Set the new status of the task
      remediationTrackerTask.Status = saveStatus;

      //Check if any new comment is added and if so, add it to the comment list
      if (remediationTrackerTaskPageState.TaskComment !== "") {
        let commentToBeSaved: RemediationTrackerTaskCommentDTO = {
          RemediationTrackerTaskId:
            remediationTrackerTaskPageState.RemediationTrackerTask.Id,
          Comment: remediationTrackerTaskPageState.TaskComment,
        } as RemediationTrackerTaskCommentDTO;

        remediationTrackerTask.RemediationTrackerTaskComments = [
          commentToBeSaved,
          ...remediationTrackerTask.RemediationTrackerTaskComments,
        ];
      }

      //Set if the user is admin
      remediationTrackerTask.IsAdmin = checkSystemFunctionRight(
        EnumSystemFunctions.RemTrackerTaskPage_SaveTask,
        EnumSystemFunctionTypes.ButtonAccess
      );
      if (isApproval) {
        remediationTrackerTask.CheckSameUserHasMultipleRole = true;
      }
      if (isSaveClick) {
        setIsDirty(false);
        dispatch(saveRemediationTrackerTask(remediationTrackerTask, false));
      } else if (closeWithoutApproval || closeIfERNumberAvailable) {
        remediationTrackerTask.CloseWithoutApproval = true;
        dispatch(
          openPopup({
            Open: true,
            HeaderText: "Reason for Closure",
            LableText: "Reason for Closure",
            InputText: "",
            BodyText: "Are you sure you want to mark the task as Closed?",
            Required: true,
            CancelButtonText: "Discard",
            YesButtonText: "Confirm",
            PopupType: EnumPopupType.TextInputPopup,
            InputTextCount: 100,
            ActionBtnClick: (value, closePopup) => {
              handleCloseWithoutApprovalYesClick(
                value,
                remediationTrackerTask,
                closePopup
              );
            },
          } as PopupProps)
        );
      } else if (cancelTask) {
        dispatch(
          openPopup({
            Open: true,
            HeaderText: "Reason for Cancellation",
            LableText: "Reason for Cancellation",
            InputText: "",
            Required: true,
            CancelButtonText: "Discard",
            YesButtonText: "Confirm",
            PopupType: EnumPopupType.TextInputPopup,
            InputTextCount: 100,
            ActionBtnClick: (value, closePopup) => {
              handleCancelPopupYesClick(
                value ?? "",
                remediationTrackerTask,
                closePopup
              );
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          isRejectClick
            ? openPopup({
                Open: true,
                HeaderText: "Reason for Rejection",
                LableText: "Reason for Rejection",
                InputText: "",
                Required: true,
                CancelButtonText: "Discard",
                YesButtonText: "Confirm",
                PopupType: EnumPopupType.TextInputPopup,
                InputTextCount: 100,
                ActionBtnClick: (value, closePopup) => {
                  handleRejectPopupYesClick(
                    value,
                    remediationTrackerTask,
                    prevStatus,
                    closePopup
                  );
                },
              } as PopupProps)
            : openPopup({
                Open: true,
                BodyText: confirmationMessage,
                HeaderText: "Confirmation",
                PopupType: EnumPopupType.YesNoConfirmation,
                YesBtnClick() {
                  setIsDirty(false);
                  dispatch(
                    saveRemediationTrackerTask(remediationTrackerTask, true)
                  );
                },
              } as PopupProps)
        );
      }
      // } else {
      //   dispatch(
      //     openPopup({
      //       Open: true,
      //       BodyText:
      //         "This task is being overdue. Please change the due date to a future date",
      //       HeaderText: "Error",
      //       PopupType: EnumPopupType.WarningPopup,
      //     } as PopupProps)
      //   );
      // }
    }
  };

  const getFilesToBeRemoved = (): number[] => {
    let tempFileUploadIdArray = [];
    let removedFileIdArray = [];
    if (remediationTrackerTaskPageState.TempFileIdList?.length > 0) {
      tempFileUploadIdArray = remediationTrackerTaskPageState.TempFileIdList;
    }
    if (
      remediationTrackerTaskPageState.RemediationTrackerTask.RemovedFileIdList
        ?.length > 0
    ) {
      removedFileIdArray =
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemovedFileIdList;
    }

    let mergedArray = tempFileUploadIdArray.concat(
      removedFileIdArray.filter(
        (item) => tempFileUploadIdArray.indexOf(item) < 0
      )
    );

    return mergedArray;
  };

  const deleteFilesOnDiscard = () => {
    let remediationTrackerTask: RemediationTrackerTaskDTO = Object.assign(
      {},
      remediationTrackerTaskPageState.RemediationTrackerTask
    );

    let filesToBeRemoved: number[] = getFilesToBeRemoved();

    remediationTrackerTask = {
      ...remediationTrackerTask,

      RemovedFileIdList: filesToBeRemoved,
    };

    if (remediationTrackerTask.RemovedFileIdList?.length > 0) {
      dispatch(deleteFilesOnCancel(remediationTrackerTask));
      return true;
    }
    return false;
  };

  const checkFilesAndCancel = () => {
    let result = deleteFilesOnDiscard();
    if (result) {
      dispatch(
        remediationTrackerTaskReducerActions.changePageMode(
          EnumPageMode.ViewMode
        )
      );
    } else {
      if (
        remediationTrackerTaskPageState.PageMode.PageMode ==
        EnumPageMode.AddMode
      ) {
        // setRemediationTrackerTaskPageState((value) => {
        //   return {
        //     ...value,
        //     EditInAddMode: false,
        //   };
        // });
        dispatch(
          remediationTrackerTaskReducerActions.resetRemediationTrackerTask()
        );
        navigate("/remediationTrackerTasks", {
          state: {
            TaskSearchParameter: parameter?.TaskSearchParameter,
            IsFullScreen: parameter?.IsFullScreen,
            ColumnOptions: parameter?.ColumnOptions ?? [],
          } as RemediationTrackerTasksPageParametersType,
        });
      } else {
        setRemediationTrackerTaskPageState((values) => {
          return {
            ...values,
            RemediationTrackerTask:
              remediationTrackerTaskState.RemediationTrackerTask,
            TaskComment: remediationTrackerTaskState.TaskComment,
          };
        });

        dispatch(
          remediationTrackerTaskReducerActions.changePageMode(
            EnumPageMode.ViewMode
          )
        );
      }
    }
  };

  const closePageBtnClick = () => {
    // dispatch(
    //   remediationTrackerTaskReducerActions.resetRemediationTrackerTask()
    // );

    navigate("/remediationTrackerTasks", {
      state: {
        TaskSearchParameter: parameter?.TaskSearchParameter,
        IsFullScreen: parameter.IsFullScreen,
        ColumnOptions: parameter?.ColumnOptions ?? [],
      } as RemediationTrackerTasksPageParametersType,
    });
  };

  const closeBtnClick = () => {
    if (
      remediationTrackerTaskPageState.RemediationTrackerTask
        .CloseTaskIfERNumberEntered &&
      remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber?.trim()
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText:
            "Note: ER number has been entered for this task, please confirm that the ER record is tracked outside of this application and the task can be marked as Closed. If not, please remove the entered value from the ER number field.",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesButtonText: "Confirm",
          NoButtonText: "Discard",
          YesBtnClick() {
            saveBtnClick(
              EnumRemediationTrackerTaskStatus.Closed,
              false,
              false,
              "",
              false,
              false,
              true,
              false
            );
          },
        } as PopupProps)
      );
    } else {
      saveBtnClick(
        EnumRemediationTrackerTaskStatus.PendingClosureApproval,
        false,
        false,
        "Are you sure you want to submit the task for closure?",
        false,
        false,
        false,
        false
      );
    }
  };

  const cancelBtnClick = () => {
    const isDirtyTemp = isDirty;
    if (
      remediationTrackerTaskPageState.PageMode.PageMode === EnumPageMode.AddMode
    ) {
      if (
        (remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ==
            null) &&
        (!remediationTrackerTaskPageState.RemediationTrackerTask
          .SelectedActionOwnerId ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .SelectedActionOwnerId === "0") &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.Piority ==
          null ||
          remediationTrackerTaskPageState.RemediationTrackerTask.Piority ===
            0) &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ==
            null) &&
        remediationTrackerTaskPageState.TaskComment === "" &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemediationTrackerTaskQuestions?.length ===
          remediationTrackerTaskState.RemediationTrackerTask
            .RemediationTrackerTaskQuestions?.length &&
        remediationTrackerTaskPageState.RemediationTrackerTask.ApproverId ===
          0 &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .IsCapexrequired == null &&
        (remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexamountRequired === 0.0 ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexamountRequired == null) &&
        (remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexnumberAndName === "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexnumberAndName == null) &&
        remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus ==
          null &&
        remediationTrackerTaskPageState.RemediationTrackerTask.AreaOwner ==
          null &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexplannedYear == null &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber ==
            null)
      ) {
        checkFilesAndCancel();
      } else {
        setIsDirty(false);

        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              checkFilesAndCancel();
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      }
    } else {
      if (
        remediationTrackerTaskState.RemediationTrackerTask.TaskName !==
          remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ||
        remediationTrackerTaskState.RemediationTrackerTask
          .SelectedActionOwnerId !==
          remediationTrackerTaskPageState.RemediationTrackerTask
            .SelectedActionOwnerId ||
        remediationTrackerTaskState.RemediationTrackerTask.Piority !==
          remediationTrackerTaskPageState.RemediationTrackerTask.Piority ||
        remediationTrackerTaskState.RemediationTrackerTask.DueDate !==
          remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ||
        remediationTrackerTaskState.TaskComment !==
          remediationTrackerTaskPageState.TaskComment ||
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemediationTrackerTaskQuestions?.length !==
          remediationTrackerTaskState.RemediationTrackerTask
            .RemediationTrackerTaskQuestions?.length ||
        remediationTrackerTaskPageState.TempFileIdList?.length > 0 ||
        remediationTrackerTaskState.RemediationTrackerTask.ApproverId !==
          remediationTrackerTaskPageState.RemediationTrackerTask.ApproverId ||
        remediationTrackerTaskState.RemediationTrackerTask.IsCapexrequired !==
          remediationTrackerTaskPageState.RemediationTrackerTask
            .IsCapexrequired ||
        remediationTrackerTaskState.RemediationTrackerTask
          .CapexamountRequired !==
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexamountRequired ||
        remediationTrackerTaskState.RemediationTrackerTask
          .CapexnumberAndName !==
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexnumberAndName ||
        remediationTrackerTaskState.RemediationTrackerTask.Capexstatus !==
          remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus ||
        remediationTrackerTaskState.RemediationTrackerTask.AreaOwner !==
          remediationTrackerTaskPageState.RemediationTrackerTask.AreaOwner ||
        remediationTrackerTaskState.RemediationTrackerTask.CapexplannedYear !==
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexplannedYear ||
        remediationTrackerTaskState.RemediationTrackerTask.Ernumber !==
          remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber
      ) {
        setIsDirty(false);

        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              remediationTrackerTaskPageState.Validator.hideMessages();
              checkFilesAndCancel();
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      } else {
        remediationTrackerTaskPageState.Validator.hideMessages();
        checkFilesAndCancel();
      }
    }
  };

  const cancelTaskBtnClick = () => {
    saveBtnClick(
      EnumRemediationTrackerTaskStatus.Cancelled,
      false,
      false,
      "",
      false,
      false,
      false,
      true
    );
  };

  const CloseWithoutApprovalBtnClick = () => {
    saveBtnClick(
      EnumRemediationTrackerTaskStatus.Closed,
      false,
      false,
      "",
      false,
      true,
      false,
      false
    );
  };

  const backBtnClick = () => {
    const isDirtyTemp = isDirty;

    if (
      remediationTrackerTaskPageState.PageMode.PageMode === EnumPageMode.AddMode
    ) {
      if (
        (remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ==
            null) &&
        (!remediationTrackerTaskPageState.RemediationTrackerTask
          .SelectedActionOwnerId ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .SelectedActionOwnerId === "0") &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.Piority ==
          null ||
          remediationTrackerTaskPageState.RemediationTrackerTask.Piority ===
            0) &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ==
            null) &&
        remediationTrackerTaskPageState.TaskComment === "" &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemediationTrackerTaskQuestions?.length ==
          remediationTrackerTaskState.RemediationTrackerTask
            .RemediationTrackerTaskQuestions?.length &&
        remediationTrackerTaskPageState.RemediationTrackerTask.ApproverId ===
          0 &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .IsCapexrequired == null &&
        (remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexamountRequired == 0.0 ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexamountRequired == null) &&
        (remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexnumberAndName === "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexnumberAndName == null) &&
        remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus ==
          null &&
        remediationTrackerTaskPageState.RemediationTrackerTask.AreaOwner ==
          null &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexplannedYear == null &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber ==
            null)
      ) {
        dispatch(
          remediationTrackerTaskReducerActions.resetRemediationTrackerTask()
        );
        navigate("/remediationTrackerTasks", {
          state: {
            TaskSearchParameter: parameter?.TaskSearchParameter,
            IsFullScreen: parameter.IsFullScreen,
            ColumnOptions: parameter?.ColumnOptions ?? [],
          } as RemediationTrackerTasksPageParametersType,
        });
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              let result = deleteFilesOnDiscard();
              setRemediationTrackerTaskPageState((values) => {
                return {
                  ...values,
                  TaskComment: remediationTrackerTaskState.TaskComment,
                  IsOverdue: false,
                };
              });
              navigate("/remediationTrackerTasks", {
                state: {
                  TaskSearchParameter: parameter.TaskSearchParameter,
                  IsFullScreen: parameter.IsFullScreen,
                  ColumnOptions: parameter?.ColumnOptions ?? [],
                } as RemediationTrackerTasksPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      }
    } else {
      if (
        remediationTrackerTaskState.RemediationTrackerTask.TaskName !=
          remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ||
        remediationTrackerTaskState.RemediationTrackerTask
          .SelectedActionOwnerId !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .SelectedActionOwnerId ||
        remediationTrackerTaskState.RemediationTrackerTask.Piority !=
          remediationTrackerTaskPageState.RemediationTrackerTask.Piority ||
        remediationTrackerTaskState.RemediationTrackerTask.DueDate !=
          remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ||
        remediationTrackerTaskState.TaskComment !=
          remediationTrackerTaskPageState.TaskComment ||
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemediationTrackerTaskQuestions?.length !=
          remediationTrackerTaskState.RemediationTrackerTask
            .RemediationTrackerTaskQuestions?.length ||
        remediationTrackerTaskPageState.TempFileIdList?.length > 0 ||
        remediationTrackerTaskState.RemediationTrackerTask.ApproverId !=
          remediationTrackerTaskPageState.RemediationTrackerTask.ApproverId ||
        remediationTrackerTaskState.RemediationTrackerTask.IsCapexrequired !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .IsCapexrequired ||
        remediationTrackerTaskState.RemediationTrackerTask
          .CapexamountRequired !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexamountRequired ||
        remediationTrackerTaskState.RemediationTrackerTask.CapexnumberAndName !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexnumberAndName ||
        remediationTrackerTaskState.RemediationTrackerTask.Capexstatus !=
          remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus ||
        remediationTrackerTaskState.RemediationTrackerTask.AreaOwner !=
          remediationTrackerTaskPageState.RemediationTrackerTask.AreaOwner ||
        remediationTrackerTaskState.RemediationTrackerTask.CapexplannedYear !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexplannedYear ||
        remediationTrackerTaskState.RemediationTrackerTask.Ernumber !=
          remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber
      ) {
        setIsDirty(false);

        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              let result = deleteFilesOnDiscard();
              setRemediationTrackerTaskPageState((values) => {
                return {
                  ...values,
                  TaskComment: remediationTrackerTaskState.TaskComment,
                  IsOverdue: false,
                };
              });
              dispatch(
                remediationTrackerTaskReducerActions.changePageMode(
                  EnumPageMode.ViewMode
                )
              );
              navigate("/remediationTrackerTasks", {
                state: {
                  TaskSearchParameter: parameter.TaskSearchParameter,
                  IsFullScreen: parameter.IsFullScreen,
                  ColumnOptions: parameter?.ColumnOptions ?? [],
                } as RemediationTrackerTasksPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          remediationTrackerTaskReducerActions.resetRemediationTrackerTask()
        );
        navigate("/remediationTrackerTasks", {
          state: {
            TaskSearchParameter: parameter.TaskSearchParameter,
            IsFullScreen: parameter.IsFullScreen,
            ColumnOptions: parameter?.ColumnOptions ?? [],
          } as RemediationTrackerTasksPageParametersType,
        });
      }
    }
  };

  const submitBtnClick = () => {
    if (
      remediationTrackerTaskPageState.RemediationTrackerTask
        .CloseTaskIfERNumberEntered &&
      remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber?.trim()
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText:
            "Note: ER number has been entered for this task, please confirm that the ER record is tracked outside of this application and the task can be marked as Closed. If not, please remove the entered value from the ER number field.",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesButtonText: "Confirm",
          NoButtonText: "Discard",
          YesBtnClick() {
            saveBtnClick(
              EnumRemediationTrackerTaskStatus.Closed,
              false,
              false,
              "",
              false,
              false,
              true,
              false
            );
          },
        } as PopupProps)
      );
    } else {
      saveBtnClick(
        remediationTrackerTaskPageState.RemediationTrackerTask.SMEReviewRequired
          ? EnumRemediationTrackerTaskStatus.PendingReviewSME
          : EnumRemediationTrackerTaskStatus.PendingReviewSite,
        false,
        false,
        "Are you sure you want to submit the task for review?",
        false,
        false,
        false,
        false
      );
    }
  };

  const approveBtnClick = () => {
    if (
      remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
      EnumRemediationTrackerTaskStatus.PendingClosureApproval
    ) {
      saveBtnClick(
        EnumRemediationTrackerTaskStatus.Closed,
        false,
        false,
        "Are you sure you want to approve the task?",
        true,
        false,
        false,
        false
      );
    } else if (
      remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.PendingReviewSME ||
      remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.PendingReviewSite
    ) {
      saveBtnClick(
        EnumRemediationTrackerTaskStatus.ActionInProgress,
        false,
        false,
        "Are you sure you want to approve the task?",
        remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
          EnumRemediationTrackerTaskStatus.PendingReviewSite
          ? true
          : false,
        false,
        false,
        false
      );
    }
  };

  const rejectBtnClick = () => {
    if (
      remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
      EnumRemediationTrackerTaskStatus.PendingClosureApproval
    ) {
      saveBtnClick(
        EnumRemediationTrackerTaskStatus.ActionInProgress,
        false,
        true,
        "Are you sure you want to reject the task?",
        false,
        false,
        false,
        false
      );
    } else if (
      remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.PendingReviewSME ||
      remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.PendingReviewSite
    ) {
      saveBtnClick(
        EnumRemediationTrackerTaskStatus.New,
        false,
        true,
        "Are you sure you want to reject the task?",
        false,
        false,
        false,
        false
      );
    }
  };

  const questionDeleteBtnClick = (questionId: number) => {
    let questionList =
      remediationTrackerTaskPageState.RemediationTrackerTask?.RemediationTrackerTaskQuestions?.filter(
        (question) => question.Id != questionId
      );

    if (questionList?.length == 1) {
      dispatch(
        openPopup({
          Open: true,
          BodyText:
            "Do you really want to un-merge the current task? After verification, it will become a single task",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setRemediationTrackerTaskPageState((values) => {
              return {
                ...values,
                RemediationTrackerTask: {
                  ...values.RemediationTrackerTask,
                  RemediationTrackerTaskQuestions: questionList,
                },
                showDeleteBtn: questionList?.length > 1 ? true : false,
              };
            });
          },
        } as PopupProps)
      );
    } else {
      setRemediationTrackerTaskPageState((values) => {
        return {
          ...values,
          RemediationTrackerTask: {
            ...values.RemediationTrackerTask,
            RemediationTrackerTaskQuestions: questionList,
          },
          showDeleteBtn: questionList?.length > 1 ? true : false,
        };
      });
    }
  };

  const showMoreCommentsClick = () => {
    let commentMoreToggle = remediationTrackerTaskPageState.ShowMoreComments;
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        ShowMoreComments: !commentMoreToggle,
      };
    });

    dispatch(
      remediationTrackerTaskReducerActions.updateCommentToggleState(true)
    );
  };

  const showMoreHistoryClick = () => {
    let historyMoreToggle = remediationTrackerTaskPageState.ShowMoreHistory;
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        ShowMoreHistory: !historyMoreToggle,
      };
    });

    dispatch(
      remediationTrackerTaskReducerActions.updateHistoryToggleState(true)
    );
  };

  const RemediationTrackerTaskPageValidation = () => {
    if (
      remediationTrackerTaskPageState.PageMode.PageMode === EnumPageMode.AddMode
    ) {
      if (
        (remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ==
            null) &&
        (!remediationTrackerTaskPageState.RemediationTrackerTask
          .SelectedActionOwnerId ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .SelectedActionOwnerId === "0") &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.Piority ==
          null ||
          remediationTrackerTaskPageState.RemediationTrackerTask.Piority ===
            0) &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ==
            null) &&
        remediationTrackerTaskPageState.TaskComment === "" &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemediationTrackerTaskQuestions?.length ==
          remediationTrackerTaskState.RemediationTrackerTask
            .RemediationTrackerTaskQuestions?.length &&
        remediationTrackerTaskPageState.RemediationTrackerTask.ApproverId ===
          0 &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .IsCapexrequired == null &&
        (remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexamountRequired == 0.0 ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexamountRequired == null) &&
        (remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexnumberAndName === "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexnumberAndName == null) &&
        remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus ==
          null &&
        remediationTrackerTaskPageState.RemediationTrackerTask.AreaOwner ==
          null &&
        remediationTrackerTaskPageState.RemediationTrackerTask
          .CapexplannedYear == null &&
        (remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber ===
          "" ||
          remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber ==
            null)
      ) {
        setIsDirty(false);
      } else {
        setIsDirty(true);
      }
    } else {
      if (
        remediationTrackerTaskState.RemediationTrackerTask.TaskName !=
          remediationTrackerTaskPageState.RemediationTrackerTask.TaskName ||
        remediationTrackerTaskState.RemediationTrackerTask
          .SelectedActionOwnerId !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .SelectedActionOwnerId ||
        remediationTrackerTaskState.RemediationTrackerTask.Piority !=
          remediationTrackerTaskPageState.RemediationTrackerTask.Piority ||
        remediationTrackerTaskState.RemediationTrackerTask.DueDate !=
          remediationTrackerTaskPageState.RemediationTrackerTask.DueDate ||
        remediationTrackerTaskState.TaskComment !=
          remediationTrackerTaskPageState.TaskComment ||
        remediationTrackerTaskPageState.RemediationTrackerTask
          .RemediationTrackerTaskQuestions?.length !=
          remediationTrackerTaskState.RemediationTrackerTask
            .RemediationTrackerTaskQuestions?.length ||
        remediationTrackerTaskPageState.TempFileIdList?.length > 0 ||
        remediationTrackerTaskState.RemediationTrackerTask.ApproverId !=
          remediationTrackerTaskPageState.RemediationTrackerTask.ApproverId ||
        remediationTrackerTaskState.RemediationTrackerTask.IsCapexrequired !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .IsCapexrequired ||
        remediationTrackerTaskState.RemediationTrackerTask
          .CapexamountRequired !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexamountRequired ||
        remediationTrackerTaskState.RemediationTrackerTask.CapexnumberAndName !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexnumberAndName ||
        remediationTrackerTaskState.RemediationTrackerTask.Capexstatus !=
          remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus ||
        remediationTrackerTaskState.RemediationTrackerTask.AreaOwner !=
          remediationTrackerTaskPageState.RemediationTrackerTask.AreaOwner ||
        remediationTrackerTaskState.RemediationTrackerTask.CapexplannedYear !=
          remediationTrackerTaskPageState.RemediationTrackerTask
            .CapexplannedYear ||
        remediationTrackerTaskState.RemediationTrackerTask.Ernumber !=
          remediationTrackerTaskPageState.RemediationTrackerTask.Ernumber
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  };

  const disabledDate = (date: dayjs.Dayjs) => {
    //return date.isBefore(dayjs().subtract(1, "day"));
    return date.isBefore(dayjs().startOf("day"));
  };

  const convertDateFormatToString = (dateString: string): string => {
    if (dateString == null || dateString == "") {
      return null;
    }
    const [day, month, year] = dateString.split("-");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = months.indexOf(month) + 1;
    return `${year}-${monthIndex.toString().padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
  };

  const onDueDateChange = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        RemediationTrackerTask: {
          ...values.RemediationTrackerTask,
          DueDate: convertDateFormatToString(value),
        },
      };
    });
  };

  const onChangePriorityDropdown = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          Piority: selectedValue ? parseInt(selectedValue) : null,
        },
      };
    });
  };

  const onChangeActionOwner = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          SelectedActionOwnerId: selectedValue || "0",
          ActionOwnerDropDownList: selectOption,
        },
      };
    });
  };

  const onChangeTaskName = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        RemediationTrackerTask: {
          ...values.RemediationTrackerTask,
          TaskName: value,
        },
      };
    });
  };

  const onChangeTaskComment = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        TaskComment: value,
      };
    });
  };

  const onChangeApprover = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          ApproverId: selectedValue ? parseInt(selectedValue) : 0,
        },
      };
    });
  };

  const onChangeCapexRequired = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    let showCapexFields = true;
    let showCapexPlannedField = true;
    let capexamountRequired =
      remediationTrackerTaskPageState.RemediationTrackerTask
        .CapexamountRequired;
    let capexNumberAndName =
      remediationTrackerTaskPageState.RemediationTrackerTask.CapexnumberAndName;
    let capexStatus =
      remediationTrackerTaskPageState.RemediationTrackerTask.Capexstatus;

    let capexPlannedYear =
      remediationTrackerTaskPageState.RemediationTrackerTask.CapexplannedYear;
    if (
      parseInt(selectedValue) === EnumIsCapexRequired.No ||
      selectedValue === ""
    ) {
      remediationTrackerTaskPageState.Validator.purgeFields();
      showCapexFields = false;
      showCapexPlannedField = false;
    } else if (
      parseInt(selectedValue) === EnumIsCapexRequired.YesToBeRequested ||
      selectedValue === ""
    ) {
      remediationTrackerTaskPageState.Validator.purgeFields();
    }
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          IsCapexrequired: selectedValue ? parseInt(selectedValue) : null,
          CapexamountRequired: showCapexFields ? capexamountRequired : null,
          CapexnumberAndName: showCapexFields ? capexNumberAndName : null,
          Capexstatus: showCapexFields ? capexStatus : null,
          CapexplannedYear: showCapexPlannedField ? capexPlannedYear : null,
        },
        ShowCapexFields: showCapexFields,
        ShowCapexPlannedYear: showCapexPlannedField,
      };
    });
  };

  const onChangeCapexAmount = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        RemediationTrackerTask: {
          ...values.RemediationTrackerTask,
          CapexamountRequired: value,
        },
      };
    });
  };

  const onChangeCapexNumName = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        RemediationTrackerTask: {
          ...values.RemediationTrackerTask,
          CapexnumberAndName: value,
        },
      };
    });
  };

  const onChangeCapexStatus = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          Capexstatus: selectedValue ? parseInt(selectedValue) : null,
        },
      };
    });
  };

  const onChangeAreaOwnerType = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          AreaOwner: selectedValue ? parseInt(selectedValue) : null,
        },
      };
    });
  };

  const onClosedDateChange = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        RemediationTrackerTask: {
          ...values.RemediationTrackerTask,
          CompletedDate: convertDateFormatToString(value),
        },
      };
    });
  };

  const onChangeClosedBy = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          CompletedById: selectedValue ? parseInt(selectedValue) : 0,
        },
      };
    });
  };

  const onChangeCapexPlannedYear = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setRemediationTrackerTaskPageState((value) => {
      return {
        ...value,
        RemediationTrackerTask: {
          ...value.RemediationTrackerTask,
          CapexplannedYear: selectedValue ? parseInt(selectedValue) : null,
        },
      };
    });
  };

  const onChangeErNum = (name: string, value: any) => {
    setRemediationTrackerTaskPageState((values) => {
      return {
        ...values,
        RemediationTrackerTask: {
          ...values.RemediationTrackerTask,
          Ernumber: value,
        },
      };
    });
  };

  const editButtonClick = () => {
    dispatch(
      remediationTrackerTaskReducerActions.changePageMode(EnumPageMode.EditMode)
    );
  };

  // const completeButtonClick = () => {
  //   saveBtnClick(
  //     EnumRemediationTrackerTaskStatus.Complete,
  //     false,
  //     "Are you sure you want to complete the task?"
  //   );
  // };

  const getSelectedFiles = (fileList: Array<UploadFileDTO>) => {
    let removedFileIdList: Array<number> =
      remediationTrackerTaskPageState.RemediationTrackerTask
        .RemovedFileIdList != null
        ? [
            ...remediationTrackerTaskPageState.RemediationTrackerTask
              .RemovedFileIdList,
          ]
        : [];

    remediationTrackerTaskPageState.RemediationTrackerTask.UploadFiles.forEach(
      (file: UploadFileDTO, index: number) => {
        let found = fileList.filter((obj: UploadFileDTO) => {
          return obj.Id === file.Id;
        });

        if (found?.length === 0) {
          removedFileIdList.push(file.Id);
        }
      }
    );

    let newFileList = fileList.filter((file) => file.Id == 0);

    fileList = fileList.map((file) => {
      return {
        ...file,
        RemediationTrackerTaskId:
          remediationTrackerTaskPageState.RemediationTrackerTask.Id,
      };
    });

    dispatch(
      remediationTrackerTaskReducerActions.changeUploadFileListData({
        UploadFiles: fileList,
        RemovedFileIdList: removedFileIdList,
      } as RemediationTrackerTaskDTO)
    );

    setIsDirty(true);
  };

  const getError = (error: FileUploaderErrorType) => {};

  const uploadFilesToBackend = () => {
    let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
    let inProgressUpload: UploadFileDTO = {} as UploadFileDTO;

    inProgressUpload =
      remediationTrackerTaskPageState.RemediationTrackerTask.UploadFiles?.find(
        (file: UploadFileDTO) => {
          return file.UploadStatus === EnumDataTransferStatus.InProgress;
        }
      );

    if (!inProgressUpload) {
      toBeUploaded =
        remediationTrackerTaskPageState.RemediationTrackerTask.UploadFiles?.filter(
          (file: UploadFileDTO) => {
            return file.UploadStatus === EnumDataTransferStatus.NotStarted;
          }
        );

      if (toBeUploaded?.length > 0) {
        dispatch(remediationTrackerTaskFileUpload());
      }
    }
  };

  useEffect(() => {
    uploadFilesToBackend();
  }, [remediationTrackerTaskPageState.RemediationTrackerTask.UploadFiles]);

  useEffect(() => {
    if (remediationTrackerTaskState.TaskDeleted) {
      dispatch(
        remediationTrackerTaskReducerActions.navigateToRemediationTrackerTasks(
          false
        )
      );
      navigate("/remediationTrackerTasks", {
        state: {
          TaskSearchParameter: parameter?.TaskSearchParameter,
          IsFullScreen: parameter.IsFullScreen,
          ColumnOptions: parameter?.ColumnOptions ?? [],
        } as RemediationTrackerTasksPageParametersType,
      });
    }
  }, [remediationTrackerTaskState.TaskDeleted]);

  function getStatusCss(file: any) {
    throw new Error("Function not implemented.");
  }

  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const onCloseBtnClick = () => {
  //   setOpen(false);
  // };

  const onViewInspectionQuestionOpen = (inspectionQuestionId: number) => {
    dispatch(
      viewInspectionQuestionSliceReducerActions.viewInspectionQuestionDetails({
        InspectionQuestionId: inspectionQuestionId,
      })
    );
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.RemediationTrackerTask,
      functionId,
      functionTypeId
    );
  };

  const getDisplayVersion = (version: string): string => {
    if (version.includes(".")) {
      let versionArray = version.split(".");
      let decimalPart = parseInt(versionArray[1]);
      return [versionArray[0], decimalPart.toString()].join(".");
    }
  };

  const getStatusSuffix = (): string => {
    // if (
    //   remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
    //     EnumRemediationTrackerTaskStatus.PendingReview &&
    //   !remediationTrackerTaskPageState.RemediationTrackerTask.IsSMEApproved
    // ) {
    //   return (
    //     remediationTrackerTaskPageState.RemediationTrackerTask?.StatusNavigation
    //       ?.Description + "(SME)"
    //   );
    // } else if (
    //   remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
    //     EnumRemediationTrackerTaskStatus.PendingReview &&
    //   remediationTrackerTaskPageState.RemediationTrackerTask.IsSMEApproved
    // ) {
    //   return (
    //     remediationTrackerTaskPageState.RemediationTrackerTask?.StatusNavigation
    //       ?.Description + "(Site)"
    //   );
    // } else if (
    //   remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
    //   EnumRemediationTrackerTaskStatus.PendingClosureApprovalSite
    // ) {
    //   return (
    //     remediationTrackerTaskPageState.RemediationTrackerTask?.StatusNavigation
    //       ?.Description + "(Site)"
    //   );
    // } else {
    return remediationTrackerTaskPageState.RemediationTrackerTask
      ?.StatusNavigation?.Description;
    // }
  };

  const approvalHistoryBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    dispatch(
      viewRemediationTrackerTaskHistorySliceReducerActions.viewRemediationTrackerTaskHistoryDetails(
        {
          Ref: event.currentTarget,
          RemediationTrackerTaskId:
            parameter?.RemediationTrackerTaskId ??
            remediationTrackerTaskPageState?.RemediationTrackerTask?.Id,
          Identifier:
            remediationTrackerTaskPageState.RemediationTrackerTask
              ?.Identifier ?? "",
        } as ViewRemediationTrackerTaskHistoryProps
      )
    );
  };

  const getUserAndDateFieldValue = () => {
    if (
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.Closed ||
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.ClosedAsOverdue
    ) {
      return (
        remediationTrackerTaskPageState.RemediationTrackerTask
          .CompletedByAndDate ?? ""
      );
    } else if (
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
      EnumRemediationTrackerTaskStatus.Cancelled
    ) {
      return (
        remediationTrackerTaskPageState.RemediationTrackerTask
          .CancelledByAndDate ?? ""
      );
    } else {
      return "";
    }
  };

  const getUserAndDateFieldLabel = () => {
    if (
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.Closed ||
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
        EnumRemediationTrackerTaskStatus.ClosedAsOverdue
    ) {
      if (
        remediationTrackerTaskPageState.RemediationTrackerTask?.ClosureReason ??
        ""
      ) {
        return "Closed By and Date";
      } else {
        return "Completed By and Date";
      }
    } else if (
      remediationTrackerTaskState.RemediationTrackerTask.Status ===
      EnumRemediationTrackerTaskStatus.Cancelled
    ) {
      return "Cancelled By and Date";
    } else {
      return "";
    }
  };
  return (
    <>
      {/* Global Header with Status & Version */}
      <Grid container columns={12} className="">
        {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>View Task</h1>
        </Grid> */}

        {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        >
          <div className="global-version-top mb-5">
            <span>Version:</span> */}
        {/* {remediationTrackerTaskPageState.RemediationTrackerTask.Version !=
            null
              ? remediationTrackerTaskPageState.RemediationTrackerTask.Version.toFixed(
                  1
                )
              : "1.0"} */}

        {/* {remediationTrackerTaskPageState.RemediationTrackerTask.Version !=
            null
              ? getDisplayVersion(
                  remediationTrackerTaskPageState.RemediationTrackerTask.Version.toFixed(
                    2
                  )
                )
              : "1.0"}
          </div>
          <div className="standart-status-change mb-5 d-flex">
            <div className="standards-status">
              <span>Status:</span>
              <span
                className={
                  remediationTrackerTaskPageState.IsOverdue
                    ? "status-color red"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status === EnumRemediationTrackerTaskStatus.Closed
                    ? "status-color green"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status === EnumRemediationTrackerTaskStatus.AutoClosed
                    ? "status-color green"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status ===
                      EnumRemediationTrackerTaskStatus.ActionInProgress
                    ? "status-color yellow"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status === EnumRemediationTrackerTaskStatus.New
                    ? "status-color blue"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status ===
                      EnumRemediationTrackerTaskStatus.PendingReviewSME
                    ? "status-color submit"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status ===
                      EnumRemediationTrackerTaskStatus.PendingReviewSite
                    ? "status-color site"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status ===
                      EnumRemediationTrackerTaskStatus.ReviewCompleted
                    ? "status-color not-started"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status === EnumRemediationTrackerTaskStatus.Closed
                    ? "status-color green"
                    : remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status ===
                      EnumRemediationTrackerTaskStatus.PendingClosureApproval
                    ? "status-color pendding-closure"
                    : "status-color gray"
                }
              >
                {" "}
                {remediationTrackerTaskPageState.IsOverdue
                  ? "Overdue"
                  : getStatusSuffix()}
              </span>
            </div>
          </div>
        </Grid> */}
      </Grid>
      {/* Global Header with Status & Version  End*/}

      <div className="content-section-card remediation-tracker-page top-main-padding">
        {/* Global Sub Header with  Buttons*/}
        <Grid
          container
          columns={12}
          className="global-subhead-btn-wrap top-main-border-inner"
        >
          <Grid item className="global-subhead-wrap" style={{marginBottom: "5px"}}>
            {/* <IconButton
              aria-label="back"
              className="sub-head-back-btn"
              onClick={backBtnClick}
              title="Back to Remediation Tracker"
            >
              <ArrowLeft2 size="24" variant="Outline" />
            </IconButton> */}
            <Button
              aria-label="back"
              className="sub-head-back-btn back"
              onClick={backBtnClick}
              title="Back to home page"
            >
              {/* <ArrowLeft2 size="24" variant="Outline" /> */}
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color">Back</span>
            </Button>
            <h1>
              {remediationTrackerTaskState.PageMode.PageMode ===
              EnumPageMode.AddMode
                ? "New Task:"
                : remediationTrackerTaskPageState.RemediationTrackerTask
                    .Identifier &&
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .RemediationTrackerTaskQuestions[0].InspectionQuestion
                    ?.SiteInspection?.Site?.SiteName
                ? "Task : " +
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .RemediationTrackerTaskQuestions[0].InspectionQuestion
                    ?.SiteInspection?.Site?.SiteName +
                  " : " +
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .Identifier
                : "Task: "}
              {/* View Task: {"    "}
              {
                remediationTrackerTaskPageState.RemediationTrackerTask
                  .Identifier
              } */}
            </h1>
            <div className="vl"></div>
            <Grid
              item
              // lg={6}
              // md={6}
              // sm={12}
              // xs={12}
              className="vlign-middle status-version-wrap"
            >
              <div className="standart-status-change d-flex">
                <div className="standards-status">
                  {/* <span>Status:</span> */}
                  <span
                    className={
                      remediationTrackerTaskPageState.IsOverdue
                        ? "status-color red"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status === EnumRemediationTrackerTaskStatus.Closed
                        ? "status-color green"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.AutoClosed
                        ? "status-color green"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.ActionInProgress
                        ? "status-color yellow"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status === EnumRemediationTrackerTaskStatus.New
                        ? "status-color blue"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.PendingReviewSME
                        ? "status-color submit"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.PendingReviewSite
                        ? "status-color site"
                        : // : remediationTrackerTaskPageState.RemediationTrackerTask
                        //     .Status ===
                        //   EnumRemediationTrackerTaskStatus.ReviewCompleted
                        //? "status-color not-started"
                        remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.ClosedAsOverdue
                        ? "status-color overdue-closed"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.Cancelled
                        ? "status-color remediation-cancelled"
                        : remediationTrackerTaskPageState.RemediationTrackerTask
                            .Status ===
                          EnumRemediationTrackerTaskStatus.PendingClosureApproval
                        ? "status-color pendding-closure"
                        : "status-color gray"
                    }
                  >
                    {" "}
                    {remediationTrackerTaskPageState.IsOverdue
                      ? "Overdue"
                      : getStatusSuffix()}
                  </span>
                </div>
              </div>
              <div className="global-version-top">
                <span>Version:</span>
                {/* {remediationTrackerTaskPageState.RemediationTrackerTask.Version !=
            null
              ? remediationTrackerTaskPageState.RemediationTrackerTask.Version.toFixed(
                  1
                )
              : "1.0"} */}

                {remediationTrackerTaskPageState.RemediationTrackerTask
                  .Version != null
                  ? getDisplayVersion(
                      remediationTrackerTaskPageState.RemediationTrackerTask.Version.toFixed(
                        2
                      )
                    )
                  : "1.0"}
              </div>
            </Grid>
          </Grid>

          <Grid item className="sub-header-btn-wrap">
            {remediationTrackerTaskState.PageMode.IsShowCancelBtn ? (
              <Button
                className="secondary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<CloseCircle variant="Outline" />}
                onClick={cancelBtnClick}
                title="Discard the task changes"
              >
                Discard
              </Button>
            ) : null}

            {remediationTrackerTaskState.PageMode.PageMode ===
            EnumPageMode.ViewMode ? (
              <Button
                className="secondary-btn-small"
                variant="outlined"
                startIcon={<CloseCircle variant="Outline" />}
                size="small"
                onClick={closePageBtnClick}
                title="Close"
              >
                Close
              </Button>
            ) : null}
            {remediationTrackerTaskState.PageMode.PageMode !==
              EnumPageMode.AddMode && (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Timer variant="Outline" />}
                onClick={approvalHistoryBtnClick}
                title="View Approval History"
              >
                Approval History
              </Button>
            )}

            {!(
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.Closed ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.AutoClosed ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.Cancelled
            ) &&
            checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_CancelTask,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
            (remediationTrackerTaskState.PageMode.PageMode ===
              EnumPageMode.EditMode ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.PendingReviewSME ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.PendingReviewSite ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.PendingClosureApproval) ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<FolderCross variant="Outline" />}
                onClick={cancelTaskBtnClick}
                title="Cancel the task"
              >
                Cancel
              </Button>
            ) : null}

            {!(
              remediationTrackerTaskState.RemediationTrackerTask?.Status ===
                EnumRemediationTrackerTaskStatus.PendingReviewSME ||
              remediationTrackerTaskState.RemediationTrackerTask?.Status ===
                EnumRemediationTrackerTaskStatus.PendingReviewSite ||
              remediationTrackerTaskState.RemediationTrackerTask?.Status ===
                EnumRemediationTrackerTaskStatus.PendingClosureApproval ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.Closed ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.AutoClosed ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.Cancelled
            ) &&
            checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_EditTask,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
            remediationTrackerTaskState.PageMode.IsShowEditBtn ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Edit variant="Outline" />}
                onClick={editButtonClick}
                title="Edit"
              >
                Edit
              </Button>
            ) : null}

            {checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_SaveTask,
              EnumSystemFunctionTypes.ButtonAccess
            ) && remediationTrackerTaskState.PageMode.IsShowSaveBtn ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<DirectboxReceive variant="Outline" />}
                onClick={() => {
                  saveBtnClick(
                    remediationTrackerTaskState.RemediationTrackerTask.Status,
                    true,
                    false,
                    "",
                    false,
                    false,
                    false,
                    false
                  );
                }}
                title="Save task changes"
              >
                Save
              </Button>
            ) : null}
            {checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_Submit_Closure,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
            remediationTrackerTaskState.PageMode.IsShowCloseBtn &&
            ((remediationTrackerTaskState.RemediationTrackerTask.Status ===
              EnumRemediationTrackerTaskStatus.ActionInProgress &&
              remediationTrackerTaskPageState.RemediationTrackerTask
                .ClosureStageReviewRequired) ||
              (remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.New &&
                !remediationTrackerTaskPageState.RemediationTrackerTask
                  .SMEReviewRequired &&
                !remediationTrackerTaskPageState.RemediationTrackerTask
                  .SiteLeadershipReviewRequired &&
                remediationTrackerTaskPageState.RemediationTrackerTask
                  .ClosureStageReviewRequired)) ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Refresh2 variant="Outline" />}
                onClick={closeBtnClick}
                title="Submit For Closure"
              >
                Submit For Closure
              </Button>
            ) : null}
            {/* {checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_ChangeStatus,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
            remediationTrackerTaskState.PageMode.IsShowInProgressBtn &&
            remediationTrackerTaskState.RemediationTrackerTask.Status ===
              EnumRemediationTrackerTaskStatus.ReviewCompleted ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Refresh2 variant="Outline" />}
                onClick={inProgressBtnClick}
              >
                Action In Progress
              </Button>
            ) : null} */}

            {checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_CloseTask,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              remediationTrackerTaskState.PageMode.PageMode ===
                EnumPageMode.EditMode &&
              ((remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.New &&
                !remediationTrackerTaskPageState.RemediationTrackerTask
                  .SMEReviewRequired &&
                !remediationTrackerTaskPageState.RemediationTrackerTask
                  .SiteLeadershipReviewRequired &&
                !remediationTrackerTaskPageState.RemediationTrackerTask
                  .ClosureStageReviewRequired) ||
                (remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.ActionInProgress &&
                  !remediationTrackerTaskPageState.RemediationTrackerTask
                    .ClosureStageReviewRequired)) && (
                <Button
                  className="primary-btn-small"
                  size="small"
                  variant="outlined"
                  startIcon={<TickCircle variant="Outline" />}
                  onClick={CloseWithoutApprovalBtnClick}
                  title="Close"
                >
                  Close
                </Button>
              )}

            {checkSystemFunctionRight(
              EnumSystemFunctions.RemTrackerTaskPage_Submit_Review,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
            remediationTrackerTaskState.PageMode.IsShowSubmitBtn &&
            remediationTrackerTaskState.RemediationTrackerTask.Status ===
              EnumRemediationTrackerTaskStatus.New &&
            (remediationTrackerTaskPageState.RemediationTrackerTask
              .SMEReviewRequired ||
              remediationTrackerTaskPageState.RemediationTrackerTask
                .SiteLeadershipReviewRequired) ? (
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Refresh2 variant="Outline" />}
                onClick={submitBtnClick}
                title="Submit For Review"
              >
                Submit For Review
              </Button>
            ) : null}
            {/* {remediationTrackerTaskState.PageMode.IsCompleteBtn &&
            !(
              remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.New &&
              !remediationTrackerTaskPageState.IsOverdue
            ) &&
            remediationTrackerTaskState.RemediationTrackerTask.Status !==
              EnumRemediationTrackerTaskStatus.Approve &&
            remediationTrackerTaskState.RemediationTrackerTask.Status !==
              EnumRemediationTrackerTaskStatus.Complete &&
            remediationTrackerTaskState.RemediationTrackerTask.Status !==
              EnumRemediationTrackerTaskStatus.PendingReview ? (
              <Button
                variant="contained"
                startIcon={<TickCircle variant="Outline" />}
                className="primary-btn"
                onClick={completeButtonClick}
              >
                Complete
              </Button>
            ) : null} */}
            {remediationTrackerTaskPageState.RemediationTrackerTask
              .ShowRejectButton ? (
              // checkSystemFunctionRight(
              //   EnumSystemFunctions.RemTrackerTaskPage_Approval,
              //   EnumSystemFunctionTypes.ButtonAccess
              // ) &&

              // remediationTrackerTaskState.PageMode.IsShowRejectBtn &&
              // (remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
              //   EnumRemediationTrackerTaskStatus.PendingReviewSME ||
              //   remediationTrackerTaskState.RemediationTrackerTask?.Status ===
              //     EnumRemediationTrackerTaskStatus.PendingReviewSite ||
              //   remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
              //     EnumRemediationTrackerTaskStatus.PendingClosureApproval)
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<Slash variant="Outline" />}
                onClick={rejectBtnClick}
                title="Reject"
              >
                Reject
              </Button>
            ) : null}

            {remediationTrackerTaskPageState.RemediationTrackerTask
              .ShowApprovalButton ? (
              // checkSystemFunctionRight(
              //   EnumSystemFunctions.RemTrackerTaskPage_Approval,
              //   EnumSystemFunctionTypes.ButtonAccess
              // ) &&

              // remediationTrackerTaskState.PageMode.IsShowApproveBtn &&
              // (remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
              //   EnumRemediationTrackerTaskStatus.PendingReviewSME ||
              //   remediationTrackerTaskState.RemediationTrackerTask?.Status ===
              //     EnumRemediationTrackerTaskStatus.PendingReviewSite ||
              //   remediationTrackerTaskPageState.RemediationTrackerTask.Status ===
              //     EnumRemediationTrackerTaskStatus.PendingClosureApproval
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<TickCircle variant="Outline" />}
                onClick={approveBtnClick}
                title="Approve"
              >
                Approve
              </Button>
            ) : null}
          </Grid>
        </Grid>
        {/* Global Sub Header with  Buttons*/}
        <Grid container spacing={3} className="pl-10 pr-10 remtrack-task">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="form-group" style={{ paddingTop: 14 }}>
              {/* <HPRTextBox
                Name="task-name"
                Value={
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .TaskName ?? ""
                }
                Label="Task Description"
                Id={""}
                Type={EnumTextBoxType.Text}
                ClassName={""}
                InputProps={{ maxLength: 1000 }}
                ShowTextCounter
                TextLength={1000}
                MultiLine
                onTextBoxChange={onChangeTaskName}
                Validator={remediationTrackerTaskPageState.Validator}
                Rules={"required"}
                IsEnableValidator={true}
                Required
                Disabled={
                  remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                  (remediationTrackerTaskPageState.RemediationTrackerTask
                    .Status > EnumRemediationTrackerTaskStatus.New &&
                    userScope !== SystemGroupScope.Global)
                }
              /> */}
              <HPRTextBoxAutoResize
                Name="task-name"
                Value={
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .TaskName ?? ""
                }
                Label="Task Description"
                Id={""}
                Type={EnumTextBoxType.Text}
                ClassName={""}
                InputProps={{ maxLength: 1000 }}
                ShowTextCounter
                TextLength={1000}
                MultiLine
                onTextBoxChange={onChangeTaskName}
                Validator={remediationTrackerTaskPageState.Validator}
                Rules={"required"}
                IsEnableValidator={true}
                Required
                Disabled={
                  remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                  (remediationTrackerTaskPageState.RemediationTrackerTask
                    .Status > EnumRemediationTrackerTaskStatus.New &&
                    userScope !== SystemGroupScope.Global)
                }
              />
            </div>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} className="pt-0">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-group">
                  <HPRDropDownSingleSelect
                    Label="Category"
                    Name="category"
                    Select={
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        ?.AreaOwner
                    }
                    Items={
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        .AreaOwnerTypeDropDownList
                    }
                    Validator={remediationTrackerTaskPageState.Validator}
                    Rules={"required"}
                    IsEnableValidator={true}
                    Required
                    onDropDownChange={onChangeAreaOwnerType}
                    Disabled={
                      remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                      (remediationTrackerTaskPageState.RemediationTrackerTask
                        .Status > EnumRemediationTrackerTaskStatus.New &&
                        userScope !== SystemGroupScope.Global)
                    }
                  ></HPRDropDownSingleSelect>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <HPRDropDownMsGraphSingleSelect
                    Name="action-owner"
                    Label="Action Owner"
                    SelectedValue={
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        .SelectedActionOwnerId ?? "0"
                    }
                    Items={
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        ?.ActionOwnerDropDownList ?? ([] as Array<DropDownDTO>)
                    }
                    Url={
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        ?.ActionOwnerDropDownList &&
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        ?.ActionOwnerDropDownList?.length > 0
                        ? "RemediationTrackerTask/GetActionOwnerListForDropDown"
                        : ""
                    }
                    onDropDownChange={onChangeActionOwner}
                    Disabled={
                      remediationTrackerTaskPageState.PageMode?.IsDisable
                      // ||
                      // (remediationTrackerTaskState.RemediationTrackerTask
                      //   ?.Status ===
                      //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                      //   !checkSystemFunctionRight(
                      //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                      //     EnumSystemFunctionTypes.ButtonAccess
                      //   ))
                    }
                    IsSearchable
                  ></HPRDropDownMsGraphSingleSelect>
                </div>
              </Grid>
            </Grid>

            <div className="mb-20">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="ant-date-outer">
                    <HPRDatePicker
                      Label={"Due Date"}
                      Value={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          .DueDate
                      }
                      Name={"duedate"}
                      onDateChange={onDueDateChange}
                      IsDisabledDate={disabledDate}
                      Type={EnumDateType.DateOnly}
                      Required
                      Disabled={
                        remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                        (remediationTrackerTaskPageState.RemediationTrackerTask
                          .Status > EnumRemediationTrackerTaskStatus.New &&
                          userScope !== SystemGroupScope.Global)
                      }
                    ></HPRDatePicker>
                    {remediationTrackerTaskPageState.Validator.message(
                      "This field is required",
                      remediationTrackerTaskPageState.RemediationTrackerTask
                        .DueDate,
                      "required"
                    )}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="form-group">
                    <HPRDropDownSingleSelect
                      Label="Priority"
                      Name="priority"
                      Select={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          ?.Piority
                      }
                      Items={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          .PriorityDropDownList
                      }
                      onDropDownChange={onChangePriorityDropdown}
                      Validator={remediationTrackerTaskPageState.Validator}
                      Rules={"required|min:2,num"}
                      IsEnableValidator={true}
                      Required
                      Disabled={
                        remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                        (remediationTrackerTaskPageState.RemediationTrackerTask
                          .Status > EnumRemediationTrackerTaskStatus.New &&
                          userScope !== SystemGroupScope.Global)
                      }
                    ></HPRDropDownSingleSelect>
                  </div>
                </Grid>

                <Grid item xs={6} className="pt-0">
                  <div className="form-group mb-20">
                    <HPRDropDownSingleSelect
                      Label="Is CAPEX Required?"
                      Name="capex-required"
                      Select={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          ?.IsCapexrequired
                      }
                      Items={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          .IsCapexRequiredDropDownList
                      }
                      onDropDownChange={onChangeCapexRequired}
                      Validator={remediationTrackerTaskPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      Required
                      Disabled={
                        remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                        (remediationTrackerTaskPageState.RemediationTrackerTask
                          .Status > EnumRemediationTrackerTaskStatus.New &&
                          userScope != SystemGroupScope.Global)
                        // (remediationTrackerTaskState.RemediationTrackerTask
                        //   ?.Status ==
                        //   EnumRemediationTrackerTaskStatus.ReviewCompleted &&
                        //   !checkSystemFunctionRight(
                        //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Review,
                        //     EnumSystemFunctionTypes.ButtonAccess
                        //   )) ||
                        // (remediationTrackerTaskState.RemediationTrackerTask
                        //   ?.Status ==
                        //   EnumRemediationTrackerTaskStatus.ActionInProgress &&
                        //   !checkSystemFunctionRight(
                        //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Review,
                        //     EnumSystemFunctionTypes.ButtonAccess
                        //   )) ||
                        // ((remediationTrackerTaskState.RemediationTrackerTask
                        //   ?.Status ===
                        //   EnumRemediationTrackerTaskStatus.PendingReviewSME ||
                        //   remediationTrackerTaskState.RemediationTrackerTask
                        //     ?.Status ===
                        //     EnumRemediationTrackerTaskStatus.PendingReviewSite) &&
                        //   !checkSystemFunctionRight(
                        //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Review,
                        //     EnumSystemFunctionTypes.ButtonAccess
                        //   )) ||
                        // (remediationTrackerTaskState.RemediationTrackerTask
                        //   ?.Status ===
                        //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                        //   !checkSystemFunctionRight(
                        //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                        //     EnumSystemFunctionTypes.ButtonAccess
                        //   ))
                      }
                    ></HPRDropDownSingleSelect>
                  </div>
                </Grid>
                {remediationTrackerTaskPageState.ShowCapexFields && (
                  <Grid item xs={6} className="pt-0">
                    <div className="form-group">
                      <HPRTextBox
                        Name="capex-amount-required"
                        Value={
                          remediationTrackerTaskPageState.RemediationTrackerTask.CapexamountRequired?.toString() ??
                          ""
                        }
                        Label={`CAPEX Amount Required (${mainLayoutState.AppConfiguration?.CurrencyFormat})`}
                        Id={""}
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        InputProps={{ maxLength: 12 }}
                        ComponentProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                        onTextBoxChange={onChangeCapexAmount}
                        Validator={remediationTrackerTaskPageState.Validator}
                        //Rules={"required"}
                        IsEnableValidator={true}
                        //Required
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode
                            ?.IsDisable ||
                          (remediationTrackerTaskPageState
                            .RemediationTrackerTask.Status >
                            EnumRemediationTrackerTaskStatus.New &&
                            userScope !== SystemGroupScope.Global)
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ==
                          //   EnumRemediationTrackerTaskStatus.ReviewCompleted &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Review,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   )) ||
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ==
                          //   EnumRemediationTrackerTaskStatus.ActionInProgress &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Review,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   )) ||
                          // ((remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ===
                          //   EnumRemediationTrackerTaskStatus.PendingReviewSME ||
                          //   remediationTrackerTaskState.RemediationTrackerTask
                          //     ?.Status ===
                          //     EnumRemediationTrackerTaskStatus.PendingReviewSite) &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Review,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   )) ||
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ===
                          //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   ))
                        }
                      />
                    </div>
                  </Grid>
                )}
                {remediationTrackerTaskPageState.ShowCapexFields && (
                  <Grid item xs={6} className="pt-0">
                    <div className="form-group mb-0">
                      <HPRTextBoxAutoResize
                        Name="capex-num-name"
                        Title={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .CapexnumberAndName ?? ""
                        }
                        Value={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .CapexnumberAndName ?? ""
                        }
                        Label="CAPEX Number and Name"
                        Id={""}
                        Type={EnumTextBoxType.Text}
                        ClassName={"capex-num-name"}
                        InputProps={{ maxLength: 100 }}
                        TextLength={100}
                        ShowTextCounter
                        onTextBoxChange={onChangeCapexNumName}
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode?.IsDisable
                          //   ||
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ===
                          //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   ))
                        }
                      />
                    </div>
                  </Grid>
                )}
                {remediationTrackerTaskPageState.ShowCapexFields && (
                  <Grid item xs={6} className="pt-0">
                    <div className="">
                      <HPRDropDownSingleSelect
                        Label="CAPEX Status"
                        Name="capex-status"
                        Select={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            ?.Capexstatus
                        }
                        Items={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .CapexStatusDropDownList
                        }
                        onDropDownChange={onChangeCapexStatus}
                        Disabled={
                          remediationTrackerTaskPageState.PageMode?.IsDisable
                          // ||
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ===
                          //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   ))
                        }
                      ></HPRDropDownSingleSelect>
                    </div>
                  </Grid>
                )}

                {remediationTrackerTaskPageState.ShowCapexPlannedYear && (
                  <Grid item xs={6} className="pt-0">
                    <div className="form-group mb-0">
                      <HPRDropDownSingleSelect
                        Label="CAPEX Planned Year"
                        Name="capex-planned-year"
                        Select={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            ?.CapexplannedYear
                        }
                        Items={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .CapexPlannedYearDropDownList
                        }
                        onDropDownChange={onChangeCapexPlannedYear}
                        Disabled={
                          remediationTrackerTaskPageState.PageMode?.IsDisable
                          // ||
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ===
                          //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   ))
                        }
                      ></HPRDropDownSingleSelect>
                    </div>
                  </Grid>
                )}
                <Grid item xs={6} className="pt-0">
                  <div className="form-group mb-0">
                    <HPRTextBox
                      Name="er-number"
                      Value={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          .Ernumber ?? ""
                      }
                      Label="ER Number"
                      Id={""}
                      Type={EnumTextBoxType.Text}
                      ClassName={""}
                      InputProps={{ maxLength: 50 }}
                      TextLength={50}
                      ShowTextCounter
                      onTextBoxChange={onChangeErNum}
                      Disabled={
                        remediationTrackerTaskPageState?.PageMode?.IsDisable
                      }
                    />
                  </div>
                </Grid>
                {/* {pending approvers textbox} */}
                {(remediationTrackerTaskPageState.RemediationTrackerTask
                  .Status ===
                  EnumRemediationTrackerTaskStatus.PendingReviewSME ||
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .Status ===
                    EnumRemediationTrackerTaskStatus.PendingReviewSite ||
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .Status ===
                    EnumRemediationTrackerTaskStatus.PendingClosureApproval) && (
                  <Grid item xs={6} className="pt-0">
                    <div className="form-group">
                      <HPRTextBox
                        Name="pending approvers"
                        Value={
                          remediationTrackerTaskPageState.RemediationTrackerTask.PendingApproverDropDownList?.map(
                            (approver) => {
                              return approver.Label;
                            }
                          ).join(" | ") ?? ""
                        }
                        Label={`Pending Approver(s)`}
                        Id={""}
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        Disabled={true}
                        Title={
                          remediationTrackerTaskPageState.RemediationTrackerTask.PendingApproverDropDownList?.map(
                            (approver) => {
                              return approver.Label;
                            }
                          ).join(" | ") ?? ""
                        }
                      />

                      {/* <HPRDropDownMsGraphMultipleSelect
                        Id=""
                        Label="Pending Approver(s)"
                        Name="pending approvers"
                        Select={remediationTrackerTaskPageState.RemediationTrackerTask.PendingApproverDropDownList?.map(
                          (approver) => {
                            return approver?.Value ?? "";
                          }
                        )}
                        Items={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            ?.ActionOwnerDropDownList ??
                          ([] as Array<DropDownDTO>)
                        }
                        Disabled={true}
                        IsSearchable
                      /> */}
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6} className="pt-0" spacing={2}>
                {(remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.Closed ||
                  remediationTrackerTaskState.RemediationTrackerTask.Status ===
                    EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
                  remediationTrackerTaskState.RemediationTrackerTask.Status ===
                    EnumRemediationTrackerTaskStatus.Cancelled) && (
                  <div className="form-group pr-5 m-r-3">
                    <HPRTextBox
                      Name="user-date"
                      Title={getUserAndDateFieldValue()}
                      Value={getUserAndDateFieldValue()}
                      Label={getUserAndDateFieldLabel()}
                      Id={""}
                      Type={EnumTextBoxType.Text}
                      ClassName={""}
                      //InputProps={{ maxLength: 100 }}
                      //TextLength={100}
                      //ShowTextCounter
                      //onTextBoxChange={onChangeCapexNumName}
                      Disabled={
                        remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                        remediationTrackerTaskState.RemediationTrackerTask
                          ?.Status ===
                          EnumRemediationTrackerTaskStatus.Closed ||
                        remediationTrackerTaskState.RemediationTrackerTask
                          ?.Status ===
                          EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
                        remediationTrackerTaskState.RemediationTrackerTask
                          ?.Status ===
                          EnumRemediationTrackerTaskStatus.Cancelled
                      }
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={12} className="pt-0">
                {remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.Cancelled && (
                  <div className="form-group mb-0 mt-10">
                    <HPRTextBox
                      Name="Cancellation Reason"
                      Value={
                        remediationTrackerTaskPageState.RemediationTrackerTask
                          ?.CancelledReason ?? ""
                      }
                      Label="Cancellation Reason"
                      Id={""}
                      Type={EnumTextBoxType.Text}
                      ClassName={""}
                      InputProps={{ maxLength: 100 }}
                      TextLength={100}
                      ShowTextCounter
                      Disabled={
                        remediationTrackerTaskPageState?.PageMode?.IsDisable ||
                        remediationTrackerTaskState.RemediationTrackerTask
                          ?.Status ===
                          EnumRemediationTrackerTaskStatus.Cancelled
                      }
                    />
                  </div>
                )}

                {(remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.Closed ||
                  remediationTrackerTaskState.RemediationTrackerTask.Status ===
                    EnumRemediationTrackerTaskStatus.ClosedAsOverdue) &&
                  remediationTrackerTaskPageState.RemediationTrackerTask
                    .ClosureReason != null && (
                    <div className="form-group mb-0 mt-10">
                      <HPRTextBox
                        Name="Reason for closure"
                        Value={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            ?.ClosureReason ?? ""
                        }
                        Label="Reason for Closure"
                        Id={""}
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        InputProps={{ maxLength: 100 }}
                        TextLength={100}
                        ShowTextCounter
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode
                            ?.IsDisable ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            .Status ===
                            EnumRemediationTrackerTaskStatus.Closed ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            .Status ===
                            EnumRemediationTrackerTaskStatus.ClosedAsOverdue
                        }
                      />
                    </div>
                  )}
              </Grid>
              <Grid container spacing={2}>
                {/* {remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.Closed && (
                  <Grid item xs={6}>
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Name="approver"
                        Label="Approver"
                        Select={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .SelectedApproverId != null
                            ? remediationTrackerTaskPageState
                                .RemediationTrackerTask.SelectedApproverId
                            : 0
                        }
                        Items={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .ActionOwnerDropDownList
                        }
                        onDropDownChange={onChangeApprover}
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode?.IsDisable
                        }
                        IsSearchable
                      ></HPRDropDownSingleSelect>
                    </div>
                  </Grid>
                )} */}
              </Grid>
              {/* {(remediationTrackerTaskState.RemediationTrackerTask.Status ===
                EnumRemediationTrackerTaskStatus.Closed ||
                remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
                remediationTrackerTaskState.RemediationTrackerTask.Status ===
                  EnumRemediationTrackerTaskStatus.Cancelled) && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <HPRTextBox
                        Name="user-date"
                        Title={getUserAndDateFieldValue()}
                        Value={getUserAndDateFieldValue()}
                        Label={getUserAndDateFieldLabel()}
                        Id={""}
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        //InputProps={{ maxLength: 100 }}
                        //TextLength={100}
                        //ShowTextCounter
                        //onTextBoxChange={onChangeCapexNumName}
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode
                            ?.IsDisable ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.Closed ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.Cancelled
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <HPRDropDownSingleSelect
                        Name="closed-by"
                        Label="Closed By"
                        Select={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .CompletedById != null
                            ? remediationTrackerTaskPageState
                                .RemediationTrackerTask.CompletedById
                            : 0
                        }
                        Items={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .ActionOwnerDropDownList
                        }
                        onDropDownChange={onChangeClosedBy}
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode
                            ?.IsDisable ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.Closed ||
                          // remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ==
                          //   EnumRemediationTrackerTaskStatus.AutoClosed ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.ClosedAsOverdue
                        }
                        IsSearchable
                      ></HPRDropDownSingleSelect>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="ant-date-outer">
                      <HPRDatePicker
                        Label={"Closed Date"}
                        Value={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .CompletedDate
                        }
                        Name={"closeddate"}
                        onDateChange={onClosedDateChange}
                        IsDisabledDate={disabledDate}
                        Type={EnumDateType.DateOnly}
                        //Required
                        Disabled={
                          remediationTrackerTaskPageState?.PageMode
                            ?.IsDisable ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.Closed ||
                          // remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ==
                          //   EnumRemediationTrackerTaskStatus.AutoClosed
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.ClosedAsOverdue ||
                          remediationTrackerTaskState.RemediationTrackerTask
                            ?.Status ==
                            EnumRemediationTrackerTaskStatus.Cancelled
                        }
                      ></HPRDatePicker>
                    </div>
                  </Grid> 
                </Grid>
              )}*/}
            </div>

            <fieldset>
              <legend>Comment(s)</legend>
              <div className="form-group m-0">
                {/* <HPRTextBox
                  Name="comment"
                  Value={remediationTrackerTaskPageState.TaskComment}
                  Label="Comment"
                  Id={""}
                  Type={EnumTextBoxType.Text}
                  ClassName={""}
                  InputProps={{ maxLength: 500 }}
                  ShowTextCounter
                  TextLength={500}
                  MultiLine
                  onTextBoxChange={onChangeTaskComment}
                  Disabled={
                    remediationTrackerTaskPageState?.PageMode?.IsDisable &&
                    !remediationTrackerTaskPageState.RemediationTrackerTask
                      .ShowApprovalButton &&
                    !remediationTrackerTaskPageState.RemediationTrackerTask
                      .ShowRejectButton
                    //     ||
                    // remediationTrackerTaskPageState.RemediationTrackerTask
                    //   .Status === EnumRemediationTrackerTaskStatus.Closed
                    //   ||
                    // (remediationTrackerTaskState.RemediationTrackerTask
                    //   ?.Status ===
                    //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                    //   !checkSystemFunctionRight(
                    //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                    //     EnumSystemFunctionTypes.ButtonAccess
                    //   ))
                  }
                /> */}
                <HPRTextBoxAutoResize
                  Name="comment"
                  Value={remediationTrackerTaskPageState.TaskComment}
                  Label="Comment"
                  Id={""}
                  Type={EnumTextBoxType.Text}
                  ClassName={""}
                  InputProps={{ maxLength: 500 }}
                  ShowTextCounter
                  TextLength={500}
                  MultiLine
                  onTextBoxChange={onChangeTaskComment}
                  Disabled={
                    remediationTrackerTaskPageState?.PageMode?.IsDisable &&
                    !remediationTrackerTaskPageState.RemediationTrackerTask
                      .ShowApprovalButton &&
                    !remediationTrackerTaskPageState.RemediationTrackerTask
                      .ShowRejectButton
                    //     ||
                    // remediationTrackerTaskPageState.RemediationTrackerTask
                    //   .Status === EnumRemediationTrackerTaskStatus.Closed
                    //   ||
                    // (remediationTrackerTaskState.RemediationTrackerTask
                    //   ?.Status ===
                    //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                    //   !checkSystemFunctionRight(
                    //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                    //     EnumSystemFunctionTypes.ButtonAccess
                    //   ))
                  }
                />
              </div>
              {remediationTrackerTaskPageState.CommentsToShow?.length > 0 ? (
                <div className="comment-wrapper">
                  {remediationTrackerTaskPageState.CommentsToShow.map(
                    (
                      comment: RemediationTrackerTaskCommentDTO,
                      index: number
                    ) => (
                      <div
                        className="comment-sec similar-grid qmargin-b"
                        key={index}
                      >
                        <div className="flex--container space-between qmargin-b">
                          <div className="flex__item">
                            <div className="flex--container vlign-middle">
                              <div className="fle__item pr-10">
                                <Avatar
                                  alt=""
                                  src="/static/images/avatar/1.jpg"
                                  sx={{ width: 26, height: 26 }}
                                />
                              </div>
                              <div className="body3">
                                {comment?.ModifiedByNavigation?.DisplayName}
                              </div>
                              <div className="gray-text body3 pl-10">
                                {comment?.ModifiedDate != null
                                  ? dayjs(comment.ModifiedDate).format(
                                      "DD-MMM-YYYY HH:mm"
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                          {comment?.Id > 0 ? null : (
                            <div className="flex__item--inherit">
                              <div className="comment-right rotate90">
                                <More size="18" />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex--container  ">
                          <div className="fle__item body4 qwidth-set">
                            <div className="">
                              <Grid container>
                                <Grid item xs={12}>
                                  {comment?.Comment}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  {remediationTrackerTaskPageState.RemediationTrackerTask
                    .RemediationTrackerTaskComments?.length > 1 ? (
                    <a
                      className="see-more link-style"
                      onClick={showMoreCommentsClick}
                    >
                      {remediationTrackerTaskPageState.ShowMoreComments
                        ? "Show less"
                        : "Show more"}
                    </a>
                  ) : null}
                </div>
              ) : null}
            </fieldset>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="pt-0"
            style={{ marginTop: -15 }}
          >
            <fieldset style={{ marginBottom: 20 }}>
              <legend>Linked Question(s)</legend>
              <div className="similar-grid">
                <Grid container spacing={1} className="similar-grid-header">
                  {/* <Grid item lg={3} md={2} sm={2} xs={2}>
                    <Tooltip title="Location">
                      <span className="truncate">
                        <span>Location</span>
                      </span>
                    </Tooltip>
                  </Grid> */}
                  <Grid item lg={4} md={4} sm={4} xs={3}>
                    <Tooltip title="Inspection">
                      <span className="truncate">
                        <span>Inspection</span>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item lg={4} md={3} sm={3} xs={2}>
                    <Tooltip title="Standard">
                      <span className="truncate">
                        <span>Standard</span>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item lg={3} md={2} sm={2} xs={1}>
                    <Tooltip title="Q.No">
                      <span>
                        <span>Q.No</span>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item lg={1} md={3} sm={3} xs={2}></Grid>
                </Grid>
                {remediationTrackerTaskPageState.RemediationTrackerTask
                  .RemediationTrackerTaskQuestions?.length > 0
                  ? remediationTrackerTaskPageState.RemediationTrackerTask.RemediationTrackerTaskQuestions.map(
                      (
                        question: RemediationTrackerTaskQuestionDTO,
                        index: number
                      ) => (
                        <Grid
                          container
                          spacing={1}
                          className="similar-row"
                          key={index}
                        >
                          {/* <Grid item lg={3} md={2} sm={2} xs={2}>
                            <span>
                              <Tooltip
                                title={
                                  question.InspectionQuestion?.SiteInspection
                                    ?.Site?.SiteName
                                }
                              >
                                <span className="truncate">
                                  {
                                    question.InspectionQuestion?.SiteInspection
                                      ?.Site?.SiteName
                                  }
                                </span>
                              </Tooltip>
                            </span>
                          </Grid> */}
                          <Grid item lg={4} md={4} sm={4} xs={3}>
                            <span>
                              <Tooltip
                                title={
                                  question.InspectionQuestion?.SiteInspection
                                    ?.Name
                                }
                              >
                                <span className="truncate">
                                  {
                                    question.InspectionQuestion?.SiteInspection
                                      ?.Name
                                  }
                                </span>
                              </Tooltip>
                            </span>
                          </Grid>
                          <Grid item lg={4} md={3} sm={3} xs={2}>
                            <Tooltip
                              title={
                                question.InspectionQuestion?.InspectionStandard
                                  ?.Standard?.Name
                              }
                            >
                              <span className="truncate">
                                {
                                  question.InspectionQuestion
                                    ?.InspectionStandard?.Standard?.Name
                                }
                              </span>
                            </Tooltip>
                          </Grid>
                          <Grid item lg={3} md={2} sm={2} xs={1}>
                            <Tooltip
                              title={`Q${question.InspectionQuestion?.SequenceNumber}`}
                            >
                              <span className="truncate">
                                Q{question.InspectionQuestion?.SequenceNumber}
                              </span>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            lg={1}
                            md={3}
                            sm={3}
                            xs={2}
                            className="fx-right"
                          >
                            {question.IsAutoClosed && !question.IsActive ? (
                              <Button
                                className=""
                                onClick={() => {
                                  onViewInspectionQuestionOpen(
                                    question.InspectionQuestion?.Id
                                  );
                                }}
                                style={{
                                  visibility: checkSystemFunctionRight(
                                    EnumSystemFunctions.RemTrackerTaskPage_View_Question,
                                    EnumSystemFunctionTypes.ButtonAccess
                                  )
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                <Tooltip
                                  title={"This question is marked as No GAP"}
                                >
                                  <InfoCircle color="#ef5350" size={20} />
                                </Tooltip>
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  onViewInspectionQuestionOpen(
                                    question.InspectionQuestion?.Id
                                  );
                                }}
                                style={{
                                  visibility: checkSystemFunctionRight(
                                    EnumSystemFunctions.RemTrackerTaskPage_View_Question,
                                    EnumSystemFunctionTypes.ButtonAccess
                                  )
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                <Tooltip title={"View Question"}>
                                  <Eye className="ico-primary" size={20} />
                                </Tooltip>
                              </Button>
                            )}
                            {checkSystemFunctionRight(
                              EnumSystemFunctions.RemTrackerTaskPage_DeleteLinked_Questions,
                              EnumSystemFunctionTypes.ButtonAccess
                            ) &&
                            question.IsActive &&
                            remediationTrackerTaskPageState.showDeleteBtn &&
                            remediationTrackerTaskPageState.PageMode
                              .PageMode !== EnumPageMode.ViewMode &&
                            remediationTrackerTaskState.RemediationTrackerTask
                              .Status ===
                              EnumRemediationTrackerTaskStatus.New ? (
                              // ||
                              // checkSystemFunctionRight(
                              //   EnumSystemFunctions.RemTrackerTaskPage_EditTask,
                              //   EnumSystemFunctionTypes.ButtonAccess
                              // ))
                              <Button
                                onClick={() => {
                                  questionDeleteBtnClick(question.Id);
                                }}
                              >
                                <Tooltip title={"Delete Question"}>
                                  <Bag
                                    variant="Outline"
                                    className="ico-primary"
                                    size="20"
                                    color="#ef5350"
                                  />
                                </Tooltip>
                              </Button>
                            ) : null}
                          </Grid>
                        </Grid>
                      )
                    )
                  : null}
              </div>
            </fieldset>
            {remediationTrackerTaskPageState.PageMode.PageMode !==
            EnumPageMode.AddMode ? (
              //   ||
              // (remediationTrackerTaskState.RemediationTrackerTask?.Status ===
              //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
              //   !checkSystemFunctionRight(
              //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
              //     EnumSystemFunctionTypes.ButtonAccess
              //   ))
              <fieldset className="mb-20">
                <legend>Attachment(s)</legend>
                <div className="form-group mb-0">
                  <div className="item-desc-wrap pt-0">
                    <div style={{ width: "101%" }}>
                      <HPRFileUploaderDND
                        Label={""}
                        FileType={[
                          EnumFileType.Doc,
                          EnumFileType.Image,
                          EnumFileType.Email,
                          EnumFileType.Excel,
                        ]}
                        Name={""}
                        GetselectedFiles={getSelectedFiles}
                        GetError={getError}
                        UploadedFileList={
                          remediationTrackerTaskPageState.RemediationTrackerTask
                            .UploadFiles
                        }
                        //ShowComment
                        Multiple
                        PreviewImage={true}
                        Disabled={
                          remediationTrackerTaskPageState.PageMode?.IsDisable
                          // ||
                          // (remediationTrackerTaskState.RemediationTrackerTask
                          //   ?.Status ===
                          //   EnumRemediationTrackerTaskStatus.PendingClosureApproval &&
                          //   !checkSystemFunctionRight(
                          //     EnumSystemFunctions.RemTrackerTaskPage_Edit_In_Pending_Closure_Approval,
                          //     EnumSystemFunctionTypes.ButtonAccess
                          //   ))
                        }
                        ShowRemoveButton={
                          checkSystemFunctionRight(
                            EnumSystemFunctions.RemTrackerTaskPage_Remove_Attachments,
                            EnumSystemFunctionTypes.ButtonAccess
                          )
                            ? "visible"
                            : "hidden"
                        }
                      ></HPRFileUploaderDND>
                    </div>
                  </div>
                  {remediationTrackerTaskPageState.RemediationTrackerTask
                    ?.UploadFiles?.length == 0 ? (
                    <div className="remediation-no-data width100-p flex--container">
                      <img className="" src={noUploadImage} style={{}}></img>
                      <div className="gray-text body2 flex__item--vcenter nodata-text">
                        No attachments added
                      </div>
                    </div>
                  ) : null}
                </div>
              </fieldset>
            ) : null}
            {/* <div>
              {remediationTrackerTaskPageState.HistoryToShow?.length > 0 ? (
                <fieldset>
                  <legend>Approval History</legend>

                  <div className="comment-wrapper mb-20">
                    {remediationTrackerTaskPageState.HistoryToShow.map(
                      (
                        history: RemediationTrackerTaskHistoryDTO,
                        index: number
                      ) => (
                        <div
                          className="comment-sec similar-grid qmargin-b"
                          key={index}
                        >
                          <div className="flex--container space-between qmargin-b">
                            <div className="flex__item">
                              <div className="flex--container vlign-middle">
                                <div className="fle__item pr-10">
                                  <Avatar
                                    alt=""
                                    src="/static/images/avatar/1.jpg"
                                    sx={{ width: 26, height: 26 }}
                                  />
                                </div>
                                <div className="body3">
                                  {history?.ModifiedBy}
                                </div>
                                <div className="gray-text body3 pl-10">
                                  {history?.ModifiedDate != null
                                    ? dayjs(history.ModifiedDate).format(
                                        "DD-MMM-YYYY HH:mm"
                                      )
                                    : ""}
                                </div>
                              </div>
                            </div>
                            {history?.Id > 0 ? null : (
                              <div className="flex__item--inherit">
                                <div className="comment-right rotate90">
                                  <More size="18" />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex--container  ">
                            <div className="fle__item body4 qwidth-set">
                              <div className="">
                                <Grid container>
                                  <Grid item xs={12}>
                                    {history?.Message}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {remediationTrackerTaskPageState.RemediationTrackerTask
                      .RemediationTrackerTaskHistoryList?.length > 1 ? (
                      <a
                        className="see-more link-style"
                        onClick={showMoreHistoryClick}
                      >
                        {remediationTrackerTaskPageState.ShowMoreHistory
                          ? "Show less"
                          : "Show more"}
                      </a>
                    ) : null}
                  </div> 
                </fieldset>
              ) : null}
            </div>*/}
          </Grid>
        </Grid>

        {/* <ViewInspectionQuestion
          InspectionQuestionId={
            remediationTrackerTaskPageState
              .SelectedRemediationTrackerTaskQuestion?.InspectionQuestion?.Id
          }
          Open={
            remediationTrackerTaskPageState?.ViewInspectionQuestionModalOpen
          }
          onCloseBtnClick={onViewInspectionQuestionClose}
        ></ViewInspectionQuestion> */}
      </div>
    </>
  );
};
export default RemediationTrackerTaskPage;
