import {
  FormControl,
  FormHelperText,
  styled,
  TextField,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { DropDownDTO } from "../../../common/types/common.dto.types";
import { getDataFromBackendForMultipleSelectDropDown } from "./HPRDropDownMultipleSelect.api";
import { HPRDropDownMultipleSelectProps } from "./HPRDropDownMultipleSelect.types";

// ==============================|| COMPONENTS - COMMON - HPRDropDown ||============================== //
interface InitialState {
  SelectedValues: Array<string>;
  SelectOptions: Array<DropDownDTO>;
  SearchText: string;
}

const HPRDropDownMultipleSelect = (props: HPRDropDownMultipleSelectProps) => {
  const initialState = {
    SelectedValues: [] as Array<string>,
    SelectOptions: [] as Array<DropDownDTO>,
    SearchText: "",
  } as InitialState;

  const [MultipleSelect, setMultipleSelect] = useState(initialState);
  const dataFetchedRef = useRef(false);
  let SelectedDropDownList: Array<string> = props.Select;

  useEffect(() => {
    // If menuitem list received
    if (props.Items && props.Items.length > 0) {
      setMultipleSelect((values) => {
        return {
          ...values,
          SelectOptions: props.Items ? props.Items : ([] as Array<DropDownDTO>),
        };
      });
    }
    // If menuitem list not received
    else {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      getDataFromBackendForMultipleSelectDropDown(
        props.Url ? props.Url : ""
      ).then((response) => {
        setMultipleSelect((values) => {
          return { ...values, SelectOptions: response };
        });
      });
    }
  }, [props.Items]);

  const setMultipleSelectSearchText = (newValue: string) => {
    setMultipleSelect((values: any) => {
      return {
        ...values,
        SearchText: newValue,
      };
    });
  };

  const onSelectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: DropDownDTO[]
  ) => {
    setMultipleSelectSearchText("");
    if (props.onMultipleSelectDropDownChange)
      props.onMultipleSelectDropDownChange(
        props.Items != null && props.Items.length
          ? props.Items
          : MultipleSelect.SelectOptions,
        value
          ? (value.map((val: DropDownDTO) => val.Value) as string[])
          : ([] as Array<string>)
      );
  };

  // const onSelectionDelete = (value: any) => {
  //   var filteredValue = props.Select?.filter((item) => item !== value);

  //   if (props.onMultipleSelectDropDownChange)
  //     props.onMultipleSelectDropDownChange(
  //       props.Items != null && props.Items.length
  //         ? props.Items
  //         : MultipleSelect.SelectOptions,
  //       filteredValue
  //     );
  // };
  // style

  // const DropDownMultipleSelect = styled(InputBase)(({ theme }) => ({
  //   color: "red",
  //   "&& .MuiSelect-select": {
  //     borderColor: "red",
  //     "&:focus": {
  //       borderColor: "#8ecae6",
  //     },
  //   },
  // "& .MuiSelect-root": {
  //   backgroundColor: "red",
  // },
  // "& .MuiSelect-select, .MuiSelect-outlined": {
  //   "&:after": {
  //     borderColor: "#219EBC",
  //   },
  //   "& .MuiSvgIcon-root": {
  //     color: "#219EBC",
  //   },
  // },
  // "label + &": {
  //   fontSize: 18,
  // },
  // "& .MuiSelect-icon": {
  //   color: "#8ECAE6",
  // },
  // "& .MuiSelect-multiple": {
  //   "& fieldset": {
  //     borderColor: "#8ECAE6",
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "#219EBC",
  //   },
  //   "&.Mui-focused fieldset": {
  //     borderColor: "#219EBC",
  //   },
  // },
  // }));
  const isOptionEqualToValue = (option, value) => option.Value === value.Value;

  const onBlurChange = (e: React.FocusEvent) => {
    setMultipleSelectSearchText("");
  };

  const selectedValue = useMemo(() => {
    return SelectedDropDownList?.map((value) => {
      const selectedValue = MultipleSelect.SelectOptions?.find(
        (item) => item?.Value?.toString() === value?.toString()
      );
      return selectedValue || null;
    }).filter((value) => value !== null);
  }, [props.Select, MultipleSelect.SelectOptions]);
  return (
    <>
      <FormControl
        className="hpr-multiselect-dropdown"
        sx={{ m: 2 }}
        fullWidth
        disabled={props.Disabled}
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
          required={props.Required}
          disabled={props.Disabled}
        >
          {props.Label}
        </InputLabel>

        <Autocomplete
          id={props.Id && props.Id !== "" ? props.Id : props.Name}
          multiple
          disabled={props.Disabled}
          options={
            MultipleSelect.SelectOptions &&
            MultipleSelect.SelectOptions.length > 0
              ? MultipleSelect.SelectOptions
              : ([] as Array<DropDownDTO>)
          }
          getOptionLabel={(option: DropDownDTO) => {
            const selectedValue = MultipleSelect.SelectOptions?.find(
              (item) => item?.Value?.toString() === option?.Value?.toString()
            );
            return option?.Label
              ? option?.Label
              : selectedValue?.Label
              ? selectedValue?.Label
              : "";
          }}
          renderOption={(props, option: DropDownDTO) => {
            return (
              <li {...props} key={`${option.Value}_${option.Label}`}>
                {option?.Label}
              </li>
            );
          }}
          inputValue={MultipleSelect?.SearchText ?? ""}
          isOptionEqualToValue={isOptionEqualToValue}
          value={selectedValue}
          defaultValue={[]}
          filterSelectedOptions
          onChange={onSelectionChange}
          onBlur={onBlurChange}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              // placeholder={props.Label}
              className={`hpr-multiselect-dropdown ${
                props.Disabled ? "Mui-disabled" : ""
              }`}
              InputLabelProps={{ shrink: true }}
              inputProps={{ ...inputProps, readOnly: !props.IsSearchable }}
            />
          )}
          noOptionsText={"No record(s) found"}
          disableClearable={props.DisableClearable}
          onInputChange={(_, value, reason) => {
            if (reason === "clear") {
              setMultipleSelectSearchText("");
            } else if (reason === "input") {
              setMultipleSelectSearchText(value);
            }
          }}
        />

        {/* <Select
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: 1,
                "& .MuiMenuItem-root": {
                  padding: 2,
                },
              },
            },
          }}
          labelId="demo-simple-select-helper-label"
          id={props.Id}
          value={SelectedDropDownList}
          label={props.Label}
          onChange={onSelectionChange}
          disabled={props.Disabled}
          multiple
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.9 }}>
                {selected.map((value) => {
                  var LabelName = MultipleSelect.SelectOptions?.find(
                    (item) => item.Value.toString() === value.toString()
                  )?.Label;

                  return (
                    <Chip
                      key={value}
                      label={LabelName}
                      onDelete={(event) => {
                        if (!props.Disabled && event.button === 0) {
                          onSelectionDelete(value);
                        }
                      }}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      clickable={false}
                    />
                  );
                })}
              </Box>
            );
          }}
        >
          {MultipleSelect.SelectOptions &&
          MultipleSelect.SelectOptions.length > 0 ? (
            MultipleSelect.SelectOptions.map(
              (option: DropDownDTO, index: number) => {
                var valueExsit = props.Select?.find(
                  (item) => item.toString() === option.Value.toString()
                );
                return (
                  <MenuItem
                    key={index}
                    value={option.Value}
                    style={{
                      display: `${
                        valueExsit !== undefined && valueExsit !== ""
                          ? "none"
                          : "block"
                      }`,
                    }}
                  >
                    {option.Label}
                  </MenuItem>
                );
              }
            )
          ) : (
            <MenuItem>No items</MenuItem>
          )}
        </Select> */}
        {props.HelperText && (
          <FormHelperText>{props.HelperText}</FormHelperText>
        )}
        {props.IsEnableValidator ? (
          props.Validator.message(
            props.Name,
            props.Select ? props.Select : "",
            props.Rules ? props.Rules : ""
          )
        ) : (
          <></>
        )}
      </FormControl>
    </>
  );
};

export default HPRDropDownMultipleSelect;
