//-----------------------------------
export enum EnumStatus {
  All = 0,
  Published = 1,
  Draft = 2,
  Deactivated = 3,
  Archive = 4,
  Deleted = 5,
  Revision = 6,
  Preparing = 7,
}
//-----------------------------------

export enum EnumSMEType {
  Primary_SME = 1,
  Secondary_SME = 2,
  SiteLocalSME = 3,
  InspectionAssignmentSME = 4,
}

export enum EnumQuestionTypesType {
  Default = 0,
  FreeText = 1,
  YesNo = 2,
  MultipleAnswersSingleChoice = 3,
  MultiplAnswersMultipleChoice = 4,
}

// export enum EnumListShowRowCount {
//   ListShowRowCount = 10,
// }

export enum EnumGridDataShowRowCount {
  GridDataShowRowCount = 10,
}

export enum EnumPageMode {
  Default = 0,
  LoadingMode = 1,
  AddMode = 2,
  ViewMode = 3,
  EditMode = 4,
  RedirectAnothePage = 5,
}

export enum EnumStandardTypes {
  All = 1,
  Reference = 2,
  System = 3,
  SubSystem = 4,
  Subject = 5,
  Section = 6,
  Requirement = 7,
}

export enum EnumPageType {
  None = 0,
  Standards = 1,
  StandardQuestions = 2,
  InspectionSets = 3,
  Locations = 4,
  SiteInspections = 5,
  Standard = 6,
  Question = 7,
  Sources = 8,
  UITest = 9,
  Reference = 10,
  System = 11,
  SubSystem = 12,
  Subject = 13,
  Section = 14,
  InspectionSet = 15,
  InspectionAllocation = 16,
  AssignInspection = 17,
  Location = 18,
  SiteInspectionStart = 19,
  QuestionnaireStart = 20,
  Questionnaire = 21,
  QuestionnaireSummary = 22,
  RemediationTrackerTasks = 23,
  RemediationTrackerTask = 24,
  UserRoleManagement = 25,
  UserRoleManagementScreens = 26,
  UserRoleManagementLocations = 27,
  UserProfile = 28,
  AssessmentTypeWizard = 29,
}

export enum EnumButtonModeType {
  Save = 1,
  SaveAndClose = 2,
  SaveAndContinue = 3,
  Update = 4,
  UpdateAndClose = 5,
}

export enum EnumAttributeType {
  Allow = 1,
  Mandotory = 2,
  NotAllowed = 3,
}

export enum EnumInspectionStandardStatus {
  Intial = 10,
  Inprogress = 20,
  Completed = 30,
  Paused = 40,
}

export enum EnumInspectionQuestionStatus {
  Answered = 10,
  Gap = 20,
  Pending = 30,
  Skipped = 40,
}

export enum EnumInspectionAllocationShowYearCount {
  ShowYearCount = 5,
}

export enum EnumRecurrencepattern {
  Annually = 10,
  Quaterly = 20,
}

export enum EnumInspectionStatus {
  NotPlanned = 10,
  Scheduled = 20,
  InProgress = 30,
  Paused = 40,
  Completed = 50,
  Overdue = 60,
  Rejected = 70,
  Removed = 80,
  Exception = 90,
  Deactivated = 100,
}

export enum EnumFileType {
  Default = 1,
  Doc = 2,
  Image = 3,
  Email = 4,
  Excel = 5,
}

export enum EnumReportType {
  SummeryReport = 1,
  StandardReport = 2,
  RemediationTrackerTasksReport = 3,
  InspectionReport = 4,
  StandardQuestionsReport = 5,
  InspectionSetReport = 6,
  PendingApprovalsByRemediationTask = 7,
  RemediationTrackerTaskBasicStatisticReport = 8,
  AllInspectionSummaryReport = 9,
  LocationReport = 10
}

export enum EnumSearchBy {
  Question = 1,
  Reference = 2,
  System = 3,
  SubSystem = 4,
  Subject = 5,
  Section = 6,
}

export enum EnumDataTransferStatus {
  Default = 0,
  NotStarted = 1,
  InProgress = 2,
  Finished = 3,
}

export enum EnumInspectionSetListType {
  Initial = 0,
  Standard = 1,
  Reference = 2,
  System = 3,
  SubSystem = 4,
  Subject = 5,
  Section = 6,
  Question = 7,
}

export enum EnumListShowRowCountList {
  ListShowRowCountPerPage_1 = 5,
  ListShowRowCountPerPage_2 = 10,
  ListShowRowCountPerPage_3 = 15,
  ListShowRowCountPerPage_4 = 20,
  ListShowRowCountPerPage_5 = 25,
}

export enum EnumRemediationTrackerTaskStatus {
  New = 10,
  PendingReviewSME = 20,
  PendingReviewSite = 25,
  ReviewCompleted = 30,
  ActionInProgress = 40,
  PendingClosureApproval = 50,
  Closed = 60,
  AutoClosed = 65,
  ClosedAsOverdue = 70,
  Cancelled = 80,
  Deactivated = 90,
  Overdue = 100, // don't delete
}

export enum EnumRemediationTrackerTaskPriority {
  None = 1,
  Low = 2,
  Medium = 3,
  High = 4,
  Critical = 5,
}

export enum EnumCapexStatus {
  Approved = 1,
  Rejected = 2,
  PendingReview = 3,
}

export enum EnumIsCapexRequired {
  No = 1,
  YesInPlan = 2,
  YesToBeRequested = 3,
}

export enum EnumRemediationTrackerSMEApprovalsType {
  OneFromEach = 1,
  OnlyOneFromAny = 2,
}

export enum EnumRemediationTrackerSiteExecutivesApprovalType {
  All = 1,
  EitherOne = 2,
  OnlySelected = 3,
}

// Sort Types
export enum EnumSortTypes {
  Ascending = 1,
  Descending = 2,
}

export enum EnumRemediationTrackerTaskColumnType {
  Location = 1,
  Inspection = 2,
  Standard = 3,
  QuestionNumber = 4,
  TaskName = 5,
  Status = 6,
  ActionOwner = 7,
  Priority = 8,
  DueDate = 9,
  Approver = 10,
  IsCapexrequired = 11,
  CapexamountRequired = 12,
  CapexnumberAndName = 13,
  Capexstatus = 14,
  AreaOwner = 15, //Category
  Identifier = 16,
  NoOfTask = 17,
  PendingApprovers = 18,
  TaskId = 19,
}

export enum EnumStandardQuestionSortType {
  Name = 1,
  NameDescending = 4,
  QuestionNumber = 3,
  QuestionNumberDescending = 6,
  LastModifiedUser = 2,
  LastModifiedUserDescending = 8,
  LastModifiedDateTime = 5,
  LastModifiedDateTimeDescending = 9,
  Status = 7,
  StatusDescending = 10,
}

export enum EnumStandardSortType {
  Name = 1,
  TotalQuestions = 3,
  LastModifiedUser = 2,
  Status = 7,
  LastModifiedDateTime = 5,
}
export enum EnumInspectionSetSortType {
  Name = 1,
  NameDescending = 3,
  LastModifiedUser = 2,
  LastModifiedUserDescending = 4,
  LastModifiedDateTime = 5,
  LastModifiedDateTimeDescending = 6,
  Status = 7,
  Type = 8,
}

export enum EnumInspectionSortType {
  Scheduling = 1,
  Gaps = 2,
  LocationName = 3,
  InspectionSetName = 4,
  Status = 5,
}

export enum EnumLocationSortType {
  Name = 1,
  NameDescending = 2,
  CountryName = 3,
  CountryNameDescending = 6,
  RegionName = 4,
  RegionNameDescending = 9,
  Status = 5,
  StatusDescending = 10,
  LastModifiedUser = 7,
  LastModifiedUserDescending = 11,
  LastModifiedDateTime = 8,
  LastModifiedDateTimeDescending = 12,
}

export enum EnumStandardSourceSortType {
  Name = 1,
  Url = 2,
  LastModifiedUser = 3,
  LastModifiedDate = 4,
}

export enum EnumAuthenticationModes {
  Default = 0,
  MicrosoftActiveDirectoryAuthentication = 1,
  MicrosoftAzureActiveDirectoryAuthentication = 2,
  BasicAuthentication = 3,
}

export enum EnumAzureAuthenticationWindowMode {
  InWindow = 1,
  Popup = 2,
}

export enum SystemGroupScope {
  Global = 1,
  Site = 2,
}

export enum EnumUserRoleManagementSortType {
  Groupname = 1,
  Status = 2,
  CreatedBy = 3,
  CreatedDate = 4,
  ModifiedBy = 5,
  ModifiedDate = 6,
  Scope = 7,
}

export enum EnumStages {
  SiteInspectionReviewByGlobalSME = 1,
  TrackTasks = 2,
  ReviewByGlobalSME = 3,
  ReviewBySiteLeadership = 4,
  ClosureApproval = 5,
  CloseTaskIfERNumberEntered = 6,
}

export enum EnumWorkflowRules {
  SiteInspectionGlobalSMEReviewRequired = 1,
  TrackTasksRequired = 2,
  GlobalSMEReviewRequired = 3,
  GlobalSMEApprovalByAllRoles = 4,
  GlobalSMEApprovalByOneRole = 5,
  GlobalPrimarySMEApprovalRequired = 6,
  GlobalSecondarySMEApprovalRequired = 7,
  InspectionAssignmentModuleSMEApprovalRequired = 8,
  SiteLeadershipReviewRequired = 9,
  SiteLeadershipApprovalByAllRoles = 10,
  SiteLeadershipApprovalByOneRole = 11,
  SiteLeadershipDirectorApprovalRequired = 12,
  SiteLeadershipQADirectorApprovalRequired = 13,
  SiteLeadershipEngineeringDirectorApprovalRequired = 14,
  SiteLeadershipEHSDirectorApprovalRequired = 15,
  SiteLeadershipQCManagerApprovalRequired = 16,
  SiteLeadershipMaintenanceManagerApprovalRequired = 17,
  ClosureApprovalRequired = 18,
  CloseTaskIfERNumberEnteredRequired = 19,
}

export enum EnumAssessmentTypeWizardSortType {
  AssessmentType = 1,
  Description = 2,
  Code = 3,
  Status = 4,
  CreatedBy = 5,
  CreatedDateAndTime = 6,
  ModifiedBy = 7,
  ModifiedDateAndTime = 8,
}

export enum EnumEnviorments {
  Development = "Development",
  Production = "PROD",
  QA = "QA",
}

export enum EnumApprovalStatus {
  Pending = 1,
  Approved = 2,
  Skipped = 3,
  Reject = 4,
}

export enum EnumEmailModule {
  RemediationTracker = 1,
  SiteInspection = 2,
}
