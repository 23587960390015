import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { HPRDatePickerProps } from "./HPRDatePicker.types";
import { EnumDateType } from "./HPRDatePicker.enum";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

// ==============================|| COMPONENTS - COMMON - HPRDATEPICKER ||============================== //

// DEFAULT SHOW DATE & TIME
const HPRDatePicker = (props: HPRDatePickerProps) => {
  let date: Dayjs | null = dayjs(props.Value);

  const OnDateChange = (newValue: dayjs.Dayjs, format: string) => {
    if (props.onDateChange) {
      props.onDateChange(
        props.Name,
        newValue != undefined ? dayjs(newValue).format(format) : ""
      );
    }
  };

  const OnDateRangeChange = (dates: Dayjs[], format: string) => {
    if (props.OnDateRangeChange) {
      const fromDate: Dayjs | null = dates ? dayjs(dates[0]) : null;
      const toDate: Dayjs | null = dates ? dayjs(dates[1]) : null;

      props.OnDateRangeChange(
        props.Name,
        fromDate?.isValid() ? fromDate.format(format) : "",
        toDate?.isValid() ? toDate.format(format) : ""
      );
    }
  };

  const getDefaultDate = (): dayjs.Dayjs => {
    if (date?.isValid()) {
      return date;
    } else if (dayjs(props.DefaultValue).isValid()) {
      return dayjs(props.DefaultValue);
    } else {
      dayjs();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {props.Type === EnumDateType.DateOnly && (
        <>
          <div className="ant-date">
            <DatePicker
              name={props.Name}
              value={date?.isValid() ? date : undefined}
              onChange={(newValue) => {
                OnDateChange(newValue, "DD-MMM-YYYY");
              }}
              defaultPickerValue={getDefaultDate()}
              disabled={props.Disabled}
              format="DD-MMM-YYYY"
              disabledDate={(currentDate) => {
                return props.IsDisabledDate
                  ? props.IsDisabledDate(currentDate)
                  : false;
              }}
              className={`${props.ClassName ? props.ClassName : ""}`}
              placeholder={props.Placeholder ?? "Select a date"}
            />
            <div
              className={`AntLabel-shrink ${props.Disabled ? "disabled" : ""}`}
            >
              {props.Label ?? ""}{" "}
              {props.Required ? <span className="asterix">*</span> : null}
            </div>
          </div>
        </>
      )}

      {props.Type === EnumDateType.DateRangePicker && (
        <>
          <div className="ant-date">
            <RangePicker
              name={props.Name}
              value={
                props.Value && props.Value?.length === 2
                  ? [
                      props.Value[0] ? dayjs(props.Value[0]) : undefined,
                      props.Value[1] ? dayjs(props.Value[1]) : undefined,
                    ]
                  : undefined
              }
              onChange={(newValue) => {
                OnDateRangeChange(newValue, "DD-MMM-YYYY");
              }}
              disabled={props.Disabled}
              format="DD-MMM-YYYY"
              disabledDate={(currentDate) => {
                return props.IsDisabledDate
                  ? props.IsDisabledDate(currentDate)
                  : false;
              }}
              className={`${props.ClassName ? props.ClassName : ""}`}
              placeholder={["Select from date", "Select to date"]}
            />
            <div
              className={`AntLabel-shrink ${props.Disabled ? "disabled" : ""}`}
            >
              {props.Label ?? ""}{" "}
              {props.Required ? <span className="asterix">*</span> : null}
            </div>
          </div>
        </>
      )}
    </LocalizationProvider>
  );
};

export default HPRDatePicker;
