import {
  RemediationTrackerTasksDownloadStatusType,
  RemediationTrackerTasksExcelReportFilterType,
} from "./RemediationTrackerTasksReport.types";
import { DirectInbox } from "iconsax-react";
import remediationTrackerTasksReportSagaActions from "./RemediationTrackerTasksReport.actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import { remediationTrackerTasksReportReducerActions } from "./RemediationTrackerTasksReport.reducer";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import {
  EnumSystemFunctions,
  EnumSystemFunctionTypes,
} from "../../../core/reducers/functionRights.types";

const RemediationTrackerTasksExcelDownload: React.FC<
  RemediationTrackerTasksExcelReportFilterType
> = (props) => {
  const initialState = [
    {
      ExportFullReport: false,
      IsDownloading: false,
    },
  ] as Array<RemediationTrackerTasksDownloadStatusType>;
  const state = useAppSelector(
    (state) => state.remediationTrackerTasksReportReducer
  );

  const authState = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();
  const isInProgress = useRef(false);

  const [taskReportanchorEl, setTaskReportanchorEl] =
    useState<null | HTMLElement>(null);
  const taskReportOpen = Boolean(taskReportanchorEl);

  const [isReportDownloading, setIsReportDownloading] = useState(initialState);

  useEffect(() => {
    setIsReportDownloading(state.RemediationTrackerTasksReportStatus);
  }, [state]);

  //open and close download popup Menu Item
  const onClickReportDownload = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setTaskReportanchorEl(event.currentTarget);
  };

  const handleReportDownloadClose = () => {
    setTaskReportanchorEl(null);
  };

  const downloadRemediationTrackerTasks = (
    reportType: EnumReportType,
    exportFullReport: boolean,
    searchParameter?: SearchParameterDTO
  ) => {
    isInProgress.current = true;
    dispatch(
      remediationTrackerTasksReportReducerActions.setRemediationTrackerTasksReportStatus(
        {
          ExportFullReport: exportFullReport,
          IsDownloading: true,
          ReportType: reportType,
        }
      )
    );
    dispatch(
      remediationTrackerTasksReportSagaActions.getRemediationTrackerTasksSummary(
        {
          ReportType: reportType,
          ReportSearchParameter: searchParameter,
        } as ReportViewerType
      )
    );
    handleReportDownloadClose();
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.RemediationTrackerTask,
      functionId,
      functionTypeId
    );
  };

  const openDownloadMenu = (): boolean => {
    if (
      checkSystemFunctionRight(
        EnumSystemFunctions.RemTrackerTasksPage_Export,
        EnumSystemFunctionTypes.ButtonAccess
      ) ||
      checkSystemFunctionRight(
        EnumSystemFunctions.RemTrackerTasksPage_Export_TasksPendingApprovers,
        EnumSystemFunctionTypes.ButtonAccess
      ) ||
      checkSystemFunctionRight(
        EnumSystemFunctions.RemTrackerTasksPage_Export_BasicStatistic,
        EnumSystemFunctionTypes.ButtonAccess
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {/* <Button className="mb-10 excel" onClick={onClickReportDownload}>
        <Document className="m-r-10" style={{ fontSize: 20 }} />
        <span> Export</span>
      </Button> */}
      <Button
        onClick={onClickReportDownload}
        className="rem-lined-btn"
        disableElevation
        size="small"
        variant="outlined"
        startIcon={<DirectInbox variant="Outline" size={20} />}
      >
        Export
      </Button>

      <Menu
        className="sort-dialog-content"
        open={taskReportOpen && openDownloadMenu()}
        anchorEl={taskReportanchorEl}
        onClose={handleReportDownloadClose}
      >
        {checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTasksPage_Export,
          EnumSystemFunctionTypes.ButtonAccess
        ) && (
          <Grid display={"flex"} alignItems={"center"}>
            {isReportDownloading.map(
              (item, index) =>
                !item.ExportFullReport &&
                item.IsDownloading &&
                item.ReportType ===
                  EnumReportType.RemediationTrackerTasksReport && (
                  <CircularProgress key={index} size={20} />
                )
            )}
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                downloadRemediationTrackerTasks(
                  EnumReportType.RemediationTrackerTasksReport,
                  false,
                  props.RemediationTrackerTasksExcelReportFilter
                );
              }}
              disabled={
                !props.ReportViewer?.IsReportDataFound ||
                isReportDownloading.some(
                  (item) =>
                    !item.ExportFullReport &&
                    item.IsDownloading &&
                    item.ReportType ===
                      EnumReportType.RemediationTrackerTasksReport
                )
              }
            >
              Filtered Record(s) Only
            </MenuItem>
          </Grid>
        )}
        {checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTasksPage_Export,
          EnumSystemFunctionTypes.ButtonAccess
        ) && (
          <Grid container display={"flex"} alignItems={"center"}>
            {isReportDownloading.map(
              (item, index) =>
                item.ExportFullReport &&
                item.IsDownloading &&
                item.ReportType ===
                  EnumReportType.RemediationTrackerTasksReport && (
                  <CircularProgress key={index} size={20} />
                )
            )}
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                downloadRemediationTrackerTasks(
                  EnumReportType.RemediationTrackerTasksReport,
                  true
                );
              }}
              disabled={isReportDownloading.some(
                (item) =>
                  item.ExportFullReport &&
                  item.IsDownloading &&
                  item.ReportType ===
                    EnumReportType.RemediationTrackerTasksReport
              )}
            >
              All Record(s)
            </MenuItem>
          </Grid>
        )}
        {checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTasksPage_Export_TasksPendingApprovers,
          EnumSystemFunctionTypes.ButtonAccess
        ) && (
          <Grid container display={"flex"} alignItems={"center"}>
            {isReportDownloading.map(
              (item, index) =>
                item.IsDownloading &&
                item.ReportType ===
                  EnumReportType.PendingApprovalsByRemediationTask && (
                  <CircularProgress key={index} size={20} />
                )
            )}
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                downloadRemediationTrackerTasks(
                  EnumReportType.PendingApprovalsByRemediationTask,
                  true
                );
              }}
              disabled={isReportDownloading.some(
                (item) =>
                  item.IsDownloading &&
                  item.ReportType ===
                    EnumReportType.PendingApprovalsByRemediationTask
              )}
            >
              Task(s) Pending Approver(s) Report
            </MenuItem>
          </Grid>
        )}
        {checkSystemFunctionRight(
          EnumSystemFunctions.RemTrackerTasksPage_Export_BasicStatistic,
          EnumSystemFunctionTypes.ButtonAccess
        ) && (
          <Grid container display={"flex"} alignItems={"center"}>
            {isReportDownloading.map(
              (item, index) =>
                item.IsDownloading &&
                item.ReportType ===
                  EnumReportType.RemediationTrackerTaskBasicStatisticReport && (
                  <CircularProgress key={index} size={20} />
                )
            )}
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                downloadRemediationTrackerTasks(
                  EnumReportType.RemediationTrackerTaskBasicStatisticReport,
                  true
                );
              }}
              disabled={isReportDownloading.some(
                (item) =>
                  item.IsDownloading &&
                  item.ReportType ===
                    EnumReportType.RemediationTrackerTaskBasicStatisticReport
              )}
            >
              Basic Statistic Report
            </MenuItem>
          </Grid>
        )}
      </Menu>
    </>
  );
};

export default RemediationTrackerTasksExcelDownload;
