import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  InputBase,
  Breadcrumbs,
  Link,
  Stack,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
/* Icons */
import {
  CardSend,
  CloseCircle,
  ArrowLeft2,
  CardEdit,
  ArrowRight2,
  SearchNormal1,
  Edit,
  CardSlash,
} from "iconsax-react";

/* Components */

import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import HPRInspectionSetDragItem from "../../../components/common/HPRInspectionSetDragItem/HPRInspectionSetDragItem";
import {
  EnumInspectionSetListType,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { useLocation, useNavigate } from "react-router-dom";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  EnumListType,
  InspectionSetPageParametersType,
  InspectionSetPageType,
} from "./InspectionSetPage.types";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  deactivateInspectionSetApi,
  getAvailableData,
  saveSelectedData,
} from "./InspectionSetPage.action";
import {
  DropDownDTO,
  InspectionSetListTypeDTO,
  InspectionSetSelectDTO,
} from "../../../common/types/common.dto.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import SimpleReactValidator from "simple-react-validator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  changePageMode,
  setInitialData,
  setInspectionSetName,
  setSearchInspectionSetListAvailable,
  setSelectedData,
} from "./InspectionSetPage.reducer";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import InspectionSetReportExcelDownload from "../../Reports/InspectionSetReport/InspectionSetReportExcel";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";

const InspectionSetPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  // set initial state values for InspectionSetPageType
  const initialState = {
    IsLoading: false,
    PageMode: {
      IsDisable: false,
      IsShowSaveBtn: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      PageMode: EnumPageMode.Default,
    } as PageModeType,
    InspectionSet: {
      InspectionName: "",
      InspectionId: 0,
      Status: "",
      StatusId: EnumStatus.Draft,
      SelectedAssessmentTypeId: 0,
      InspectionSetListAvailable: [] as Array<InspectionSetSelectDTO>,
      InspectionSetListSelected: [] as Array<InspectionSetSelectDTO>,
      AssessmentTypeList: [] as Array<DropDownDTO>,
    } as InspectionSetListTypeDTO,
    SearchInspectionSetListAvailable: {
      InspectionId: 0,
      Name: "",
      InspectionSetListType: EnumInspectionSetListType.Initial,
      StandardId: 0,
      StandardSystemId: null,
      StandardSubSystemId: null,
      StandardSubectId: null,
      StandardReferenceId: null,
      StandardSectionId: null,
      IsSelected: true,
    } as InspectionSetSelectDTO,
    SearchInspectionSetListSelected: {
      InspectionId: 0,
      Name: "",
      InspectionSetListType: EnumInspectionSetListType.Initial,
      StandardId: 0,
      StandardSystemId: null,
      StandardSubSystemId: null,
      StandardSubectId: null,
      StandardReferenceId: null,
      StandardSectionId: null,
      IsSelected: false,
    } as InspectionSetSelectDTO,
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    CheckAllAvailable: false,
    CheckAllSelected: false,
    ClickInspectionSetItem: {} as InspectionSetSelectDTO,
  } as InspectionSetPageType;

  // create state for InspectionSetageType with initialState values
  const [InspectionSetPageState, setInspectionSetPageState] =
    useState(initialState);

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: InspectionSetPageParametersType = location.state;

  // reducer manage fucntions
  const InspectionSetState = useAppSelector(
    (state) => state.inspectionSetReducer
  );
  const dispatch = useAppDispatch();

  // use for page navigation
  const navigate = useNavigate();

  // check when one time call to get InspectionSet data from backend
  const dataFetchedRef = useRef(false);

  // load page with parameter and with out
  useEffect(() => {
    if (dataFetchedRef.current) return;
    if (
      parameter?.InspectionSetId < 0 ||
      parameter?.InspectionSetId === undefined
    ) {
      dataFetchedRef.current = true;
      dispatch(setInitialData(initialState));
      dispatch(getAvailableData(initialState));
      dispatch(changePageMode(EnumPageMode.AddMode));
    } else {
      dataFetchedRef.current = true;

      dispatch(
        setInitialData({
          ...initialState,
          SearchInspectionSetListAvailable: {
            ...initialState.SearchInspectionSetListAvailable,
            InspectionId: parameter?.InspectionSetId,
          },
          SearchInspectionSetListSelected: {
            ...initialState.SearchInspectionSetListSelected,
            InspectionId: parameter?.InspectionSetId,
          },
        })
      );

      dispatch(
        getAvailableData({
          ...initialState,
          SearchInspectionSetListAvailable: {
            ...initialState.SearchInspectionSetListAvailable,
            InspectionId: parameter?.InspectionSetId,
          },
        })
      );

      dispatch(
        changePageMode(
          parameter?.InspectionSetId === 0
            ? EnumPageMode.AddMode
            : EnumPageMode.ViewMode
        )
      );
    }
  }, []);

  //TODO - uncomment
  // set navigation parameter after save new inspection set
  // useEffect(() => {
  //   if (!dataFetchedRef.current) return;

  //   if (
  //     InspectionSetState.InspectionSet?.InspectionId !==
  //       parameter?.InspectionSetId &&
  //     InspectionSetPageState.PageMode?.PageMode === EnumPageMode.AddMode
  //   ) {
  //     navigate("/inspectionSet", {
  //       state: {
  //         InspectionSetId: InspectionSetState.InspectionSet?.InspectionId,
  //       } as InspectionSetPageParametersType,
  //     });
  //   }
  // }, [InspectionSetState.InspectionSet?.InspectionId]);

  // page unload validations
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    // YesBtnClick() {
    // },
  } as UsePromptTypes;
  usePrompt(prompt);
  //-----Button functions-----//

  //check validation
  const inspectionSetPagevalidation = (): boolean => {
    let changesExsit = true;
    if (InspectionSetState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        InspectionSetState?.InspectionSet?.InspectionName !== "" ||
        InspectionSetState?.InspectionSet?.InspectionSetListSelected?.length !==
          0 ||
        InspectionSetState?.InspectionSet?.SelectedAssessmentTypeId !== 0
      ) {
        changesExsit = false;
      }
    } else {
      if (
        JSON.stringify(
          InspectionSetState?.InspectionSet?.InspectionSetListSelected
        ) !==
          JSON.stringify(
            InspectionSetState?.OriginalInspectionSet?.InspectionSetListSelected
          ) ||
        InspectionSetState?.InspectionSet?.InspectionName !==
          InspectionSetState?.OriginalInspectionSet?.InspectionName ||
        InspectionSetState?.InspectionSet?.SelectedAssessmentTypeId !==
          InspectionSetState?.OriginalInspectionSet?.SelectedAssessmentTypeId
      ) {
        changesExsit = false;
      }
    }

    setIsDirty(!changesExsit);
    return changesExsit;
  };

  //check page validation
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    inspectionSetPagevalidation();
  }, [InspectionSetPageState]);

  //On reducer status change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    const SearchAvailableParamter =
      InspectionSetState?.SearchInspectionSetListAvailable;
    const SearchSelectedParamter =
      InspectionSetState?.SearchInspectionSetListSelected;

    const AvailableSelectedList =
      InspectionSetState.InspectionSet?.InspectionSetListAvailable?.filter(
        (filter) => {
          return (
            SearchAvailableParamter.StandardId === filter.StandardId &&
            SearchAvailableParamter.StandardReferenceId ===
              filter.StandardReferenceId &&
            SearchAvailableParamter.StandardSystemId ===
              filter.StandardSystemId &&
            SearchAvailableParamter.StandardSubSystemId ===
              filter.StandardSubSystemId &&
            SearchAvailableParamter.StandardSubectId ===
              filter.StandardSubectId &&
            SearchAvailableParamter.StandardSectionId ===
              filter.StandardSectionId &&
            filter?.Name?.toUpperCase().includes(
              SearchAvailableParamter?.Name?.toUpperCase()
            ) &&
            !filter.IsHide
          );
        }
      );

    let filteredSelectedList =
      InspectionSetState.InspectionSet?.InspectionSetListSelected?.filter(
        (filter) => {
          return (
            SearchSelectedParamter.StandardId === filter.StandardId &&
            SearchSelectedParamter.StandardReferenceId ===
              filter.StandardReferenceId &&
            SearchSelectedParamter.StandardSystemId ===
              filter.StandardSystemId &&
            SearchSelectedParamter.StandardSubSystemId ===
              filter.StandardSubSystemId &&
            SearchSelectedParamter.StandardSubectId ===
              filter.StandardSubectId &&
            SearchSelectedParamter.StandardSectionId ===
              filter.StandardSectionId &&
            filter?.Name?.toUpperCase().includes(
              SearchSelectedParamter?.Name?.toUpperCase()
            )
          );
        }
      );

    if (
      InspectionSetState?.SearchInspectionSetListAvailable
        .InspectionSetListType === EnumInspectionSetListType.Initial &&
      InspectionSetState?.SearchInspectionSetListSelected
        .InspectionSetListType === EnumInspectionSetListType.Initial
    ) {
      setDateWhenReducerChange(
        AvailableSelectedList,
        filteredSelectedList,
        EnumInspectionSetListType.Standard
      );
    } else {
      if (
        InspectionSetState.InspectionSet?.InspectionSetListSelected?.length >
          0 &&
        filteredSelectedList?.length === 0 &&
        InspectionSetState.SearchInspectionSetListSelected
          ?.InspectionSetListType !== EnumInspectionSetListType.Standard
      ) {
        const isLinkEnable = isInspectionSetListTypeLinkEnable(
          InspectionSetState.SearchInspectionSetListSelected
        );

        const inspectionSetListType = isLinkEnable
          ? InspectionSetState.SearchInspectionSetListSelected
              ?.InspectionSetListType
          : InspectionSetState.SearchInspectionSetListSelected
              ?.InspectionSetListType - 1;

        onClickBreadcrumbsAvailable(inspectionSetListType);
      } else {
        setDateWhenReducerChange(
          AvailableSelectedList,
          filteredSelectedList,
          InspectionSetState?.SearchInspectionSetListAvailable
            .InspectionSetListType
        );
      }
    }
  }, [InspectionSetState]);

  const isInspectionSetListTypeLinkEnable = (
    searchParameter: InspectionSetSelectDTO
  ): boolean => {
    switch (searchParameter?.InspectionSetListType) {
      case EnumInspectionSetListType.Reference:
        return searchParameter.StandardReferenceId !== null;
      case EnumInspectionSetListType.System:
        return searchParameter.StandardSystemId !== null;
      case EnumInspectionSetListType.SubSystem:
        return searchParameter.StandardSubSystemId !== null;
      case EnumInspectionSetListType.Subject:
        return searchParameter.StandardSubectId !== null;
      case EnumInspectionSetListType.Section:
        return searchParameter.StandardSectionId !== null;
      default:
        return true;
    }
  };

  const setDateWhenReducerChange = (
    dataListAvailable: Array<InspectionSetSelectDTO>,
    dataListSelected: Array<InspectionSetSelectDTO>,
    inspectionSetListType: EnumInspectionSetListType
  ) => {
    const CheckAllAvailable =
      dataListAvailable?.filter((filter) => filter.IsSelected).length ===
      dataListAvailable?.length;

    const CheckAllSelected =
      dataListSelected?.filter((filter) => filter.IsSelected).length ===
      dataListSelected?.length;

    setInspectionSetPageState((values) => {
      return {
        ...values,
        InspectionSet: {
          ...values.InspectionSet,
          InspectionId: InspectionSetState?.InspectionSet?.InspectionId,
          InspectionName: InspectionSetState?.InspectionSet?.InspectionName,
          Version: InspectionSetState?.InspectionSet?.Version,
          Status: InspectionSetState?.InspectionSet?.Status,
          StatusId: InspectionSetState?.InspectionSet?.StatusId,
          InspectionSetListAvailable: dataListAvailable ?? dataListAvailable,
          InspectionSetListSelected: dataListSelected,
        },
        SearchInspectionSetListAvailable: {
          ...InspectionSetState?.SearchInspectionSetListAvailable,
          InspectionSetListType: inspectionSetListType,
        },
        SearchInspectionSetListSelected: {
          ...InspectionSetState?.SearchInspectionSetListSelected,
          InspectionSetListType: inspectionSetListType,
        },
        CheckAllAvailable: CheckAllAvailable,
        CheckAllSelected: CheckAllSelected,
        PageMode: InspectionSetState?.PageMode,
      };
    });
  };

  const onDropDownChangeAssessmentType = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    dispatch(
      setInitialData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          SelectedAssessmentTypeId: parseInt(selectedValue),
        },
      })
    );
  };

  //reset  question List
  const ResetInspectionSetPage = () => {
    dispatch(
      setInitialData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListAvailable: [
            ...InspectionSetState.InspectionSet.InspectionSetListAvailable.map(
              (item) => {
                return { ...item, IsSelected: item.IsSelectedDisabled };
              }
            ),
          ],
          InspectionSetListSelected: [
            ...InspectionSetState.InspectionSet.InspectionSetListSelected.map(
              (item) => {
                return { ...item, IsSelected: item.IsSelectedDisabled };
              }
            ),
          ],
        },
      })
    );
  };

  //on inspection set name  change
  const onInspectionSetNameChange = (name: string, value: any) => {
    dispatch(setInspectionSetName(value));
  };

  //On publish button click
  const onClickPublishBtn = () => {
    //check form valid
    if (IsPageValid()) {
      ResetInspectionSetPage();
      dispatch(
        saveSelectedData({
          ...InspectionSetState.InspectionSet,
          InspectionName: InspectionSetPageState?.InspectionSet?.InspectionName,
          StatusId: EnumStatus.Published,
          InspectionSetListAvailable: [],
        })
      );
      setIsDirty(false);
    }
  };

  //On publish button click
  const onClickDrafthBtn = () => {
    //check form valid
    if (IsPageValid()) {
      ResetInspectionSetPage();
      dispatch(
        saveSelectedData({
          ...InspectionSetState.InspectionSet,
          InspectionName: InspectionSetPageState?.InspectionSet?.InspectionName,
          StatusId: EnumStatus.Draft,
          InspectionSetListAvailable: [],
        })
      );
      setIsDirty(false);
    }
  };

  const IsPageValid = (): boolean => {
    //check form valid
    let isValid = InspectionSetPageState.Validator?.allValid();
    if (!isValid) {
      setInspectionSetPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !InspectionSetPageState.ShowErrorMsg,
        };
      });
      //show validation message
      InspectionSetPageState.Validator.showMessages();
    } else {
      //hide validation message
      InspectionSetPageState.Validator.hideMessages();

      if (
        InspectionSetState?.InspectionSet?.InspectionSetListSelected?.length ===
        0
      ) {
        isValid = false;
        dispatch(
          openPopup({
            Open: true,
            BodyText:
              "Please select at least one question(s) from the available list",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
      }
    }

    return isValid;
  };

  //On MoveTo Selected click
  const MoveToSelected = () => {
    let availableSelectedItemList =
      InspectionSetState?.InspectionSet?.InspectionSetListAvailable?.filter(
        (filter) => {
          return (
            filter.IsSelected && !filter.IsSelectedDisabled && !filter.IsHide
          );
        }
      );
    //return not selected any element
    if (availableSelectedItemList?.length === 0) return;

    //validate duplicate questions are exsit
    const duplicateQuestionExsit = checkDuplicateQuestionExsit(
      availableSelectedItemList
    );

    if (duplicateQuestionExsit) {
      dispatch(
        openPopup({
          Open: true,
          BodyText:
            "There are multiple source(s) linked to the selected question(s). Once you select the question(s), it will remove from other source(s). Do you want to proceed?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setMoveToSelectedDataList(availableSelectedItemList);
          },
        } as PopupProps)
      );
    } else {
      setMoveToSelectedDataList(availableSelectedItemList);
    }
  };

  const setMoveToSelectedDataList = (
    availableSelectedItemList: Array<InspectionSetSelectDTO>
  ) => {
    let alreadySelectedQuestion = [
      ...InspectionSetState.InspectionSet.InspectionSetListSelected,
    ];

    //set updated new selected list
    let updatedSelectedItemList = [
      ...alreadySelectedQuestion,
      ...availableSelectedItemList,
    ];

    availableSelectedItemList?.forEach((item) => {
      const selectedQuestionList = isQuestionAlreadySelected(
        item,
        alreadySelectedQuestion
      );

      //Check Parent Level
      const itemIndex = findInspectionSetQuestionListIndex(
        item,
        item.InspectionSetListType,
        updatedSelectedItemList
      );

      if (
        !isChildListExsit(item, updatedSelectedItemList) &&
        selectedQuestionList?.length !== item.QuestionIdList?.length
      ) {
        //set Temporary selected List
        alreadySelectedQuestion = [
          ...alreadySelectedQuestion,
          {
            ...item,
            QuestionIdList: item.QuestionIdList?.filter(
              (filterQId) => !selectedQuestionList.includes(filterQId)
            ),
          },
        ];

        //unselect selected item
        const assignAuestionList = updatedSelectedItemList[
          itemIndex
        ].QuestionIdList.filter(
          (filterQId) => !selectedQuestionList.includes(filterQId)
        );

        updatedSelectedItemList[itemIndex] = {
          ...updatedSelectedItemList[itemIndex],
          QuestionIdList: assignAuestionList,
          QuestionCount: assignAuestionList?.length,
          IsSelected: false,
        };

        for (let type = item.InspectionSetListType - 1; type > 0; type--) {
          let index = findInspectionSetQuestionListIndex(
            item,
            type,
            updatedSelectedItemList
          );

          if (updatedSelectedItemList[index] !== undefined) {
            const filterQuestionList = [
              ...updatedSelectedItemList[index].QuestionIdList,
              ...assignAuestionList,
            ];

            updatedSelectedItemList[index] = {
              ...updatedSelectedItemList[index],
              QuestionCount: filterQuestionList?.length,
              QuestionIdList: filterQuestionList,
              IsSelected: false,
            };
          }
        }
      } else {
        updatedSelectedItemList[itemIndex] = {
          ...updatedSelectedItemList[itemIndex],
          QuestionCount: 0,
          QuestionIdList: [],
          IsSelected: false,
        };
      }
    });

    //remove mutiple selected question
    setInspectionSetSelectedData([
      ...updatedSelectedItemList.filter((filter) => filter.QuestionCount !== 0),
    ]);
  };

  const checkDuplicateQuestionExsit = (
    selectedItemList: Array<InspectionSetSelectDTO>
  ): boolean => {
    let duplicateQuestionExsit = false;
    selectedItemList.forEach((selectedItem) => {
      if (!duplicateQuestionExsit) {
        const inspectionsetList =
          InspectionSetState.InspectionSet.InspectionSetListAvailable.filter(
            (filter) => filter.StandardId === selectedItem.StandardId
          );

        for (let type = selectedItem.InspectionSetListType; type > 0; type--) {
          const updatedList = inspectionsetList.filter(
            (filter) => filter.InspectionSetListType === type
          );

          duplicateQuestionExsit =
            updatedList
              .map((item) => {
                return selectedItem.QuestionIdList.some((item2) =>
                  item.QuestionIdList.includes(item2)
                );
              })
              .filter((fil) => fil).length > 1;

          if (duplicateQuestionExsit) break;
        }
      }
    });
    return duplicateQuestionExsit;
  };

  const isQuestionAlreadySelected = (
    question: InspectionSetSelectDTO,
    selectedItemList: Array<InspectionSetSelectDTO>
  ): Array<number> => {
    return question.QuestionIdList?.filter((filterQuestionId) =>
      selectedItemList?.some(
        (some) =>
          some.QuestionIdList?.includes(filterQuestionId) &&
          question.StandardId === some.StandardId &&
          some.InspectionSetListType !== EnumInspectionSetListType.Standard
      )
    );
  };

  //setMoveToSelectData
  const setInspectionSetSelectedData = (
    inspectionSetListSelected: Array<InspectionSetSelectDTO>
  ) => {
    const inspectionSetListAvailable = setInspectionSetListObject(
      InspectionSetState.InspectionSet.InspectionSetListAvailable,
      inspectionSetListSelected
    );
    dispatch(
      setSelectedData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListSelected: inspectionSetListSelected,
          InspectionSetListAvailable: inspectionSetListAvailable,
        },
        SearchInspectionSetListAvailable: {
          ...InspectionSetState.SearchInspectionSetListAvailable,
          IsSelected: true,
        },
        SearchInspectionSetListSelected:
          inspectionSetListSelected?.length > 0
            ? {
                ...InspectionSetState.SearchInspectionSetListAvailable,
                IsSelected: false,
              }
            : {
                ...InspectionSetState.SearchInspectionSetListSelected,
                IsSelected: false,
                InspectionSetListType: EnumInspectionSetListType.Initial,
              },
      })
    );
  };

  const setInspectionSetListObject = (
    inspectionSetListAvailable: Array<InspectionSetSelectDTO>,
    inspectionSetListSelected: Array<InspectionSetSelectDTO>
  ): Array<InspectionSetSelectDTO> => {
    return [
      ...inspectionSetListAvailable.map((item) => {
        const selectedQuestionObj = isInspectionSetAlreadySelected(
          item,
          inspectionSetListSelected
        );

        let questionList = getquestionList(
          item,
          inspectionSetListSelected
        )?.filter((value, index, array) => array.indexOf(value) === index);

        const filteredQuestionList = item.QuestionIdList.filter(
          (filterQuestionId) => !questionList.includes(filterQuestionId)
        );

        if (selectedQuestionObj && questionList?.length > 0) {
          questionList = [
            ...selectedQuestionObj.QuestionIdList,
            ...selectedQuestionObj.QuestionIdList.filter(
              (filterQuestionId) =>
                !filteredQuestionList.includes(filterQuestionId)
            ),
          ];
        } else {
          questionList = [...filteredQuestionList];
        }

        //remove duplicate Question id
        questionList = questionList?.filter(
          (value, index, array) => array.indexOf(value) === index
        );

        const questionExsit = selectedQuestionObj ? true : false;

        const isSelectOrDisabled =
          !questionExsit && item.IsSelectedDisabled ? false : item.IsSelected;

        return {
          ...item,
          IsSelected: isSelectOrDisabled,
          IsSelectedDisabled: isSelectOrDisabled,
          IsHide: questionList?.length <= 0 && !questionExsit,
          QuestionCount: questionList?.length,
        };
      }),
    ];
  };

  const getquestionList = (
    SelectedItem: InspectionSetSelectDTO,
    inspectionSetQuestionList: Array<InspectionSetSelectDTO>
  ): Array<number> => {
    const questionList = SelectedItem.QuestionIdList?.filter(
      (filterQuestionId) =>
        !isChildListExsit(SelectedItem, inspectionSetQuestionList) &&
        inspectionSetQuestionList?.some(
          (some) =>
            some.QuestionIdList?.includes(filterQuestionId) &&
            SelectedItem.Id !== some.Id &&
            SelectedItem.Name !== some.Name &&
            SelectedItem.StandardId === some.StandardId &&
            some.InspectionSetListType !== EnumInspectionSetListType.Standard
        )
    );
    return questionList;
  };

  const isInspectionSetAlreadySelected = (
    SelectedItem: InspectionSetSelectDTO,
    inspectionSetList: Array<InspectionSetSelectDTO>
  ): InspectionSetSelectDTO => {
    return inspectionSetList.find(
      (filter) =>
        filter.Id === SelectedItem?.Id &&
        filter.StandardId === SelectedItem?.StandardId &&
        filter.StandardReferenceId === SelectedItem?.StandardReferenceId &&
        filter.StandardSystemId === SelectedItem?.StandardSystemId &&
        filter.StandardSubSystemId === SelectedItem?.StandardSubSystemId &&
        filter.StandardSubectId === SelectedItem?.StandardSubectId &&
        filter.StandardSectionId === SelectedItem?.StandardSectionId &&
        filter.InspectionSetListType === SelectedItem?.InspectionSetListType
    );
  };

  const isChildListExsit = (
    SelectedItem: InspectionSetSelectDTO,
    inspectionSetList: Array<InspectionSetSelectDTO>
  ): boolean => {
    switch (SelectedItem?.InspectionSetListType) {
      case EnumInspectionSetListType.Standard:
        return (
          inspectionSetList.filter(
            (filter) =>
              filter.StandardId === SelectedItem?.Id &&
              filter.StandardReferenceId ===
                SelectedItem?.StandardReferenceId &&
              filter.StandardSystemId === SelectedItem?.StandardSystemId &&
              filter.StandardSubSystemId ===
                SelectedItem?.StandardSubSystemId &&
              filter.StandardSubectId === SelectedItem?.StandardSubectId &&
              filter.StandardSectionId === SelectedItem?.StandardSectionId
          )?.length > 0
        );
      case EnumInspectionSetListType.Reference:
        return (
          inspectionSetList.filter(
            (filter) =>
              filter.StandardId === SelectedItem?.StandardId &&
              filter.StandardReferenceId === SelectedItem?.Id &&
              filter.StandardSystemId === SelectedItem?.StandardSystemId &&
              filter.StandardSubSystemId ===
                SelectedItem?.StandardSubSystemId &&
              filter.StandardSubectId === SelectedItem?.StandardSubectId &&
              filter.StandardSectionId === SelectedItem?.StandardSectionId
          )?.length > 0
        );
      case EnumInspectionSetListType.System:
        return (
          inspectionSetList.filter(
            (filter) =>
              filter.StandardId === SelectedItem?.StandardId &&
              filter.StandardReferenceId ===
                SelectedItem?.StandardReferenceId &&
              filter.StandardSystemId === SelectedItem?.Id &&
              filter.StandardSubSystemId ===
                SelectedItem?.StandardSubSystemId &&
              filter.StandardSubectId === SelectedItem?.StandardSubectId &&
              filter.StandardSectionId === SelectedItem?.StandardSectionId
          )?.length > 0
        );
      case EnumInspectionSetListType.SubSystem:
        return (
          inspectionSetList.filter(
            (filter) =>
              filter.StandardId === SelectedItem?.StandardId &&
              filter.StandardReferenceId ===
                SelectedItem?.StandardReferenceId &&
              filter.StandardSystemId === SelectedItem?.StandardSystemId &&
              filter.StandardSubSystemId === SelectedItem?.Id &&
              filter.StandardSubectId === SelectedItem?.StandardSubectId &&
              filter.StandardSectionId === SelectedItem?.StandardSectionId
          )?.length > 0
        );
      case EnumInspectionSetListType.Subject:
        return (
          inspectionSetList.filter(
            (filter) =>
              filter.StandardId === SelectedItem?.StandardId &&
              filter.StandardReferenceId ===
                SelectedItem?.StandardReferenceId &&
              filter.StandardSystemId === SelectedItem?.StandardSystemId &&
              filter.StandardSubSystemId ===
                SelectedItem?.StandardSubSystemId &&
              filter.StandardSubectId === SelectedItem?.Id &&
              filter.StandardSectionId === SelectedItem?.StandardSectionId
          )?.length > 0
        );
      case EnumInspectionSetListType.Section:
        return (
          inspectionSetList.filter(
            (filter) =>
              filter.StandardId === SelectedItem?.StandardId &&
              filter.StandardReferenceId ===
                SelectedItem?.StandardReferenceId &&
              filter.StandardSystemId === SelectedItem?.StandardSystemId &&
              filter.StandardSubSystemId ===
                SelectedItem?.StandardSubSystemId &&
              filter.StandardSubectId === SelectedItem?.StandardSubectId &&
              filter.StandardSectionId === SelectedItem?.Id
          )?.length > 0
        );
      default:
        return false;
    }
  };

  //On Remove From Selected click
  const RemoveFromSelected = () => {
    let selectedRemoveItemList =
      InspectionSetState.InspectionSet?.InspectionSetListSelected?.filter(
        (filter) => {
          return filter.IsSelected;
        }
      );

    //return not selected any element
    if (selectedRemoveItemList?.length === 0) return;

    let selectedQuestionList = [
      ...InspectionSetState.InspectionSet.InspectionSetListSelected.filter(
        (filter) => {
          return !filter.IsSelected;
        }
      ),
    ];

    selectedRemoveItemList.forEach((removeitem) => {
      //Check Parent Level
      for (let type = removeitem.InspectionSetListType - 1; type > 0; type--) {
        let index = findInspectionSetQuestionListIndex(
          removeitem,
          type,
          selectedQuestionList
        );

        if (selectedQuestionList[index] !== undefined) {
          const filterQuestionList = selectedQuestionList[
            index
          ]?.QuestionIdList?.filter(
            (filterQuestionId) =>
              !selectedRemoveItemList?.some(
                (some) =>
                  some.QuestionIdList?.includes(filterQuestionId) &&
                  removeitem.StandardId === some.StandardId
              )
          );

          selectedQuestionList[index] = {
            ...selectedQuestionList[index],
            QuestionCount: filterQuestionList?.length,
            QuestionIdList: filterQuestionList ?? [...filterQuestionList],
            IsSelected: false,
          };
        }
      }
    });

    setInspectionSetSelectedData(
      selectedQuestionList.filter((filter) => filter.QuestionCount !== 0)
    );
  };

  /// Start Check Box click handle
  const onCheckBoxClicked = (
    SelectedItemIndex: number,
    Id: number,
    ListType: EnumListType,
    SelectedItemType: EnumInspectionSetListType,
    IsChecked: boolean
  ) => {
    if (ListType === EnumListType.Available) {
      onCheckBoxClickedAvailableQuestionList(
        SelectedItemIndex,
        SelectedItemType,
        IsChecked
      );
    } else if (ListType === EnumListType.Selected) {
      onCheckBoxClickedSelectedQuestionList(
        SelectedItemIndex,
        SelectedItemType,
        IsChecked
      );
    }
  };

  const onCheckBoxClickedAvailableQuestionList = (
    SelectedItemIndex: number,
    SelectedItemType: EnumInspectionSetListType,
    IsChecked: boolean
  ) => {
    let inspectionSetListAvailable = [
      ...InspectionSetState.InspectionSet.InspectionSetListAvailable,
    ];

    let checkSelectedItem = {
      ...inspectionSetListAvailable[SelectedItemIndex],
    };
    for (let type = SelectedItemType; type > 0; type--) {
      let index = findInspectionSetQuestionListIndex(
        checkSelectedItem,
        type,
        inspectionSetListAvailable
      );

      if (inspectionSetListAvailable[index] !== undefined) {
        const filterdChildList = getInspectionChildList(
          inspectionSetListAvailable[index],
          inspectionSetListAvailable
        );

        inspectionSetListAvailable[index] = {
          ...inspectionSetListAvailable[index],
          IsSelected:
            !IsChecked && index !== SelectedItemIndex
              ? filterdChildList.length > 0
              : IsChecked,
        };
      }
    }

    //Check Child Level
    //Question is a last level
    const filterdChildList = getInspectionChildList(
      checkSelectedItem,
      inspectionSetListAvailable
    );

    filterdChildList?.map((item) => {
      let orgIndex = findInspectionSetQuestionListIndex(
        item,
        item.InspectionSetListType,
        inspectionSetListAvailable
      );

      if (inspectionSetListAvailable[orgIndex] !== undefined) {
        inspectionSetListAvailable[orgIndex] = {
          ...inspectionSetListAvailable[orgIndex],
          IsSelected: IsChecked,
        };
      }
    });

    dispatch(
      setSelectedData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListAvailable: inspectionSetListAvailable,
        },
      })
    );
  };

  const onCheckBoxClickedSelectedQuestionList = (
    SelectedItemIndex: number,
    SelectedItemType: EnumInspectionSetListType,
    IsChecked: boolean
  ) => {
    let inspectionSetListSelected = [
      ...InspectionSetState.InspectionSet.InspectionSetListSelected,
    ];

    let checkSelectedItem = { ...inspectionSetListSelected[SelectedItemIndex] };

    //Check Parent Level
    for (let type = SelectedItemType; type > 0; type--) {
      let index =
        SelectedItemType === type
          ? SelectedItemIndex
          : findInspectionSetQuestionListIndex(
              checkSelectedItem,
              type,
              inspectionSetListSelected
            );

      const parentIsChecked = !getInspectionChildList(
        inspectionSetListSelected[index],
        inspectionSetListSelected
      ).some((filter) => !filter.IsSelected);

      if (inspectionSetListSelected[index] !== undefined) {
        inspectionSetListSelected[index] = {
          ...inspectionSetListSelected[index],
          IsSelected:
            IsChecked && index !== SelectedItemIndex
              ? parentIsChecked
              : IsChecked,
        };
      }
    }

    //Check Child Level
    //Question is a last level
    const filterdChildList = getInspectionChildList(
      checkSelectedItem,
      inspectionSetListSelected
    );

    filterdChildList?.map((item) => {
      let orgIndex = findInspectionSetQuestionListIndex(
        item,
        item.InspectionSetListType,
        inspectionSetListSelected
      );

      if (inspectionSetListSelected[orgIndex] !== undefined) {
        inspectionSetListSelected[orgIndex] = {
          ...inspectionSetListSelected[orgIndex],
          IsSelected: IsChecked,
        };
      }
    });

    dispatch(
      setSelectedData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListSelected: inspectionSetListSelected,
        },
      })
    );
  };

  //on Check All Available checkbox clicked
  const handleCheckAllAvailable = () => {
    let isChecked = !InspectionSetPageState.CheckAllAvailable;

    const checkedInspectionSetListAvailable =
      InspectionSetPageState?.InspectionSet?.InspectionSetListAvailable;

    let tempinspectionSetListAvailable = [
      ...InspectionSetState.InspectionSet.InspectionSetListAvailable,
    ];

    let selectedItemIndex = -1;
    for (let i = 0; i < checkedInspectionSetListAvailable?.length; i++) {
      const index = getInspectionSetIndex(
        checkedInspectionSetListAvailable[i],
        InspectionSetState.InspectionSet?.InspectionSetListAvailable
      );

      if (index !== -1) {
        selectedItemIndex = index;
      }

      if (!tempinspectionSetListAvailable[index]?.IsHide) {
        tempinspectionSetListAvailable[index] = {
          ...tempinspectionSetListAvailable[index],
          IsSelected:
            tempinspectionSetListAvailable[index]?.IsSelectedDisabled ||
            tempinspectionSetListAvailable[index]?.IsHide
              ? tempinspectionSetListAvailable[index]?.IsSelected
              : isChecked,
        };
      }

      //Check Child Level
      //Question is a last level
      const filterdChildList = getInspectionChildList(
        checkedInspectionSetListAvailable[i],
        tempinspectionSetListAvailable
      );
      tempinspectionSetListAvailable = setChildInspectionSetAvilableList(
        filterdChildList,
        tempinspectionSetListAvailable,
        isChecked
      );
    }

    //Check Parent level
    const selectedItem = tempinspectionSetListAvailable[selectedItemIndex];
    for (let type = selectedItem?.InspectionSetListType; type > 0; type--) {
      let index = findInspectionSetQuestionListIndex(
        selectedItem,
        type,
        tempinspectionSetListAvailable
      );

      if (selectedItemIndex !== index) {
        const parentIsChecked = !getInspectionChildList(
          tempinspectionSetListAvailable[index],
          tempinspectionSetListAvailable
        ).some((filter) => !filter.IsSelected);

        if (tempinspectionSetListAvailable[index] !== undefined) {
          tempinspectionSetListAvailable[index] = {
            ...tempinspectionSetListAvailable[index],
            IsSelected: parentIsChecked,
          };
        }
      }
    }

    dispatch(
      setSelectedData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListAvailable: tempinspectionSetListAvailable,
        },
        CheckAllAvailable: isChecked,
      })
    );
  };

  const setChildInspectionSetAvilableList = (
    filterdChildList: Array<InspectionSetSelectDTO>,
    tempinspectionSetListAvailable: Array<InspectionSetSelectDTO>,
    isChecked: boolean
  ): Array<InspectionSetSelectDTO> => {
    filterdChildList?.forEach((item) => {
      let orgIndex = findInspectionSetQuestionListIndex(
        item,
        item.InspectionSetListType,
        tempinspectionSetListAvailable
      );

      if (tempinspectionSetListAvailable[orgIndex] !== undefined) {
        tempinspectionSetListAvailable[orgIndex] = {
          ...tempinspectionSetListAvailable[orgIndex],
          IsSelected:
            tempinspectionSetListAvailable[orgIndex]?.IsSelectedDisabled ||
            tempinspectionSetListAvailable[orgIndex]?.IsHide
              ? tempinspectionSetListAvailable[orgIndex]?.IsSelected
              : isChecked,
        };
      }
    });

    return tempinspectionSetListAvailable;
  };

  //on Check All Selected checkbox clicked
  const handleCheckSelected = () => {
    let isChecked = !InspectionSetPageState.CheckAllSelected;

    const checkedInspectionSetListSelected =
      InspectionSetPageState?.InspectionSet?.InspectionSetListSelected;

    let tempInspectionSetListSelected = [
      ...InspectionSetState.InspectionSet.InspectionSetListSelected,
    ];

    let selectedItemIndex = -1;
    for (let i = 0; i < checkedInspectionSetListSelected?.length; i++) {
      const itemIndex = getInspectionSetIndex(
        checkedInspectionSetListSelected[i],
        InspectionSetState.InspectionSet?.InspectionSetListSelected
      );

      if (itemIndex !== -1) {
        selectedItemIndex = itemIndex;
      }

      tempInspectionSetListSelected[itemIndex] = {
        ...tempInspectionSetListSelected[itemIndex],
        IsSelected: isChecked,
      };

      //Check Child Level
      //Question is a last level
      const filterdChildList = getInspectionChildList(
        checkedInspectionSetListSelected[i],
        tempInspectionSetListSelected
      );

      filterdChildList?.map((item) => {
        let orgIndex = findInspectionSetQuestionListIndex(
          item,
          item.InspectionSetListType,
          tempInspectionSetListSelected
        );

        if (tempInspectionSetListSelected[orgIndex] !== undefined) {
          tempInspectionSetListSelected[orgIndex] = {
            ...tempInspectionSetListSelected[orgIndex],
            IsSelected: isChecked,
          };
        }
      });
    }

    //Check Parent level
    const selectedItem = tempInspectionSetListSelected[selectedItemIndex];
    for (let type = selectedItem?.InspectionSetListType; type > 0; type--) {
      let index = findInspectionSetQuestionListIndex(
        selectedItem,
        type,
        tempInspectionSetListSelected
      );

      if (selectedItemIndex !== index) {
        const parentIsChecked = !getInspectionChildList(
          tempInspectionSetListSelected[index],
          tempInspectionSetListSelected
        ).some((filter) => !filter.IsSelected);

        if (tempInspectionSetListSelected[index] !== undefined) {
          tempInspectionSetListSelected[index] = {
            ...tempInspectionSetListSelected[index],
            IsSelected:
              isChecked && index !== selectedItemIndex
                ? parentIsChecked
                : isChecked,
          };
        }
      }
    }

    dispatch(
      setSelectedData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListSelected: tempInspectionSetListSelected,
        },
        CheckAllSelected: isChecked,
      })
    );
  };

  /// End Check Box click handle

  const findInspectionSetQuestionListIndex = (
    SelectedItem: InspectionSetSelectDTO,
    InspectionSetListType: EnumInspectionSetListType,
    inspectionSetQuestionList: Array<InspectionSetSelectDTO>
  ) => {
    switch (InspectionSetListType) {
      case EnumInspectionSetListType.Question:
        return getInspectionSetIndex(
          { ...SelectedItem, InspectionSetListType: InspectionSetListType },
          inspectionSetQuestionList
        );
      case EnumInspectionSetListType.Section:
        return getInspectionSetIndex(
          {
            ...SelectedItem,
            Id:
              SelectedItem.InspectionSetListType ===
              EnumInspectionSetListType.Section
                ? SelectedItem.Id
                : SelectedItem.StandardSectionId,
            InspectionSetListType: InspectionSetListType,
            StandardSectionId: null,
          },
          inspectionSetQuestionList
        );
      case EnumInspectionSetListType.Subject:
        return getInspectionSetIndex(
          {
            ...SelectedItem,
            Id:
              SelectedItem.InspectionSetListType ===
              EnumInspectionSetListType.Subject
                ? SelectedItem.Id
                : SelectedItem.StandardSubectId,
            InspectionSetListType: InspectionSetListType,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          inspectionSetQuestionList
        );
      case EnumInspectionSetListType.SubSystem:
        return getInspectionSetIndex(
          {
            ...SelectedItem,
            Id:
              SelectedItem.InspectionSetListType ===
              EnumInspectionSetListType.SubSystem
                ? SelectedItem.Id
                : SelectedItem.StandardSubSystemId,
            InspectionSetListType: InspectionSetListType,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          inspectionSetQuestionList
        );
      case EnumInspectionSetListType.System:
        return getInspectionSetIndex(
          {
            ...SelectedItem,
            Id:
              SelectedItem.InspectionSetListType ===
              EnumInspectionSetListType.System
                ? SelectedItem.Id
                : SelectedItem.StandardSystemId,
            InspectionSetListType: InspectionSetListType,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          inspectionSetQuestionList
        );
      case EnumInspectionSetListType.Reference:
        return getInspectionSetIndex(
          {
            ...SelectedItem,
            Id:
              SelectedItem.InspectionSetListType ===
              EnumInspectionSetListType.Reference
                ? SelectedItem.Id
                : SelectedItem.StandardReferenceId,
            InspectionSetListType: InspectionSetListType,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          inspectionSetQuestionList
        );
      case EnumInspectionSetListType.Standard:
        return getInspectionSetIndex(
          {
            ...SelectedItem,
            Id:
              SelectedItem.InspectionSetListType ===
              EnumInspectionSetListType.Standard
                ? SelectedItem.Id
                : SelectedItem.StandardId,
            InspectionSetListType: InspectionSetListType,
            StandardId: 0,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          inspectionSetQuestionList
        );
      default:
        return -1;
    }
  };

  const getInspectionSetIndex = (
    SelectedItem: InspectionSetSelectDTO,
    inspectionSetQuestionList: Array<InspectionSetSelectDTO>
  ) => {
    return inspectionSetQuestionList?.findIndex((find) => {
      return (
        find.Id === SelectedItem.Id &&
        find.InspectionSetListType === SelectedItem.InspectionSetListType &&
        find.StandardId === SelectedItem.StandardId &&
        find.StandardReferenceId === SelectedItem.StandardReferenceId &&
        find.StandardSystemId === SelectedItem.StandardSystemId &&
        find.StandardSubSystemId === SelectedItem.StandardSubSystemId &&
        find.StandardSubectId === SelectedItem.StandardSubectId &&
        find.StandardSectionId === SelectedItem.StandardSectionId
      );
    });
  };

  const getInspectionChildList = (
    SelectedItem: InspectionSetSelectDTO,
    inspectionSetQuestionList: Array<InspectionSetSelectDTO>
  ) => {
    switch (SelectedItem?.InspectionSetListType) {
      case EnumInspectionSetListType.Standard:
        return inspectionSetQuestionList.filter(
          (filter) => filter.StandardId === SelectedItem?.Id
        );
      case EnumInspectionSetListType.Reference:
        return inspectionSetQuestionList.filter(
          (filter) =>
            filter.StandardId === SelectedItem?.StandardId &&
            filter.StandardReferenceId === SelectedItem?.Id
        );
      case EnumInspectionSetListType.System:
        return inspectionSetQuestionList.filter(
          (filter) =>
            filter.StandardId === SelectedItem?.StandardId &&
            filter.StandardReferenceId === SelectedItem?.StandardReferenceId &&
            filter.StandardSystemId === SelectedItem?.Id
        );
      case EnumInspectionSetListType.SubSystem:
        return inspectionSetQuestionList.filter(
          (filter) =>
            filter.StandardId === SelectedItem?.StandardId &&
            filter.StandardReferenceId === SelectedItem?.StandardReferenceId &&
            filter.StandardSystemId === SelectedItem?.StandardSystemId &&
            filter.StandardSubSystemId === SelectedItem?.Id
        );
      case EnumInspectionSetListType.Subject:
        return inspectionSetQuestionList.filter(
          (filter) =>
            filter.StandardId === SelectedItem?.StandardId &&
            filter.StandardReferenceId === SelectedItem?.StandardReferenceId &&
            filter.StandardSystemId === SelectedItem?.StandardSystemId &&
            filter.StandardSubSystemId === SelectedItem?.StandardSubSystemId &&
            filter.StandardSubectId === SelectedItem?.Id
        );
      case EnumInspectionSetListType.Section:
        return inspectionSetQuestionList.filter(
          (filter) =>
            filter.StandardId === SelectedItem?.StandardId &&
            filter.StandardReferenceId === SelectedItem?.StandardReferenceId &&
            filter.StandardSystemId === SelectedItem?.StandardSystemId &&
            filter.StandardSubSystemId === SelectedItem?.StandardSubSystemId &&
            filter.StandardSubectId === SelectedItem?.StandardSubectId &&
            filter.StandardSectionId === SelectedItem?.Id
        );
      default:
        return [];
    }
  };

  const onDragItemClicked = (
    //id: number,
    Type: EnumListType,
    // ListType: EnumInspectionSetListType,
    // IsChecked: boolean,
    inspectionSet: InspectionSetSelectDTO
  ) => {
    const id = inspectionSet?.Id;
    const ListType = inspectionSet?.InspectionSetListType;
    let IsChecked = inspectionSet?.IsSelected;
    let inspectionSetObj = { ...inspectionSet };
    //Selected Questions list always check
    let searchInspectionSetListSelected = {
      ...InspectionSetPageState.SearchInspectionSetListSelected,
      IsSelected:
        Type === EnumListType.Selected
          ? IsChecked
          : InspectionSetPageState.SearchInspectionSetListSelected?.IsSelected,
    };

    if (Type === EnumListType.Selected) {
      IsChecked = true;
    } else {
      const isSelected =
        InspectionSetPageState.InspectionSet?.InspectionSetListSelected?.some(
          (filter) =>
            filter.Id === inspectionSetObj?.Id &&
            filter.StandardId === inspectionSetObj?.StandardId &&
            filter.InspectionSetListType ===
              inspectionSetObj?.InspectionSetListType &&
            filter.StandardReferenceId ===
              inspectionSetObj?.StandardReferenceId &&
            filter.StandardSystemId === inspectionSetObj?.StandardSystemId &&
            filter.StandardSubSystemId ===
              inspectionSetObj?.StandardSubSystemId &&
            filter.StandardSubectId === inspectionSetObj?.StandardSubectId &&
            filter.StandardSectionId === inspectionSetObj?.StandardSectionId &&
            filter.IsSelected
        );

      inspectionSetObj = {
        ...inspectionSetObj,
        IsSelected: isSelected,
      };
    }

    if (ListType === EnumInspectionSetListType.Standard) {
      dispatch(
        getAvailableData({
          ...InspectionSetPageState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetPageState.SearchInspectionSetListAvailable,
            StandardId: id,
            InspectionSetListType: ListType,
            IsSelected: IsChecked,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          SearchInspectionSetListSelected: searchInspectionSetListSelected,
          ClickInspectionSetItem: inspectionSetObj,
        })
      );
    } else if (ListType === EnumInspectionSetListType.Reference) {
      dispatch(
        getAvailableData({
          ...InspectionSetPageState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetPageState.SearchInspectionSetListAvailable,
            StandardReferenceId: id,
            InspectionSetListType: ListType,
            IsSelected: IsChecked,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          SearchInspectionSetListSelected: searchInspectionSetListSelected,
          ClickInspectionSetItem: inspectionSetObj,
        })
      );
    } else if (ListType === EnumInspectionSetListType.System) {
      dispatch(
        getAvailableData({
          ...InspectionSetPageState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetPageState.SearchInspectionSetListAvailable,
            StandardSystemId: id,
            InspectionSetListType: ListType,
            IsSelected: IsChecked,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          SearchInspectionSetListSelected: searchInspectionSetListSelected,
          ClickInspectionSetItem: inspectionSetObj,
        })
      );
    } else if (ListType === EnumInspectionSetListType.SubSystem) {
      dispatch(
        getAvailableData({
          ...InspectionSetPageState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetPageState.SearchInspectionSetListAvailable,
            StandardSubSystemId: id,
            InspectionSetListType: ListType,
            IsSelected: IsChecked,
            StandardSubectId: null,
            StandardSectionId: null,
          },
          SearchInspectionSetListSelected: searchInspectionSetListSelected,
          ClickInspectionSetItem: inspectionSetObj,
        })
      );
    } else if (ListType === EnumInspectionSetListType.Subject) {
      dispatch(
        getAvailableData({
          ...InspectionSetPageState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetPageState.SearchInspectionSetListAvailable,
            StandardSubectId: id,
            InspectionSetListType: ListType,
            IsSelected: IsChecked,
            StandardSectionId: null,
          },
          SearchInspectionSetListSelected: searchInspectionSetListSelected,
          ClickInspectionSetItem: inspectionSetObj,
        })
      );
    } else if (ListType === EnumInspectionSetListType.Section) {
      dispatch(
        getAvailableData({
          ...InspectionSetPageState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetPageState.SearchInspectionSetListAvailable,
            StandardSectionId: id,
            InspectionSetListType: ListType,
            IsSelected: IsChecked,
          },
          SearchInspectionSetListSelected: searchInspectionSetListSelected,
          ClickInspectionSetItem: inspectionSetObj,
        })
      );
    }
  };

  const onClickBreadcrumbsAvailable = (type: EnumInspectionSetListType) => {
    if (type === EnumInspectionSetListType.Standard) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            StandardId: 0,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            StandardId: 0,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
        })
      );
    } else if (type === EnumInspectionSetListType.Reference) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            StandardReferenceId: null,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
        })
      );
    } else if (type === EnumInspectionSetListType.System) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            StandardSystemId: null,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
        })
      );
    } else if (type === EnumInspectionSetListType.SubSystem) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            StandardSubSystemId: null,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
        })
      );
    } else if (type === EnumInspectionSetListType.Subject) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            StandardSubectId: null,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
        })
      );
    } else if (type === EnumInspectionSetListType.Section) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            StandardSectionId: null,
            InspectionSetListType: type,
          } as InspectionSetSelectDTO,
        })
      );
    }
  };

  const getBreadcrumbsName = (
    inspectionSetListType: EnumInspectionSetListType,
    listType: EnumListType
  ): string => {
    const inspectionSetList =
      listType === EnumListType.Available
        ? InspectionSetState.InspectionSet?.InspectionSetListAvailable
        : InspectionSetState.InspectionSet?.InspectionSetListSelected;
    const searchParameter =
      listType === EnumListType.Available
        ? InspectionSetState?.SearchInspectionSetListAvailable
        : InspectionSetState?.SearchInspectionSetListSelected;

    switch (inspectionSetListType) {
      case EnumInspectionSetListType.Standard:
        return inspectionSetList?.find(
          (find) =>
            find.Id === searchParameter?.StandardId &&
            find.InspectionSetListType === EnumInspectionSetListType.Standard
        )?.Name;
      case EnumInspectionSetListType.Reference:
        return inspectionSetList?.find(
          (find) =>
            find.StandardId === searchParameter?.StandardId &&
            find.Id === searchParameter?.StandardReferenceId &&
            find.InspectionSetListType === EnumInspectionSetListType.Reference
        )?.Name;
      case EnumInspectionSetListType.System:
        return inspectionSetList?.find(
          (find) =>
            find.StandardId === searchParameter?.StandardId &&
            find.StandardReferenceId === searchParameter?.StandardReferenceId &&
            find.Id === searchParameter?.StandardSystemId &&
            find.InspectionSetListType === EnumInspectionSetListType.System
        )?.Name;
      case EnumInspectionSetListType.SubSystem:
        return inspectionSetList?.find(
          (find) =>
            find.StandardId === searchParameter?.StandardId &&
            find.StandardReferenceId === searchParameter?.StandardReferenceId &&
            find.StandardSystemId === searchParameter?.StandardSystemId &&
            find.Id === searchParameter?.StandardSubSystemId &&
            find.InspectionSetListType === EnumInspectionSetListType.SubSystem
        )?.Name;
      case EnumInspectionSetListType.Subject:
        return inspectionSetList?.find(
          (find) =>
            find.StandardId === searchParameter?.StandardId &&
            find.StandardReferenceId === searchParameter?.StandardReferenceId &&
            find.StandardSystemId === searchParameter?.StandardSystemId &&
            find.StandardSubSystemId === searchParameter?.StandardSubSystemId &&
            find.Id === searchParameter?.StandardSubectId &&
            find.InspectionSetListType === EnumInspectionSetListType.Subject
        )?.Name;
      case EnumInspectionSetListType.Section:
        return inspectionSetList?.find(
          (find) =>
            find.StandardId === searchParameter?.StandardId &&
            find.StandardReferenceId === searchParameter?.StandardReferenceId &&
            find.StandardSystemId === searchParameter?.StandardSystemId &&
            find.StandardSubSystemId === searchParameter?.StandardSubSystemId &&
            find.StandardSubectId === searchParameter?.StandardSubectId &&
            find.Id === searchParameter?.StandardSectionId &&
            find.InspectionSetListType === EnumInspectionSetListType.Section
        )?.Name;
      default:
        return "";
    }
  };

  //Available Questions breadcrumbs
  const breadcrumbsAvailable = [
    <Tooltip
      title={"Home"}
      key={EnumInspectionSetListType.Initial}
      itemID={`breadcrumbsAvailableHome`}
    >
      <div
        itemID={`breadcrumbsAvailableHome`}
        className="crumb home-crumb"
        key={EnumInspectionSetListType.Initial}
      >
        <Link
          id={`breadcrumbsAvailable_Home`}
          underline="hover"
          key={EnumInspectionSetListType.Initial}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Standard);
          }}
          className=""
        >
          Home
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Standard,
        EnumListType.Available
      )}
      key={EnumInspectionSetListType.Standard}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardId}`}
        className="crumb standard-crumb"
        key={EnumInspectionSetListType.Standard}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardId}`}
          underline="hover"
          key={EnumInspectionSetListType.Standard}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Reference);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Standard,
            EnumListType.Available
          )}
          {/* Standard */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Reference,
        EnumListType.Available
      )}
      key={EnumInspectionSetListType.Reference}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardReferenceId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardReferenceId}`}
        className="crumb reference-crumb"
        key={EnumInspectionSetListType.Reference}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardReferenceId}`}
          underline="hover"
          key={EnumInspectionSetListType.Reference}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.System);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Reference,
            EnumListType.Available
          )}
          {/* Reference */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.System,
        EnumListType.Available
      )}
      key={EnumInspectionSetListType.Reference}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSystemId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSystemId}`}
        className="crumb system-crumb"
        key={EnumInspectionSetListType.System}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSystemId}`}
          underline="hover"
          key={EnumInspectionSetListType.System}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.SubSystem);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.System,
            EnumListType.Available
          )}
          {/* System */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.SubSystem,
        EnumListType.Available
      )}
      key={EnumInspectionSetListType.SubSystem}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSubSystemId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSubSystemId}`}
        className="crumb subSystem-crumb"
        key={EnumInspectionSetListType.SubSystem}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSubSystemId}`}
          underline="hover"
          key={EnumInspectionSetListType.SubSystem}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Subject);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.SubSystem,
            EnumListType.Available
          )}
          {/* Sub-System */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Subject,
        EnumListType.Available
      )}
      key={EnumInspectionSetListType.Subject}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSubectId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSubectId}`}
        className="crumb subject-crumb"
        key={EnumInspectionSetListType.Subject}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSubectId}`}
          underline="hover"
          key={EnumInspectionSetListType.Subject}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Section);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Subject,
            EnumListType.Available
          )}
          {/* Subject */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Section,
        EnumListType.Available
      )}
      key={EnumInspectionSetListType.Section}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSectionId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSectionId}`}
        className="crumb section-crumb"
        key={EnumInspectionSetListType.Section}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListAvailable?.StandardSectionId}`}
          underline="hover"
          key={EnumInspectionSetListType.Section}
          color="inherit"
          // onClick={() => {
          //   onClickBreadcrumbsAvailable(EnumInspectionSetListType.Question);
          // }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Section,
            EnumListType.Available
          )}
          {/* Section */}
        </Link>
      </div>
    </Tooltip>,
  ];

  //Selected Questions breadcrumbs
  const breadcrumbsSelected = [
    <Tooltip
      title="Home"
      key={EnumInspectionSetListType.Initial}
      itemID={`breadcrumbsSelectedHome`}
    >
      <div
        itemID={`breadcrumbsSelectedHome`}
        className="crumb home-crumb"
        key={EnumInspectionSetListType.Initial}
      >
        <Link
          id={`breadcrumbsSelected_Home`}
          underline="hover"
          key={EnumInspectionSetListType.Initial}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Standard);
          }}
          className=""
        >
          Home
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Standard,
        EnumListType.Selected
      )}
      key={EnumInspectionSetListType.Standard}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardId}`}
        className="crumb standard-crumb"
        key={EnumInspectionSetListType.Standard}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardId}`}
          underline="hover"
          key={EnumInspectionSetListType.Standard}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Reference);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Standard,
            EnumListType.Selected
          )}
          {/* Standard */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Reference,
        EnumListType.Selected
      )}
      key={EnumInspectionSetListType.Reference}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardReferenceId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardReferenceId}`}
        className="crumb reference-crumb"
        key={EnumInspectionSetListType.Reference}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardReferenceId}`}
          underline="hover"
          key={EnumInspectionSetListType.Reference}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.System);
          }}
          className=""
        >
          {/* Reference */}
          {getBreadcrumbsName(
            EnumInspectionSetListType.Reference,
            EnumListType.Selected
          )}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.System,
        EnumListType.Selected
      )}
      key={EnumInspectionSetListType.System}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSystemId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSystemId}`}
        className="crumb system-crumb"
        key={EnumInspectionSetListType.System}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSystemId}`}
          underline="hover"
          key={EnumInspectionSetListType.System}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.SubSystem);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.System,
            EnumListType.Selected
          )}
          {/* System */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.SubSystem,
        EnumListType.Selected
      )}
      key={EnumInspectionSetListType.SubSystem}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSubSystemId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSubSystemId}`}
        className="crumb subSystem-crumb"
        key={EnumInspectionSetListType.SubSystem}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSubSystemId}`}
          underline="hover"
          key={EnumInspectionSetListType.SubSystem}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Subject);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.SubSystem,
            EnumListType.Selected
          )}
          {/* Sub-System */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Subject,
        EnumListType.Selected
      )}
      key={EnumInspectionSetListType.Subject}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSubectId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSubectId}`}
        className="crumb subject-crumb"
        key={EnumInspectionSetListType.Subject}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSubectId}`}
          underline="hover"
          key={EnumInspectionSetListType.Subject}
          color="inherit"
          onClick={() => {
            onClickBreadcrumbsAvailable(EnumInspectionSetListType.Section);
          }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Subject,
            EnumListType.Selected
          )}
          {/* Subject */}
        </Link>
      </div>
    </Tooltip>,

    <Tooltip
      title={getBreadcrumbsName(
        EnumInspectionSetListType.Section,
        EnumListType.Selected
      )}
      key={EnumInspectionSetListType.Section}
      itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSectionId}`}
    >
      <div
        itemID={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSectionId}`}
        className="crumb section-crumb"
        key={EnumInspectionSetListType.Section}
      >
        <Link
          id={`${InspectionSetPageState?.SearchInspectionSetListSelected?.StandardSectionId}`}
          underline="hover"
          key={EnumInspectionSetListType.Section}
          color="inherit"
          // onClick={() => {
          //   onClickBreadcrumbsAvailable(EnumInspectionSetListType.Section);
          // }}
          className=""
        >
          {getBreadcrumbsName(
            EnumInspectionSetListType.Section,
            EnumListType.Selected
          )}
          {/* Section */}
        </Link>
      </div>
    </Tooltip>,
  ];
  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const subHeading = useRef(null);
  const legend = useRef(null);
  const searchBar = useRef(null);
  const breadCrumb = useRef(null);
  const recordCount = useRef(null);

  //Set Hight
  const [listHeight, setListHeight] = useState(0);
  const [breadCrumbHeight, setBreadCrumbHeight] = useState(0);
  //-----Page load-----
  useEffect(() => {
    let breadCrumbHeight = breadCrumb?.current
      ? breadCrumb?.current?.offsetHeight &&
        breadCrumb?.current?.offsetHeight > 0
        ? breadCrumb?.current?.offsetHeight
        : 0
      : 0;

    let listheight =
      window.innerHeight -
      // mainHeading.current.offsetHeight -
      subHeading?.current?.offsetHeight -
      searchBar?.current?.offsetHeight -
      recordCount?.current?.offsetHeight -
      legend?.current?.offsetHeight -
      breadCrumbHeight -
      201;
    setListHeight(listheight);

    function handleResize() {
      let breadCrumbHeight = breadCrumb?.current
        ? breadCrumb?.current?.offsetHeight &&
          breadCrumb?.current?.offsetHeight > 0
          ? breadCrumb?.current?.offsetHeight
          : 0
        : 0;
      let listheight =
        window.innerHeight -
        // mainHeading.current.offsetHeight -
        subHeading?.current?.offsetHeight -
        searchBar?.current?.offsetHeight -
        recordCount?.current?.offsetHeight -
        legend?.current?.offsetHeight -
        breadCrumbHeight -
        201;
      setListHeight(listheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    // subHeading.current,
    // searchBar.current,
    // recordCount.current,
    // legend.current,
    breadCrumb.current,
  ]);

  // Style
  const setStatusColor = () => {
    let statusColor = "";

    if (
      InspectionSetPageState?.InspectionSet?.StatusId === EnumStatus.Published
    ) {
      statusColor = "status-color green";
    } else if (
      InspectionSetPageState?.InspectionSet?.StatusId === EnumStatus.Draft
    ) {
      statusColor = "status-color yellow";
    } else if (
      InspectionSetPageState?.InspectionSet?.StatusId === EnumStatus.Deactivated
    ) {
      statusColor = "status-color gray";
    } else {
      statusColor = "status-color";
    }

    return statusColor;
  };

  // Drag And Drop
  const onSelectedQuestionDragEnd = (result) => {
    const { destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const startIndex = getInspectionSetIndex(
      InspectionSetPageState.InspectionSet.InspectionSetListSelected[
        result.source.index
      ],
      InspectionSetState.InspectionSet.InspectionSetListSelected
    );

    const endIndex = getInspectionSetIndex(
      InspectionSetPageState.InspectionSet.InspectionSetListSelected[
        result.destination.index
      ],
      InspectionSetState.InspectionSet.InspectionSetListSelected
    );

    const reorderInspectionSetList = [
      ...InspectionSetState.InspectionSet.InspectionSetListSelected,
    ];

    const [removed] = reorderInspectionSetList.splice(startIndex, 1);
    reorderInspectionSetList.splice(endIndex, 0, removed);

    dispatch(
      setSelectedData({
        ...InspectionSetState,
        InspectionSet: {
          ...InspectionSetState.InspectionSet,
          InspectionSetListSelected: reorderInspectionSetList.map(
            (item, index) => {
              return {
                ...item,
                SequenceNumber: index,
              };
            }
          ),
        },
      })
    );
  };

  const onsearchNameChangeAvailable = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInspectionSetPageState((values) => {
      return {
        ...values,
        SearchInspectionSetListAvailable: {
          ...values.SearchInspectionSetListAvailable,
          Name: event?.target?.value,
        },
      };
    });

    if (!event?.target?.value) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            Name: "",
          },
        })
      );
    }
  };

  const onsearchNameAvailableKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      if (
        InspectionSetState?.SearchInspectionSetListAvailable?.Name ===
        InspectionSetPageState?.SearchInspectionSetListAvailable?.Name
      )
        return;

      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListAvailable: {
            ...InspectionSetState.SearchInspectionSetListAvailable,
            Name: InspectionSetPageState?.SearchInspectionSetListAvailable
              ?.Name,
          },
        })
      );
    }
  };

  const onsearchNameChangeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    setInspectionSetPageState((values) => {
      return {
        ...values,
        SearchInspectionSetListSelected: {
          ...values.SearchInspectionSetListSelected,
          Name: event?.target?.value,
        },
      };
    });

    if (!event?.target?.value) {
      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            Name: "",
          },
        })
      );
    }
  };

  const onsearchNameSelectedKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      if (
        InspectionSetState?.SearchInspectionSetListSelected?.Name ===
        InspectionSetPageState?.SearchInspectionSetListSelected?.Name
      )
        return;

      dispatch(
        setSearchInspectionSetListAvailable({
          ...InspectionSetState,
          SearchInspectionSetListSelected: {
            ...InspectionSetState.SearchInspectionSetListSelected,
            Name: InspectionSetPageState?.SearchInspectionSetListSelected?.Name,
          },
        })
      );
    }
  };

  const onClickBtnSearchNameAvailable = () => {
    if (
      InspectionSetState?.SearchInspectionSetListAvailable?.Name ===
      InspectionSetPageState?.SearchInspectionSetListAvailable?.Name
    )
      return;

    dispatch(
      setSearchInspectionSetListAvailable({
        ...InspectionSetState,
        SearchInspectionSetListAvailable: {
          ...InspectionSetState.SearchInspectionSetListAvailable,
          Name: InspectionSetPageState?.SearchInspectionSetListAvailable?.Name,
        },
      })
    );
  };

  const onClickBtnSearchNameSelected = () => {
    if (
      InspectionSetState?.SearchInspectionSetListSelected?.Name ===
      InspectionSetPageState?.SearchInspectionSetListSelected?.Name
    )
      return;

    dispatch(
      setSearchInspectionSetListAvailable({
        ...InspectionSetState,
        SearchInspectionSetListSelected: {
          ...InspectionSetState.SearchInspectionSetListSelected,
          Name: InspectionSetPageState?.SearchInspectionSetListSelected?.Name,
        },
      })
    );
  };

  //On back button click
  const backOrCloseOnClickFunction = () => {
    inspectionSetPagevalidation();
    navigate("/inspectionsets");
  };

  const EditButtonClick = () => {
    dispatch(changePageMode(EnumPageMode.EditMode));
  };

  const CancelButtonClick = () => {
    if (InspectionSetState.PageMode.PageMode === EnumPageMode.AddMode) {
      inspectionSetPagevalidation();
      navigate("/inspectionsets");
    } else {
      if (!inspectionSetPagevalidation()) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              ResetInspectionSetPage();
              pageLoadWithDefaultData();
            },
          } as PopupProps)
        );
      } else {
        ResetInspectionSetPage();
        dispatch(changePageMode(EnumPageMode.ViewMode));
      }
    }
  };

  const pageLoadWithDefaultData = () => {
    dispatch(
      setInitialData({
        ...initialState,
        SearchInspectionSetListAvailable: {
          ...initialState.SearchInspectionSetListAvailable,
          InspectionId: InspectionSetState.InspectionSet?.InspectionId,
        },
        SearchInspectionSetListSelected: {
          ...initialState.SearchInspectionSetListSelected,
          InspectionId: InspectionSetState.InspectionSet?.InspectionId,
        },
      })
    );

    dispatch(
      getAvailableData({
        ...initialState,
        SearchInspectionSetListAvailable: {
          ...initialState.SearchInspectionSetListAvailable,
          InspectionId: InspectionSetState.InspectionSet?.InspectionId,
        },
      })
    );

    dispatch(
      changePageMode(
        InspectionSetState.InspectionSet?.InspectionId === 0
          ? EnumPageMode.AddMode
          : EnumPageMode.ViewMode
      )
    );
  };

  //TODO - TPE ----> change msg, when exsit new changes
  const DeactivateButtonClick = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Do you want to deactivate the inspection set?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          ResetInspectionSetPage();
          dispatch(
            deactivateInspectionSetApi({
              ...InspectionSetState?.InspectionSet,
              StatusId: EnumStatus.Deactivated,
            })
          );
          setIsDirty(false);
        },
      })
    );
  };

  //Selected Question count
  const getAllStandardAvailableQuestionCount = (): number => {
    return InspectionSetState?.InspectionSet?.InspectionSetListAvailable?.filter(
      (filter) => {
        return (
          filter.InspectionSetListType === EnumInspectionSetListType.Standard
        );
      }
    )?.reduce((sum, current) => sum + current.QuestionCount, 0);
  };

  const getAllStandardSelectedQuestionCount = (): number => {
    return InspectionSetState?.InspectionSet?.InspectionSetListSelected?.filter(
      (filter) => {
        return (
          filter.InspectionSetListType === EnumInspectionSetListType.Standard
        );
      }
    )?.reduce((sum, current) => sum + current.QuestionCount, 0);
  };

  return (
    <Box>
      {/* <Grid
          container
          columns={12}
          className="global-header-wrap"
          ref={mainHeading}
        > */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
            <h1>Inspection Set Template</h1>
          </Grid> */}
      {/* <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="vlign-middle status-version-wrap"
          >
            <div className="global-version-top mb-5">
              <span>Version:</span>
              {InspectionSetPageState?.InspectionSet?.Version !== null &&
              InspectionSetPageState?.InspectionSet?.Version !== 0
                ? InspectionSetPageState?.InspectionSet?.Version?.toFixed(1)
                : 0.1}
            </div>
            <div className="standart-status-change mb-5 d-flex">
              <div className="standards-status">
                <span>Status:</span>
                <span className={setStatusColor()}>
                  {" "}
                  {InspectionSetPageState?.InspectionSet?.Status !== null &&
                  InspectionSetPageState?.InspectionSet?.Status !== ""
                    ? InspectionSetPageState?.InspectionSet?.Status
                    : EnumStatus[EnumStatus.Draft]}
                </span>
              </div>
            </div>
          </Grid> */}
      {/* </Grid> */}

      <Grid container columns={12}>
        <div className="content-section-card top-main-padding">
          {/* Global Sub Header with  Buttons*/}
          <Grid
            container
            columns={12}
            className="global-subhead-btn-wrap top-main-border-inner"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="global-subhead-wrap"
              ref={subHeading}
            >
              {/* <IconButton
                  aria-label="back"
                  className="sub-head-back-btn"
                  onClick={backOrCloseOnClickFunction}
                  title={"Back to Inspection Sets"}
                >
                  <ArrowLeft2 size="24" variant="Outline" />
                </IconButton> */}
              <Button
                aria-label="back"
                className="sub-head-back-btn back"
                onClick={backOrCloseOnClickFunction}
                title="Back to home page"
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>

              <h1 className="tab-view-fnt-resize">Inspection Set Template</h1>

              <div className="vl"></div>
              <Grid
                item
                // lg={6}
                // md={6}
                // sm={12}
                // xs={12}
                className="vlign-middle status-version-wrap"
              >
                <div className="standart-status-change d-flex">
                  {/* removeed mb-5 class */}
                  <div className="standards-status">
                    <span>Status:</span>
                    <span className={setStatusColor()}>
                      {" "}
                      {InspectionSetPageState?.InspectionSet?.Status !== null &&
                      InspectionSetPageState?.InspectionSet?.Status !== ""
                        ? InspectionSetPageState?.InspectionSet?.Status
                        : EnumStatus[EnumStatus.Draft]}
                    </span>
                  </div>
                </div>
                <div className="global-version-top">
                  {/* removeed mb-5 class */}
                  <span>Version:</span>
                  {InspectionSetPageState?.InspectionSet?.Version !== null &&
                  InspectionSetPageState?.InspectionSet?.Version !== 0
                    ? InspectionSetPageState?.InspectionSet?.Version?.toFixed(1)
                    : 0.1}
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="sub-header-btn-wrap"
            >
              {InspectionSetState?.PageMode?.IsShowCancelBtn && (
                <Button
                  className="secondary-btn-small"
                  size="small"
                  variant="outlined"
                  onClick={CancelButtonClick}
                  startIcon={<CloseCircle variant="Outline" />}
                >
                  Cancel
                </Button>
              )}

              {InspectionSetState?.PageMode.IsShowCloseBtn && (
                <Button
                  className="secondary-btn-small"
                  variant="outlined"
                  disableElevation
                  onClick={backOrCloseOnClickFunction}
                  startIcon={<CloseCircle variant="Outline" />}
                  size="small"
                >
                  Close
                </Button>
              )}
              {InspectionSetState?.InspectionSet?.StatusId ==
                EnumStatus.Published && (
                <InspectionSetReportExcelDownload
                  InspectionSetName={
                    InspectionSetState?.InspectionSet?.InspectionName
                  }
                  InspectionSetId={
                    parameter?.InspectionSetId
                      ? parameter?.InspectionSetId
                      : InspectionSetState?.InspectionSet.Id
                  }
                />
              )}

              {InspectionSetState?.PageMode.IsShowDeactivateBtn &&
                InspectionSetState?.InspectionSet?.StatusId !==
                  EnumStatus.Deactivated &&
                InspectionSetState.PageMode.PageMode ===
                  EnumPageMode.EditMode && (
                  <Button
                    className="primary-btn-small"
                    variant="outlined"
                    disableElevation
                    onClick={DeactivateButtonClick}
                    startIcon={<CardSlash variant="Outline" />}
                    size="small"
                  >
                    Deactivate
                  </Button>
                )}

              {InspectionSetState?.PageMode?.IsShowDraftBtn &&
                InspectionSetState?.InspectionSet?.StatusId !==
                  EnumStatus.Published && (
                  <Button
                    className="primary-btn-small"
                    size="small"
                    variant="outlined"
                    onClick={onClickDrafthBtn}
                    startIcon={<CardEdit variant="Outline" />}
                  >
                    Draft
                  </Button>
                )}

              {InspectionSetState?.PageMode?.IsShowPublishedBtn && (
                <Button
                  className="primary-btn-small"
                  variant="outlined"
                  startIcon={<CardSend variant="Outline" />}
                  size="small"
                  onClick={onClickPublishBtn}
                >
                  Publish
                </Button>
              )}

              {InspectionSetState?.PageMode?.IsShowEditBtn &&
                InspectionSetState?.InspectionSet?.StatusId !==
                  EnumStatus.Deactivated && (
                  <Button
                    className="primary-btn-small"
                    variant="outlined"
                    disableElevation
                    onClick={EditButtonClick}
                    startIcon={<Edit variant="Outline" />}
                    size="small"
                  >
                    Edit
                  </Button>
                )}
            </Grid>
          </Grid>
          {/* Global Sub Header with  Buttons*/}
          <div className="content-inner-wrap pl-10 pr-10">
            <Grid container columns={12} ref={legend}>
              {/* Name Text Box */}

              {/* <Grid container columns={12} className="form-group" ref={searchBar}> */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6}>
                    <div className="">
                      <HPRTextBox
                        Name={"InspectionName"}
                        Type={EnumTextBoxType.Text}
                        Label={"Name"}
                        onTextBoxChange={onInspectionSetNameChange}
                        Value={
                          InspectionSetState?.InspectionSet?.InspectionName
                        }
                        Validator={InspectionSetPageState?.Validator}
                        Rules={"required"}
                        IsEnableValidator={true}
                        Required={true}
                        ShowTextCounter
                        TextLength={75}
                        InputProps={{ maxLength: 75 }}
                        Disabled={InspectionSetState?.PageMode?.IsDisable}
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <div className="pb-20">
                      <HPRDropDownSingleSelect
                        Items={
                          InspectionSetState.InspectionSet.AssessmentTypeList
                        }
                        Label={"Type"}
                        Id="Assessment_Type"
                        Name="Assessment Type"
                        Disabled={
                          InspectionSetState?.PageMode?.IsDisable ||
                          InspectionSetState?.InspectionSet?.DisableType
                        }
                        onDropDownChange={onDropDownChangeAssessmentType}
                        Select={
                          InspectionSetState.InspectionSet
                            .SelectedAssessmentTypeId
                        }
                        Validator={InspectionSetPageState.Validator}
                        Rules={"required"}
                        IsEnableValidator={true}
                        Required={true}
                        IsSearchable={true}
                      />
                    </div>
                  </Grid>
                </Grid>

                {/* Name Text Box En */}
              </Grid>
            </Grid>
            {/* </Grid> */}
            <Grid container columns={12}>
              {/* Available Question Panel */}
              <Grid item lg={12}>
                <div className="inspection-main-wrap">
                  {/* Left Panel */}
                  <div>
                    {/* Search Bar */}
                    <Grid
                      container
                      columns={12}
                      className="inspection-header-search-wrap"
                      ref={searchBar}
                    >
                      <Grid item lg={5} md={6} sm={12} xs={12}>
                        <h2>
                          Available Questions
                          {` (${getAllStandardAvailableQuestionCount()})`}
                        </h2>
                      </Grid>

                      <Grid
                        item
                        lg={7}
                        md={6}
                        sm={12}
                        xs={12}
                        className="search-bar--small unset-width"
                      >
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <InputBase
                            value={
                              InspectionSetPageState
                                ?.SearchInspectionSetListAvailable?.Name
                            }
                            onChange={onsearchNameChangeAvailable}
                            onKeyPress={onsearchNameAvailableKeyPress}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Content"
                            inputProps={{ "aria-label": "Standard" }}
                            type="search"
                          />
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              onClickBtnSearchNameAvailable();
                            }}
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                          >
                            <SearchNormal1
                              variant="Outline"
                              className="ico-secondary"
                              size={18}
                            />
                          </IconButton>
                          {/*<Divider
                            sx={{ height: 28, m: 0.5 }}
                            orientation="vertical"
                          />
                           <IconButton
                            onClick={() => {
                              // serachBtnClick(true);
                            }}
                            color="primary"
                            sx={{ p: "10px" }}
                            aria-label="directions"
                          >
                            <Filter
                              variant="Outline"
                              className="ico-secondary"
                            />
                          </IconButton> */}
                        </Paper>
                      </Grid>
                    </Grid>
                    {/* Search Bar */}

                    {/* Standards Panel */}

                    <div className="ins-set-standard-panel-wrap">
                      {/* Bread Crumb */}

                      {InspectionSetState?.SearchInspectionSetListAvailable
                        ?.InspectionSetListType !==
                        EnumInspectionSetListType.Initial &&
                        InspectionSetState?.SearchInspectionSetListAvailable
                          ?.StandardId !== 0 && (
                          <Grid container columns={12} ref={breadCrumb}>
                            <Grid item lg={12} className="sm-wrapper">
                              <Stack spacing={2} className="ins-bread-crumb">
                                <Breadcrumbs aria-label="breadcrumb">
                                  {breadcrumbsAvailable.map(
                                    (item: JSX.Element) => {
                                      if (
                                        InspectionSetState
                                          ?.SearchInspectionSetListAvailable
                                          ?.InspectionSetListType ===
                                          EnumInspectionSetListType.Initial ||
                                        InspectionSetState
                                          ?.SearchInspectionSetListAvailable
                                          ?.StandardId === 0
                                      ) {
                                        return;
                                      } else if (
                                        item.props?.children !== undefined &&
                                        parseInt(item.key.toString()) <=
                                          InspectionSetState
                                            ?.SearchInspectionSetListAvailable
                                            ?.InspectionSetListType &&
                                        item.props?.itemID !== "0" &&
                                        item.props?.itemID !== "null"
                                      ) {
                                        return { ...item };
                                      } else {
                                        return {
                                          ...item,
                                          props: {
                                            ...item.props,
                                            children: {
                                              ...item.props?.children,
                                              props: {
                                                ...item.props?.children?.props,
                                                className: `visible-height`,
                                              },
                                            },
                                          },
                                        };
                                      }
                                    }
                                  )}
                                </Breadcrumbs>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}

                      {/* Bread Crumb End */}

                      {/* Drag & Drop Item */}

                      {/* Standard list available*/}
                      <div
                        className="ins-set-dragp-wrap"
                        style={{ height: listHeight }}
                      >
                        {InspectionSetPageState?.InspectionSet?.InspectionSetListAvailable?.map(
                          (inspectionSet: InspectionSetSelectDTO, index) => {
                            return (
                              <HPRInspectionSetDragItem
                                key={`A${
                                  index + inspectionSet.Id + inspectionSet.Name
                                }`}
                                Index={inspectionSet.SequenceNumber}
                                Id={inspectionSet.Id}
                                Label={inspectionSet.Name}
                                Name={inspectionSet.Name}
                                Code={inspectionSet.Code}
                                ListType={EnumListType.Available}
                                QuestionCount={inspectionSet.QuestionCount}
                                Checked={inspectionSet.IsSelected}
                                IsShowClose={false}
                                SelectedItemType={
                                  inspectionSet.InspectionSetListType
                                }
                                CheckBoxOnClickFunction={onCheckBoxClicked}
                                ItemOnClickFunction={() => {
                                  onDragItemClicked(
                                    EnumListType.Available,
                                    inspectionSet
                                  );
                                }}
                                CheckedDisabled={
                                  InspectionSetState?.PageMode?.IsDisable ||
                                  inspectionSet?.IsSelectedDisabled
                                }
                              />
                            );
                          }
                        )}
                      </div>

                      {/* Drag & Drop Item */}

                      {/* Panel Footer */}
                      <Grid container columns={12}>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          className="ins-set-separator"
                        >
                          {}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        columns={12}
                        className="ins-set-item-count-wrapper"
                        ref={recordCount}
                      >
                        <Grid item lg={6} md={6}>
                          <HPRCheckBox
                            Id={"Chk_All_Available"}
                            Name={"Chk_All_Available"}
                            Label={"Select all"}
                            ClassName={""}
                            onCheckBoxChange={handleCheckAllAvailable}
                            checked={
                              InspectionSetPageState?.InspectionSet
                                ?.InspectionSetListAvailable?.length > 0
                                ? InspectionSetPageState?.CheckAllAvailable
                                : false
                            }
                            Disabled={
                              InspectionSetPageState?.InspectionSet
                                ?.InspectionSetListAvailable?.length === 0 ||
                              InspectionSetState?.PageMode?.IsDisable
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          className="text-right valign-middle flex-right"
                        >
                          <span className="ins-set-item-count-lbl">
                            {`${
                              InspectionSetPageState?.InspectionSet?.InspectionSetListAvailable?.filter(
                                (filter) => {
                                  return filter.IsSelected;
                                }
                              )?.length
                            }/${
                              InspectionSetPageState?.InspectionSet
                                ?.InspectionSetListAvailable?.length
                            } Item(s)`}
                          </span>
                        </Grid>

                        {/* Panel Footer End */}
                      </Grid>
                    </div>

                    {/* Standards Panel End */}
                  </div>

                  {/* Left Panel End */}

                  {/* Move Buttons */}
                  <div className="horizontal-vertical-center">
                    <div>
                      <div className="horizontal-vertical-center">
                        <Tooltip title={"Add Question(s)"}>
                          <IconButton
                            className={
                              InspectionSetState?.PageMode?.IsDisable
                                ? "disabled"
                                : ""
                            }
                            onClick={MoveToSelected}
                            disabled={InspectionSetState?.PageMode?.IsDisable}
                          >
                            <ArrowRight2
                              variant="Outline"
                              className="move-icon"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className="horizontal-vertical-center">
                        <Tooltip title={"Remove Question(s)"}>
                          <IconButton
                            className={
                              InspectionSetState?.PageMode?.IsDisable
                                ? "disabled"
                                : ""
                            }
                            onClick={RemoveFromSelected}
                            disabled={InspectionSetState?.PageMode?.IsDisable}
                          >
                            <ArrowLeft2
                              variant="Outline"
                              className="move-icon"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  {/* Move Buttons */}

                  {/* Right Panel */}
                  <div>
                    <div>
                      {/* Search Bar */}
                      <Grid
                        container
                        columns={12}
                        className="inspection-header-search-wrap"
                      >
                        <Grid item lg={5} md={6} sm={12} xs={12}>
                          <h2>
                            Selected Questions
                            {` (${getAllStandardSelectedQuestionCount()})`}
                          </h2>
                        </Grid>

                        <Grid
                          item
                          lg={7}
                          md={6}
                          sm={12}
                          xs={12}
                          className="search-bar--small unset-width"
                        >
                          <Paper
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <InputBase
                              value={
                                InspectionSetPageState
                                  ?.SearchInspectionSetListSelected?.Name
                              }
                              onChange={onsearchNameChangeSelected}
                              onKeyPress={onsearchNameSelectedKeyPress}
                              sx={{ ml: 1, flex: 1 }}
                              placeholder="Search Content"
                              inputProps={{ "aria-label": "Standard" }}
                              type="search"
                            />
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                onClickBtnSearchNameSelected();
                              }}
                              type="button"
                              sx={{ p: "10px" }}
                              aria-label="search"
                            >
                              <SearchNormal1
                                variant="Outline"
                                className="ico-secondary"
                                size={18}
                              />
                            </IconButton>
                            {/* <Divider
                              sx={{ height: 28, m: 0.5 }}
                              orientation="vertical"
                            />
                            <IconButton
                              onClick={() => {
                                // serachBtnClick(true);
                              }}
                              color="primary"
                              sx={{ p: "10px" }}
                              aria-label="directions"
                            >
                              <Filter
                                variant="Outline"
                                className="ico-secondary"
                              />
                            </IconButton> */}
                          </Paper>
                        </Grid>
                      </Grid>
                      {/* Search Bar */}

                      {/* Selected Question Panel */}

                      <div className="ins-set-standard-panel-wrap">
                        {/* Bread Crumb */}

                        {InspectionSetState?.SearchInspectionSetListAvailable
                          ?.InspectionSetListType !==
                          EnumInspectionSetListType.Initial &&
                          InspectionSetState?.SearchInspectionSetListAvailable
                            ?.StandardId !== 0 && (
                            <Grid container columns={12} ref={breadCrumb}>
                              <Grid item lg={12} className="sm-wrapper">
                                <Stack spacing={2} className="ins-bread-crumb">
                                  <Breadcrumbs
                                    separator="›"
                                    aria-label="breadcrumb"
                                  >
                                    {breadcrumbsSelected.map(
                                      (item: JSX.Element) => {
                                        if (
                                          InspectionSetState
                                            ?.SearchInspectionSetListAvailable
                                            ?.InspectionSetListType ===
                                            EnumInspectionSetListType.Initial ||
                                          InspectionSetState
                                            ?.SearchInspectionSetListAvailable
                                            ?.StandardId === 0
                                        ) {
                                          return;
                                        } else if (
                                          item.props?.children !== undefined &&
                                          InspectionSetPageState.InspectionSet
                                            ?.InspectionSetListSelected
                                            ?.length > 0 &&
                                          parseInt(item.key.toString()) <=
                                            InspectionSetState
                                              ?.SearchInspectionSetListSelected
                                              ?.InspectionSetListType &&
                                          item.props.itemID !== "0" &&
                                          item.props.itemID !== "null"
                                        ) {
                                          return { ...item };
                                        } else {
                                          return {
                                            ...item,
                                            props: {
                                              ...item.props,
                                              children: {
                                                ...item.props?.children,
                                                props: {
                                                  ...item.props?.children
                                                    ?.props,
                                                  className: `visible-height`,
                                                },
                                              },
                                            },
                                          };
                                        }
                                      }
                                    )}
                                  </Breadcrumbs>
                                </Stack>
                              </Grid>
                            </Grid>
                          )}
                        {/* Bread Crumb End */}

                        {/* Drag & Drop Item */}

                        {/* Standard list selected*/}
                        <DragDropContext onDragEnd={onSelectedQuestionDragEnd}>
                          <Droppable droppableId="divSelectedQuestionDrop">
                            {(provided) => (
                              <div
                                className="ins-set-dragp-wrap"
                                style={{ height: listHeight }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {InspectionSetPageState?.InspectionSet?.InspectionSetListSelected?.map(
                                  (
                                    inspectionSet: InspectionSetSelectDTO,
                                    index
                                  ) => {
                                    return (
                                      <Draggable
                                        key={`D${
                                          index +
                                          inspectionSet.Id +
                                          inspectionSet.Name
                                        }`}
                                        draggableId={`D_${
                                          index +
                                          "_" +
                                          inspectionSet.Id +
                                          "_" +
                                          inspectionSet.Name
                                        }`}
                                        index={index}
                                        isDragDisabled={
                                          inspectionSet?.InspectionSetListType ===
                                            EnumInspectionSetListType.Standard ||
                                          InspectionSetState?.PageMode
                                            ?.IsDisable
                                        }
                                      >
                                        {(provided) => (
                                          <div
                                            key={inspectionSet.Id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <HPRInspectionSetDragItem
                                              key={`S${
                                                index +
                                                inspectionSet.Id +
                                                inspectionSet.Name
                                              }`}
                                              Index={
                                                inspectionSet.SequenceNumber
                                              }
                                              Id={inspectionSet.Id}
                                              Label={inspectionSet.Name}
                                              Name={inspectionSet.Name}
                                              Code={inspectionSet.Code}
                                              ListType={EnumListType.Selected}
                                              QuestionCount={
                                                inspectionSet.QuestionCount
                                              }
                                              Checked={inspectionSet.IsSelected}
                                              IsShowClose={false}
                                              SelectedItemType={
                                                inspectionSet.InspectionSetListType
                                              }
                                              CheckBoxOnClickFunction={
                                                onCheckBoxClicked
                                              }
                                              ItemOnClickFunction={() => {
                                                onDragItemClicked(
                                                  EnumListType.Selected,
                                                  inspectionSet
                                                );
                                              }}
                                              CheckedDisabled={
                                                InspectionSetState?.PageMode
                                                  ?.IsDisable
                                              }
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {/* Drag & Drop Item */}

                        {/* Panel Footer */}
                        <Grid container columns={12}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            className="ins-set-separator"
                          >
                            {}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          columns={12}
                          className="ins-set-item-count-wrapper"
                          ref={recordCount}
                        >
                          <Grid item lg={6} md={6}>
                            <HPRCheckBox
                              Id={"Chk_All_Selected"}
                              Name={"Chk_All_Selected"}
                              Label={"Select all"}
                              ClassName={""}
                              onCheckBoxChange={handleCheckSelected}
                              checked={
                                InspectionSetPageState.InspectionSet
                                  ?.InspectionSetListSelected?.length > 0
                                  ? InspectionSetPageState.CheckAllSelected
                                  : false
                              }
                              Disabled={
                                InspectionSetPageState?.InspectionSet
                                  ?.InspectionSetListSelected?.length === 0 ||
                                InspectionSetState?.PageMode?.IsDisable
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            className="text-right valign-middle flex-right"
                          >
                            <span className="ins-set-item-count-lbl">
                              {`${
                                InspectionSetPageState?.InspectionSet?.InspectionSetListSelected?.filter(
                                  (filter) => {
                                    return filter.IsSelected;
                                  }
                                )?.length
                              }/${
                                InspectionSetPageState?.InspectionSet
                                  ?.InspectionSetListSelected?.length
                              } Item(s)`}
                            </span>
                          </Grid>

                          {/* Panel Footer End */}
                        </Grid>
                      </div>

                      {/* Selected Question Panel */}
                    </div>
                  </div>

                  {/* Right Panel End */}
                </div>

                {/* Legend Start*/}
                <div className="inspection-main-wrap bottom-legend">
                  <div>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="legend pb-10">
                        <div className="item1 flex--container pl-0">
                          <div className="flex__item--inherit symbol standard"></div>
                          <Tooltip title="Standard">
                            <div className="flex__item--vcenter label truncate">
                              Standard
                            </div>
                          </Tooltip>
                        </div>
                        <div className="item1 flex--container">
                          <div className="flex__item--inherit symbol reference"></div>
                          <Tooltip title="Reference">
                            <div className="flex__item--vcenter label truncate">
                              Reference
                            </div>
                          </Tooltip>
                        </div>
                        <div className="item1 flex--container">
                          <div className="flex__item--inherit symbol system"></div>
                          <Tooltip title="System">
                            <div className="flex__item--vcenter label truncate">
                              System
                            </div>
                          </Tooltip>
                        </div>
                        <div className="item1 flex--container">
                          <div className="flex__item--inherit symbol sub-system"></div>
                          <Tooltip title="Sub-system">
                            <div className="flex__item--vcenter label truncate">
                              Sub-system
                            </div>
                          </Tooltip>
                        </div>
                        <div className="item1 flex--container">
                          <div className="flex__item--inherit symbol subject"></div>
                          <Tooltip title="Subject">
                            <div className="flex__item--vcenter label truncate">
                              Subject
                            </div>
                          </Tooltip>
                        </div>
                        <div className="item1 flex--container">
                          <div className="flex__item--inherit symbol section"></div>
                          <Tooltip title=" Section">
                            <div className="flex__item--vcenter label truncate">
                              Section
                            </div>
                          </Tooltip>
                        </div>
                        <div className="item1 flex--container">
                          <div className="flex__item--inherit symbol question"></div>
                          <Tooltip title=" Question">
                            <div className="flex__item--vcenter label truncate">
                              Question
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </div>
                {/* Legend End */}
              </Grid>
              {/* Available Question Panel End*/}
            </Grid>
          </div>
        </div>
      </Grid>
    </Box>
  );
};

export default InspectionSetPage;
