import {
  InspectionAssignmentSmeDTO,
  InspectionDTO,
  InspectionMemberDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
} from "../../../common/types/common.dto.types";
import { QuestionnaireSummaryParameterDTO } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import {
  CopyInspectionStandardDTO,
  QuestionMainDataType,
} from "./SiteInspectionStartPage.types";

export const GETSITEINSPECTIONQUESTIONLIST = "GETSITEINSPECTIONQUESTIONLIST";
export const SAVEINSPECTIONSITETEAMMEMBER = "SAVEINSPECTIONSITETEAMMEMBER";
export const COPYINSPECTIONSTANDARD = "COPYINSPECTIONSTANDARD";
export const COMPLETEINSPECTIONSTANDARD = "COMPLETEINSPECTIONSTANDARD";
export const REOPENINSPECTIONSTANDARD = "REOPENINSPECTIONSTANDARD";
export const RESUMEINSPECTIONSTANDARD = "RESUMEINSPECTIONSTANDARD";
export const COMPLETEINSPECTION = "COMPLETEINSPECTION";
export const REOPENCOMPLETEINSPECTION = "REOPENCOMPLETEINSPECTION";
export const GETSUMMARYOFINSPECTIONBEFORECLOSE =
  "GETSUMMARYOFINSPECTIONBEFORECLOSE";

export const GETGLOBALSMEASSIGNMENTPOPUPDATA =
  "GETGLOBALSMEASSIGNMENTPOPUPDATA";

export const SAVEASSIGNEDGLOBALSME = "SAVEASSIGNEDGLOBALSME";

export const getSiteInspectionStartData = (inspectionId: number) => {
  return {
    type: GETSITEINSPECTIONQUESTIONLIST,
    payload: inspectionId,
  };
};

export const resumeInspectionStandard = (inspectionStandardId: number) => {
  return {
    type: RESUMEINSPECTIONSTANDARD,
    payload: inspectionStandardId,
  };
};
export const saveInspectionSiteTeamMember = (
  inspectionMember: Array<InspectionMemberDTO>
) => {
  return {
    type: SAVEINSPECTIONSITETEAMMEMBER,
    payload: inspectionMember,
  };
};

export const copyInspectionStandard = (
  copyInspectionStandardDto: CopyInspectionStandardDTO
) => {
  return {
    type: COPYINSPECTIONSTANDARD,
    payload: copyInspectionStandardDto,
  };
};

export const completeInspectionStandard = (
  questionnaireData: QuestionnaireDTO
) => {
  return {
    type: COMPLETEINSPECTIONSTANDARD,
    payload: questionnaireData,
  };
};

export const reOpenInspectionStandard = (
  questionnaireData: QuestionnaireSummaryParameterDTO
) => {
  return {
    type: REOPENINSPECTIONSTANDARD,
    payload: questionnaireData,
  };
};

export const completeInspection = (inspectionData: InspectionDTO) => {
  return {
    type: COMPLETEINSPECTION,
    payload: inspectionData,
  };
};

export const reopenCompleteInspection = (inspectionData: QuestionnaireDTO) => {
  return {
    type: REOPENCOMPLETEINSPECTION,
    payload: inspectionData,
  };
};

export const saveAssignedGlobalSMEs = (
  inspectionStandard: InspectionStandardDTO
) => {
  return {
    type: SAVEASSIGNEDGLOBALSME,
    payload: inspectionStandard,
  };
};

export const getGlobalSMEAssignmentPopupData = (
  inspectionStandard: InspectionStandardDTO
) => {
  return {
    type: GETGLOBALSMEASSIGNMENTPOPUPDATA,
    payload: inspectionStandard,
  };
};
