import { createSlice } from "@reduxjs/toolkit";
import { EnumListShowRowCountList } from "../../../common/enums/common.enums";
import {
  AssessmentTypeDTO,
  InspectionDTO,
  PaginationDTO,
  StatusStatisticsDTO,
} from "../../../common/types/common.dto.types";
import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";

const initialState = {
  Data: {} as PaginationDTO,
  SiteInspectionSearchParameter: {
    Name: "",
    InspectionStatusId: 0,
    AssessmentType: { GroupId: "0" } as AssessmentTypeDTO,
    SelectedPage: 0,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    ShowInspectionStatistics: false,
    ShowCompletedInspection: false,
  } as InspectionDTO,
  AssessmentTypeList: [] as Array<AssessmentTypeDTO>,
  StatusStatistics: [] as Array<StatusStatisticsDTO>,
} as SiteInspectionsPageInitialState;

const siteInspectionsSlice = createSlice({
  name: "siteInspections",
  initialState: initialState,
  reducers: {
    setSiteInspectionListSearchParameter: (
      state: SiteInspectionsPageInitialState,
      action: { payload: InspectionDTO; type: string }
    ) => {
      return {
        ...state,
        SiteInspectionSearchParameter: action.payload,
      };
    },
    setSiteInspectionsList: (
      state: SiteInspectionsPageInitialState,
      action: { payload: SiteInspectionsPageInitialState; type: string }
    ) => {
      //convert inspection list to 2d array
      const numColumns = 2;
      let newInspectionList =
        action.payload.Data?.Data?.PageData?.reduce(
          (inspectionArray: number[][], _, index: number) => {
            if (index % numColumns === 0) {
              inspectionArray.push(
                action.payload.Data?.Data?.PageData?.slice(
                  index,
                  index + numColumns
                )
              );
            }
            return inspectionArray;
          },
          []
        ) ?? [];

      if (action.payload.Data?.SelectedPage !== 0) {
        newInspectionList = [...(state.Data.Data ?? []), ...newInspectionList];
      }

      return {
        ...state,
        Data: {
          ...state.Data,
          Data: newInspectionList,
          PageSize: action.payload.Data?.PageSize,
          SelectedPage: action.payload.Data?.SelectedPage,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
        },
        AssessmentTypeList: action.payload.Data?.Data?.AssessmentTypeList,
        StatusStatistics: action.payload.Data?.Data?.StatusStatistics,
      };
    },

    setStatusUpdatedInspectionList: (
      state: SiteInspectionsPageInitialState,
      action: { payload: PaginationDTO; type: string }
    ) => {
      let itemsList = state.Data?.Data?.map((item) => {
        let isExsit = action.payload.Data?.find(
          (filter) => filter.Id.toString() === item.Id.toString()
        );
        if (isExsit !== undefined) {
          item.StatusID = isExsit.Status;
        }
        return item;
      });
      state.Data.Data = itemsList;
    },
    setShowInspectionStatistics: (
      state: SiteInspectionsPageInitialState,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        SiteInspectionSearchParameter: {
          ...state.SiteInspectionSearchParameter,
          ShowInspectionStatistics: action.payload,
        },
      };
    },
    resetSiteInspectionsList: (state: SiteInspectionsPageInitialState) => {
      return {
        ...initialState,
        SiteInspectionSearchParameter: state.SiteInspectionSearchParameter,
      };
    },
  },
});

export const siteInspectionsPageReducerActions = siteInspectionsSlice.actions;
export const siteInspectionsPageReducer = siteInspectionsSlice.reducer;
