import {
  AssignInspectionDTO,
  InspectionDTO,
} from "../../../common/types/common.dto.types";

export const GETASSIGNINSPECTIONDETAILS = "GETASSIGNINSPECTIONDETAILS";
export const SAVEASSIGNINSPECTIONDETAILS = "SAVEASSIGNINSPECTIONDETAILS";
export const GETINSPECTIONSTANDARDLIST = "GETINSPECTIONSTANDARDLIST";

export const getInspectionDetails = (inspection: InspectionDTO) => {
  return {
    type: GETASSIGNINSPECTIONDETAILS,
    payload: inspection,
  };
};

export const saveInspectionDetails = (inspection: AssignInspectionDTO) => {
  return {
    type: SAVEASSIGNINSPECTIONDETAILS,
    payload: inspection,
  };
};

export const getInspectionStandardList = (inspection: InspectionDTO) => {
  return {
    type: GETINSPECTIONSTANDARDLIST,
    payload: inspection,
  };
};
