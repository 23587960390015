import { DownloadStatusType } from "./StandardReport.types";
import standardReportSagaActions from "./StandardReport.actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { Document } from "iconsax-react";
import { EnumReportType } from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  StandardDTO,
} from "../../../common/types/common.dto.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { useEffect, useRef, useState } from "react";
import { standarReportReducerActions } from "./StandardReport.reducer";
import { CircularProgress } from "@mui/material";

const ExcelDownload = (props: {
  Title: string;
  StandardId: number;
  StandardName: string;
  InspectionId: number;
  InspectionStandardId: number;
}) => {
  const state = useAppSelector((state) => state.standarReportReducer);
  const dispatch = useAppDispatch();
  const isInProgress = useRef(false);
  const [isReportDownloading, setIsReportDownloading] = useState(false);

  useEffect(() => {
    const reportStatus = state.excelReportStatus.some(
      (standard) =>
        standard.InspectionId === props.InspectionId &&
        standard.StandardId === props.StandardId &&
        standard.StandardName === props.StandardName &&
        standard.IsDownloading === true &&
        standard.InspectionStandardId === props.InspectionStandardId
    );

    setIsReportDownloading(reportStatus);
  }, [state]);

  return (
    <>
      {isReportDownloading && (
        <div>
          <CircularProgress size={25} />
        </div>
      )}
      <a
        href="#"
        title={props.Title}
        className={`excel${isReportDownloading ? " disabled" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          isInProgress.current = true;
          dispatch(
            standarReportReducerActions.setExcelReportStatus({
              InspectionId: props.InspectionId,
              StandardId: props.StandardId,
              StandardName: props.StandardName,
              IsDownloading: true,
              InspectionStandardId: props.InspectionStandardId,
            } as DownloadStatusType)
          );
          dispatch(
            standardReportSagaActions.getQuestionnaireSummary({
              ReportType: EnumReportType.StandardReport,
              Inspection: {
                Id: props.InspectionId,
                Name: "",
              } as InspectionDTO,
              Standard: {
                Id: props.StandardId,
                Name: props.StandardName,
              } as StandardDTO,
              InspectionStandardId: props.InspectionStandardId,
            } as ReportViewerType)
          );
        }}
      >
        {/* <TextSnippetOutlinedIcon /> */}

        <Document className="m-r-10" color="#66bb6a" style={{ fontSize: 20 }} />
      </a>
    </>
  );
};

export default ExcelDownload;
