import { createSlice } from "@reduxjs/toolkit";
import {
  EnumDataTransferStatus,
  EnumInspectionStandardStatus,
  EnumPageMode,
} from "../../../common/enums/common.enums";

import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import {
  InspectionQuestionDTO,
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { QuestionnaireDataType } from "./QuestionnairePage.types";
import produce from "immer";

// ==============================|| VIEWS - PAGES - STANDARDSubject PAGE - REDUCER ||============================== //

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: false,
    IsShowAddBtn: false,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: true,
    IsShowUpdateBtn: false,
    IsPauseBtn: false,
    IsCompleteBtn: false,
    IsShowProgress: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Questionnaire: {
    InspectionQuestion: {
      Comment: "",
    },
    StandardID: 0,
    SiteInspectionID: 0,
    CurrentQuestionNumber: 0,
    AllQuestionCount: 0,
    AnsweredCount: 0,
    PendingCount: 0,
    GapCount: 0,
    AnsweredAsNA: 0,
    SkippedCount: 0,
    InspectionStandardName: "",
    QuestionProgressBarValue: 0,
    QuestionText: "",
    InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
    InspectionStandardStatus: 0,
    InspectionStandardStatusId: EnumInspectionStandardStatus.Completed,
    ReadOnlyMode: false,
    FinalQuestionAnswered: false,
    SaveAnswerBeforeClickPrevious: false,
    UploadedFileList: [] as Array<UploadFileDTO>,
    IsFromQuestionnairePge: false,
    ImageFile: {} as any,
    DocumentFile: {} as any,
    RemovedFileIdList: [] as Array<number>,
    IsSiteInspectionViewButtonClick: false,
    ReferenceID: 0,
    IsEnableReviewByGlobalSME: false,
    RejectedNow: false,
  } as QuestionnaireDTO,
  YesNoAnswer: "",
  IsHideSkipButton: false,
  QuestionnaireSaveCopy: {} as QuestionnaireDTO,
  IsQuestionnaireSaveRunning: false,
  QuestionnaireSaveActionName: "",
  OnPageUploadedFileList: [] as Array<UploadFileDTO>,
} as QuestionnaireDataType;

export const siteInspectionQuestionnaireSlice = createSlice({
  name: "siteInspectionQuestionnaire",
  initialState: initialState,
  reducers: {
    setLoading: (
      state: QuestionnaireDataType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    setSiteInspectionQuestionnaire: (
      state: QuestionnaireDataType,
      action: { payload: QuestionnaireDTO; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: action.payload,
      };
    },
    setSiteInspectionQuestionnaireInspectionQuestion: (
      state: QuestionnaireDataType,
      action: { payload: InspectionQuestionDTO; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: {
          ...state.Questionnaire,
          InspectionQuestion: {
            ...state.Questionnaire.InspectionQuestion,
            Comment: action.payload.Comment,
            CorrectAnswer: action.payload.CorrectAnswer,
            ProductImpactAnswer: action.payload.ProductImpactAnswer,
          },
        },
      };
    },
    setSiteInspectionStandardStatus: (
      state: QuestionnaireDataType,
      action: { payload: QuestionnaireDTO; type: string }
    ) => {
      return { ...state, Questionnaire: action.payload };
    },
    changePageMode: (
      state: QuestionnaireDataType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            IsDisable: false,
            IsShowCloseBtn: true,
            IsShowEditBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: true,
            IsRedirect: false,
          },
        };
      }
    },
    setPageModeRedirectMode: (
      state: QuestionnaireDataType,
      action: { payload: RedirectObject; type: string }
    ) => {
      return {
        ...state,
        PageMode: {
          ...state.PageMode,
          RedirctOption: action.payload,
        },
      };
    },
    clearPageModeRedirectMode: (state) => {
      return {
        ...state,
        PageMode: {
          ...state.PageMode,
          RedirctOption: initialState.PageMode.RedirctOption,
        },
      };
    },
    clearPageState: () => {
      return initialState;
    },

    setPageMode: (
      state: QuestionnaireDataType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsRedirect: false,
            IsPauseBtn: false,
            IsCompleteBtn: false,
            IsShowProgress: false,
          },
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsRedirect: false,
            IsPauseBtn: false,
            IsCompleteBtn: false,
            IsShowProgress: false,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsRedirect: false,
            IsPauseBtn: true,
            IsCompleteBtn: true,
            IsShowProgress: true,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
            IsRedirect: false,
            IsPauseBtn: false,
            IsCompleteBtn: false,
            IsShowProgress: false,
          },
        };
      } else if (EnumPageMode.RedirectAnothePage === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.RedirectAnothePage,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsRedirect: true,
            IsPauseBtn: false,
            IsCompleteBtn: false,
            IsShowProgress: false,
          },
        };
      }
    },
    addFileToUploadFilesList: (
      state: QuestionnaireDataType,
      action: { payload: Array<UploadFileDTO>; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: {
          ...state.Questionnaire,
          UploadedFileList: [
            ...state.Questionnaire.UploadedFileList,
            ...action.payload,
          ],
        },
      };
    },
    updateRemoveUploadFilesList: (
      state: QuestionnaireDataType,
      action: { payload: Array<UploadFileDTO>; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: {
          ...state.Questionnaire,
          UploadedFileList: [
            ...state.Questionnaire.UploadedFileList,
            ...action.payload,
          ],
        },
      };
    },
    updateUploadFilesList: (
      state: QuestionnaireDataType,
      action: { payload: Array<UploadFileDTO>; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: {
          ...state.Questionnaire,
          UploadedFileList: action.payload,
        },
      };
    },
    updateUploadFileStatus: (
      state: QuestionnaireDataType,
      action: { payload: UploadFileDTO; type: string }
    ) => {
      return produce(state, (draftState) => {
        draftState.Questionnaire.UploadedFileList.map((file) => {
          if (file.UploadStatus === EnumDataTransferStatus.InProgress) {
            file.UploadStatus = EnumDataTransferStatus.Finished;
            file.Id = action.payload.Id;
            file.FilePreview = action.payload.FilePreview;
            file.File = action.payload.FilePreview;
            file.FilePath = action.payload.FilePath;
          }
          return file;
        });
        return draftState;
      });
    },
    updateFileName: (
      state,
      action: { payload: { Id: number; Name: string } }
    ) => {
      return produce(state, (draftState) => {
        draftState.Questionnaire.UploadedFileList.map((file) => {
          if (file.Id === action.payload.Id) {
            file.FileName = action.payload.Name;
          }
          return file;
        });
        return draftState;
      });
    },
    updateDownloadFileStatus: (state, action: { payload: UploadFileDTO }) => {
      return produce(state, (draftState) => {
        draftState.Questionnaire.UploadedFileList.map((file) => {
          if (file.Id === action.payload.Id) {
            file.DownloadStatus = action.payload.DownloadStatus;
          }
          return file;
        });
        return draftState;
      });
    },
    updateQuestionnaireSaveRunningStatus: (
      state: QuestionnaireDataType,
      action: { payload: QuestionnaireDataType; type: string }
    ) => {
      return {
        ...state,
        IsQuestionnaireSaveRunning: action.payload.IsQuestionnaireSaveRunning,
        QuestionnaireSaveCopy: action.payload.QuestionnaireSaveCopy,
        QuestionnaireSaveActionName: action.payload.QuestionnaireSaveActionName,
      };
    },
    changeUploadFileListData: (
      state: QuestionnaireDataType,
      action: { payload: QuestionnaireDTO; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: {
          ...state.Questionnaire,
          UploadedFileList: action.payload.UploadedFileList,
          RemovedFileIdList: action.payload.RemovedFileIdList,
          SaveAnswerBeforeClickPrevious:
            action.payload.SaveAnswerBeforeClickPrevious,
        },
      };
    },
    setPageReadOnlyMode: (
      state: QuestionnaireDataType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        Questionnaire: {
          ...state.Questionnaire,
          ReadOnlyMode: action.payload,
        },
      };
    },
  },
});

export const {
  setSiteInspectionQuestionnaire,
  setSiteInspectionStandardStatus,
  changePageMode,
  setLoading,
  setPageMode,
  setPageModeRedirectMode,
  clearPageModeRedirectMode,
  clearPageState,
  addFileToUploadFilesList,
  updateUploadFilesList,
  updateUploadFileStatus,
  updateFileName,
  updateDownloadFileStatus,
  updateQuestionnaireSaveRunningStatus,
  setSiteInspectionQuestionnaireInspectionQuestion,
  changeUploadFileListData,
  setPageReadOnlyMode,
} = siteInspectionQuestionnaireSlice.actions;
export const siteInspectionQuestionnaireReducer =
  siteInspectionQuestionnaireSlice.reducer;
