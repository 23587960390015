import { all, fork } from "@redux-saga/core/effects";
import loginSaga from "../../views/pages/LoginPage/LoginPage.saga";
import questionSaga from "../../views/pages/QuestionsPage/QuestionPage.saga";
import standardReferenceSaga from "../../views/pages/ReferencePage/ReferencePage.saga";
import sourcesSaga from "../../views/pages/SourcesPage/SourcesPage.saga";
import standardSaga from "../../views/pages/StandardPage/StandardPage.saga";
import standardQuestionSaga from "../../views/pages/StandardQuestionsPage/StandardsQuestionsPage.saga";
import standardsSaga from "../../views/pages/StandardsPage/StandardsPage.saga";
import standardSystemSaga from "../../views/pages/SystemPage/SystemPage.saga";
import standardSubSystemSaga from "../../views/pages/StandardSubSystemPage/StandardSubSystemPage.saga";
import standardSubjectSaga from "../../views/pages/SubjectPage/SubjectPage.saga";
import standardSectionSaga from "../../views/pages/SectionPage/SectionPage.saga";
import locationSaga from "../../views/pages/LocationPage/LocationPage.saga";
import locationsListSaga from "../../views/pages/LocationsPage/LocationsPage.saga";
import InspectionSetsSaga from "../../views/pages/InspectionSetsPage/InspectionSetsPage.saga";
import questionnaireStartSaga from "../../views/pages/QuestionnaireStartPage/QuestionnaireStartPage.saga";
import InspectionAllocationSaga from "../../views/pages/InspectionAllocationPage/InspectionAllocationPage.saga";
import stieInspectionStartPageSaga from "../../views/pages/SiteInspectionStartPage/SiteInspectionStartPage.saga";
import siteInspectionsListSaga from "../../views/pages/SiteInspectionsPage/SiteInspectionsPage.saga";
import SiteInspectionQuestionnairePageSaga from "../../views/pages/QuestionnairePage/QuestionnairePage.saga";
import assignInspectionSaga from "../../views/pages/AssignInspectionPage/AssignInspectionPage.saga";
import inspectionSetSaga from "../../views/pages/InspectionSetPage/InspectionSetPage.saga";
import questionnaireSummaryPageSaga from "../../views/pages/QuestionnaireSummaryPage/QuestionnaireSummaryPage.saga";
import reportSaga from "../../components/ReportViewer/ReportViewer.saga";
import standardReportSaga from "../../views/Reports/StandardReport/StandardReport.saga";
import appConfigurationSaga from "../../views/layouts/main-layout/MainLayout.saga";
import remediationTrackerTaskListSaga from "../../views/pages/RemediationTrackerTasksPage/RemediationTrackerTasksPage.saga";
import remediationTrackerTaskSaga from "../../views/pages/RemediationTrackerTaskPage/RemediationTrackerTaskPage.saga";
import viewInspectionQuestionSaga from "../../views/components/ViewInspectionQuestion/ViewInspectionQuestion.saga";
import remediationTrackerTasksReportSaga from "../../views/Reports/RemediationTrackerTasksReport/RemediationTrackerTasksReport.saga";
import viewInspectionSummarySaga from "../../views/components/ViewInspectionSummary/ViewInspectionSummary.saga";
import inspectionReportSaga from "../../views/Reports/StandardsSummaryReport/StandardsSummaryReport.saga";
import standardQuestionsReportSaga from "../../views/Reports/StandardQuestionsReport/StandardQuestionsReport.saga";
import viewRemediationTrackerTaskHistorySaga from "../../views/components/ViewRemediationTrackerTaskHistory/ViewRemediationTrackerTaskHistory.saga";
import inspectionSetReportSaga from "../../views/Reports/InspectionSetReport/InspectionSetReport.saga";
import viewInspectionQuestionHistorySaga from "../../views/components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory.saga";
import userRoleManagementSaga from "../../views/pages/UserRoleManagementPage/UserRoleManagement.saga";
import UserRoleManagementScreensSaga from "../../views/pages/UserRoleManagementPage/UserRoleManagementScreen/UserRoleManagementScreens.saga";
import userRoleManagementLocationsSaga from "../../views/pages/UserRoleManagementPage/UserRoleManagementLocations/UserRoleManagementLocations.saga";
import landingPageSaga from "../../views/pages/LandingPage/LandingPage.saga";
import assessmentTypeWizardSaga from "../../views/pages/AssessmentTypeWizardPage/AssessmentTypeWizardPage.saga";
import assessmentTypeWizardListSaga from "../../views/pages/AssessmentTypeWizardsPage/AssessmentTypeWizardsPage.saga";
import allInspectionReportSaga from "../../views/Reports/AllInspectionSummaryReport/AllInspectionSummaryReport.saga";
import locationReportSaga from "../../views/Reports/LocationReport/LocationReport.saga";

// ==============================|| REDUX - ROOT SAGA ||============================== //

const rootSaga = function* () {
  yield all([fork(appConfigurationSaga)]);
  yield all([fork(loginSaga)]);
  yield all([fork(standardsSaga)]);
  yield all([fork(standardQuestionSaga)]);
  yield all([fork(questionSaga)]);
  yield all([fork(standardSaga)]);
  yield all([fork(standardReferenceSaga)]);
  yield all([fork(sourcesSaga)]);
  yield all([fork(standardSystemSaga)]);
  yield all([fork(standardSubSystemSaga)]);
  yield all([fork(standardSubjectSaga)]);
  yield all([fork(standardSectionSaga)]);
  yield all([fork(locationSaga)]);
  yield all([fork(locationsListSaga)]);
  yield all([fork(InspectionSetsSaga)]);
  yield all([fork(questionnaireStartSaga)]);
  yield all([fork(InspectionAllocationSaga)]);
  yield all([fork(stieInspectionStartPageSaga)]);
  yield all([fork(siteInspectionsListSaga)]);
  yield all([fork(SiteInspectionQuestionnairePageSaga)]);
  yield all([fork(assignInspectionSaga)]);
  yield all([fork(inspectionSetSaga)]);
  yield all([fork(questionnaireSummaryPageSaga)]);
  yield all([fork(reportSaga)]);
  yield all([fork(standardReportSaga)]);
  yield all([fork(remediationTrackerTaskListSaga)]);
  yield all([fork(remediationTrackerTaskSaga)]);
  yield all([fork(viewInspectionQuestionSaga)]);
  yield all([fork(remediationTrackerTasksReportSaga)]);
  yield all([fork(viewInspectionSummarySaga)]);
  yield all([fork(inspectionReportSaga)]);
  yield all([fork(standardQuestionsReportSaga)]);
  yield all([fork(viewRemediationTrackerTaskHistorySaga)]);
  yield all([fork(inspectionSetReportSaga)]);
  yield all([fork(viewInspectionQuestionHistorySaga)]);
  yield all([fork(userRoleManagementSaga)]);
  yield all([fork(UserRoleManagementScreensSaga)]);
  yield all([fork(userRoleManagementLocationsSaga)]);
  yield all([fork(landingPageSaga)]);
  yield all([fork(assessmentTypeWizardSaga)]);
  yield all([fork(assessmentTypeWizardListSaga)]);
  yield all([fork(allInspectionReportSaga)]); 
  yield all([fork(locationReportSaga)]); 
};

export default rootSaga;
