// react
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Icons */
import { SearchNormal1, AddCircle } from "iconsax-react";

// material UI
import {
  Button,
  Grid,
  IconButton,
  Paper,
  InputBase,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// redux

// project imports
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  EnumInspectionSetSortType,
  EnumListShowRowCountList,
  EnumPageType,
  EnumSortTypes,
  EnumStatus,
} from "../../../common/enums/common.enums";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getInspectionSetList } from "./InspectionSetsPage.action";
import {
  setInspectionSetInitState,
  setInspectionSetSearchParameter,
} from "./InspectionSetsPage.reducer";
import {
  DropDownDTO,
  InspectionSetDTO,
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StatusSummaryDTO,
} from "../../../common/types/common.dto.types";
import { InspectionSetPageInitialState } from "./InspectionSetsPage.types";
import { InspectionSetPageParametersType } from "../InspectionSetPage/InspectionSetPage.types";

/* Components */
import HPRStatus from "../../../components/common/HPRStatus/HPRStatus";
import HPRListPagination from "../../../components/common/HPRListPagination/HPRListPagination";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import CloseIcon from "@mui/icons-material/Close";
/* Components End */

// ==============================|| VIEWS - PAGES - INSPECTIONSETS ||============================== //

const initialState = {
  Data: {} as PaginationDTO,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  InspectionSetSearchParameter: {
    InspectionSetSearchParams: {
      Name: "",
      Status: EnumStatus.Published,
      SortBy: {
        Label: "Name",
        Value: EnumInspectionSetSortType.Name.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
    } as InspectionSetDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
  } as SearchParameterDTO,
} as InspectionSetPageInitialState;

const InspectionSetsPage = (props: PageRegistrationProperties) => {
  // registering page properties
  usePageRegistration(props);

  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);

  // navigate
  const navigate = useNavigate();

  // reducer
  const inspectionSetsState = useAppSelector(
    (state) => state.InspectionSetsReducer
  );

  const mainLayoutState = useAppSelector((state) => state.mainLayoutReducer);

  const dispatch = useAppDispatch();

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  //page state
  const [inspectionSetPageState, setInspectionSetPageState] =
    useState(initialState);

  //-----Page load-----
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    dispatch(
      setInspectionSetInitState({
        ...inspectionSetsState,
        InspectionSetSearchParameter:
          inspectionSetPageState.InspectionSetSearchParameter,
        ListPagination: inspectionSetPageState.ListPagination,
        Data: {} as PaginationDTO,
        IsLoading: true,
      })
    );

    dispatch(
      getInspectionSetList(inspectionSetPageState.InspectionSetSearchParameter)
    );
  }, []);

  //set Page State when reducer change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setInspectionSetPageState((values) => {
      return {
        ...values,
        Data: inspectionSetsState.Data,
        ListPagination: {
          ...values.ListPagination,
          ItemList: inspectionSetsState.ListPagination.ItemList,
          TotalRecordsCount:
            inspectionSetsState.ListPagination.TotalRecordsCount,
          SelectedPage: inspectionSetsState.ListPagination.SelectedPage,
        },
        InspectionSetSearchParameter: {
          ...values.InspectionSetSearchParameter,
          SelectedPage:
            inspectionSetsState.InspectionSetSearchParameter.SelectedPage,
        },
      };
    });
  }, [inspectionSetsState]);

  //-----on change serach TextBox-----
  const onsearchNameChange = (searchValue: string) => {
    setInspectionSetPageState((values) => {
      return {
        ...values,
        InspectionSetSearchParameter: {
          ...values.InspectionSetSearchParameter,
          InspectionSetSearchParams: {
            ...values.InspectionSetSearchParameter.InspectionSetSearchParams,
            Name: searchValue,
          },
        },
      };
    });

    if (!searchValue) {
      dispatch(
        getInspectionSetList({
          ...inspectionSetPageState.InspectionSetSearchParameter,
          InspectionSetSearchParams: {
            ...inspectionSetPageState.InspectionSetSearchParameter
              .InspectionSetSearchParams,
            Name: "",
          },
        })
      );
    }
  };
  //-----Search-----
  const onChangeInspectionSetSearchParameter = (
    isAdvanceSearch: boolean,
    selectedPage: number,
    status: EnumStatus,
    sortBy: SortDropDownDTO,
    perpage: number
  ) => {
    setInspectionSetPageState((values) => {
      return {
        ...values,
        ListPagination: {
          ...values.ListPagination,
          ItemList: inspectionSetsState.ListPagination.ItemList,
          ItemShowCount: perpage,
          TotalRecordsCount:
            inspectionSetsState.ListPagination.TotalRecordsCount,
          SelectedPage: inspectionSetsState.ListPagination.SelectedPage,
        },
        InspectionSetSearchParameter: {
          ...values.InspectionSetSearchParameter,
          InspectionSetSearchParams: {
            ...values.InspectionSetSearchParameter.InspectionSetSearchParams,
            Status: status,
            SortBy: sortBy,
          },
          ListPageSize: perpage,
          SelectedPage: selectedPage,
          isAdvanceSearch: isAdvanceSearch,
        },
      };
    });

    dispatch(
      setInspectionSetSearchParameter({
        ...inspectionSetPageState.InspectionSetSearchParameter,
        IsAdvanceSearch: isAdvanceSearch,
        SelectedPage: selectedPage,
        InspectionSetSearchParams: {
          ...inspectionSetPageState.InspectionSetSearchParameter
            .InspectionSetSearchParams,
          Status: status,
          SortBy: sortBy,
        },
        ListPageSize: perpage,
      })
    );

    dispatch(
      getInspectionSetList({
        ...inspectionSetPageState.InspectionSetSearchParameter,
        IsAdvanceSearch: isAdvanceSearch,
        SelectedPage: selectedPage,
        InspectionSetSearchParams: {
          ...inspectionSetPageState.InspectionSetSearchParameter
            .InspectionSetSearchParams,
          Status: status,
          SortBy: sortBy,
        },
        ListPageSize: perpage,
      })
    );
  };

  //-----enetr Key Press ===> serach TextBox-----
  const onsearchNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      if (
        inspectionSetPageState.InspectionSetSearchParameter
          .InspectionSetSearchParams.Name !==
        inspectionSetsState.InspectionSetSearchParameter
          .InspectionSetSearchParams.Name
      ) {
        onChangeInspectionSetSearchParameter(
          inspectionSetPageState?.InspectionSetSearchParameter?.IsAdvanceSearch,
          1, //Default select 1st page
          inspectionSetPageState.InspectionSetSearchParameter
            .InspectionSetSearchParams.Status,
          inspectionSetPageState.InspectionSetSearchParameter
            .InspectionSetSearchParams.SortBy,
          inspectionSetPageState.InspectionSetSearchParameter.ListPageSize
        );
      }
    }
  };

  //-----Serach Buttion Click-----
  const serachBtnClick = (IsAdvanceSearch: boolean) => {
    onChangeInspectionSetSearchParameter(
      IsAdvanceSearch,
      1, //Default select 1st page
      inspectionSetPageState.InspectionSetSearchParameter
        .InspectionSetSearchParams.Status,
      inspectionSetPageState.InspectionSetSearchParameter
        .InspectionSetSearchParams.SortBy,
      inspectionSetPageState.InspectionSetSearchParameter.ListPageSize
    );
  };

  //-----selected Page Change (callback function)-----
  const onPageChange = (newPage: number) => {
    if (
      inspectionSetPageState.InspectionSetSearchParameter.SelectedPage !==
      newPage
    ) {
      onChangeInspectionSetSearchParameter(
        inspectionSetPageState?.InspectionSetSearchParameter?.IsAdvanceSearch,
        newPage,
        inspectionSetPageState.InspectionSetSearchParameter
          .InspectionSetSearchParams.Status,
        inspectionSetPageState.InspectionSetSearchParameter
          .InspectionSetSearchParams.SortBy,
        inspectionSetPageState.InspectionSetSearchParameter.ListPageSize
      );
    }
  };

  const searchByStatusOnClick = (NewStatus: EnumStatus) => {
    if (
      inspectionSetsState.InspectionSetSearchParameter.InspectionSetSearchParams
        .Status !== NewStatus
    ) {
      onChangeInspectionSetSearchParameter(
        inspectionSetPageState?.InspectionSetSearchParameter?.IsAdvanceSearch,
        1,
        NewStatus,
        inspectionSetPageState.InspectionSetSearchParameter
          .InspectionSetSearchParams.SortBy,
        inspectionSetPageState.InspectionSetSearchParameter.ListPageSize
      );
    }
  };

  //-----List Item onclick callback function-----
  const ItemOnClickFunction = (Id: number) => {
    navigate("/inspectionSet", {
      state: {
        InspectionSetId: Id,
      } as InspectionSetPageParametersType,
    });
  };

  const AddBtnOnclick = () => {
    navigate("/inspectionSet", {});
  };

  const BacktnOnclick = () => {
    navigate("/");
  };

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  // const searchBar = useRef(null);
  const recordCount = useRef(null);
  const statusBar = useRef(null);
  // const subHeading = useRef(null);

  //Set Hight
  const [listHeight, setListHeight] = useState(0);
  // const [calendarHeight, setCalendarHeight] = useState(0);

  //-----Page load-----
  useEffect(() => {
    let listheight =
      window.innerHeight -
      mainHeading.current.offsetHeight -
      // subHeading.current.offsetHeight -
      // searchBar.current.offsetHeight -
      recordCount.current.offsetHeight -
      statusBar.current.offsetHeight -
      191;
    setListHeight(listheight);

    // let calendarHeight =
    //   listheight -
    //   subHeading.current.offsetHeight -
    //   searchBar.current.offsetHeight -
    //   125;
    // setCalendarHeight(calendarHeight);

    function handleResize() {
      let listheight =
        window.innerHeight -
        mainHeading.current.offsetHeight -
        // subHeading.current.offsetHeight -
        // searchBar.current.offsetHeight -
        recordCount.current.offsetHeight -
        statusBar.current.offsetHeight -
        191;
      setListHeight(listheight);

      // let calendarHeight =
      //   listheight -
      //   subHeading.current.offsetHeight -
      //   searchBar.current.offsetHeight -
      //   125;
      // setCalendarHeight(calendarHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onChangeSortBy(SelectedSortState: SortDropDownDTO) {
    onChangeInspectionSetSearchParameter(
      inspectionSetPageState?.InspectionSetSearchParameter?.IsAdvanceSearch,
      1,
      inspectionSetPageState.InspectionSetSearchParameter
        .InspectionSetSearchParams.Status,
      SelectedSortState,
      inspectionSetPageState.InspectionSetSearchParameter.ListPageSize
    );
  }

  function onChangePagination(Name: string, Id: string) {
    onChangeInspectionSetSearchParameter(
      inspectionSetPageState?.InspectionSetSearchParameter?.IsAdvanceSearch,
      1,
      inspectionSetPageState.InspectionSetSearchParameter
        .InspectionSetSearchParams.Status,
      inspectionSetPageState.InspectionSetSearchParameter
        .InspectionSetSearchParams.SortBy,
      parseInt(Id)
    );
  }

  return (
    <>
      {/* Global Header with Status & Version */}

      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="" ref={mainHeading}>
          <h1>{props.PageTitle}</h1>
        </Grid> */}

      {/* Global Header with Status & Version  End*/}

      <div className="content-section-card top-main-padding">
        {/* Global Sub Header with  Buttons*/}
        <Grid container>
          <div
            className="flex--container width100-p top-main-border pl-10 pr-10"
            ref={mainHeading}
          >
            {/* <IconButton
              aria-label="back"
              className="sub-head-back-btn"
              onClick={BacktnOnclick}
              title={"Back to home page"}
            >
              <ArrowLeft2 size="24" variant="Outline" />
            </IconButton> */}
            <div className="flex__item--inherit pr-10">
              <h2>
                <Button
                  aria-label="back"
                  className="sub-head-back-btn back"
                  onClick={BacktnOnclick}
                  title="Back to home page"
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>
              </h2>
            </div>
            <div className="flex__item--inherit global-header-wrap">
              <h1>{props.PageTitle}</h1>
            </div>
            {/* Serarch Start */}
            <div className="flex__item search-bar--small ml-10 mr-10">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <InputBase
                  value={
                    inspectionSetPageState.InspectionSetSearchParameter
                      .InspectionSetSearchParams.Name
                  }
                  onChange={(event) => {
                    event.stopPropagation();
                    onsearchNameChange(event.target?.value);
                  }}
                  onKeyPress={onsearchNameKeyPress}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Inspection Sets"
                  inputProps={{ "aria-label": "Inspection Set" }}
                  //type="search"
                />
                {isMouseOver &&
                  inspectionSetPageState.InspectionSetSearchParameter
                    ?.InspectionSetSearchParams?.Name &&
                  inspectionSetPageState.InspectionSetSearchParameter
                    ?.InspectionSetSearchParams?.Name.length > 0 && (
                    <Tooltip title="Clear" placement="left-end">
                      <IconButton
                        className="mr-0"
                        onClick={() => onsearchNameChange("")}
                      >
                        <CloseIcon
                          style={{ color: "#219ebc", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                <Tooltip title="Search" placement={"left-end"}>
                  <IconButton
                    onClick={() => {
                      serachBtnClick(false);
                    }}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    className="m-0"
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                </Tooltip>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton
                      onClick={() => {
                        serachBtnClick(true);
                      }}
                      color="primary"
                      sx={{ p: "10px" }}
                      aria-label="directions"
                    >
                      <Filter variant="Outline" className="ico-secondary" />
                    </IconButton> */}
              </Paper>
            </div>
            {/* Serarch End*/}

            <div className="sub-header-btn-wrap flex__item--inherit">
              {/* <Button
              className="secondary-btn"
              variant="outlined"
              startIcon={<CloseCircle variant="Outline" />}
            >
              Cancel
            </Button> */}
              <Button
                className="primary-btn-small"
                variant="outlined"
                startIcon={<AddCircle variant="Outline" />}
                // className="ternery-btn-small"
                onClick={AddBtnOnclick}
                title="Add"
              >
                Add
              </Button>
            </div>
          </div>
        </Grid>
        {/* Global Sub Header with  Buttons*/}
        {/* Serarch Start */}
        {/* <Grid container className="mb-15" ref={searchBar}>
          <Grid item xs={12} className="search-bar--small">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                value={
                  inspectionSetPageState.InspectionSetSearchParameter
                    .InspectionSetSearchParams.Name
                }
                onChange={onsearchNameChange}
                onKeyPress={onsearchNameKeyPress}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Inspection Sets"
                inputProps={{ "aria-label": "Inspection Set" }}
                type="search"
              />
              <IconButton
                onClick={() => {
                  serachBtnClick(false);
                }}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                className="m-0"
              >
                <SearchNormal1 variant="Outline" className="ico-secondary" />
              </IconButton> */}
        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                onClick={() => {
                  serachBtnClick(true);
                }}
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
              >
                <Filter variant="Outline" className="ico-secondary" />
              </IconButton> */}
        {/* </Paper>
          </Grid>
        </Grid> */}
        {/* Serarch End*/}
        <div className="content-inner-wrap pl-10 pr-10">
          <div ref={statusBar} className="pt-10">
            <HPRStatus
              onClickStatus={searchByStatusOnClick}
              Name={"InspectionSetsStatus"}
              SelectedStatus={
                inspectionSetPageState.InspectionSetSearchParameter
                  .InspectionSetSearchParams.Status as EnumStatus
              }
              SortByItemList={
                [
                  {
                    Label: "Name",
                    Value: EnumInspectionSetSortType.Name.toString(),
                    SortType: EnumSortTypes.Ascending,
                  },
                  {
                    Label: "Last modified user",
                    Value:
                      EnumInspectionSetSortType.LastModifiedUser.toString(),
                    SortType: EnumSortTypes.Ascending,
                  },
                  {
                    Label: "Last modified date & time",
                    Value:
                      EnumInspectionSetSortType.LastModifiedDateTime.toString(),
                    SortType: EnumSortTypes.Ascending,
                  },
                  // {commented for later use}
                  // {
                  //   Label: "Type",
                  //   Value: EnumInspectionSetSortType.Type.toString(),
                  //   SortType: EnumSortTypes.Ascending,
                  // },
                ] as SortDropDownDTO[]
              }
              onChangeSortBy={onChangeSortBy}
              InitialSortByState={
                {
                  Label: "Name",
                  Value: EnumInspectionSetSortType.Name.toString(),
                  SortType: EnumSortTypes.Ascending,
                } as SortDropDownDTO
              }
              StatusSummaryItemList={
                [
                  {
                    Value: EnumStatus.Published,
                    Label: "Published",
                    StatusSummaryCount:
                      inspectionSetPageState.Data?.StatusSummaryCount
                        ?.Published,
                  },
                  // {
                  //   Value: EnumStatus.Revision,
                  //   Label: "Revision",
                  //   StatusSummaryCount:
                  //     inspectionSetPageState.Data?.StatusSummaryCount?.Revision,
                  // },
                  {
                    Value: EnumStatus.Draft,
                    Label: "Draft",
                    StatusSummaryCount:
                      inspectionSetPageState.Data?.StatusSummaryCount?.Draft,
                  },
                  {
                    Value: EnumStatus.Deactivated,
                    Label: "Deactivated",
                    StatusSummaryCount:
                      inspectionSetPageState.Data?.StatusSummaryCount
                        ?.Deactivated,
                  },
                  // {
                  //   Value: EnumStatus.Archive,
                  //   Label: "Archive",
                  //   StatusSummaryCount:
                  //     inspectionSetPageState.Data?.StatusSummaryCount?.Archive,
                  // },
                  {
                    Value: EnumStatus.All,
                    Label: "All",
                    StatusSummaryCount:
                      inspectionSetPageState.Data?.StatusSummaryCount?.All,
                  },
                ] as Array<StatusSummaryDTO>
              }
              PerPageItemList={
                [
                  {
                    Label: "5",
                    Value:
                      EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
                  },
                  {
                    Label: "10",
                    Value:
                      EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
                  },
                  {
                    Label: "15",
                    Value:
                      EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
                  },
                  {
                    Label: "20",
                    Value:
                      EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
                  },
                  {
                    Label: "25",
                    Value:
                      EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
                  },
                ] as DropDownDTO[]
              }
              onChangePerPage={onChangePagination}
            />
          </div>
          {/* Items Found  */}

          <Grid item xs={12} className="mb-15" ref={recordCount}>
            {!inspectionSetsState.IsLoading && !mainLayoutState.IsLoading && (
              <div className="records-found">
                {inspectionSetPageState.ListPagination.TotalRecordsCount ===
                  undefined ||
                inspectionSetPageState.ListPagination.TotalRecordsCount === 0
                  ? "No records found"
                  : inspectionSetPageState.ListPagination.TotalRecordsCount ===
                    1
                  ? "1 record found"
                  : inspectionSetPageState.ListPagination.TotalRecordsCount +
                    " records found"}
              </div>
            )}
          </Grid>

          {/* Items Found  End */}
          <div
            className="inspections-page-items-wrap"
            style={{ height: listHeight }}
          >
            <HPRListPagination
              ItemList={inspectionSetPageState.ListPagination.ItemList}
              ItemShowCount={
                inspectionSetPageState.ListPagination.ItemShowCount
              }
              TotalRecordsCount={
                inspectionSetPageState.ListPagination.TotalRecordsCount
              }
              PaginationOnClickFunction={onPageChange}
              ItemOnClickFunction={ItemOnClickFunction}
              PageType={EnumPageType.InspectionSets}
              SelectedPage={inspectionSetPageState.ListPagination.SelectedPage}
              IsLoading={
                inspectionSetsState.IsLoading ||
                inspectionSetsState.IsLoading === undefined
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InspectionSetsPage;
