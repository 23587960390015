import { createSlice } from "@reduxjs/toolkit";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { LocationReportType } from "./LocationReport.types";

const initialState = {
    LocationReport: {} as ReportViewerType,
    IsDownloading: false,
} as LocationReportType;

const locationReportPageSlice = createSlice({
  name: "LocationReport",
  initialState: initialState,
  reducers: {
    setLocationReportData: (
      state,
      action: { payload: LocationReportType }
    ) => {
      return {
        ...state,
        InspectionReport: action.payload?.LocationReport,
      };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, isReady: action.payload };
    },
    setIsXLAllIsReady: (state, action: { payload: boolean }) => {
      return { ...state, isReadyAllXL: action.payload };
    },
    setDownloadStatus: (state, action: { payload: boolean }) => {
      return { ...state, IsDownloading: action.payload };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const locationReportReducerActions = locationReportPageSlice.actions;
export const locationReportReducer = locationReportPageSlice.reducer;
