import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { GetAppOptions } from "../../common/functions/common.functions";
import { AppOptions } from "../../common/types/common.types";

const reactPlugin = new ReactPlugin();
const currentAppOptions: AppOptions = GetAppOptions();
const insightKey =
  currentAppOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions.InsightKey;
const appInsights = new ApplicationInsights({
  config: {
    connectionString: insightKey,
    extensions: [reactPlugin],
    disableAjaxTracking: true, // Disables automatic tracking of AJAX requests
    // disableExceptionTracking: true, // Disables automatic tracking of exceptions
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags["ai.cloud.role"] = "testTag";
});

export { reactPlugin, appInsights };
