import { createSlice } from "@reduxjs/toolkit";
import SimpleReactValidator from "simple-react-validator";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardSectionDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { SectionPageType } from "./SectionPage.types";

// ==============================|| VIEWS - PAGES - STANDARDSubject PAGE - REDUCER ||============================== //

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {} as PaginationDTO,
  StandardSection: {
    Id: 0,
    Name: "",
    StandardId: 0,
    Version: 1,
    Status: EnumStatus.Published,
  } as StandardSectionDTO,
  StandardSectionSearchParameter: {
    StandardSectionSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardSectionDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  SectionModalOpen: false,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as SectionPageType;

export const standardSectionSlice = createSlice({
  name: "standardSectionList",
  initialState: initialState,
  reducers: {
    setLoading: (
      state: SectionPageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    setStandardSectionInitState: (
      state: SectionPageType,
      action: { payload: SectionPageType; type: string }
    ) => {
      return {
        ...state,
        StandardSection: {
          ...state.StandardSection,
          StandardId: action.payload.StandardSection.StandardId,
        },
        StandardSectionSearchParameter: {
          ...state.StandardSectionSearchParameter,
          StandardSectionSearchParams: {
            ...state.StandardSectionSearchParameter.StandardSectionSearchParams,
            StandardId: action.payload.StandardSection.StandardId,
            Name: action.payload.StandardSection.Name,
          },
        },
        Data: action.payload.Data,
      };
    },
    setStandardSectionList: (
      state: SectionPageType,
      action: { payload: SectionPageType; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        Data: {
          Data: action.payload.Data?.Data,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
        } as PaginationDTO,
      };
    },
    setStandardSectionSearchParameter: (
      state: SectionPageType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        StandardSectionSearchParameter: {
          ...state.StandardSectionSearchParameter,
          StandardSectionSearchParams: {
            ...state.StandardSectionSearchParameter.StandardSectionSearchParams,
            StandardId: action.payload.StandardSectionSearchParams.StandardId,
          },

          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          SortByNavigation: action.payload.SortByNavigation,
          GridPageSize: action.payload.GridPageSize,
        } as SearchParameterDTO,
      };
    },
    setStandardSection: (
      state: SectionPageType,
      action: { payload: StandardSectionDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        StandardSection: {
          ...state.StandardSection,
          Id: action.payload.Id ? action.payload.Id : 0,
          Name: action.payload.Name ? action.payload.Name : "",
        },
      };
    },
    setDeleteStandardSection: (
      state: SectionPageType,
      action: { payload: StandardSectionDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        deletestandardSection: action.payload,
      };
    },
    changePageMode: (
      state: SectionPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            IsDisable: false,
            IsShowCloseBtn: true,
            IsShowEditBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: true,
          },
        };
      }
    },

    setPageMode: (
      state: SectionPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: true,
            IsShowCloseBtn: false,
          },
        };
      }
    },
    setSectionModalOpen: (
      state: SectionPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        SectionModalOpen: action.payload,
      };
    },
  },
});

export const {
  setStandardSectionInitState,
  setStandardSectionList,
  setStandardSection,
  setDeleteStandardSection,
  changePageMode,
  setStandardSectionSearchParameter,
  setLoading,
  setSectionModalOpen,
  setPageMode,
} = standardSectionSlice.actions;
export const standardSectionReducer = standardSectionSlice.reducer;
