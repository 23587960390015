export enum EnumPopupType {
  ErrorPopup = 1,
  SuccessPopup = 2,
  InfoPopup = 3,
  WarningPopup = 4,
  YesNoConfirmation = 5,
  TextInputPopup = 6,
  LoginSwitchPopup = 7,
  EmployeeDropdownPopup = 8,
}
