import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

export const GET_LOCATION_REPORT_DATA =
  "GET_LOCATION_REPORT_DATA";

const locationReportSagaActions = {
  getLocationReport: (parameters: ReportViewerType) => {
    return {
      type: GET_LOCATION_REPORT_DATA,
      payload: parameters,
    };
  },
};

export default locationReportSagaActions;
