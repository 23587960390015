import { createSlice } from "@reduxjs/toolkit";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { AllInspectionDownloadStatusType, AllInspectionReportType } from "./AllInspectionSummaryReport.types";
import { InspectionReportType } from "../StandardsSummaryReport/StandardsSummaryReport.types";

const initialState = {
  InspectionReport: {} as ReportViewerType,
  IsDownloading: false,
  excelReportStatus: [],
} as AllInspectionReportType;

const allInspectionReportPageSlice = createSlice({
  name: "AllInspectionReport",
  initialState: initialState,
  reducers: {
    setAllInspectionReportData: (
      state,
      action: { payload: AllInspectionReportType }
    ) => {
      return {
        ...state,
        InspectionReport: action.payload?.InspectionReport,
      };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, isReady: action.payload };
    },
    setIsXLAllIsReady: (state, action: { payload: boolean }) => {
      return { ...state, isReadyAllXL: action.payload };
    },
    setDownloadStatus: (state, action: { payload: boolean }) => {
      return { ...state, IsDownloading: action.payload };
    },
    setExcelReportStatus: (
      state,
      action: { payload: AllInspectionDownloadStatusType }
    ) => {
      const tempList = [...state.excelReportStatus];
      const index = tempList.findIndex(
        (summary) => summary.InspectionId === action.payload.InspectionId
      );

      if (index !== -1) {
        tempList[index] = {
          ...tempList[index],
          ...({
            InspectionId: action.payload.InspectionId,
            IsDownloading: action.payload.IsDownloading,
          } as AllInspectionDownloadStatusType),
        };
      } else {
        tempList.push({
          InspectionId: action.payload.InspectionId,
          IsDownloading: action.payload.IsDownloading,
        } as AllInspectionDownloadStatusType);
      }

      return { ...state, excelReportStatus: tempList };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const allInspectionReportReducerActions = allInspectionReportPageSlice.actions;
export const allInspectionReportReducer = allInspectionReportPageSlice.reducer;
